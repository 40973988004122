import React from "react";
import AccessInfoRow from "./components/AccessInfoRow/AccessInfoRow";
import "./AccessInfo.scss";
import Screen from "../Screen/Screen";
import AccessInfoEditMobileCard from "../AccessInfoEditMobileCard/AccessInfoEditMobileCard";

const AccessInfo = ({ permissions, typePermissions, typeFile = "folder" }) => {
  return (
    <>
      {permissions?.view?.length > 0 && (
        <>
          <Screen size={"sm"}>
            <div className="accessInfo">
              <div
                className={`accessInfo-header ${
                  typeFile === "file" ? "file" : ""
                }`}
              >
                <span className="accessInfo-header-title name">
                  {typePermissions === "company" ? "Компании" : "Профили"}
                </span>
                <span className="accessInfo-header-title">Просмотр</span>
                {typeFile === "folder" && (
                  <span className="accessInfo-header-title">Добавление</span>
                )}
                <span className="accessInfo-header-title">Редактирование</span>
                <span className="accessInfo-header-title">Копирование</span>
                <span className="accessInfo-header-title">Перемещение</span>
                <span className="accessInfo-header-title">Удаление</span>
              </div>
              <ul className="accessInfo-list customScroll">
                {permissions?.view?.map((elem) => (
                  <AccessInfoRow
                    info={elem}
                    permissions={permissions}
                    key={elem._id}
                    typeFile={typeFile}
                  />
                ))}
              </ul>
            </div>
          </Screen>
          <Screen size={"sm"} down>
            <div className="accessInfoMobile">
              <div
                className={`accessInfoMobile-header ${
                  typeFile === "file" ? "file" : ""
                }`}
              >
                <span className="accessInfoMobile-header-title name">
                  {typePermissions === "company" ? "Компании" : "Профили"}
                </span>
              </div>
              <ul className="accessInfoMobile-list customScroll">
                {permissions?.view?.map((elem) => (
                  <AccessInfoEditMobileCard
                    info={elem}
                    permissions={permissions}
                    key={elem._id}
                    typeFile={typeFile}
                  />
                ))}
              </ul>
            </div>
          </Screen>
        </>
      )}
    </>
  );
};

export default AccessInfo;
