import React, { useId } from "react";
import "./ModalPutAside.scss";
import Button from "../Button/Button";
import InputDate from "../InputDate/InputDate";
import { useState } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import HistoryChanges from "../HistoryChanges/HistoryChanges";
import { useModal } from "../../hooks/useModal";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { asyncPostponedTask } from "../../store/reducers/tasksReducer";
import Screen from "../Screen/Screen";

function ModalPutAside() {
  const formId = useId();

  const dispatch = useDispatch();

  const { getInfo, changeModalVisible } = useModal();

  const infoTask = getInfo("putAsideTask", "data");

  const [date, setDate] = useState(new Date(infoTask.end));

  const onSubmit = async (e) => {
    e.preventDefault();
    if (dayjs(date).diff(infoTask.end, "day") < 0) {
      return toast.error(
        `Нельзя отложить задачу раньше чем ${dayjs(infoTask.end).format("DD.MM.YYYY")}`,
      );
    }
    let idArray = [];
    idArray.push(infoTask._id);
    const res = await dispatch(
      asyncPostponedTask({
        deadline: date,
        tasks: idArray,
      }),
    );
    if (!res.error) return changeModalVisible("putAsideTask", false, { deadline: date });
    changeModalVisible("putAsideTask", false, null);
  };

  return (
    <ModalWrapper
      title={"Отложить дедлайн задачи"}
      onClose={() => changeModalVisible("putAsideTask", false)}
      btns={
        <div className="d-flex justify-center">
          <Button type="submit" disabled={!date} title="Отложить" color="blue" form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalPutAside" id={formId}>
        <div className="modalPutAside-main">
          <div className="modalPutAside-main-grid">
            <InputDate
              onChange={(date) => setDate(date)}
              value={date}
              withPortal={true}
              time={true}
            />
          </div>
          <Screen size={"lg"}>
            <div className="d-flex justify-center">
              <Button type="submit" disabled={!date} title="Отложить" color="blue" form={formId} />
            </div>
          </Screen>

          <HistoryChanges history={infoTask.history} />
        </div>
      </form>
    </ModalWrapper>
  );
}

export default ModalPutAside;
