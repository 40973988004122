import React from "react";
import "./Breadcrumbs.scss";
import BreadcrumbsItem from "./components/BreadcrumbsItem/BreadcrumbsItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useCallback } from "react";
import { useSearch } from "../../../../hooks/useSearch";

const Breadcrumbs = ({ path, getFatherFolders, activeChapter }) => {
  const { setQueryParam, goToPage } = useSearch();

  const getFolder = (folder) => {
    setQueryParam({ father: folder._id });
  };

  let visibleFirst = activeChapter === "company" || activeChapter === "project";

  const isFirst = useCallback(
    (index) => {
      return visibleFirst ? false : index === 0 ? true : false;
    },
    [activeChapter]
  );

  return (
    <div className="breadcrumbs">
      <Swiper
        modules={[FreeMode]}
        className={`swiper-breadcrumbs`}
        slidesPerView={"auto"}
        spaceBetween={10}
        freeMode={{
          enabled: true,
          sticky: true,
        }}
        slideToClickedSlide={true}
      >
        {!!path && (
          <>
            {visibleFirst && (
              <SwiperSlide className={`swiper-breadcrumbs-item`}>
                <BreadcrumbsItem
                  first={true}
                  info={""}
                  onClick={() =>
                    goToPage(
                      "documentation",
                      {},
                      {
                        chapter:
                          activeChapter === "project"
                            ? "projects"
                            : "companies",
                      }
                    )
                  }
                  key={"elem._id"}
                />
              </SwiperSlide>
            )}

            {path.map((elem, index) => (
              <SwiperSlide className={`swiper-breadcrumbs-item`} key={elem._id}>
                <BreadcrumbsItem
                  first={isFirst(index)}
                  info={elem}
                  onClick={() => getFolder(elem)}
                  key={elem._id}
                />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </div>
  );
};

export default Breadcrumbs;
