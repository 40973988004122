import React from "react";
import "./GroupsCardMobile.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import { getBaseUrl } from "../../../../../../../utils/getBaseUrl";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import { transformRole } from "../../../../../../../utils/transformRole";
import CardGridHeader from "../../../../../../../components/CardGridHeader/CardGridHeader";

const GroupsCardMobile = ({ info, changeModalVisible }) => {
  const { profiles, name, company, director, count } = info;

  const getContacts = (type) => {
    const directorContact = profiles.find((el) => el.user._id === director._id);
    if (type === "email") {
      return directorContact?.email;
    } else {
      return directorContact?.phone;
    }
  };

  const onClick = () => {
    changeModalVisible("infoGroup", true, info);
  };

  return (
    <div className="groupsCardMobile">
      <div className="groupsCardMobile-header">
        <CardGridHeader
          title={"Участников:"}
          text={count}
          onClick={() => changeModalVisible("menu", true, info, false, false, "groupsCard")}
        />
        {/* <div className="groupsCardMobile-header-info">
          <div className="groupsCardMobile-header-info-text">
            <span className="groupsCardMobile-header-info-text-title">
              {" "}
              Участников:
            </span>

            <span className="groupsCardMobile-header-info-text-count"> </span>
          </div>
          <Icons
            type={"menu"}
            color={"black"}
            size={"m-md"}
            onClick={() => changeModalVisible("menu", true, info)}
          />
        </div> */}

        <h4 className="groupsCardMobile-header-title">{name}</h4>
      </div>
      <ul className="groupsCardMobile-content" onClick={() => onClick()}>
        {/* <li className="groupsCardMobile-content-item">
          <span className="groupsCardMobile-content-item-title">Компания:</span>
          <span className="groupsCardMobile-content-item-text">
            {company.name}
          </span>
        </li> */}

        <li className="groupsCardMobile-content-item">
          <span className="groupsCardMobile-content-item-title">Руководитель:</span>
          <span className="groupsCardMobile-content-item-text">{getFormatFIO(director?.user)}</span>
        </li>
        <li className="groupsCardMobile-content-item">
          <span className="groupsCardMobile-content-item-title">Компания руководителя:</span>
          <span className="groupsCardMobile-content-item-text">{company?.name}</span>
        </li>
      </ul>
      <ul className="groupsCardMobile-footer">
        <li className="groupsCardMobile-footer-item">
          <Icons
            type={"phone"}
            size={"md"}
            className="groupsCardMobile-footer-item-img"
            color={"white"}
          />
          <a
            className="groupsCardMobile-footer-item-text"
            href={`tel: ${getContacts("phone") || "-"}`}
          >
            {getContacts("phone") || "-"}
          </a>
        </li>
        <li className="groupsCardMobile-footer-item">
          <Icons
            type={"mail"}
            size={"md"}
            className="groupsCardMobile-footer-item-img"
            color={"white"}
          />
          <a
            className="groupsCardMobile-footer-item-text"
            href={`mailto:${getContacts("phone") || "-"}`}
          >
            {getContacts("email") || "-"}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default GroupsCardMobile;
