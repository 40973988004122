import React, { useEffect } from "react";
import Select from "../../../Select/Select";
import Period from "../../../Period/Period";
import InputSearch from "../../../InputSearch/InputSearch";
import InputDate from "../../../InputDate/InputDate";
import "./FilterModal.scss";
import Icons from "../../../Icons/Icons";
import Button from "../../../Button/Button";
import { useSearch } from "../../../../hooks/useSearch";
import { getVisibleFilterPage } from "../../../../utils/getVisibleFilterPage";
import Screen from "../../../Screen/Screen";
import Checkbox from "../../../Checkbox/Checkbox";

const FilterModal = (props) => {
  const {
    onChange,
    currentPage,
    options,
    accept,
    clear,
    mobile,
    filterVisible,
    changeFilterVisible,
    IV,
    changeIV,
  } = props;

  const { queries } = useSearch();

  useEffect(() => {
    if (queries.region) onChange("region", queries.region);

    if (queries.roomType) onChange("roomType", queries.roomType);

    if (queries.position) onChange("position", queries.position);

    if (queries.status) onChange("status", queries.status);

    if (queries.overdue) onChange("overdue", queries.overdue);

    if (queries.author) onChange("author", queries.author);

    if (queries.executor) onChange("executor", queries.executor);

    if (queries.project) onChange("project", queries.project);

    if (queries.start) onChange("start", new Date(queries.start));

    if (queries.end) onChange("end", new Date(queries.end));

    if (queries.isImportant) onChange("isImportant", queries.isImportant ? "isImportant" : null);

    if (queries.company) onChange("company", queries.company);

    if (queries.section) onChange("section", queries.section);

    if (queries.role) onChange("role", queries.role);

    if (queries.typeCompanies) onChange("typeCompanies", queries.typeCompanies);

    if (queries.director) onChange("director", queries.director);

    if (queries.period)
      onChange("period", [new Date(queries.period[0]), new Date(queries.period[1])]);
  }, [queries]);

  return (
    <div className={`filter-modal`}>
      <Screen size={"lg"}>
        <>
          <div className="filter-modal-header">
            <div className="filter-modal-title">
              <span>Фильтры:</span>
            </div>
            <div onClick={() => changeFilterVisible()}>
              {" "}
              <Icons type={"crossBlackIcon"} size={"xl"} title={{ visible: true, text: "" }} />
            </div>
          </div>
        </>
        <hr className="hr-grey" />
      </Screen>

      <div className={"filter-modal-fields"}>
        {/* Важная*/}
        {getVisibleFilterPage("isImportant", currentPage) && (
          // <Select
          //   value={options.isImportant.value}
          //   options={options.isImportant.options}
          //   handleChange={(data) => onChange("isImportant", data)}
          //   placeholder="Выберите приоретет"
          //   title="Тип задачи:"
          //   isClearable={false}
          // />
          <div className="filter-modal-fields-row">
            <Checkbox
              check={options.isImportant.value ? true : false}
              onCheck={() =>
                onChange(
                  "isImportant",
                  !options.isImportant.value
                    ? { value: "isImportant", label: "isImportant" }
                    : null,
                )
              }
            />
            <span className="filter-modal-fields-row-title">Показать важные задачи </span>
          </div>
        )}

        {/* Город */}
        {getVisibleFilterPage("region", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.region.value}
            handleChange={(data) => onChange("region", data)}
            forInput={true}
            type="region"
            placeholder="Введите город"
            title="Город:"
          />
        )}
        {/* Тип приемки */}
        {getVisibleFilterPage("section", currentPage) && (
          <Select
            isClearable={false}
            value={options.section.value}
            options={options.section.options}
            handleChange={(data) => onChange("section", data)}
            placeholder="Выберите раздел"
            title="Раздел:"
          />
        )}

        {/* Тип помещения */}
        {getVisibleFilterPage("roomType", currentPage) && (
          <Select
            isClearable={false}
            value={options.roomType.value}
            options={options.roomType.options}
            handleChange={(data) => onChange("roomType", data)}
            placeholder="Выберите тип"
            title="Тип помещения:"
          />
        )}

        {/* Протокол */}
        {getVisibleFilterPage("protocol", currentPage) && (
          <Select
            isClearable={false}
            value={options.protocol.value}
            options={options.protocol.options}
            handleChange={(data) => onChange("protocol", data)}
            placeholder="Выберите протокол"
            title="Протокол:"
          />
        )}

        {/* Период */}
        {getVisibleFilterPage("period", currentPage) && (
          <Period
            state={options.period.value || []}
            handleSelect={(range) => onChange("period", range)}
            title="Период:"
            dev={["filterPeriod"]}
          />
        )}

        {/* Период по контракту*/}
        {getVisibleFilterPage("periodСontract", currentPage) && (
          <Period
            state={options.periodСontract.value || date}
            handleSelect={(range) => onChange("periodСontract", range.selection)}
            title="Период по контракту:"
            dev={["filterPeriod"]}
          />
        )}

        {/* Проекты */}
        {getVisibleFilterPage("project", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.project.value}
            options={options.project.options}
            handleChange={(data) => onChange("project", data)}
            title="Проект:"
            type="project"
            placeholder="Выберите проект"
          />
        )}
        {/* Руководитель */}
        {getVisibleFilterPage("director", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.director.value}
            options={options.director.options}
            handleChange={(data) => onChange("director", data)}
            placeholder="Введите сотрудника"
            title="Руководитель"
          />
        )}

        {/* Сотрудники */}
        {/* {getVisibleFilterPage("employee", currentPage) && (
        <InputSearch
          value={filters.employee}
          options={options.employee?.options}
          handleChange={(data) => onChange("employee", data)}
          placeholder="Введите сотрудника"
          title="Сотрудники"
        />
      )} */}

        {/* Участник */}
        {getVisibleFilterPage("participant", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.participant.value}
            options={options.participant.options}
            handleChange={(data) => onChange("participant", data)}
            placeholder="Введите участника"
            title="Участник"
          />
        )}
        {/* Заказчик */}
        {getVisibleFilterPage("customer", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.customer.value}
            options={options.customer.options}
            handleChange={(data) => onChange("customer", data)}
            placeholder="Введите заказчика"
            title="Заказчик"
          />
        )}
        {getVisibleFilterPage("responsible", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.responsible.value}
            options={options.responsible.options}
            handleChange={(data) => onChange("responsible", data)}
            placeholder="Введите ответственного"
            title="Ответсвтенный"
          />
        )}
        {/* Должность */}
        {getVisibleFilterPage("position", currentPage) && (
          <Select
            isClearable={false}
            value={options.position.value}
            options={options.position.options}
            handleChange={(data) => onChange("position", data)}
            placeholder="Выберите должность"
            title="Должность:"
          />
        )}

        {/* Компания */}
        {getVisibleFilterPage("company", currentPage) && (
          <InputSearch
            type="company"
            isClearable={false}
            value={options.company.value}
            options={options.company.options}
            handleChange={(data) => onChange("company", data)}
            placeholder="Выберите компанию"
            title="Компания:"
          />
        )}

        {/* Роль */}
        {getVisibleFilterPage("role", currentPage) && (
          <Select
            isClearable={false}
            value={options.role.value}
            options={options.role.options}
            handleChange={(data) => onChange("role", data)}
            placeholder="Выберите роль"
            title="Роль:"
          />
        )}

        {/* Статус */}
        {getVisibleFilterPage("status", currentPage) && (
          <Select
            isClearable={false}
            value={options.status.value}
            options={options.status.options}
            handleChange={(data) => onChange("status", data)}
            placeholder="Выберите статус"
            title="Статус:"
          />
        )}
        {/* Просроченные */}
        {getVisibleFilterPage("overdue", currentPage) && (
          <Select
            isClearable={false}
            value={options.overdue.value}
            options={options.overdue.options}
            handleChange={(data) => onChange("overdue", data)}
            placeholder="Выберите тип"
            title="Отклонение по срокам:"
          />
        )}
        {/* Автор */}
        {getVisibleFilterPage("author", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.author.value}
            options={options.author.options}
            handleChange={(data) => onChange("author", data)}
            placeholder="Введите имя автора"
            title="Автор"
          />
        )}

        {/*Исполнитель*/}
        {getVisibleFilterPage("executor", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.executor.value}
            options={options.executor.options}
            handleChange={(data) => onChange("executor", data)}
            placeholder="Введите имя исполнителя"
            title="Исполнитель"
          />
        )}

        {/* Проекты - задачи*/}
        {getVisibleFilterPage("projects", currentPage) && (
          <InputSearch
            isClearable={false}
            value={options.project.value}
            options={options.project}
            handleChange={(data) => onChange("project", data)}
            placeholder="Введите название проекта"
            title="Проект:"
            type="project"
          />
        )}

        {/* Колличество сотрудников */}
        {getVisibleFilterPage("quantity", currentPage) && (
          <Select
            value={options.quantity.value}
            options={options.quantity.options}
            handleChange={(data) => onChange("quantity", data)}
            placeholder="По уменьшению"
            title="Колл-во сотрудников:"
            isClearable={false}
          />
        )}

        {/* Проекты - задачи*/}
        {getVisibleFilterPage("typeCompanies", currentPage) && (
          <Select
            value={options.typeCompanies.value}
            options={options.typeCompanies.options}
            handleChange={(data) => onChange("typeCompanies", data)}
            placeholder="Все компании"
            title="Тип:"
            isClearable={false}
          />
        )}

        {/* Дата создания */}
        {getVisibleFilterPage("start", currentPage) && (
          <InputDate
            value={options.start.value}
            onChange={(data) => onChange("start", data)}
            name="start"
            dev={["filterDate"]}
            placeholderText="Выберите дату"
            title="Дата создания:"
            time
          />
        )}

        {/* Дата звершения */}
        {getVisibleFilterPage("end", currentPage) && (
          <InputDate
            value={options.end.value}
            onChange={(data) => onChange("end", data)}
            name="end"
            dev={["filterDate"]}
            placeholderText="Выберите дату"
            title="Дедлайн:"
            time
          />
        )}

        {/* Предписания */}
        {getVisibleFilterPage("instructions", currentPage) && (
          <Select
            value={options.instructions.value}
            options={options.instructions.options}
            handleChange={(data) => onChange("instructions", data)}
            placeholder="Все предписания"
            title="Предписания:"
            isClearable={false}
          />
        )}
      </div>
      <Screen size={"lg"}>
        <div className={"filter-modal-btns"}>
          <Button color="blue" title="Применить" onClick={accept} />
          <Button color="dark" title="Сбросить" onClick={clear} />
        </div>
      </Screen>
    </div>
  );
};

export default FilterModal;
