import React from "react";
import "./ModalDeleteItem.scss";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import Screen from "../Screen/Screen";
import { useModal } from "../../hooks/useModal";

function ModalDeleteItem(props) {
  const {
    changeModalVisible: changeModalVisibleProps,
    onDelete,
    headerTitle,
    title,
    nameModal,
    onDeleteTitle,
  } = props;

  const { changeModalVisible: changeModalVisibleGlobal } = useModal();

  const changeModalVisible = (...args) => {
    if (changeModalVisibleProps) {
      changeModalVisibleProps(...args);
    } else {
      changeModalVisibleGlobal(nameModal, false, null);
    }
  };

  return (
    <>
      <Screen size={"lg"}>
        <ModalWrapper
          width="494px"
          onClose={() => changeModalVisible(nameModal, false)}
          title={headerTitle || "Модальное окно на удаление"}
          noscroll={true}
          minHeight={false}
          visibleSize={false}
        >
          <div className="modalDeleteItem">
            <div className="modalDeleteItem-main">
              <span className="modalDeleteItem-text bold">
                {title || "Модальное окно на удаление"}
              </span>
              <div className="modalDeleteItem-main-btns">
                <Button
                  onClick={() => onDelete && onDelete()}
                  title={`${onDeleteTitle || "Удалить"}`}
                  color="blue"
                />
                <Button
                  onClick={() => changeModalVisible(nameModal, false)}
                  title="Отменить"
                  color="dark"
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      </Screen>
      <Screen size={"lg"} down>
        <ModalWrapperMobile
          onClose={() => changeModalVisible(nameModal, false)}
          title={headerTitle || "Модальное окно на удаление"}
          noscroll={true}
          height={"150px"}
          btns={
            <div className="modalDeleteItem-main-btns">
              <Button
                onClick={onDelete}
                title={`${onDeleteTitle || "Удалить"}`}
                color="blue"
              />
              <Button
                onClick={() => changeModalVisible(nameModal, false)}
                title="Отменить"
                color="dark"
              />
            </div>
          }
        >
          <div className="modalDeleteItem">
            <div className="modalDeleteItem-main">
              <span className="modalDeleteItem-text bold">
                {title || "Модальное окно на удаление"}
              </span>
            </div>
          </div>
        </ModalWrapperMobile>
      </Screen>
    </>
  );
}

export default ModalDeleteItem;
