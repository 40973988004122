export const getTypePages = (types) => {
  let arr = [];

  if (types.length > 0 || types.includes("genContractor")) {
    if (types.includes("genContractor")) {
      return (arr = [
        {
          chapter: "genContractor",
          title: "Ген.подрядчика",
        },
        {
          chapter: "customer",
          title: "Заказчика",
        },
        {
          chapter: "executor",
          title: "Подрядчика",
        },
      ]);
    } else if (types.includes("executor")) {
      return (arr = [
        {
          chapter: "genContractor",
          title: "Ген.подрядчика",
        },
        {
          chapter: "executor",
          title: "Подрядчика",
        },
      ]);
    } else if (types.includes("customer")) {
      return (arr = [
        {
          chapter: "customer",
          title: "Заказчика",
        },
        {
          chapter: "genContractor",
          title: "Ген.подрядчика",
        },
      ]);
    }
  } else {
    return arr;
  }
};
