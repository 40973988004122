import React, { useState, useEffect } from "react";
import "./ModalSwiperPhoto.scss";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Keyboard, FreeMode, Navigation, Thumbs } from "swiper";
import { getBaseUrl } from "../../utils/getBaseUrl";
import Icons from "../Icons/Icons";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";

const ModalSwiperPhoto = () => {
  const { changeModalVisible, getInfo } = useModal();
  const { list, initialSlide } = getInfo("swiperPhoto", "data");
  const { width } = useWindowDimensions();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [typePosition, setTypePosition] = useState("vertical");
  //   const swiper = useSwiper();
  const [swiper, setSwiper] = useState(null);

  const getFullPathImage = (image) => {
    if (typeof image === "string" && image.includes("delivery_work")) {
      return getFullPath(image, "deliveryWork");
    } else if (typeof image === "string" && image.includes("photos")) {
      return getFullPath(image, "task");
    } else if (typeof image === "string" && image.includes("comments")) {
      return getFullPath(image, "task");
    } else if (typeof image === "string" && image.includes("chats")) {
      return getFullPath(image, "chats");
    }
    return getFullPath(image, "url");
  };

  useEffect(() => {
    if (width <= 1024) {
      setTypePosition("");
    } else {
      setTypePosition("vertical");
    }
  }, [width]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("swiperPhoto", false)}
      title={"Просмотр фотографий"}
    >
      <div className="modalSwiperPhoto">
        {!!list?.length && (
          <Swiper
            style={{
              "--swiper-navigation-color": "#000000",
              "--swiper-pagination-color": "#000000",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            keyboard={{
              enabled: true,
            }}
            modules={[Keyboard, FreeMode, Navigation, Thumbs]}
            className="modalSwiperPhoto-bigImg-swiper"
            onSwiper={(s) => {
              setSwiper(s);
            }}
            initialSlide={initialSlide || 0}
          >
            <>
              <div
                onClick={() => swiper.slideNext()}
                className="modalSwiperPhoto-bigImg-swiper-next"
              >
                <Icons type={"arrow"} size={"xl"} cursor />
              </div>
              <div
                onClick={() => swiper.slidePrev()}
                className="modalSwiperPhoto-bigImg-swiper-prev"
              >
                <Icons type={"arrow"} size={"xl"} cursor />
              </div>
            </>
            {list.map((photo, i) => {
              return (
                <SwiperSlide key={i}>
                  <img className="modalSwiperPhoto-img" src={getFullPathImage(photo)} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}

        <Swiper
          onSwiper={setThumbsSwiper}
          // loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="modalSwiperPhoto-panel-swiper"
          direction={typePosition}
          initialSlide={initialSlide || 0}
        >
          {list.map((photo, i) => {
            return (
              <SwiperSlide key={i}>
                <img className="modalSwiperPhoto-panel-img" src={getFullPathImage(photo)} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </ModalWrapper>
  );
};

export default ModalSwiperPhoto;
