import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import "./DeliveryWorkRequests.scss";
import Pagination from "../../../../../components/Pagination/Pagination";
import DeliveryWorkRequestsRow from "../DeliveryWorkRequestsRow/DeliveryWorkRequestsRow";
import { useNavigate } from "react-router-dom";

const DeliveryWorkRequests = (props) => {
  const { deliveryWorks, count } = props;

  return (
    <div className="deliveryWork-content__requests deliveryWork-requests customScroll">
      <ul className="deliveryWork-requests__header deliveryWork-requests-header">
        {/* <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-numder">
          <Checkbox
            check={selectItems.length === requests.length}
            onCheck={addAllItemSelect}
          />
          <span>№:</span>
        </li> */}
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-typeWork">
          <span title="Вид работы">Работа:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-author">
          <span title="Автор обращения">Автор:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-inspectors">
          <span title="Проверяющий">Инженер:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-inspectors">
          <span title="Исполнитель">Проект:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-date">
          <span title="Дата вызова">Дата вызова:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-status">
          <span title="Статус">Статус:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-typeReceiving">
          <span title="Раздел">Раздел:</span>
        </li>
        <li className="deliveryWork-requests-header__item deliveryWork-requests-header__item-bnts">
          <span></span>
        </li>
      </ul>
      <ul className="deliveryWork-requests__list">
        {deliveryWorks.map((work) => {
          return <DeliveryWorkRequestsRow key={work._id} id={work._id} info={work} />;
        })}
      </ul>
      {count >= 10 && (
        <div className="deliveryWork-requests__pagination deliveryWork-requests__pagination">
          <Pagination currentPage={pageNumber} count={count} onPageChange={(page) => changePage(page)} />
        </div>
      )}
    </div>
  );
};
export default DeliveryWorkRequests;
