import React, { useEffect, useState, useId } from "react";
import "./ModalEditEmployee.scss";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import Select from "../Select/Select";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { asyncEditUser, asyncGetUsers } from "../../store/reducers/userReducer";
import InputSearch from "../../components/InputSearch/InputSearch";
import { getForSelect } from "../../utils/getForSelect";
import { transformForSelect } from "../../utils/transformForSelect";
import { transformTypeAccount } from "../../utils/transformTypeAccount";
import SelectMulti from "../../components/SelectMulti/SelectMulti";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useModal } from "../../hooks/useModal";
import { setData } from "../../store/reducers/modalsReduces";
import InputDate from "../../components/InputDate/InputDate";
import ProfileRowModal from "../../components/ProfileRowModal/ProfileRowModal";
import { transformRole } from "../../utils/transformRole";
import { toast } from "react-toastify";
import Screen from "../Screen/Screen";

const ModalEditEmployee = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();
  const { userData } = useSelector((state) => state.user);
  const infoModal = getInfo("editEmployee", "data");

  const {
    _id,
    name,
    surname,
    patronymic,
    avatar,
    email,
    phone,
    role,
    region,
    profiles: userProfiles,
    date_of_birth,
  } = infoModal;

  const [profiles, setProfiles] = useState([]);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    surname: surname,
    email: email,
    name: name,
    phoneСompany: "",
    phone: phone,
    patronymic: patronymic,
    avatar: null,
    dateOfBirth: date_of_birth ? new Date(date_of_birth) : new Date(),
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    region: {
      options: [],
      value: transformForSelect(region),
    },
    role: {
      options: "default",
      value: { label: transformRole(role), value: role },
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("name", { minHeight: 3, maxHeight: 100 });
    const checkSurname = checkValidateIV("surname", {
      minHeight: 3,
      maxHeight: 50,
    });
    const checkEmail = checkValidateIV("email", { email: true });
    const checkPhone = checkValidateIV("phone", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    const checkRole = checkValidateOptions("role", { required: true });

    if (!checkName || !checkEmail || !checkPhone || !checkRegion || !checkSurname || !checkRole)
      return;

    const formData = new FormData();
    formData.append("name", IV.name);
    formData.append("surname", IV.surname);
    formData.append("patronymic", IV.patronymic);
    formData.append("email", IV.email);
    formData.append("phone", IV.phone);
    formData.append("date_of_birth", IV.dateOfBirth);
    formData.append("region", options.region.value.value || null);
    formData.append("role", options.role.value.value || null);
    if (IV.avatar) formData.append("avatar", IV.avatar);

    // * проверка profiles
    if (profiles.length) {
      for (let i = 0; i < profiles.length; i++) {
        const profile = profiles[i];
        if (!profile.company || !profile.position) {
          return toast.error("Заполните и сохраните все поля в профайле");
        }
        if (profile.edit) {
          return toast.error("Есть несохраненные профайлы");
        }
      }
    }

    const res = await dispatch(asyncEditUser({ formData, user_id: _id }));
    if (res.error) return;
    handleChange && handleChange();
    changeModalVisible("editEmployee", false, null);
  };

  const createProfile = () => {
    const profile = new Object({
      index: Math.random(),
      company: null,
      department: [],
      position: null,
      groups: [],
    });
    setProfiles((prev) => [...prev, profile]);
  };

  const deleteProfile = (index) => {
    setProfiles((prev) => {
      return prev.filter((profile, i) => profile.index !== index);
    });
  };

  useEffect(() => {
    if (userProfiles && Array.isArray(userProfiles)) {
      setProfiles((prev) => [
        ...prev,
        ...userProfiles.map((profile) => ({
          _id: profile._id,
          index: profile._id,
          company: transformForSelect(profile.company),
          position: transformForSelect(profile.position),
          departments: profile.departments.map(transformForSelect),
          groups: profile.groups.map(transformForSelect),
        })),
      ]);
    }
  }, []);

  const onChangeProfile = (index, company, department, position, groups, otherFields = {}) => {
    return setProfiles((prev) => {
      return prev.map((elem) => {
        if (elem.index === index) {
          return {
            ...elem,
            company,
            department,
            position,
            groups,
            ...otherFields,
          };
        } else {
          return elem;
        }
      });
    });
  };

  return (
    <ModalWrapper
      title={`Редактировать сотрудника`}
      onClose={() => changeModalVisible("editEmployee", false)}
      btns={
        <div className="modalEditEmployee-btn-wrapper">
          <Button type="submit" title="Сохранить" color="blue" form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalEditEmployee-content" id={formId}>
        <h4 className="modalEditEmployee-content-title">Основная информация</h4>
        <div className="modalEditEmployee-content-baseInfo">
          <div className="modalEditEmployee-content-baseInfo-wrapper">
            <ul className="modalEditEmployee-content-info">
              <li className="modalEditEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.surname}
                  name="surname"
                  title="Фамилия:*"
                  placeholder="Введите Фамилию"
                  validate={checkValidateIV("surname")}
                />
              </li>
              <li className="modalEditEmployee-content-info-item">
                <Input
                  validate={checkValidateIV("name")}
                  onChange={changeIV}
                  value={IV.name}
                  name="name"
                  title="Имя:*"
                  placeholder="Введите Имя"
                />
              </li>
              <li className="modalEditEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.patronymic}
                  name="patronymic"
                  title="Отчество:"
                  placeholder="Введите Отчество"
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <InputDate
                  title="Дата рождения:"
                  value={IV.dateOfBirth}
                  onChange={(data) => {
                    changeIV("set", { name: "dateOfBirth", value: data });
                  }}
                />
              </li>
              <li className="modalEditEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.email}
                  name="email"
                  title="Email:*"
                  placeholder="Введите Email"
                  validate={checkValidateIV("email")}
                />
              </li>
              <li className="modalEditEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.phone}
                  name="phone"
                  type="tel"
                  title="Телефон:*"
                  placeholder="Введите номер телефона"
                  validate={checkValidateIV("phone")}
                />
              </li>
              <li className="modalEditEmployee-content-info-item">
                <InputSearch
                  forInput={true}
                  type="region"
                  handleChange={(data) => {
                    changeOptions("region", "value", data);
                  }}
                  placeholderSelect="Выберите город"
                  validate={checkValidateOptions("region")}
                  placeholder="Выберите город"
                  title="Город:*"
                  menuPlacement="top"
                  value={options.region.value}
                />
              </li>
              {userData.role === "admin" && (
                <li className="modalEditEmployee-content-info-item">
                  <Select
                    options={options.role.options}
                    value={options.role.value}
                    handleChange={(data) => changeOptions("role", "value", data)}
                    placeholder="Выберите роль"
                    title="Роль в системе:*"
                    forInput
                    menuPlacement="top"
                  />
                </li>
              )}
            </ul>
          </div>

          <div className="modalEditEmployee-content-img">
            <span className="modalEditEmployee-content-info-title">Фото:</span>
            <div className="modalEditEmployee-content-info-img">
              <ImageUpload
                onChange={(value) => changeIV("set", { name: "avatar", value })}
                logo={avatar}
              />
            </div>
          </div>
        </div>
        <div className="hr"></div>
        <h4 className="modalAddEmployee-content-title">Профайлы</h4>
        {!!profiles.length &&
          profiles.map((profile) => (
            <ProfileRowModal
              key={profile.index || profile._id}
              info={profile}
              onDelete={() => deleteProfile(profile.index)}
              onChangeProfile={onChangeProfile}
              setProfiles={setProfiles}
              userProfiles={userProfiles}
              edit
            />
          ))}
        <div className="modalAddEmployee-content-baseInfo-btn-wrapper">
          <Button
            title="Профайл"
            color="dark"
            icon={{ position: "left" }}
            onClick={() => createProfile()}
          />
        </div>
        <Screen size={"lg"}>
          <div className="modalEditEmployee-btn-wrapper">
            <Button type="submit" title="Сохранить" color="blue" form={formId} />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditEmployee;
