import React from "react";
import "./Textarea.scss";

function Textarea(props) {
  const {
    name,
    value,
    height,
    minHeight,
    maxHeight,
    onChange,
    title,
    placeholder,
    validate,
  } = props;

  const classes = `
    textarea
    ${validate ? "error" : ""}
  `;

  const checkRequaerd = (title) => {
    const arr = title.split("");
    if (arr.at(-1) === "*") {
      arr.splice(arr.length - 1);
      return (
        <div className="textarea-title">
          <span>{arr}</span>
          <span className="textarea-title-red"> *</span>
        </div>
      );
    } else {
      return <span className="textarea-title">{title}</span>;
    }
  };

  return (
    <div className={classes}>
      {!!title && checkRequaerd(title)}
      <div className="textarea-input ">
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          className="customScroll"
          style={{ height, minHeight, maxHeight }}
          placeholder={placeholder}
        ></textarea>
      </div>
      {!!validate && (
        <div className="textarea-validate">
          <span>{validate}</span>
        </div>
      )}
    </div>
  );
}

export default Textarea;
