import React, { useState, useRef } from "react";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import "./StructuresListLastRow.scss";
import Icons from "../../../../../Icons/Icons";
import Screen from "../../../../../Screen/Screen";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";

const StructuresListLastRow = ({ director, name, employeesLength, changeModalVisible, info }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const onEvent = (name) => {
    if (name === "edit") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info, true);
    }

    if (name === "del") {
      setMenuVisible(false);
      return changeModalVisible("deleteDepartment", true, info);
    }

    if (name === "info") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info);
    }
    if (name === "menu") {
      return changeModalVisible("menu", true, info);
    }
  };

  return (
    <li className="structuresListLastRow">
      <div className="structuresListLastRow__content">
        <div className="structuresListLastRow__info">
          <h5 className="structuresListLastRow__title">
            {name} {`(сотрудников: ${employeesLength})`}
          </h5>
          <Screen size={"lg"}>
            {!!director?.user && (
              <span className="structuresListLastRow__text">Руководитель отдела: {getFormatFIO(director.user)}</span>
            )}
          </Screen>
        </div>
        <div className="structuresListLastRow__btns">
          <Screen size={"lg"}>
            <button
              className="structuresListLastRow__btn structuresListLastRow__btn-menu"
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <Icons
                type={"menu"}
                size={"md"}
                color={"white"}
                cursor
                title={{ visible: true, text: "" }}
              />
            </button>
          </Screen>
          <Screen size={"lg"} down>
            <button
              className="structuresListLastRow__btn structuresListLastRow__btn-menu"
              onClick={() => onEvent("menu")}
            >
              <Icons type={"menu"} size={"m-md"} color={"white"} />
            </button>
          </Screen>
        </div>
      </div>
      <Screen size={"lg"}>
        {menuVisible && (
          <div
            ref={ref}
            className="structuresListLastRow__menu-window structuresListLastRow-menu-window animate__animated animate__fadeIn modal"
          >
            <span onClick={() => onEvent("info")}>Информация об отделе</span>
            <span onClick={() => onEvent("edit")}>Редактировать отдел</span>
            <span onClick={() => onEvent("del")}>Удалить отдел</span>
          </div>
        )}
      </Screen>
    </li>
  );
};

export default StructuresListLastRow;
