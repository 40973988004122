export const getLimitsListChart = () => {
  let limitElements = 10;
  let width = document.documentElement.clientWidth;
  if (width >= 480) {
    limitElements = 10;
  } else if (width >= 400 && width <= 479.9) {
    limitElements = 8;
  } else if (width <= 399.9) {
    limitElements = 5;
  }

  return limitElements;
};
