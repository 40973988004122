import React from "react";
import "./AccessInfoEditMobileCard.scss";
import AccessInfoEditMobileCardElem from "./components/AccessInfoEditMobileCardElem/AccessInfoEditMobileCardElem";
import Icons from "../Icons/Icons";

const AccessInfoEditMobileCard = ({
  info,
  permissions,
  typeFile,
  changePermissions,
  edit = false,
}) => {
  const { name, _id } = info;
  const findId = (arr, id) => {
    if (arr?.find((e) => e._id === id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <li className="accessInfoEditMobileCard">
      <div className="accessInfoEditMobileCard-header">
        <span className="accessInfoEditMobileCard-header-name">
          {name || ""}
        </span>
        {edit && (
          <div className="accessInfoEditMobileCard-header-btn">
            <Icons
              type={"remove"}
              size={"md"}
              cursor
              onClick={() => changePermissions("clearAll", info)}
            />
          </div>
        )}
      </div>
      <div className="accessInfoEditMobileCard-content">
        <AccessInfoEditMobileCardElem
          access={findId(permissions.view, _id)}
          title={"Просмотр"}
          edit={edit}
          check={findId(permissions.view, _id)}
          onCheck={() =>
            changePermissions(
              findId(permissions.view, _id) ? "clearAll" : "view",
              info
            )
          }
        />
        {typeFile === "folder" && (
          <AccessInfoEditMobileCardElem
            access={findId(permissions.add, _id)}
            title={"Добавление"}
            edit={edit}
            check={findId(permissions.add, _id)}
            onCheck={() => changePermissions("add", info)}
          />
        )}
        <AccessInfoEditMobileCardElem
          access={findId(permissions.edit, _id)}
          title={"Редактирование"}
          edit={edit}
          check={findId(permissions.edit, _id)}
          onCheck={() => changePermissions("edit", info)}
        />
        <AccessInfoEditMobileCardElem
          access={findId(permissions.copy, _id)}
          title={"Копирование"}
          edit={edit}
          check={findId(permissions.copy, _id)}
          onCheck={() => changePermissions("copy", info)}
        />
        <AccessInfoEditMobileCardElem
          access={findId(permissions.move, _id)}
          title={"Перемещение"}
          edit={edit}
          check={findId(permissions.move, _id)}
          onCheck={() => changePermissions("move", info)}
        />
        <AccessInfoEditMobileCardElem
          access={findId(permissions.remove, _id)}
          title={"Удаление"}
          edit={edit}
          check={findId(permissions.remove, _id)}
          onCheck={() => changePermissions("remove", info)}
        />
      </div>
    </li>
  );
};

export default AccessInfoEditMobileCard;
