import React from "react";
import "./EmployeesCardMobile.scss";
import Icons from "../Icons/Icons";
import { getBaseUrl } from "../../utils/getBaseUrl";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { transformRole } from "../../utils/transformRole";
import { getFullPath } from "../../utils/getFullPath";
import noImage from "../../assets/images/pages/noavatar.svg";
import Checkbox from "../Checkbox/Checkbox";
import { profilesParsing } from "../../utils/profilesParsing.js";
import CardGridHeader from "../CardGridHeader/CardGridHeader";

const EmployeesCardMobile = ({
  info,
  changeModalVisible,
  changeWiewChapter,
  onOpenModal,
  addItemSelect,
  check,
  modalCard = false,
  type = "profile",
}) => {
  const {
    total_tasks,
    avatar,
    position,
    role,
    company,
    department,
    phone,
    email,
    employees,
    region,
    _id,
    profiles,
    isLogin,
  } = info;

  return (
    <div className={`employeesCardMobile ${isLogin ? "" : "not-login"}`}>
      <div className="employeesCardMobile-header">
        {modalCard ? (
          <div className="employeesCardMobile-header-mobile">
            <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
          </div>
        ) : (
          <CardGridHeader
            title={type === "profile" ? "Задач:" : ""}
            text={type === "profile" ? total_tasks || 0 : ""}
            onClick={() => changeModalVisible("menu", true, info, false, false, "employeesCard")}
          />
        )}
        <img
          className="employeesCardMobile-header-img"
          src={getFullPath(avatar, "avatar")}
          alt={type === "profile" ? getFormatFIO(info) : getFormatFIO(info?.user)}
        />
        <h4 className="employeesCardMobile-header-title">
          {type === "profile" ? getFormatFIO(info) : getFormatFIO(info?.user)}
        </h4>
      </div>
      <ul className="employeesCardMobile-content" onClick={() => onOpenModal(info)}>
        <li className="employeesCardMobile-content-item">
          <span className="employeesCardMobile-content-item-title">Компания:</span>
          <span
            className="employeesCardMobile-content-item-text"
            title={type === "profile" ? profilesParsing("company", profiles) || "-" : "-"}
          >
            {type === "profile" ? profilesParsing("company", profiles) || "-" : company?.name}
          </span>
        </li>
        <li className="employeesCardMobile-content-item">
          <span className="employeesCardMobile-content-item-title">Должность:</span>
          <span
            className="employeesCardMobile-content-item-text"
            title={
              type === "profile"
                ? profilesParsing("position", profiles) || "-"
                : position?.name || "-"
            }
          >
            {type === "profile"
              ? profilesParsing("position", profiles) || "-"
              : position?.name || "-"}
          </span>
        </li>
        <li className="employeesCardMobile-content-item">
          <span className="employeesCardMobile-content-item-title">Отдел:</span>
          <span
            className="employeesCardMobile-content-item-text"
            title={type === "profile" ? profilesParsing("departments", profiles) || "-" : ""}
          >
            {type === "profile"
              ? profilesParsing("departments", profiles) || "-"
              : department?.name || "-"}
          </span>
        </li>

        {/* <li className="employeesCardMobile-content-item">
          <span className="employeesCardMobile-content-item-title">Роль:</span>
          <span className="employeesCardMobile-content-item-text" title={transformRole(role)}>
            {transformRole(role)}
          </span>
        </li> */}
        {type === "profile" && (
          <li className="employeesCardMobile-content-item">
            <span className="employeesCardMobile-content-item-title">Город:</span>
            <span className="employeesCardMobile-content-item-text" title={region?.name}>
              {region?.name}
            </span>
          </li>
        )}
      </ul>
      <ul className="employeesCardMobile-footer">
        <li className="employeesCardMobile-footer-item">
          <Icons
            type={"phone"}
            size={"md"}
            className="employeesCardMobile-footer-item-img"
            color={"white"}
          />
          <a
            className="employeesCardMobile-footer-item-text"
            href={`tel: ${phone || info?.user?.phone}`}
          >
            {phone || info?.user?.phone}
          </a>
        </li>
        <li className="employeesCardMobile-footer-item">
          <Icons
            type={"mail"}
            size={"md"}
            className="employeesCardMobile-footer-item-img"
            color={"white"}
          />
          <a
            className="employeesCardMobile-footer-item-text"
            href={`mailto:${email || info?.user?.email}`}
          >
            {email || info?.user?.phone}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default EmployeesCardMobile;
