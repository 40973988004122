import React, { useEffect, useState } from "react";
import { useSelects } from "../../hooks/useSelects";
import Pagination from "../Pagination/Pagination";
import CardRowTask from "../CardRowTask/CardRowTask";
import "./TasksListRow.scss";
import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import TasksRowHeader from "../TasksRowHeader/TasksRowHeader";

// ! TODO есть проблема с запросом на получение задач при переключении chapter
// ! TODO делается двойной запрос, так как в useEffect зависимостью стоит и page и chapter

const TasksListRow = ({ taskInfo, onDelete }) => {
  const { changeModalVisible } = useModal();

  const [visibleHeader, setVisibleHeader] = useState(false);

  let { tasks, count } = useSelector((state) => state.tasks);

  if (taskInfo) {
    tasks = taskInfo.tasks;
    count = taskInfo.count;
  }

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(tasks);

  useEffect(() => {
    if (selectItems.length > 0) {
      setVisibleHeader(true);
    } else {
      setVisibleHeader(false);
    }
  }, [selectItems]);

  return (
    <div className="tasks-row">
      <div className="tasks-row-wrapper">
        <TasksRowHeader
          check={selectItems.length === tasks.length}
          onCheck={addAllItemSelect}
          onDelete={() => changeModalVisible("deleteTask", true, selectItems)}
          visible={selectItems.length === 1}
          selectItems={selectItems}
          tasks={tasks}
          visibleHeader={visibleHeader}
        />

        <ul className="tasks-row-cards">
          {tasks &&
            tasks.map((task) => {
              return (
                <CardRowTask
                  info={task}
                  data={task}
                  key={task._id}
                  onCheck={(taskId) => {
                    addItemSelect(taskId);
                  }}
                  check={(taskId) => selectItems.includes(taskId)}
                  onDelete={onDelete}
                />
              );
            })}
        </ul>
      </div>
      <Pagination count={count} />
    </div>
  );
};

export default TasksListRow;
