import React, { useState, useRef, useEffect } from "react";
import noImage from "../../assets/images/pages/Employees/no-image.svg";
import noAvatar from "../../assets/images/pages/Projects/no-image.jpg";
import "./ImageUpload.scss";
import Button from "../Button/Button";
import Icons from "../Icons/Icons";
import { getFullPath } from "../../utils/getFullPath";
import Screen from "../Screen/Screen";

const ImageUpload = ({
  logo,
  profileAvatar,
  profileLogo,
  profileMobileAvatar,
  edit = true,
  onChange,
}) => {
  const [file, setFile] = useState(null);
  const [fileObj, setFileObj] = useState(null);

  const imageRef = useRef();

  useEffect(() => {
    if (logo && typeof logo === "string" && logo !== "remove") {
      setFile(getFullPath(logo, "avatar"));
    }
  }, [logo]);

  const addImage = (event) => {
    imageRef.current.click();
    event.preventDefault();
    if (!!event.target.files) {
      const file = URL.createObjectURL(event.target.files[0]);
      setFile(file);

      setFileObj(event.target.files[0]);
    }
  };
  const removeImage = (event) => {
    event.preventDefault();
    setFile(null);
    setFileObj("remove");
  };

  useEffect(() => {
    if (onChange) onChange(fileObj);
  }, [fileObj]);

  return (
    <div
      className={`imageUpload ${profileAvatar ? "imageUpload-profileAvatar" : ""} ${
        profileLogo ? "imageUpload-profileLogo" : ""
      } ${profileMobileAvatar ? "imageUpload-profileMobileAvatar" : ""}`}
    >
      {edit ? (
        <input
          className="imageUpload__input"
          type="file"
          onChange={(event) => {
            addImage(event);
            event.target.value = "";
          }}
          id="upload"
          accept="image/*"
          ref={imageRef}
        />
      ) : (
        <div className="imageUpload__input imageUpload__input-img" />
      )}

      <label htmlFor="upload">
        <img
          id="avatar"
          crossOrigin="anonymous"
          src={
            file !== null
              ? file
              : // : profileAvatar || profileMobileAvatar
                // ? noAvatar
                noImage
          }
          className={`imageUpload__image ${!edit ? "imageUpload__image-no-edit" : ""} ${
            profileAvatar && "imageUpload-profileAvatar__image"
          }`}
          alt=""
        />
      </label>
      <label htmlFor="avatar" />
      {edit && (
        <div className={`imageUpload__btns ${profileAvatar && "imageUpload-profileAvatar__btns"}`}>
          <>
            {profileAvatar ? (
              // <div
              //   className="imageUpload-profileAvatar__btn-big"
              //   onClick={(event) => addImage(event)}
              // >
              //   Изменить
              // </div>
              <Button
                className="imageUpload-profileAvatar__btn-big"
                color="blue"
                title="Изменить"
                onClick={(event) => addImage(event)}
              />
            ) : (
              <>
                <Screen size={"lg"}>
                  <div className="imageUpload__btn" onClick={(event) => addImage(event)}>
                    <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
                  </div>
                </Screen>
              </>
            )}
            {file && (
              <div className="imageUpload__btn" onClick={(event) => removeImage(event)}>
                <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};
export default ImageUpload;
