import React from "react";
import { useDispatch } from "react-redux";
import { asyncAddUserToChat, asyncCreateMessage } from "../../../../store/reducers/chatReduser";
import Icons from "../../../Icons/Icons";
import Button from "../../../Button/Button";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import DocItem from "../../../DocItem/DocItem";
import Input from "../../../Input/Input";
import "./Footer.scss";

const Footer = (props) => {
  const { selectItems, forwardMessage, currentChat, addMessage, myId } = props;

  const dispatch = useDispatch();

  const { IV, changeIV } = useChangeIV({
    text: "",
    files: [],
  });

  const isPhoto = (type) => {
    const typesPhoto = ["image/png", "image/svg+xml", "image/jpeg", "image/jpg"];
    if (typesPhoto.includes(type)) return true;
    return false;
  };

  const changeFiles = (e) => {
    const arr = [];
    const fileList = e.target.files;
    for (const key in fileList) {
      if (key === "length" || key === "item") continue;
      arr.push(fileList[key]);
    }
    if (IV.files?.length) {
      return changeIV("set", { name: "files", value: [...IV.files, ...arr] });
    }
    changeIV("set", { name: "files", value: arr });
  };

  const deleteFile = (i) => {
    changeIV("set", {
      name: "files",
      value: IV.files.filter((_, index) => index !== i),
    });
  };

  const isLeaveChat = () => {
    return !!!currentChat.activeUserList.find((u) => u._id === myId);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!IV.text.trim() && !IV.files.length) return;
    const photos = IV.files.filter((d) => isPhoto(d.type));
    const documents = IV.files.filter((d) => !isPhoto(d.type));

    const formData = new FormData();
    formData.append("text", IV.text);
    formData.append("isVoice", false);

    documents.forEach((d) => formData.append("document", d));
    photos.forEach((p) => formData.append("photo", p));

    if (isLeaveChat()) {
      await dispatch(
        asyncAddUserToChat({
          chatId: currentChat._id,
          userIds: [myId],
        }),
      );

      await dispatch(
        asyncCreateMessage({
          chatId: currentChat._id,
          data: {
            text: "Вернулся в чат",
            event: true,
          },
        }),
      );
    }

    const res = await dispatch(
      asyncCreateMessage({
        chatId: currentChat._id,
        data: formData,
      }),
    );

    if (res.error) return;
    changeIV("clear");
    addMessage(res.payload);
  };

  return (
    <form onSubmit={onSubmit} className="windowChatfooter">
      <div className="windowChatfooter-files">
        {!!IV.files?.length && (
          <div className="windowChatfooter-files-list customScroll">
            {IV.files?.map((file, i) => (
              <DocItem
                key={file.name}
                shadow
                index={i}
                event={{
                  remove: (i) => deleteFile(i),
                }}
                info={file}
                btnVisible={{ remove: true }}
              />
            ))}
          </div>
        )}
      </div>
      <div className="windowChatfooter-bottom">
        {/* <Textarea
            maxHeight="90px"
            placeholder="Введите ответ"
            value={IV.text}
            onChange={changeIV}
            name="text"
          /> */}
        <Input placeholder="Введите сообщение" value={IV.text} onChange={changeIV} name="text" />
        <div className="windowChatfooter-bottom-btns">
          <div className="d-flex gap-10 align-center">
            {!!selectItems.length ? (
              <div className="windowChatfooter-forward" onClick={forwardMessage}>
                <Icons type={"forvard"} size={"md"} cursor color={"strokeBlack"} />
                {!!selectItems.length && (
                  <div className="windowChatfooter-forward-count">
                    <span> {selectItems.length}</span>
                  </div>
                )}
              </div>
            ) : (
              <>
                <input onChange={changeFiles} id="file" type="file" multiple />
                <label htmlFor="file">
                  <Icons type={"paperIcon"} size={"md"} cursor />
                </label>
              </>
            )}
            {/* <Icons type={"voiceMicro"} size={"md"} cursor /> */}
          </div>
          <button type="submit" className="windowChatfooter-submit-btn">
            {" "}
            <Icons type={"send"} size={"md"} cursor />{" "}
          </button>
          {/* <Button type="submit" color="blue" title="Отправить" /> */}
        </div>
      </div>
    </form>
  );
};
export default Footer;
