import React from "react";
import "./ModalInfoGroup.scss";
import { getFormatFIO } from "../../utils/getFormatFIO.js";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import { useModal } from "../../hooks/useModal";
import { useSearch } from "../../hooks/useSearch";

const ModalInfoGroup = ({ info, setChapter }) => {
  const { changeModalVisible, getInfo } = useModal();

  const { name, director, profiles, company, description } = info || getInfo("infoGroup", "data");

  const { goToPage } = useSearch();

  return (
    <ModalWrapper
      title={"Информация о группе"}
      onClose={() => changeModalVisible("infoGroup", false)}
    >
      <div className="modalInfoGroup">
        <div className="modalInfoGroup-content modalInfoGroup-content">
          <h4 className="modalInfoGroup-content-title">Основная информация</h4>

          <li className="modalInfoGroup-content-info-item">
            <span className="modalInfoGroup-content-info-title">Название группы:</span>
            <span className="modalInfoGroup-content-info-text">{name}</span>
          </li>
          <li className="modalInfoGroup-content-info-item">
            <span className="modalInfoGroup-content-info-title">Описание:</span>
            <span className="modalInfoGroup-content-info-text">{description || ""}</span>
          </li>
        </div>
        <div className="hr"></div>
        <div className="modalInfoGroup-content">
          <h4 className="modalInfoGroup-content-title">Руководитель</h4>
          <ul className=" modalInfoGroup-content-info">
            <li className="modalInfoGroup-content-info-item">
              <span className="modalInfoGroup-content-info-title">Компания:</span>
              <span className="modalInfoGroup-content-info-text">{company?.name || ""}</span>
            </li>
            <li className="modalInfoGroup-content-info-item">
              <span className="modalInfoGroup-content-info-title">ФИО:</span>
              <span className="modalInfoGroup-content-info-text">
                {getFormatFIO(director?.user)}
              </span>
            </li>
          </ul>
        </div>
        {!!profiles.length && (
          <>
            <div className="hr"></div>
            <div className="modalInfoGroup-content modalInfoGroup-content">
              <ListModalProfile
                profiles={profiles}
                title={"Участники"}
                type={"employeesCompanyPosition"}
                onClickInfo={(info) => {
                  goToPage("userInfo", { userId: info._id });
                  changeModalVisible("infoGroup", false);
                }}
              />
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default ModalInfoGroup;
