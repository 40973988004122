import React from "react";
import Icons from "../../../Icons/Icons";
import "./ModalMobileMenuItem.scss";
import { useModal } from "../../../../hooks/useModal";

const ModalMobileMenuItem = ({ onClick, title, typeIcon }) => {
  return (
    <li className="modalMobileMenuItem" onClick={onClick && onClick}>
      <Icons type={typeIcon ? typeIcon : ""} size={"md"} color={"black"} />
      <span className="modalMobileMenu-item">{title ? title : ""}</span>
    </li>
  );
};

export default ModalMobileMenuItem;
