import React from "react";
import "./Button.scss";
import plusIcon from "../../assets/icons/plus.svg";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";

function Button(props) {
  const {
    disabled,
    title,
    color,
    onClick,
    style,
    icon,
    type = "button",
    loading = false,
    form = null,
    classesPrefix = [],
  } = props;

  return (
    <>
      {type === "submit" ? (
        <>
          <Screen size={"lg"}>
            <button
              form={form}
              type={type}
              style={{
                ...style,
                cursor: disabled ? "no-drop" : "pointer",
                maxHeight: icon ? "34px" : "auto",
                maxWidth: icon?.only ? "40px" : "222px",
                width: icon?.only ? "40px" : "100%",
              }}
              onClick={disabled || loading ? null : onClick}
              disabled={loading || disabled}
              className={`button ${color} ${disabled ? "disabled" : ""} ${classesPrefix.join(" ")}`}
            >
              {loading ? (
                <div className="submit-spinner"></div>
              ) : (
                <>
                  {icon && icon?.position === "left" && (
                    <Icons
                      type={"plus"}
                      size={"lg"}
                      cursor
                      color={"white"}
                      className="button-icon"
                    />
                  )}
                  {!!title && <span className={`${classesPrefix.join(" ")}`}>{title}</span>}
                  {icon && icon?.position === "right" && (
                    <Icons
                      type={"plus"}
                      size={"lg"}
                      cursor
                      color={"white"}
                      className="button-icon"
                    />
                  )}
                  {icon && icon?.type && icon?.only && (
                    <Icons
                      type={icon?.type}
                      size={"md"}
                      cursor
                      className="button-icon"
                      color={"white"}
                    />
                  )}
                </>
              )}
            </button>
          </Screen>
          <Screen size={"lg"} down>
            <button
              form={form}
              type={type}
              style={{
                ...style,
                cursor: disabled || loading ? "no-drop" : "pointer",
                maxHeight: icon ? "40px" : "auto",
                maxWidth: icon?.only ? "40px" : "100%",
                width: icon?.only ? "40px" : "",
              }}
              disabled={loading || disabled}
              onClick={disabled || loading ? null : onClick}
              className={`button-mobile ${color} ${disabled ? "disabled" : ""} ${classesPrefix.join(
                " ",
              )}`}
            >
              {loading ? (
                <div className="submit-spinner"></div>
              ) : (
                <>
                  {icon && icon?.position === "left" && (
                    <Icons type={"plus"} size={"lg"} className="button-icon" color={"white"} />
                  )}
                  {!!title && <span className={`${classesPrefix.join(" ")}`}>{title}</span>}
                  {icon && icon?.position === "right" && (
                    <Icons type={"plus"} size={"lg"} className="button-icon" color={"white"} />
                  )}
                  {icon && icon?.type && icon?.only && (
                    <Icons type={icon?.type} size={"md"} color={"white"} className="button-icon" />
                  )}
                </>
              )}
            </button>
          </Screen>
        </>
      ) : (
        <>
          <Screen size={"lg"}>
            <div
              style={{
                ...style,
                cursor: disabled || loading ? "no-drop" : "pointer",
                maxHeight: icon ? "35px" : "auto",
                width: icon?.only ? "35px" : "100%",
                maxWidth: icon?.only ? "35px" : "222px",
              }}
              onClick={disabled ? null : onClick}
              className={`button ${color} ${disabled ? "disabled" : ""} ${classesPrefix.join(" ")}`}
            >
              {loading ? (
                <div className="submit-spinner"></div>
              ) : (
                <>
                  {icon && icon?.position === "left" && (
                    <Icons
                      type={"plus"}
                      size={"lg"}
                      cursor
                      className="button-icon"
                      color={"white"}
                    />
                  )}
                  {!!title && <span className={`${classesPrefix.join(" ")}`}>{title}</span>}
                  {icon && icon?.position === "right" && (
                    <Icons
                      type={"plus"}
                      size={"lg"}
                      cursor
                      className="button-icon"
                      color={"white"}
                    />
                  )}
                  {icon && icon?.type && (
                    <Icons type={icon?.type} size={"md"} cursor className="button-icon" />
                  )}
                </>
              )}
            </div>
          </Screen>
          <Screen size={"lg"} down>
            <div
              style={{
                ...style,
                cursor: disabled || loading ? "no-drop" : "pointer",
                maxHeight: icon ? "40px" : "auto",
                maxWidth: icon?.only ? "40px" : "100%",
                width: icon?.only ? "40px" : "",
              }}
              onClick={disabled ? null : onClick}
              className={`button-mobile ${color} ${disabled ? "disabled" : ""} ${classesPrefix.join(
                " ",
              )}`}
            >
              {icon && icon?.position === "left" && (
                <Icons type={"plus"} size={"lg"} className="button-icon" />
              )}
              {!!title && <span className={`${classesPrefix.join(" ")}`}>{title}</span>}
              {icon && icon?.position === "right" && (
                <Icons type={"plus"} size={"lg"} className="button-icon" />
              )}
              {icon && icon?.type && (
                <Icons type={icon?.type} size={"md"} cursor className="button-icon" />
              )}
            </div>
          </Screen>
        </>
      )}
    </>
  );
}

export default Button;
