import React from "react";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../../../utils/getFullPath";
import { getBaseUrl } from "../../../../../../utils/getBaseUrl";
import { getFormatDateTime } from "../../../../../../utils/getFormatDateTime";
import DocItem from "../../../../../DocItem/DocItem";
import forwardSelect from "../../../../../../assets/icons/forward-select.svg";
import dayjs from "dayjs";
import "./Message.scss";
import Icons from "../../../../../Icons/Icons";
import { useModal } from "../../../../../../hooks/useModal";

const Message = ({ visibleAvatar, info, addItemSelect, myId, index, selectItems }) => {
  const { changeModalVisible } = useModal();
  return (
    <div
      key={info._id}
      className={`message ${info.creatorUser?._id === myId ? "myMessage" : "memberMessage"}`}
    >
      {visibleAvatar(info, index) && (
        <div
          style={{
            backgroundImage: `url(${getFullPath(info.creatorUser?.avatar, "avatar")})`,
          }}
          className="message-logo"
        />
      )}

      <div
        className={`message-wrapper ${selectItems.includes(info._id) ? "forward" : ""}`}
        onClick={() => addItemSelect(info._id)}
      >
        {visibleAvatar(info, index) && (
          <div className="message-profile">
            <div className="message-profile-info">
              <span className="message-profile-info-title">
                {getFormatFIO(info.creatorUser, "FI")}
              </span>
              {/* <span className="positions">({info.member.position})</span> */}
            </div>
          </div>
        )}

        {!!info.text && (
          <div className={`message-text ${selectItems.includes(info._id) ? "forward" : ""}`}>
            <span>{info.text}</span>
          </div>
        )}

        {(info?.documents ?? []).length > 0 && (
          <div className={`message-files `}>
            <div className="message-files-list">
              {info.documents.map((file) => (
                <DocItem
                  forWhat="chat"
                  btnVisible={{ download: true }}
                  download
                  shadow
                  key={file.name}
                  info={file}
                />
              ))}
            </div>
          </div>
        )}
        {(info?.photos ?? []).length > 0 && (
          <div className="message-photos">
            {info?.photos?.map((img) => (
              <div
                key={img}
                style={{
                  backgroundImage: `url(${getFullPath(img.path, "chat")})`,
                }}
                className="message-photos-item"
                onClick={() =>
                  changeModalVisible("swiperPhoto", true, {
                    list: info?.photos?.map((p) => p.path),
                    initialSlide: index,
                  })
                }
              ></div>
            ))}
          </div>
        )}

        {(info?.forwardedMessages ?? []).length > 0 && (
          <div
            className={`message-forwardedMessages ${
              info.creatorUser?._id === myId ? "myMessage" : "memberMessage"
            }`}
          >
            {info.forwardedMessages.map((forwardMsg, i) => (
              <div
                key={i}
                className={`message-forwardedMessages-wrapper ${
                  selectItems.includes(info._id) ? "forward" : ""
                } `}
              >
                <div className="message-profile">
                  <div className="message-profile-info">
                    <span className="message-profile-info-title">
                      {getFormatFIO(forwardMsg?.creatorUser, "FI")}
                    </span>
                  </div>
                </div>

                {!!forwardMsg.text && (
                  <div
                    className={`message-text ${selectItems.includes(info._id) ? "forward" : ""}`}
                  >
                    <span>{forwardMsg.text}</span>
                  </div>
                )}

                {(forwardMsg.documents ?? []).length > 0 && (
                  <div
                    className={`message-files ${selectItems.includes(info._id) ? "forward" : ""}`}
                  >
                    <div className="message-files-list">
                      {forwardMsg.documents.map((file) => (
                        <DocItem
                          forWhat="chat"
                          btnVisible={{ download: true }}
                          download
                          shadow
                          key={file.name}
                          info={file}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {(forwardMsg?.photos ?? []).length > 0 && (
                  <div className="message-photos">
                    {forwardMsg?.photos?.map((img) => (
                      <div
                        key={img}
                        style={{
                          backgroundImage: `url(${getFullPath(img.path, "chat")})`,
                        }}
                        className="message-photos-item"
                      ></div>
                    ))}
                  </div>
                )}
                <div className="message-forwardedMessages-time">
                  <span>{getFormatDateTime(info.createdAt)}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* {!!info.body.images.length && (
<div
  className={`message-images ${selectItems.includes(info.id) ? "forward" : ""}`}
  onClick={() => addItemSelect(info.id)}
>
  <div className="flex-dir-column gap-10">
    <div className="message-images-list">
      {info.body.images.map((img) => (
        <div
          key={img}
          style={{
            backgroundImage: `url(${img})`,
          }}
          className="message-images-list-item"
        ></div>
      ))}
    </div>
    
  </div>
</div>
)} */}

        {/* {!!info.body.voices.length && (
<div
  className={`message-voices ${selectItems.includes(info.id) ? "forward" : ""}`}
  onClick={() => addItemSelect(info.id)}
>
  <div className="message-voices-list">
    {info.body.voices.map((voice) => (
      <div key={voice.id} className="message-voices-list-item">
        <img src={voicePlay} alt="play" />
        <div
          className="message-voices-list-item-road"
          style={{
            backgroundImage: `url(${voiceRoad})`,
          }}
        ></div>
      </div>
    ))}
  </div>{" "}
  
</div>
)} */}
        <div className="message-time">
          <span>{getFormatDateTime(info.createdAt)}</span>
        </div>
        {!!selectItems.includes(info._id) && (
          <div
            className={`forward-select ${
              info.creatorUser?._id === myId ? "myMessage" : "memberMessage"
            }`}
          >
            <img src={forwardSelect} alt="select" />
          </div>
        )}
        <div className={`forvardIcon `}>
          {" "}
          {!!selectItems.includes(info._id) ? (
            ""
          ) : (
            <Icons type={"forvard"} size={"md"} cursor color={"strokeBlue"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
