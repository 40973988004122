import React, { useEffect } from "react";
import "./Documentation.scss";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import { useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalAddDoc from "../../../components/ModalAddDoc/ModalAddDoc";
import ModalAddToFolder from "../../../components/ModalAddToFolder/ModalAddToFolder";
import ModalAddFolder from "../../../components/ModalAddFolder/ModalAddFolder";
import ModalInfoEditFolder from "../../../components/ModalInfoEditFolder/ModalInfoEditFolder";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import { useModal } from "../../../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { asyncDeleteFile, asyncGetFolder } from "../../../store/reducers/documentationReduser";
import ModalViewDoc from "../../../components/ModalViewDoc/ModalViewDoc";
import DocumentationList from "../../../components/DocumentationList/DocumentationList";
import ProjectsDocumentsList from "./components/ProjectsDocumentsList/ProjectsDocumentsList";
import ModalInfoEditFile from "../../../components/ModalInfoEditFile/ModalInfoEditFile";
import { useSelects } from "../../../hooks/useSelects";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { getTypePages } from "../../../utils/getTypePages";
import { ErrorBoundary } from "../../../ui";

const Documentation = () => {
  const dispatch = useDispatch();

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { folders } = useSelector((state) => state.documentation);
  const { father, documents } = folders;

  const [chapter, setChapter] = useState("projects");
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [headFolder, setHeadFolder] = useState(null);
  const [fatherFolder, setFatherFolder] = useState(null);
  const [viewCards, setViewCards] = useState("rows");

  const [projectInfo, setInfoProject] = useState(null);

  const [folder, setFolder] = useState(null);

  const { search, setQueryParam, queries } = useSearch(`documentation`);

  const { visible, types = [] } = useHasPermissions("documentation", projectInfo);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");

  const changeViewCard = (value) => setViewCards(value);

  const changeWiewChapter = (value) => setChapter(value);

  const changeCurrentFolder = (folder) => setFatherFolder(folder);

  // * получаем папку
  const getFolder = async () => queries.father && search();

  const getFolderById = async () => {
    const folderId = queries.father;

    if (JSON.stringify(folderId) !== "[object Object]") return;
    const res = await dispatch(asyncGetFolder({ folderId }));
    if (res.error) return;

    setFatherFolder(res.payload);
    setHeadFolder(res.payload);

    return res.payload._id;
  };

  const deleteFolder = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteFolder", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteFolder", false, null);
  };

  const deleteDocument = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteDocument", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteDocument", false, null);
  };

  useEffect(() => {
    if (!queries.chapter) return setChapter("projects");

    setChapter(queries.chapter);
  }, [queries.chapter]);

  useEffect(() => {
    if (fatherFolder) return;
    (async () => {
      if (queries.father) {
        await getFolderById();
      } else {
        setFatherFolder(fatherFolder);
        setHeadFolder(fatherFolder);
      }

      setQueryParam({ father });
    })();
  }, [fatherFolder, queries.father]);

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    if (fatherFolder?.main) {
      setHeadFolder(fatherFolder);
    }
  }, [fatherFolder]);

  useEffect(() => {
    setInfoProject(folders?.father?.projectInfo);
  }, [folders]);

  // useEffect(() => {
  //   setActiveSearchNav(type);
  // }, [type]);
  return (
    <ErrorBoundary>
      <div className="documentation">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="documentation-content">
          <div className="documentation-content-filter">
            <div></div> {/* нужен общий коунт */}
            {/* <LeftInfo title={`Всего файлов: ${count || 0}`} /> */}
            <div className="documentation-content-filter-set">
              <AdditionalFilterPanel
                active={chapter}
                changeWiewChapter={changeWiewChapter}
                viewCards={viewCards}
                onViewClick={changeViewCard}
              />
              <Filter disabled={true} />
            </div>
          </div>
          <div className="documentation-content-main customScroll">
            <div className="documentation-searchPanel">
              <SearchPanel
                navigationLeft={{
                  visible: chapter === "project" && (!!fatherFolder || !!queries.father),
                }}
                buttons={{
                  list: father
                    ? [
                        {
                          title: "Папка",
                          icon: true,
                          onClick: () => changeModalVisible("addFolder", true),
                        },
                        {
                          title: "Документ",
                          icon: true,
                          onClick: () => changeModalVisible("addDocument", true, fatherFolder),
                        },
                      ]
                    : [],
                }}
                inputSearch={{
                  visible: !!father,
                  placeholder: "Найти файл или папку по названию",
                }}
                typePages={getTypePages(types)}
              />
            </div>

            <div className="documentation-wrapper">
              <DocumentationList
                viewCards={viewCards}
                setFatherFolder={setFatherFolder}
                fatherFolder={fatherFolder}
                visibleHeader={visibleHeader}
                setVisibleHeader={setVisibleHeader}
                changeCurrentFolder={changeCurrentFolder}
                activeChapter={chapter}
                handleChange={getFolder}
              />
            </div>
          </div>
        </div>
        {/* Добавление документа */}
        {getVisible("addDocument") && (
          <ModalAddDoc
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Добавление папки */}
        {getVisible("addFolder") && (
          <ModalAddFolder
            handleChange={getFolder}
            fatherFolder={fatherFolder}
            headFolder={headFolder}
          />
        )}

        {/* Просмотр/реадктирование папки */}
        {getVisible("infoFolder") && (
          <ModalInfoEditFolder
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}
        {/* Редактирование файла */}
        {getVisible("infoFile") && (
          <ModalInfoEditFile
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}
        {/* Добавление в папку */}
        {getVisible("addToFolder") && (
          <ModalAddToFolder
            headFolder={headFolder}
            fatherFolder={fatherFolder}
            handleChange={getFolder}
          />
        )}

        {/* Удаление документа */}
        {getVisible("deleteDocument") && (
          <ModalDeleteItem
            headerTitle="Удалить документ"
            title="Вы уверены, что хотите удалить этот документ?"
            nameModal="deleteDocument"
            onDelete={deleteDocument}
          />
        )}

        {/* Просмотр документов*/}
        {getVisible("viewDoc") && <ModalViewDoc />}

        {/* Удаление папки */}
        {getVisible("deleteFolder") && (
          <ModalDeleteItem
            headerTitle="Удалить папку"
            title="Вы уверены, что хотите удалить эту папку?"
            nameModal="deleteFolder"
            onDelete={deleteFolder}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Documentation;
