import React, { useEffect } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ProjectWorkScheduleAdd from "../ProjectWorkScheduleAdd/ProjectWorkScheduleAdd";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import "./ModalAddWorkScheldule.scss";
import { useDispatch } from "react-redux";
import { asyncCreateScheduleProductionWorks } from "../../store/reducers/projectsReducer";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { useSelector } from "react-redux";
import { transformForSelect } from "../../utils/transformForSelect";
import { getForSelect } from "../../utils/getForSelect";
import dayjs from "dayjs";

function ModalAddWorkScheldule(props) {
  const { changeModalVisible, access, handleChange } = props;
  const { project } = useSelector((state) => state.projects);
  const { _id: projectId, customers, work_start, work_end } = project;

  const dispatch = useDispatch();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
    contractWorkStart: new Date(work_start),
    contractWorkEnd: new Date(work_end),
    chartWorkStart: new Date(work_start),
    chartWorkEnd: new Date(work_end),
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    customer: {
      options: [],
      value: customers.map(transformForSelect),
    },
    executor: {
      options: [],
      value: null,
    },
  });
  //
  const getCompanies = () => {
    getForSelect.getCompanies({ project: projectId }, (c) => {
      changeOptions("customer", "options", c);
      changeOptions("executor", "options", c);
    });
  };

  const validateDate = () => {
    let validate = false;
    const { contractWorkStart, contractWorkEnd, chartWorkStart, chartWorkEnd } = IV;

    if (dayjs(contractWorkEnd).diff(contractWorkStart, "day") < 0) {
      validate = "Завершение работ по контракту, не может быть раньше начала работ по контракту";
    }
    if (dayjs(chartWorkEnd).diff(chartWorkStart, "day") < 0) {
      validate = "Завершение работ по графику, не может быть раньше начала работ по графику";
    }
    return validate;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validate = validateDate();
    if (validate) return toast.warning(validate);

    const res = await dispatch(
      asyncCreateScheduleProductionWorks({
        name: IV.name,
        start: IV.contractWorkStart,
        end: IV.contractWorkEnd,
        startChart: IV.chartWorkStart,
        endChart: IV.chartWorkEnd,
      }),
    );
    if (res.error) return;
    changeModalVisible("addWorkScheldule", false);
    handleChange && handleChange();
  };

  useEffect(() => getCompanies(), []);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addWorkScheldule", false)}
      title={`Создать график работ`}
    >
      <form onSubmit={onSubmit} className="modalAddWorkScheldule">
        <ProjectWorkScheduleAdd
          add={true}
          checkValidateIV={checkValidateIV}
          checkValidateOptions={checkValidateOptions}
          changeIV={changeIV}
          IV={IV}
          changeOptions={changeOptions}
          options={options}
        />
        <div className="modalAddWorkScheldule-btns">
          <Button color="blue" title="Создать" type="submit" />
        </div>
      </form>
    </ModalWrapper>
  );
}

export default ModalAddWorkScheldule;
