import React, { useEffect, useId, useMemo } from "react";
import "./ModalEditTask.scss";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Button from "../Button/Button";
import EditInfoTask from "./components/EditInfoTask/EditInfoTask";
import EditInfoProject from "../EditInfoProject/EditInfoProject";
import { useModal } from "../../hooks/useModal";
import { useChangeIV } from "../../hooks/useChangeIV";
import { notificationsSelect, statusesSelect } from "../../data/task";
import { useDispatch } from "react-redux";
import {
  asyncCopyTask,
  asyncCreateSubTask,
  asyncCreateTask,
  asyncDelegationTask,
  asyncEditTask,
} from "../../store/reducers/tasksReducer";
import { getValueForOptions } from "./utils/getValueForOptions";
import { getModalName } from "../../utils/getModalName";
import { setData } from "../../store/reducers/modalsReduces";
import { transformFormData } from "./utils/transformFormData";
import { getFullInfoTask } from "./utils/getFullInfoTask";
import SubtaskList from "../SubtaskList/SubtaskList";
import { useSelector } from "react-redux";
import { asyncGetUserProfiles } from "../../store/reducers/userReducer";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useState } from "react";
import { changeColorCoordinates } from "../../utils/layer/changeColorCoordinates";
import Screen from "../Screen/Screen";
import { useDraft } from "../../hooks/useDraft";

function ModalEditTask(props) {
  const {
    getTasks,
    handleChange, // отрабатывает, если что-то добавил/редактировал, принимает в себя mode
    saveData, // сохраняет данные при закрытии модального окна
    hardValues = {}, // фиксированное значение
  } = props;
  const formId = useId();

  const mode = props.mode; // add | edit | copy | subTask | editSubTask | delegation
  const modalName = getModalName(mode);

  const dispatch = useDispatch();

  const { getInfo, changeModalVisible, intermediateSaving } = useModal();

  const infoTask = getInfo(modalName, "data");
  const { task } = useSelector((state) => state.tasks);
  const { userData } = useSelector((state) => state.user);

  const { sizeModal, viewModal } = useSelector((state) => state.settings);

  const [userProfiles, setUserProfiles] = useState([]);
  const [authorCompany, setAuthorCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const [permission, setPermission] = useState({
    assigned: false,
    rejected: false,
    atWork: false,
    overdue: false,
    onVerification: false,
    completed: false,
    select: false,
    edit: false,
  });

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      name: infoTask?.name || "",
      description: infoTask?.description || "",
      isImportant: infoTask?.isImportant || false,
      start:
        mode === "add" || mode === "subTask"
          ? new Date()
          : infoTask?.start
          ? new Date(infoTask.start)
          : new Date(),

      end:
        mode === "add" || mode === "subTask"
          ? infoTask?.end
            ? infoTask?.end
            : null
          : infoTask?.end
          ? new Date(infoTask.end)
          : null,
      coordinates: infoTask?.coordinates || "",

      photos: infoTask?.photos || [],

      notificationEveryDay:
        mode === "add" ? true : infoTask?.notificationEveryDay || !!infoTask?.notifications,
      regularity: infoTask?.regularity,
    }),
    modalName,
  );

  const { options, changeOptions, checkValidateOptions } = intermediateSaving(
    useChangeOptions({
      status: {
        value:
          mode === "copy"
            ? { label: "Назначено", value: "assigned" }
            : getValueForOptions(infoTask, "status") || { label: "Назначено", value: "assigned" },
        options: statusesSelect,
      },
      executors: {
        value: getValueForOptions(infoTask, "executors"),
        options: [],
      },
      observers: {
        value: getValueForOptions(infoTask, "observers"),
        options: [],
      },
      notificationFrequency: {
        value: getValueForOptions(infoTask, "notificationFrequency") || {
          label: "6 часов",
          value: "6hour",
        },
        options: notificationsSelect,
      },
      layers: {
        value: getValueForOptions(infoTask, "layers"),
        options: [],
      },
      author: {
        value: getValueForOptions(infoTask, "author"),
        options: [],
      },
      projects: {
        value: hardValues?.project ? hardValues?.project : getValueForOptions(infoTask, "projects"),
        options: [],
      },
      other: {
        value: { label: "Ещё", value: "other" },
        options: [
          { label: "Копировать задачу", value: "copy" },
          { label: "Делегировать задачу", value: "delegation" },
          { label: "Отложить задачу", value: "putAside" },
          { label: "Удалить задачу", value: "remove" },
        ],
      },
    }),
    modalName,
  );

  const setDataFromDraft = (data) => {
    const newIV = { ...IV };
    const newOptions = { ...options };

    for (const key in data) {
      if (newIV.hasOwnProperty(key)) {
        if (key === "start" || key === "end") {
          newIV[key] = new Date(data[key]);
        } else {
          newIV[key] = data[key];
        }
      } else if (newOptions.hasOwnProperty(key)) {
        changeOptions(key, "set", data[key]);
      }
    }

    changeIV("setAll", newIV);
  };

  const { addDataToDraft, removeDataFromDraft } = useDraft(IV, modalName, {
    onPush: setDataFromDraft,
    hasOptions: true,
    conditionForCheck: mode === "add" || mode === "subTask",
    filterData: {
      fieldNotEqual: {},
      interconnectedFields: {
        notificationFrequency: !!options.notificationFrequency.value,
      },
      withOutField: {
        notificationEveryDay: 1,
        other: 1,
        notificationFrequency: 1,
        start: 1,
        photos: 1,
        status: 1,
      },
    },
  });

  const createSubTasksForCreatedTask = async (tasks, fatherTaskId) => {
    await Promise.all(
      tasks.map((task) => {
        const formData = new FormData();
        formData.append("name", task.name);
        formData.append("author", task.author._id);
        formData.append("description", task.description);
        formData.append("start", mode === "add" ? new Date() : task.start);
        formData.append("end", task.end);
        formData.append("isImportant", task.isImportant);
        formData.append("status", options.status.value.value);
        formData.append("father", fatherTaskId);

        if (task.regularity) formData.append("regularity", JSON.stringify(task.regularity));

        if (task.photos) {
          task.photos.forEach((photo) => formData.append("photo", photo));
        }

        if (task.notifications) formData.append("notifications", task.notifications);

        if (task.coordinates && task.layers) formData.append("coordinates", task.coordinates);

        if (task.projects) formData.append("project", task.project._id);

        if (task.layers) formData.append("layer", task.layer._id);

        formData.append("executors", JSON.stringify(task.executors.map((t) => t._id)));

        formData.append("observers", JSON.stringify(task.observers.map((t) => t._id)));

        return dispatch(asyncCreateSubTask(formData));
      }),
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validateDate = { required: true };
    if (mode === "add") {
      validateDate.noMoreDate = { start: new Date(), end: IV.end };
    }
    if (mode === "edit") {
      validateDate.noMoreDate = { start: IV.start, end: IV.end };
    }
    const arrForValidate = [
      checkValidateIV("name", { required: true, minHeight: 3, maxHeight: 100 }),
      checkValidateIV("end", validateDate),
      checkValidateOptions("status", { required: true }),
      checkValidateOptions("author", { required: true }),
      checkValidateOptions("executors", { requiredArray: true }),
    ];

    if (IV.notificationEveryDay) {
      arrForValidate.push(checkValidateOptions("notificationFrequency", { required: true }));
    }

    if (arrForValidate.some((el) => !el)) return console.log(arrForValidate);

    const formData = new FormData();

    if (IV.photos.filter((p) => typeof p !== "string").length) {
      IV.photos.forEach((photo) => formData.append("photo", photo));
    }

    if (mode === "edit" || mode === "copy" || mode === "delegation") {
      formData.append("photos", JSON.stringify(IV.photos.filter((p) => typeof p === "string")));
    }

    formData.append("name", IV.name);
    formData.append("description", IV.description);
    formData.append("isImportant", IV.isImportant);
    formData.append("start", mode === "add" ? new Date() : IV.start);
    formData.append("end", IV.end || IV.start);

    formData.append("status", options.status.value.value);
    formData.append("author", options.author.value.value);

    if (IV.notificationEveryDay) {
      formData.append("notifications", options.notificationFrequency.value.value || null);
    } else {
      if (mode === "edit") formData.append("notifications", "none");
    }

    if (IV.coordinates && options.layers.value) {
      const coordinates = changeColorCoordinates(IV.coordinates, options.status.value.value);

      formData.append("coordinates", coordinates);
    } else {
      if (mode === "edit") formData.append("coordinates", "none");
    }

    if (options.projects.value) {
      formData.append("project", options.projects.value.value);
    } else {
      if (mode === "edit") formData.append("project", "none");
    }

    if (options.layers.value) {
      formData.append("layer", options.layers.value.value);
    } else {
      if (mode === "edit") formData.append("layer", "none");
    }

    formData.append("executors", JSON.stringify(options.executors.value.map((e) => e.value)));
    formData.append("observers", JSON.stringify(options.observers.value.map((e) => e.value)));

    if (IV.regularity) {
      formData.append("regularity", JSON.stringify(IV.regularity));
    } else {
      if (mode === "edit") formData.append("regularity", "none");
    }

    if (mode === "add" && infoTask?.sub_tasks) {
      formData.append("tasks", JSON.stringify(infoTask.sub_tasks));
    }

    let res = { error: true };
    setLoading(true);
    if (mode === "add") {
      // * в случае, если добавляем задачу в протокол, в модалке добавление/редактирование мероприятия
      if (
        getInfo("addEvent", "data") ||
        getInfo("editEvent", "data") ||
        getInfo("editProtocol", "data")
      ) {
        // return console.log(transformFormData(formData));
        return changeModalVisible("addTask", false, transformFormData(formData));
      } else {
        res = await dispatch(asyncCreateTask(formData));
        if (!res.error) {
          await createSubTasksForCreatedTask(infoTask?.sub_tasks || [], res.payload._id);
        }
      }
    } else if (mode === "edit") {
      // в случае, если перед редактированием была открыта информация об задаче
      res = await dispatch(
        asyncEditTask({
          taskId: infoTask._id,
          data: formData,
        }),
      );
    } else if (mode === "subTask") {
      const idEditModal = getInfo("editTask", "data")?._id;
      if (idEditModal) {
        formData.append("father", idEditModal);
        res = await dispatch(asyncCreateSubTask(formData));
        if (res.error) return setLoading(false);
        return changeModalVisible("addSubTask", false, res.payload);
      } else {
        const idCopuModal = getInfo("copyTask", "data")?._id;
        formData.append("father", idCopuModal);
        return changeModalVisible("addSubTask", false, transformFormData(formData));
      }
    } else if (mode === "copy") {
      console.log("copy", formData);
      // formData.append("status", "assigned");
      res = await dispatch(
        asyncCopyTask({
          taskId: infoTask._id,
          data: formData,
        }),
      );
    } else if (mode === "editSubTask") {
      // в случае, если я редактирую подзадачу находясь в просмотре задачи
      if (getInfo("infoTask", "data")) {
        res = await dispatch(
          asyncEditTask({
            taskId: infoTask._id,
            data: formData,
          }),
        );
      } else {
        if (getInfo("editTask", "data")) {
          res = await dispatch(
            asyncEditTask({
              taskId: infoTask._id,
              data: formData,
            }),
          );
        } else {
          return changeModalVisible(
            "editSubTask",
            false,
            transformFormData(formData, infoTask._id),
          );
        }
      }
    } else if (mode === "delegation") {
      res = await dispatch(
        asyncDelegationTask({
          taskId: infoTask._id,
          data: formData,
        }),
      );
    }

    if (res.error) return setLoading(false);

    if (getTasks) await getTasks();
    if (getInfo("infoTask", "data")) return changeModalVisible(modalName, false, "prev");
    setLoading(false);
    changeModalVisible(modalName, false, saveData ? "prev" : null);

    handleChange && handleChange(mode, res.payload);
    (mode === "add" || mode === "subTask") && removeDataFromDraft();
  };

  const closeModal = async () => {
    addDataToDraft(
      {
        ...IV,
        ...options,
      },
      { setAll: true },
    );

    changeModalVisible(modalName, false);
  };

  const onCheckChangeStatus = () => {
    const { _id: userId } = userData;
    if (!task) return;
    const authorId = task?.author?.user?._id;

    const fromExecutor = task?.executors?.find((e) => e.user._id === userId);

    if (userData?.role === "admin") {
      // console.log("admin");
      return setPermission({
        assigned: true,
        rejected: true,
        atWork: true,
        overdue: true,
        onVerification: true,
        completed: true,
        select: true,
        edit: true,
      });
    } else if (authorId === userId && !fromExecutor) {
      return setPermission({
        assigned: true,
        rejected: true,
        atWork: true,
        overdue: true,
        onVerification: true,
        completed: true,
        select: true,
        edit: true,
      });
    } else {
      return setPermission({
        assigned: false,
        rejected: true,
        atWork: false,
        overdue: false,
        onVerification: true,
        completed: true,
        select: false,
        edit: false,
      });
    }
  };

  useEffect(() => {
    // если задачу удалили
    if (getInfo("deleteTask", "data")) {
      changeModalVisible(modalName, false, null);
    }

    // если добавили рисунки или поинты на слой
    if (getInfo("addPoint", "data")) {
      changeIV("set", {
        name: "coordinates",
        value: getInfo("addPoint", "data").coordinates,
      });
      changeModalVisible("clear", "addPoint");
    }

    // если удалили подзадачу
    if (getInfo("deleteSubTask", "data")) {
      const { _id: taskId } = getInfo("deleteSubTask", "data");
      dispatch(
        setData({
          modalName,
          data: {
            sub_tasks: infoTask.sub_tasks.filter((task) => task._id !== taskId),
          },
        }),
      );
      changeModalVisible("clear", "deleteSubTask");
    }

    // если добавили подзадачу
    if (getInfo("addSubTask", "data") && getInfo("addSubTask", "data")?._id) {
      getFullInfoTask({
        ...getInfo("addSubTask", "data"),
        creator: userData,
      }).then((newTask) => {
        if (!newTask) return;
        dispatch(
          setData({
            modalName,
            data: {
              sub_tasks: [...(infoTask?.sub_tasks || []), newTask],
            },
          }),
        );
        changeModalVisible("clear", "addSubTask");
      });
    }

    // если мы добавляем задачу для протокола, подставляем проект из протокола
    if (getInfo("editProtocol", "data")) {
      changeOptions("projects", "set", {
        value: getInfo("editProtocol", "data").project.value,
        optins: [],
      });
    }

    // если изменили подзадачу в режиме добавление задачи
    if (getInfo("editSubTask", "data") && mode !== "editSubTask") {
      getFullInfoTask({
        ...getInfo("editSubTask", "data"),
        creator: userData,
      }).then((newTask) => {
        if (!newTask) return;
        dispatch(
          setData({
            modalName,
            data: {
              sub_tasks: (infoTask?.sub_tasks || []).map((task) => {
                if (task._id === newTask._id) return newTask;
                return task;
              }),
            },
          }),
        );
        changeModalVisible("clear", "editSubTask");
      });
    }
    if (mode === "edit") {
      onCheckChangeStatus();
    }
  }, []);

  // * получение всех профайлов пользоватетяля
  useEffect(() => {
    (async () => {
      if (!getInfo("editProtocol", "data")) changeOptions("author", "value", null);
      const res = await dispatch(
        asyncGetUserProfiles({
          projects: options.projects.value ? [options.projects.value.value] : null,
        }),
      );
      if (res.payload) {
        console.log("res.payload", res.payload);
        setUserProfiles(res.payload.profiles);
        changeOptions(
          "author",
          "options",
          res.payload.profiles.map((p) => ({
            label: `${getFormatFIO(p.user)} (Компания: ${p.company.name}; Должность: ${
              p.position.name
            })`,
            value: p._id,
          })),
        );
      }
    })();
  }, [options.projects.value]);

  // * получение компании автора для фильтрации исполнителей и наблюдателей
  useEffect(() => {
    if (!options.author.value) return;
    if (options.author.value?.value) changeOptions("author", "value", options.author.value);

    const company = userProfiles.find(
      (profile) => profile._id === options.author.value.value,
    )?.company;
    if (company) setAuthorCompany(company._id);
    else setAuthorCompany(null);
  }, [options.author.value]);

  useEffect(() => {
    if (userData?.role !== "admin" && mode !== "add") {
      if (task?.status === "onVerification" && permission?.onVerification) {
        changeOptions("status", "options", [
          { label: "В работе", value: "atWork" },
          { label: "Выполнено", value: "completed" },
          { label: "На проверке", value: "onVerification" },
        ]);
      }
      if (task?.status === "rejected" && permission?.rejected) {
        changeOptions("status", "options", [
          { label: "Отклонено", value: "rejected" },
          { label: "Назначено", value: "assigned" },
        ]);
      }
    }
  }, [permission]);
  const optionsProject = useMemo(() => options, [options]);

  const showSubTasksList = () => {
    if (getInfo("addEvent", "data")) return false;

    return mode === ("edit" || mode === "add");
  };

  return (
    <ModalWrapper
      title={getModalName(mode, true)}
      onClose={closeModal}
      viewCardVisible={true}
      draftModal={{
        name: modalName,
        onPush: setDataFromDraft,
        onDelete: removeDataFromDraft,
      }}
      btns={
        <div className="ModalEditTask-btnCreate">
          {mode === "add" && <Button type="submit" title="Создать" color="blue" form={formId} />}
          {mode === "subTask" && (
            <Button type="submit" title="Создать" color="blue" form={formId} loading={loading} />
          )}
          {mode === "copy" && (
            <Button type="submit" title="Копировать" color="blue" form={formId} loading={loading} />
          )}
          {mode === "delegation" && (
            <Button
              type="submit"
              title="Делегировать"
              color="blue"
              form={formId}
              loading={loading}
            />
          )}
          {(mode === "edit" || mode === "editSubTask") && (
            <Button type="submit" title="Сохранить" color="blue" form={formId} loading={loading} />
          )}
        </div>
      }
    >
      <div className="ModalEditTask">
        <form onSubmit={onSubmit} className="ModalEditTask-main-form" id={formId}>
          <div
            className={`ModalEditTask-main-edit ${sizeModal ? "fullScreen" : ""} ${
              viewModal ? "rows" : "columns"
            }`}
          >
            <EditInfoTask
              mode={mode}
              IVTask={IV}
              changeIV={changeIV}
              checkValidateIVTask={checkValidateIV}
              optionsTask={options}
              changeOptions={changeOptions}
              checkValidateOptionsTask={checkValidateOptions}
              executors={getValueForOptions(infoTask, "executors")}
              authorCompany={authorCompany}
              hardValues={hardValues}
            />
            <EditInfoProject
              mode={mode}
              IVProject={IV}
              changeIV={changeIV}
              optionsProject={optionsProject}
              changeOptions={changeOptions}
              checkValidateOptionsProject={checkValidateOptions}
              viewModal={viewModal}
              hardValues={hardValues}
            />
          </div>
          {showSubTasksList() && (
            <>
              <div className="hr"></div>
              <SubtaskList mode={mode} fatherId={infoTask?._id} tasks={infoTask?.sub_tasks} />
            </>
          )}
          <Screen size={"lg"}>
            <div className="ModalEditTask-btnCreate">
              {mode === "add" && (
                <Button
                  type="submit"
                  title="Создать"
                  color="blue"
                  form={formId}
                  loading={loading}
                />
              )}
              {mode === "subTask" && (
                <Button
                  type="submit"
                  title="Создать"
                  color="blue"
                  form={formId}
                  loading={loading}
                />
              )}
              {mode === "copy" && (
                <Button
                  type="submit"
                  title="Копировать"
                  color="blue"
                  form={formId}
                  loading={loading}
                />
              )}
              {mode === "delegation" && (
                <Button
                  type="submit"
                  title="Делегировать"
                  color="blue"
                  form={formId}
                  loading={loading}
                />
              )}
              {(mode === "edit" || mode === "editSubTask") && (
                <Button
                  type="submit"
                  title="Сохранить"
                  color="blue"
                  form={formId}
                  loading={loading}
                />
              )}
            </div>
          </Screen>
        </form>
      </div>
    </ModalWrapper>
  );
}

export default ModalEditTask;
