import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./EmployeeProject.scss";
import { asyncGetProjects } from "../../../../../../store/reducers/projectsReducer";
import ProjectsList from "../../../../../../components/ProjectsList/ProjectsList";
import { useState } from "react";
import axios from "../../../../../../axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSearch } from "../../../../../../hooks/useSearch";
import { useSelector } from "react-redux";
import EmptySection from "../../../../../../components/EmptySection/EmptySection";
import SearchPanel from "../../../../../../components/SearchPanel/SearchPanel";

const EmployeeProject = ({ viewCards }) => {
  const { userProjects } = useSelector((state) => state.projects);

  const {} = useSearch("userInfo:projects");

  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userProjects) return;
    setProjects(userProjects.projects);
    setCount(userProjects.count);
  }, [userProjects]);

  return (
    <div className="employeeProject">
      {/* <SearchPanel
        inputSearch={{
          visible: true,
          placeholder: "Найти проект по названию или номеру",
        }}
      /> */}
      {projects.length > 0 ? (
        <ProjectsList projects={projects} count={count} viewCards={viewCards} />
      ) : (
        <EmptySection
          text={"Проектов не создано"}
          // textBtn={"Добавить"}
          // onClick={() => {
          //   changeModalVisible("addProject", true);
          // }}
        />
      )}
    </div>
  );
};

export default EmployeeProject;
