import React, { useState, useEffect } from "react";
import "./Groups.scss";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import { useSelects } from "../../../../../hooks/useSelects";
import { useFilter } from "../../../../../hooks/useFilter";
import { asyncGetGroups } from "../../../../../store/reducers/groupsReducer";
import { useDispatch, useSelector } from "react-redux";
import GroupsRow from "./components/GroupsRow/GroupsRow";
import Pagination from "../../../../../components/Pagination/Pagination";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

const Groups = ({ setGroupLength }) => {
  const { changeModalVisible } = useModal();

  const { groups, count, loading } = useSelector((state) => state.groups);
  const { selectItems, addItemSelect } = useSelects(groups);

  const { add } = useHasPermissions("employees:groups");
  const { params } = useSearch("employees:groups");

  const pageNumber = params.page;

  const getNumber = (i) => i + 1 + groups.length * pageNumber - groups.length;

  useEffect(() => {
    setGroupLength(count);
  }, [groups]);

  return (
    <section className="groups">
      <div className="groups__searchPanel groups-searchPanel">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти по названию группы",
          }}
          buttons={{
            list: add && [
              {
                title: "Создать",
                // icon: true,
                onClick: () => {
                  changeModalVisible("addGroup", true);
                },
              },
            ],
          }}
        />
      </div>
      <div className="groups__content">
        <WrapperLoader loading={loading} chapter={"groups"}>
          {groups.length > 0 ? (
            <div className="groups__row groups-row customScroll">
              <div className="groups-row-wrapper">
                <ul className="groups-row__header groups-row-header">
                  <li className="groups-row-header__item groups-row-header__item-group">
                    <span title="Группа">Группа:</span>
                  </li>
                  <li className="groups-row-header__item groups-row-header__item-manager">
                    <span title="Руководитель">Руководитель:</span>
                  </li>
                  {/* <li className="groups-row-header__item groups-row-header__item-сompany">
                    <span title="Компания">Компания:</span>
                  </li> */}
                  <li className="groups-row-header__item groups-row-header__item-numberEmployees">
                    <span title="Колличество участников">Колличество участников:</span>
                  </li>
                </ul>
                <ul className="groups-row__list groups-row-list">
                  {groups.map((group, index) => (
                    <GroupsRow
                      key={group._id}
                      info={group}
                      number={getNumber(index)}
                      onCheck={() => {
                        addItemSelect(group._id);
                      }}
                      check={selectItems.includes(group._id)}
                    />
                  ))}
                </ul>
              </div>
              <Pagination count={count} />
            </div>
          ) : (
            !loading && (
              <EmptySection
                text={"Группы не созданы"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addGroup", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
    </section>
  );
};
export default Groups;
