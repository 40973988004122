import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = (props) => {
  const {} = props;
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
  }, []);
  return (
    <div className="footer">
      <span className="footer-title">
        {" "}
        © ООО "Дэвин" 2023-2024 г. Использование контента сайта, его кода, механизмов взаимодействия
        с пользователем, идеи и технической реализации продукта без согласия авторов строго
        запрещено.
      </span>
      {/* <div className="footer-date">
        <span>{dayjs(currentDate).format("HH:mm")}</span>
        <span>{dayjs(currentDate).format("DD.MM.YYYY")}</span>
      </div> */}
    </div>
  );
};

export default Footer;
