import React, { useEffect } from "react";
import Input from "../../../Input/Input";
import Select from "../../../Select/Select";
import Textarea from "../../../Textarea/Textarea";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import "./ModalWorkEdit.scss";
import InputDate from "../../../InputDate/InputDate";
import dayjs from "dayjs";
import Button from "../../../Button/Button";
import HistoryChanges from "../../../HistoryChanges/HistoryChanges";
import ListWorks from "../../../ListWorks/ListWorks";
import {
  asyncCreateWork,
  asyncEditWork,
  asyncCompletedScheduleProductionWorks,
} from "../../../../store/reducers/projectsReducer";
import { getForSelect } from "../../../../utils/getForSelect";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { transformForSelect } from "../../../../utils/transformForSelect";
import InputSearch from "../../../InputSearch/InputSearch";
import axios from "../../../../axios/axios";
import { useParams } from "react-router-dom";

function ModalWorkEdit(props) {
  const { info, createMode, scheduleWorksId, getWorksAndSteps, changeModalVisible } = props;

  const dispatch = useDispatch();

  const { projectId } = useParams();

  const { IV, changeIV } = useChangeIV({
    description: info?.description,
    start: info?.start ? new Date(info?.start) : new Date(),
    end: info?.end ? new Date(info?.end) : new Date(),
    reasonForTransfer: info?.reasonForTransfer || [],
    historyChanges: info?.historyChanges || [],
    progress: info?.progress ? info?.progress : 0,
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    scheduleWorks: {
      options: [],
      value: null,
    },
    relationshipTasks: {
      options: [],
      value: [],
    },
    dependenciesTasks: {
      options: [],
      value: [],
    },
    responsible: {
      options: [],
      value: [],
    },
    stages: {
      options: [],
      value: info?.project ? { label: info.project.name, value: info.project._id } : null,
    },
    executor: {
      options: [],
      value: info?.executor ? { label: info.executor.name, value: info.executor._id } : null,
    },
  });

  const changeRelationshipTasks = (task, event = "add") => {
    const { value } = task;
    if (event === "add") {
      const newArr = [...options.relationshipTasks.value, task];
      changeOptions("relationshipTasks", "value", newArr);
      changeOptions(
        "relationshipTasks",
        "options",
        options.relationshipTasks.options.filter((task) => task.value !== value),
      );
    } else {
      const newArr = options.relationshipTasks.value.filter((task) => task.value !== value);
      changeOptions("relationshipTasks", "value", newArr);
      changeOptions("relationshipTasks", "options", [...options.relationshipTasks.options, task]);
    }
  };

  const changeDependenciesTasks = (task, event = "add") => {
    const { value } = task;
    if (event === "add") {
      const newArr = [...options.dependenciesTasks.value, task];
      changeOptions("dependenciesTasks", "value", newArr);
      changeOptions(
        "dependenciesTasks",
        "options",
        options.dependenciesTasks.options.filter((task) => task.value !== value),
      );
    } else {
      const newArr = options.dependenciesTasks.value.filter((task) => task.value !== value);
      changeOptions("dependenciesTasks", "value", newArr);
      changeOptions("dependenciesTasks", "options", [...options.dependenciesTasks.options, task]);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(options);
    const validate = [
      checkValidateOptions("scheduleWorks", { required: true }),
      checkValidateOptions("stages", { required: true }),
    ];

    if (validate.some((el) => !el)) return;

    if (dayjs(IV.end).diff(IV.start, "day") < 0)
      return toast.warning("Дата завершения не может быть раньше даты начала");

    const dependenciesTasks = options.dependenciesTasks.value.map((d) => d.value);
    const relationshipTasks = options.relationshipTasks.value.map((d) => d.value);

    for (let i = 0; i < dependenciesTasks.length; i++) {
      if (relationshipTasks.includes(dependenciesTasks[i]))
        return toast.warning("Одна и та-же работа не может быть зависимой и связанной");
    }

    let res;
    const data = {
      name: options.scheduleWorks.value.label,
      description: IV.description,
      start: IV.start,
      end: IV.end,
      progress: Number(IV.progress) < 0 ? 0 : Number(IV.progress),
      relationshipTasks: options.relationshipTasks.value.map((el) => el.value),
      dependenciesTasks: options.dependenciesTasks.value.map((el) => el.value),
    };
    if (!!options.executor?.value?.value) {
      data.executor = options.executor.value.value;
    }
    if (createMode) {
      data.stageId = options.stages.value.value;
      res = await dispatch(asyncCreateWork(data));
    } else {
      data.workId = info._id;
      data.stage = options.stages.value.value;
      res = await dispatch(asyncEditWork(data));
    }
    if (res.error) return;
    await getWorksAndSteps();
    changeModalVisible("infoWork", false, null);
  };

  const onCompleted = async (e) => {
    e.preventDefault();
    await dispatch(asyncCompletedScheduleProductionWorks(info._id));
  };

  const getWorkName = async () => {
    const workItem = await axios.post(`/scheduleWorks/get_work_by_name/${info?.name}`);
    if (workItem.data && Array.isArray(workItem.data) && workItem.data[0]) {
      const value = workItem.data[0];
      changeOptions("scheduleWorks", "value", transformForSelect(value));
    }
  };

  useEffect(() => {
    const workId = createMode ? null : info.id;
    if (options.stages.value) {
      getForSelect.getStagesAndWorks(
        { scheduleWorksId, stage: options.stages.value.value, type: "task" },
        (stagesAndWorks) => {
          changeOptions(
            "relationshipTasks",
            "options",
            stagesAndWorks.filter((item) => item.value !== workId),
          );
        },
      );

      getForSelect.getStagesAndWorks(
        { scheduleWorksId, stage: options.stages.value.value, type: "task" },
        (stagesAndWorks) => {
          changeOptions(
            "dependenciesTasks",
            "options",
            stagesAndWorks.filter((item) => item.value !== workId),
          );
        },
      );
    }
  }, [options.stages.value]);

  useEffect(() => {
    getWorkName();
    getForSelect.getStagesAndWorks({ scheduleWorksId, type: "project" }, (stagesAndWorks) => {
      changeOptions("stages", "options", stagesAndWorks);
    });
  }, []);

  useEffect(() => {
    if (createMode) {
      changeIV("set", { name: "name", value: "" });
      changeIV("set", { name: "start", value: new Date() });
      changeIV("set", { name: "end", value: new Date() });
    }
  }, [createMode]);

  useEffect(() => {
    if (createMode) return;

    const projectOfWork = options.stages.options.find(
      (s) => s.value === info.project._id || s.value === info.project,
    );
    changeOptions("stages", "value", projectOfWork);
  }, [options.stages.options]);

  useEffect(() => {
    if (createMode) return;
    const { dependenciesTasks, relationshipTasks } = info;
    changeOptions("dependenciesTasks", "value", dependenciesTasks.map(transformForSelect));
    changeOptions("relationshipTasks", "value", relationshipTasks.map(transformForSelect));
    changeOptions("scheduleWorks", "value", { label: info.name, value: info._id });
  }, []);

  return (
    <form onSubmit={onSubmit} className="modalWorkEdit">
      <InputSearch
        forInput
        type="scheduleWorks"
        placeholder="Введите название работы"
        title="Название работы:*"
        handleChange={(data) => changeOptions("scheduleWorks", "value", data)}
        placeholderSelect="Введите название работы"
        value={options.scheduleWorks.value}
        validate={checkValidateOptions("scheduleWorks")}
      />
      <Select
        forInput
        title="Этап:*"
        value={options.stages.value}
        options={options.stages.options}
        handleChange={(value) => changeOptions("stages", "value", value)}
        placeholder="Выберите задачу"
        validate={checkValidateOptions("stages")}
      />
      <div className="modalWorkEdit-row">
        <InputDate
          value={IV.start}
          onChange={(value) => changeIV("set", { name: "start", value })}
          title="Дата начала:"
          withPortal={true}
        />
        <InputDate
          value={IV.end}
          onChange={(value) => changeIV("set", { name: "end", value })}
          title="Дата завершения:"
          withPortal={true}
        />
        <div className="modalWorkEdit-row-item">
          <span className="modalWorkEdit-row-item-title">Кол-во дней:</span>
          <span className="bold">{dayjs(IV.end).diff(IV.start, "day")}</span>
        </div>
        <div className="modalWorkEdit-row-item">
          <span className="modalWorkEdit-row-item-title">Кол-во дней просрочки:</span>
          <span className="bold">
            {dayjs().diff(IV.end, "day") < 0 ? 0 : dayjs().diff(IV.end, "day")}
          </span>
        </div>
        <div className="modalWorkEdit-row-item-inputProcent">
          <Input
            title="Прогресс"
            placeholder="Процент выполнения"
            name="progress"
            type="number"
            value={IV.progress}
            onChange={(e) => {
              if (+e.target.value > 100) return;
              changeIV(e);
            }}
            // validate={checkValidateIV("progress")}
          />
          <span className="procent">%</span>
        </div>
      </div>
      <div className="modalWorkEdit-select-row">
        <div className="flex-dir-column gap-10">
          <Select
            forInput
            title="Зависит от:"
            value={null}
            options={options.dependenciesTasks.options}
            handleChange={(value) => changeDependenciesTasks(value, "add")}
            placeholder="Выберите задачу"
            isDisabled={!options.stages.value}
          />
          {options.dependenciesTasks?.value?.length > 0 && (
            <ListWorks
              works={options.dependenciesTasks.value}
              editMode
              changeRelationshipTasks={changeDependenciesTasks}
            />
          )}
        </div>
        <div className="flex-dir-column gap-10">
          <Select
            forInput
            title="Связвана с:"
            value={null}
            options={options.relationshipTasks.options}
            handleChange={(value) => changeRelationshipTasks(value, "add")}
            placeholder="Выберите задачу"
            isDisabled={!options.stages.value}
          />
          {options.relationshipTasks?.value?.length > 0 && (
            <ListWorks
              works={options.relationshipTasks.value}
              editMode
              changeRelationshipTasks={changeRelationshipTasks}
            />
          )}
        </div>
      </div>
      <div className="modalWorkEdit-select-row">
        <InputSearch
          forInput
          title="Подрядчик:"
          type="company"
          placeholder="Выберите исполнителя"
          handleChange={(data) => {
            changeOptions("executor", "value", data);
          }}
          value={options.executor.value}
          optionsForSearch={{ project: projectId, from_executor: true }}
        />
      </div>

      {!createMode && (
        <Textarea
          placeholder="Введите причину переноса"
          height="170px"
          name="description"
          title="Причина переноса срока:"
          value={IV.description}
          onChange={changeIV}
        />
      )}

      {!createMode &&
        (info?.completed ? (
          <Button onClick={(e) => onCompleted(e)} color="dark" title="Возобновить" />
        ) : (
          <Button onClick={(e) => onCompleted(e)} color="red" title="Завершить" />
        ))}
      <div className="d-flex justify-center">
        <Button type="submit" color="blue" title="Сохранить" />
      </div>
      {!!IV?.historyChanges?.length && <HistoryChanges historyChanges={IV.historyChanges} />}
    </form>
  );
}

export default ModalWorkEdit;
