import React, { useRef } from "react";
import "./AdditionalFilterPanel.scss";
import Icons from "../Icons/Icons";
import ScreenIcon from "./components/ScreenIcon/ScreenIcon";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useSearch } from "../../hooks/useSearch";
import { useState } from "react";
import { useEffect } from "react";

// * страницы при которых нужно отображать кнопку смены вида карточек
const PAGES_THAT_CAN_BE_CHANGED_VIEW = ["projects", "tasks", "documentation"];

function AdditionalFilterPanel(props) {
  const {
    currentPage: currentPageProps,
    active,
    activeChild,
    viewCards,
    onViewClick,
    viewChapterLayers,
    viewChapterCompanies,
    viewProjectWorkSchedule,
    viewChapterParticipants,
    changeWiewChapter,
    changeChapterLayers,
    changeChapterParticipants,
    changeChapterProjectWorkSchedule,
    changeChapterCompanies,
    importFile,
    showImportFile,
    importPdf,
    importExcel,
    visibleNav,
  } = props;

  const [currentPage, setCurrentPage] = useState(currentPageProps);

  const { getCurrentPage } = useSearch();

  const showButtonForChangeViewCard = PAGES_THAT_CAN_BE_CHANGED_VIEW.includes(currentPage);

  const refImportFile = useRef();
  const { setQueryParam, goToPage } = useSearch();

  const changeView = (newView) => {
    if (onViewClick) onViewClick(newView);
    else {
      // setQueryParam({ view: newView });
    }
  };

  const onChangeWiewChapter = (value) => {
    if (changeWiewChapter) changeWiewChapter(value);
    else {
      setQueryParam({ tab: value });
    }
  };

  useOnClickOutside(refImportFile, () => showImportFile(false));

  useEffect(() => {
    if (currentPageProps) return;

    const page = getCurrentPage();

    if (page) setCurrentPage(page);
  }, [currentPageProps]);

  return (
    <div className="additionalFilterPanel">
      {currentPage !== "calendar" && (
        <div className="additionalFilterPanel-content">
          {/* Проекты */}
          {currentPage === "projects" && (
            <>
              <div
                onClick={() => onChangeWiewChapter("all")}
                className={`additionalFilterPanel-item ${active === "all" ? "active" : ""}`}
              >
                <ScreenIcon type={"allProject"} />
                <span>Все</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("creator")}
                className={`additionalFilterPanel-item ${active === "creator" ? "active" : ""}`}
              >
                <ScreenIcon type={"myProject"} />
                <span>Мои проекты</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("member")}
                className={`additionalFilterPanel-item ${active === "member" ? "active" : ""}`}
              >
                <ScreenIcon type={"memberProject"} />
                <span>Участник в проектах</span>
              </div>
            </>
          )}
          {/* Информация о проекте */}
          {currentPage === "project" && (
            <>
              <div
                onClick={() => goToPage("project:basicInformation")}
                className={`additionalFilterPanel-item ${
                  active === "basicInformation" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"infoProject"} />
                <span>Основная информация</span>
              </div>
              <div
                onClick={() => goToPage("project:participants")}
                className={`additionalFilterPanel-item ${
                  active === "participants" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"members"} />
                <span>Участники</span>
              </div>
              {visibleNav && (
                <div
                  onClick={() => goToPage("project:companies")}
                  className={`additionalFilterPanel-item ${active === "companies" ? "active" : ""}`}
                >
                  <ScreenIcon type={"company"} />
                  <span>Компании</span>
                </div>
              )}
              <div
                onClick={() => goToPage("project:tasks")}
                className={`additionalFilterPanel-item ${active === "tasks" ? "active" : ""}`}
              >
                <ScreenIcon type={"tasks"} />
                <span>Задачи</span>
              </div>
              <div
                onClick={() => goToPage("project:layers")}
                className={`additionalFilterPanel-item ${active === "layers" ? "active" : ""}`}
              >
                <ScreenIcon type={"layers"} />
                <span>Слои</span>
              </div>
              <div
                onClick={() => goToPage("project:documentation")}
                className={`additionalFilterPanel-item ${
                  active === "documentation" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"docs"} />
                <span>Документация</span>
              </div>
              <div
                onClick={() => goToPage("project:protocols")}
                className={`additionalFilterPanel-item ${active === "protocols" ? "active" : ""}`}
              >
                <ScreenIcon type={"protocols"} />
                <span>Протокол совещания</span>
              </div>
              <div
                onClick={() => goToPage("project:workSchedule")}
                className={`additionalFilterPanel-item ${
                  active === "workSchedule" || activeChild === "workSchedule" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"graf"} />
                <span>График производства работ</span>
              </div>
              <div
                onClick={() => goToPage("project:photoReports")}
                className={`additionalFilterPanel-item ${
                  active === "photoReports" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"foto"} />
                <span>Фотоотчеты</span>
              </div>
            </>
          )}
          {/* Страница Сотрудники */}
          {currentPage === "employees" && (
            <>
              <div
                onClick={() => goToPage(`employees:structures`)}
                className={`additionalFilterPanel-item ${active === "structures" ? "active" : ""}`}
              >
                <ScreenIcon type={"structure"} />
                <span>Наша структура</span>
              </div>
              <div
                onClick={() => goToPage(`employees:users`)}
                className={`additionalFilterPanel-item ${active === "users" ? "active" : ""}`}
              >
                <ScreenIcon type={"members"} />
                <span>Сотрудники</span>
              </div>
              <div
                onClick={() => goToPage(`employees:groups`)}
                className={`additionalFilterPanel-item ${active === "groups" ? "active" : ""}`}
              >
                <ScreenIcon type={"groups"} />
                <span>Группы</span>
              </div>
              <div
                onClick={() => goToPage(`employees:companies`)}
                className={`additionalFilterPanel-item ${active === "companies" ? "active" : ""}`}
              >
                <ScreenIcon type={"company"} />
                <span>Компании</span>
              </div>
            </>
          )}
          {/* Задачи */}
          {currentPage === "tasks" && (
            <>
              <div
                onClick={() => onChangeWiewChapter("all")}
                className={`additionalFilterPanel-item ${active === "all" ? "active" : ""}`}
              >
                <ScreenIcon type={"all"} />
                <span>Все</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("incoming")}
                className={`additionalFilterPanel-item ${active === "incoming" ? "active" : ""}`}
              >
                <ScreenIcon type={"incoming"} />
                <span>Входящие</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("outgoing")}
                className={`additionalFilterPanel-item ${active === "outgoing" ? "active" : ""}`}
              >
                <ScreenIcon type={"outgoing"} />
                <span>Исходящие</span>
              </div>
            </>
          )}

          {/* Страница с ролями*/}
          {currentPage === "profileAdmin" && (
            <>
              <div
                onClick={() => onChangeWiewChapter("profileAdmin")}
                className={`additionalFilterPanel-item ${
                  active === "profileAdmin" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"admin"} />
                <span>Администратор</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("profileDirector")}
                className={`additionalFilterPanel-item ${
                  active === "profileDirector" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"director"} />
                <span>Директор</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("profileUser")}
                className={`additionalFilterPanel-item ${active === "profileUser" ? "active" : ""}`}
              >
                <ScreenIcon type={"user"} />
                <span>Пользователь</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("profileCustomer")}
                className={`additionalFilterPanel-item ${
                  active === "profileCustomer" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"customer"} />
                <span>Заказчик</span>
              </div>
              <div
                onClick={() => onChangeWiewChapter("profilePerformer")}
                className={`additionalFilterPanel-item ${
                  active === "profilePerformer" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"performer"} />
                <span>Исполнитель</span>
              </div>
            </>
          )}
          {/* Документация */}
          {currentPage === "documentation" && (
            <>
              <div
                onClick={() => goToPage("documentation", {}, { chapter: "projects" })}
                className={`additionalFilterPanel-item ${
                  active === "projects" || active === "project" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"projects"} />
                <span>Документация проектов</span>
              </div>
              <div
                onClick={() => goToPage("documentation", {}, { chapter: "companies" })}
                className={`additionalFilterPanel-item ${
                  active === "companies" || active === "company" ? "active" : ""
                }`}
              >
                <ScreenIcon type={"docAll"} />
                <span>Общая документация</span>
              </div>
            </>
          )}
          {/* Настройки */}
          {currentPage === "settings" && (
            <>
              <div
                onClick={() => goToPage("settings:general")}
                className={`additionalFilterPanel-item ${active === "general" ? "active" : ""}`}
              >
                <ScreenIcon type={"infoItem"} />
                <span>Общие</span>
              </div>
              <div
                onClick={() => goToPage("settings:employees")}
                className={`additionalFilterPanel-item ${active === "employees" ? "active" : ""}`}
              >
                <ScreenIcon type={"employees"} />
                <span>Должности</span>
              </div>
              <div
                onClick={() => goToPage("settings:project")}
                className={`additionalFilterPanel-item ${active === "project" ? "active" : ""}`}
              >
                <ScreenIcon type={"project"} />
                <span>Проекты</span>
              </div>
            </>
          )}
        </div>
      )}

      {/*Сотрудники: Экспорт */}
      {/* {currentPage === "employees" && (
        <div className="additionalFilterPanel-devs">
          {active === "employees" ||
          active === "structures" ||
          active === "companies" ||
          active === "groups" ? (
            <div className="additionalFilterPanel-item">
              <div className="additionalFilterPanel-item-imports">
                <div onClick={showImportFile} className="additionalFilterPanel-item-imports-btn">
                  <Icons
                    type={"download"}
                    size={"xl"}
                    title={{
                      visible: true,
                      text: "",
                    }}
                  />
                  {/* <GearSettings src={GearSettings} alt="import" /> */}
      {/* </div>  */}
      {/* {importFile && (
                  <div ref={refImportFile} className="additionalFilterPanel-item-imports-select">
                    <div onClick={importPdf} className="additionalFilterPanel-item-imports-pdf">
                      <span>Экспорт в PDF</span>
                    </div>
                    <div onClick={importExcel} className="additionalFilterPanel-item-imports-excel">
                      <span>Экспорт в EXCEL</span>
                    </div>
                  </div>
                )} */}
      {/* </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )} */}

      {/* отображение вида карточек */}
      {showButtonForChangeViewCard && (
        <div className="additionalFilterPanel-devs">
          <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
            {viewCards === "columns" ? (
              <Icons
                type={"viewCard"}
                onClick={() => changeView("rows")}
                size={"xl"}
                title={{
                  visible: true,
                  text: "",
                }}
              />
            ) : (
              <Icons
                type={"viewCard"}
                onClick={() => changeView("columns")}
                className="rotate90"
                size={"xl"}
                title={{
                  visible: true,
                  text: "",
                }}
              />
            )}
          </div>
        </div>
      )}

      {/* Колонки / строки*/}
      {currentPage !== "profileAdmin" && (
        <div className="additionalFilterPanel-devs">
          {/* Слои */}
          {active === "layers" && (
            <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
              {viewChapterLayers === "columns" ? (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterLayers}
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              ) : (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterLayers}
                  className="rotate90"
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              )}
            </div>
          )}

          {/* Компании в проекте */}
          {active === "companiesInProject" && (
            <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
              {viewChapterCompanies === "columns" ? (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterCompanies}
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              ) : (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterCompanies}
                  className="rotate90"
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              )}
            </div>
          )}
          {/* Участники */}
          {active === "participants" && (
            <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
              {viewChapterParticipants === "rows" ? (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterParticipants}
                  size={"xl"}
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              ) : (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterParticipants}
                  className="rotate90"
                  size={"xl"}
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              )}
            </div>
          )}
          {currentPage === "project" && active === "documentation" && (
            <div className="additionalFilterPanel-devs">
              <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
                {viewCards === "columns" ? (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("rows")}
                    size={"xl"}
                    title={{
                      visible: true,
                      text: "",
                    }}
                  />
                ) : (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("columns")}
                    className="rotate90"
                    size={"xl"}
                    title={{
                      visible: true,
                      text: "",
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {/* Графки производства работы */}
          {activeChild === "infoWorkSchedule" && (
            <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
              {viewProjectWorkSchedule === "rows" ? (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterProjectWorkSchedule}
                  size={"xl"}
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              ) : (
                <Icons
                  type={"viewCard"}
                  onClick={changeChapterProjectWorkSchedule}
                  className="rotate90"
                  size={"xl"}
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              )}
            </div>
          )}
          {active === "photoReports" && (
            // <div className="additionalFilterPanel-item">
            //   {viewChapterPhoto === "small-grid" && (
            //     <Icons
            //       type={"small-grid"}
            //       onClick={() => changeChapterPhoto("small-grid")}
            //       size={"xl"}
            //       title={{ visible: true, text: "Изменить размер" }}
            //     />
            //   )}
            //   {viewChapterPhoto === "grid" && (
            //     <Icons
            //       type={"grid"}
            //       onClick={() => changeChapterPhoto("grid")}
            //       size={"xl"}
            //       title={{ visible: true, text: "Изменить размер" }}
            //     />
            //   )}
            //   {viewChapterPhoto === "big-grid" && (
            //     <Icons
            //       type={"big-grid"}
            //       onClick={() => changeChapterPhoto("big-grid")}
            //       size={"xl"}
            //       title={{ visible: true, text: "Изменить размер" }}
            //     />
            //   )}
            // </div>
            <div className="additionalFilterPanel-devs">
              <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
                {viewCards === "columns" ? (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("rows")}
                    size={"xl"}
                    title={{
                      visible: true,
                      text: "",
                    }}
                  />
                ) : (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("columns")}
                    className="rotate90"
                    size={"xl"}
                    title={{
                      visible: true,
                      text: "",
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {/* Протокол совещания */}
          {(active === "meetingMinutes" ||
            currentPage === "tasks" ||
            currentPage === "deliveryWork") && (
            // <div className="additionalFilterPanel-item">
            //   <div className="additionalFilterPanel-item-imports">
            //     <div
            //       onClick={showImportFile}
            //       className="additionalFilterPanel-item-imports-btn"
            //     >
            //       <Icons
            //         type={"download"}
            //         size={"xl"}
            //         title={{ visible: true, text: "Экспорт" }}
            //       />
            //       {/* <GearSettings src={GearSettings} alt="import" /> */}
            //     </div>
            //     {importFile && (
            //       <div
            //         ref={refImportFile}
            //         className="additionalFilterPanel-item-imports-select"
            //       >
            //         <div
            //           onClick={importPdf}
            //           className="additionalFilterPanel-item-imports-pdf"
            //         >
            //           <span>Экспорт в PDF</span>
            //         </div>
            //         <div
            //           onClick={importExcel}
            //           className="additionalFilterPanel-item-imports-excel"
            //         >
            //           <span>Экспорт в EXCEL</span>
            //         </div>
            //       </div>
            //     )}
            //   </div>
            // </div>
            <></>
          )}
          {currentPage === "employees" &&
            (active === "structures" || active === "companies" || active === "users" ? (
              <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
                {viewCards === "columns" ? (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("rows")}
                    size={"xl"}
                    title={{ visible: true, text: "" }}
                  />
                ) : (
                  <Icons
                    type={"viewCard"}
                    onClick={() => onViewClick("columns")}
                    className="rotate90"
                    size={"xl"}
                    title={{ visible: true, text: "" }}
                  />
                )}
              </div>
            ) : (
              ""
            ))}
          {currentPage === "calendar" && (
            <div className="additionalFilterPanel-item additionalFilterPanel-item-row-column">
              {viewCards === "columns" ? (
                <Icons
                  type={"viewCard"}
                  onClick={() => onViewClick("rows")}
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              ) : (
                <Icons
                  type={"viewCard"}
                  onClick={() => onViewClick("columns")}
                  className="rotate90"
                  size={"xl"}
                  title={{ visible: true, text: "" }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AdditionalFilterPanel;
