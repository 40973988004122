import React, { useState } from "react";
import "./CompanyTaskList.scss";
import { useSelector } from "react-redux";
import { useSearch } from "../../hooks/useSearch";
import SearchPanel from "../SearchPanel/SearchPanel";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import TasksListRow from "../TaskListRow/TaskListRow";
import ModalTask from "../ModalTask/ModalTask";
import ModalEditTask from "../ModalEditTask/ModalEditTask";
import ModalDelegationTask from "../ModalDelegationTask/ModalDelegationTask";
import ModalPutAside from "../ModalPutAside/ModalPutAside";
import ModalDeleteItem from "../ModalDeleteItem/ModalDeleteItem";
import ModalAddDellEmployees from "../ModalAddDellEmployees/ModalAddDellEmployees";
import ModalSwiperPhoto from "../ModalSwiperPhoto/ModalSwiperPhoto";
import { useModal } from "../../hooks/useModal";

const CompanyTaskList = () => {
  // const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const { getInfo, getVisible, changeModalVisible } = useModal();

  let { tasks, count, loading } = useSelector((state) => state.tasks);
  const { search } = useSearch("company:tasks");
  const getTasks = async () => search();

  const changePage = (page) => {
    setPageNumber(page);
  };

  return (
    <div className="companyTaskList">
      <div className="companyTaskList-search">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти задачу по названию или номеру",
          }}
          buttons={{
            list: [
              {
                title: "Добавить",
                onClick: () => changeModalVisible("addTask"),
                // icon: true,
              },
            ],
          }}
        />
      </div>

      <WrapperLoader loading={loading}>
        {!!tasks.length && (
          <TasksListRow
          // pageNumber={pageNumber}
          // changePage={changePage}
          // taskInfo={{ tasks, count }}
          // getTasks={getTasks}
          />
        )}
      </WrapperLoader>
      {/* Информация задачи */}
      {getVisible("infoTask") && <ModalTask handleChange={() => getUserTask(pageNumber)} />}
      {/* Редактирование задачи */}
      {getVisible("editTask") && (
        <ModalEditTask getTasks={() => getUserTask(pageNumber)} mode={"edit"} />
      )}
      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && (
        <ModalEditTask getTasks={() => getTasks(pageNumber)} mode={"editSubTask"} />
      )}
      {/* Добавление задачи */}
      {getVisible("addTask") && (
        <ModalEditTask getTasks={() => getUserTask(pageNumber)} mode={"add"} />
      )}
      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && (
        <ModalEditTask getTasks={() => getUserTask(pageNumber)} mode={"subTask"} />
      )}
      {/* Копирование задачи */}
      {getVisible("copyTask") && (
        <ModalEditTask getTasks={() => getUserTask(pageNumber)} mode={"copy"} />
      )}
      {/* Делегирование задачи */}
      {getVisible("delegationTask") && (
        <ModalDelegationTask getTasks={() => getUserTask(pageNumber)} />
      )}
      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}
      {/* Просмотр рисунока */}
      {getVisible("showPoint") && <ModalAddPointInLayer show />}
      {/* Отложить задачу */}
      {getVisible("putAsideTask") && <ModalPutAside />}
      {/* Удалить задачу */}
      {getVisible("deleteTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту задачу?"
          headerTitle="Удалить задачу"
        />
      )}
      {/* Удалить подзадачу задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту подзадачу?"
          headerTitle="Удалить подзадачу"
        />
      )}
      {getVisible("addEmployeesTask") && (
        <ModalAddDellEmployees add={true} getTasks={getUserTask(pageNumber)} />
      )}
      {getVisible("delEmployeesTask") && (
        <ModalAddDellEmployees getTasks={getUserTask(pageNumber)} />
      )}
      {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
    </div>
  );
};

export default CompanyTaskList;
