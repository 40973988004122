import React, { useState, useEffect } from "react";
import "./Analytics.scss";
import Filter from "../../../components/Filter/Filter";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import AnalyticsAddChart from "../../../components/AnalyticsAddChart/AnalyticsAddChart";
import AnalyticsInfoChart from "../../../components/AnalyticsInfoChart/AnalyticsInfoChart";
import { ErrorBoundary } from "../../../ui";

const chartsList = [
  { title: "Колонны", icon: "columnsChart" },
  { title: "Полосы", icon: "bandsChart" },
  { title: "Области", icon: "areasChart" },
  {
    title: "Шаговые области",
    icon: "stepAreasChart",
  },
  { title: "Линии", icon: "linesChart" },
  { title: "Пирог", icon: "pieChart" },
  { title: "Пончик", icon: "donutChart" },
  { title: "Список", icon: "listChart" },
];

function Analytics() {
  const [visibleAddedChart, setVisibleAddedChart] = useState(false);
  const [heightChart, setHeightChart] = useState(400);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1700 && width >= 1300) {
      setHeightChart(300);
    } else if (width < 1300 && width >= 1000) {
      setHeightChart(200);
    }
  }, [width]);

  return (
    <ErrorBoundary>
      <div className="analytics">
        <div className="analytics-content">
          <div className="analytics-content-filter">
            {/* <LeftInfo
            onClick={() => setVisibleAddedChart((prev) => !prev)}
            icon={visibleAddedChart ? true : false}
            title={visibleAddedChart ? "" : "Создать график/ диаграмму"}
          /> */}
            {/* <Filter currentPage="analytics" /> */}
          </div>
          {/* <div className="analytics-content-searchPanel">
          <SearchPanel
            inputSearch={{
              visible: true,
              placeholder: "Поиск по аналитике",
            }}
            // buttons={{
            //   list: [
            //     {
            //       title: "График",
            //       onClick: () => setVisibleAddedChart(true),
            //       icon: true,
            //     },
            //   ],
            // }}
          />
        </div> */}

          <div className="analytics-content-main customScroll">
            {/* Аналитика */}
            {/* {!visibleAddedChart && ( */}
            <AnalyticsInfoChart heightChart={heightChart} />
            {/* )} */}

            {/* Создание графика */}
            {/* {visibleAddedChart && (
            <AnalyticsAddChart chartsList={chartsList} heightChart={400} />
          )} */}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Analytics;
