import React from "react";
import Icons from "../../../Icons/Icons";
import "./PlanListCard.scss";
import { useModal } from "../../../../hooks/useModal";
import Screen from "../../../Screen/Screen";

const PlanListCard = ({ active, onclick, info }) => {
  const { name, document_name } = info;
  const { changeModalVisible } = useModal();
  return (
    <div className={`planListCard ${active ? "planListCard-active" : ""}`}>
      <div className="planListCard-name" onClick={onclick}>
        <span title={name} className="planListCard-name-title">
          {name}
        </span>
        {document_name && <span className="planListCard-name-doc">{document_name}</span>}
      </div>

      <Screen size={"lg"}>
        <div className="hrCol"></div>
        <div className="planListCard-btns">
          <div
            onClick={() => changeModalVisible("editLayer", true, info)}
            className="planListCard-btns-elem planListCard-btns-visibleMarks"
          >
            <Icons
              type={"edit"}
              size={"md"}
              color={"blue"}
              title={{
                text: "Редактировать слой",
                visible: false,
              }}
            />
          </div>
          {/* <Icons
        type={"availabilityDoc"}
        size={"md"}
        color={active ? "white" : "blue"}
      /> */}
          <div
            className="planListCard-btns-elem"
            onClick={() => changeModalVisible("tasksLayer", true, info)}
          >
            {" "}
            <Icons
              type={"tasks"}
              size={"md"}
              color={"blue"}
              cursor
              title={{
                text: "Задачи слоя",
                visible: false,
              }}
            />
          </div>

          <div
            onClick={() => changeModalVisible("deleteLayer", true, info)}
            className="planListCard-btns-elem planListCard-btns-remove"
          >
            <Icons
              type={"remove"}
              size={"md"}
              color={"blue"}
              cursor
              title={{
                text: "Удалить слой",
                visible: false,
              }}
            />
          </div>
        </div>
      </Screen>
      <Screen size={"lg"} down>
        <div
          onClick={() => changeModalVisible("menu", true, info)}
          className="planListCard-btns-elem planListCard-btns-visibleMarks"
        >
          <Icons
            type={active ? "menuWhite" : "menu"}
            size={"md"}
            title={{
              text: "Редактировать слой",
              visible: false,
            }}
          />
        </div>
      </Screen>
    </div>
  );
};

export default PlanListCard;
