import axios from "../../../axios/axios";

const getUser = async (userIds) => {
  const res = await axios.post(`/users/get_profile/${userIds}`);

  return res.data;
};

const getUsers = async (userIds) => {
  const resultUsers = await Promise.all(
    userIds.map(async (id) => {
      return await axios.post(`/users/get_profile/${id}`);
    })
  );

  return resultUsers.filter((user) => !!user.data).map((user) => user.data);
};

const getProject = async (projectId) => {
  const project = await axios.post(`/projects/get_project/${projectId}`);
  if (!project.data) return null;
  return project.data;
};

const getLayer = async (layerId) => {
  const layer = await axios.post(`/layers/get_layer/${layerId}`);
  if (!layer.data) return null;
  return layer.data;
};

export const getFullInfoTask = async (task) => {
  try {
    const { executors, observers, project, layer, author } = task;

    const resultTask = { ...task };

    if (executors && Array.isArray(executors)) resultTask.executors = await getUsers(executors);

    if (observers && Array.isArray(observers)) resultTask.observers = await getUsers(observers);

    if (project) resultTask.project = await getProject(project);

    if (layer) resultTask.layer = await getLayer(layer);

    resultTask.author = await getUser(author);

    return resultTask;
  } catch (error) {
    return null;
  }
};
