import React, { useEffect, useState } from "react";
import "./ModalProject.scss";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import dayjs from "dayjs";
import Slider from "../../components/Slider/Slider";
import { useModal } from "../../hooks/useModal";

function ModalProject() {
  const { getInfo, changeModalVisible } = useModal();

  const info = getInfo("infoProject", "data");

  const {
    name,
    description,
    contractNumber,
    customers,
    conclusion_contract,
    roomType,
    contract_start,
    address,
    photos,
    contract_end,
    work_start,
    work_end,
  } = info;

  const [imagesList] = useState(photos);

  let swiper;
  useEffect(() => {
    swiper = document.querySelector(`.swiper`)?.swiper;
  });

  return (
    <ModalWrapper onClose={() => changeModalVisible("infoProject")} title="Информация о проекте">
      <div className="modalProject">
        <div className="modalProject-info">
          {/* Информация */}
          <div className="basicInformation-info-item name">
            <span className="title">Название:</span>
            <span>{name}</span>
          </div>
          <div className="modalProject-info-item description">
            <span className="title">Описание:</span>
            <span className="word-break">{description}</span>
          </div>

          <div className="modalProject-info-specifications">
            <div className="modalProject-info-specifications-block-item">
              <span className="title">Заказчик:</span>
              <span>{customers[0].name}</span>
            </div>
            <div className="modalProject-info-specifications-block-item">
              <span className="title">№ контракта:</span>
              <span className=" word-break">{contractNumber}</span>
            </div>
            <div className="modalProject-info-specifications-block-item">
              <span className="title">Дата заключения контракта:</span>
              <span>{dayjs(conclusion_contract).format("DD.MM.YYYY")}</span>
            </div>
            <div className="modalProject-info-specifications-block-item">
              <span className="title">Тип помещения:</span>
              <span>{roomType}</span>
            </div>

            <div className="modalProject-info-specifications-block-item">
              <span className="title">Начало контракта:</span>
              <span>{dayjs(contract_start).format("DD.MM.YYYY")}</span>
            </div>

            <div className="modalProject-info-specifications-block-item">
              <span className="title">Завершение контракта:</span>
              <span>{dayjs(contract_end).format("DD.MM.YYYY")}</span>
            </div>
            <div className="modalProject-info-specifications-block-item">
              <span className="title">Начало работ:</span>
              <span>{dayjs(work_start).format("DD.MM.YYYY")}</span>
            </div>

            <div className="modalProject-info-specifications-block-item">
              <span className="title">Завершение работ:</span>
              <span>{dayjs(work_end).format("DD.MM.YYYY")}</span>
            </div>
          </div>
          <div className="modalProject-info-specifications-block-item">
            <span className="title">Адрес:</span>
            <span>{address}</span>
          </div>
        </div>
        <div className="modalProject-photos">
          <Slider
            title="Фотографии проекта:"
            images={imagesList}
            // onChange={setimagesList}
            edit={false}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalProject;
