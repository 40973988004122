import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import dayjs from "dayjs";
import logoProdaction from "../../../../assets/images/navbar-logo-roboweb.svg";
import NavbarItemLink from "../../../NavbarItemLink/NavbarItemLink";
import { useDispatch } from "react-redux";
import Icons from "./components/Icons/Icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Screen from "../../../Screen/Screen";
import logoAsg from "../../../../assets/images/asg.jpg";

const availability = {
  admin: [],
  customer: [
    "projects",
    "tasks",
    "calendar",
    "employees",
    "reference",
    "profile",
    "documentation",
    "settings",
  ],
  executor: [],
};

const links = [
  {
    name: "Аналитика",
    path: "analytics",
  },
  {
    name: "Проекты",
    path: "projects",
  },
  {
    name: "Задачи",
    path: "tasks",
  },
  // {
  //   name: "Сдача работ",
  //   path: "deliveryWork",
  // },

  {
    name: "Календарь",
    path: "calendar",
  },
  {
    name: "Сотрудники",
    path: "employees",
  },
  {
    name: "Документы",
    path: "documentation",
  },
  {
    name: "Чат",
    path: "chat",
  },
  {
    name: "Справка",
    path: "reference",
  },
  {
    name: "Профиль",
    path: "profile",
  },
  {
    name: "Настройки",
    path: "settings",
  },
  {
    name: "Выйти",
    path: "exit",
  },
];

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [height, setHeight] = useState("625px");
  const [currentDate, setCurrentDate] = useState(new Date());

  const { company } = useSelector((state) => state.user.userData);

  const [logo, setLogo] = useState(null);

  // const [isShown, setIsShown] = useState(localStorage.getItem("showNavbar") || false);
  const [isShown, setIsShown] = useState(false);
  const { userData } = useSelector((state) => state.user);

  const { unreadMessages } = userData;

  const role = "admin";

  const handleMouseEnter = () => {
    localStorage.setItem("showNavbar", true);
    setIsShown(true);
  };

  const handleMouseLeave = () => {
    localStorage.setItem("showNavbar", false);
    setIsShown(false);
  };

  useEffect(() => {
    setIsShown(false);
  }, []);

  useEffect(() => {
    setLogo(company?.logo);
  }, [company]);

  useEffect(() => {
    setHeight(`calc(${window.innerHeight}px - 195px)`);
  }, []);

  useEffect(() => {
    const e = window.addEventListener("resize", () => {
      setHeight(`calc(${window.innerHeight}px - 195px)`);
    });

    return () => {
      window.removeEventListener("resize", e);
    };
  });

  useEffect(() => {
    setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
  }, []);

  return (
    <div
      className={`navbar ${isShown ? "navbar-active" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`navbar-wrapper ${isShown ? "navbar-active-wrapper" : ""}`}>
        <div className="navbar-wrapper-content">
          <div className="navbar-customeLogo">
            <div className="navbar-customeLogo-plug " onClick={() => navigate("/profile")}>
              {/* <img src={getFullPath(logo, "logo")} alt="navbar-customeLogo-plug-img" /> */}
              <img src={logoAsg} alt="navbar-customeLogo-plug-img" />
            </div>
          </div>
          <div
            style={{
              height: height,
            }}
            className="navbar-links customScroll"
          >
            {links.map((link) => (
              <NavbarItemLink
                availability={availability[role]}
                info={link}
                key={link.name}
                unreadMessages={unreadMessages}
              />
            ))}
          </div>
        </div>
        <div className="navbar-footer">
          {/* <div className="navbar-footer-date">
            <span>{dayjs(currentDate).format("HH:mm")}</span>
            <span>{dayjs(currentDate).format("DD.MM.YYYY")}</span>
          </div> */}

          <div className="navbar-logo">
            <Icons nameIcon={"zaplan"} />
          </div>
          {/* <span className="navbar-footer-police">© 2023 Все права защищены</span> */}
          {/* <hr className="hr-white" /> */}
          {/* <div className="navbar-footer-academyLogo">
            <a
              href="https://roboweb.team"
              className="navbar-footer-academyLogo-logo"
              target="_blank"
            >
              <Screen size={"xxxxl"} down>
                {isShown ? (
                  <img src={logoProdaction} className="navbar-footer-academyLogo-logo-big" alt="" />
                ) : (
                  <img
                    src={logoProdaction}
                    className="navbar-footer-academyLogo-logo-small"
                    alt=""
                  />
                )}{" "}
              </Screen>

              <Screen size={"xxxxl"}>
                <img src={logoProdaction} className="navbar-footer-academyLogo-logo-big" alt="" />
              </Screen>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
