import React, { useState } from "react";
import { getFormatFIO } from "../../../../../../../../utils/getFormatFIO";
import { getBaseUrl } from "../../../../../../../../utils/getBaseUrl";
import Icons from "../../../../../../../Icons/Icons";
import "./StructuresGridCardFooter.scss";
import StructuresGridCardContact from "../StructuresGridCardContact/StructuresGridCardContact";
import { getFullPath } from "../../../../../../../../utils/getFullPath";

const StructuresGridCardFooter = ({ employees }) => {
  const [activeList, setActiveList] = useState(false);
  const baseURL = getBaseUrl();
  return (
    <div className="structuresGridCardFooter">
      <div className="structuresGridCardFooter-header">
        <span className="structuresGridCardFooter-title">Сотрудники:</span>
        <Icons
          type={"arrowDown"}
          size={"xs"}
          cursor
          className={`structuresGridCardFooter-arrow ${
            activeList ? `structuresGridCardFooter-arrow-active` : ""
          }`}
          onClick={() => setActiveList(!activeList)}
          title={{
            visible: true,
            text: activeList ? "Свернуть" : "Развернуть",
          }}
        />
      </div>
      <div className="structuresGridCardFooter-wrapper">
        {activeList ? (
          <>
            <div className="structuresGridCardFooter-list customScroll">
              {employees.map((elem) => {
                return (
                  <StructuresGridCardContact
                    name={elem.user}
                    avatar={elem?.user?.avatar}
                    position={elem?.position?.name}
                    key={elem._id}
                  />
                );
              })}
            </div>
            <span className="structuresGridCardFooter-text">
              Всего сотрудников: {employees.length}
            </span>
          </>
        ) : (
          <div className="structuresGridCardFooter-avatars">
            {employees.length > 0 &&
              employees.slice(0, 4).map((elem) => {
                return (
                  <img
                    crossOrigin="anonymous"
                    className={`structuresGridCardFooter-avatar ${
                      !elem?.user?.avatar
                        ? "structuresGridCardFooter-avatar-border"
                        : ""
                    }`}
                    src={getFullPath(elem?.user?.avatar, "avatar")}
                    alt={elem?.user?.name}
                    key={elem?._id}
                    title={getFormatFIO(elem?.user)}
                  />
                );
              })}
            {employees.length > 4 && (
              <div className="structuresGridCardFooter-other">
                <span>+ {employees.length - 4}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StructuresGridCardFooter;
