import React, { useEffect, useRef } from "react";
import "./CardLayers.scss";
import { useState } from "react";
import { getTasksLayers } from "../../utils/getTasksLayers";
import Icons from "../Icons/Icons";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../../utils/getBaseUrl";
import axios from "../../axios/axios";
import Screen from "../Screen/Screen";
import { useModal } from "../../hooks/useModal";
import CardGridHeader from "../CardGridHeader/CardGridHeader";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

function CardLayers({ number, info, editLayer }) {
  const { changeModalVisible } = useModal();
  const { name, image, document_name, id, tasksStatus } = info;

  const ref = useRef();

  const [menuVisible, setMenuVisible] = useState(false);
  useOnClickOutside(ref, () => setMenuVisible(false));
  const tasksInfo = getTasksLayers(info);
  const navigate = useNavigate();

  const goToPageTask = (statusId) => {
    navigate(`/tasks/?project=${id}&status=${statusId}`);
  };

  const downloadFile = (e) => {
    e.preventDefault();
    axios
      .get(image, { responseType: "blob" })
      .then((data) => {
        let urll = window.URL.createObjectURL(data.data);
        const link = document.createElement("a");
        link.href = urll;
        link.download = info.name;
        document.body.append(link);
        link.style = "display: none";
        link.click();
        link.remove();
        // setTimeout(() => {
        //   window.URL.revokeObjectURL(urll);
        //   document.body.removeChild(link);
        // }, 0);
        URL.revokeObjectURL(link);
      })
      .catch(console.error);
  };
  return (
    <div className="cardLayers">
      <div className="cardLayers-header">
        <Screen size={"lg"}>
          <CardGridHeader
            title={"Слой"}
            text={`№ ${number}`}
            onClick={() => setMenuVisible((prev) => !prev)}
          />
          <>
            {menuVisible && (
              <div className="cardLayers-header-events" ref={ref}>
                <span onClick={() => editLayer(info._id)}>Редактировать слой</span>
                <span onClick={() => changeModalVisible("tasksLayer", true, info)}>
                  Задачи слоя
                </span>
                <span onClick={() => changeModalVisible("deleteLayer", true, info)}>
                  Удалить слой
                </span>
              </div>
            )}
          </>
        </Screen>
        <Screen size={"lg"} down>
          <CardGridHeader
            title={"Слой"}
            text={`№ ${number}`}
            onClick={() => changeModalVisible("menu", true, info)}
          />
        </Screen>
      </div>
      <div
        style={{ backgroundImage: `url(${getBaseUrl() + image})` }}
        className="cardLayers-plan"
      ></div>
      <div className="cardLayers-info">
        <span className="cardLayers-info-name">{name}</span>
        <div className="cardLayers-info-doc">
          <span className="cardLayers-info-doc-name">{document_name}</span>
          <Icons
            onClick={downloadFile}
            type={"download"}
            size={"md"}
            cursor
            title={{
              visible: true,
              text: "",
            }}
          />
        </div>
        <div className="cardLayers-info-tasks">
          <div className="cardLayers-info-tasks-desc">
            <span className="cardLayers-info-tasks-desc-title">Задачи слоя:</span>
            {/* <div
              className="cardLayers-info-tasks-desc-li overdue"
              // onClick={() => goToPageTask(4)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                Просрочено: {tasksStatus.overdue || 0}
              </span>
            </div> */}
            <div
              className="cardLayers-info-tasks-desc-li onVerification"
              // onClick={() => goToPageTask(3)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                На проверке: {tasksStatus.onVerification || 0}
              </span>
            </div>
            <div
              className="cardLayers-info-tasks-desc-li atWork"
              // onClick={() => goToPageTask(2)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                В работе: {tasksStatus.atWork || 0}
              </span>
            </div>
            <div
              className="cardLayers-info-tasks-desc-li completed"
              // onClick={() => goToPageTask(1)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                Выполнено: {tasksStatus.completedTasks || 0}
              </span>
            </div>

            <div
              className="cardLayers-info-tasks-desc-li rejected"
              // onClick={() => goToPageTask(5)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                Отклонено: {tasksStatus.rejected || 0}
              </span>
            </div>
            <div
              className="cardLayers-info-tasks-desc-li assigned"
              // onClick={() => goToPageTask(6)}
            >
              <div className="cardLayers-info-tasks-desc-li-circle"></div>
              <span className="cardLayers-info-tasks-desc-li-title">
                Назначенно: {tasksStatus.assigned || 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardLayers;
