import { toast } from "react-toastify";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOptionsFilters } from "../../utils/getOptionsFilters";
import axios from "../../axios/axios";
import { getLimitElements } from "../../utils/getLimitElements";
import { getLimitDocElements } from "../../utils/getLimitDocElements";

/* eslint-disable consistent-return */
/* eslint-disable camelcase */
export const asyncGetProjects = createAsyncThunk(
  "projects/asyncGetProjects",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitElements() || 10;
      const res = await axios.post(
        `/projects/get_projects/${data?.page || 1}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetUserProjects = createAsyncThunk(
  "projects/asyncGetUserProjects",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitElements() || 10;
      const res = await axios.post(
        `/projects/get_for_user/${data?.queries.userId}/${data.page}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProject = createAsyncThunk(
  "projects/asyncGetProject",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/projects/get_project/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateProject = createAsyncThunk(
  "projects/asyncCreateProject",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/projects/create_project`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditProject = createAsyncThunk(
  "projects/asyncEditProject",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const { formData, project_id } = data;
      const res = await axios.patch(`projects/change_project/${project_id}`, formData);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteProject = createAsyncThunk(
  "projects/asyncDeleteProject",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`projects/delete_project/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProjectEmployees = createAsyncThunk(
  "projects/asyncGetProjectEmployees",
  async (data, helpers) => {
    const { rejectWithValue, getState } = helpers;
    const projectId = getState().projects.project._id;
    const limit = data?.params?.limit || getLimitElements();
    try {
      const res = await axios.post(
        `/projects/get_project_employees/${projectId}/${data.page || 1}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteEmployeesFromProject = createAsyncThunk(
  "projects/asyncDeleteEmployeesFromProject",
  async (profile_ids, { getState, rejectWithValue }) => {
    const { _id: project_id } = getState().projects.project;
    try {
      const res = await axios.delete(`/projects/delete_employee/${project_id}`, {
        data: { profile_ids },
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncAddEmployeesToProject = createAsyncThunk(
  "projects/asyncAddEmployeesToProject",
  async (profile_ids, { getState, rejectWithValue }) => {
    const { _id: project_id } = getState().projects.project;
    try {
      const res = await axios.post(`/projects/add_employee/${project_id}`, {
        profile_ids,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProjectCompanies = createAsyncThunk(
  "projects/asyncGetProjectCompanies",
  async (data, helpers) => {
    const { rejectWithValue, getState } = helpers;

    const projectId = getState().projects.project._id;
    try {
      const limit = data?.params?.limit || getLimitElements();
      const res = await axios.post(
        `/projects/get_project_companies/${projectId}/${data.page || 1}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProjectCompaniesForAdd = createAsyncThunk(
  "projects/asyncGetProjectCompaniesForAdd",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;

    try {
      const res = await axios.post(
        `/projects/get_companies_for_add/${data.projectId}/${data.numberPage}`,
        { companyName: data.companyName },
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProjectAllCompanies = createAsyncThunk(
  "projects/asyncGetProjectAllCompanies",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    const { project_id } = data;
    try {
      const res = await axios.post(`/projects/get_all_companies/${project_id}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteCompaniesFromProject = createAsyncThunk(
  "projects/asyncDeleteCompaniesFromProject",
  async (data, { getState, rejectWithValue }) => {
    const company_ids = data;
    const { _id: project_id } = getState().projects.project;
    try {
      const res = await axios.delete(`/projects/delete_company/${project_id}`, {
        data: { company_ids },
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncAddCompaniesToProject = createAsyncThunk(
  "projects/asyncAddCompaniesToProject",
  async (data, { getState, rejectWithValue }) => {
    const company_ids = data;
    const { _id: project_id } = getState().projects.project;
    try {
      const res = await axios.post(`/projects/add_company/${project_id}`, {
        company_ids,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetLayers = createAsyncThunk("projects/asyncGetLayers", async (data, heplers) => {
  const { rejectWithValue, getState } = heplers;
  try {
    const projectId = getState().projects.project._id;
    const limit = data?.params?.limit || getLimitElements() || 10;
    const res = await axios.post(`/layers/get/${projectId}/${data.page}`, data.params, {
      params: { limit },
    });
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateLayers = createAsyncThunk(
  "projects/asyncCreateLayers",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { formData, project_id } = data;
      const res = await axios.post(`/layers/create/${project_id}`, formData);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditLayers = createAsyncThunk(
  "projects/asyncEditLayers",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { formData, layer_id } = data;
      const res = await axios.patch(`/layers/update/${layer_id}`, formData);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteLayers = createAsyncThunk(
  "projects/asyncDeleteLayers",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { layer_id } = data;
      const res = await axios.delete(`/layers/delete/${layer_id}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetFolders = createAsyncThunk(
  "projects/asyncGetFolders",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    const limit = data?.params?.limit || getLimitDocElements();
    try {
      const res = await axios.post(
        `/documents/get_folders/${data.page || 1}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateFolders = createAsyncThunk(
  "projects/asyncCreateFolders",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/create/`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateDocuments = createAsyncThunk(
  "projects/asyncCreateDocuments",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/create_document/`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteDocuments = createAsyncThunk(
  "projects/asyncDeleteDocuments",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/documents/delete_document/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteFolder = createAsyncThunk(
  "projects/asyncDeleteFolder",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/documents/delete_folder/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditFolder = createAsyncThunk(
  "projects/asyncEditFolder",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { folder_id } = data;
      const res = await axios.patch(`/documents/edit_folder/${folder_id}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncTransportFolder = createAsyncThunk(
  "projects/asyncTransportFolder",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/transport_document/`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCopyFolder = createAsyncThunk(
  "projects/asyncCopyFolder",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/copy_document/`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProjectTasks = createAsyncThunk(
  "projects/asyncGetProjectTasks",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitElements() || 10;
      const res = await axios.post(`tasks/get/${data?.page || 1}/?limit=${limit}`, data.params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetProtocols = createAsyncThunk(
  "projects/asyncGetProtocols",
  async (data, heplers) => {
    const { rejectWithValue, getState } = heplers;
    try {
      const { _id: project_id } = getState().projects.project;
      const limit = data?.params?.limit || getLimitElements() || 10;
      const res = await axios.post(
        `/calendar/protocols/${project_id}/${data.page || 1}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteProtocols = createAsyncThunk(
  "projects/asyncDeleteProtocolss",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/calendar/delete_protocol/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// TODO убрать и использовать reducers/calendar
export const asyncEditProtocols = createAsyncThunk(
  "projects/asyncEditProtocols",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/calendar/update_protocol/${data.protocol_id}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** получение графиков */
/**
 * queries @param {
 * name?: string;
 * period?: Date[] ([DateStart, DateEnd])
 * }
 */
export const asyncGetScheduleProductionWorks = createAsyncThunk(
  "projects/asyncGetScheduleProductionWorks",
  async (data, heplers) => {
    const { rejectWithValue, getState } = heplers;
    try {
      const projectId = getState().projects.project._id;
      const limit = data?.params?.limit || getLimitElements() || 10;
      const res = await axios.post(
        `/scheduleWorks/get/${projectId}/${data.page}/?limit=${limit}`,
        data.params,
      );
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** создание графика */
/**
 * @param {
 * name: string
 * start: Date
 * end: Date
 * startChart: Date
 * endChart: Date
 * }
 */
export const asyncCreateScheduleProductionWorks = createAsyncThunk(
  "projects/asyncCreateScheduleProductionWorks",
  async (data, heplers) => {
    const { rejectWithValue, getState } = heplers;
    const { _id: project_id } = getState().projects.project;
    try {
      const res = await axios.post(`/scheduleWorks/create/${project_id}`, {
        ...data,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** редактивароние графика */
/**
 * @param {
 * name?: string
 * start?: Date
 * end?: Date
 * startChart?: Date
 * endChart?: Date
 * }
 */
export const asyncEditScheduleProductionWorks = createAsyncThunk(
  "projects/asyncEditScheduleProductionWorks",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    const { scheduleWorks_id } = data;
    try {
      const res = await axios.patch(`/scheduleWorks/update/${scheduleWorks_id}`, {
        ...data,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
// удаление графика
export const asyncDeleteScheduleProductionWorks = createAsyncThunk(
  "projects/asyncDeleteScheduleProductionWorks",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/scheduleWorks/remove/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCompletedScheduleProductionWorks = createAsyncThunk(
  "projects/asyncCompletedScheduleProductionWorks",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/scheduleWorks/completed_work/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** получение этапов */
export const asyncGetWorksAndSteps = createAsyncThunk(
  "projects/asyncGetWorksAndSteps",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/scheduleWorks/get_stages/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** получение этапа */
export const asyncGetStage = createAsyncThunk("projects/asyncGetStage", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.post(`/scheduleWorks/get_stage/${data}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** получение работы */
export const asyncGetWork = createAsyncThunk("projects/asyncGetWork", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.post(`/scheduleWorks/get_work/${data}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** создание этапа */
/**
 * @param {
 * name: string
 * start: Date
 * end: Date
 * checkingDdates?: boolean (default: false)
 * }
 */
export const asyncCreateStage = createAsyncThunk(
  "projects/asyncCreateStage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/scheduleWorks/create_stage/${data.scheduleWorksId}`, {
        ...data,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** редактирование этапа */
/**
 * @param {
 * name?: string
 * start?: Date
 * end?: Date
 * checkingDdates?: boolean (default: false)
 * description?: string
 * }
 */
export const asyncEditStage = createAsyncThunk("projects/asyncEditStage", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.patch(`/scheduleWorks/edit_stage/${data.stageId}`, { ...data });
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** удаление этапа */
export const asyncDeleteStage = createAsyncThunk(
  "projects/asyncDeleteStage",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/scheduleWorks/delete_stage/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** создание работы */
/**
 * @param {
 * name: string
 * start: Date
 * end: Date
 * executor: string (id)
 * progress: number
 * relationshipTasks: string[] (id[])
 * dependenciesTasks: string[] (id[])
 * }
 */
export const asyncCreateWork = createAsyncThunk(
  "projects/asyncCreateWork",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/scheduleWorks/create_work/${data.stageId}`, { ...data });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** редактирование работы */
/**
 * @param {
 * name?: string
 * start?: Date
 * end?: Date
 * executor?: string (id)
 * progress?: number
 * relationshipTasks?: string[] (id[])
 * dependenciesTasks?: string[] (id[])
 * }
 */
export const asyncEditWork = createAsyncThunk("projects/asyncEditWork", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.patch(`/scheduleWorks/edit_work/${data.workId}`, {
      ...data,
    });
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** удаление работы */
export const asyncDeleteWork = createAsyncThunk(
  "projects/asyncDeleteWork",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/scheduleWorks/delete_work/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** редактирование работы */
/**
 * @param {
 * type: string (work | stage)
 * fromIndex: number,
 * toIndex: number,
 * }
 */
export const asyncMoveStageAndWork = createAsyncThunk(
  "projects/asyncMoveStageAndWork",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/scheduleWorks/move/${data.scheduleWorkId}`, {
        ...data,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const projectsReducer = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    count: 0,

    project: null,
    projectEmployees: null,
    projectCompanies: null,
    projectLayers: null,
    projectDolders: null,
    projectMeetingMinutes: null,
    projectScheduleProductionWorks: null,
    projectPhotos: null,
    projectTasks: null,

    userProjects: null,

    edit: false,
    loading: false,
    error: false,
  },
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload;
    },
    // ! ОТКАЗЫВАЕМСЯ
    setEditProjectMode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetProjects.fulfilled]: (state, action) => {
      state.projects = action.payload.projects;
      state.count = action.payload.count;
    },
    [asyncGetProjects.rejected]: "Не удалось получить проекты",

    [asyncGetProject.fulfilled]: (state, { payload }) => (state.project = payload),
    [asyncGetProject.rejected]: "Не удалось получить проект",

    [asyncCreateProject.fulfilled]: "Проект создан",
    [asyncCreateProject.rejected]: "Не удалось создать проект.",

    [asyncEditProject.fulfilled]: "Проект изменен",
    [asyncEditProject.rejected]: "Не удалось изменить проект.",

    [asyncDeleteProject.fulfilled]: "Проект удален",
    [asyncDeleteProject.rejected]: "Не удалось удалить проект",

    [asyncGetUserProjects.fulfilled]: (state, { payload }) => (state.userProjects = payload),
    [asyncGetUserProjects.rejected]: "Не удалось получить проекты пользователя",

    [asyncGetProjectEmployees.fulfilled]: (state, { payload }) =>
      (state.projectEmployees = payload),
    [asyncGetProjectEmployees.rejected]: "Не удалось получить участников проекта",

    [asyncDeleteEmployeesFromProject.fulfilled]: "Сотрудник удален",
    [asyncDeleteEmployeesFromProject.rejected]: "Не удалось удалить сотрудника.",

    [asyncAddEmployeesToProject.fulfilled]: "Сотрудник добавлен",
    [asyncAddEmployeesToProject.rejected]: "Не удалось добавить сотрудника.",

    [asyncGetProjectCompanies.fulfilled]: (state, { payload }) =>
      (state.project_companies = payload),
    [asyncGetProjectCompanies.rejected]: "Не удалось получить компании проекта",

    [asyncGetProjectCompaniesForAdd.rejected]:
      "Не удалось получить компании для добавления в проект",

    [asyncDeleteCompaniesFromProject.fulfilled]: "Компания удалена",
    [asyncDeleteCompaniesFromProject.rejected]: "Не удалось удалить компанию.",

    [asyncAddCompaniesToProject.fulfilled]: "Компания добавлена",
    [asyncAddCompaniesToProject.rejected]: "Не удалось добавить компанию.",

    [asyncGetLayers.fulfilled]: (state, { payload }) => (state.project_layers = payload),
    [asyncGetLayers.rejected]: "Не удалось получить слои проекта",

    [asyncCreateLayers.fulfilled]: "Слой создан",
    [asyncCreateLayers.rejected]: "Не удалось создать слой",

    [asyncDeleteLayers.fulfilled]: "Слой удален",
    [asyncDeleteLayers.rejected]: "Не удалось удалить слой",

    [asyncEditLayers.fulfilled]: "Слой изменен",
    [asyncEditLayers.rejected]: "Не удалось изменить слой",

    [asyncGetFolders.fulfilled]: (state, { payload }) => (state.project_folders = payload),
    [asyncGetFolders.rejected]: "Не удалось получить папки",

    [asyncCreateFolders.fulfilled]: "Папка добавлена",
    [asyncCreateFolders.rejected]: "Не удалось создать папку",

    [asyncCreateDocuments.fulfilled]: "Документ добавлена",
    [asyncCreateDocuments.rejected]: "Не удалось создать документ",

    [asyncDeleteDocuments.fulfilled]: "Документ удален",
    [asyncDeleteDocuments.rejected]: "Не удалось удалить документ",

    [asyncDeleteFolder.fulfilled]: "Папка удалена",
    [asyncDeleteFolder.rejected]: "Не удалось удалить папку",

    [asyncEditFolder.fulfilled]: "Папка изменена",
    [asyncEditFolder.rejected]: "Не удалось изменить папку",

    [asyncTransportFolder.fulfilled]: "Документ перемещен",
    [asyncTransportFolder.rejected]: "Не удалось переместить документ",

    [asyncCopyFolder.fulfilled]: "Документ скопирован",
    [asyncCopyFolder.rejected]: "Не удалось скопировать документ",

    [asyncGetProjectTasks.fulfilled]: (state, action) => (state.projectTasks = action.payload),
    [asyncGetProjectTasks.rejected]: "Не удалось получить задачи проекта",

    [asyncGetProtocols.fulfilled]: (state, action) =>
      (state.project_meetingMinutes = action.payload),
    [asyncGetProtocols.rejected]: "Не удалось получить протоколы",

    [asyncDeleteProtocols.fulfilled]: "Протокол удален",
    [asyncDeleteProtocols.rejected]: "Не удалось удалить протокол",

    [asyncEditProtocols.fulfilled]: "Протокол изменен",
    [asyncEditProtocols.rejected]: "Не удалось изменить протокол",

    [asyncGetScheduleProductionWorks.fulfilled]: (state, { payload }) =>
      (state.project_scheduleProductionWorks = payload),
    [asyncGetScheduleProductionWorks.rejected]: "Не удалось получить графики работ",

    [asyncDeleteScheduleProductionWorks.fulfilled]: "График производства удален",
    [asyncDeleteScheduleProductionWorks.rejected]: "Не удалось удалить график производства",

    [asyncEditScheduleProductionWorks.fulfilled]: "График изменен",
    [asyncEditScheduleProductionWorks.rejected]: "Не удалось изменить график",

    [asyncGetWorksAndSteps.rejected]: "Не удалось получить список этапов и работ",

    [asyncCompletedScheduleProductionWorks.fulfilled]: "Работа изменена",
    [asyncCompletedScheduleProductionWorks.rejected]: "Не удалось изменить работу",

    [asyncCreateStage.fulfilled]: "Этап добавлен",
    [asyncCreateStage.rejected]: "Не удалось добавить этап",

    [asyncEditStage.fulfilled]: "Этап изменен",
    [asyncEditStage.rejected]: "Не удалось изменить этап",

    [asyncDeleteStage.fulfilled]: "Этап удален",
    [asyncDeleteStage.rejected]: "Не удалось удалить этап",

    [asyncCreateWork.fulfilled]: "Работа добавлена",
    [asyncCreateWork.rejected]: "Не удалось добавить работу",

    [asyncEditWork.fulfilled]: "Работа изменена",
    [asyncEditWork.rejected]: "Не удалось изменить работу",

    [asyncDeleteWork.fulfilled]: "Работу удалена",
    [asyncDeleteWork.rejected]: "Не удалось удалить работу",

    [asyncGetStage.rejected]: "Не удалось получить этап",

    [asyncGetWork.rejected]: "Не удалось получить работу",

    [asyncCreateScheduleProductionWorks.fulfilled]: "График добавлен",
    [asyncCreateScheduleProductionWorks.rejected]: "Не удалось добавить график",

    [asyncMoveStageAndWork.rejected]: "Не удалось сменить позицию работы",
  }),
});

export const { setProject, setEditProjectMode } = projectsReducer.actions;

export default projectsReducer.reducer;
