import React from "react";
import "./ModalWrapper.scss";
import ModalWrapperDesktop from "../ModalWrapperDesktop/ModalWrapperDesktop";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import Screen from "../Screen/Screen";
import { ErrorBoundary } from "../../ui";

const ModalWrapper = ({
  onClose,
  title,
  children,
  className,
  width,
  noscroll,
  minHeight,
  height,
  editBtn,
  visibleSize,
  btns,
  viewCardVisible,
  draftModal,
}) => {
  return (
    <>
      {/* {createPortal(
        <> */}

      <Screen size={"lg"}>
        <ModalWrapperDesktop
          title={title}
          onClose={onClose}
          className={className}
          width={width}
          noscroll={noscroll}
          minHeight={minHeight}
          editBtn={editBtn}
          visibleSize={visibleSize}
          viewCardVisible={viewCardVisible}
          draftModal={draftModal}
        >
          {children}
        </ModalWrapperDesktop>
      </Screen>
      <Screen size={"lg"} down>
        <ModalWrapperMobile
          title={title}
          onClose={onClose}
          className={className}
          width={width}
          noscroll={noscroll}
          minHeight={minHeight}
          heightContent={height}
          btns={btns}
          editBtn={editBtn}
          draftModal={draftModal}
        >
          {children}
        </ModalWrapperMobile>
      </Screen>
      {/* </>,
        portalContainer
      )} */}
    </>
  );
};

export default ModalWrapper;
