import React from "react";

const ModalEditCompanyNavigate = ({ chapter, changeWiewChapter }) => {
  return (
    <div className="modalEditCompany-navigate">
      <div
        className={`modalEditCompany-navigate-item ${chapter === "employees" ? "active" : ""}`}
        onClick={() => changeWiewChapter("employees")}
      >
        <span>Сотрудники</span>
      </div>
      <div
        className={`modalEditCompany-navigate-item ${chapter === "responsibles" ? "active" : ""}`}
        onClick={() => changeWiewChapter("responsibles")}
      >
        <span>Ответственные по проекту</span>
      </div>
    </div>
  );
};

export default ModalEditCompanyNavigate;
