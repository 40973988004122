import React, { useState, useEffect } from "react";
import ProfileTabsListRow from "./components/ProfileTabsListRow/ProfileTabsListRow";
import "./ProfileTabsList.scss";
import iconAdmin from "../../assets/icons/admin-blue.svg";
import iconDirector from "../../assets/icons/director-blue.svg";
import iconUser from "../../assets/icons/user-blue.svg";
import iconCustomer from "../../assets/icons/customer-blue.svg";
import iconPerformer from "../../assets/icons/performer-blue.svg";

const ProfileTabsList = ({ chapter, data }) => {
  const getTypeIcon = (type) => {
    switch (type) {
      case "profileAdmin":
        return iconAdmin;
      case "profileDirector":
        return iconDirector;
      case "profileUser":
        return iconUser;
      case "profileCustomer":
        return iconCustomer;
      case "profilePerformer":
        return iconPerformer;
      default:
        return iconAdmin;
    }
  };

  return (
    <div className="profileTabsList">
      <ul className="profileTabsList__header profileTabsList-header">
        <li className="profileTabsList-header__item profileTabsList-header-item profileTabsList-header-item-title">
          <img
            className="profileTabsList-header-item__img"
            src={getTypeIcon(chapter)}
            alt=""
          />
          <h2 className="profileTabsList-header-item__text">
            {data.type.name}
          </h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Чтение</h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Добавление</h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Изменение</h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Удаление</h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Утверждение</h2>
        </li>
        <li className="profileTabsList-header__item profileTabsList-header-item">
          <h2 className="profileTabsList-header-item__text">Экспорт</h2>
        </li>
      </ul>
      <ul className="profileTabsList__list profileTabsList-list">
        {data.pagePermission.map((elem, i) => {
          return (
            <li className="profileTabsList__item profileTabsList-item" key={i}>
              <ProfileTabsListRow info={elem} page />
              {elem?.pagePermission && (
                <ul className="profileTabsList-item__list profileTabsList-item-list">
                  {elem?.pagePermission?.map((elems, id) => {
                    return (
                      <li className="profileTabsList-item-list__item" key={id}>
                        <ProfileTabsListRow info={elems} />
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileTabsList;
