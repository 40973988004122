import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import "./CardMeetingMinutesRow.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import { useDispatch } from "react-redux";
import { asyncGetTask } from "../../../../../../../store/reducers/tasksReducer";
import { useModal } from "../../../../../../../hooks/useModal";
import axios from "../../../../../../../axios/axios";
import { toast } from "react-toastify";
import MeetingMinutesTaskList from "./components/MeetingMinutesTaskList/MeetingMinutesTaskList";
import { getFormatDateTime } from "../../../../../../../utils/getFormatDateTime";
import { useHasPermissions } from "../../../../../../../hooks/useHasPermissions";

function CardMeetingMinutesRow(props) {
  const { info, check, addItemSelectProtocol } = props;
  const { _id, name, number, tasks: taskProtocol, createdAt } = info;

  const dispatch = useDispatch();
  const { changeModalVisible, getInfo } = useModal();
  const { edit, remove } = useHasPermissions("infoProtocol", info);
  const [loading, setLoading] = useState(false);
  const [taskRejectedVisible, setTaskRejectedVisible] = useState(false);
  const [tasks, setTasks] = useState([]);

  const handleOnInfo = async (info) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible("infoTask", true, res.payload);
  };

  const handleOnDelete = (info) => {
    changeModalVisible("deleteTask", true, {
      info,
    });
  };

  const handleOnEdit = async (info) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible("editTask", true, res.payload);
  };

  // получение подзадач в в протоколе
  const getSubTask = async () => {
    setLoading(true);

    const res = await axios.post(`calendar/get_protocols_tasks/${_id}`);
    if (res.error) {
      toast.error("Не удалось получить задачу");
      return setLoading(false);
    }
    setTasks(res.data);

    setLoading(false);
  };

  useEffect(() => {
    setTasks(taskProtocol);
  }, []);

  useEffect(() => {
    if (!taskRejectedVisible) return;
    getSubTask();
  }, [getInfo("deleteTask"), taskRejectedVisible]);

  return (
    <div className="cardMeetingMinutesRow">
      <div className="cardMeetingMinutesRow-container">
        <div className="cardMeetingMinutesRow-container-item number">
          <Checkbox onCheck={() => addItemSelectProtocol(_id)} check={check} />
          <span>{number}</span>
        </div>
        <div className="cardMeetingMinutesRow-container-item createAt">
          <span title={getFormatDateTime(createdAt)}>{getFormatDateTime(createdAt)}</span>
        </div>
        <div
          className="cardMeetingMinutesRow-container-item name"
          onClick={() => changeModalVisible("infoProtocol", true, info)}
        >
          <span title={name}>{name}</span>
        </div>

        <div className="cardMeetingMinutesRow-container-item task">
          <span title={tasks.length}>{tasks.length}</span>
        </div>
        <div className="cardMeetingMinutesRow-container-item btns">
          {/* <img src={pdf} alt="pdf" />
          <img src={excel} alt="excel" /> */}
          {taskProtocol?.length > 0 &&
            (loading ? (
              <div className="tasksRowCard-btn">
                {" "}
                <div className="submit-spinner"></div>
              </div>
            ) : (
              <div
                className="tasksRowCard-btn"
                onClick={() => setTaskRejectedVisible((prev) => !prev)}
              >
                <Icons
                  type={"tasksIcon"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "Подазадачи" }}
                />
              </div>
            ))}

          <Icons
            onClick={() => changeModalVisible("infoProtocol", true, info)}
            type={"infoItem"}
            size={"md"}
            cursor
            title={{
              visible: true,
              text: "",
            }}
          />
          {edit && (
            <Icons
              type={"edit"}
              size={"md"}
              cursor
              onClick={() => changeModalVisible("editProtocol", true, info)}
              title={{
                visible: true,
                text: "",
              }}
            />
          )}
          {remove && (
            <Icons
              onClick={() => changeModalVisible("deleteProtocol", true, info)}
              type={"remove"}
              size={"md"}
              cursor
              title={{
                visible: true,
                text: "",
              }}
            />
          )}
        </div>
      </div>

      {taskRejectedVisible && tasks?.length > 0 && (
        <div className="cardMeetingMinutesRow-task">
          <div className="hr"></div>
          <MeetingMinutesTaskList
            fatherId={_id}
            tasks={tasks}
            buttonAddTask={false}
            getTasks={getSubTask}
            handleOnEdit={handleOnEdit}
            handleOnInfo={handleOnInfo}
            handleOnDelete={handleOnDelete}
          />
        </div>
      )}
    </div>
  );
}

export default CardMeetingMinutesRow;
