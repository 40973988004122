let lazy;

const setLazy = (l) => {
  lazy = l;
};

const onChangelines = (linesData, setNewLines, l) => {
  lazy = l;
  const { lines, width, height } = JSON.parse(linesData);
  const newLines = lines.filter((line) => line.brushColor !== "black").filter((line) => line.brushColor !== "yellow");
  setNewLines(
    JSON.stringify({
      lines: newLines,
      width,
      height,
    })
  );
};

const getRangePin = (line) => {
  if (!line) return line;
  const newLine = line.map((el) => {
    if (el.brushColor !== "#f74912") {
      return {
        ...el,
        isPoint: true,
        range: {
          y: {
            first: el.points[0].y,
            last: el.points[el.points.length - 1].y - 60,
          },
          x: {
            first: el.points[0].x - 25,
            last: el.points[el.points.length - 1].x + 25,
          },
        },
      };
    } else {
      return {
        ...el,
        isPoint: false,
        range: el.points,
      };
    }
  });

  return newLine;
};

const getAllLines = (allLines) => {
  if (!allLines.length) return '{"lines": [], "width": 0, "height": 0}';

  let data;
  allLines.forEach((line, i) => {
    if (i === 0) {
      if (!line.lines) return "";
      const lines = line.lines.map((el) => ({ ...el, idTask: line.id }));
      const range = getRangePin(lines);
      data = {
        ...lines,
        lines: range,
      };
    } else {
      if (!line.lines) return "";
      const lines = line.lines.map((el) => ({ ...el, idTask: line.id }));
      const range = getRangePin(lines);
      data = {
        ...data,
        lines: [...data.lines, ...range],
      };
    }
  });
  return data ? JSON.stringify(data) : '{"lines": [], "width": 0, "height": 0}';
};

const getTaskIdByRange = (allLines) => {
  if (!lazy) return;
  const lines = allLines.filter((el) => !!el.range);
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].isPoint) {
      if (
        lines[i].range.x.first < lazy.x &&
        lines[i].range.x.last > lazy.x &&
        lines[i].range.y.first > lazy.y &&
        lines[i].range.y.last < lazy.y
      ) {
        return {
          idTask: lines[i].idTask,
          line: lines[i],
        };
      }
    } else {
      const { range } = lines[i];
      const [lazyY, lazyX] = [lazy?.y || 0, lazy?.x || 0];

      let taskX = false;
      let taskY = false;
      for (let i = 0; i < range.length; i++) {
        const { x, y } = range[i];
        // -- X
        if (
          +x.toFixed(0) === +lazyX.toFixed(0) ||
          (+x.toFixed(0) + 3 >= +lazyX.toFixed(0) && +x.toFixed(0) <= +lazyX.toFixed(0)) ||
          (+x.toFixed(0) - 3 <= +lazyX.toFixed(0) && +x.toFixed(0) >= +lazyX.toFixed(0))
        ) {
          taskX = true;
        }
        // -- Y
        if (
          +y.toFixed(0) === +lazyY.toFixed(0) ||
          (+y.toFixed(0) + 3 >= +lazyY.toFixed(0) && +y.toFixed(0) <= +lazyY.toFixed(0)) ||
          (+y.toFixed(0) - 3 <= +lazyY.toFixed(0) && +y.toFixed(0) >= +lazyY.toFixed(0))
        ) {
          taskY = true;
        }
      }
      if (taskX && taskY) {
        return {
          idTask: lines[i].idTask,
          line: lines[i],
        };
      }
    }
  }
  return null;
};

const getTasksLayers = (info) => {
  const obj = {
    atWork: 0,
    rejected: 0,
    overdue: 0,
    completedTasks: 0,
  };
  const { lines } = JSON.parse(getAllLines(info.tasks));
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].brushColor === "blue") {
      obj.atWork = obj.atWork + 1;
    }
    if (lines[i].brushColor === "red") {
      obj.rejected = obj.rejected + 1;
    }
    if (lines[i].brushColor === "perple") {
      obj.overdue = obj.overdue + 1;
    }
    if (lines[i].brushColor === "green") {
      obj.completedTasks = obj.completedTasks + 1;
    }
  }
  return obj;
};

export { getTaskIdByRange, getRangePin, getAllLines, onChangelines, getTasksLayers, setLazy };
