import React, { useEffect, useState } from "react";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";
import "./NavigationListMobile.scss";
import { useSearch } from "../../hooks/useSearch";

const NavigationListMobile = ({
  listBtn = {
    visible: false,
    type: "typePages",
    list: [],
  },
  activeNav = "",
  typePages: typePagesProp,
}) => {
  const [btnList, setBtnList] = useState(listBtn?.list);
  const [selectActive, setSelectActive] = useState({});
  const [activeBtnList, setActiveBtnList] = useState(false);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");

  useEffect(() => {
    if (listBtn?.list?.length > 0) {
      setBtnList(listBtn.list.filter((item) => item.type !== activeNav));
      setSelectActive(listBtn.list.find((item) => item.type === activeNav));
      setActiveBtnList(false);
    }
  }, [activeNav]);

  useEffect(() => {
    if (listBtn?.list?.length > 0) {
      setSelectActive(listBtn.list[0]);
      setBtnList(listBtn.list);
    }
  }, []);

  const { setQueryParam, queries } = useSearch();

  const typePagesProjectTasks = [
    {
      chapter: "all",
      title: "Все",
    },
    {
      chapter: "incoming",
      title: "Входящие",
    },
    {
      chapter: "outgoing",
      title: "Исходящие",
    },
  ];

  const typePagesDefault = [
    {
      chapter: "genContractor",
      title: "Ген.подрядчика",
    },
    {
      chapter: "customer",
      title: "Заказчика",
    },
    {
      chapter: "executor",
      title: "Подрядчика",
    },
  ];

  const typePages = (() => {
    if (listBtn?.type === "project:tasks") {
      return typePagesProjectTasks;
    } else {
      if (typePagesProp) {
        return typePagesProp;
      } else {
        return typePagesDefault;
      }
    }
  })();

  const changeActiveNavSearch = (tab) => {
    setQueryParam({ tab });
  };

  useEffect(() => {
    setSelectActive(typePages.find((item) => item.chapter === activeSearchNav));
    setActiveBtnList(false);
  }, [activeSearchNav, typePagesProp]);

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  return (
    listBtn?.visible && (
      <div className="navigationListMobile">
        {/* {listBtn.type === "typePages" && ( */}
        <>
          <Screen size="xs">
            <div className="navigationListMobile-btns">
              {typePages.map((page) =>
                page.title ? (
                  <div
                    key={page.title}
                    onClick={() => changeActiveNavSearch(page.chapter)}
                    className={`navigationListMobile-btns-btn ${
                      page.chapter === activeSearchNav ? "navigationListMobile-btns-btn-active" : ""
                    }`}
                  >
                    <span className="navigationListMobile-btns-btn-title">{page.title}</span>
                  </div>
                ) : (
                  ""
                ),
              )}
            </div>
          </Screen>
          <Screen size="xs" down>
            <div className="navigationListMobile-select">
              <div
                className="navigationListMobile-select-btn"
                onClick={() => setActiveBtnList(!activeBtnList)}
              >
                <span className="navigationListMobile-select-btn-title">{selectActive?.title}</span>
                <Icons
                  type={"arrowDown"}
                  size={"md"}
                  color={"dark"}
                  //   cursor
                  className={`navigationListMobile-select-btn-arrow ${
                    activeBtnList ? "navigationListMobile-select-btn-arrow-open" : ""
                  }`}
                />
              </div>
              <div
                className={`navigationListMobile-select-list ${
                  activeBtnList ? "navigationListMobile-select-list-active" : ""
                }`}
              >
                {typePages?.map((page) =>
                  page.title ? (
                    <span
                      className="navigationListMobile-select-list-option"
                      key={page.title}
                      onClick={() => changeActiveNavSearch(page.chapter)}
                    >
                      {page.title}
                    </span>
                  ) : (
                    ""
                  ),
                )}
              </div>
            </div>
          </Screen>
        </>
      </div>
    )
  );
};

export default NavigationListMobile;
