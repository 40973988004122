import React, { useEffect, useState } from "react";
import LeftInfo from "../LeftInfo/LeftInfo";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import SearchPanel from "../SearchPanel/SearchPanel";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Pagination from "../Pagination/Pagination";
import Button from "../Button/Button";
import "./ModalAddInChat.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useSelects } from "../../hooks/useSelects";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useModal } from "../../hooks/useModal";
import axios from "../../axios/axios";
import { useDispatch } from "react-redux";
import {
  asyncAddUserToChat,
  asyncCreateMessage,
  asyncGetMessagesChat,
} from "../../store/reducers/chatReduser";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLimitElements } from "../../utils/getLimitElements";
import CardRowsParticMinChat from "../CardRowsParticMinChat/CardRowsParticMinChat";
import Screen from "../Screen/Screen";
import EmployeesCardMobile from "../EmployeesCardMobile/EmployeesCardMobile";

const ModalAddInChat = ({ handleChange }) => {
  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const currentChat = getInfo("addInChat", "data");

  const [pageNumber, setPageNumber] = useState(1);
  const [employeesList, setEmployeesList] = useState([]);
  const [count, setCount] = useState(0);

  const [usersForAdd, setUsersForAdd] = useState([]);
  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(employeesList);

  const addAllUser = () => {
    if (selectItems.length === employeesList.length) {
      setUsersForAdd([]);
    } else {
      setUsersForAdd(employeesList);
    }

    addAllItemSelect();
  };

  const addUser = (ids) => {
    const filterSort = employeesList?.filter((elem) => {
      return ids?.find((item) => elem._id === item);
    });

    setUsersForAdd((prev) => [...prev, ...filterSort]);
  };

  const changePage = (page = 1) => {
    setPageNumber(page);
  };

  const getEmployees = async (page = pageNumber, options = {}) => {
    options = {
      ...options,
      without: currentChat.activeUserList.map((u) => u._id),
    };
    const res = await axios.post(`/users/get/${page}/?limit=${getLimitElements()}`, options);
    if (res.error) return;

    setEmployeesList(res.data.users);
    setCount(res.data.count);
  };

  const onSubmitSearch = async (value) => {
    await getEmployees(1, { name: value });
  };

  const onChange = async (value) => {
    if (value === "") setPageNumber(1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!selectItems.length) return toast.error("Необходимо выбрать хотя бы одного сотрудника");

    const res = await dispatch(
      asyncAddUserToChat({
        chatId: currentChat._id,
        userIds: selectItems,
      }),
    );

    if (res.error) return;

    await dispatch(
      asyncCreateMessage({
        chatId: currentChat._id,
        data: {
          text: `добавил в чат ${usersForAdd.map((u) => getFormatFIO(u)).join(", ")}`,
          event: true,
          isVoice: false,
        },
      }),
    );

    await dispatch(asyncGetMessagesChat(currentChat._id));
    changeModalVisible("addInChat", false);

    handleChange && handleChange();
  };

  useEffect(() => {
    getEmployees();
  }, [pageNumber]);

  useEffect(() => {
    addUser(selectItems);
  }, [selectItems]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addInChat")}
      title="Добавить участника"
      // noscroll={false}
      btns={
        <div className="modalAddChat-table-btns">
          <Button disabled={!selectItems.length} color="blue" title="Добавить" type="submit" />
        </div>
      }
    >
      <div className="modalAddInChat">
        <div className="modalAddInChat-header">
          <Screen size={"lg"}>
            <LeftInfo nowrap color="#F6F6F6" title={`Всего сотрудников: ${count}`} />
          </Screen>
          <Screen size={"lg"} down>
            <div className="modalAddInChat-header-mobile">
              <span>Всего сотрудников: {count}</span>
              <div className="modalAddInChat-header-mobile-check">
                <Checkbox
                  onCheck={addAllItemSelect}
                  check={selectItems?.length === employeesList?.length}
                />
                <span>Выбрать всех</span>
              </div>
            </div>
          </Screen>
          <SearchPanel
            onChange={onChange}
            onSubmit={onSubmitSearch}
            inputSearch={{
              visible: true,
              placeholder: "Найти сотрудника по Ф.И.О.",
            }}
          />
        </div>

        <form onSubmit={onSubmit} className="modalAddInChat-table">
          <Screen size={"lg"}>
            <div className="modalAddInChat-table-header">
              <Checkbox onCheck={addAllUser} check={selectItems.length === employeesList.length} />
              <span>ФИО:</span>
              <span>Email:</span>
              <span>Компания:</span>
              <span>Отдел:</span>
              <span>Должность:</span>
            </div>
            <div className="modalAddInChat-table-list">
              {employeesList.map((employee) => (
                <CardRowsParticMinChat
                  key={employee._id}
                  check={selectItems.includes(employee._id)}
                  info={employee}
                  addItemSelect={() => addItemSelect(employee._id)}
                />
              ))}
            </div>
          </Screen>
          <Screen size={"lg"} down>
            <div className="modalAddChat-grid customScroll">
              {employeesList.map((user, i) => (
                <EmployeesCardMobile
                  key={user._id}
                  info={user}
                  modalCard={true}
                  check={selectItems.includes(user._id)}
                  addItemSelect={addItemSelect}
                />
                // <span>{i}</span>
              ))}
            </div>
          </Screen>
          <Pagination
            currentPage={pageNumber}
            count={count}
            onPageChange={(page) => changePage(page)}
            modal={true}
          />
          <Screen size={"lg"}>
            <Button disabled={!selectItems.length} color="blue" title="Добавить" type="submit" />
          </Screen>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ModalAddInChat;
