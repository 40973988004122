import { getAllLines } from "./getAllLines.js";

export const getTasksLayers = (info) => {
  const obj = {
    atWork: 0,
    rejected: 0,
    overdue: 0,
    completedTasks: 0,
  };
  const { lines } = JSON.parse(getAllLines(info.tasks));
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].brushColor === "blue") {
      obj.atWork = obj.atWork + 1;
    }
    if (lines[i].brushColor === "red") {
      obj.rejected = obj.rejected + 1;
    }
    if (lines[i].brushColor === "perple") {
      obj.overdue = obj.overdue + 1;
    }
    if (lines[i].brushColor === "green") {
      obj.completedTasks = obj.completedTasks + 1;
    }
  }
  return obj;
};
