import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import "./CardRowsPartic.scss";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import Icons from "../../../../../../../components/Icons/Icons";
import { transformRole } from "../../../../../../../utils/transformRole";
import { MenuDots } from "../../../../../../../ui";

function CardRowsPartic(props) {
  const { addItemSelect, check, number, changeModalVisible, info, showCheckbox } = props;
  const { _id, position, company, role, phone, email } = info;

  const onEvent = (name) => {
    switch (name) {
      case "info":
        return changeModalVisible("infoParticipant", true, props.info);
      case "edit":
        return changeModalVisible("infoParticipant", true, props.info, true);
      case "delete":
        return changeModalVisible("deleteParticipant", true, props.info);
      default:
        return changeModalVisible("infoParticipant", true, props.info);
    }
  };

  const listMenu = [
    {
      text: "Информация",
      onClick: () => onEvent("info"),
      name: "info",
    },
    {
      text: "Удалить",
      onClick: () => onEvent("delete"),
      name: "delete",
    },
  ];

  return (
    <div className="cardRowsPartic">
      {/* <div className="cardRowsPartic-item number">
        <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
        <span>{number}</span>
      </div> */}
      <div className="cardRowsPartic-item name" onClick={() => onEvent("info")}>
        <span title={getFormatFIO(info.user)}>{getFormatFIO(info?.user)}</span>
      </div>
      <div className="cardRowsPartic-item email">
        <a title={info?.user?.email} href={`mailto:${info?.user?.email}`}>
          {info?.user?.email}
        </a>
      </div>
      <div className="cardRowsPartic-item phone">
        <span title={info?.user?.phone}>{info?.user?.phone}</span>
      </div>
      <div className="cardRowsPartic-item position">
        <span title={position?.name || ""}>{position?.name || ""}</span>
      </div>
      <div className="cardRowsPartic-item">
        <span title={company?.name}>{company?.name}</span>
      </div>

      <div className="cardRowsPartic-item_btns">
        <MenuDots list={listMenu} />
        {/* <Icons
          type={"infoItem"}
          size={"md"}
          cursor
          onClick={() => onEvent("info")}
          title={{ visible: true, text: "" }}
        /> */}
        {/* <img onClick={() => event("edit")} src={edit} alt="edit" /> */}

        {/* <Icons
          onClick={() => onEvent("delete")}
          type={"remove"}
          size={"md"}
          cursor
          title={{ visible: true, text: "" }}
        /> */}
      </div>
    </div>
  );
}

export default CardRowsPartic;
