import React from "react";
import "./HeaderMobilePage.scss";
import NavigationMobilePanel from "./components/NavigationMobilePanel/NavigationMobilePanel";
import Icons from "../Icons/Icons";

const HeaderMobilePage = ({
  title,
  changeWiewChapter,
  active,
  currentPage,
  disabled = false,
  onClose,
  btns,
  visibleNav = false,
  visiblePemission = false,
}) => {
  return (
    <>
      {!disabled && (
        <div className="headerMobilePage">
          <div className="headerMobilePage-header">
            {title && <h2 className="headerMobilePage-title">{title}</h2>}
            {btns && btns}
            {!!onClose && (
              <button className="headerMobilePage-btn-close" onClick={onClose}>
                <Icons type={"crossBlack"} size={"xl"} color={"black"} />
              </button>
            )}
          </div>
          {visibleNav && (
            <NavigationMobilePanel
              changeWiewChapter={changeWiewChapter}
              active={active}
              currentPage={currentPage}
              visiblePemission={visiblePemission}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HeaderMobilePage;
