import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../../../../components/Pagination/Pagination";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { useSelects } from "../../../../../hooks/useSelects";
import "./CompaniesMobile.scss";
import { useFilter } from "../../../../../hooks/useFilter";
import { asyncDeleteCompaniesFromProject } from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import CompaniesCardMobile from "../../../../../components/CompaniesCardMobile/CompaniesCardMobile";
import ModalInfoEditCompany from "../../../../../components/ModalInfoEditCompany/ModalInfoEditCompany";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";

function CompaniesMobile(props) {
  const { setCompaniesInProjectLength } = props;

  const dispatch = useDispatch();

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const projects = useSelector((state) => state.projects);
  const { project_companies } = projects;

  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState(0);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(companies);

  const { setQueryParam, queries, params, search } = useSearch("project:companies");

  const numberPage = params.page || 1;

  const { chageFitlers } = useFilter();
  const changeActiveNavSearch = (page) => {
    setActiveNav(page);
  };

  const deleteCompaniesFromProject = async () => {
    let companiesForDelete = [];
    if (!!selectItems.length) {
      companiesForDelete = selectItems;
    } else {
      companiesForDelete.push(getInfo("deleteCompany", "data")._id);
    }

    await dispatch(asyncDeleteCompaniesFromProject(companiesForDelete));

    changeModalVisible("deleteCompany");
    clearItems();
    search();
  };

  useEffect(() => {
    setCompanies(project_companies?.companies || []);
    setCount(project_companies?.count || 0);
    setCompaniesInProjectLength(project_companies?.count || 0);
  }, [project_companies]);

  return (
    <>
      <div className="companiesMobile">
        <div className="companiesMobile-header">
          <NavigationListMobile
            listBtn={{
              visible: true,
              type: "typePages",
            }}
          />
        </div>
        <WrapperLoader loading={projects.loading} chapter={"companies"}>
          {companies.length ? (
            <>
              <div className="companiesMobile-grid">
                {companies.map((company, i) => (
                  <CompaniesCardMobile
                    changeModalVisible={changeModalVisible}
                    key={company._id}
                    info={company}
                    check={selectItems.includes(company._id)}
                    addItemSelect={addItemSelect}
                  />
                ))}
              </div>

              <div className="companiesMobile-pagination">
                <Pagination count={count} />
              </div>
            </>
          ) : (
            <EmptySection
              text={"В данном проекте ещё нет компаний"}
              textBtn={"Добавить"}
              onClick={() => {
                changeModalVisible("addCompany", true);
              }}
            />
          )}
        </WrapperLoader>
        {/* Информация/реактировать компанию */}
        {getVisible("infoCompany") && <ModalInfoEditCompany />}
      </div>
    </>
  );
}

export default CompaniesMobile;
