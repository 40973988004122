import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./CompanyEmployees.scss";
import CompanyEmployeesList from "./components/CompanyEmployeesList/CompanyEmployeesList";
import { Outlet } from "react-router-dom";

const CompanyEmployees = () => {
  return (
    <div className="companyEmployees">
      <Outlet />
    </div>
  );
};

export default CompanyEmployees;
