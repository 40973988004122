export const getModalName = (mode, title = false) => {
  if (title) {
    if (mode === "add") return "Добавить новую задачу";
    else if (mode === "edit") return "Редактировать задачу";
    else if (mode === "editSubTask") return "Редактировать подзадачу";
    else if (mode === "copy") return "Скопировать задачу";
    else if (mode === "delegation") return "Делегировать задачу";
    else if (mode === "subTask") return "Добавить подзадачу";
    return "Добавить новую задачу";
  }

  if (mode === "add") return "addTask";
  else if (mode === "edit") return "editTask";
  else if (mode === "editSubTask") return "editSubTask";
  else if (mode === "copy") return "copyTask";
  else if (mode === "delegation") return "delegationTask";
  else if (mode === "subTask") return "addSubTask";
  return "addTask";
};
