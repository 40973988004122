import React, { useState, useEffect } from "react";
import "./Header.scss";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Icons from "../../../Icons/Icons";
import { useSelector } from "react-redux";
import { useSearch } from "../../../../hooks/useSearch";
import { getFormatFIO } from "../../../../utils/getFormatFIO";

function Header() {
  const { userData } = useSelector((state) => state.user);

  const [newNotifications, setNewNotifications] = useState(0);

  const location = useLocation();
  const currentPage = location.pathname.replace(/^\//, "").split("/")[0];

  const { goToPage } = useSearch();

  const { project } = useSelector((state) => state.projects);
  const { employeeInfo } = useSelector((state) => state.user);

  const { company } = useSelector((state) => state.companies);

  const { userId } = useParams();

  const icons = [
    {
      name: "Аналитика",
      path: "analytics",
    },
    {
      name: "Проекты",
      path: "projects",
    },

    {
      name: `Проект${project?.name ? `: ${project?.name}` : ""} `,
      path: "project",
    },
    {
      name: "Сдача работ",
      path: "deliveryWork",
    },
    {
      name: "Задачи",
      path: "tasks",
    },
    {
      name: "Календарь",
      path: "calendar",
    },
    {
      name: "Сотрудники",
      path: "employees",
    },
    {
      name: "Документы",
      path: "documentation",
    },
    {
      name: "Чат",
      path: "chat",
    },
    {
      name: "Справка",
      path: "reference",
    },
    {
      name: "Профиль",
      path: "profile",
    },
    {
      name: `Компания${company?.name ? `: ${company?.name}` : ""}`,
      path: "company",
    },
    {
      name: "Настройки",
      path: "settings",
    },
    {
      name: "Уведомления",
      path: "notifications",
    },
    {
      name: ` ${
        employeeInfo?._id === userId
          ? getFormatFIO(employeeInfo, "full")
          : "Информация о сотруднике"
      }`,
      path: "userInfo",
    },
  ];

  const getInfo = () => {
    return {
      name: icons.find((el) => el?.path === (currentPage ? currentPage : "analytics"))?.name,
      icon: icons.find((el) => el.path === (currentPage ? currentPage : "analytics"))?.icon,
    };
  };

  useEffect(() => {
    setNewNotifications(userData?.notifications);
  }, [userData]);

  return (
    <div className="header">
      <div className="header-name">
        <Icons type={currentPage} size={"xl"} className={"header-icon"} />
        <h1>{getInfo()?.name || "Страница"}</h1>
      </div>
      <div onClick={() => goToPage("notifications")} className="header-notifications">
        <Icons type={"notifications"} size={"xl"} className={"header-icon"} />
        {!!newNotifications && <div className="header-notifications-circle" />}
      </div>
    </div>
  );
}

export default Header;
