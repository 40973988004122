import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import DocItem from "../DocItem/DocItem";
import InputSearch from "../InputSearch/InputSearch";
import Input from "../Input/Input";
import InputDate from "../InputDate/InputDate";
import InputFile from "../InputFile/InputFile";
import EditInfoProject from "../EditInfoProject/EditInfoProject";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Select from "../Select/Select";
import Textarea from "../Textarea/Textarea";
import { prioritiesSelect, statusesSelect } from "../../data/task";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { useModal } from "../../hooks/useModal";
import {
  asyncCreateDeliveryWork,
  asyncEditDeliveryWork,
} from "../../store/reducers/deliveryWorkReducer";
import { asyncGetUserProfiles } from "../../store/reducers/userReducer";
import "./ModalEditDeliveryWork.scss";
import { getModalName } from "./utils/getModalName";
import { getValueForOptions } from "./utils/getValueForOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";

const ModalEditDeliveryWork = (props) => {
  const { mode, handleChange } = props;

  const dispatch = useDispatch();

  const modalName = getModalName(mode);

  const { changeModalVisible, getInfo, intermediateSaving } = useModal();

  const infoWork = getInfo(modalName, "data");

  const [file, setFile] = useState([]);

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      name: infoWork?.name || "",
      description: infoWork?.description || "",
      call_date: infoWork?.call_date ? new Date(infoWork?.call_date) : new Date(),
      documents: infoWork?.documents || [],

      photos: infoWork?.photos || [],
      coordinates: infoWork?.coordinates || "",
    }),
    modalName,
  );

  const { options, changeOptions, checkValidateOptions } = intermediateSaving(
    useChangeOptions({
      engineer: {
        value: getValueForOptions(infoWork, "engineer"),
        options: [],
      },
      type_work: {
        value: getValueForOptions(infoWork, "type_work"),
        options: [
          { label: "Приемка работы", value: "acceptance_work" },
          { label: "Контроль исполнения", value: "execution_control" },
        ],
      },
      section: {
        value: getValueForOptions(infoWork, "section"),
        options: [
          { label: "Физическая приемка", value: "physical" },
          { label: "Документальная приемка", value: "documentary" },
        ],
      },
      layers: {
        value: getValueForOptions(infoWork, "layers"),
        options: [],
      },
      projects: {
        value: getValueForOptions(infoWork, "projects"),
        options: [],
      },
      priorities: {
        value: getValueForOptions(infoWork, "priorities"),
        options: prioritiesSelect,
      },
      status: {
        value: getValueForOptions(infoWork, "status"),
        options: statusesSelect,
      },
      author: {
        value: getValueForOptions(infoWork, "author"),
        options: [],
      },
    }),
    modalName,
  );

  const removeFile = (name) => {
    setFile((prev) => {
      return prev.filter((el) => el.name !== name);
    });
  };

  const closeModal = () => {
    changeModalVisible(modalName, false);
  };

  const changeFiles = (event, data) => {
    if (event === "add") changeIV("set", { name: "documents", value: data.documents });
    else if (event === "delete") {
      const newArr = IV.documents.filter((_, i) => i !== data.i);
      changeIV("set", { name: "documents", value: newArr });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const arrForValidate = [
      checkValidateIV("name", { required: true }),
      checkValidateIV("description", { maxHeight: 5000 }),
      checkValidateOptions("status", { required: true }),
      checkValidateOptions("type_work", { required: true }),
      checkValidateOptions("engineer", { required: true }),
      checkValidateOptions("section", { required: true }),
      checkValidateOptions("author", { required: true }),
    ];

    if (mode === "add") {
      arrForValidate.push(
        checkValidateIV("call_date", {
          noMoreDate: { start: new Date(), end: IV.call_date },
        }),
      );
    }

    if (IV.documents.length > 5) return toast.error("Максимальное количество документов 5");
    if (IV.photos.length > 5) return toast.error("Максимальное количество фотографий 5");

    if (arrForValidate.some((el) => !el)) return;

    const formData = new FormData();

    if (IV.photos.filter((p) => typeof p !== "string").length) {
      IV.photos.forEach((photo) => formData.append("photo", photo));
    }

    if (IV.documents.length) {
      IV.documents.forEach((document) => {
        if (document instanceof File) formData.append("document", document);
      });
    }

    if (mode === "edit") {
      formData.append("photos", JSON.stringify(IV.photos.filter((p) => typeof p === "string")));
      formData.append(
        "documents",
        JSON.stringify(IV.documents.filter((d) => !(d instanceof File))),
      );
    }

    formData.append("name", IV.name);
    formData.append("description", IV.description);
    formData.append("call_date", IV.call_date);
    formData.append("status", options.status.value.value);
    formData.append("section", options.section.value.value);
    formData.append("engineer", options.engineer.value.value);
    formData.append("type_work", options.type_work.value.value);
    formData.append("author", options.author.value.value);

    if (IV.coordinates && options.layers.value) {
      formData.append("coordinates", IV.coordinates);
    } else {
      if (mode === "edit") formData.append("coordinates", "none");
    }

    if (options.projects.value) {
      formData.append("project", options.projects.value.value);
    } else {
      if (mode === "edit") formData.append("project", "none");
    }

    if (options.layers.value) {
      formData.append("layer", options.layers.value.value);
    } else {
      if (mode === "edit") formData.append("layer", "none");
    }

    let res = { error: true };

    if (mode === "add") {
      res = await dispatch(asyncCreateDeliveryWork(formData));
    }

    if (mode === "edit") {
      res = await dispatch(asyncEditDeliveryWork({ workId: infoWork._id, data: formData }));
    }

    if (res.error) return;

    closeModal();

    handleChange && handleChange(mode);
  };

  useEffect(() => {
    // если добавили рисунки или поинты на слой
    if (getInfo("addPoint", "data")) {
      changeIV("set", {
        name: "coordinates",
        value: getInfo("addPoint", "data").coordinates,
      });
      changeModalVisible("clear", "addPoint");
    }
  }, []);

  // * получение всех профайлов пользоватетяля
  useEffect(() => {
    (async () => {
      if (mode === "add") changeOptions("author", "value", null);
      const res = await dispatch(
        asyncGetUserProfiles({
          projects: options.projects.value ? [options.projects.value.value] : null,
        }),
      );

      if (res.payload) {
        changeOptions(
          "author",
          "options",
          res.payload.profiles.map((p) => ({
            label: `${getFormatFIO(p.user)} (Компания: ${p.company.name}; Должность: ${
              p.position.name
            })`,
            value: p._id,
          })),
        );
      }
    })();
  }, [options.projects.value]);

  return (
    <ModalWrapper title={getModalName(mode, true)} onClose={closeModal}>
      <form onSubmit={onSubmit} className="modalEditDeliveryWork-form">
        <div className="modalEditDeliveryWork-form-wrapper">
          <div className="modalEditDeliveryWork-form-wrapper-info">
            <Input
              title="Название работы:*"
              value={IV.name}
              onChange={changeIV}
              name="name"
              placeholder="Введите название работы"
              validate={checkValidateIV("name")}
            />
            <Textarea
              onChange={changeIV}
              value={IV.description}
              height="170px"
              name="description"
              title="Описание работы:"
              placeholder="Введите описание работы не более 5000 символов"
              validate={checkValidateIV("description")}
            />
            <ul className="modalEditDeliveryWork-form-list">
              <div className="editInfoTask-person">
                <Select
                  options={options.author.options}
                  value={options.author.value}
                  handleChange={(data) => changeOptions("author", "value", data)}
                  placeholder="Выберите автора"
                  forInput
                  menuPlacement="top"
                  title="Автор:*"
                  validate={checkValidateOptions("author")}
                />
              </div>
              <li className="modalEditDeliveryWork-form-item">
                <InputSearch
                  options={options.engineer.options}
                  value={options.engineer.value}
                  handleChange={(value) => changeOptions("engineer", "value", value)}
                  placeholder={"Выберите инженера"}
                  forInput
                  menuPlacement="top"
                  title="Инженер ССК:*"
                  optionsForSearch={{ profile: true }}
                  validate={checkValidateOptions("engineer")}
                />
              </li>

              <li className="modalEditDeliveryWork-form-item">
                <Select
                  options={options.section.options}
                  handleChange={(value) => {
                    changeOptions("section", "value", value);
                  }}
                  value={options.section.value}
                  title="Раздел:*"
                  forInput
                  placeholder={"Выберите раздел"}
                  validate={checkValidateOptions("section")}
                />
              </li>
              <li className="modalEditDeliveryWork-form-item">
                <InputDate
                  title="Дата вызова:"
                  value={IV.call_date}
                  validate={checkValidateIV("call_date")}
                  onChange={(data) => {
                    changeIV("set", { name: "call_date", value: data });
                  }}
                />
              </li>
              <li className="modalEditDeliveryWork-form-item inputFile">
                <InputFile
                  title="Файлы:"
                  value={IV.documents}
                  placeholder="Прикрепите файлы работы"
                  onChange={(documents) => changeFiles("add", { documents })}
                  deleteFile={(i) => changeFiles("delete", { i })}
                />
              </li>
            </ul>
            {file.length > 0 ? (
              <div className="modalEditDeliveryWork-form-list">
                <div className="modalEditDeliveryWork-form-item">
                  {file.map((elem) => {
                    return (
                      <DocItem
                        key={elem.lastModified}
                        shadow
                        event={{ remove: () => removeFile(elem.name) }}
                        btnVisible={{ remove: true }}
                        info={elem}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <EditInfoProject
            modalName={modalName}
            mode={mode}
            IVProject={IV}
            changeIV={changeIV}
            optionsProject={options}
            changeOptions={changeOptions}
            checkValidateOptionsProject={checkValidateOptions}
            showSelects={["type_work"]}
            hideSelects={["priorities"]}
          />
        </div>
        <div className="modalEditDeliveryWork-btn-wrapper">
          <Button title={mode === "add" ? "Создать" : "Сохранить"} color={"blue"} type="submit" />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditDeliveryWork;
