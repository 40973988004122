export const colorsMatchStatus = {
  completed: "#16c35b",
  atWork: "#04488e",
  onVerification: "#dc9d00",
  overdue: "#db1f35",
  rejected: "#c0c0c0",
  assigned: "#7f00ff",
  drawing: "#f74912",
  undefined: "#f74912",
  null: "#f74912",
};

export const changeColorCoordinates = (coordinates, status, toJson = true) => {
  if (!coordinates || typeof coordinates !== "string") throw new Error("Invalid coordinates");

  const { lines, width, height } = JSON.parse(coordinates);

  const newLines = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    if (line.brushColor === colorsMatchStatus.drawing) newLines.push(line);
    else {
      newLines.push({
        ...line,
        brushColor: colorsMatchStatus[status],
      });
    }
  }

  if (toJson) {
    return JSON.stringify({ lines: newLines, width, height });
  } else {
    return { lines: newLines, width, height };
  }
};
