import React, { useState, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import SearchPanel from "../SearchPanel/SearchPanel";
import { useChangeIV } from "../../hooks/useChangeIV";
import ChatListItem from "../ChatList/components/ChatListItem/ChatListItem";
import "./ModalForward.scss";
import { useEffect } from "react";
import { useMemo } from "react";
import Input from "../Input/Input";
import { useModal } from "../../hooks/useModal";
import { asyncCreateMessage } from "../../store/reducers/chatReduser";
import Screen from "../Screen/Screen";

const ModalForward = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const { _id: myId } = useSelector((state) => state.user.userData);
  const { chats } = useSelector((state) => state.chats);

  const [forward, setForward] = useState(null);

  const [chatList, setChatList] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);

  const { IV, changeIV } = useChangeIV({
    search: "",
    text: "",
  });

  const changeCurrentChat = (id) => {
    setCurrentChat(id);
  };

  const onForward = () => {
    const chat = chatList.find((ch) => ch._id === currentChat);
    if (chat) setForward(chat);
  };

  const chatListFilter = useMemo(() => {
    return chatList.filter((ch) => {
      if ((ch?.name || "").toLowerCase().includes(IV.search.toLowerCase())) return ch;
      const usersFio = ch.userList
        .filter((u) => u._id !== myId)
        .map((u) => `${u.name} ${u.surname} ${u.patronymic}`.toLowerCase())
        .join(" ");
      if (usersFio.includes(IV.search.toLowerCase())) return ch;
    });
  }, [IV.search, chatList]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const forwardMessages = getInfo("forward", "data")?.messageIds || [];
    const message = {
      text: IV.text,
      forwardedMessages: forwardMessages,
    };

    await dispatch(
      asyncCreateMessage({
        chatId: currentChat,
        data: message,
      }),
    );

    changeModalVisible("forward", false, "prev");
    if (getInfo("forward", "data")?.callback) {
      getInfo("forward", "data")?.callback(currentChat);
    }
    handleChange();
  };

  useEffect(() => {
    setChatList(chats);
  }, [chats]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("forward", false)}
      title="Переслать сообщение"
      width="406px"
      btns={
        !!forward ? (
          <div className="modalForward-form-btns">
            <Button type="submit" color="blue" title="Отправить" form={formId} />
            <Button onClick={() => setForward(null)} color="grey" title="Отмена" />
          </div>
        ) : (
          <div className="d-flex justify-center">
            <Button onClick={onForward} disabled={!currentChat} color="blue" title="Переслать" />
          </div>
        )
      }
    >
      {!!forward && (
        <form onSubmit={onSubmit} className="modalForward-form" id={formId}>
          <div className="modalForward-form-chat">
            <span className="title">Чат:</span>
            <ChatListItem
              currentChatId={forward._id}
              info={forward}
              myId={myId}
              noreadmsg={false}
              // currentChatId={forward._id}
              onClick={() => changeCurrentChat(forward?._id)}
            />
          </div>
          <Input
            value={IV.text}
            onChange={changeIV}
            name="text"
            placeholder="Введите текст сообщения"
          />
          <Screen size={"lg"}>
            <div className="modalForward-form-btns">
              <Button type="submit" color="blue" title="Отправить" />
              <Button onClick={() => setForward(null)} color="grey" title="Отмена" />
            </div>
          </Screen>
        </form>
      )}
      {!!forward || (
        <div className="modalForward">
          <SearchPanel
            onChange={(value) => changeIV("set", { name: "search", value })}
            inputSearch={{
              visible: true,
              placeholder: "Найти по названию группы или Ф. И. О.",
            }}
            clearButton={false}
          />
          <span className="modalForward-title">Чаты:</span>
          <div className="modalForward-chatList customScroll">
            {chatListFilter.map((chat) => (
              <>
                <ChatListItem
                  currentChatId={currentChat}
                  onClick={() => changeCurrentChat(chat?._id)}
                  info={chat}
                  myId={myId}
                  noreadmsg={false}
                  key={chat._id}
                />
              </>
            ))}
          </div>
          <Screen size={"lg"}>
            <div className="d-flex justify-center">
              <Button onClick={onForward} disabled={!currentChat} color="blue" title="Переслать" />
            </div>
          </Screen>
        </div>
      )}
    </ModalWrapper>
  );
};

export default ModalForward;
