import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useModal } from "../../../../hooks/useModal";
import {
  asyncCreateMessage,
  asyncDeleteUserFromChat,
  setChat,
} from "../../../../store/reducers/chatReduser";
import Icons from "../../../Icons/Icons";
import Screen from "../../../Screen/Screen";
import { getFullPath } from "../../../../utils/getFullPath";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import "./Header.scss";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

const Header = (props) => {
  const { currentChat, myId, usersOnline, getMessages } = props;
  const { name, isPersonal } = currentChat;
  const [activeUserList, setActiveUserList] = useState([]);
  const [visibleUserList, setVisibleUserList] = useState(false);
  const [count, setCount] = useState(0);
  const { height, width } = useWindowDimensions();

  const refVisibleUserList = useRef();
  useOnClickOutside(refVisibleUserList, () => setVisibleUserList(false));
  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const deleteUser = async (userId) => {
    const res = await dispatch(
      asyncDeleteUserFromChat({
        chatId: currentChat._id,
        userId,
      }),
    );
    if (res.error) return;
    const newUserList = activeUserList.filter((user) => user._id !== userId);
    setActiveUserList(newUserList);
    dispatch(
      setChat({
        chatId: currentChat._id,
        chat: {
          ...currentChat,
          activeUserList: newUserList,
        },
      }),
    );

    await dispatch(
      asyncCreateMessage({
        chatId: currentChat._id,
        data: {
          text: `удалил из чата ${getFormatFIO(activeUserList.find((u) => u._id === userId))}`,
          event: true,
          isVoice: false,
        },
      }),
    );

    await getMessages();
  };

  const getTitle = () => {
    if (isPersonal) {
      const interlocutor = currentChat.userList.find((user) => user._id !== myId);
      return getFormatFIO(interlocutor);
    }
    return name;
  };

  const getLogo = () => {
    if (isPersonal) {
      const interlocutor = activeUserList.find((user) => user._id !== myId);
      return getFullPath(interlocutor?.avatar, "avatar");
    }

    return {
      list: activeUserList.map((u) => getFullPath(u.avatar, "avatar")),
      count: activeUserList.length - 2,
    };
  };

  const isOnline = () => {
    if (!isPersonal) return false;
    const interlocutor = activeUserList.find((user) => user._id !== myId);
    return usersOnline.includes(interlocutor?._id);
  };

  const isLeaveChat = () => {
    return !!!activeUserList.find((u) => u._id === myId);
  };

  const leaveChat = () => {
    // if (currentChat.isPersonal || isLeaveChat())
    //   return changeModalVisible("deleteChat", true, currentChat);
    changeModalVisible("leaveChat", true, currentChat);
  };

  const deleteChat = () => {
    changeModalVisible("deleteChat", true, currentChat);
  };

  useEffect(() => {
    setActiveUserList(currentChat.activeUserList || []);
    setVisibleUserList(false);
  }, [currentChat]);

  useEffect(() => {
    if (width >= 2000) {
      return setCount(50);
    } else if (width >= 1900 && width < 2000) {
      return setCount(50);
    } else if (width >= 1700 && width < 1900) {
      return setCount(50);
    } else if (width >= 1500 && width < 1700) {
      return setCount(40);
    } else if (width >= 1300 && width < 1500) {
      return setCount(40);
    } else if (width >= 1024 && width < 1300) {
      return setCount(30);
    } else if (width < 1024) {
      return setCount(10);
    }
  }, [width]);

  console.log(isLeaveChat(), !!currentChat?.isPersonal && !isLeaveChat());

  return (
    <div className="windowChatHeader">
      <div className={`windowChatHeader-info ${!currentChat.isPersonal ? "group" : ""}`}>
        <div className="windowChatHeader-info-logo">
          {getLogo()?.list ? (
            <div className="windowChatHeader-info-logo-list">
              {getLogo()
                ?.list.slice(0, count)
                .map((elem) => (
                  <div
                    key={elem._id}
                    style={{ backgroundImage: `url(${elem})` }}
                    className="windowChatHeader-info-logo-img"
                  ></div>
                ))}
              {getLogo()?.list.length > count && (
                <div className="windowChatHeader-info-logo-list-countMembers">
                  <span>+ {getLogo().count - count}</span>
                </div>
              )}
            </div>
          ) : (
            <div className="windowChatHeader-info-logo-member">
              <div
                style={{ backgroundImage: `url(${getLogo()})` }}
                className="windowChatHeader-info-logo-img"
              ></div>
            </div>
          )}
        </div>
        <div className="windowChatHeader-info-wrapper">
          <span
            title={getTitle()}
            className={`windowChatHeader-info-title ${isOnline() ? "online" : ""} ${
              isPersonal ? "personal" : "group"
            }`}
          >
            {getTitle()}
          </span>
        </div>
      </div>
      <div className="windowChatHeader-menu">
        <Screen size={"lg"}>
          <Icons type={"remove"} size={"md"} cursor onClick={deleteChat} />

          {!currentChat?.isPersonal && !isLeaveChat() && (
            <Icons type={"exit"} size={"md"} color={"black"} cursor onClick={leaveChat} />
          )}
          {currentChat.creatorUser === myId && !currentChat?.group && (
            <Icons
              type={"cleaning"}
              size={"md"}
              color={"black"}
              cursor
              onClick={() => changeModalVisible("clearChat", true, currentChat)}
              title={{ visible: true, text: "Очистить чат" }}
            />
          )}
          {currentChat.isPersonal || (
            <Icons
              onClick={() => setVisibleUserList((prev) => !prev)}
              cursor="true"
              type="infoItem"
              size={"md"}
              title={{ visible: true, text: "" }}
            />
          )}
          {currentChat.isPersonal || !!currentChat?.group || (
            <Icons
              type={"plus"}
              size={"md"}
              color={"black"}
              cursor
              onClick={() => changeModalVisible("addInChat", true, currentChat)}
              title={{ visible: true, text: "Добавить участника" }}
            />
          )}
        </Screen>
        <Screen size={"lg"} down>
          <Icons
            type={"menu"}
            size={"md"}
            cursor
            onClick={() => changeModalVisible("menu", true, currentChat)}
          />
        </Screen>
      </div>
      {visibleUserList && (
        <div className="windowChatHeader-info-userList customScroll" ref={refVisibleUserList}>
          {activeUserList.map((user) => (
            <div className="windowChatHeader-info-userList-item" key={user._id}>
              <span className="windowChatHeader-info-userList-item-text">{getFormatFIO(user)}</span>
              {!!currentChat?.group || (
                <Icons
                  cursor
                  onClick={() => deleteUser(user._id)}
                  type="remove"
                  size={"md"}
                  title={{ visible: true, text: "Удалить из чата" }}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Header;
