import React, { useState, useEffect } from "react";
import "./EmployeeMobile.scss";
import { useModal } from "../../../hooks/useModal";
import { asyncGetUser } from "../../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import { useSearch } from "../../../hooks/useSearch";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import { getFormatFIO } from "../../../utils/getFormatFIO";
import EmployeeTasksMobile from "./components/EmployeeTasksMobile/EmployeeTasksMobile";
import EmployeeProjectMobile from "./components/EmployeeProjectMobile/EmployeeProjectMobile";
import EmployeeBaseInfoMobile from "./components/EmployeeBaseInfoMobile/EmployeeBaseInfoMobile";
import NavigationListMobile from "../../../components/NavigationListMobile/NavigationListMobile";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const EmployeeMobile = () => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(null);

  const [chapter, setChapter] = useState("info");
  const [activeFilter, setActiveFilter] = useState("all");

  const { changeModalVisible, getVisible } = useModal();

  const { params, goToPage, setQueryParam, queries } = useSearch();

  const { visible } = useHasPermissions(`userInfo:info`, userInfo);

  const changeWiewChapter = (value) => {
    if (value === "info") return goToPage(`userInfo:info`);

    goToPage(`userInfo:${value}`);
  };

  const changeWiewTasks = (value) => setQueryParam({ tab: value });

  const changeWiewProjects = (value) => setQueryParam({ tab: value });

  const getCurrentData = async (userId) => {
    if (userInfo && userInfo._id === userId) return;

    const res = await dispatch(asyncGetUser(userId));

    if (res.error) return toast.error("Не удалось получить актуальные данные");

    setUserInfo(res.payload);
  };

  useEffect(() => {
    if (params.userId) getCurrentData(params.userId);
    if (params.list) return setChapter(params.list);
    else setChapter("info");
  }, [params.userId, params.list]);

  useEffect(() => {
    if (queries.tab) setActiveFilter(queries.tab);
    else setActiveFilter("all");
  }, [queries.tab]);

  return (
    <LayoutMobile
      // filterDisabled={true}
      footerBtnBack={{
        visible: true,
        onClick: () => goToPage("employees:users"),
      }}
    >
      <ErrorBoundary>
        {userInfo && (
          <div className="employeeMobile">
            <HeaderMobilePage
              title={userInfo && getFormatFIO(userInfo, "full")}
              active={chapter}
              visibleNav={visible}
              currentPage={"infoEmployee"}
              changeWiewChapter={changeWiewChapter}
              onClose={() => goToPage("employees:users")}
            />

            <div className="employeeInfoMobile-content">
              {chapter === "info" && <EmployeeBaseInfoMobile info={userInfo} />}
              {chapter === "projects" && <EmployeeProjectMobile info={userInfo} />}
              {chapter === "tasks" && (
                <div className="employeeInfoMobile-tasks">
                  <NavigationListMobile
                    activeNav={activeFilter}
                    // listBtn={{
                    //   visible: true,
                    //   list: [
                    //     {
                    //       title: "Все",
                    //       onClick: () => changeWiewTasks("all"),
                    //       active: activeFilter === "all" ? true : false,
                    //       type: "all",
                    //     },
                    //     {
                    //       title: "Исходящие",
                    //       onClick: () => changeWiewTasks("incoming"),
                    //       active: activeFilter === "incoming" ? true : false,
                    //       type: "incoming",
                    //     },
                    //     {
                    //       title: "Входящие",
                    //       onClick: () => changeWiewTasks("outgoing"),
                    //       active: activeFilter === "outgoing" ? true : false,
                    //       type: "outgoing",
                    //     },
                    //   ],
                    // }}
                    listBtn={{
                      visible: true,
                      type: "project:tasks",
                    }}
                  />
                  <EmployeeTasksMobile
                  // activeFilter={activeFilter}
                  // changeWiewTasks={changeWiewTasks}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default EmployeeMobile;
