import React from "react";
import { Bars } from "react-loader-spinner";
import "./Loader.scss";

function Loader() {
  return (
    <div className="loader">
      <Bars color="#0094E7" />
    </div>
  );
}

export default Loader;
