import React from "react";
import "./ModalDelegationTask.scss";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import HistoryChanges from "../HistoryChanges/HistoryChanges";
import { useModal } from "../../hooks/useModal";
import InputSearch from "../InputSearch/InputSearch";
import { useDispatch } from "react-redux";
import { asyncDelegationTask } from "../../store/reducers/tasksReducer";

function ModalDelegationTask(props) {
  const { getTasks, handleChange } = props;
  const dispatch = useDispatch();

  const { getInfo, changeModalVisible } = useModal();

  const { _id: taskId, history } = getInfo("delegationTask", "data");

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    executors: {
      value: [],
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateOptions("executors", { requiredArray: true })) return;

    const res = await dispatch(
      asyncDelegationTask({
        taskId,
        data: { executors: options.executors.value.map((e) => e.value) },
      })
    );
    if (res.error) return;
    if (getTasks) await getTasks();
    handleChange && handleChange();
    changeModalVisible("delegationTask", false, res.payload);
  };

  return (
    <ModalWrapper title={"Делегировать задачу"} onClose={() => changeModalVisible("delegationTask", false)}>
      <div className="modalDelegationTask">
        <form onSubmit={onSubmit} className="modalDelegationTask-main ">
          <InputSearch
            multi={true}
            value={options.executors.value}
            handleChange={(data) => changeOptions("executors", "value", data)}
            placeholder="Выберите исполнителя"
            forInput
            menuPlacement="top"
            title="Исполнитель:*"
            validate={checkValidateOptions("executors")}
          />
          <div className="d-flex justify-center">
            <Button type="submit" title="Делегировать" color="blue" />
          </div>
          <HistoryChanges history={history} />
        </form>
      </div>
    </ModalWrapper>
  );
}

export default ModalDelegationTask;
