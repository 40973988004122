import dayjs from "dayjs";
import React, { useState } from "react";
import "./CalendarMini.scss";
import {
  getCalendarMatrix,
  getCurrentDate,
  monthName,
} from "../../utils/utilsCalendar";
import { getFormatFIO } from "../../utils/getFormatFIO";
import Screen from "../Screen/Screen";
import EventList from "./components/EventList/EventList";

function CalendarMini(props) {
  const { currentDate, events } = props;
  const { year, month } = currentDate;
  const matrix = getCalendarMatrix(year, month);
  const [activeDay, setActiveDay] = useState(dayjs(getCurrentDate()));
  const now = dayjs();
  const checkMonthDay = (day) => {
    if (month !== dayjs(day).month()) {
      return "noMonth";
    }
    return "";
  };

  const eventListForDay = (day) => {
    const lists = [];
    for (let i = 0; i < events.length; i++) {
      const { start, end } = events[i];
      if (
        dayjs(start).subtract(1, "day") <= dayjs(day) &&
        dayjs(end) >= dayjs(day)
      ) {
        lists.push(events[i]);
      }
    }
    return lists;
  };

  const changeCurrentDay = (day) => {
    setActiveDay(day);
  };

  const checkActiveDay = (day) => {
    if (dayjs(day).format("DD.MM.YYYY") === activeDay.format("DD.MM.YYYY")) {
      return "activeDay";
    } else if (dayjs(day).format("DD.MM.YYYY") === now.format("DD.MM.YYYY")) {
      if (activeDay.format("DD.MM.YYYY") === now.format("DD.MM.YYYY")) {
        return "activeDay";
      } else {
        return "currentDay";
      }
    }

    if (eventListForDay(day)?.length) return "haveEvents";
    return "";
  };

  return (
    <div className="calendarMini">
      {/* кнопка добавления */}
      {/* <div
        onClick={() => changeModalVisible("addEvent")}
        className="calendarMini-btnAddEvent"
      >
        +
      </div> */}
      <Screen size={"lg"}>
        <div className="calendarMini-title">
          <span className="year">{year}</span>
          <span className="month">{monthName[month]}</span>
        </div>
      </Screen>

      <div className="calendarMini-calendar">
        <div className="calendarMini-calendar-header">
          <span>Пн</span>
          <span>Вт</span>
          <span>Ср</span>
          <span>Чт</span>
          <span>Пт</span>
          <span>Сб</span>
          <span>Вс</span>
        </div>
        <div className="calendarMini-calendar-main">
          {matrix.map((week, indexWeek) => (
            <div key={indexWeek} className="calendarMini-calendar-main-week">
              {week.map((day, indexDay) => (
                <div
                  key={indexDay}
                  className={`calendarMini-calendar-main-day ${checkActiveDay(
                    day
                  )}`}
                  onClick={() => {
                    changeCurrentDay(day);
                  }}
                >
                  <span
                    className={`calendarMini-calendar-main-day-title ${checkMonthDay(
                      day
                    )} `}
                  >
                    {dayjs(day).format("DD")}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="calendarMini-calendar-footer">
          <div>
            <span className="bold">Дата</span>{" "}
            <span>{dayjs(activeDay).format("DD/MM/YYYY")}</span>
          </div>
        </div>
      </div>
      <EventList eventListForDay={eventListForDay} activeDay={activeDay} />
    </div>
  );
}

export default CalendarMini;
