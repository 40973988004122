export const getFormatStatus = (s) => {
  switch (s) {
    case "assigned": {
      return {
        color: "#7f00ff",
        text: "Назначено",
      };
    }
    case "rejected": {
      return {
        color: "#c0c0c0",
        text: "Отклонено",
      };
    }
    case "completed": {
      return {
        color: "#16c35b",
        text: "Выполненно",
      };
    }
    case "atWork": {
      return {
        color: "#04488e",
        text: "В работе",
      };
    }
    case "overdue": {
      return {
        color: "#db1f35",
        text: "Просрочено",
      };
    }
    case "onVerification": {
      return {
        color: "#dc9d00",
        text: "На проверке",
      };
    }
    default: {
      return {
        color: "#16C35B",
        text: "Выполненно",
      };
    }
  }
};
