import React, { useState } from "react";
import "./BasicInformation.scss";
import axios from "../../../../../axios/axios";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import { asyncEditProject, setProject } from "../../../../../store/reducers/projectsReducer";
import InputDate from "../../../../../components/InputDate/InputDate";
import Select from "../../../../../components/Select/Select";
import Button from "../../../../../components/Button/Button";
import Input from "../../../../../components/Input/Input";
import Textarea from "../../../../../components/Textarea/Textarea";
import gps from "../../../../../assets/icons/gps.svg";
import Slider from "../../../../../components/Slider/Slider";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import { toast } from "react-toastify";
import { transformForSelect } from "../../../../../utils/transformForSelect";
import InputSearch from "../../../../../components/InputSearch/InputSearch";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

function BasicInformation({ getData }) {
  const dispatch = useDispatch();
  // const { queries } = useSearch(`project:basicInformation`);
  const { queries } = useSearch(`project:basicInformation`);
  const dataProject = useSelector((state) => state.projects.project);

  const editQueries = queries.edit;

  const {
    _id: projectId,
    name,
    description,
    contractNumber,
    customers,
    roomType,
    generalContractor,
    address,
    photos,
    work_start,
    work_end,
    conclusion_contract,
    contract_start,
    contract_end,
    completed: completed_for_project,
    region,
  } = dataProject;

  const { visible, edit: permissionEdit } = useHasPermissions(
    "project:basicInformation",
    dataProject,
  );

  const [imagesList, setimagesList] = useState(photos);
  const [completed, setCompleted] = useState(completed_for_project);

  const [edit, setEdit] = useState(editQueries || false);

  const { getVisible, changeModalVisible } = useModal();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: name,
    contractNumber: contractNumber,
    description,
    address: address,
    work_start: new Date(work_start),
    work_end: new Date(work_end),
    conclusion_contract: new Date(conclusion_contract),
    contract_start: new Date(contract_start),
    contract_end: new Date(contract_end),
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    genContractor: {
      options: [],
      value: transformForSelect(generalContractor),
    },
    customers: {
      options: [],
      value: customers.map(transformForSelect),
    },
    roomType: { options: "default", value: transformForSelect(roomType) },
    region: {
      value: transformForSelect(region),
    },
  });

  const validateDate = () => {
    let validate = false;

    const { work_start, work_end, contract_start, contract_end, conclusion_contract } = IV;

    if (dayjs(contract_start).diff(conclusion_contract, "day") < 0) {
      validate = "Дата начала контракта не может быть раньше даты заключения контракта";
    }

    if (dayjs(work_start).diff(contract_start, "day") < 0) {
      validate = "Дата начала работ не может быть раньше даты начала контракта";
    }

    if (dayjs(contract_end).diff(work_end, "day") < 0) {
      validate = "Дата завершения контракта не может быть раньше даты завершения работ";
    }

    if (dayjs(work_end).diff(work_start, "day") < 0) {
      validate = "Завершение работ не может быть раньше начала работ";
    }

    if (dayjs(contract_end).diff(contract_start, "day") < 0) {
      validate = "Завершение контракта не может быть раньше начала контракта";
    }
    if (imagesList.length > 5) {
      validate = "Максимальное колличество фото 5";
    }
    return validate;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validate = validateDate();
    if (validate) return toast.warning(validate);
    const checkValidateFields = [
      checkValidateIV("name", { minHeight: 3, maxHeight: 100 }),
      checkValidateIV("description", { maxHeight: 5000 }),
      checkValidateIV("address", { required: true, minHeight: 8 }),
      checkValidateIV("contractNumber", { required: true }),
      checkValidateOptions("roomType", { required: true }),
      checkValidateOptions("customers", { requiredArray: true }),
      checkValidateOptions("genContractor", { required: true }),
    ].every((item) => item === true);
    if (checkValidateFields) {
      const formData = new FormData();

      formData.append("_id", projectId);
      formData.append("name", IV.name);
      formData.append("description", IV.description);
      formData.append("contractNumber", IV.contractNumber);
      formData.append("address", IV.address);

      formData.append("work_start", IV.work_start);
      formData.append("work_end", IV.work_end);
      formData.append("conclusion_contract", IV.conclusion_contract);
      formData.append("contract_start", IV.contract_start);
      formData.append("contract_end", IV.contract_end);

      formData.append("generalContractor", options.genContractor.value.value);
      formData.append("region", options.region.value.value);
      formData.append("customers", JSON.stringify(options.customers.value.map((c) => c.value)));
      formData.append("roomType", options.roomType.value.value);
      formData.append(
        "photos",
        JSON.stringify(imagesList.filter((img) => typeof img === "string")),
      );

      for (let i = 0; i < imagesList.length; i++) {
        if (typeof imagesList[i] !== "string") {
          formData.append(`photos`, imagesList[i]);
        }
      }

      const res = await dispatch(asyncEditProject({ formData, project_id: projectId }));
      if (res.error) return;
      getData && getData();
      setEdit(false);
    }
  };

  const completeProject = async () => {
    setCompleted((prev) => {
      toast.success(`Проект ${prev ? "возобнавлен" : "завершен"}`);
      return !prev;
    });
    await axios.post(`projects/complete_project/${projectId}`);
    changeModalVisible("completeProject", false);
  };

  return (
    <>
      {getVisible("complete_project") && <div className="bgc-shadow"></div>}
      <div className="basicInformation">
        {edit ? (
          <form onSubmit={onSubmit} className="basicInformation-edit">
            {/* Редактирование */}
            <div className="basicInformation-edit-forms">
              <div className="basicInformation-edit-forms-set">
                <Input
                  onChange={changeIV}
                  value={IV.name}
                  name="name"
                  title="Название проекта:*"
                  validate={checkValidateIV("name")}
                />
                <Input
                  name="contractNumber"
                  value={IV.contractNumber}
                  onChange={changeIV}
                  placeholder="Введите номер контракта"
                  title="№ контракта:*"
                  validate={checkValidateIV("contractNumber")}
                />
                <InputSearch
                  forInput
                  multi
                  listModal={true}
                  validate={checkValidateOptions("customers")}
                  type="company"
                  placeholder="Выберите заказчика"
                  title="Заказчик:*"
                  handleChange={(value) => changeOptions("customers", "value", value)}
                  placeholderSelect="Выберите город"
                  optionsForSearch={{ type: "customer" }}
                  value={options.customers.value}
                />
                <InputSearch
                  forInput={true}
                  validate={checkValidateOptions("genContractor")}
                  type="company"
                  value={options.genContractor.value}
                  placeholder="Выберите генерального подрядчика"
                  title="Генеральный подрядчик:*"
                  handleChange={(value) => changeOptions("genContractor", "value", value)}
                  placeholderSelect="Выберите город"
                  optionsForSearch={{ type: "gencontractor" }}
                />

                <Select
                  title="Тип помещения:*"
                  value={options.roomType.value}
                  options={options.roomType.options}
                  forInput
                  handleChange={(value) => changeOptions("roomType", "value", value)}
                  validate={checkValidateOptions("roomType")}
                />
                <InputDate
                  value={IV.conclusion_contract}
                  onChange={(data) => {
                    changeIV("set", {
                      name: "conclusion_contract",
                      value: data,
                    });
                  }}
                  title="Дата заключения контракта:*"
                />
                <InputDate
                  value={IV.contract_start}
                  onChange={(data) => {
                    changeIV("set", { name: "contract_start", value: data });
                  }}
                  title="Начало контракта:*"
                />
                <InputDate
                  value={IV.contract_end}
                  onChange={(data) => {
                    changeIV("set", { name: "contract_end", value: data });
                  }}
                  title="Завершение контракта:*"
                />
                <InputDate
                  value={IV.work_start}
                  onChange={(data) => {
                    changeIV("set", { name: "work_start", value: data });
                  }}
                  title="Начало работ:*"
                />
                <InputDate
                  value={IV.work_end}
                  onChange={(data) => {
                    changeIV("set", { name: "work_end", value: data });
                  }}
                  title="Завершение работ:*"
                />
                <InputSearch
                  forInput={true}
                  type="region"
                  name="region"
                  value={options.region.value}
                  placeholder="Введите город"
                  title="Город:*"
                  handleChange={(data) => {
                    changeOptions("region", "value", data);
                  }}
                  placeholderSelect="Выберите город"
                  validate={checkValidateOptions("region")}
                />
                <Input
                  title="Адрес:*"
                  name="address"
                  value={IV.address}
                  onChange={changeIV}
                  icon
                  validate={checkValidateIV("address")}
                >
                  <div className="input-icon">
                    <img src={gps} alt="gps" />
                  </div>
                </Input>
              </div>
              <Textarea
                height="170px"
                title="Описание:"
                placeholder="Введите описание проекта не более 5000 символов"
                name="description"
                value={IV.description}
                onChange={changeIV}
                validate={checkValidateIV("description")}
              />
            </div>
            <div className="basicInformation-edit-btn">
              <div className="basicInformation-edit-btn-set">
                <Button type="submit" title="Сохранить" color="blue" />
                {/* <Button
                  title="Отменить"
                  color="dark"
                  onClick={() => getInfoProject()}
                /> */}
                <Button
                  onClick={() => changeModalVisible("completeProject", true)}
                  title={`${completed ? "Возобновить" : "Завершить"}`}
                  color="red"
                />
              </div>
            </div>
          </form>
        ) : (
          <div className="basicInformation-info">
            {/* Информация */}
            <div className="basicInformation-info-item">
              <span className="title">Название:</span>
              <span title={name}>{name}</span>
            </div>
            <div className="basicInformation-info-item description">
              <span className="title">Описание:</span>
              <span className="word-break">{description}</span>
            </div>

            <div className="basicInformation-info-specifications">
              {visible && (
                <div className="basicInformation-info-specifications-block-item">
                  <span className="title">Заказчик:</span>
                  <span className="text" title={customers[0].name}>
                    {customers[0].name}
                  </span>
                </div>
              )}

              <div className="basicInformation-info-specifications-block-item">
                <span className="title">Генеральный подрядчик:</span>
                <span className="text" title={generalContractor?.name}>
                  {generalContractor.name}
                </span>
              </div>
              {visible && (
                <div className="basicInformation-info-specifications-block-item">
                  <span className="title">№ контракта:</span>
                  <span className="text" title={contractNumber}>
                    {contractNumber}
                  </span>
                </div>
              )}
              <div className="basicInformation-info-specifications-block-item">
                <span className="title">Тип помещения:</span>
                <span className="text" title={roomType}>
                  {roomType}
                </span>
              </div>
              {visible && (
                <>
                  <div className="basicInformation-info-specifications-block-item">
                    <span className="title">Дата заключения контракта:</span>
                    <span className="text" title={dayjs(conclusion_contract).format("DD.MM.YYYY")}>
                      {dayjs(conclusion_contract).format("DD.MM.YYYY")}
                    </span>
                  </div>

                  <div className="basicInformation-info-specifications-block-item">
                    <span className="title">Начало контракта:</span>
                    <span className="text" title={dayjs(contract_start).format("DD.MM.YYYY")}>
                      {dayjs(contract_start).format("DD.MM.YYYY")}
                    </span>
                  </div>

                  <div className="basicInformation-info-specifications-block-item">
                    <span className="title">Завершение контракта:</span>
                    <span className="text" title={dayjs(contract_end).format("DD.MM.YYYY")}>
                      {dayjs(contract_end).format("DD.MM.YYYY")}
                    </span>
                  </div>
                  <div className="basicInformation-info-specifications-block-item">
                    <span className="title">Начало работ:</span>
                    <span className="text" title={dayjs(work_start).format("DD.MM.YYYY")}>
                      {dayjs(work_start).format("DD.MM.YYYY")}
                    </span>
                  </div>

                  <div className="basicInformation-info-specifications-block-item">
                    <span className="title">Завершение работ:</span>
                    <span className="text" title={dayjs(work_end).format("DD.MM.YYYY")}>
                      {dayjs(work_end).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </>
              )}
              <div className="basicInformation-info-specifications-block-item">
                <span className="title">Город:</span>
                <span className="text" title={region.name}>
                  {region.name}
                </span>
              </div>
              <div className="basicInformation-info-specifications-block-item">
                <span className="title">Адрес:</span>
                <span className="text" title={address}>
                  {address}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="basicInformation-photos">
          <Slider
            title="Фотографии проекта:"
            images={imagesList}
            onChange={setimagesList}
            edit={edit}
          />
        </div>
        {!edit && permissionEdit && (
          <Button type="button" title="Редактировать" color="blue" onClick={() => setEdit(true)} />
        )}
        {getVisible("completeProject") && (
          <ModalDeleteItem
            changeModalVisible={changeModalVisible}
            nameModal="completeProject"
            headerTitle={`${completed ? "Возобновление" : "Завершение"} проекта`}
            title={`Вы уверены, что хотите ${completed ? "Возобновить" : "Завершить"} проект?`}
            onDeleteTitle={`${completed ? "Возобновить" : "Завершить"}`}
            onDelete={() => completeProject()}
          />
        )}
      </div>
    </>
  );
}

export default BasicInformation;
