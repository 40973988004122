import React from "react";
import ProjectWorkScheduleRows from "../ProjectWorkScheduleRows/ProjectWorkScheduleRows";
import ProjectWorkScheduleCol from "../../../../../../../components/ProjectWorkScheduleCol/ProjectWorkScheduleCol";

const ProjectWorkScheduleItem = ({
  viewProjectWorkSchedule,
  data: dataModal,
  changeModalVisible,
  tasks = [],
}) => {
  // let tasks = [
  //   {
  //     start: new Date(2022, 10, 27),
  //     end: new Date(2023, 1, 15),
  //     name: "Первый этап",
  //     id: "ProjectSample",
  //     progress: 25,
  //     type: "project",
  //     hideChildren: false, // сворачивает и разворачивает детей
  //     displayOrder: 1,
  //     delayInDays: "2",
  //     number: 1,
  //     dependenciesTasks: [],
  //     relationshipTasks: [
  //       {
  //         start: new Date(2022, 10, 27),
  //         end: new Date(2022, 11, 1),
  //         name: "Разработка документации",
  //         id: "Task 0",
  //         progress: 45,
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //         // isDisabled: true, // блокирует изменения задачи
  //         number: 1.1,
  //       },
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 1",
  //         progress: 25,
  //         dependencies: ["Task 0"],
  //         dependenciesTasks: [],
  //         relationshipTasks: [],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 3,
  //         number: 1.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //       },
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
  //         name: "Проработка гипотез",
  //         id: "Task 2",
  //         progress: 100,
  //         dependencies: ["Task 1"],
  //         type: "task",
  //         project: "ProjectSample",
  //         dependenciesTasks: [],
  //         relationshipTasks: [],
  //         displayOrder: 4,
  //         number: 1.3,
  //       },
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
  //         name: "Разработка",
  //         id: "Task 3",
  //         progress: 10,
  //         dependencies: ["Task 2"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 5,
  //         dependenciesTasks: [],
  //         relationshipTasks: [],
  //         number: 1.4,
  //       },
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //         end: new Date(2022, 12, 29),
  //         name: "Проверка",
  //         id: "Task 4",
  //         type: "task",
  //         progress: 70,
  //         dependencies: ["Task 3"],
  //         project: "ProjectSample",
  //         displayOrder: 6,
  //         number: 1.5,
  //         dependenciesTasks: [],
  //         relationshipTasks: [],
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(2022, 10, 27),
  //     end: new Date(2022, 11, 1),
  //     name: "Разработка документации",
  //     id: "Task 0",
  //     progress: 45,
  //     type: "task",
  //     project: "ProjectSample",
  //     displayOrder: 2,
  //     styles: {
  //       backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //     },
  //     // isDisabled: true, // блокирует изменения задачи
  //     number: 1.1,
  //     dependencies: [],
  //     dependenciesTasks: [], // Зависит от...
  //     relationshipTasks: [
  //       // Связвана с...
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 1",
  //         progress: 25,
  //         dependencies: ["Task 0"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 3,
  //         number: 1.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //     name: "Исправления",
  //     id: "Task 1",
  //     progress: 25,
  //     dependencies: ["Task 0"],
  //     dependenciesTasks: [
  //       {
  //         start: new Date(2022, 10, 27),
  //         end: new Date(2022, 11, 1),
  //         name: "Разработка документации",
  //         id: "Task 0",
  //         progress: 45,
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //         // isDisabled: true, // блокирует изменения задачи
  //         number: 1.1,
  //         dependenciesTasks: [],
  //         relationshipTasks: [],
  //       },
  //     ],
  //     relationshipTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
  //         name: "Проработка гипотез",
  //         id: "Task 2",
  //         progress: 100,
  //         dependencies: ["Task 1"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 4,
  //         number: 1.3,
  //       },
  //     ],
  //     type: "task",
  //     project: "ProjectSample",
  //     displayOrder: 3,
  //     number: 1.2,
  //     styles: {
  //       backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //     },
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
  //     name: "Проработка гипотез",
  //     id: "Task 2",
  //     progress: 100,
  //     dependencies: ["Task 1"],
  //     type: "task",
  //     project: "ProjectSample",
  //     displayOrder: 4,
  //     number: 1.3,
  //     dependenciesTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 1",
  //         progress: 25,
  //         dependencies: ["Task 0"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 3,
  //         number: 1.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //       },
  //     ],
  //     relationshipTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
  //         name: "Разработка",
  //         id: "Task 3",
  //         progress: 10,
  //         dependencies: ["Task 2"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 5,
  //         relationshipTasks: [
  //           { id: "Task 2", name: "Проработка гипотез" },
  //           { id: "Task 4", name: "Проверка" },
  //         ],
  //         number: 1.4,
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
  //     name: "Разработка",
  //     id: "Task 3",
  //     progress: 10,
  //     dependencies: ["Task 2"],
  //     type: "task",
  //     project: "ProjectSample",
  //     displayOrder: 5,
  //     relationshipTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //         end: new Date(2022, 12, 29),
  //         name: "Проверка",
  //         id: "Task 4",
  //         type: "task",
  //         progress: 70,
  //         dependencies: ["Task 3"],
  //         project: "ProjectSample",
  //         displayOrder: 6,
  //         number: 1.5,
  //         relationshipTasks: [],
  //       },
  //     ],
  //     dependenciesTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
  //         name: "Проработка гипотез",
  //         id: "Task 2",
  //         progress: 100,
  //         dependencies: ["Task 1"],
  //         type: "task",
  //         project: "ProjectSample",
  //         relationshipTasks: [
  //           { id: "Task 1", name: "Исправления" },
  //           { id: "Task 3", name: "Разработка" },
  //         ],
  //         displayOrder: 4,
  //         number: 1.3,
  //         works: [],
  //       },
  //     ],
  //     number: 1.4,
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //     end: new Date(2022, 12, 29),
  //     name: "Проверка",
  //     id: "Task 4",
  //     type: "task",
  //     progress: 70,
  //     dependencies: ["Task 3"],
  //     project: "ProjectSample",
  //     displayOrder: 6,
  //     number: 1.5,
  //     relationshipTasks: [],
  //     dependenciesTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
  //         name: "Разработка",
  //         id: "Task 3",
  //         progress: 10,
  //         dependencies: ["Task 2"],
  //         type: "task",
  //         project: "ProjectSample",
  //         displayOrder: 5,
  //         relationshipTasks: [
  //           { id: "Task 2", name: "Проработка гипотез" },
  //           { id: "Task 4", name: "Проверка" },
  //         ],
  //         number: 1.4,
  //         works: [],
  //       },
  //     ],
  //   },
  //   // {
  //   //   start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
  //   //   end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
  //   //   name: "Внедрение",
  //   //   id: "Task 6",
  //   //   progress: currentDate.getMonth(),
  //   //   type: "milestone",
  //   //   dependencies: ["Task 4"],
  //   //   project: "ProjectSample",
  //   //   displayOrder: 7,
  //   //   number: 1.6,
  //   // },
  //   {
  //     start: new Date(2022, 10, 29),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
  //     name: "Второй этап",
  //     id: "ProjectToDo",
  //     progress: 25,
  //     type: "project",
  //     hideChildren: false, // сворачивает и разворачивает детей
  //     displayOrder: 8,
  //     delayInDays: "2",
  //     number: 2,
  //     dependencies: ["ProjectSample"],
  //     dependenciesTasks: [],
  //     relationshipTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
  //         name: "Разработка документации",
  //         id: "Task 9",
  //         progress: 100,
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 9,
  //         dependencies: [],
  //         // isDisabled: true, // блокирует изменения задачи
  //         number: 2.1,
  //       },
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 10",
  //         progress: 25,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 10,
  //         number: 2.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //       },
  //       {
  //         start: new Date(2022, 10, 29),
  //         end: new Date(2022, 11, 11),
  //         name: "Тестирование",
  //         id: "Task 11",
  //         progress: 10,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 11,
  //         number: 2.3,
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
  //     name: "Разработка документации",
  //     id: "Task 9",
  //     progress: 100,
  //     type: "task",
  //     project: "ProjectToDo",
  //     displayOrder: 9,
  //     dependencies: [],
  //     // isDisabled: true, // блокирует изменения задачи
  //     number: 2.1,
  //     dependenciesTasks: [],
  //     relationshipTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 10",
  //         progress: 25,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 10,
  //         number: 2.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //       },
  //       {
  //         start: new Date(2022, 10, 29),
  //         end: new Date(2022, 11, 11),
  //         name: "Тестирование",
  //         id: "Task 11",
  //         progress: 10,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 11,
  //         number: 2.3,
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //     end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //     name: "Исправления",
  //     id: "Task 10",
  //     progress: 25,
  //     dependencies: ["Task 9"],
  //     type: "task",
  //     project: "ProjectToDo",
  //     displayOrder: 10,
  //     number: 2.2,
  //     styles: {
  //       backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //     },
  //     dependenciesTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
  //         name: "Разработка документации",
  //         id: "Task 9",
  //         progress: 100,
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 9,
  //         dependencies: [],
  //         // isDisabled: true, // блокирует изменения задачи
  //         number: 2.1,
  //         works: [],
  //       },
  //     ],
  //     relationshipTasks: [
  //       {
  //         start: new Date(2022, 10, 29),
  //         end: new Date(2022, 11, 11),
  //         name: "Тестирование",
  //         id: "Task 11",
  //         progress: 10,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 11,
  //         number: 2.3,
  //       },
  //     ],
  //   },
  //   {
  //     start: new Date(2022, 10, 29),
  //     end: new Date(2022, 11, 11),
  //     name: "Тестирование",
  //     id: "Task 11",
  //     progress: 10,
  //     dependencies: ["Task 9"],
  //     type: "task",
  //     project: "ProjectToDo",
  //     displayOrder: 11,
  //     number: 2.3,
  //     dependenciesTasks: [
  //       {
  //         start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
  //         end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
  //         name: "Исправления",
  //         id: "Task 10",
  //         progress: 25,
  //         dependencies: ["Task 9"],
  //         type: "task",
  //         project: "ProjectToDo",
  //         displayOrder: 10,
  //         number: 2.2,
  //         styles: {
  //           backgroundColor: "#db1f35d4", // костомизация отдлеьной таски backgroundSelectedColor,  progressColor , progressSelectedColor
  //         },
  //         dependenciesTasks: [
  //           {
  //             start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  //             end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
  //             name: "Разработка документации",
  //             id: "Task 9",
  //             progress: 100,
  //             type: "task",
  //             project: "ProjectToDo",
  //             displayOrder: 9,
  //             dependencies: [],
  //             // isDisabled: true, // блокирует изменения задачи
  //             number: 2.1,
  //             works: [],
  //           },
  //         ],
  //         relationshipTasks: [
  //           {
  //             start: new Date(2022, 10, 29),
  //             end: new Date(2022, 11, 11),
  //             name: "Тестирование",
  //             id: "Task 11",
  //             progress: 10,
  //             dependencies: ["Task 9"],
  //             type: "task",
  //             project: "ProjectToDo",
  //             displayOrder: 11,
  //             number: 2.3,
  //           },
  //         ],
  //       },
  //     ],
  //     relationshipTasks: [],
  //   },
  // ];
  const { data, editMode: edit } = dataModal;
  return (
    <div className="projectWorkScheduleItem">
      {viewProjectWorkSchedule === "rows" ? (
        <ProjectWorkScheduleRows
          data={data}
          changeModalVisible={changeModalVisible}
          edit={edit}
          tasks={tasks}
        />
      ) : (
        <ProjectWorkScheduleCol
          data={data}
          changeModalVisible={changeModalVisible}
          edit={edit}
          tasks={tasks}
        />
      )}
    </div>
  );
};

export default ProjectWorkScheduleItem;
