import React from "react";
import ListModalProfile from "../../../ListModalProfile/ListModalProfile";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import Icons from "../../../Icons/Icons";
import { getFullPath } from "../../../../utils/getFullPath";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { useModal } from "../../../../hooks/useModal";

const EventListItem = ({ info, onEvent }) => {
  const { edit, remove } = useHasPermissions("calendar", info);
  const { changeModalVisible } = useModal();

  return (
    <div className="modalEventList-list-item">
      <div className="modalEventList-list-item-header">
        <span className="modalEventList-list-item-header-name">{info?.name}</span>
        <div className="d-flex gap-10">
          <Icons
            onClick={() => onEvent("info", info)}
            type={"infoItem"}
            size={"md"}
            cursor
            title={{
              visible: true,
              text: "",
            }}
          />
          {edit && (
            <Icons
              type={"edit"}
              size={"md"}
              cursor
              onClick={() => onEvent("edit", info)}
              title={{
                visible: true,
                text: "",
              }}
            />
          )}
          {remove && (
            <Icons
              onClick={() => onEvent("delete", info)}
              type={"remove"}
              size={"md"}
              cursor
              title={{
                visible: true,
                text: "",
              }}
            />
          )}
        </div>
      </div>

      <div className="modalEventList-list-item-person">
        <span className="modalEventList-list-item-title">Автор: </span>
        <div className="d-flex gap-10">
          <div
            style={{
              backgroundImage: `url(${getFullPath(info?.author?.user?.avatar, "avatar")})`,
            }}
            className="modalEventList-list-item-person-logo"
          ></div>
          <div className="modalEventList-list-item-person-info">
            <span className="modalEventList-list-item-person-title">
              {getFormatFIO(info?.author?.user)}
            </span>
            <span className="modalEventList-list-item-person-text">
              {info?.author?.position?.name}
            </span>
          </div>
        </div>
      </div>
      <div className="flex-dir-column gap-10">
        <span className="modalEventList-list-item-title">Участники:</span>
        {!!info?.employees?.length && (
          <ListModalProfile
            profiles={info.employees}
            onClickInfo={(info) => {
              changeModalVisible("infoUser", true, info);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EventListItem;
