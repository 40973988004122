import React, { useState, useEffect } from "react";
import "./GorupsMobile.scss";
import { useSelects } from "../../../../../hooks/useSelects";
import { useFilter } from "../../../../../hooks/useFilter";
import { asyncGetGroups } from "../../../../../store/reducers/groupsReducer";
import { useDispatch, useSelector } from "react-redux";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import { useNavigate, useParams } from "react-router-dom";
import GroupsCardMobile from "./components/GorupsCardMobile/GroupsCardMobile";
import GroupInfoEditMobile from "./components/GroupInfoEditMobile/GroupInfoEditMobile";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";

const GorupsMobile = ({ setGroupLength, infoGroup, editGroup }) => {
  const { changeModalVisible } = useModal();

  const { groups, count, loading } = useSelector((state) => state.groups);

  const { params } = useSearch("employees:groups");

  const pageNumber = params.page;

  const getNumber = (i) => i + 1 + groups.length * pageNumber - groups.length;

  useEffect(() => {
    setGroupLength(count);
  }, [groups]);

  return (
    <>
      <div className="gorupsMobile">
        <WrapperLoader loading={loading} chapter={"groups"}>
          {groups.length > 0 ? (
            <div className="groupsMobile-section">
              <div className="gorupsMobile-grid">
                {groups.map((group) => (
                  <GroupsCardMobile
                    info={group}
                    key={group._id}
                    changeModalVisible={changeModalVisible}
                  />
                ))}
              </div>
              {count > getLimitElements() && (
                <div className="employeesGridMobile-pagination">
                  <Pagination count={count} />
                </div>
              )}
            </div>
          ) : (
            !loading && (
              <EmptySection
                text={"Группы не созданы"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addGroup", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
    </>
  );
};

export default GorupsMobile;
