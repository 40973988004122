import React, { useState, useEffect } from "react";
import "./EmployeesGridMobile.scss";
import { useSelects } from "../../../../../hooks/useSelects";
import { useFilter } from "../../../../../hooks/useFilter";
import { useDispatch, useSelector } from "react-redux";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { asyncGetUsers } from "../../../../../store/reducers/userReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import { useNavigate, useParams } from "react-router-dom";
import EmployeesCardMobile from "../../../../../components/EmployeesCardMobile/EmployeesCardMobile";
import Pagination from "../../../../../components/Pagination/Pagination";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";

const EmployeesGridMobile = ({ setEmployeeLength, changeWiewChapter }) => {
  const { changeModalVisible, getVisible } = useModal();

  const { params, goToPage } = useSearch("employees:users");

  const pageNumber = params.page;

  const { users, count, loading } = useSelector((state) => state.user);

  useEffect(() => {
    setEmployeeLength(count);
  }, [users]);
  return (
    <>
      {/* {getVisible("infoEditEmployee") ? (
        <EmployeeInfoEditMobile
          info={getInfo("infoEditEmployee", "data")}
          // edit={getInfo("infoEditEmployee")?.editMode}
        />
      ) : ( */}
      <div className="employeesGridMobile">
        <WrapperLoader loading={loading} chapter={"employees"}>
          {users.length > 0 ? (
            <div className="employeesGridMobile-section">
              <div className="employeesGridMobile-grid">
                {users.map((user) => (
                  <EmployeesCardMobile
                    info={user}
                    key={user._id}
                    changeModalVisible={changeModalVisible}
                    changeWiewChapter={changeWiewChapter}
                    onOpenModal={(info) => {
                      goToPage("userInfo", { userId: info._id });
                    }}
                  />
                ))}
              </div>
              {count > getLimitElements() && (
                <div className="employeesGridMobile-pagination">
                  <Pagination count={count} />
                </div>
              )}
            </div>
          ) : (
            !loading && (
              <EmptySection
                text={"Сотрудники не добавлены"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addEmployee", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
      {/* )} */}
    </>
  );
};

export default EmployeesGridMobile;
