import { mediaBreakpoints } from "../../data/mediaBreakpoints";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const Screen = ({ children, size, down }) => {
  const { width } = useWindowDimensions();

  let screen;

  if (down) {
    screen = width < mediaBreakpoints[size] - 0.02;
  } else {
    screen = width >= mediaBreakpoints[size];
  }

  return screen ? children : null;
};

export default Screen;
