import React, { useState } from "react";
import CompanyBaseInfoInfo from "./components/CompanyBaseInfoInfo/CompanyBaseInfoInfo";
import CompanyBaseInfoEdit from "./components/CompanyBaseInfoEdit/CompanyBaseInfoEdit";

const CompanyBaseInfo = () => {
  const [edit, setEdit] = useState(false);
  return edit ? (
    <CompanyBaseInfoEdit setEdit={setEdit} />
  ) : (
    <CompanyBaseInfoInfo setEdit={setEdit} />
  );
};

export default CompanyBaseInfo;
