import React from "react";
import Button from "../Button/Button";

const AuthPageResetPassword = ({
  resetPassword,
  setRresetPasswordVisible,
  changeIV,
  IV,
  loading,
}) => {
  return (
    <form onSubmit={resetPassword} className="authPage-auth-form">
      <span className="authPage-auth-form-text">
        Вам на почту будет отправлена ссылка для восстановления пароля
      </span>
      <div className="authPage-auth-form-inputs">
        <div className="authPage-auth-form-inputs-input">
          <label className="authPage-auth-form-inputs-input-label" htmlFor="login">
            Введите email
          </label>
          <input
            className="authPage-auth-form-inputs-input-input"
            placeholder="Info@mail.ru"
            id="login"
            type="login"
            name="login"
            value={IV.login}
            onChange={(e) => changeIV(e)}
          />
        </div>
      </div>
      <Button
        className="authPage-auth-form-btnSub"
        title="Отправить"
        color="blue"
        type="submit"
        loading={loading}
      />
      <span
        onClick={() => setRresetPasswordVisible(false)}
        className="authPage-auth-form-btnResetPasw"
      >
        Войти
      </span>
    </form>
  );
};

export default AuthPageResetPassword;
