export const getModalName = (mode, title = false) => {
  if (title) {
    if (mode === "add") return "Создать работу";
    else if (mode === "edit") return "Редактировать работу";
    return "Создать работу";
  }

  if (mode === "add") return "addDeliveryWork";
  else if (mode === "edit") return "editDeliveryWork";
  return "addDeliveryWork";
};
