import React, { useState } from "react";
import "./ProfileNotificationCart.scss";
import СheckboxSwitch from "../../../СheckboxSwitch/СheckboxSwitch";

const ProfileNotificationCart = ({ info, handleCheck = () => {} }) => {
  const { name, title, description, allowed } = info;

  return (
    <div className="profileNotificationCart">
      <div className="profileNotificationCart__info">
        <h5 className="profileNotificationCart__title">{title}</h5>
        <span className="profileNotificationCart__text">{description}</span>
      </div>
      <СheckboxSwitch check={allowed} onCheck={() => handleCheck(name)} />
    </div>
  );
};

export default ProfileNotificationCart;
