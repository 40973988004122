import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

export const getCatalogWorkName = createAsyncThunk("scheduleWorks/getCatalogWorkName", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("/scheduleWorks/get_work_by_name/work", data.params);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCatalogWork = createAsyncThunk("scheduleWorks/getCatalogWork", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("/scheduleWorks/get_work_names", data.params);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncСreateCatalogWork = createAsyncThunk(
  "scheduleWorks/asyncСreateCatalogWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/scheduleWorks/create_work_name`, data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateCatalogWork = createAsyncThunk(
  "scheduleWorks/asyncUpdateCatalogWork",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/scheduleWorks/update_work_name/${data.id}`, {
        name: data?.name,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteCatalogWork = createAsyncThunk(
  "scheduleWorks/asyncDeleteCatalogWork",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/scheduleWorks/delete_work_name/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const catalogWorkReducer = createSlice({
  name: "catalogWork",
  initialState: {
    catalogWork: [],
  },

  extraReducers: extraReducersWrapper({
    [getCatalogWork.fulfilled]: (state, action) => {
      state.catalogWork = action.payload;
    },

    [getCatalogWork.rejected]: "Не удалось получить каталог работ",
    [asyncСreateCatalogWork.fulfilled]: "Работа успешно создана",
    [asyncСreateCatalogWork.rejected]: "Не удалось создать работу",
    [asyncUpdateCatalogWork.fulfilled]: "Работа успешно обновлена",
    [asyncUpdateCatalogWork.rejected]: "Не удалось обновить работу",
    [asyncDeleteCatalogWork.fulfilled]: "Работа удалена",
    [asyncDeleteCatalogWork.rejected]: "Не удалось удалить работу.",
  }),
});

export default catalogWorkReducer.reducer;
