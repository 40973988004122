import React from "react";
import { getBaseUrl } from "../../../../../utils/getBaseUrl";
import noImg from "../../../../../assets/images/pages/noImages.jpg";
import { transformRole } from "../../../../../utils/transformRole";
import "./EmployeeBaseInfoMobile.scss";
import { getFormatDate } from "../../../../../utils/getFormatDate";
import EmployeeBaseInfoProfile from "../../../../../components/EmployeeBaseInfoProfile/EmployeeBaseInfoProfile";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalTask from "../../../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../../../components/ModalEditTask/ModalEditTask";
import ModalAddPointInLayer from "../../../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalAddDellEmployees from "../../../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import ModalPutAside from "../../../../../components/ModalPutAside/ModalPutAside";
import { useSearch } from "../../../../../hooks/useSearch";
import { useModal } from "../../../../../hooks/useModal";

const EmployeeBaseInfoMobile = ({ info }) => {
  const {
    name,
    surname,
    patronymic,
    email,
    phone,
    region,
    position,
    company,
    role,
    groups,
    avatar,
    profiles,
    date_of_birth,
  } = info;
  const { search } = useSearch("userInfo:tasks");
  const { changeModalVisible, getVisible } = useModal();
  const baseURL = getBaseUrl();
  return (
    <div className="employeeBaseInfoMobile">
      <div className="employeeBaseInfoMobile-wrapper">
        <ul className="employeeBaseInfoMobile-info">
          {/* <li className="employeeBaseInfoMobile-info-item">
          <span className="employeeBaseInfoMobile-info-item-title">
            Фамилия:
          </span>
          <span className="employeeBaseInfoMobile-info-item-value">
            {info?.surname}
          </span>
        </li>
        <li className="employeeBaseInfoMobile-info-item">
          <span className="employeeBaseInfoMobile-info-item-title">Имя:</span>
          <span className="employeeBaseInfoMobile-info-item-value">{name}</span>
        </li>
        <li className="employeeBaseInfoMobile-info-item">
          <span className="employeeBaseInfoMobile-info-item-title">
            Отчество:
          </span>
          <span className="employeeBaseInfoMobile-info-item-value">
            {patronymic}
          </span>
        </li> */}
          <li className="employeeBaseInfoMobile-info-item">
            <span className="employeeBaseInfoMobile-info-item-title">Дата рождения:</span>
            <span className="employeeBaseInfoMobile-info-item-value">
              {getFormatDate(date_of_birth) || ""}
            </span>
          </li>
          <li className="employeeBaseInfoMobile-info-item">
            <span className="employeeBaseInfoMobile-info-item-title">Email:</span>

            <a
              href={`mailto:${email}`}
              className="employeeBaseInfoMobile-info-item-value"
              title={email}
            >
              {email}
            </a>
          </li>
          <li className="employeeBaseInfoMobile-info-item">
            <span className="employeeBaseInfoMobile-info-item-title">Телефон:</span>
            <a
              title={phone}
              href={`tel: ${phone}`}
              className="employeeBaseInfoMobile-info-item-value"
            >
              {phone}
            </a>
          </li>

          <li className="employeeBaseInfoMobile-info-item">
            <span className="employeeBaseInfoMobile-info-item-title">Роль:</span>
            <span className="employeeBaseInfoMobile-info-item-value">{transformRole(role)}</span>
          </li>

          <li className="employeeBaseInfoMobile-info-item">
            <span className="employeeBaseInfoMobile-info-item-title">Город:</span>
            <span className="employeeBaseInfoMobile-info-item-value" title={region?.name}>
              {region?.name}
            </span>
          </li>
        </ul>
        <div className="employeeBaseInfoMobile-avatar">
          <span className="employeeBaseInfoMobile-avatar-title">Фотография:</span>
          <img
            crossOrigin="anonymous"
            className="employeeBaseInfoMobile-avatar-img"
            src={!!avatar ? baseURL + avatar : noImg}
            alt=""
          />
        </div>
      </div>
      <div className="hr"></div>
      <h4 className="employeeBaseInfo-title">Профили</h4>
      <div className="employeeBaseInfoMobile-porfiles">
        {profiles.map((profile) => (
          <EmployeeBaseInfoProfile
            info={profile}
            key={profile._id}
            addTask={true}
            onClick={() =>
              changeModalVisible("addTask", true, {
                executors: [profile],
              })
            }
          />
        ))}
      </div>
      {/* Редактирование задачи */}
      {getVisible("editTask") && <ModalEditTask handleChange={() => search()} mode={"edit"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && (
        <ModalEditTask handleChange={() => search()} mode={"editSubTask"} />
      )}

      {/* Добавление задачи */}
      {getVisible("addTask") && <ModalEditTask handleChange={() => search()} mode={"add"} />}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask handleChange={() => search()} mode={"subTask"} />}

      {/* Копирование задачи */}
      {getVisible("copyTask") && <ModalEditTask handleChange={() => search()} mode={"copy"} />}

      {/* Делегирование задачи */}
      {getVisible("delegationTask") && (
        <ModalEditTask handleChange={() => search()} mode={"delegation"} />
      )}

      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}

      {/* Просмотр рисунока */}
      {getVisible("showPoint") && <ModalAddPointInLayer show />}

      {/* Отложить задачу */}
      {getVisible("putAsideTask") && <ModalPutAside />}

      {/* Удалить подзадачу задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту подзадачу?"
          headerTitle="Удалить подзадачу"
        />
      )}
    </div>
  );
};

export default EmployeeBaseInfoMobile;
