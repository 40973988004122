import React, { useState, useEffect } from "react";
import "./ProfileMobileTabs.scss";
import HeaderMobilePage from "../../../../../components/HeaderMobilePage/HeaderMobilePage";
import ProfileTabsList from "../../../../../components/ProfileTabsList/ProfileTabsList";
import roles from "../../../../../data/roles";
import Screen from "../../../../../components/Screen/Screen";
import ProfileMobileTabsList from "./components/ProfileMobileTabsList/ProfileMobileTabsList";

const ProfileMobileTabs = ({ chapter, changeWiewChapter }) => {
  let rolesFilter =
    roles.find((elem) => elem.type.nameEn === chapter) ||
    roles.find((elem) => elem.type.nameEn === "profileAdmin");
  const [data, setData] = useState(rolesFilter);

  useEffect(() => {
    setData(roles.find((elem) => elem.type.nameEn === chapter));
  }, [chapter]);

  return (
    <div className="profileMobileTabs">
      <HeaderMobilePage
        title={"Роли"}
        active={chapter}
        currentPage="profileAdmin"
        changeWiewChapter={changeWiewChapter}
        onClose={() => changeWiewChapter("profileEdit")}
        visibleNav={true}
      />

      <Screen size={"xs"}>
        <ProfileTabsList chapter={chapter} data={data} />
      </Screen>
      <Screen size={"xs"} down>
        <ProfileMobileTabsList chapter={chapter} data={data} />
      </Screen>
    </div>
  );
};

export default ProfileMobileTabs;
