/* eslint-disable no-prototype-builtins */
const { createSlice } = require("@reduxjs/toolkit");

const draftReduces = createSlice({
  name: "modals",
  initialState: {
    addTask: { visible: false, data: null },
    addProject: { visible: false, data: null },
    addSubTask: { visible: false, data: null },
  },
  reducers: {
    changeNotificationDraft: (state, { payload }) => {
      state[payload.draft] = payload.data;
    },
    closeAllNotificationDraft: (state) => {
      for (const key in state) {
        state[key] = {
          visible: false,
          data: null,
        };
      }
    },
  },
});

export const { changeNotificationDraft, closeAllNotificationDraft } = draftReduces.actions;

export default draftReduces.reducer;
