const { createSlice } = require("@reduxjs/toolkit");

const blockScrollReducer = createSlice({
  name: "blockScroll",
  initialState: { disabled: false },
  reducers: {
    setBlockScroll: (state, action) => {
      const { disabled } = action.payload;
      state.disabled = disabled;
    },
  },
});
export const { setBlockScroll } = blockScrollReducer.actions;

export default blockScrollReducer.reducer;
