import dayjs from "dayjs";
import React, { useState } from "react";
import { useMemo } from "react";
import { memo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./ChatList.scss";
import ChatListItem from "./components/ChatListItem/ChatListItem";
import { useSearch } from "../../hooks/useSearch";

const ChatList = (props) => {
  const { _id } = useSelector((state) => state.user.userData);
  const { chats } = useSelector((state) => state.chats);
  const { goToPage, params } = useSearch();
  const [chatList, setChatList] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);

  const chatListToday = useMemo(() => {
    const today = chatList
      .filter((chat) => !!chat.lastMessage)
      .filter((chat) => {
        return !!(dayjs(new Date()).diff(chat.lastMessage.createdAt, "h") < 12);
      });

    const withoutToday = chatList.filter((chat) => {
      return !!!today.find((ch) => ch._id === chat._id);
    });

    return {
      today,
      withoutToday,
    };
  }, [chatList]);

  useEffect(() => {
    setChatList(chats);
  }, [chats]);

  const changeCurrentChat = (chatId) => {
    goToPage("chat", { chatId });
    // setCurrentChatId(chatId);
  };

  useEffect(() => {
    if (params.chatId) setCurrentChatId(params.chatId);
  }, [params.chatId]);

  return (
    <>
      <div className="chatList customScroll">
        {!!chatListToday.today.length && (
          <>
            <span className="title">Сегодня:</span>
            <div className="chatList-list">
              {chatListToday.today.map((chat) => (
                <>
                  <ChatListItem
                    onClick={() => changeCurrentChat(chat?._id)}
                    key={chat?._id}
                    info={chat}
                    myId={_id}
                    currentChatId={currentChatId}
                  />
                </>
              ))}
            </div>
          </>
        )}
        <span className="title">Все чаты:</span>
        <div className="chatList-list">
          {chatListToday.withoutToday.map((chat) => (
            <>
              <ChatListItem
                onClick={() => changeCurrentChat(chat?._id)}
                key={chat?._id}
                info={chat}
                myId={_id}
                currentChatId={currentChatId}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(ChatList);
