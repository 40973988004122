import React from "react";
import "./ProfileEdit.scss";
import LeftInfo from "../../../../../components/LeftInfo/LeftInfo";
import ProfileEditInfo from "../../../../../components/ProfileEditInfo/ProfileEditInfo";
import ProfileRole from "./components/ProfileRole/ProfileRole";
import ProfileNotification from "../../../../../components/ProfileNotification/ProfileNotification";
import ProfileLogoCompany from "./components/ProfileLogoCompany/ProfileLogoCompany";
import { useSelector } from "react-redux";
import { transformRole } from "../../../../../utils/transformRole";
import ProfilesList from "../../../../../components/ProfilesList/ProfilesList";

const ProfileEdit = () => {
  const { userData } = useSelector((state) => state.user);

  if (!userData) return;

  const { name, surname, role } = userData;

  return (
    <div className="profileEdit">
      <div className="profileEdit-filter">
        <LeftInfo title={`${surname} ${name} (Роль в системе: ${transformRole(role)})`} />
      </div>
      <ProfileEditInfo />
      <ProfilesList />
      {/* <ProfileRole changeWiewChapter={changeWiewChapter} /> */}

      {/* <ProfileNotification /> */}
      {/* <ProfilePassword /> */}

      {/* <ProfileLogoCompany /> */}
    </div>
  );
};

export default ProfileEdit;
