import React from "react";
import "./ActiveHeaderWrapper.scss";
import Checkbox from "../Checkbox/Checkbox";
import Icons from "../Icons/Icons";

const ActiveHeaderWrapper = ({ check, onCheck, onDelete, children }) => {
  return (
    <div className="activeHeaderWrapper">
      <Checkbox check={check} onCheck={onCheck} />
      <div className="activeHeaderWrapper-item delete" onClick={onDelete}>
        <Icons
          type={"remove"}
          size={"md"}
          cursor
          title={{ visible: true, text: "" }}
        />
        <span className="activeHeaderWrapper-item-text">Удалить</span>
      </div>
      {children}
    </div>
  );
};

export default ActiveHeaderWrapper;
