import React, { useEffect, useState } from "react";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { useDispatch, useSelector } from "react-redux";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import ModalAddPointInLayer from "../../../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalEditTask from "../../../../../components/ModalEditTask/ModalEditTask";
import ModalTask from "../../../../../components/ModalTask/ModalTask";
import Pagination from "../../../../../components/Pagination/Pagination";
import CardLayers from "../../../../../components/CardLayers/CardLayers";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import { useModal } from "../../../../../hooks/useModal";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";
import { asyncDeleteLayers, asyncGetLayers } from "../../../../../store/reducers/projectsReducer";
import { asyncDeleteLayerFromTasks } from "../../../../../store/reducers/tasksReducer";
import { getBaseUrl } from "../../../../../utils/getBaseUrl";
import { getPageCount } from "../../../../../utils/getPageCount";
import "./LayersMobile.scss";
import { useSearch } from "../../../../../hooks/useSearch";
import PlanList from "../../../../../components/PlanList/PlanList";
import CanvasPlan from "../../../../desktop/Project/components/Layers/components/CanvasPlan/CanvasPlan";
import {
  getRangePin,
  getTaskIdByRange,
  onChangelines,
} from "../../../../desktop/Project/components/Layers/components/utils";
import MenuMarks from "../../../../../components/MenuMarks/MenuMarks";
import ModalSwiperPhoto from "../../../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";
import ModalAddLayer from "../../../../../components/ModalAddLayer/ModalAddLayer";
import ModalEditLayer from "../../../../../components/ModalEditLayer/ModalEditLayer";
import ModalTasksLayer from "../../../../../components/ModalTasksLayer/ModalTasksLayer";

const LayersMobile = ({ viewCards, setLayersLength }) => {
  const projects = useSelector((state) => state.projects);
  const status = useSelector((state) => state.status.status);

  const dispatch = useDispatch();

  const { project_layers } = projects;

  const [layersList, setLayersList] = useState([]);
  const [count, setCount] = useState(0);

  const [currentLayer, setCurrentLayer] = useState(null);

  const [formAdding, setFormAdding] = useState(false);
  const [visiblePoints, setVisiblePoints] = useState(true);
  const [drawingChoose, setDrawingChoose] = useState("pen"); // pin | pen
  const [taskPoint, setTaskPoint] = useState(null);
  const [newLines, setNewLines] = useState("");
  const [dataLines, setDataLines] = useState("");
  const [scrollY, setScrollY] = useState(false);

  const { width, height } = useWindowDimensions();

  const [sizeLayer, setSizeLayer] = useState({
    width: 1070,
    height: 660,
  });

  // для экранов больше 1920
  const changeSizeLayer = (widht) => {
    if (widht >= 1025) {
      let heightContainer = height - 400;
      setSizeLayer({
        width: heightContainer * 1.414,
        height: heightContainer,
      });
    } else {
      let heightContainer = height - 320;
      setSizeLayer({
        width: heightContainer * 1.414,
        height: heightContainer,
      });
    }
  };

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const { search, params } = useSearch("project:layers");

  const getLayers = () => search();

  const pageNumber = params.page;

  const { options, changeOptions } = useChangeOptions({
    marks: {
      options: [
        { label: "Все отметки", value: "all" },
        { label: "Скрыть", value: "disabled" },
        { label: "Назначено", value: "assigned" },
        { label: "Отклонено", value: "rejected" },
        { label: "В работе", value: "atWork" },
        { label: "На проверке", value: "onVerification" },
        // { label: "Просрочено", value: "overdue" },
        { label: "Выполнено", value: "completedTasks" },
      ],
      value: { label: "Все отметки", value: "all" },
    },
  });

  const changeCurrentLayer = (layer) => setCurrentLayer(layer);

  const changeCurrentTask = (layerX, layerY, filterPin) => {
    if (filterPin === "drawing") return;
    let data = null;

    if (typeof layerX === "object") {
      data = layerX.id;
    } else {
      const totallines = currentLayer?.tasks?.reduce((acc, task) => {
        const { coordinates } = task;
        if (typeof coordinates === "string" && coordinates[0] === "{") {
          const { lines } = JSON.parse(coordinates);
          return [...acc, ...lines.map((l) => ({ ...l, idTask: task._id }))];
        }
        return acc;
      }, []);
      const totallinesWithRange = getRangePin(totallines);
      data = getTaskIdByRange(totallinesWithRange, layerX, layerY);
    }

    if (data?.idTask) {
      const task = currentLayer.tasks.filter((el) => el._id === data.idTask)[0];
      setTaskPoint({ task, line: data.line });
    } else {
      setTaskPoint(null);
    }
  };

  const editLayer = (id) => {
    const task = layersList.find((el) => el._id === id);
    changeModalVisible("editLayer", true, task);
  };

  const getNumber = (i) => i + 1 + layersList.length * pageNumber - layersList.length;

  const deleteLayer = async () => {
    await dispatch(asyncDeleteLayers({ layer_id: getInfo("deleteLayer", "data")._id }));
    getLayers();
    changeModalVisible("deleteLayer", false, null);
  };

  const deleteLayerFromTask = async () => {
    const { taskIds, layerId } = getInfo("deleteLayerFromTasks", "data");
    const res = await dispatch(asyncDeleteLayerFromTasks({ taskIds, layerId }));
    changeModalVisible("deleteLayerFromTasks", false, {
      status: res.error ? "ERROR" : "OK",
    });
    if (!res.error) search();
  };

  const onAddTask = () => getLayers();

  const setLinesTask = (_, newTasksLayer) => {
    setCurrentLayer((prev) => ({
      ...prev,
      tasks: newTasksLayer,
    }));
  };

  const getAllLines = (layer) => {
    const allLinesFromTask = {
      lines: [],
      width: 0,
      height: 0,
    };

    const { tasks } = layer;
    for (let j = 0; j < tasks.length; j++) {
      const { coordinates } = tasks[j];
      if (coordinates) {
        const { lines, width, height } = JSON.parse(coordinates);
        if (!allLinesFromTask.height) allLinesFromTask.height = height;
        if (!allLinesFromTask.width) allLinesFromTask.width = width;

        allLinesFromTask.lines = [...allLinesFromTask.lines, ...lines];
      }
    }
    return allLinesFromTask;
  };

  const deleteTasks = async () => {
    let idArray = [];

    idArray.push(getInfo("deleteTasks", "data"));

    await dispatch(
      asyncDeleteTask({
        tasks: idArray,
      }),
    );

    changeModalVisible("deleteTasks", false);
  };

  useEffect(() => {
    if (!currentLayer) return;

    const lines = getAllLines(currentLayer);

    setDataLines(JSON.stringify(lines));
  }, [currentLayer]);

  useEffect(() => {
    changeOptions("marks", "options", [
      { label: "Все отметки", value: "all" },
      { label: "Скрыть", value: "disabled" },
      // ...status.map((s) => ({ label: s.name, value: s.name_en })),
    ]);
  }, []);

  useEffect(() => {
    if (project_layers?.layers) {
      setLayersList(project_layers.layers);
      setCount(project_layers.count);
      setLayersLength(project_layers.count);
    }
  }, [project_layers]);

  useEffect(() => {
    if (layersList.length) {
      setCurrentLayer(layersList[0]);
    }
  }, [layersList]);

  useEffect(() => {
    if (width >= 1300) {
      setScrollY(false);
    } else if (width < 1300) {
      setScrollY(true);
    }
  }, [width]);

  useEffect(() => {
    changeSizeLayer(width);
  }, [width]);

  return (
    <div className="layersMobile">
      {layersList.length ? (
        viewCards === "rows" ? (
          <div className="layersMobile-rows">
            {" "}
            <PlanList
              layersList={layersList}
              changeCurrentLayer={changeCurrentLayer}
              currentLayer={currentLayer}
            />
            {!!currentLayer && (
              <div className="layersMobile-container">
                <div className="layersMobile-container-plan-zone">
                  <CanvasPlan
                    lines={dataLines}
                    filterPin={options.marks.value.value}
                    imgSrc={`${getBaseUrl()}${currentLayer.image}`}
                    formAdding={formAdding}
                    visiblePoints={visiblePoints}
                    drawingChoose={drawingChoose}
                    setDrawingChoose={setDrawingChoose}
                    onChangelines={(linesData, e) => onChangelines(linesData, setNewLines, e)}
                    changeCurrentTask={changeCurrentTask}
                    buttonZoom
                    width={sizeLayer?.width}
                    height={sizeLayer?.height}
                  />
                </div>
                <MenuMarks
                  formAdding={formAdding}
                  options={options}
                  changeOptions={changeOptions}
                  visiblePoints={visiblePoints}
                  setVisiblePoints={setVisiblePoints}
                  drawingChoose={drawingChoose}
                  setDrawingChoose={setDrawingChoose}
                  currentLayer={currentLayer}
                  taskPoint={taskPoint}
                  setTaskPoint={setTaskPoint}
                  setLinesTask={setLinesTask}
                  editLayer={editLayer}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="layersMobile-grid ">
              {layersList.map((layer, i) => (
                <CardLayers
                  number={getNumber(i)}
                  key={layer._id}
                  info={layer}
                  changeCurrentTask={changeCurrentTask}
                  changeModalVisible={changeModalVisible}
                  editLayer={editLayer}
                />
              ))}
            </div>

            <Pagination
              currentPage={pageNumber}
              pageCount={getPageCount(count)}
              onPageChange={(page) => setPageNumber(page)}
            />
          </>
        )
      ) : (
        <EmptySection
          text={"В данном проекте ещё нет слоёв"}
          textBtn={"Добавить"}
          onClick={() => {
            changeModalVisible("addLayer", true);
          }}
        />
      )}
      {getVisible("menuLayer") && (
        <ModalMobileMenu
          changeModalVisible={changeModalVisible}
          chapter={"menuLayer"}
          nameModal={"menuLayer"}
          // setCompanyLength={setCompanyLength}
          onClose={() => changeModalVisible("menuLayer", false)}
          project={projects}
          currentLayer={currentLayer}
        />
      )}
      {/* Добавить слой */}
      {getVisible("addLayer") && <ModalAddLayer handleChange={getLayers} />}

      {/* Удалить слой */}
      {getVisible("deleteLayer") && (
        <ModalDeleteItem
          headerTitle="Удалить слой"
          title="Вы уверены, что хотите удалить этот слой?"
          nameModal="deleteLayer"
          changeModalVisible={changeModalVisible}
          onDelete={deleteLayer}
        />
      )}

      {/* Удалить слой из задач */}
      {getVisible("deleteLayerFromTasks") && (
        <ModalDeleteItem
          headerTitle="Удалить слой из задач"
          title="Вы уверены, что хотите удалить этот слой из задач?"
          changeModalVisible={() => changeModalVisible("deleteLayerFromTasks", false)}
          onDelete={deleteLayerFromTask}
        />
      )}

      {/* Редактировать слой */}
      {getVisible("editLayer") && <ModalEditLayer handleChange={getLayers} />}
      {/* Редактировать слой */}
      {getVisible("tasksLayer") && <ModalTasksLayer />}

      {/* Информация о задачи */}
      {getVisible("infoTask") && <ModalTask restrictSelection={true} />}

      {/* добавление задачи */}
      {getVisible("addTask") && (
        <ModalEditTask handleChange={onAddTask} mode={"add"} restrictSelection={true} />
      )}

      {/* Редактирование задачи */}
      {getVisible("editTask") && <ModalEditTask restrictSelection={true} mode={"edit"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && <ModalEditTask mode={"editSubTask"} restrictSelection={true} />}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask mode={"subTask"} restrictSelection={true} />}

      {/* Копирование задачи */}
      {getVisible("copyTask") && <ModalEditTask mode={"copy"} restrictSelection={true} />}

      {/* Делегирование задачи */}
      {getVisible("delegationTask") && <ModalDelegationTask restrictSelection={true} />}

      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}

      {getVisible("showPoint") && <ModalAddPointInLayer show />}

      {/* Удалить задачу */}
      {getVisible("deleteLayerFromTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteLayerFromTask", false)}
          onDelete={deleteLayerFromTask}
          title="Вы уверены, что хотите удалить из этих задач слой?"
          headerTitle="Удалить задачи"
        />
      )}

      {/* Удалить задачи */}
      {getVisible("deleteTasks") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteTasks", false)}
          onDelete={deleteTasks}
          title="Вы уверены, что хотите удалить эти задачи?"
          headerTitle="Удалить задачу"
        />
      )}

      {/* Удалить задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTasks}
          title="Вы уверены, что хотите удалить эти задачи?"
          headerTitle="Удалить задачу"
        />
      )}

      {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
    </div>
  );
};

export default LayersMobile;
