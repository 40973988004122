import dayjs from "dayjs";
import { useState } from "react";

export const useChangeIV = (initialIV) => {
  const [IV, setIV] = useState(initialIV);

  const [validateIV, setValidateIV] = useState(() => {
    const obj = {};
    for (const key in initialIV) {
      obj[key] = null;
    }
    return obj;
  });

  const changeIV = (e, param) => {
    if (e === "set") {
      const { name, value } = param;

      setIV((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (validateIV[name]) {
        setValidateIV((prev) => ({
          ...prev,
          [name]: null,
        }));
      }
    } else if (e === "setAll") {
      setIV(param);
    } else if (e === "all") {
      setIV(initialIV);
    } else if (e === "clear") {
      const obj = {};
      for (const key in IV) {
        if (typeof IV[key] === "string") obj[key] = "";
        else if (Array.isArray(IV[key])) obj[key] = [];
      }
      setIV(obj);
    } else if (typeof e === "function") {
      const newValue = e(IV[param.name]);
      setIV((prev) => ({
        ...prev,
        [param.name]: newValue || IV[param.name],
      }));
    } else {
      setIV((prev) => ({
        ...prev,
        [e?.target.name]: e?.target.value, // ? временное решение - падает фильтр при проблеме с беком
      }));

      if (validateIV[e?.target.name]) {
        setValidateIV((prev) => ({
          ...prev,
          [e?.target.name]: null,
        }));
      }
    }
  };

  const checkValidateIV = (field, validateData) => {
    let value = true;
    if (!validateData) return validateIV[field];

    // длина массива не больше
    if (validateData.maxArrayLenght) {
      if (IV[field].length >= +validateData.maxArrayLenght) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: `Количество элементов не должно быть больше ${validateData.maxArrayLenght}`,
        }));
        value = false;
      }
    }

    // одна дата не больше другой
    if (validateData.noMoreDate) {
      const { start, end } = validateData.noMoreDate;
      if (dayjs(end).diff(start, "day") < 0) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: `Дата не может быть раньше чем ${dayjs(start).format("DD.MM.YYYY")}`,
        }));
        value = false;
      }
    }

    // определенная длина
    if (validateData.height) {
      if (IV[field]?.length !== validateData.height) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: `Длинна текста должна быть ${validateData.height} символов`,
        }));
        value = false;
      }
    }

    // минимальная длина
    if (validateData.minHeight) {
      if (IV[field]?.length < validateData.minHeight) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: `Длинна текста должна быть больше ${validateData.minHeight} символов`,
        }));
        value = false;
      }
    }

    // максимальная длина
    if (validateData.maxHeight) {
      if (IV[field]?.length > validateData.maxHeight) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: `Длинна текста должна быть не больше ${validateData.maxHeight} символов`,
        }));
        value = false;
      }
    }

    // проверка наличия
    if (validateData.required) {
      if (!IV[field]) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: "Поле является обязательным",
        }));
        value = false;
      }
    }

    // проверка одинаковых полей
    if (validateData.same) {
      if (!(IV[field] === IV[validateData.same] && IV[field])) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: "Поля не совпадают",
        }));
        value = false;
      }
    }

    // проверка корректного номера телефона
    if (validateData.tel) {
      const tel = IV[field]
        .replace("+", "")
        .replace("(", "")
        .replace(")", "")
        .replaceAll("_", "")
        .replaceAll("-", "");
      if (tel.length !== 11) {
        setValidateIV((prev) => ({
          ...prev,
          [field]: "Некорректный номер телефона",
        }));
        value = false;
      }
    }

    // проверка корректного email
    if (validateData.email) {
      const email = IV[field];
      if (email.includes("@")) {
        const arr = email.split("@");
        if (!arr[1] || !arr[0]) {
          setValidateIV((prev) => ({
            ...prev,
            [field]: "Некорректная электронная почта",
          }));
          value = false;
        }
      } else {
        setValidateIV((prev) => ({
          ...prev,
          [field]: "Некорректная электронная почта",
        }));
        value = false;
      }
    }

    return value;
  };

  return { IV, changeIV, checkValidateIV, validateIV };
};
