import dayjs from "dayjs";
import React from "react";
import Button from "../../../Button/Button";
import HistoryChanges from "../../../HistoryChanges/HistoryChanges";
import { getFormatDate } from "../../../../utils/getFormatDate";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import "./ModalWorkInfo.scss";
import ListWorks from "../../../ListWorks/ListWorks";

function ModalWorkInfo(props) {
  const { info, changeModalVisible } = props;
  let {
    historyChanges,
    name,
    desc,
    startDate,
    endDate,
    description,
    subcontractingOrganizations,
    relationshipTasks,
    dependenciesTasks,
    director,
    responsible,
    start,
    end,
    progress,
  } = info;

  // relationshipTasks = [
  //   { id: 1, name: "Зависимая задача 1" },
  //   { id: 2, name: "Зависимая задача 2" },
  // ];
  return (
    <div className="modalWorkInfo">
      <div className="modalWorkInfo-item">
        <span className="modalWorkInfo-title">Название работы:</span>
        <span className="word-break">{name || ""}</span>
      </div>
      <div className="modalWorkInfo-list">
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Дата начала:</span>
          <span className="modalWorkInfo-text">{getFormatDate(start) || ""}</span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Дата завершения:</span>
          <span className="modalWorkInfo-text">{getFormatDate(end) || ""}</span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Кол-во дней:</span>
          <span className="modalWorkInfo-text">{dayjs(end).diff(start, "day")}</span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Кол-во дней просрочки:</span>
          <span className="modalWorkInfo-text">
            {(dayjs().diff(end, "day") < 0 ? 0 : dayjs().diff(end, "day")) || "0"}
          </span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Прогресс:</span>
          <span className="modalWorkInfo-text">{progress + " %" || ""}</span>
        </div>
      </div>
      {/* <div className="modalWorkInfo-item">
        <span className="title">Описание задачи:</span>
        <span className="bold  word-break">{desc}</span>
      </div> */}
      {description && (
        <div className="modalWorkInfo-item">
          <span className="modalWorkInfo-title">Причина переноса срока:</span>
          <span className="modalWorkInfo-text word-break">{description || "-"}</span>
        </div>
      )}
      {(dependenciesTasks?.length > 0 || relationshipTasks?.length > 0) && (
        <div className="modalWorkInfo-select-row">
          {dependenciesTasks?.length > 0 && (
            <ListWorks
              works={dependenciesTasks}
              title={"Зависит от:"}
              changeModalVisible={changeModalVisible}
            />
          )}
          {relationshipTasks?.length > 0 && (
            <ListWorks
              works={relationshipTasks}
              title={"Связвана с:"}
              changeModalVisible={changeModalVisible}
            />
          )}
        </div>
      )}

      {/* <div className="d-flex gap-50">
        <div className="modalWorkInfo-item">
          <span className="title">Суб.подрядная организация:</span>
          <div className="modalWorkInfo-subcontractingOrganizations customScroll">
            {subcontractingOrganizations.map((sub) => (
              <span>{sub.name};</span>
            ))}
          </div>
        </div>
        <div className="modalWorkInfo-item">
          <span className="title">Руководитель:</span>
          <div className="d-flex gap-10">
            <div
              style={{ backgroundImage: `url(${director.logo})` }}
              className="logo"
            ></div>
            <div className="modalWorkInfo-item">
              <span className="bold">{getFormatFIO(director.FIO)}</span>
              <span>{director.position}</span>
            </div>
          </div>
        </div>
        <div className="modalWorkInfo-item">
          <span className="title">Ответственный:</span>
          <div className="d-flex gap-10">
            <div
              style={{ backgroundImage: `url(${responsible.logo})` }}
              className="logo"
            ></div>
            <div className="modalWorkInfo-item">
              <span className="bold">{getFormatFIO(responsible.FIO)}</span>
              <span>{responsible.position}</span>
            </div>
          </div>
        </div>
        <div className="modalWorkInfo-item">
          <span className="title">Дата начала:</span>
          <span className="bold">{getFormatDate(startDate)}</span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="title">Дата завершения:</span>
          <span className="bold">{getFormatDate(endDate)}</span>
        </div>
        <div className="modalWorkInfo-item">
          <span className="title">Кол-во дней:</span>
          <span className="bold">{dayjs(endDate).diff(startDate, "day")}</span>
        </div>
      </div> */}

      {/* <Button color="red" title="Завершить" /> */}
      {!!historyChanges?.length && <HistoryChanges historyChanges={historyChanges} />}
    </div>
  );
}

export default ModalWorkInfo;
