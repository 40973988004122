import React, { useEffect, useState } from "react";
import "./Slider.scss";
import noImage from "../../assets/images/pages/Projects/no-image.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import Icons from "../Icons/Icons";
import Button from "../Button/Button";
import { getFullPath } from "../../utils/getFullPath";

import { FreeMode, Navigation, Thumbs } from "swiper";

function Slider(props) {
  let {
    images = [],
    onChange,
    edit,
    title,
    btnPosition = "center",
    btnVisible = true,
    border,
  } = props;

  const [imagesList, setimagesList] = useState(images);
  const [currentImage, setcurrentImage] = useState(images?.length && imagesList[0]);
  const [swiper, setSwiper] = useState(null);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    onChange && onChange(imagesList);
  }, [imagesList]);

  const removeImg = (e, img) => {
    e.stopPropagation();

    setimagesList((prev) =>
      prev.filter((f) => {
        if (typeof f === "string") {
          return f !== img;
        } else if (typeof f === "object") {
          return img.name !== f.name;
        }
      }),
    );
  };

  const btnStyles = {
    justifyContent: btnPosition,
  };

  const uploadsImages = (e) => {
    const files = e.target.files;
    const filesArr = [];
    for (const key in files) {
      if (key === "length" || key === "item") continue;
      filesArr.push(files[key]);
    }

    setimagesList((prev) => {
      return [...prev, ...filesArr];
    });
  };

  const convertImage = (img) => {
    if (typeof img === "string") {
      if (img.includes("photos")) return getFullPath(img, "project");
    }
    return getFullPath(img, "url");
  };

  useEffect(() => {
    setcurrentImage(imagesList[0]);
  }, [imagesList]);

  return (
    <div className={`slider ${border ? "border" : ""}`}>
      <div className="slider-photos">
        {title && <span className="slider-photos-title">{title}</span>}

        {!!imagesList.length ? (
          <div className="slider-photos-section">
            <Swiper
              style={{
                "--swiper-navigation-color": "#000000",
                "--swiper-pagination-color": "#000000",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="slider-photos-currentImg"
              onSwiper={(s) => {
                setSwiper(s);
              }}
            >
              {imagesList.map((img, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div
                      className="slider-photos-currentImg-img"
                      style={{
                        backgroundImage: `url(${convertImage(img)})`,
                      }}
                    ></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <Swiper
              onSwiper={setThumbsSwiper}
              // loop={true}
              spaceBetween={10}
              // slidesPerView={3}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="slider-photos-swiper-slider"
              breakpoints={{
                200: {
                  slidesPerView: 3,
                },
                400: {
                  slidesPerView: 3,
                },
                640: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                900: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {imagesList.map((img) => (
                <SwiperSlide key={img.name}>
                  <div
                    className="slider-photos-swiper-slider-slide"
                    onClick={() => setcurrentImage(img)}
                    style={{
                      backgroundImage: `url(${convertImage(img)})`,
                    }}
                  >
                    {!!edit && (
                      <div
                        onClick={(e) => removeImg(e, img)}
                        className="slider-photos-swiper-slider-slide-btnRemove"
                      >
                        <Icons
                          type={"remove"}
                          size={"md"}
                          cursor
                          title={{ visible: true, text: "" }}
                        />
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="slider-photos-section-btns">
              <div onClick={() => swiper.slideNext()} className="slider-photos-section-btns-next">
                <Icons type={"arrow"} size={"xl"} cursor />
              </div>
              <div onClick={() => swiper.slidePrev()} className="slider-photos-section-btns-prev">
                <Icons type={"arrow"} size={"xl"} cursor />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="slider-photos-currentImg-img"
            style={{
              backgroundImage: `url(${noImage})`,
            }}
          ></div>
        )}
        {!!edit && (
          <div className="slider-photos-btn">
            <label style={btnStyles} htmlFor="upload">
              {btnVisible && <Button title="Фото" color="dark" icon={{ position: "left" }} />}
            </label>
            <input
              multiple
              accept="image/*,image/jpeg"
              id="upload"
              type="file"
              onChange={uploadsImages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Slider;
