import React, { useState, useEffect } from "react";
import "./ProfileEditInfo.scss";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { asyncEditUser, asyncGetFullInfo } from "../../store/reducers/userReducer";
import { transformRole } from "../../utils/transformRole";
import { transformForSelect } from "../../utils/transformForSelect";
import InputSearch from "../InputSearch/InputSearch";
import Select from "../Select/Select";
import { useModal } from "../../hooks/useModal";
import InputDate from "../InputDate/InputDate";

const ProfileEditInfo = () => {
  const dispatch = useDispatch();

  const { userData, loading } = useSelector((state) => state.user);
  const {
    _id,
    name,
    surname,
    patronymic,
    avatar,
    region,
    email,
    phone,
    company,
    date_of_birth,
    role,
    type,
  } = userData;

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    surname: surname,
    email: email,
    name: name,
    phone: phone,
    patronymic: patronymic,
    avatar: avatar,
    type: type,
    dateOfBirth: date_of_birth ? new Date(date_of_birth) : new Date(),
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    company: {
      options: [],
      value: transformForSelect(company),
    },
    region: {
      options: "default",
      value: transformForSelect(region),
    },

    role: {
      options: "default",
      value: transformForSelect(role),
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("name", { minHeight: 3, maxHeight: 100 });
    const checkSurname = checkValidateIV("surname", {
      minHeight: 3,
      maxHeight: 100,
    });
    const checkEmail = checkValidateIV("email", { email: true });
    const checkPhone = checkValidateIV("phone", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    //const checkRole = checkValidateOptions("role", { required: true });

    if (!checkName || !checkEmail || !checkPhone || !checkRegion || !checkSurname) return;

    const formData = new FormData();
    formData.append("name", IV.name);
    formData.append("surname", IV.surname);
    formData.append("patronymic", IV.patronymic);
    formData.append("email", IV.email);
    formData.append("phone", IV.phone);
    formData.append("date_of_birth", IV.dateOfBirth);
    formData.append("region", options.region.value.value || null);

    formData.append("role", role || null);
    if (IV.avatar) formData.append("avatar", IV.avatar);

    const res = await dispatch(asyncEditUser({ formData, user_id: _id }));
    if (res.error) return;
  };

  useEffect(() => {
    changeIV("all", userData);
  }, [phone]);

  return (
    <div className="profileEditInfo">
      <h2 className="profileEditInfo-title profileEditInfo-title">Основная информация</h2>
      <form onSubmit={onSubmit} className="profileEditInfo-form">
        <div className="profileEditInfo-form-user profileEditInfo-form-user">
          <div className="profileEditInfo-form-user-wrapper">
            <ul className="profileEditInfo-form-user-list">
              <li className="profileEditInfo-form-user-item">
                <Input
                  onChange={changeIV}
                  value={IV.surname}
                  name="surname"
                  title="Фамилия:*"
                  placeholder="Введите Фамилию"
                  validate={checkValidateIV("surname")}
                />
              </li>
              <li className="profileEditInfo-form-user-item">
                <Input
                  validate={checkValidateIV("name")}
                  onChange={changeIV}
                  value={IV.name}
                  name="name"
                  title="Имя:*"
                />
              </li>
              <li className="profileEditInfo-form-user-item">
                <Input
                  onChange={changeIV}
                  value={IV.patronymic}
                  name="patronymic"
                  title="Отчество:"
                  placeholder="Введите Отчество"
                />
              </li>
              <li className="profileEditInfo-form-user-item">
                <InputDate
                  title="Дата рождения:"
                  value={IV.dateOfBirth}
                  onChange={(data) => {
                    changeIV("set", { name: "dateOfBirth", value: data });
                  }}
                />
              </li>
              <li className="profileEditInfo-form-user-item">
                <Input
                  onChange={changeIV}
                  value={IV.email}
                  name="email"
                  title="Email:*"
                  placeholder="Введите Email"
                  validate={checkValidateIV("email")}
                />
              </li>
              <li className="profileEditInfo-form-user-item">
                <Input
                  onChange={changeIV}
                  value={IV.phone}
                  name="phone"
                  type="tel"
                  title="Телефон:*"
                  placeholder="Введите номер телефона"
                  validate={checkValidateIV("phone")}
                />
              </li>
              {role === "admin" ? (
                <li className="profileEditInfo-form-user-item">
                  <InputSearch
                    forInput={true}
                    type="region"
                    handleChange={(data) => {
                      changeOptions("region", "value", data);
                    }}
                    placeholderSelect="Выберите город"
                    validate={checkValidateOptions("region")}
                    placeholder="Выберите город"
                    title="Город:*"
                    menuPlacement="top"
                    value={options.region.value}
                  />
                </li>
              ) : (
                <li className="profileEditInfo-form-user-item item-text">
                  <span className="profileEditInfo-form-user-item-title">Город:</span>
                  <span className="profileEditInfo-form-user-item-text">{region?.name}</span>
                </li>
              )}

              {/* {role === "admin" ? (
                <li className="profileEditInfo-form-user-item">
                  <Select
                    options={options.role.options}
                    value={options.role.value}
                    handleChange={(data) => changeOptions("role", "value", data)}
                    placeholder="Выберите роль"
                    title="Роль в системе:*"
                    forInput
                    menuPlacement="top"
                  />
                </li>
              ) : (
                <li className="profileEditInfo-form-user-item item-text">
                  <span className="profileEditInfo-form-user-item-title">Роль:</span>
                  <span className="profileEditInfo-form-user-item-text">{transformRole(role)}</span>
                </li>
              )} */}
            </ul>
            <div className="profileEditInfo-form-avatar profileEditInfo-form-avatar">
              <h5 className="profileEditInfo-form-avatar-title">Фотография:</h5>
              <ImageUpload
                logo={IV.avatar}
                // profileAvatar
                onChange={(value) => changeIV("set", { name: "avatar", value })}
              />
            </div>
          </div>

          <Button type="submit" color="blue" title="Сохранить" loading={loading} />
        </div>
      </form>
    </div>
  );
};

export default ProfileEditInfo;
