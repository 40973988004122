const getTypeByName = (name) => {
  switch (name) {
    case "gencontractor":
      return { name: "Генеральный подрядчик", _id: "gencontractor" };
    case "subcontractor":
      return { name: "Субподрядчик", _id: "subcontractor" };
    case "sontractor":
      return { name: "Подрядчик", _id: "sontractor" };
    case "deleted":
      return { name: "Удаленные", _id: "deleted" };
    case "customer":
      return { name: "Заказчик", _id: "customer" };
    default:
      return { name: "-", _id: "-" };
  }
};

export const transformTypeCompany = (type, options = {}) => {
  if (!type) return "";

  if (Array.isArray(type)) {
    if (options.forSelect) return type.map((t) => getTypeByName(t));
    return type.map((t) => getTypeByName(t).name).join(", ");
  }

  if (options.forSelect) return getTypeByName(type);

  return getTypeByName(type).name;
};
