import React from "react";
import "./CompaniesCardMobile.scss";
import Icons from "../Icons/Icons";
import { getBaseUrl } from "../../utils/getBaseUrl";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { transformRole } from "../../utils/transformRole";
import noImage from "../../assets/images/pages/noImages.svg";
import { transformTypeCompany } from "../../utils/transformTypeCompany";
import Checkbox from "../Checkbox/Checkbox";
import CardGridHeader from "../CardGridHeader/CardGridHeader";
import { countCompanyEmployess } from "../../utils/countCompanyEmployess.js";

const CompaniesCardMobile = ({
  info,
  changeModalVisible,
  addItemSelect,
  check,
  modalCard = false,
}) => {
  const { profiles, logo, name, director, region, type, phone, email, inn, _id } = info;
  const baseURL = getBaseUrl();

  return (
    <div className="companiesCardMobile">
      {" "}
      <div className="companiesCardMobile-header">
        {modalCard ? (
          <div className="employeesCardMobile-header-mobile">
            <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
          </div>
        ) : (
          <CardGridHeader
            title={"Сотрудников:"}
            text={countCompanyEmployess(profiles) || 1}
            onClick={() => changeModalVisible("menu", true, info, false, false, "companiesCard")}
          />
        )}
        <img
          className="companiesCardMobile-header-img"
          src={logo ? baseURL + logo : noImage}
          alt={name}
        />
        <h4 className="companiesCardMobile-header-title">{name}</h4>
      </div>
      <ul
        className="companiesCardMobile-content"
        onClick={() => changeModalVisible("infoCompany", true, info)}
      >
        <li className="companiesCardMobile-content-item">
          <span className="companiesCardMobile-content-item-title">Руководитель:</span>
          <span className="companiesCardMobile-content-item-text">{getFormatFIO(director)}</span>
        </li>
        <li className="companiesCardMobile-content-item">
          <span className="companiesCardMobile-content-item-title">Тип:</span>
          <span className="companiesCardMobile-content-item-text">
            {transformTypeCompany(type)}
          </span>
        </li>

        <li className="companiesCardMobile-content-item">
          <span className="companiesCardMobile-content-item-title">Город:</span>
          <span className="companiesCardMobile-content-item-text">{region?.name}</span>
        </li>
        <li className="companiesCardMobile-content-item">
          <span className="companiesCardMobile-content-item-title">ИНН:</span>
          <span className="companiesCardMobile-content-item-text">{inn}</span>
        </li>
      </ul>
      <ul className="companiesCardMobile-footer">
        <li className="companiesCardMobile-footer-item">
          <Icons
            type={"phone"}
            size={"md"}
            className="companiesCardMobile-footer-item-img"
            color={"white"}
          />
          <a className="companiesCardMobile-footer-item-text" href={`tel: ${phone}`}>
            {phone}
          </a>
        </li>
        <li className="companiesCardMobile-footer-item">
          <Icons
            type={"mail"}
            size={"md"}
            className="companiesCardMobile-footer-item-img"
            color={"white"}
          />
          <a className="companiesCardMobile-footer-item-text" href={`mailto:${email}`}>
            {email}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CompaniesCardMobile;
