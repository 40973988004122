export const getFormDataTasks = (tasks) => {
  const formDataTasks = [];

  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    const formData = new FormData();

    if (task?.photos?.length && task.photos.filter((p) => typeof p !== "string").length) {
      task.photos.forEach((photo) => formData.append("photo", photo));
    }

    formData.append("name", task.name);
    formData.append("description", task.description === "undefined" ? "" : task.description);
    formData.append("start", task.start);
    formData.append("end", task.end || task.start);
    formData.append("regularity", task.regularity || null);

    formData.append("priority", task.priority);
    formData.append("status", task.status);

    formData.append("notifications", task.notifications || null);

    if (task.project) {
      formData.append("project", task.project);
    }

    if (task.layer) {
      formData.append("layer", task.layer);
    }

    if (task.coordinates && task.layer) {
      formData.append("coordinates", task.coordinates);
    }

    formData.append("author", task.author);
    formData.append("executors", JSON.stringify(task.executors));
    formData.append("observers", JSON.stringify(task.observers));

    formDataTasks.push(formData);
  }

  return formDataTasks;
};
