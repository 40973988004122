import React, { useEffect, useState } from "react";
import "./ImageList.scss";
import Button from "../Button/Button";
import Icons from "../Icons/Icons";
import { getFullPath } from "../../utils/getFullPath";
import { useModal } from "../../hooks/useModal";

const ImageList = (props) => {
  const { images, editMode = true, title, onChange } = props;

  const { changeModalVisible } = useModal();
  const [listImage, setListImage] = useState([]);

  const changeImage = (e) => {
    const files = e.target.files;
    const filesArr = [];

    for (const key in files) {
      if (key === "length" || key === "item") continue;
      // console.log(files[key].type);
      // if (
      //   files[key].type !== "image/svg+xml" ||
      //   files[key].type !== "image/jpeg"
      // )
      //   return;
      filesArr.push(files[key]);
    }

    const newPhotos = [...listImage, ...filesArr];

    setListImage(newPhotos);
    onChange(newPhotos);
  };

  const removeImg = (image) => {
    const newPhotos = listImage.filter((img) => {
      if (typeof image === "string") {
        if (image !== img) return img;
      }
      if (image.name !== img.name) return img;
    });

    setListImage(newPhotos);
    onChange(newPhotos);
  };

  const getFullPathImage = (image) => {
    let photo = null;
    if (typeof image === "string" && image.includes("delivery_work")) {
      photo = getFullPath(image, "deliveryWork");
    } else if (typeof image === "string" && image.includes("photos")) {
      photo = getFullPath(image, "task");
    } else {
      photo = getFullPath(image, "url");
    }

    return photo;
  };

  useEffect(() => {
    if (listImage.length) return;
    setListImage(images || []);
  }, [images]);

  return (
    <div className="imageList">
      <span className="imageList-title">{title || "Фото:"}</span>
      {!!listImage.length && (
        <div className="imageList-list customScroll">
          {listImage.map((image, index) => (
            <div
              key={image}
              style={{ backgroundImage: `url(${getFullPathImage(image)})` }}
              className="imageList-list-item"
              onClick={() =>
                changeModalVisible("swiperPhoto", true, {
                  list: listImage,
                  initialSlide: index,
                })
              }
            >
              {!!editMode && (
                <Icons
                  onClick={(e) => {
                    e.stopPropagation();
                    removeImg(image);
                  }}
                  type={"remove"}
                  size={"md"}
                  className="imageList-list-item-remove"
                  cursor
                  title={{ visible: true, text: "" }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {editMode && (
        <>
          <label htmlFor="fileTask">
            <Button color="dark" title="Фото" icon={{ position: "left" }} />
          </label>
          <input
            onChange={changeImage}
            multiple
            className="d-none"
            type="file"
            id="fileTask"
            name="fileTask"
          />
        </>
      )}
    </div>
  );
};

export default ImageList;
