import React from "react";
import Icons from "../../../../../../../Icons/Icons";
import Screen from "../../../../../../../Screen/Screen";
import { useModal } from "../../../../../../../../hooks/useModal";
import { getFormatDate } from "../../../../../../../../utils/getFormatDate";
import "./ProjectWorkScheduleColListRow.scss";
import dayjs from "dayjs";

const ProjectWorkScheduleColListRow = ({ info, edit }) => {
  const { index, name, start, end, progress, executor } = info;
  const { changeModalVisible } = useModal();

  return (
    <div className="projectWorkScheduleColListRow">
      <div className="projectWorkScheduleColListRow-box-checkbox">
        {/* <Checkbox
check={workItems.includes(task.id)}
onCheck={() => addWorkInItems(task.id)}
/> */}
        <span>{index}</span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={name}>{name}</span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={executor ? executor.name : ""}>{executor ? executor.name : ""}</span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={getFormatDate(start)}>{getFormatDate(start)}</span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={getFormatDate(end)}>{getFormatDate(end)}</span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={dayjs(new Date(end)).diff(start, "day")}>
          {dayjs(new Date(end)).diff(start, "day")}
        </span>
      </div>
      <div className="projectWorkScheduleColListRow-box">
        <span title={progress + " %"}>{progress + " %"}</span>
      </div>
      {/* <div className="projectWorkScheduleColListRow-box">
<span>{numberPeopleTakingPart} </span>
</div> */}

      <div className="projectWorkScheduleColListRow-box-btns">
        <Screen size={"lg"}>
          <Icons
            type={"infoItem"}
            size={"md"}
            cursor
            onClick={() => changeModalVisible("infoWork", true, info)}
            title={{ visible: true, text: "" }}
          />
          {edit && (
            <>
              <Icons
                type={"edit"}
                size={"md"}
                cursor
                onClick={() => changeModalVisible("infoWork", true, info, true)}
                title={{ visible: true, text: "" }}
              />
              <Icons
                type={"remove"}
                size={"md"}
                cursor
                onClick={() => changeModalVisible("deleteWork", true, info)}
                title={{ visible: true, text: "" }}
              />
            </>
          )}
        </Screen>
        <Screen size={"lg"} down>
          <Icons
            type={"menu"}
            size={"m-md"}
            cursor
            onClick={() => changeModalVisible("menu", true, info)}
          />
        </Screen>
      </div>
    </div>
  );
};

export default ProjectWorkScheduleColListRow;
