import React, { useEffect, useId } from "react";
import "./ModalReportsFilterMobile.scss";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import { useModal } from "../../hooks/useModal";
import Button from "../Button/Button";
import Select from "../Select/Select";
import InputSearch from "../InputSearch/InputSearch";
import { useDispatch, useSelector } from "react-redux";
import { taskReportsAnalitics } from "../../store/reducers/analyticsReducer";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";

const ModalReportsFilterMobile = ({
  setTitleSelect,
  titleSelect,
  setTitle,
}) => {
  const formId = useId();

  const dispatch = useDispatch();
  const { changeModalVisible } = useModal();

  const { userData } = useSelector((state) => state.user);
  const { _id: userId } = userData;

  const { options, changeOptions } = useChangeOptions({
    type: {
      options: [
        { label: "Компания", value: "company" },
        { label: "Проект", value: "project" },
        { label: "Сотрудник", value: "user" },
      ],
      value: { label: "Сотрудник", value: "user" },
    },
    options: {
      options: [],
      value:
        titleSelect.title === "Сотрудник:"
          ? { label: getFormatFIO(userData), value: userId }
          : null,
    },
  });

  const getReports = async (type = "user", id) => {
    if (type === "project") {
      await dispatch(taskReportsAnalitics({ project: id }));
      setTitle({
        type: "по проекту",
        name: options.options.value.label,
      });
    } else if (type === "company") {
      await dispatch(taskReportsAnalitics({ company: id }));
      setTitle({
        type: "компании",
        name: options.options.value.label,
      });
    } else {
      await dispatch(taskReportsAnalitics({ user: id }));
      setTitle({
        type: "сотрудника",
        name: options.options.value.label,
      });
    }
  };

  useEffect(() => {
    if (options.type.value?.value === "company") {
      setTitleSelect({
        title: "Компания:",
        placeholder: "Выбрать компанию",
      });
    } else if (options.type.value?.value === "project") {
      setTitleSelect({
        title: "Проект:",
        placeholder: "Выбрать проект",
      });
    } else {
      setTitleSelect({
        title: "Сотрудник:",
        placeholder: "Выбрать сотрудника",
      });
    }
  }, [options.type.value]);

  return (
    <ModalWrapperMobile
      onClose={() => changeModalVisible("reportsFilter", false)}
      title={"Фильтр отчета по задачам"}
      noscroll={true}
      height={"150px"}
      btns={
        <div className="modalReportsFilterMobile-btns">
          <Button
            title="Применить"
            color="blue"
            onClick={() => {
              getReports(options.type.value.value, options.options.value.value);
              changeModalVisible("reportsFilter", false);
            }}
            form={formId}
          />
          <Button
            title="Сбросить"
            color="dark"
            onClick={() => {
              changeOptions("type", "value", null);
              changeOptions("options", "value", null);
            }}
          />
        </div>
      }
    >
      <form className="modalReportsFilterMobile" id={formId}>
        <div className="modalReportsFilterMobile-main">
          <Select
            options={options.type.options}
            value={options.type.value}
            handleChange={(data) => changeOptions("type", "value", data)}
            forInput
            title="Тип:"
            placeholder="Выбрать тип"
            maxMenuHeigh={30}
            isSearchable={false}
          />
          <InputSearch
            options={options.options.options}
            value={options.options.value}
            handleChange={(value) => changeOptions("options", "value", value)}
            placeholder={titleSelect.placeholder}
            forInput
            title={titleSelect.title}
            type={options.type.value?.value}
          />
        </div>
      </form>
    </ModalWrapperMobile>
  );
};

export default ModalReportsFilterMobile;
