import React from "react";
import "./ModalInfoUser.scss";
import { useModal } from "../../hooks/useModal";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { transformRole } from "../../utils/transformRole";
import { getFormatDate } from "../../utils/getFormatDate";
import { getFullPath } from "../../utils/getFullPath";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { clearDobleObj } from "../../utils/clearDobleObg";
import { createArray } from "../../utils/createArray.js";
import ListModal from "../ListModal/ListModal";

const InfoUserProfile = ({ info }) => {
  const { company, position, departments, groups, projects } = info;
  const { changeModalVisible } = useModal();

  const createSting = (arr) => {
    return createArray(clearDobleObj(arr), "name").join(", ");
  };
  return (
    <div className="infoUserProfile">
      <ul className="modalInfoUser-list">
        <li className="modalInfoUser-item">
          <span className="modalInfoUser-item-title">Компания:</span>
          <span className="modalInfoUser-item-text">{company?.name}</span>
        </li>
        <li className="modalInfoUser-item">
          <span className="modalInfoUser-item-title">Должность:</span>
          <span className="modalInfoUser-item-text">{position?.name}</span>
        </li>
        <li className="modalInfoUser-item">
          <span className="modalInfoUser-item-title">Отдел:</span>
          <span className="modalInfoUser-item-text">{createSting(departments)}</span>
        </li>
        <li className="modalInfoUser-item">
          <span className="modalInfoUser-item-title">Группы:</span>
          <span className="modalInfoUser-item-text">{createSting(groups)}</span>
        </li>
      </ul>
      {!!projects.length && (
        <div className="infoUserProfile-projects">
          <span className="modalInfoUser-item-title">Проекты:</span>
          <ListModal
            // title={"Проекты:"}
            type={"projects"}
            info={projects || []}
            infoList={projects}
            onClickInfo={(info) => {
              changeModalVisible("infoProject", true, info);
            }}
          />
        </div>
      )}
    </div>
  );
};

const ModalInfoUser = () => {
  const { changeModalVisible, getInfo } = useModal();

  const info = getInfo("infoUser", "data");
  const {
    _id,
    name,
    surname,
    patronymic,
    avatar,
    email,
    phone,
    role,
    region,
    profiles,
    date_of_birth,
  } = info;

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("infoUser", false)}
      title={`Информация о сотруднике ${getFormatFIO(info)}`}
    >
      <div className="modalInfoUser">
        <h4 className="modalInfoUser-title">Основная информация</h4>
        <div className="modalInfoUser-wrapper">
          <ul className="modalInfoUser-list">
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Фамилия:</span>
              <span className="modalInfoUser-item-text">{surname}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Имя:</span>
              <span className="modalInfoUser-item-text">{name}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Отчество:</span>
              <span className="modalInfoUser-item-text">{patronymic || ""}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Дата рождения:</span>
              <span className="modalInfoUser-item-text">{getFormatDate(date_of_birth) || ""}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Email:</span>
              <span className="modalInfoUser-item-text">{email}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Телефон:</span>
              <span className="modalInfoUser-item-text">{phone}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Город:</span>
              <span className="modalInfoUser-item-text">{region.name}</span>
            </li>
            <li className="modalInfoUser-item">
              <span className="modalInfoUser-item-title">Роль:</span>
              <span className="modalInfoUser-item-text">{transformRole(role)}</span>
            </li>
          </ul>
          <div className="modalInfoUser-avatar">
            <span className="modalInfoUser-item-title">Фотография:</span>
            <img
              className="modalInfoUser-avatar-img"
              src={getFullPath(avatar, "noImage")}
              alt={getFormatFIO(info)}
            />
          </div>
        </div>
        {!!profiles.length && (
          <>
            <div className="hr"></div>
            <h4 className="modalInfoUser-title">Профайлы</h4>
            <div className="modalInfoUser-wrapper">
              <div className="modalInfoUser-profiles">
                {profiles.map((profile) => {
                  return <InfoUserProfile key={profile._id} info={profile} />;
                })}
              </div>
            </div>
          </>
        )}
      </div>{" "}
    </ModalWrapper>
  );
};

export default ModalInfoUser;
