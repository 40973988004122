import React, { useEffect, useId } from "react";
import "./ModalEditGroup.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Input from "../Input/Input";
import Select from "../Select/Select";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useDispatch } from "react-redux";
import { asyncEditGroup, asyncGetGroups } from "../../store/reducers/groupsReducer";
import { getForSelect } from "../../utils/getForSelect";
import { transformForSelect } from "../../utils/transformForSelect";
import InputSearch from "../../components/InputSearch/InputSearch";
import SelectMulti from "../../components/SelectMulti/SelectMulti";
import { useModal } from "../../hooks/useModal";
import { setData } from "../../store/reducers/modalsReduces";
import Textarea from "../../components/Textarea/Textarea";
import { getFormatFIO } from "../../utils/getFormatFIO";
import Screen from "../Screen/Screen";

const ModalEditGroup = ({ info, handleChange }) => {
  const formId = useId();

  const { changeModalVisible, getInfo } = useModal();
  const dispatch = useDispatch();
  const { _id, name, director, company, profiles, description } =
    info || getInfo("editGroup", "data");

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: name,
    description: description,
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    director: {
      options: [],
      value: null,
    },
    company: {
      options: [],
      value: transformForSelect(company),
    },
    profiles: {
      options: [],
      value: [],
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const checkName = checkValidateIV("name", { minHeight: 3, maxHeight: 100 });
    const checkСompany = checkValidateOptions("company", { required: true });
    const checkDirector = checkValidateOptions("director", { required: true });

    if (!checkName || !checkСompany || !checkDirector) return;

    const res = await dispatch(
      asyncEditGroup({
        groupId: _id,
        data: {
          name: IV.name,
          description: IV.description,
          profiles: options.profiles.value?.map((e) => e.value) || [],
          company: options.company.value.value,
          director: options.director.value.value,
        },
      }),
    );

    if (res.error) return;

    handleChange && handleChange("employees:groups");
    changeModalVisible("editGroup", false, null);
  };

  useEffect(() => {
    const formatLabel = (info) => {
      const fio = getFormatFIO(info.user);
      const company = info.company ? `Компания: ${info.company.name};` : "";
      const position = info.position ? `Позиция: ${info.position.name};` : "";
      return fio + " " + "( " + company + " " + position + ")";
    };

    changeOptions(
      "profiles",
      "value",
      profiles.map((u) => ({
        label: formatLabel(u),
        value: u._id,
      })) || [],
    );

    changeOptions("director", "value", {
      label: formatLabel(director),
      value: director._id,
    });
  }, []);

  return (
    <ModalWrapper
      title={"Редактировать группу"}
      onClose={() => changeModalVisible("editGroup", false)}
      btns={
        <div className="modalEditGroup-btn-wrapper">
          <Button type="submit" title="Сохранить" color="blue" form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalEditGroup" action="#" id={formId}>
        <div className="modalEditGroup-content modalEditGroup-content">
          <h4 className="modalEditGroup-content-title">Основная информация</h4>
          <Input onChange={changeIV} value={IV.name} name="name" title="Название группы:*" />
          <Textarea
            onChange={changeIV}
            value={IV.description}
            height="170px"
            name="description"
            title="Описание:"
            placeholder="Введите описание"
          />
        </div>
        <div className="hr"></div>
        <div className="modalEditGroup-content">
          <h4 className="modalEditGroup-content-title">Руководитель</h4>
          <ul className="modalEditGroup-content-info">
            <li className="modalEditGroup-content-info-item">
              <Select
                options={options.company.options}
                value={options.company.value}
                handleChange={(data) => changeOptions("company", "value", data)}
                placeholder="Выберите компанию"
                title="Компания:*"
                forInput
                validate={checkValidateOptions("company")}
              />
            </li>
            <li className="modalEditGroup-content-info-item">
              <InputSearch
                options={options.director.options}
                value={options.director.value}
                handleChange={(data) => changeOptions("director", "value", data)}
                placeholder="Выберите руководителя"
                title="ФИО:*"
                forInput
                isDisabled={!options.company.value}
                validate={checkValidateOptions("director")}
                optionsForSearch={{
                  profile: true,
                  company: options.company.value?.value,
                }}
              />
            </li>
          </ul>
        </div>
        <div className="hr"></div>
        <div className="modalEditGroup-content modalEditGroup-content">
          <h4 className="modalEditGroup-content-title">Участники</h4>
          <InputSearch
            value={options.profiles.value}
            options={options.profiles.options}
            placeholder="Выберите участников"
            // title="Участники:"
            forInput
            handleChange={(data) => changeOptions("profiles", "value", data)}
            multi={true}
            listModal={true}
            optionsForSearch={{ profile: true }}
          />
        </div>
        <div className="hr"></div>
        <Screen size={"lg"}>
          <div className="modalEditGroup-btn-wrapper">
            <Button type="submit" title="Сохранить" color="blue" form={formId} />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditGroup;
