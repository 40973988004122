import React, { useState } from "react";
import "./ParticipantsMobile.scss";
import Pagination from "../../../../../components/Pagination/Pagination";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFilter } from "../../../../../hooks/useFilter";
import { asyncDeleteEmployeesFromProject } from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import EmployeesCardMobile from "../../../../../components/EmployeesCardMobile/EmployeesCardMobile";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import ParticipantInfoMobile from "./components/ParticipantInfoMobile/ParticipantInfoMobile";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalParticipant from "../../../../../components/ModalParticipants/ModalParticipant";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";
import { getTypePages } from "../../../../../utils/getTypePages";

function ParticipantsMobile(props) {
  const {
    setParticipantsLength,

    numberPage,
    setNumberPage,
  } = props;

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const { projectEmployees, project } = projects;

  const [activeNav, setActiveNav] = useState("genСontractor");

  const [participants, setParticipants] = useState([]);
  const [count, setCount] = useState(0);

  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(participants);

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const { setQueryParam, queries, search } = useSearch("project:participants");
  const { visible, types = [] } = useHasPermissions("project:participants", project);
  const changeActiveNav = (page) => {
    setActiveNav(page);
  };

  const deleteFromProject = async () => {
    let employees_for_delete = [];
    if (!!selectItems.length) {
      employees_for_delete = selectItems;
    } else {
      employees_for_delete.push(getInfo("deleteParticipant", "data")._id);
    }

    await dispatch(asyncDeleteEmployeesFromProject(employees_for_delete));

    changeModalVisible("deleteParticipant");
    clearItems();
    search();
  };
  useEffect(() => {
    setParticipants(projectEmployees?.users || []);
    setCount(projectEmployees?.count || 0);
    setParticipantsLength(projectEmployees?.count || 0);
  }, [projectEmployees]);

  return (
    <>
      <div className="participantsMobile">
        <div className="participantsMobile-header">
          <NavigationListMobile
            listBtn={{
              visible: true,
              type: "typePages",
            }}
            typePages={getTypePages(types)}
          />
        </div>
        <WrapperLoader
          loading={projects.loading}
          //   viewCards={viewChapterParticipants}
          chapter={"participants"}
        >
          {participants.length ? (
            <>
              <div className="participantsMobile-grid">
                {participants.map((participant) => (
                  <EmployeesCardMobile
                    changeModalVisible={changeModalVisible}
                    key={participant._id}
                    info={participant}
                    onOpenModal={(info) => {
                      changeModalVisible("infoParticipant", true, info);
                    }}
                    type={"user"}
                  />
                ))}
              </div>

              <div className="participantsMobile-pagination">
                <Pagination count={count} />
              </div>
            </>
          ) : (
            <EmptySection
              text={"В данном проекте ещё нет участников"}
              textBtn={"Добавить"}
              onClick={() => {
                changeModalVisible("addParticipant", true);
              }}
            />
          )}
        </WrapperLoader>
        {/* Информация об участнике */}
        {getVisible("infoParticipant") && (
          <ModalParticipant
            info={getInfo("infoParticipant", "data")}
            edit={getInfo("infoParticipant", "editMode")}
            changeModalVisible={changeModalVisible}
          />
        )}

        {/* Удалить участника */}
        {getVisible("deleteParticipant") && (
          <ModalDeleteItem
            headerTitle="Удалить участника"
            title="Вы уверены, что хотите удалить участника из проекта?"
            modalName="deleteParticipant"
            changeModalVisible={() => changeModalVisible("deleteParticipant")}
            onDelete={deleteFromProject}
          />
        )}
      </div>
    </>
  );
}

export default ParticipantsMobile;
