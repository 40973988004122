import classNames from "classnames";
import "./MenuDropdownWrapper.scss";

export const MenuDropdownWrapper = ({ position, children, className, type = "base" }) => {
  return (
    <div
      className={classNames(
        "menuDropdownWrapper",
        className,
        "menuDropdownWrapper-" + type,
        "menuDropdownWrapper-" + position,
      )}
    >
      {children}
    </div>
  );
};
