import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO.js";
import "./ModalDelegateTasksEmployeeRow.scss";

const ModalDelegateTasksEmployeeRow = ({ check, onCheck, info, number }) => {
  const {
    name,
    surname,
    patronymic,
    email,
    phone,
    position,
    region,
    company,
    groups,
    tasks,
  } = info;
  return (
    <li className="modalDelegateTasksEmployeeRow">
      <ul className="modalDelegateTasksEmployeeRow__list">
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-number">
          <Checkbox check={check} onCheck={onCheck} />
          <span>{number}</span>
        </li>
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-fio">
          <span>{getFormatFIO(info)}</span>
        </li>
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-email">
          <span title={email}>{email}</span>
        </li>
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-position">
          <span title={position.name}>{position.name}</span>
        </li>
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-сompany">
          <span title={company.name}>{company.name}</span>
        </li>
        <li className="modalDelegateTasksEmployeeRow__item modalDelegateTasksEmployeeRow__item-group">
          <span title={groups[0]?.name}>{groups[0]?.name}</span>
        </li>
      </ul>
    </li>
  );
};

export default ModalDelegateTasksEmployeeRow;
