import React, { useEffect, useState } from "react";
import "./NavigationMobilePanel.scss";
//import Icons from "../../../Icons/Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useSearch } from "../../../../hooks/useSearch";
import {
  profileAdmin,
  projects,
  project,
  documentation,
  employees,
  tasks,
  infoEmployee,
  settings,
} from "../../../../data/pages";

const NavigationMobilePanel = ({
  changeWiewChapter,
  active,
  currentPage: currentPageProps,
  visiblePemission,
}) => {
  const [currentPage, setCurrentPage] = useState(currentPageProps); // если требуется отправить кастомный currentPage

  const { getCurrentPage, setQueryParam, goToPage } = useSearch();

  const onChangeWiewChapter = (value) => {
    if (changeWiewChapter) changeWiewChapter(value);
    else {
      setQueryParam({ tab: value });
    }
  };

  useEffect(() => {
    if (currentPageProps) return;

    const page = getCurrentPage();

    if (page) setCurrentPage(page);
  }, [currentPageProps]);

  return (
    <nav className="navigationMobilePanel">
      <ul className="navigationMobilePanel-list">
        {/* Страница с ролями*/}
        <Swiper
          modules={[FreeMode]}
          className={`swiper-nav`}
          slidesPerView={"auto"}
          spaceBetween={10}
          freeMode={{
            enabled: true,
            sticky: true,
          }}
          slideToClickedSlide={true}
        >
          {currentPage === "profileAdmin" && (
            <>
              {profileAdmin.map((e, i) => {
                return (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => changeWiewChapter(e.chapter)}
                      className={`navigationMobilePanel-item ${
                        active === e.chapter ? "active" : ""
                      }`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {/* Проекты */}
          {currentPage === "projects" && (
            <>
              {projects.map((e, i) => {
                return (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => onChangeWiewChapter(e.chapter)}
                      className={`navigationMobilePanel-item ${
                        active === e.chapter ? "active" : ""
                      }`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {/* Проект */}
          {currentPage === "project" && (
            <>
              {project.map((e, i) => {
                return e.page === "companies" && !visiblePemission ? (
                  ""
                ) : (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => goToPage(e.chapter)}
                      className={`navigationMobilePanel-item ${active === e.page ? "active" : ""}`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {/* Документация */}
          {currentPage === "documentation" && (
            <>
              <SwiperSlide className={`swiper-nav-item`}>
                <li
                  onClick={() => goToPage("documentation", {}, { chapter: "projects" })}
                  className={`navigationMobilePanel-item ${
                    active === "projects" || active === "project" ? "active" : ""
                  }`}
                >
                  <span>Документация проектов</span>
                </li>
              </SwiperSlide>
              <SwiperSlide className={`swiper-nav-item`}>
                <li
                  onClick={() => goToPage("documentation", {}, { chapter: "companies" })}
                  className={`navigationMobilePanel-item ${
                    active === "companies" || active === "company" ? "active" : ""
                  }`}
                >
                  <span>Общая документация</span>
                </li>
              </SwiperSlide>
            </>
          )}
          {/* Страница Сотрудники */}
          {currentPage === "employees" && (
            <>
              <SwiperSlide className={`swiper-nav-item`}>
                <div
                  onClick={() => goToPage(`employees:structures`)}
                  className={`navigationMobilePanel-item ${
                    active === "structures" ? "active" : ""
                  }`}
                >
                  <span>Наша структура</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className={`swiper-nav-item`}>
                <div
                  onClick={() => goToPage(`employees:users`)}
                  className={`navigationMobilePanel-item ${active === "users" ? "active" : ""}`}
                >
                  <span>Сотрудники</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className={`swiper-nav-item`}>
                <div
                  onClick={() => goToPage(`employees:groups`)}
                  className={`navigationMobilePanel-item ${active === "groups" ? "active" : ""}`}
                >
                  <span>Группы</span>
                </div>
              </SwiperSlide>
              <SwiperSlide className={`swiper-nav-item`}>
                <div
                  onClick={() => goToPage(`employees:companies`)}
                  className={`navigationMobilePanel-item ${active === "companies" ? "active" : ""}`}
                >
                  <span>Компании</span>
                </div>
              </SwiperSlide>
            </>
          )}
          {/* Задачи */}
          {(currentPage === "tasks" || currentPage === "tasksKanban") && (
            <>
              {tasks.map((e, i) => {
                return (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => onChangeWiewChapter(e.chapter)}
                      className={`navigationMobilePanel-item ${
                        active === e.chapter ? "active" : ""
                      }`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {/* Информация о сотруднике */}
          {currentPage === "infoEmployee" && (
            <>
              {infoEmployee.map((e, i) => {
                return (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => changeWiewChapter(e.chapter)}
                      className={`navigationMobilePanel-item ${
                        active === e.chapter ? "active" : ""
                      }`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {/* Информация о сотруднике */}
          {currentPage?.split(":")[0] === "settings" && (
            <>
              {settings.map((e, i) => {
                return (
                  <SwiperSlide className={`swiper-nav-item`} key={i}>
                    <li
                      onClick={() => changeWiewChapter(e.chapter)}
                      className={`navigationMobilePanel-item ${
                        active === e.chapter ? "active" : ""
                      }`}
                    >
                      <span>{e.text}</span>
                    </li>
                  </SwiperSlide>
                );
              })}
            </>
          )}
        </Swiper>
      </ul>
    </nav>
  );
};

export default NavigationMobilePanel;
