import React from "react";
import Button from "../Button/Button";
import InputPassword from "../InputPassword/InputPassword";
import Checkbox from "../Checkbox/Checkbox";
import confidational from "../../assets/doc/confidational.docx";
import usersAgreement from "../../assets/doc/usersAgreement.docx";
import "./AuthPageForm.scss";

const AuthPageForm = ({ IV, changeIV, auth, loading, setRresetPasswordVisible }) => {
  return (
    <form onSubmit={auth} className="authPage-auth-form">
      <span className="authPage-auth-form-title">Автоматизация строительного контроля</span>
      <div className="authPage-auth-form-inputs">
        <div className="authPage-auth-form-inputs-input">
          <label className="authPage-auth-form-inputs-input-label" htmlFor="login">
            Email
          </label>
          <input
            className="authPage-auth-form-inputs-input-input"
            placeholder="Info@mail.ru"
            id="login"
            type="login"
            name="login"
            value={IV.login}
            onChange={(e) => changeIV(e)}
          />
        </div>
        <div className="authPage-auth-form-inputs-input">
          <InputPassword value={IV.password} onChange={(e) => changeIV(e)} auth />
        </div>
      </div>
      <div className="authPage-auth-form-confirm">
        <Checkbox
          className="authPage-auth-form-confirm-checbox"
          onCheck={() =>
            changeIV("set", {
              name: "confirm",
              value: !IV.confirm,
            })
          }
          check={IV.confirm}
        />
        <span className="authPage-auth-form-confirm-text">
          Я даю своё согласие на обработку персональных даннных в соответсвии с{" "}
          <a href={confidational} className="authPage-auth-form-confirm-text-link" target="_blank">
            политикой конфиденциальности
          </a>{" "}
          и{" "}
          <a href={usersAgreement} className="authPage-auth-form-confirm-text-link" target="_blank">
            условиями использования
          </a>
        </span>
      </div>
      <Button
        disabled={!IV.confirm}
        className="authPage-auth-form-btnSub"
        title="Войти"
        color="blue"
        type="submit"
        loading={loading}
      />

      <span
        onClick={() => setRresetPasswordVisible(true)}
        className="authPage-auth-form-btnResetPasw"
      >
        Забыли пароль?
      </span>
    </form>
  );
};

export default AuthPageForm;
