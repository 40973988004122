import dayjs from "dayjs";

export const monthName = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const getCalendarMatrix = (
  year = dayjs(new Date()).year(),
  month = dayjs(new Date()).mount()
) => {
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1 - 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
};

export const getCurrentDate = () => {
  return `${dayjs().format("YYYY")}-${dayjs().format("MM")}-${dayjs().format(
    "D"
  )}`;
};

export const getRangeYears = (range = 10) => {
  const year = dayjs().year();
  const years = [];
  for (let i = 0; i < range; i++) {
    years.push(year - i);
  }
  for (let i = 0; i < range; i++) {
    if (i !== 0) years.push(year + i);
  }
  return years.sort((a, b) => a - b);
};
