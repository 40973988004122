import React, { useEffect, useRef, useId } from "react";
import "./ModalAddPointInLayer.scss";
import CanvasPlan from "../../pages/desktop/Project/components/Layers/components/CanvasPlan/CanvasPlan";
import pointWhite from "../../assets/icons/point-white.svg";
import pointBlack from "../../assets/icons/point-black.svg";
import markersWhite from "../../assets/icons/markers-white.svg";
import markersBlack from "../../assets/icons/markers-black.svg";
import cleaningWhite from "../../assets/icons/cleaning-white.svg";
import cleaningBlack from "../../assets/icons/cleaning-black.svg";
import handBlack from "../../assets/icons/handBlack.svg";
import handWhite from "../../assets/icons/handWhite.svg";
import { useState } from "react";
import Button from "../Button/Button";
import { getBaseUrl } from "../../utils/getBaseUrl";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import {
  getRangePin,
  getTaskIdByRange,
  setLazy,
} from "../../pages/desktop/Project/components/Layers/components/utils";
import Screen from "../Screen/Screen";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import Icons from "../Icons/Icons";

const ModalAddPointInLayer = ({ show }) => {
  const formId = useId();
  const { sizeModal } = useSelector((state) => state.settings);
  const { getInfo, changeModalVisible } = useModal();

  const { width } = useWindowDimensions();

  const refSize = useRef();
  const refContainer = useRef();

  const modalName = show ? "showPoint" : "addPoint";

  const layerInfo = getInfo(modalName, "data");

  const [canvas, setCanvas] = useState(null);

  // * координаты, которые передали из задачи
  const [coordinates, setCoordinates] = useState("");

  // * новые координаты
  const [newCoordinates, setNewCoordinates] = useState("");

  const [drawingChoose, setDrawingChoose] = useState("watch"); // pin | draw | cleaning

  const [canvasSize, setCanvasSize] = useState({
    canvasWidth: 1070,
    canvasHeight: 756,
  });

  const changelines = (line, lazy) => {
    setLazy(lazy);

    setNewCoordinates(line);
  };

  const clearCoordinates = () => {
    canvas.clear();
    setCoordinates(JSON.stringify({ lines: [], width: 0, height: 0 }));
    setNewCoordinates(JSON.stringify({ lines: [], width: 0, height: 0 }));
  };

  const onSave = () => {
    changeModalVisible(modalName, false, { coordinates: newCoordinates });
  };

  const deleteLine = () => {
    let data = null;

    if (typeof coordinates === "string" && coordinates[0] === "{") {
      const currentCoordinates = JSON.parse(coordinates);

      const totallinesWithRange = getRangePin(currentCoordinates.lines);
      data = getTaskIdByRange(totallinesWithRange);

      if (data?.line) {
        let lines = getRangePin(currentCoordinates.lines);
        const rangeForDelete = data.line.range;

        const newLines = lines.filter((l) => {
          if (l.isPoint && !Array.isArray(rangeForDelete)) {
            if (
              !(
                l.range.x.first === rangeForDelete.x.first &&
                l.range.y.first === rangeForDelete.y.first &&
                l.range.x.last === rangeForDelete.x.last &&
                l.range.y.last === rangeForDelete.y.last
              )
            ) {
              return l;
            }
          } else {
            const { range } = l;
            const totalRange = (() => {
              if (Array.isArray(range)) {
                return range.reduce((acc, r) => (acc += +r.x + +r.y), 0);
              }
            })();
            const totalRangeForDelete = (() => {
              if (Array.isArray(rangeForDelete)) {
                return rangeForDelete.reduce((acc, r) => (acc += +r.x + +r.y), 0);
              }
            })();
            if (range.length !== rangeForDelete.length && totalRange !== totalRangeForDelete)
              return l;
          }
        });

        const newCoordinates = { ...currentCoordinates, lines: newLines };

        setCoordinates(JSON.stringify(newCoordinates));
        setNewCoordinates(JSON.stringify(newCoordinates));
      }
    }

    if (typeof newCoordinates === "string" && newCoordinates[0] === "{") {
      const currentCoordinates = JSON.parse(newCoordinates);

      const totallinesWithRange = getRangePin(currentCoordinates.lines);
      data = getTaskIdByRange(totallinesWithRange);

      if (data?.line) {
        let lines = getRangePin(currentCoordinates.lines);
        const rangeForDelete = data.line.range;

        const newLines = lines.filter((l) => {
          if (l.isPoint && !Array.isArray(rangeForDelete)) {
            if (
              !(
                l.range.x.first === rangeForDelete.x.first &&
                l.range.y.first === rangeForDelete.y.first &&
                l.range.x.last === rangeForDelete.x.last &&
                l.range.y.last === rangeForDelete.y.last
              )
            ) {
              return l;
            }
          } else {
            const { range } = l;
            const totalRange = (() => {
              if (Array.isArray(range)) {
                return range.reduce((acc, r) => (acc += +r.x + +r.y), 0);
              }
            })();
            const totalRangeForDelete = (() => {
              if (Array.isArray(rangeForDelete)) {
                return rangeForDelete.reduce((acc, r) => (acc += +r.x + +r.y), 0);
              }
            })();
            if (range.length !== rangeForDelete.length && totalRange !== totalRangeForDelete)
              return l;
          }
        });

        const newCoordinates = { ...currentCoordinates, lines: newLines };

        setCoordinates(JSON.stringify(newCoordinates));
        setNewCoordinates(JSON.stringify(newCoordinates));
      }
    }
  };

  // размер слоя от размера родителя
  const { offsetWidth: offsetWidthLayer, offsetHeight: offsetHeightLayer } = refSize.current || {};

  const resizeHandler = () => {
    if (!offsetWidthLayer || !offsetHeightLayer) return;

    if (width <= 1024) {
      setCanvasSize({
        canvasWidth: offsetHeightLayer * 1.414,
        canvasHeight: offsetHeightLayer,
      });
    } else {
      setCanvasSize({
        canvasWidth: offsetWidthLayer,
        canvasHeight: offsetWidthLayer / 1.414,
      });
    }
  };

  useEffect(() => {
    if (layerInfo?.coordinates && !coordinates) {
      setCoordinates(layerInfo.coordinates);
    }
  }, [layerInfo]);

  // useEffect(() => {
  //   resizeHandler();
  // }, [width, sizeModal]);

  useEffect(() => {
    if (width <= 1024) {
      setDrawingChoose("watch");
    }
  }, [width]);

  // useEffect(() => {
  //   resizeHandler();
  // }, [offsetWidthLayer, offsetHeightLayer]);

  return (
    <ModalWrapper
      title={modalName === "addPoint" ? "Добавить отметку" : "Просмотр слоя"}
      onClose={() => changeModalVisible(modalName, false)}
      // visibleSize={false}
      btns={
        !!show || (
          <>
            <div className="modalAddPointInLayer-main-btnChoose">
              <BtnsChooseMobile changeChoose={setDrawingChoose} drawingChoose={drawingChoose} />
            </div>
            <div className="modalAddPointInLayer-main-btns">
              <Button onClick={onSave} title="Добавить" color="blue" />
              <Button onClick={clearCoordinates} title="Очистить" color="red" />
            </div>
          </>
        )
      }
      noscroll={width <= 1024 ? false : true}
    >
      <div className="modalAddPointInLayer" ref={refContainer}>
        <div className="modalAddPointInLayer-main">
          <span className="modalAddPointInLayer-main-title">Слой:</span>
          {/* <div
            className="modalAddPointInLayer-main-wrapper"
            // style={{ height: `${contaynerSize.contaynerHeight}px` }}
 
          > */}
          <div
            className={`modalAddPointInLayer-main-plan ${drawingChoose}`}
            // style={{ width: `${width - 20}px` }}
            ref={refSize}
          >
            {drawingChoose === "cleaning" || show ? (
              <CanvasPlan
                lines={coordinates}
                changeCurrentTask={deleteLine}
                onChangelines={() => {}}
                onLoadCanvas={setCanvas}
                width={canvasSize.canvasWidth}
                height={canvasSize.canvasHeight}
                imgSrc={getBaseUrl() + layerInfo.image}
                mode={"watch"}
                onClickCanvas={deleteLine}
                buttonZoom={true}
              />
            ) : (
              <CanvasPlan
                lines={coordinates}
                changeCurrentTask={deleteLine}
                onChangelines={changelines}
                onLoadCanvas={setCanvas}
                width={canvasSize.canvasWidth}
                height={canvasSize.canvasHeight}
                imgSrc={getBaseUrl() + layerInfo.image}
                mode={drawingChoose}
                isPin={drawingChoose === "pin" ? true : false}
                colorPinByStatus={drawingChoose === "pin" ? "assigned" : "draw"}
                buttonZoom={true}
              />
            )}
          </div>
          {/* </div> */}
          <Screen size={"lg"}>
            {!!show || (
              <>
                <div className="modalAddPointInLayer-main-btnChoose">
                  <BtnsChooseMobile changeChoose={setDrawingChoose} drawingChoose={drawingChoose} />
                </div>
                <div className="d-flex justify-center gap-10">
                  <Button onClick={onSave} title="Добавить" color="blue" />
                  <Button onClick={clearCoordinates} title="Очистить" color="red" />
                </div>
              </>
            )}
          </Screen>
        </div>
      </div>
    </ModalWrapper>
  );
};

function BtnsChoose({ drawingChoose, changeChoose }) {
  return (
    <>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "cleaning" ? "active" : ""
        }`}
        onClick={() => changeChoose("cleaning")}
      >
        <img src={drawingChoose === "cleaning" ? cleaningBlack : cleaningWhite} alt="cleaning" />
      </div>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "draw" ? "active" : ""
        }`}
        onClick={() => changeChoose("draw")}
      >
        <img src={drawingChoose === "draw" ? markersWhite : markersBlack} alt="draw" />
      </div>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "pin" ? "active" : ""
        }`}
        onClick={() => changeChoose("pin")}
      >
        <img src={drawingChoose === "pin" ? pointWhite : pointBlack} alt="pin" />
      </div>
    </>
  );
}

function BtnsChooseMobile({ drawingChoose = "watch", changeChoose }) {
  return (
    <>
      {/* {drawingChoose === "watch" && (
        <Button
          color="blue"
          icon={{ only: true, type: "edit" }}
          onClick={() => changeChoose("pin")}
        />
      )}

      {drawingChoose !== "watch" && (
        <> */}

      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "watch" ? "active" : ""
        }`}
        onClick={() => changeChoose("watch")}
      >
        <img src={drawingChoose === "watch" ? handWhite : handBlack} alt="hand" />
      </div>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "cleaning" ? "active" : ""
        }`}
        onClick={() => changeChoose("cleaning")}
      >
        <img src={drawingChoose === "cleaning" ? cleaningWhite : cleaningBlack} alt="cleaning" />
      </div>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "draw" ? "active" : ""
        }`}
        onClick={() => changeChoose("draw")}
      >
        <img src={drawingChoose === "draw" ? markersWhite : markersBlack} alt="draw" />
      </div>
      <div
        className={`modalAddPointInLayer-main-btnChoose-item ${
          drawingChoose === "pin" ? "active" : ""
        }`}
        onClick={() => changeChoose("pin")}
      >
        <img src={drawingChoose === "pin" ? pointWhite : pointBlack} alt="pin" />
      </div>
    </>
    //   )}
    // </>
  );
}

export default ModalAddPointInLayer;
