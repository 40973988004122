import React, { useState, useEffect } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import "./ChatMobile.scss";
import { useModal } from "../../../hooks/useModal";
import WindowChat from "../../../components/WindowChat/WindowChat";
import ChatList from "../../../components/ChatList/ChatList";
import Screen from "../../../components/Screen/Screen";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import ModalAddInChat from "../../../components/ModalAddInChat/ModalAddInChat";
import ModalAddChat from "../../../components/ModalAddChat/ModalAddChat";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import { useSelector, useDispatch } from "react-redux";
import ModalForward from "../../../components/ModalForward/ModalForward";
import {
  asyncClearСhat,
  asyncCreateMessage,
  asyncDeleteСhat,
  asyncGetChat,
  asyncLeaveСhat,
} from "../../../store/reducers/chatReduser";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

const ChatMobile = () => {
  const dispatch = useDispatch();

  const { count } = useSelector((state) => state.chats);

  const [currentChat, setCurrentChat] = useState(null);

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const { search, params } = useSearch("chat");

  const getChats = () => {
    search();
  };

  const changeCurrentChat = async (chatId) => {
    if (chatId === null) return setCurrentChat(null);

    const res = await dispatch(asyncGetChat(chatId));

    if (res.error) return;
    setCurrentChat(res.payload);
  };

  const deleteChat = async () => {
    const { _id: chatId } = getInfo("deleteChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncDeleteСhat(chatId));
    if (!res.error) {
      await getChats();
      changeModalVisible("deleteChat", false);
      setCurrentChat(null);
    }
  };

  const clearChat = async () => {
    const { _id: chatId } = getInfo("clearChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncClearСhat(chatId));
    if (!res.error) {
      await getChats();
      changeModalVisible("clearChat", false);
      setCurrentChat(null);
    }
  };

  const leaveChat = async () => {
    const { _id: chatId } = getInfo("leaveChat", "data");
    if (!chatId) return;
    const res = await dispatch(asyncLeaveСhat({ chatId }));
    await dispatch(
      asyncCreateMessage({
        chatId,
        data: {
          text: `Покинул группу`,
          event: true,
        },
      }),
    );
    if (!res.error) {
      await getChats();
      changeModalVisible("leaveChat", false);
    }
  };

  useEffect(() => {
    if (!params.chatId) return;

    changeCurrentChat(params.chatId);
  }, [params.chatId]);

  return (
    <LayoutMobile
      search={{
        inputSearch: {
          visible: true,
          placeholder: "Найти по Ф.И.О сотрудника или названию группы",
        },
      }}
      footerBtnAddCircle={{
        visible: true,
        onClick: () => changeModalVisible("addChat", true),
      }}
      filterDisabled={true}
      footerBtnBack={
        !!currentChat && {
          visible: true,
          onClick: () => {
            changeModalVisible("addInChat", false);
            setCurrentChat(null);
          },
        }
      }
    >
      <ErrorBoundary>
        <div className="chatMobile">
          <Screen size={"sm"}>
            <ChatList currentChat={currentChat} />
            <WindowChat currentChat={currentChat} />
          </Screen>
          <Screen size={"sm"} down>
            <HeaderMobilePage
              title={currentChat ? " " : `Чаты: ${count}`}
              currentPage={"chat"}
              onClose={currentChat && (() => setCurrentChat(null))}
            />
            {currentChat ? (
              <WindowChat currentChat={currentChat} />
            ) : (
              <ChatList currentChat={currentChat} changeCurrentChat={changeCurrentChat} />
            )}
          </Screen>
        </div>
        {getVisible("menu") && (
          <ModalMobileMenu
            chapter={"chat"}
            nameModal={"menu"}
            // setCompanyLength={setCompanyLength}
          />
        )}
        {/* Модальное окно для пересылания сообщение */}
        {getVisible("forward") && <ModalForward handleChange={getChats} />}
        {/* Модальное окно для создания чата */}
        {getVisible("addChat") && <ModalAddChat handleChange={getChats} />}
        {/* Модальное окно для добавления участника */}
        {getVisible("addInChat") && <ModalAddInChat handleChange={getChats} />}
        {/* Модальное окно для удаление чата */}
        {getVisible("deleteChat") && (
          <ModalDeleteItem
            headerTitle="Удаление чата"
            title="Вы уверены, что хотите удалить этот чат?"
            changeModalVisible={() => changeModalVisible("deleteChat")}
            onDelete={deleteChat}
          />
        )}

        {/* Модальное окно для очистки чата */}
        {getVisible("clearChat") && (
          <ModalDeleteItem
            headerTitle="Очистка чата"
            title="Вы уверены, что хотите очистить этот чат?"
            changeModalVisible={() => changeModalVisible("clearChat")}
            onDelete={clearChat}
          />
        )}

        {/* Модальное окно для того чтобы покинуть чат */}
        {getVisible("leaveChat") && (
          <ModalDeleteItem
            headerTitle="Покинуть чат"
            title="Вы уверены, что хотите покинуть этот чат?"
            changeModalVisible={() => changeModalVisible("leaveChat")}
            onDelete={leaveChat}
            onDeleteTitle="Покинуть"
          />
        )}
      </ErrorBoundary>
    </LayoutMobile>
  );
};
export default ChatMobile;
