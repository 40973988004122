import React, { useState, useEffect } from "react";
import "./NotificationsMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import { SwipeableList, SwipeableListItem } from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import Icons from "../../../components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  asyncDeleteNotification,
  asyncReadNotification,
  asyncDeleteAllNotification,
  asyncReadAllNotification,
} from "../../../store/reducers/notificationsReducer";
import { useSearch } from "../../../hooks/useSearch";
import Pagination from "../../../components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ErrorBoundary } from "../../../ui";

const NotificationsMobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notificationsState = useSelector((state) => state.notifications);

  const { search } = useSearch("notifications");

  const [notifications, setNotifications] = useState([]);
  const [x1, seTx1] = useState(null);

  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const handelTouchStartEvent = (event) => {
    const firstTouch = event.touches[0];
    seTx1(firstTouch.clientX);
  };

  const removeNotification = (id) => {
    setNotification(notifications.filter((elem) => elem.id !== id));
  };

  const goToLink = (notification) => {
    if (notification.link) {
      const linkTo = notification.link;

      notification.read || toRead(notification);

      return navigate(linkTo);
    }

    notification.read || toRead(notification);
  };

  const toRead = async (notification) => {
    setLoading(true);
    const res = await dispatch(asyncReadNotification(notification._id));
    setLoading(false);

    if (res.error) return;

    const newNotifications = notifications.map((n) => {
      if (n._id === notification._id) {
        return {
          ...n,
          read: true,
        };
      }

      return n;
    });

    setNotifications(newNotifications);
  };

  const toReadAll = async () => {
    if (!count) {
      return toast.success("Нет новых уведомлений");
    }

    setLoading(true);
    const res = await dispatch(asyncReadAllNotification());
    setLoading(false);

    if (res.error) return;

    search();
  };

  const remove = async (e, notification) => {
    console.log(e);
    // e.preventDefult();

    if (!notification.read && notification.link) {
      return toast.error("Чтобы удалить уведомление, необходимо его отметить, как прочитанное");
    }

    setLoading(true);
    const res = await dispatch(asyncDeleteNotification(notification._id));
    setLoading(false);

    if (res.error) return;

    search();
  };

  const removeAll = async () => {
    if (!count) {
      return toast.success("Нет новых уведомлений");
    }

    setLoading(true);
    const res = await dispatch(asyncDeleteAllNotification());
    setLoading(false);

    if (res.error) return;

    search();
  };

  useEffect(() => {
    setNotifications(notificationsState.notifications);
    setCount(notificationsState.count);
  }, [notificationsState]);

  return (
    <LayoutMobile filterDisabled={true}>
      <ErrorBoundary>
        <div className="notificationsMobile">
          <HeaderMobilePage
            title={`Новых уведомлений: ${count}`}
            currentPage={"notifications"}
            btns={
              <div className="notificationsMobile-header-btns">
                <div onClick={toReadAll} className="notificationsMobile-header-btns-button">
                  {/* Прочесть все */}
                  <Icons
                    type={"notif"}
                    size={"xl"}
                    title={{ visible: true, text: "Удалить все уведомления" }}
                  />
                </div>
                <div onClick={removeAll} className="notificationsMobile-header-btns-button">
                  {/* Удалить все */}
                  <Icons
                    type={"cleaning"}
                    size={"xl"}
                    title={{ visible: true, text: "Удалить все уведомления" }}
                  />
                </div>
              </div>
            }
          />

          <SwipeableList>
            <div className="notificationsMobile-list">
              {notifications.map((notification, index) => {
                return (
                  <SwipeableListItem
                    key={notification._id}
                    swipeLeft={{
                      content: <Icons type={"remove"} size={"m-md"} />,
                      action: (e) => remove(e, notification),
                    }}
                    swipeRight={{
                      content: <Icons type={"infoItem"} size={"m-md"} />,
                      action: () => goToLink(notification),
                    }}
                    onSwipeProgress={(progress) => console.info(`Swipe progress: ${progress}%`)}
                  >
                    <div
                      className={`notificationsMobileItem ${loading ? "disabled" : ""}`}
                      onClick={() => toRead(notification)}
                    >
                      <div className="notificationsMobileItem-status">
                        {notification.read && <Icons type={"twoChechmark"} size={"md"} />}

                        {!notification.read && (
                          <div className="notificationsMobileItem-status-circle" />
                        )}
                      </div>
                      <span>{notification?.title}</span>
                    </div>
                  </SwipeableListItem>
                );
              })}
            </div>
          </SwipeableList>
          <Pagination count={count} type={"notifications"} />
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default NotificationsMobile;
