import React from "react";
import "./ProfilesList.scss";
import { useSelector } from "react-redux";
import EmployeeBaseInfoProfile from "../EmployeeBaseInfoProfile/EmployeeBaseInfoProfile";

const ProfilesList = () => {
  const { userData } = useSelector((state) => state.user);
  const { profiles } = userData;

  return (
    <div className="profilesList">
      <h2 className="profilesList-title">Профили</h2>
      <div className="profilesList-wrapper">
        <div className="profilesList-list">
          {!!profiles &&
            profiles.map((profile) => <EmployeeBaseInfoProfile info={profile} key={profile._id} />)}
        </div>
      </div>
    </div>
  );
};

export default ProfilesList;
