import React, { useEffect, useState } from "react";
import Button from "../../../Button/Button";
import Input from "../../../Input/Input";
import Textarea from "../../../Textarea/Textarea";
import "../../ModalEditProtocol.scss";
import { useModal } from "../../../../hooks/useModal";
import InputSearch from "../../../InputSearch/InputSearch";
import SubtaskList from "../../../SubtaskList/SubtaskList";
import ModalWrapper from "../../../ModalWrapper/ModalWrapper";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import dayjs from "dayjs";
import { getValueForOptions } from "../../utils/getValueForOptions";
import { getFormDataTasks } from "../../utils/getFormTasks";
import { useDispatch } from "react-redux";
import { asyncCreateProtocol, asyncEditProtocol } from "../../../../store/reducers/calendarReducer";
import { asyncCreateTask } from "../../../../store/reducers/tasksReducer";
import Screen from "../../../Screen/Screen";

const ModalEditProtocol = ({ handleChange, mode }) => {
  const modalName = mode === "add" ? "addProtocol" : "editProtocol";

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo, intermediateSaving } = useModal();

  const infoProtocol = getInfo(modalName, "data");

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      protocolName: infoProtocol?.name,
      protocolDescription: infoProtocol?.description,
      tasks: [],
      event: infoProtocol?.event,
    }),
    modalName,
  );

  const { options, changeOptions, checkValidateOptions } = intermediateSaving(
    useChangeOptions({
      project: {
        value: getValueForOptions(infoProtocol, "project"),
        options: [],
      },
      employees_gencontractor: {
        value: getValueForOptions(infoProtocol, "employees_gencontractor"),
        options: [],
      },
      employees_customer: {
        value: getValueForOptions(infoProtocol, "employees_customer"),
        options: [],
      },
      employees_executor: {
        value: getValueForOptions(infoProtocol, "employees_executor"),
        options: [],
      },
      secretary: {
        value: getValueForOptions(infoProtocol, "secretary"),
        options: [],
      },
    }),
    modalName,
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    let arrForValidate = [
      checkValidateIV("protocolName", { required: true, minHeight: 3 }),
      checkValidateIV("protocolDescription", { maxHeight: 5000 }),
      checkValidateOptions("employees_gencontractor", { requiredArray: true }),
      checkValidateOptions("project", { required: true }),
      checkValidateOptions("secretary", { required: true }),
    ];

    if (arrForValidate.some((el) => !el)) return;

    const dataProtocol = {};

    dataProtocol.name = IV.protocolName;
    dataProtocol.description = IV.protocolDescription;
    dataProtocol.project = options.project.value.value;
    dataProtocol.secretary = options.secretary.value.value;

    dataProtocol.employees_gencontractor = options.employees_gencontractor.value.map(
      (e) => e.value,
    );
    dataProtocol.employees_customer = options.employees_customer.value.map((e) => e.value);
    dataProtocol.employees_executor = options.employees_executor.value.map((e) => e.value);

    // редактирование протокола
    // если добавили новый задачи
    const newTasks = (IV.tasks || []).filter((task) => task._id.length !== 24);

    if (newTasks.length) {
      let formDataTasks = getFormDataTasks(newTasks.filter((task) => task._id.length !== 24));
      formDataTasks = formDataTasks.map((formDataTask) => dispatch(asyncCreateTask(formDataTask)));

      const responses = await Promise.all(formDataTasks);

      const taskIds = responses.filter((task) => !task.error).map((task) => task.payload._id);

      const currentTasks = (IV.tasks || []).filter((t) => t._id.length === 24).map((t) => t._id);

      dataProtocol.tasks = [...currentTasks, ...taskIds];
    }

    let res = { error: true };

    if (mode === "add") {
      res = await dispatch(
        asyncCreateProtocol({
          eventId: IV.event._id,
          data: dataProtocol,
        }),
      );
    } else if (mode === "edit") {
      res = await dispatch(
        asyncEditProtocol({
          protocolId: infoProtocol._id,
          data: dataProtocol,
        }),
      );
    }

    if (res.error) return;

    if (getInfo("editEvent", "data") || getInfo("infoEvent", "data")) {
      changeModalVisible(modalName, false, "prev");
    } else {
      changeModalVisible(modalName, false, null);
    }
    handleChange && handleChange();
  };

  useEffect(() => {
    changeIV("set", { name: "tasks", value: infoProtocol?.tasks || [] });
  }, []);

  useEffect(() => {
    if (getInfo("addTask", "data")) {
      changeIV("set", {
        name: "tasks",
        value: [...(infoProtocol.tasks || []), getInfo("addTask", "data")],
      });
      changeModalVisible("clear", "addTask");
    }

    if (getInfo("deleteTask", "data")) {
      const taskId = getInfo("deleteTask", "data")._id;
      changeIV("set", {
        name: "tasks",
        value: (infoProtocol.tasks || []).filter((t) => t._id !== taskId),
      });
      changeModalVisible("clear", "deleteTask");
    }
  }, []);

  const modalTitle =
    mode === "add"
      ? `Создание протокола мероприятия "${IV.event.name || ""}"`
      : `Редактирование протокола собрания №${infoProtocol.number} от ${dayjs(
          infoProtocol.createdAt,
        ).format("DD.MM.YYYY")}`;

  return (
    <ModalWrapper
      title={modalTitle}
      onClose={() => changeModalVisible(modalName, false)}
      btns={
        <div className="modalEditProtocol-btns">
          <Button type="submit" title="Сохранить" color="blue" />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalEditProtocol modal">
        <div className="modalEditProtocol-grid">
          <InputSearch
            type="project"
            forInput
            value={options.project.value}
            options={options.project.options}
            placeholder="Выберите проект"
            title="Проект:*"
            handleChange={(data) => changeOptions("project", "value", data)}
            validate={checkValidateOptions("project")}
          />
        </div>
        <Input
          title="Название:*"
          placeholder="Введите название протокола"
          name="protocolName"
          value={IV.protocolName}
          onChange={changeIV}
          validate={checkValidateIV("protocolName")}
        />
        <Textarea
          value={IV.protocolDescription}
          name="protocolDescription"
          onChange={changeIV}
          height="170px"
          title="Протокол:"
          placeholder="Опишите ход мероприятия не более 5000 символов"
          validate={checkValidateIV("protocolDescription")}
        />
        <div className="modalEditProtocol-grid">
          <InputSearch
            value={options.secretary.value}
            options={options.secretary.options}
            handleChange={(data) => changeOptions("secretary", "value", data)}
            placeholder="Выберите сотрудника"
            title="Автор протокола:*"
            menuPlacement="top"
            forInput
            validate={checkValidateOptions("secretary")}
            isDisabled={!options.project.value}
            optionsForSearch={{
              project: options.project?.value?.value,
              profile: true,
            }}
          />
        </div>
        <div className="modalEditProtocol-participants">
          <h5 className="modalEditProtocol-title">Участники:</h5>

          <InputSearch
            value={options.employees_gencontractor.value}
            options={options.employees_gencontractor.options}
            handleChange={(data) => changeOptions("employees_gencontractor", "value", data)}
            title="от Ген.подрядчика:*"
            menuPlacement="top"
            isDisabled={!options.project.value}
            multi={true}
            forInput
            validate={checkValidateOptions("employees_gencontractor")}
            optionsForSearch={{
              project: options.project?.value?.value,
              profile: true,
              from_gen_contractor: true,
            }}
          />
          <InputSearch
            value={options.employees_customer.value}
            options={options.employees_customer.options}
            handleChange={(data) => changeOptions("employees_customer", "value", data)}
            title="от Заказчика:"
            menuPlacement="top"
            isDisabled={!options.project.value}
            multi
            forInput
            optionsForSearch={{
              project: options.project?.value?.value,
              profile: true,
              from_customer: true,
            }}
          />
          <InputSearch
            value={options.employees_executor.value}
            options={options.employees_executor.options}
            handleChange={(data) => changeOptions("employees_executor", "value", data)}
            title="от Исполнителя:"
            menuPlacement="top"
            isDisabled={!options.project.value}
            multi
            forInput
            optionsForSearch={{
              project: options.project?.value?.value,
              profile: true,
              from_executor: true,
            }}
          />
        </div>

        {!!IV.tasks.length && (
          <>
            <div className="hr"></div>
            <SubtaskList
              title="Задачи:"
              buttonAddTask={false}
              mode={`protocol.edit`}
              tasks={IV.tasks || []}
              fatherId={infoProtocol._id}
            />
          </>
        )}

        <Button
          onClick={() => changeModalVisible("addTask", true, { project: options.project.value })}
          title="Задачу"
          icon={{ position: "left" }}
          disabled={!options.project.value}
          color="dark"
        />
        <Screen size={"lg"}>
          <div className="modalEditProtocol-btns">
            <Button type="submit" title="Сохранить" color="blue" />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditProtocol;
