import React, { useState } from "react";
import "./InputPassword.scss";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

const InputPassword = ({
  value,
  onChange,
  auth = false,
  title,
  placeholder,
  name,
  validate,
  autocomplete = "on",
}) => {
  const [showPassword, setshowPassword] = useState(false);
  return (
    <div className="inputPassword">
      <label
        className={`${auth ? "inputPassword-auth-label" : "inputPassword-label"}`}
        htmlFor="password"
      >
        {title ? title : "Пароль"}
      </label>
      <div className="inputPassword-wrapper">
        <i onClick={() => setshowPassword((prev) => !prev)} className="inputPassword-icon">
          <Screen size={"lg"}>
            {showPassword ? (
              <Icons
                type={"eyeOpen"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Скрыть пароль" }}
              />
            ) : (
              <Icons
                type={"eyeClose"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Показать пароль" }}
              />
            )}
          </Screen>
          <Screen size={"lg"} down>
            {showPassword ? (
              <Icons type={"eyeOpen"} size={"md"} color={"grey"} />
            ) : (
              <Icons type={"eyeClose"} size={"md"} color={"grey"} />
            )}
          </Screen>
        </i>
        <input
          placeholder={placeholder ? placeholder : "•••••••••"}
          id={name ? name : "password"}
          type={`${showPassword ? "text" : "password"}`}
          name={name ? name : "password"}
          className={`${auth ? "inputPassword-auth-input" : "inputPassword-input"} ${
            !!validate ? "error" : ""
          }`}
          value={value}
          onChange={onChange}
          autoComplete={autocomplete === "off" ? "off" : "on"}
        />
      </div>
    </div>
  );
};

export default InputPassword;
