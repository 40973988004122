import React, { useState } from "react";
import "./FolderStructure.scss";
import { asyncGetTree } from "../../store/reducers/documentationReduser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FolderStructureItem from "./components/FolderStructureItem/FolderStructureItem";
import Icons from "../Icons/Icons";

const FolderStructure = ({ headFolder, title, fatherFolder, activeFolder, setActiveFolder }) => {
  const [childrenData, setChildrenData] = useState([]);
  const dispatch = useDispatch();

  const getStructure = async (activeFolder) => {
    setActiveFolder(activeFolder);
    const res = await dispatch(
      asyncGetTree({
        head: activeFolder._id,
      }),
    );
    if (res.error) return;
    setChildrenData(res.payload.children);
  };
  useEffect(() => {
    getStructure(headFolder);
  }, []);

  return (
    <div className="folderStructure">
      <div className="folderStructure-header">
        <div className="folderStructure-header-title">
          {title} в папку {activeFolder.name || ""}
        </div>
      </div>
      <div className="folderStructure-content customScroll">
        <FolderStructureItem
          info={headFolder}
          key={headFolder?._id}
          setActiveFolder={setActiveFolder}
          activeFolder={activeFolder}
          headFolder={true}
        />
        <div className="folderStructure-content-wrapper">
          {childrenData.length > 0 &&
            childrenData.map((elem) => (
              <FolderStructureItem
                info={elem}
                key={elem._id}
                setActiveFolder={setActiveFolder}
                activeFolder={activeFolder}
                headFolder={false}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FolderStructure;
