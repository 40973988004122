import React, { useState, useEffect, useMemo } from "react";
import "./ProfileTabs.scss";
import LeftInfo from "../../../../../components/LeftInfo/LeftInfo";
import AdditionalFilterPanel from "../../../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import ProfileTabsList from "../../../../../components/ProfileTabsList/ProfileTabsList";
import roles from "../../../../../data/roles";

const ProfileTabs = ({ chapter, changeWiewChapter }) => {
  let rolesFilter = useMemo(
    () =>
      roles.find((elem) => elem.type.nameEn === chapter) || roles.find((elem) => elem.type.nameEn === "profileAdmin"),
    [chapter]
  );

  const [data, setData] = useState(rolesFilter);

  useEffect(() => {
    setData(roles.find((elem) => elem.type.nameEn === chapter));
  }, [chapter]);
  return (
    <div className="profileTabs">
      <div className="profileTabs__nav profileTabs-nav">
        <LeftInfo
          icon
          title={"Профиль"}
          onClick={() => {
            changeWiewChapter("profileEdit");
          }}
        />
        <AdditionalFilterPanel active={chapter} currentPage="profileAdmin" changeWiewChapter={changeWiewChapter} />
      </div>
      <div className="profileTabs__content">
        <ProfileTabsList chapter={chapter} data={data} />
      </div>
    </div>
  );
};

export default ProfileTabs;
