import React, { useEffect, useState } from "react";
import Button from "../../../../../../../components/Button/Button";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import SearchPanel from "../../../../../../../components/SearchPanel/SearchPanel";
import "./ModalAddCompany.scss";
import CardRowCompaniesMin from "../CardRowCompaniesMin/CardRowCompaniesMin";
import { useSelects } from "../../../../../../../hooks/useSelects";
import ModalWrapper from "../../../../../../../components/ModalWrapper/ModalWrapper";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../../../../axios/axios";
import {
  asyncAddCompaniesToProject,
  asyncGetProjectCompaniesForAdd,
} from "../../../../../../../store/reducers/projectsReducer";
import { useModal } from "../../../../../../../hooks/useModal";
import { useSearch } from "../../../../../../../hooks/useSearch";

function ModalAddCompany() {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState([]);
  const [numberPage, setNumberPage] = useState(1);
  const { _id: projectId } = useSelector((state) => state.projects.project);

  const { changeModalVisible } = useModal();

  const { search } = useSearch();

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(companies);

  const getCompanies = async (companyName = "") => {
    const res = await dispatch(
      asyncGetProjectCompaniesForAdd({
        projectId,
        numberPage,
        companyName,
      }),
    );
    if (res.error) return;

    setCompanies(res.payload.companies);
    setCount(res.payload.count);
  };

  const addCompaniesFromProject = async () => {
    await dispatch(asyncAddCompaniesToProject(selectItems));

    changeModalVisible("addCompanyToProject");
    clearItems();
    search({ nativePage: "project:companies" });
  };

  const changeSearchIV = (v) => v === "" && getCompanies();

  useEffect(() => {
    getCompanies();
  }, [numberPage]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addCompanyToProject")}
      title="Добавить компанию в проект"
      className="modalWrapperContainer"
    >
      <div className="modalAddCompany">
        <div className="modalAddCompany-searchPanel">
          <div className="modalAddCompany-searchPanel-info">
            <span>Всего компаний: {count}</span>
          </div>
          <SearchPanel
            onSubmit={(v) => getCompanies(v)}
            onChange={(v) => changeSearchIV(v)}
            inputSearch={{
              visible: true,
              placeholder: "Найти компанию по названию",
            }}
          />
        </div>
        <div className="modalAddCompany-cards">
          <div className="modalAddCompany-cards-header">
            <div className="modalAddCompany-cards-header-item number">
              <Checkbox
                onCheck={addAllItemSelect}
                check={selectItems.length === companies.length}
              />
            </div>
            <div className="modalAddCompany-cards-header-item">
              <span title="Компания:">Компания:</span>
            </div>
            <div className="modalAddCompany-cards-header-item">
              <span title="Тип:">Тип:</span>
            </div>
            <div className="modalAddCompany-cards-header-item">
              <span title="Город:">Город:</span>
            </div>
            <div className="modalAddCompany-cards-header-item">
              <span title="ФИО руководителя:">ФИО руководителя:</span>
            </div>

            <div className="modalAddCompany-cards-header-item">
              <span title="Кол-во сотрудников:">Кол-во сотрудников:</span>
            </div>
          </div>
        </div>
        <div className="modalAddCompany-cards rows">
          {companies.map((complanies) => (
            <CardRowCompaniesMin
              key={complanies}
              addItemSelect={addItemSelect}
              check={selectItems.includes(complanies._id)}
              info={complanies}
            />
          ))}
        </div>
        <div className="modalAddCompany-footer-pagination">
          <Pagination count={count} onPageChange={(n) => setNumberPage(n)} modal={true} />
        </div>
        <div className="modalAddCompany-footer-bnts">
          <Button
            onClick={addCompaniesFromProject}
            title="Добавить"
            color="blue"
            icon
            disabled={!selectItems.length}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalAddCompany;
