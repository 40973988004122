import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { unreadTotalUserMessage } from "./userReducer";

export const asyncGetChats = createAsyncThunk("chants/asyncGetChats", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.get(`/chats`, {
      params: data.params,
    });

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetUnReadMessages = createAsyncThunk(
  "chants/asyncGetUnReadMessages",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.get(`/chats/${data}/unread-count`);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetChat = createAsyncThunk("chants/asyncGetChat", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.get(`/chats/${data}`);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetMessagesChat = createAsyncThunk(
  "chants/asyncGetMessagesChat",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.get(`/chats/${data}/messages`);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateChat = createAsyncThunk("chants/asyncCreateChat", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.post(`/chats/`, data);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncAddUserToChat = createAsyncThunk(
  "chants/asyncAddUserToChat",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/chats/users/add`, data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteUserFromChat = createAsyncThunk(
  "chants/asyncDeleteUserFromChat",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.patch(`/chats/users/remove`, data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncUnreadMessages = createAsyncThunk(
  "chants/asyncUnreadMessages",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.get(`/chats/${data}/messages/unread`);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteСhat = createAsyncThunk("chants/asyncDeleteСhat", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.delete(`/chats/delete/${data}`);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncClearСhat = createAsyncThunk("chants/asyncClearСhat", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.patch(`/chats/clear/${data}`);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncLeaveСhat = createAsyncThunk("chants/asyncLeaveСhat", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.patch(`/chats/leave/`, data);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateMessage = createAsyncThunk(
  "chants/asyncCreateMessage",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/chats/${data.chatId}/messages`, data.data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncReadMessage = createAsyncThunk(
  "chants/asyncReadMessage",
  async (data, heplers) => {
    const { rejectWithValue, dispatch } = heplers;
    try {
      const res = await axios.patch(`/messages/read`, data);
      dispatch(unreadTotalUserMessage(data.messageIdList.length));
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const chatsReducer = createSlice({
  name: "chat",
  initialState: {
    chats: [],
    currentChat: null,
    messages: [],
    count: 0,
  },
  reducers: {
    setChat: (state, { payload }) => {
      const chat = state.chats.find((ch) => ch._id === payload.chatId);
      if (!chat) {
        state.chats = [...state.chats, payload.chat];
        return;
      }
      state.chats = state.chats.map((c) => {
        if (c._id === payload.chatId) {
          return payload.chat;
        }
        return c;
      });
    },
    setUnreadMessageChat: (state, { payload }) => {
      state.chats = state.chats.map((m) => {
        if (m._id === payload.chatId) {
          return {
            ...m,
            unreadMessageCount: payload.count,
          };
        }
        return m;
      });
    },
    readChat: (state, { payload }) => {
      state.chats = state.chats.map((m) => {
        if (m._id === payload.chatId) {
          return {
            ...m,
            unreadMessageCount: 0,
          };
        }
        return m;
      });
    },
    readMessages: (state, { payload }) => {
      const { messageIds, myId } = payload;
      state.messages = state.messages.map((m) => {
        if (messageIds.includes(m._id)) {
          return {
            ...m,
            toUserList: m.toUserList.map((u) => {
              if (u === myId) {
                return {
                  ...u,
                  isRead: true,
                };
              }
              return u;
            }),
          };
        }
        return m;
      });
    },
    deleteChat: (state, { payload }) => {
      state.chats = state.chats.filter((ch) => ch._id !== payload);
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetChats.fulfilled]: (state, { payload }) => {
      state.chats = payload.chatList;
      state.count = payload.chatList.length;
    },
    [asyncGetChats.rejected]: "Не удалось получить список чатов",

    [asyncGetUnReadMessages.rejected]: "Не удалось получить список непрочитанных сообщений",

    [asyncGetChat.rejected]: "Не удалось получить информацию о чате",

    [asyncGetMessagesChat.fulfilled]: (state, { payload }) => {
      state.messages = payload.messageList;
    },
    [asyncGetMessagesChat.rejected]: "Не удалось получить сообщения в чате",

    [asyncCreateChat.fulfilled]: "Чат успешно создан",
    [asyncCreateChat.rejected]: "Не удалось создать чат",

    [asyncCreateChat.fulfilled]: "Чат успешно создан",
    [asyncCreateChat.rejected]: "Не удалось создать чат",

    [asyncAddUserToChat.fulfilled]: "Пользователь успешно добавлен",
    [asyncAddUserToChat.rejected]: "Не удалось добавить пользователя",

    [asyncDeleteUserFromChat.fulfilled]: "Пользователь успешно удален",
    [asyncDeleteUserFromChat.rejected]: "Не удалось удалить пользователя",

    [asyncUnreadMessages.rejected]: "Не удалось получить не прочитанные сообщения",

    [asyncCreateMessage.rejected]: "Не удалось отправить сообщения",

    [asyncReadMessage.rejected]: "Не удалось прочесть сообщения",

    [asyncDeleteСhat.fulfilled]: "Чат успешно удален",
    [asyncDeleteСhat.rejected]: "Не удалось удалить чат",

    [asyncClearСhat.fulfilled]: "Чат успешно очищен",
    [asyncClearСhat.rejected]: "Не удалось очистить чат",

    [asyncLeaveСhat.fulfilled]: "Вы успешно покинули чат",
    [asyncLeaveСhat.rejected]: "Не удалось покинуть чат",
  }),
});

export const { setChat, deleteChat, setUnreadMessageChat, readChat, readMessages } =
  chatsReducer.actions;

export default chatsReducer.reducer;
