import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const get_status = createAsyncThunk("status/get_status", async () => {
  try {
    const res = await axios.get("/status");
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const statusReducer = createSlice({
  name: "status",
  initialState: {
    status: [],
  },
  extraReducers: {
    [get_status.fulfilled]: (state, action) => {
      const { status } = action.payload;
      state.status = status;
    },
  },
});

export default statusReducer.reducer;
