import React from "react";
import { getFormatFIO } from "../../../../../../../../utils/getFormatFIO";
import { getBaseUrl } from "../../../../../../../../utils/getBaseUrl";
import noAvatar from "../../../../../../../../assets/images/pages/noavatar.svg";
import Icons from "../../../../../../../Icons/Icons";
import "./StructuresGridCardContact.scss";

const StructuresGridCardContact = ({
  avatar,
  name,
  position,
  phone,
  mail,
  grade,
}) => {
  const baseURL = getBaseUrl();
  return (
    <div
      className={`structuresGridCardContact structuresGridCardContact-${grade}`}
    >
      <img
        className={`structuresGridCardContact-image ${
          avatar ? "structuresGridCardContact-image-avatar" : ""
        }`}
        src={avatar ? baseURL + avatar : noAvatar}
        alt="icon: user"
      />
      <div className="structuresGridCardContact-wrapper">
        <span className="structuresGridCardContact-title">
          {`${getFormatFIO(name, "F")} ${getFormatFIO(name, "I")}`}
        </span>
        <span className="structuresGridCardContact-text">{position}</span>
      </div>
      <div className="structuresGridCardContact-contact">
        <a href={`tel: ${phone}`}>
          <Icons
            type={"phone"}
            size={"xs"}
            className="structuresGridCardContact-img"
            title={{ visible: true, text: "Позвонить" }}
          />
        </a>
        <a href={`mailto: ${mail}`}>
          <Icons
            type={"mail"}
            size={"xs"}
            className="structuresGridCardContact-img"
            title={{ visible: true, text: "Написать письмо" }}
          />
        </a>
      </div>
    </div>
  );
};

export default StructuresGridCardContact;
