import React, { useEffect, useState } from "react";
import GanttChart from "./components/GanttChart/GanttChart";
import "./ProjectWorkScheduleChart.scss";

const ProjectWorkScheduleChart = ({ tasks, edit }) => {
  return (
    <div className="projectWorkScheduleChart">
      <span className="projectWorkScheduleChart-title">График:</span>
      <div className="projectWorkScheduleChart-main">
        <GanttChart
          tasksData={tasks}
          edit={edit}
          // handleOnDragEnd={handleOnDragEnd}
        />
      </div>
    </div>
  );
};

export default ProjectWorkScheduleChart;
