import React, { useEffect } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import "./ModalEventList.scss";
import { getFormatFIO } from "../../utils/getFormatFIO";
import dayjs from "dayjs";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";
import { useDispatch } from "react-redux";
import { asyncGetEvent } from "../../store/reducers/calendarReducer";
import { setData } from "../../store/reducers/modalsReduces";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import EventListItem from "./components/EventListItem/EventListItem";

const ModalEventList = () => {
  const dispatch = useDispatch();

  const { getInfo, changeModalVisible } = useModal();

  const { date, list } = getInfo("eventList", "data");
  const { edit, remove } = useHasPermissions("calendar", date);

  const onEvent = async (name, data) => {
    if (name === "info" || name === "edit") {
      const res = await dispatch(asyncGetEvent(data._id));
      if (res.error) return;
      if (name === "edit") changeModalVisible("editEvent", true, res.payload.data);
      else if (name === "info") changeModalVisible("infoEvent", true, res.payload.data);
    } else if (name === "delete") {
      changeModalVisible("deleteEvent", true, { _id: data._id });
    }
  };

  useEffect(() => {
    // * если удалили мероприятие
    if (getInfo("deleteEvent", "data")) {
      const eventId = getInfo("deleteEvent", "data")._id;
      const newList = list.filter((e) => e._id !== eventId);
      dispatch(
        setData({
          modalName: "eventList",
          data: {
            list: newList,
          },
        }),
      );
      if (!newList.length) changeModalVisible("clear", "eventList");
      changeModalVisible("clear", "deleteEvent");
    }

    // * если изменили данные мероприятия
    if (getInfo("editEvent", "data")) {
      const { _id: eventId, name, start } = getInfo("editEvent", "data");
      const newList = list
        .map((event) => {
          if (event._id === eventId) return { ...event, name };
          return event;
        })
        .filter((event) => dayjs(start).diff(event.start, "day") === 0);

      dispatch(
        setData({
          modalName: "eventList",
          data: { list: newList },
        }),
      );

      if (!newList.length) changeModalVisible("clear", "eventList");
      changeModalVisible("clear", "editEvent");
    }
  }, []);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("eventList", false)}
      title={`Список мероприятий: ${dayjs(date).format("DD.MM.YYYY")} г`}
    >
      <div className="modalEventList">
        <div className="modalEventList-list">
          {list.map((event) => (
            <EventListItem key={event._id} info={event} onEvent={onEvent} />
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalEventList;
