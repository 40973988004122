import { useState } from "react";

export const useSelects = (items) => {
  const [selectItems, setselectItems] = useState([]);
  const addItemSelect = (id) => {
    if (selectItems.includes(id)) {
      setselectItems((prev) => {
        return prev.filter((el) => el !== id);
      });
    } else {
      setselectItems((prev) => [...prev, id]);
    }
  };

  const addAllItemSelect = () => {
    if (selectItems.length === items.length) {
      return setselectItems([]);
    }
    const arr = [];
    items.forEach((el) => arr.push(el._id));
    setselectItems(arr);
  };

  const clearItems = () => {
    setselectItems([]);
  };
  return {
    selectItems,
    addItemSelect,
    addAllItemSelect,
    clearItems,
  };
};
