import React, { useState, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useModal } from "../../../../hooks/useModal";
import CardColumnTask from "../CardColumnTask/CardColumnTask";
import "./TasksColumn.scss";

const TasksColumn = (props) => {
  const { title, type, columnId, tasks = [], count = 0, loadTasks } = props;

  const { changeModalVisible } = useModal();

  const [bgDragging, setBgbgDragging] = useState("rgba(129, 129, 129, 0.2)");

  useEffect(() => {
    if (type === "assigned") {
      setBgbgDragging("rgba(127, 0, 255, 0.2)");
    } else if (type === "rejected") {
      setBgbgDragging("rgba(192, 192, 192, 0.3)");
    } else if (type === "atWork") {
      setBgbgDragging("rgba(4, 72, 142, 0.2)");
    } else if (type === "onVerification") {
      setBgbgDragging("rgba(220, 157, 0, 0.2)");
    } else if (type === "completed") {
      setBgbgDragging("rgba(22, 195, 91, 0.2)");
    } else if (type === "overdue") {
      setBgbgDragging("rgba(219, 31, 53, 0.2)");
    }
  }, [type]);

  useEffect(() => {
    const statusColumn = document.querySelector(`.tasksColumn-${columnId}`);

    const el = statusColumn.querySelector(".tasksColumn-content");

    const lastLine = el.querySelector(`.lastLine`);

    el.scrollTop = 0;

    const observer = new IntersectionObserver((entries, obs) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          obs.unobserve(entry.target);
          loadTasks(columnId);
        }
      });
    });

    if (lastLine) observer.observe(lastLine);
  }, [tasks]);

  return (
    <li id={type} className={`tasksColumn tasksColumn-${type}`}>
      <div className={`tasksColumn-header tasksColumn-header-${type}`}>
        <span className={`tasksColumn-header-title tasksColumn-header-title-${type}`}>
          {title}: {count}
        </span>
      </div>
      <Droppable droppableId={columnId} key={columnId}>
        {(provided, snapshot) => {
          return (
            <ul
              className="tasksColumn-content customScroll"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? bgDragging : "#F6F6F6",
              }}
            >
              {!!tasks.length &&
                tasks.map((task, index) => {
                  return (
                    <>
                      <CardColumnTask
                        changeModalVisible={changeModalVisible}
                        data={task}
                        key={task?._id}
                        draggableId={task?._id}
                        index={index}
                      />
                      {tasks.length - 1 === index && <hr className={`lastLine ${columnId}`} />}
                    </>
                  );
                })}
              {provided.placeholder}
            </ul>
          );
        }}
      </Droppable>
    </li>
  );
};
export default TasksColumn;
