import React, { useEffect, useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import { getFormatFIO } from "../../utils/getFormatFIO.js";
import { getFormatDate } from "../../utils/getFormatDate";
import { transformStatus } from "../../utils/transformStatus";
import "./CardRowTask.scss";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import { asyncGetTask, asyncGetSubTasks } from "../../store/reducers/tasksReducer";
import { useDispatch } from "react-redux";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import { clearDobleObj } from "../../utils/clearDobleObg";
import { createArray } from "../../utils/createArray";
import dayjs, { diff } from "dayjs";
import Screen from "../Screen/Screen";
import { profilesParsing } from "../../utils/profilesParsing";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import { useCreateStatusDay } from "../../hooks/useCreateStatusDay";
import { MenuDots } from "../../ui/index.js";

const CardRowTask = (props) => {
  const {
    info,
    data,
    handleOnDelete,
    handleOnInfo,
    handleOnEdit,
    btnInfo = true,
    btnEdit = true,
    btnDelete = true,
    onDelete,
    check,
    onCheck,
    editMode = true,
  } = props;

  let {
    number,
    executors,
    description,
    name,
    project,
    start,
    end,
    status,
    author,
    _id,
    sub_tasks,
    isImportant,
    unReadComments,
  } = data;

  const { edit, remove } = useHasPermissions(`taskCard`, data);

  const { statusDay } = useCreateStatusDay(data);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [subTask, setSubTasks] = useState([]);

  const [visibleSubTasks, setVisibleSubTasks] = useState(false);

  const { changeModalVisible } = useModal();

  const handleOnSubtaskInfo = async (info, name = "infoTask", editMode = false) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible(name, true, res.payload, editMode);
  };

  const onEvent = (name) => {
    let modalName = "infoTask";
    let editMode = false;

    if (name === "edit") {
      modalName = "editTask";
      editMode = true;
    }

    if (name === "remove") {
      modalName = "deleteTask";
    }

    return handleOnSubtaskInfo(info, modalName, editMode);
  };

  const listMenu = [
    {
      text: "Информация",
      onClick: () => onEvent("info"),
      name: "info",
      disabled: !!btnInfo,
    },
    {
      text: "Редактировать",
      onClick: () => onEvent("edit"),
      name: "edit",
      disabled: !!btnEdit && edit,
    },
    {
      text: "Удалить",
      onClick: () => onEvent("remove"),
      name: "remove",
      disabled: !!btnDelete && remove,
    },
  ];

  const handleOnSubtaskEdit = async (info) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible("editTask", true, res.payload);
  };

  // получение подзадач
  const getSubTask = async (taskId) => {
    setLoading(true);
    const res = await axios.post(`tasks/get_subtasks/${taskId}`);
    if (res.error) {
      toast.error("Не удалось получить задачу");
      return setLoading(false);
    }
    setSubTasks(res.data);
    setVisibleSubTasks(true);
    setLoading(false);
  };

  // преобразование формата профайла
  const formatInfo = (info) => {
    const company = profilesParsing("company", info);
    const position = profilesParsing("position", info);

    return `${getFormatFIO(info[0].user)} (${company ? `Компания: ${company};` : ""} ${
      position ? ` Позиция: ${position};` : ""
    })`;
  };
  // сортировка исполнителей по масиивам
  const executorsSort = (executors) => {
    const profilesSort = Object.values(
      executors.reduce((acc, profile) => {
        const name = profile?.user?._id;
        acc[name] ??= [];
        acc[name].push({ ...profile });
        return acc;
      }, {}),
    );
    return profilesSort;
  };

  const formatAuthor = (author) => {
    return `${getFormatFIO(author?.user)} (${
      author?.company?.name ? `Компания: ${author?.company?.name};` : ""
    } ${author?.position?.name ? ` Позиция: ${author?.position?.name};` : ""})`;
  };

  const unReadCommentsValue = unReadComments - 2 >= 5 ? "5+" : unReadComments;

  return (
    <li className="tasksRowCard">
      <ul
        className={`tasksRowCard-list ${isImportant ? "isImpotrant" : ""} ${
          unReadComments > 0 ? "commentsCount" : " "
        } ${editMode && _id.length === 24 ? "editMode" : ""}`}
      >
        {editMode && _id.length === 24 && (
          <li className="tasksRowCard-item tasksRowCard-item-check">
            <Checkbox check={check && check(_id)} onCheck={() => onCheck && onCheck(_id)} />
          </li>
        )}
        <Screen size={"xxxl"}>
          <li className="tasksRowCard-item tasksRowCard-item-number">
            <span title={`№ ${number}`}>№ {number || 0}</span>
          </li>
        </Screen>
        <Screen size={"xxxl"}>
          <li
            data-count={unReadCommentsValue}
            className="tasksRowCard-item tasksRowCard-item-task"
            onClick={() => onEvent("info")}
          >
            <span
              title={`Назвавние: ${name}${!!description ? `, Описание: ${description}` : ""} ${
                unReadComments > 0 ? `, Непрочитанных коментариев: ${unReadCommentsValue}` : ""
              }`}
            >
              {name}
            </span>
          </li>
        </Screen>
        <Screen size={"xxxl"} down>
          <li
            data-count={unReadCommentsValue}
            className="tasksRowCard-item tasksRowCard-item-task"
            onClick={() => onEvent("info")}
          >
            <span
              title={`Назвавние: ${name}${!!description ? `, Описание: ${description}` : ""} ${
                unReadComments > 0 ? `, Непрочитанных коментариев: ${unReadCommentsValue}` : ""
              }`}
            >
              №{number} {name}
            </span>
          </li>
        </Screen>
        <li className="tasksRowCard-item tasksRowCard-item-author">
          <span title={formatAuthor(author)}>{getFormatFIO(author?.user)}</span>
        </li>
        <li className="tasksRowCard-item tasksRowCard-item-executor">
          <span
            title={executorsSort(executors)
              .map((executor) => formatInfo(executor))
              .join(", ")}
          >
            {executorsSort(executors)
              .map((executor) => getFormatFIO(executor[0]?.user))
              .join(", ")}
          </span>
        </li>
        <Screen size={"xxxl"}>
          <li
            className="tasksRowCard-item"
            title={clearDobleObj(createArray(executors, "company"))
              .map((elem) => elem?.name)
              .join(", ")}
          >
            <span>
              {clearDobleObj(createArray(executors, "company"))
                .map((elem) => elem?.name)
                .join(", ")}
            </span>
          </li>
        </Screen>
        <li className="tasksRowCard-item tasksRowCard-item-nameProject">
          <span title={project?.name || "-"}>{project?.name || "-"}</span>
        </li>
        <li
          className="tasksRowCard- tasksRowCard-item-period"
          title={`Поставлена ${getFormatDate(start)} - Крайний срок ${getFormatDate(end)}`}
        >
          <span className="tasksRowCard-item-startDate">{getFormatDate(start)}</span>
          <span>-</span>
          <span className="tasksRowCard-item-endDate">{getFormatDate(end)}</span>
        </li>

        <li className={`tasksRowCard-item tasksRowCard-item-day ${statusDay.type}`}>
          <span title={statusDay.title}>
            <Screen size={"xxl"}>
              {statusDay.text} {statusDay.day}
            </Screen>
            <Screen size={"xxl"} down>
              {statusDay.type === "overdue" ? "-" : ""}
              {statusDay.day}
            </Screen>
          </span>
        </li>

        <li className={`tasksRowCard-item tasksRowCard-item-status ${status}`}>
          <span title={transformStatus(status).name}>{transformStatus(status).name}</span>
        </li>
        <li className="tasksRowCard-item tasksRowCard-btns">
          {sub_tasks > 0 &&
            (loading ? (
              <div className="tasksRowCard-btn">
                {" "}
                <div className="submit-spinner"></div>
              </div>
            ) : (
              <div
                className="tasksRowCard-btn"
                onClick={() => (visibleSubTasks ? setVisibleSubTasks(false) : getSubTask(_id))}
              >
                <Icons
                  type={"tasksIcon"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "Подазадачи" }}
                />
              </div>
            ))}
          <MenuDots list={listMenu} />
          {/* {!!btnInfo && (
            <div className="tasksRowCard-btn" onClick={() => onEvent("info")}>
              <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </div>
          )}

          {!!btnEdit && edit && (
            <div className="tasksRowCard-btn" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </div>
          )}

          {!!btnDelete && remove && (
            <div
              onClick={() => (onDelete ? onDelete(_id) : onEvent("remove"))}
              className="tasksRowCard-btn"
            >
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </div>
          )} */}
        </li>
      </ul>
      {visibleSubTasks && (
        <ul className="tasksRowCard-subtasks">
          {subTask?.map((task, index) => (
            <CardRowTask
              info={task}
              data={task}
              key={task._id}
              onCheck={(taskId) => onCheck(taskId || task._id)}
              check={(taskId) => check(taskId)}
              onDelete={onDelete}
              handleOnInfo={() => handleOnSubtaskInfo(task)}
              handleOnEdit={() => handleOnSubtaskEdit(task)}
              editMode={editMode}
              btnInfo={btnInfo}
              btnEdit={btnEdit}
              btnDelete={btnDelete}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
export default CardRowTask;
