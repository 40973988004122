import React from "react";
import timeIcon from "../../assets/icons/time-black.svg";
import "./InputTime.scss";
import Icons from "../Icons/Icons";
import dayjs from "dayjs";

function InputTime(props) {
  const { value, onChange, title, textBlack } = props;

  return (
    <div className="inputTime">
      {!!title && <span className="inputTime-title">{title}</span>}
      <div className="inputTime-input">
        <input
          onChange={(e) => onChange(e.target.value)}
          type="time"
          name="inputTime"
          value={value || dayjs().format("HH:mm")}
        />
        {/* <div className="inputTime-input-icon">
          <Icons type={"timeIcon"} size={"md"} cursor />
        </div> */}
      </div>
    </div>
  );
}

export default InputTime;
