import React, { useState, useRef, useId } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useModal } from "../../hooks/useModal";
import { asyncEditLayers } from "../../store/reducers/projectsReducer";
import { getBaseUrl } from "../../utils/getBaseUrl";

import "./ModalEditLayer.scss";
import Screen from "../Screen/Screen";

const ModalEditLayer = (props) => {
  const { handleChange } = props;
  const formId = useId();
  const { getInfo, changeModalVisible } = useModal();
  const imageRef = useRef();
  const infoLayer = getInfo("editLayer", "data");

  const { name, image } = infoLayer;

  const dispatch = useDispatch();

  const [choose, setChoose] = useState("basicInfo"); // basicInfo | tasks
  const [tasks, setTasks] = useState([]);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: name,
    file: image ? image : null,
  });

  const uploadsFile = (event) => {
    imageRef.current.click();
    event.preventDefault();
    const file = event.target.files[0];
    changeIV("set", { name: "file", value: file });
  };

  const deleteFile = () => {
    return changeIV("set", { name: "file", value: null });
  };

  const getImage = () => {
    if (typeof IV.file === "object") return URL.createObjectURL(IV.file);
    return getBaseUrl() + IV.file;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3 })) return;

    const formData = new FormData();
    formData.append("name", IV.name);
    formData.append("image", infoLayer.image ? IV.file || "remove" : IV.file);

    await dispatch(
      asyncEditLayers({
        layer_id: infoLayer._id,
        formData,
      }),
    );

    handleChange && handleChange();
    changeModalVisible("editLayer", false);
  };

  useEffect(() => {
    if (choose === "tasks") {
    }
  }, [choose]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("editLayer")}
      title="Редактировать слой"
      btns={
        <div className="modalEditLayer-basicInfo-btns">
          <div className="modalEditLayer-basicInfo-btns-wrapper">
            <input
              type="file"
              id="filePlan"
              onChange={(event) => {
                uploadsFile(event);
                event.target.value = "";
              }}
              ref={imageRef}
            />
            <label htmlFor="filePlan"></label>
            <Button title="Загрузить" color="dark" onClick={(event) => uploadsFile(event)} />
            <Button onClick={() => deleteFile("delete")} title="Удалить" color="red" />
          </div>
          <div className="modalEditLayer-basicInfo-btns-submit">
            <Button type="submit" title="Сохранить" color="blue" id={formId} />
          </div>
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalEditLayer" form={formId}>
        <div className="modalEditLayer-basicInfo">
          <Input
            placeholder="Введите название слоя"
            title="Название слоя:"
            value={IV.name}
            onChange={changeIV}
            name="name"
            validate={checkValidateIV("name")}
          />
          <div className="modalEditLayer-basicInfo-plan">
            <span className="modalEditLayer-basicInfo-plan-title">Слой:</span>
            {IV.file && (
              <div className="modalEditLayer-basicInfo-plan-wrapper">
                <div
                  style={{ backgroundImage: `url(${getImage()})` }}
                  className="modalEditLayer-basicInfo-plan-img"
                ></div>
              </div>
            )}
          </div>
          <Screen size={"lg"}>
            <div className="modalEditLayer-basicInfo-btns">
              <div className="modalEditLayer-basicInfo-btns-wrapper">
                <input
                  type="file"
                  id="filePlan"
                  onChange={(event) => {
                    uploadsFile(event);
                    event.target.value = "";
                  }}
                  ref={imageRef}
                />
                <label
                  htmlFor="filePlan"
                  className="modalEditLayer-basicInfo-btns-filePlan"
                ></label>
                <Button title="Загрузить" color="dark" onClick={(event) => uploadsFile(event)} />
                <Button onClick={() => deleteFile("delete")} title="Удалить" color="red" />
              </div>
              <div className="modalEditLayer-basicInfo-btns-submit">
                <Button type="submit" title="Сохранить" color="blue" />
              </div>
            </div>
          </Screen>
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditLayer;
