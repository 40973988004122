import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { SECRET_KEY } from "../../utils/secret";
import { toast } from "react-toastify";
import decode from "jwt-decode";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitElements } from "../../utils/getLimitElements";

export const asyncLogin = createAsyncThunk("user/asyncLogin", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("/users/login", data);

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncRestoteAccess = createAsyncThunk(
  "user/asyncRestoteAccess",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post("/users/restore_access", data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetFullInfo = createAsyncThunk("user/asyncGetFullInfo", async (_, helpers) => {
  const { getState, rejectWithValue } = helpers;
  try {
    const { _id: user_id } = getState().user.userData;
    const res = await axios.post(`/users/get_user/${user_id}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetUserProfiles = createAsyncThunk(
  "user/asyncGetUserProfiles",
  async (data, helpers) => {
    const { getState, rejectWithValue } = helpers;
    try {
      const { _id } = getState().user.userData;
      const res = await axios.post(`/users/get_profiles/${_id}`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetUsers = createAsyncThunk("user/asyncGetUsers", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    console.log(data?.params);
    const limit = data?.params?.limit || getLimitElements();
    const res = await axios.post(`/users/get/${data.page}/?limit=${limit}`, data.params);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetUser = createAsyncThunk("user/asyncGetUser", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/users/get_user/${data}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateUser = createAsyncThunk("user/asyncCreateUser", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("/users/create", data);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditUser = createAsyncThunk("user/asyncEditUser", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const { formData, user_id } = data;
    const res = await axios.patch(`/users/edit/${user_id}`, formData);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditProfile = createAsyncThunk("user/asyncEditProfile", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`/users/edit_profile/${data.userId}`, data.data);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncResetPassword = createAsyncThunk(
  "user/asyncResetPassword",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post("/users/reset", data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncRemoveUser = createAsyncThunk("user/asyncRemoveUser", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios({
      url: `/users/remove/${data.user_id}`,
      method: "DELETE",
      data,
    });

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetAlloweNotification = createAsyncThunk(
  "user/asyncGetAlloweNotification",
  async (_, helpers) => {
    const { rejectWithValue, getState } = helpers;
    try {
      const userId = getState().user.userData._id;
      const res = await axios.post(`/userNotifications/get/${userId}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditAlloweNotification = createAsyncThunk(
  "user/asyncEditAlloweNotification",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/userNotifications/edit/${data.userId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncUnconnectAccount = createAsyncThunk(
  "user/asyncUnconnectAccount",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/users/unconnect/${data.userId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userReducer = createSlice({
  name: "user",
  initialState: {
    isAuth: false,
    loading: false,
    error: null,
    userData: null,
    users: [],
    employeeInfo: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      state.isAuth = false;
    },
    checkIsAuth: (state) => {
      const token = localStorage.getItem("token");

      if (token) {
        const userData = decode(token, SECRET_KEY);
        state.userData = userData.data;
        state.isAuth = true;
      } else {
        state.userData = null;
        state.isAuth = false;
      }
    },
    setUser: (state, { payload }) => {
      if (payload.user) {
        state.users = state.users.map((user) => {
          if (user._id === payload.user._id) {
            return payload.user;
          }
          return user;
        });
      }
    },
    unreadTotalUserMessage: (state, { payload }) => {
      if (payload <= 0) return;

      if (state?.userData?.unreadMessages <= 0) return;

      state.userData.unreadMessages = state.userData.unreadMessages - payload;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncLogin.fulfilled]: (state, { payload }) => {
      const { token, refresh } = payload;

      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("refresh", refresh);

        state.userData = decode(token, SECRET_KEY)?.data;
        state.isAuth = true;

        toast.success("Вы успешно авторизовались");
      }
    },
    [asyncLogin.rejected]: "Не корректные данные для входа.",

    [asyncGetFullInfo.fulfilled]: (state, { payload }) => {
      if (state.userData) state.userData = { ...state.userData, ...payload };
      else state.userData = payload;
    },
    [asyncGetFullInfo.rejected]: (state, { payload }) => {
      if (payload.response.status === 401) {
        state.isAuth = false;
        localStorage.removeItem("token");
      }
      console.log(payload);
      toast.error("Не удалось получить данные пользователя.");
    },

    [asyncGetUserProfiles.rejected]: "Не удалось получить профайлы пользователя.",

    [asyncGetUsers.fulfilled]: (state, { payload }) => {
      state.users = payload.users;
      state.count = payload.count;
    },

    [asyncGetUsers.rejected]: "Не удалось получить пользователей.",

    [asyncGetUser.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.employeeInfo = payload;
    },
    [asyncGetUser.rejected]: "Не удалось получить пользователя.",

    [asyncEditUser.fulfilled]: "Данные успешно изменены",
    [asyncEditUser.rejected]: "Не удалось изменить пользователя.",

    [asyncEditProfile.fulfilled]: "Данные профайла успешно изменены",
    [asyncEditProfile.rejected]: "Не удалось изменить профайл пользователя.",

    [asyncCreateUser.fulfilled]: "Пользователь добавлен",
    [asyncCreateUser.rejected]: "Не удалось добавить пользователя.",

    [asyncResetPassword.fulfilled]: "Пароль успешно изменен",
    [asyncResetPassword.rejected]: "Не удалось сбросить пароль.",

    [asyncRemoveUser.fulfilled]: "Сотрудник успешно удален",
    [asyncRemoveUser.rejected]: "Не удалось удалить сотрудника.",

    [asyncGetAlloweNotification.fulfilled]: (state, { payload }) => {
      state.userData = {
        ...state.userData,
        userNotifications: payload,
      };
    },
    [asyncGetAlloweNotification.rejected]: "Не удалось получить уведомления.",

    [asyncEditAlloweNotification.fulfilled]: "Уведомление успешно изменено",
    [asyncEditAlloweNotification.rejected]: "Не удалось изменить уведомление.",

    [asyncUnconnectAccount.fulfilled]: "Аккаунт успешно отвязан",
    [asyncUnconnectAccount.rejected]: "Не удалось отвзять аккаунт.",
  }),
});

export const { login, logout, checkIsAuth, setUser, unreadTotalUserMessage } = userReducer.actions;

export default userReducer.reducer;
