import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

export const asyncGetStructures = createAsyncThunk("structures/asyncGetStructures", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`structures/get`, data.params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateDepartament = createAsyncThunk("structures/asyncCreateDepartament", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("structures/create", data);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditDepartament = createAsyncThunk("structures/asyncEditDepartament", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`structures/edit/${data.departmentId}`, data.data);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncRemoveDepartament = createAsyncThunk("structures/asyncRemoveDepartament", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios({
      method: "DELETE",
      url: `structures/remove/${data}`,
    });
    if (res.data) return res.data;
  } catch (error) {
    rejectWithValue(error);
  }
});

const structuresReducer = createSlice({
  name: "structures",
  initialState: {
    structures: null,
    structure: null,
    count: 0,
    edit: false,
    loading: false,
    error: null,
  },
  reducers: {
    set_structures: (state, action) => {
      const { structures } = action.payload;
      state.structures = structures;
    },
    set_structure: (state, action) => {
      const { structure } = action.payload;
      state.structure = structure;
    },
    set_edit_structure_mode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetStructures.fulfilled]: (state, { payload }) => {
      state.structures = payload.structures;
      state.count = payload.count;
    },
    [asyncGetStructures.rejected]: "Не удалось получить департамент",

    [asyncCreateDepartament.fulfilled]: () => toast.success("Отдел успешно создан"),
    [asyncCreateDepartament.rejected]: "Не удалось создать отдел",

    [asyncEditDepartament.fulfilled]: () => toast.success("Отдел успешно изменен"),
    [asyncEditDepartament.rejected]: "Не удалось изменить отдел",

    [asyncRemoveDepartament.fulfilled]: () => toast.success("Отдел успешно удален"),
    [asyncRemoveDepartament.rejected]: "Не удалось удалить отдел",
  }),
});

export const { set_structure, set_edit_structure_mode } = structuresReducer.actions;

export default structuresReducer.reducer;
