import React from "react";
import "./SettingsEmployees.scss";
import AddedPosition from "./components/AddedPosition/AddedPosition";

const SettingsEmployees = () => {
  return (
    <div className="settingsEmployees">
      <AddedPosition />
    </div>
  );
};

export default SettingsEmployees;
