import React, { useEffect, useState } from "react";
import { useModal } from "../../hooks/useModal";
import { getFormatFIO } from "../../utils/getFormatFIO";
import "./TasksGridCardMobile.scss";
import { getFormatStatus } from "../../utils/getFormatStatus";
import { getFormatDate } from "../../utils/getFormatDate";
import CardGridHeader from "../CardGridHeader/CardGridHeader";
import Icons from "../Icons/Icons";
import { useCreateStatusDay } from "../../hooks/useCreateStatusDay";

const TasksGridCardMobile = ({ info }) => {
  const {
    status,
    name,
    author,
    executors,
    start,
    end,
    sub_tasks,
    number,
    isImportant,
    unReadComments,
  } = info;
  const { changeModalVisible } = useModal();
  const { statusDay } = useCreateStatusDay(info);
  // сортировка исполнителей по масиивам
  const executorsSort = (executors) => {
    const profilesSort = Object.values(
      executors.reduce((acc, profile) => {
        const name = profile.user._id;
        acc[name] ??= [];
        acc[name].push({ ...profile });
        return acc;
      }, {}),
    );
    return profilesSort;
  };
  console.log(statusDay);
  return (
    <div className="tasksGridCardMobile">
      <div className="tasksGridCardMobile-header">
        <CardGridHeader
          title={"Статус:"}
          text={getFormatStatus(status).text}
          onClick={() => changeModalVisible("menuTasks", true, info, false, false, "taskCard")}
          styleText={{ color: getFormatStatus(status).color }}
        />
        <div
          className="tasksGridCardMobile-header-content"
          onClick={() => changeModalVisible("infoTask", true, info)}
        >
          {isImportant && <Icons type={"fire"} size={"md"} />}
          {unReadComments > 0 && (
            <div className="tasksGridCardMobile-commentsCount">
              <span>{unReadComments}</span>
            </div>
          )}
          <span className="tasksGridCardMobile-header-content-title">
            №{number} {name}
          </span>
        </div>
      </div>
      <ul
        className="tasksGridCardMobile-content"
        onClick={() => changeModalVisible("infoTask", true, info)}
      >
        <li className="tasksGridCardMobile-content-item">
          <span className="tasksGridCardMobile-content-item-title">Проект:</span>
          <span className="tasksGridCardMobile-content-item-text">
            {info?.project?.name || "-"}
          </span>
        </li>
        <li className="tasksGridCardMobile-content-item">
          <span className="tasksGridCardMobile-content-item-title">Срок выполнения:</span>
          <span className="tasksGridCardMobile-content-item-text">
            {getFormatDate(start)} - {getFormatDate(end)}
          </span>
        </li>
        <li className="tasksGridCardMobile-content-item">
          <span className="tasksGridCardMobile-content-item-title">Автор:</span>
          <span className="tasksGridCardMobile-content-item-text">
            {getFormatFIO(author?.user)}
          </span>
        </li>
        <li className="tasksGridCardMobile-content-item  executors">
          <span className="tasksGridCardMobile-content-item-title">Исполнитель:</span>
          <span className="tasksGridCardMobile-content-item-text executors">
            {executorsSort(executors)
              .map((executor) => getFormatFIO(executor[0]?.user))
              .join(", ")}
          </span>
        </li>
        {statusDay.type !== "" && (
          <li className="tasksGridCardMobile-content-item">
            <span className="tasksGridCardMobile-content-item-title">{statusDay?.text}</span>
            <span className={`tasksGridCardMobile-content-item-text ${statusDay.type}`}>
              {statusDay?.day}
            </span>
          </li>
        )}

        <li className="tasksGridCardMobile-content-item">
          <span className="tasksGridCardMobile-content-item-title">Подзадач:</span>
          <span className="tasksGridCardMobile-content-item-text">{sub_tasks?.length || 0}</span>
        </li>
      </ul>
    </div>
  );
};

export default TasksGridCardMobile;
