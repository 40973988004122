import React from "react";
import "./CompanyProfileRow.scss";
import Icons from "../../../../../Icons/Icons";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import { useSelector } from "react-redux";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";
import { useModal } from "../../../../../../hooks/useModal";
import { MenuDots } from "../../../../../../ui";

const CompanyProfileRow = ({ info }) => {
  const { name, profiles } = info;
  const { changeModalVisible } = useModal();

  const { company } = useSelector((state) => state.companies);

  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const createUser = () => {
    return profiles
      .map((profile) => {
        return getFormatFIO(profile.user, "full");
      })
      .join(", ");
  };

  const onEvent = (name) => {
    if (name === "info") {
      return changeModalVisible("infoEditProfiles", true, info);
    }
    if (name === "edit") return changeModalVisible("infoEditProfiles", true, info, true);
  };

  const listMenu = [
    {
      text: "Информация",
      onClick: () => onEvent("info"),
      name: "info",
    },
    {
      text: "Редактировать",
      onClick: () => onEvent("edit"),
      name: "edit",
      disabled: editPermission,
    },
  ];

  return (
    <ul className="companyProfileRow">
      <li
        className="companyProfileRow-item companyProfileRow-item-name"
        onClick={() => onEvent("info")}
      >
        <span>{name}</span>
      </li>
      <li className="companyProfileRow-item companyProfileRow-item" title={createUser()}>
        <span>{createUser()}</span>
      </li>

      <li className="companyProfileRow-btns">
        <MenuDots list={listMenu} />
        {/* <button className="employeesListRow__btn" onClick={() => onEvent("info")}>
          <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
        </button>
        {editPermission && (
          <button className="employeesListRow__btn" onClick={() => onEvent("edit")}>
            <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
        )} */}
      </li>
    </ul>
  );
};

export default CompanyProfileRow;
