import React, { useEffect } from "react";
import "./EmployeesList.scss";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import EmployeesListRow from "../../../../../components/EmployeesListRow/EmployeesListRow";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSelector } from "react-redux";
import Pagination from "../../../../../components/Pagination/Pagination";
import EmployeesListGridCard from "./components/EmployeesListGridCard/EmployeesListGridCard";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

const EmployeesList = ({ viewCards, setEmployeeLength }) => {
  const { changeModalVisible } = useModal();

  const { add } = useHasPermissions("employees:users");

  const { params } = useSearch("employees:users");

  const pageNumber = params.page;

  const { users, count, loading } = useSelector((state) => state.user);

  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(users);

  const getNumber = (i) => i + 1 + users.length * pageNumber - users.length;

  useEffect(() => {
    setEmployeeLength(count);
  }, [users]);

  return (
    <section className="employeesList">
      <div className="employeesList-searchPanel employeesList-searchPanel">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти сотрудника по ФИО",
          }}
          buttons={{
            list: add && [
              {
                title: "Создать",
                // icon: true,
                onClick: () => {
                  changeModalVisible("addEmployee", true);
                },
              },
            ],
          }}
        />
      </div>
      <div className="employeesList-content employeesList-content">
        <WrapperLoader loading={loading} viewCards={viewCards} chapter={"employees"}>
          {users.length > 0 ? (
            viewCards === "rows" ? (
              <div className="employeesList-row customScroll">
                <div className="employeesList-row-wrapper">
                  {/* {visibleHeader && (
                        <RowActiveHeader
                          check={selectItems.length === users.length}
                          onCheck={addAllItemSelect}
                          onDelete={() =>
                            changeModalVisible("deleteEmployee", true)
                          }
                        />
                      )} */}
                  <ul className="employeesList-row-header">
                    {/* <Checkbox
                          check={selectItems.length === users.length}
                          onCheck={addAllItemSelect}
                        /> */}
                    <li className="employeesList-row-header-item employeesList-row-header-item-fio">
                      <span title="ФИО">ФИО:</span>
                    </li>
                    <li className="employeesList-row-header-item employeesList-row-header-item-сompany">
                      <span title="Компания">Компания:</span>
                    </li>
                    <li className="employeesList-row-header-item employeesList-row-header-item-position">
                      <span title="Должность">Должность:</span>
                    </li>
                    <li className="employeesList-row-header-item employeesList-row-header-item-email">
                      <span title="Email">Email:</span>
                    </li>
                    <li className="employeesList-row-header-item employeesList-row-header-item-tel">
                      <span title="Телефон">Телефон:</span>
                    </li>

                    {/* <li className="employeesList-row-header-item employeesList-row-header-item-region">
                      <span title="Город">Город:</span>
                    </li> */}

                    {/* <li className="employeesList-row-header-item employeesList-row-header-item-group">
                          <span title="Группа">Группа:</span>
                        </li> */}
                    <li className="employeesList-row-header-item employeesList-row-header-item-task">
                      <span title="Задачи">Задачи:</span>
                    </li>
                  </ul>
                  <ul className="employeesList-row-list">
                    {users.map((employee, index) => (
                      <EmployeesListRow
                        key={employee._id}
                        info={employee}
                        number={getNumber(index)}
                        onCheck={() => addItemSelect(employee._id)}
                        check={selectItems.includes(employee._id)}
                        employees={users}
                        type="employees"
                      />
                    ))}
                  </ul>
                </div>
                <Pagination count={count} />
              </div>
            ) : (
              <>
                <div className="employeesList-grid customScroll">
                  <ul className="employeesList-grid-list">
                    {users.map((employee, index) => (
                      <EmployeesListGridCard
                        key={employee._id}
                        info={employee}
                        number={getNumber(index)}
                        employees={users}
                      />
                    ))}
                  </ul>
                  <Pagination count={count} />
                </div>
              </>
            )
          ) : (
            !loading && (
              <EmptySection
                text={"Сотрудники не добавлены"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addEmployee", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
    </section>
  );
};
export default EmployeesList;
