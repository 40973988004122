import React, { useEffect, useState } from "react";
import "./TasksMobile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFilter } from "../../../hooks/useFilter";
import { useModal } from "../../../hooks/useModal";
import { columnsFromBackend } from "../../../utils/columnsFromBackend";
import {
  asyncDeleteSubTask,
  asyncDeleteTask,
  asyncGetTasks,
} from "../../../store/reducers/tasksReducer";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import TasksGridMobile from "../../../components/TasksGridMobile/TasksGridMobile";
import EmptySection from "../../../components/EmptySection/EmptySection";
import TasksDragDrop from "../../../components/TasksDragDrop/TasksDragDrop";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalEditTask from "../../../components/ModalEditTask/ModalEditTask";
import ModalTask from "../../../components/ModalTask/ModalTask";
import ModalPutAside from "../../../components/ModalPutAside/ModalPutAside";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalDelegationTask from "../../../components/ModalDelegationTask/ModalDelegationTask";
import ModalProject from "../../../components/ModalProject/ModalProject";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalAddDellEmployees from "../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

const TasksMobile = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { search, queries, goToPage } = useSearch("tasks");

  const { tasks, count, loading } = useSelector((state) => state.tasks);

  const [viewCards, setViewCards] = useState("rows");
  const [chapter, setChapter] = useState("all");

  const getTasks = async () => search();

  const deleteTask = async () => {
    const idArray = [];
    if (Array.isArray(getInfo("deleteTask", "data"))) {
      idArray.push(...getInfo("deleteTask", "data"));
    } else {
      idArray.push(getInfo("deleteTask", "data"));
    }
    await dispatch(asyncDeleteTask({ tasks: idArray }));
    await getTasks();

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }
    changeModalVisible("deleteTask", false);
  };

  const deleteSubTask = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteSubTask", "data"));
    if (!getInfo("copyTask", "data") && !getInfo("addTask", "data")) {
      await dispatch(asyncDeleteSubTask({ tasks: idArray }));
      await getTasks();
    }

    changeModalVisible("deleteSubTask", false, "prev");
  };

  const changeViewCard = (value) => {
    if (value === "rows") return goToPage("tasks");
    goToPage("tasksKanban", {}, { tab: "all" });
  };

  useEffect(() => {
    if (!queries.tab) return setChapter("all");

    setChapter(queries.tab);
  }, [queries.tab]);

  return (
    <LayoutMobile
      search={{
        inputSearch: {
          visible: true,
          placeholder: "Найти задачу по названию или №",
        },
      }}
      viewCards={viewCards}
      onViewClick={changeViewCard}
      footerBtnRotate={!getVisible("infoTask") && { visible: true }}
      //filterOnAccept={() => getTasks()}
      filter={{ visible: true }}
      filterDisabled={getVisible("infoTask") ? true : false}
      footerBtnAddCircle={
        !getVisible("infoTask") && {
          visible: true,
          onClick: () => changeModalVisible("addTask", true),
        }
      }
      footerBtnBack={
        getVisible("infoTask") && {
          visible: true,
          onClick: () => changeModalVisible("infoTask", false),
        }
      }
    >
      <ErrorBoundary>
        <HeaderMobilePage title={`Всего задач: ${count}`} active={chapter} visibleNav={true} />
        <WrapperLoader loading={loading}>
          <div className="tasks-mobile">
            {viewCards === "rows" &&
              (!!count ? (
                <TasksGridMobile />
              ) : (
                !loading && (
                  <EmptySection
                    text={"Задач не создано"}
                    textBtn={"Добавить"}
                    onClick={() => {
                      changeModalVisible("addTask", true);
                    }}
                  />
                )
              ))}
            {viewCards === "columns" && <TasksDragDrop onDrag={search} />}
          </div>
        </WrapperLoader>

        {getVisible("menuTasks") && (
          <ModalMobileMenu
            changeModalVisible={changeModalVisible}
            chapter={"tasks"}
            nameModal={"menuTasks"}
            // setCompanyLength={setCompanyLength}
            onClose={() => changeModalVisible("menuTasks", false)}
          />
        )}
        {/* Информация задачи */}
        {getVisible("infoTask") && <ModalTask handleChange={getTasks} />}

        {/* Редактирование задачи */}
        {getVisible("editTask") && <ModalEditTask getTasks={getTasks} mode={"edit"} />}

        {/* Добавление задачи */}
        {getVisible("addTask") && <ModalEditTask getTasks={getTasks} mode={"add"} />}

        {/* Редактирование подзадачи при добавлении */}
        {getVisible("editSubTask") && <ModalEditTask getTasks={getTasks} mode={"editSubTask"} />}

        {/* Добавление подзадачи */}
        {getVisible("addSubTask") && <ModalEditTask getTasks={getTasks} mode={"subTask"} />}

        {/* Копирование задачи */}
        {getVisible("copyTask") && <ModalEditTask getTasks={getTasks} mode={"copy"} />}

        {/* Отложить задачу */}
        {getVisible("putAsideTask") && <ModalPutAside />}

        {/* Делегирование задачи */}
        {getVisible("delegationTask") && <ModalDelegationTask getTasks={getTasks} />}

        {/* Добавить рисунок или поинт */}
        {getVisible("addPoint") && <ModalAddPointInLayer />}

        {/* Просмотр рисунока */}
        {getVisible("showPoint") && <ModalAddPointInLayer show />}

        {/* Отложить задачу */}
        {getVisible("putAsideTask") && <ModalPutAside />}

        {/* Удалить задачу */}
        {getVisible("deleteTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteTask")}
            onDelete={deleteTask}
            title="Вы уверены, что хотите удалить эту задачу?"
            headerTitle="Удалить задачу"
          />
        )}

        {/* Удалить подзадачу задачу */}
        {getVisible("deleteSubTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
            onDelete={deleteSubTask}
            title="Вы уверены, что хотите удалить эту подзадачу?"
            headerTitle="Удалить подзадачу"
          />
        )}

        {/* информация о сотруднике */}
        {getVisible("infoParticipant") && (
          <ModalParticipant
            info={getInfo("infoParticipant", "data")}
            changeModalVisible={changeModalVisible}
            edit={false}
          />
        )}

        {/* Информация о проекте */}
        {getVisible("infoProject") && (
          <ModalProject
            info={getInfo("infoProject", "data")}
            changeModalVisible={changeModalVisible}
          />
        )}

        {getVisible("infoUser") && <ModalInfoUser />}

        {getVisible("addEmployeesTask") && <ModalAddDellEmployees add={true} getTasks={getTasks} />}
        {getVisible("delEmployeesTask") && <ModalAddDellEmployees getTasks={getTasks} />}

        {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default TasksMobile;
