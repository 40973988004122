import React, { useEffect } from "react";
import "./EmployeeTasksList.scss";
import WrapperLoader from "../../../../../../components/WrapperLoader/WrapperLoader";
import { useState } from "react";
import TasksListRow from "../../../../../../components/TaskListRow/TaskListRow";
import { useModal } from "../../../../../../hooks/useModal";
import { asyncDeleteTask } from "../../../../../../store/reducers/tasksReducer";
import ModalDeleteItem from "../../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalTask from "../../../../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../../../../components/ModalEditTask/ModalEditTask";
import ModalDelegationTask from "../../../../../../components/ModalDelegationTask/ModalDelegationTask";
import ModalAddDellEmployees from "../../../../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import { useDispatch } from "react-redux";
import { useSearch } from "../../../../../../hooks/useSearch";
import { useSelector } from "react-redux";
import ModalPutAside from "../../../../../../components/ModalPutAside/ModalPutAside";
import EmptySection from "../../../../../../components/EmptySection/EmptySection";
import SearchPanel from "../../../../../../components/SearchPanel/SearchPanel";

const EmployeeTasksList = () => {
  const dispatch = useDispatch();
  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { search } = useSearch("userInfo:tasks");

  const { userTasks, loading } = useSelector((state) => state.tasks);

  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState(0);

  const deleteTask = async () => {
    const idArray = [];

    if (Array.isArray(getInfo("deleteTask", "data"))) {
      idArray.push(...getInfo("deleteTask", "data"));
    } else {
      idArray.push(getInfo("deleteTask", "data"));
    }

    await dispatch(asyncDeleteTask({ tasks: idArray }));
    await search();

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }
    changeModalVisible("deleteTask", false);
  };

  useEffect(() => {
    if (!userTasks) return;
    setTasks(userTasks.tasks);
    setCount(userTasks.count);
  }, [userTasks]);

  return (
    <div className="employeeTasksList">
      {/* <SearchPanel
        inputSearch={{
          visible: true,
          placeholder: "Найти задачу по названию или номеру",
        }}
      /> */}
      <div className="employeeTasksList__content">
        <WrapperLoader loading={loading}>
          {!!tasks?.length ? (
            <TasksListRow taskInfo={{ tasks, count }} />
          ) : (
            !loading && (
              <EmptySection
                text={"Задач не создано"}
                // textBtn={"Добавить"}
                // onClick={() => {
                //   changeModalVisible("addTask", true);
                // }}
              />
            )
          )}
        </WrapperLoader>
      </div>
      {/* Информация задачи */}
      {getVisible("infoTask") && <ModalTask handleChange={() => search()} />}

      {/* Редактирование задачи */}
      {getVisible("editTask") && <ModalEditTask handleChange={() => search()} mode={"edit"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && (
        <ModalEditTask handleChange={() => search()} mode={"editSubTask"} />
      )}

      {/* Добавление задачи */}
      {getVisible("addTask") && <ModalEditTask handleChange={() => search()} mode={"add"} />}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask handleChange={() => search()} mode={"subTask"} />}

      {/* Копирование задачи */}
      {getVisible("copyTask") && <ModalEditTask handleChange={() => search()} mode={"copy"} />}

      {/* Делегирование задачи */}
      {getVisible("delegationTask") && (
        <ModalEditTask handleChange={() => search()} mode={"delegation"} />
      )}

      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}

      {/* Просмотр рисунока */}
      {getVisible("showPoint") && <ModalAddPointInLayer show />}

      {/* Отложить задачу */}
      {getVisible("putAsideTask") && <ModalPutAside />}
      {/* Удалить задачу */}
      {getVisible("deleteTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту задачу?"
          headerTitle="Удалить задачу"
        />
      )}
      {/* Удалить подзадачу задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту подзадачу?"
          headerTitle="Удалить подзадачу"
        />
      )}

      {getVisible("addEmployeesTask") && (
        <ModalAddDellEmployees add={true} getTasks={() => search()} />
      )}
      {getVisible("delEmployeesTask") && <ModalAddDellEmployees getTasks={() => search()} />}
    </div>
  );
};

export default EmployeeTasksList;
