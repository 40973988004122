import React, { useState, useEffect } from "react";
import "./ChatRow.scss";
import Input from "../Input/Input";
import MessageItem from "../MessageItem/MessageItem";
import DocItem from "../DocItem/DocItem";
import Button from "../Button/Button";
import { useChangeIV } from "../../hooks/useChangeIV";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentTask,
  asyncAddCommentTask,
  asyncGetTasks,
  asyncReadAllCommentsTask,
  setUnReadComments,
} from "../../store/reducers/tasksReducer";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { setData } from "../../store/reducers/modalsReduces";

const ChatRow = (props) => {
  const { messages: messagesProps = [], unReadComments: unReadCommentsCount } = props;
  const [activeTab, setActiveTab] = useState(false);

  const { _id: myId, ...userData } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const params = useParams();

  const { getInfo } = useModal();
  const { _id: task_id, unReadComments } = getInfo("infoTask", "data");

  const [messages, setMessages] = useState([]);

  const { IV, changeIV } = useChangeIV({
    text: "",
    documents: [],
  });

  const isPhoto = (type) => {
    const typesPhoto = ["image/png", "image/svg+xml", "image/jpeg", "image/jpg"];
    if (typesPhoto.includes(type)) return true;
    return false;
  };

  const changeFiles = (e) => {
    const documents = [];
    const docList = e.target.files;
    for (const key in docList) {
      if (key === "length" || key === "item") continue;
      documents.push(docList[key]);
    }
    changeIV("set", {
      name: "documents",
      value: [...IV.documents, ...documents],
    });
  };

  const deleteFile = (index) => {
    changeIV(
      (prev) => {
        return prev.filter((_, i) => i !== index);
      },
      { name: "documents" },
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const documents = IV.documents;

    const formDate = new FormData();

    if (!IV.text.trim() && !IV.documents.length) return;

    formDate.append("text", IV.text);

    documents.forEach((d) => formDate.append("document", d));

    const res = await dispatch(
      asyncAddCommentTask({
        taskId: task_id,
        data: formDate,
      }),
    );

    if (res.error) return toast.error("Не удалось отправить сообщение");

    setMessages((prev) => [...prev, res.payload]);

    changeIV("clear");

    const newComment = {
      ...res.payload,
      author: {
        _id: myId,
        ...userData,
      },
    };

    dispatch(
      addCommentTask({
        taskId: task_id,
        comment: newComment,
      }),
    );

    dispatch(
      setData({
        modalName: "infoTask",
        data: {
          comments: [...messages, newComment],
        },
      }),
    );
  };

  const visibleAvatar = (elem, index) => {
    const prevMessage = [...messages].reverse()[index - 1];
    if (prevMessage?.author?._id === elem?.author?._id) {
      return false;
    } else {
      return true;
    }
  };

  const readAllComments = async () => {
    const res = await dispatch(asyncReadAllCommentsTask({ taskId: task_id }));

    if (res.error) return;

    dispatch(
      setUnReadComments({
        taskId: task_id,
        unReadComments: 0,
      }),
    );

    dispatch(
      setData({
        modalName: "infoTask",
        data: {
          unReadComments: 0,
        },
      }),
    );
  };

  useEffect(() => {
    if (!unReadComments) return;

    if (activeTab) {
      readAllComments();
    }
  }, [unReadComments, activeTab]);

  useEffect(() => {
    if (activeTab) {
      const chat = document.querySelector(".chatRow-window-messages");

      chat.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [messages, activeTab]);

  useEffect(() => {
    setMessages(messagesProps || []);
  }, []);

  return (
    <div className="chatRow">
      <div className={`chatRow-title`} onClick={() => setActiveTab(!activeTab)}>
        <div className="chatRow-title-content">
          <div
            data-count={unReadCommentsCount}
            className={`chatRow-btn-comment ${unReadComments ? "countComment" : ""}`}
          >
            <Icons type={"chat"} size={"md"} color={"black"} />
          </div>

          <span className="chatRow-title-text">Ответы и комментарии</span>
        </div>

        <div className="chatRow-title-btn">
          <Icons
            type={"arrowDown"}
            size={"md"}
            cursor
            className={`chatRow-title-icon ${activeTab ? "chatRow-title-icon-rotate" : ""}`}
          />
        </div>
      </div>
      {activeTab && (
        <div className="chatRow-list">
          <form onSubmit={onSubmit} className="chatRow-window">
            <div className="chatRow-view">
              <div className="chatRow-window-messages customScroll">
                {messages?.length ? (
                  <>
                    {[...messages]?.reverse()?.map((msg, index) => (
                      <MessageItem
                        key={msg._id}
                        info={msg}
                        visibleAvatar={visibleAvatar(msg, index)}
                        myId={myId}
                      />
                    ))}
                  </>
                ) : (
                  <div className="chatRow-window-messages-noMassages">
                    <span>Сообщений пока что нет</span>
                  </div>
                )}
              </div>
            </div>
            <div className="chatRow-window-textZone">
              {!!IV.documents.length && (
                <div className="chatRow-window-textZone-files customScroll">
                  {IV.documents.map((doc, i) => (
                    <DocItem
                      index={i}
                      event={{
                        remove: (i) => deleteFile(i),
                      }}
                      info={doc}
                      btnVisible={{ remove: true }}
                      key={i}
                    />
                  ))}
                </div>
              )}
              <div className="chatRow-window-textZone-footer">
                <Input
                  value={IV.text}
                  onChange={changeIV}
                  name="text"
                  placeholder="Введите сообщение"
                  className="chatRow-window-input"
                />
                <div className="chatRow-window-textZone-btns">
                  <label htmlFor="fileChat">
                    <Icons
                      type={"paperIcon"}
                      size={"md"}
                      cursor
                      title={{
                        visible: true,
                        text: "",
                      }}
                    />
                  </label>
                  <input
                    onChange={changeFiles}
                    multiple
                    className="d-none"
                    type="file"
                    name="file"
                    id="fileChat"
                  />
                  {/* <Icons type={"voiceMicro"} size={"md"} cursor /> */}
                  <button type="submit" className="chatRow-window-textZone-btns-submit-btn">
                    {" "}
                    <Icons type={"send"} size={"md"} cursor />{" "}
                  </button>
                  {/* <Button type="submit" color="blue" title="Отправить" /> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatRow;
