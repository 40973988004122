import React, { useState, useEffect } from "react";
import "./ModalViewDoc.scss";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import DocViewer, {
  DocViewerRenderers,
  PDFRenderer,
  PNGRenderer,
} from "react-doc-viewer";
import { getBaseUrl } from "../../utils/getBaseUrl";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const MyNoRenderer = ({ document, fileName }) => {
  const fileText = fileName || document?.fileType || "";

  if (fileText) {
    return (
      <div className="myNoRenderer-text">
        Ошибка отображения файла! ({fileText})
      </div>
    );
  }

  return <div className="myNoRenderer-text">Ошибка отображения файла!</div>;
};

const ModalViewDoc = () => {
  const { changeModalVisible, getInfo } = useModal();
  const [heightContent, setHeightContent] = useState();
  const { height } = useWindowDimensions();
  const info = getInfo("viewDoc", "data");

  const docs = [{ uri: `${getBaseUrl() + "/folders" + info.path_to_doc}` }];
  useEffect(() => {
    setHeightContent(height - 400);
  }, [height]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("viewDoc", false, null)}
      title={`Просомотр файла  ${info.name || ""}`}
    >
      <div className="modalViewDoc">
        <DocViewer
          //   pluginRenderers={[PDFRenderer, PNGRenderer]}
          className={"react-doc-viewer-modal"}
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            noRenderer: {
              overrideComponent: MyNoRenderer,
            },
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default ModalViewDoc;
