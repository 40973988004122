import React from "react";
import "./Input.scss";
import InputMask from "react-input-mask";

function Input(props) {
  const {
    id,
    name,
    type,
    value,
    onChange,
    title,
    placeholder,
    children,
    icon,
    min,
    className,
    validate,
    disabled,
  } = props;

  const checkRequaerd = (title) => {
    const arr = title.split("");
    if (arr.at(-1) === "*") {
      arr.splice(arr.length - 1);
      return (
        <div htmlFor={id} className="input-title">
          <span>{arr}</span>
          <span className="input-title-red"> *</span>
        </div>
      );
    } else {
      return (
        <span htmlFor={id} className="input-title">
          {title}
        </span>
      );
    }
  };

  return (
    <div
      className={`input ${min ? "min" : ""} ${className ? className : ""} ${
        !!validate ? "error" : ""
      } ${!!disabled ? "disabled" : ""}`}
    >
      {!!title && checkRequaerd(title)}
      {type === "tel" || type === "email" ? (
        type === "tel" ? (
          <InputMask
            mask={type === "tel" ? "+7(999)-999-99-99" : "email@example.com"}
            name={name}
            onChange={onChange}
            type={type || "text"}
            placeholder={placeholder}
            value={value}
            id={id}
          >
            {(iProps) => <input {...iProps} />}
          </InputMask>
        ) : (
          <input
            type="email"
            id="email"
            pattern=".+@"
            required
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
          />
        )
      ) : (
        <input
          style={{ paddingRight: icon ? "50px" : "10px" }}
          name={name}
          onChange={onChange}
          type={type || "text"}
          placeholder={placeholder}
          value={value}
          id={id}
          disabled={disabled}
        />
      )}
      {children}
      {!!validate && (
        <div className="input-validate">
          <span>{validate}</span>
        </div>
      )}
    </div>
  );
}

export default Input;
