import React from "react";
import ModalWrapperMobile from "../../../../../../../components/ModalWrapperMobile/ModalWrapperMobile";
import "./ModalChooseAddStepWorkMobile.scss";
import { useModal } from "../../../../../../../hooks/useModal";

const ModalChooseAddStepWorkMobile = () => {
  const { changeModalVisible } = useModal();
  return (
    <ModalWrapperMobile
      onClose={() => changeModalVisible("chooseStageOrWork", false)}
      heightContent={"170px"}
    >
      <ul className="modalChooseAddStepWorkMobile">
        <li
          className="modalChooseAddStepWorkMobile-item"
          onClick={() =>
            changeModalVisible("infoStage", true, null, false, true)
          }
        >
          <span>Добавить этап</span>
        </li>
        <li
          className="modalChooseAddStepWorkMobile-item"
          onClick={() =>
            changeModalVisible("infoWork", true, null, false, true)
          }
        >
          <span>Добавить работу</span>
        </li>
      </ul>
    </ModalWrapperMobile>
  );
};

export default ModalChooseAddStepWorkMobile;
