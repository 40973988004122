import React, { useState, useEffect } from "react";
import axios from "../../../../../axios/axios";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import CardColumnProject from "../../../../../components/CardColumnProject/CardColumnProject";
import { useSelector } from "react-redux";
import { useSearch } from "../../../../../hooks/useSearch";
import { useModal } from "../../../../../hooks/useModal";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";

const EmployeeProjectMobile = ({ info }) => {
  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { userProjects, loading } = useSelector((state) => state.projects);

  const { goToPage } = useSearch("userInfo:projects");

  const goToPageProject = async (edit, info) => {
    goToPage("project", { projectId: info._id }, { edit: Number(edit) });
  };

  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(0);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userProjects) return;
    setProjects(userProjects.projects);
    setCount(userProjects.count);
  }, [userProjects]);

  return (
    <>
      <WrapperLoader loading={loading} chapter={"projects"}>
        {projects.length > 0 ? (
          <div className="projectsMobile-grid">
            {projects.map((card, i) => (
              <CardColumnProject
                changeModalVisible={changeModalVisible}
                number={i + 1}
                key={card._id}
                info={card}
              />
            ))}
          </div>
        ) : (
          !loading && (
            <EmptySection
              text={"Данный пользователь не принимает участие в проектах"}
              nobtn={true}
            />
          )
        )}
      </WrapperLoader>
      {getVisible("menu") && (
        <ModalMobileMenu
          changeModalVisible={changeModalVisible}
          chapter={"projects"}
          nameModal={"menu"}
          goToPageProject={goToPageProject}
          // setCompanyLength={setCompanyLength}
        />
      )}
    </>
  );
};

export default EmployeeProjectMobile;
