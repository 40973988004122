import dayjs from "dayjs";
import React from "react";
import { useState, useRef } from "react";
import Icons from "../../../../../../../Icons/Icons";
import StatusIcon from "../StatusIcon/StatusIcon";
import "./TaskListTableItem.scss";
import { useModal } from "../../../../../../../../hooks/useModal";
import Screen from "../../../../../../../Screen/Screen";
import { useOnClickOutside } from "../../../../../../../../hooks/useOnClickOutside";

const TaskListTableItem = (props) => {
  const {
    task,
    rowHeight,
    showTaskSection,
    provided,
    taskIndex,
    positiomMenu,
    edit: editMode,
  } = props;

  const { changeModalVisible } = useModal();

  if (typeof task.start === "string") task.start = new Date(task.start);

  if (typeof task.end === "string") task.end = new Date(task.end);

  const onEvent = (name) => {
    const nameModal = task.type === "project" ? "infoStage" : "infoWork";
    if (name === "info") return changeModalVisible(nameModal, true, task);
    if (name === "edit") return changeModalVisible(nameModal, true, task, true);
    if (name === "remove") {
      return changeModalVisible(`delete${task.type === "project" ? "Stage" : "Work"}`, true, task);
    }
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const today = dayjs();

  const startDate = dayjs(task.start).format("DD.MM.YYYY");
  const endDate = dayjs(task.end).format("DD.MM.YYYY");

  const deltaOverdue = today.valueOf() - task.end.valueOf(); // дельта между сегодняшним дом и датой завершения
  const deltaPeriod = Math.abs(
    Math.floor((task.start.valueOf() - task.end.valueOf()) / 1000 / 60 / 60 / 24),
  ); // колличество дней выполнения
  const overdueTask = Math.floor(deltaOverdue / 1000 / 60 / 60 / 24); // преобразование
  const startWarnringNotification = (Math.abs(overdueTask) / deltaPeriod) * 100;

  return (
    <li
      className={`taskListTableItem ${showTaskSection ? "taskListTableItem-showTaskSection" : ""}`}
      style={{ height: rowHeight, padding: "0 auto" }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {menuVisible && (
        <div
          ref={ref}
          className={`taskListTableItem-elem-menu-window  
            taskListTableItem-elem-menu-window-${positiomMenu} ${
            showTaskSection ? `taskListTableItem-elem-menu-window-position ` : ""
          }`}
        >
          <span onClick={() => onEvent("info")}>
            Информация {task.type === "project" ? "об этапе" : "о работе"}
          </span>
          {editMode && (
            <>
              <span onClick={() => onEvent("edit")}>
                Редактировать {task.type === "project" ? "этап" : "работу"}
              </span>
              {task.type === "project" && (
                <span onClick={() => changeModalVisible("infoWork", true, task, false, true)}>
                  Добавить работу
                </span>
              )}
              <span onClick={() => onEvent("remove")}>
                Удалить {task.type === "project" ? "этап" : "работу"}
              </span>
            </>
          )}
        </div>
      )}
      <div className="taskListTableItem-elem taskListTableItem-elem-number">
        <span className="taskListTableItem-elem-text elem-text-bold">
          {task.project ? `${task.project.number}.${task.number}` : task.number}
        </span>
      </div>
      <div
        className={`taskListTableItem-elem taskListTableItem-elem-name ${
          task.type === "project" ? "taskListTableItem-elem-name-project" : ""
        }`}
        title={task.name}
      >
        <span className="taskListTableItem-elem-text ">{task.name}</span>
      </div>
      <div className="taskListTableItem-elem taskListTableItem-elem-menu">
        <Screen size={"lg"}>
          <Icons
            type={"menu"}
            size={"md"}
            cursor
            onClick={() => setMenuVisible(!menuVisible)}
            className="projectWorkScheduleInfoPanel-menuIcon"
            title={{ visible: true, text: "" }}
          />
        </Screen>
        <Screen size={"lg"} down>
          <Icons
            type={"menu"}
            size={"md"}
            cursor
            onClick={() => changeModalVisible("menu", true, task)}
            className="projectWorkScheduleInfoPanel-menuIcon"
          />
        </Screen>
      </div>

      <div
        className={`taskListTableItem-elem-showSection ${
          showTaskSection ? "taskListTableItem-elem-showSection-open" : ""
        }`}
      >
        {showTaskSection && (
          <>
            <div
              className="taskListTableItem-elem taskListTableItem-elem-startDate"
              title={startDate}
            >
              <span className="taskListTableItem-elem-text">{startDate}</span>
            </div>
            <div
              className="taskListTableItem-elem taskListTableItem-elem-startDate"
              title={endDate}
            >
              <span className="taskListTableItem-elem-text">{endDate}</span>
            </div>
            <div
              className="taskListTableItem-elem taskListTableItem-elem-startDate"
              title={endDate}
            >
              <span className="taskListTableItem-elem-text">
                {dayjs(task.end).diff(task.start, "day") || 0}
              </span>
            </div>
            <div
              className={`taskListTableItem-elem taskListTableItem-elem-percent ${
                task.type === "project" ? "taskListTableItem-elem-percent-project" : ""
              }`}
            >
              <span className="taskListTableItem-elem-item-text">{task.progress + " %"}</span>
            </div>
          </>
        )}
      </div>

      <div className="taskListTableItem-elem taskListTableItem-elem-startDate">
        {task.progress !== 100 && overdueTask !== 0 && overdueTask > 0 && (
          <StatusIcon numberDay={overdueTask} error />
        )}
        {task.progress === 100 && <StatusIcon success />}
        {startWarnringNotification < 30 && task.progress !== 100 && overdueTask < 0 && (
          <StatusIcon numberDay={Math.abs(overdueTask)} warning />
        )}
      </div>
    </li>
  );
};

export default TaskListTableItem;
