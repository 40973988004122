import React, { useState } from "react";
import "./ModalAddDeliveryWorkMobile.scss";
import Input from "../../../../../components/Input/Input";
import Textarea from "../../../../../components/Textarea/Textarea";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import Select from "../../../../../components/Select/Select";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import InputDate from "../../../../../components/InputDate/InputDate";
import Button from "../../../../../components/Button/Button";
import FileUploader from "../../../../../components/FileUploader/FileUploader";
import DocItem from "../../../../../components/DocItem/DocItem";
import ModalWrapperMobile from "../../../../../components/ModalWrapperMobile/ModalWrapperMobile";
import WorkProjectInfo from "../../../../../components/WorkProjectInfo/WorkProjectInfo";
import { useSelector } from "react-redux";
import { useModal } from "../../../../../hooks/useModal";

const ModalAddDeliveryWorkMobile = (props) => {
  let { info } = props;
  const { changeModalVisible } = useModal();
  const layers = useSelector((state) => state.layers.layers);
  const [file, setFile] = useState([]);

  const { IV, changeIV } = useChangeIV({
    taskName: "",
    taskDescription: "",
    startDate: "",
  });

  const { options, changeOptions } = useChangeOptions({
    typeWork: {
      options: [
        { label: "Сдача работ", value: "Сдача работ" },
        { label: "Сдача работ 2", value: "Сдача работ 2" },
      ],
      value: null,
    },
    typeReceiving: {
      options: [
        { label: "Физическая приёмка", value: "Физическая приёмка" },
        { label: "Документальная приёмка", value: "Документальная приёмка" },
      ],
      value: null,
    },
    engineer: {
      options: [
        { label: "Иванов ВВ", value: "Иванов ВВ" },
        { label: "Сидоров СС", value: "Сидоров СС" },
      ],
      value: null,
    },
    nameProject: {
      options: info.map((elem) => {
        return {
          label: elem.basicInformation.nameProject,
          value: elem.basicInformation.nameProject,
        };
      }),
      value: null,
    },
    layers: {
      options: [
        { label: "Без слоя", value: null },
        ...layers.map((l) => {
          return { label: l.name, value: l.id };
        }),
      ],
      value: null,
    },
  });

  const addFile = (data) => {
    setFile((prev) => [...prev, ...data]);
  };

  const removeFile = (name) => {
    setFile((prev) => {
      return prev.filter((el) => el.name !== name);
    });
  };

  const getLayersInfo = (layerId) => {
    const info = layers.find((l) => l.id === layerId);
    return info;
  };

  const onEvent = (name) => {
    if (name === "addPoint") {
      changeModalVisible(
        "addPoint",
        true,
        getLayersInfo(options.layers.value.value),
        false,
        false,
        (modals) => {
          if (modals.addDeliveryWork.visible) return "addDeliveryWorkMobile";
        },
      );
    }
  };

  return (
    <ModalWrapperMobile
      title={"Сдать работу на проверку"}
      onClose={() => changeModalVisible("addDeliveryWorkMobile", false)}
    >
      <form action="#" className="modalAddDeliveryWorkMobile">
        <div className="modalAddDeliveryWorkMobile-wrapper">
          <div className="modalAddDeliveryWorkMobile-wrapper-info">
            <Input
              title="Название работы:"
              value={IV.taskName}
              onChange={changeIV}
              name="taskName"
              placeholder="Введите название работы не более 50 символов"
            />
            <Textarea
              onChange={changeIV}
              value={IV.taskDescription}
              height="170px"
              name="taskDescription"
              title="Описание работы:"
              placeholder="Введите описание работы не более 5000 символов"
            />
            <ul className="modalAddDeliveryWorkMobile-list">
              <li className="modalAddDeliveryWorkMobile-item">
                <Select
                  options={options.engineer.options}
                  handleChange={(value) => {
                    changeOptions("engineer", "value", value);
                  }}
                  value={options.engineer.value}
                  title="Инженер ССК:"
                  forInput
                  placeholder={"Выберите инженера"}
                />
              </li>

              <li className="modalAddDeliveryWorkMobile-item">
                <Select
                  options={options.typeReceiving.options}
                  handleChange={(value) => {
                    changeOptions("typeReceiving", "value", value);
                  }}
                  value={options.typeReceiving.value}
                  title="Раздел:"
                  forInput
                  placeholder={"Выберите раздел"}
                />
              </li>
              <li className="modalAddDeliveryWorkMobile-item">
                <FileUploader
                  handleChange={(data) => addFile(data)}
                  title={"Файлы:"}
                  multiple={true}
                  typeBtn
                />
              </li>
              <li className="modalAddDeliveryWorkMobile-item">
                {" "}
                <InputDate
                  title="Дата вызова:"
                  value={IV.startDate}
                  onChange={(data) => {
                    changeIV("set", { name: "startDate", value: data });
                  }}
                />
              </li>
            </ul>
            {file.length > 0 ? (
              <div className="modalAddDeliveryWorkMobile-DocItem">
                {file.map((elem, index) => {
                  return (
                    <DocItem
                      key={elem.lastModified}
                      shadow
                      event={{ remove: () => removeFile(elem.name) }}
                      btnVisible={{ remove: true }}
                      info={elem}
                    />
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          <WorkProjectInfo
            getLayersInfo={getLayersInfo}
            typeWorkOptions={options.typeWork.options}
            typeWorkValue={options.typeWork.value}
            layersOptions={options.layers.options}
            layersValue={options.layers.value}
            projectOptions={options.nameProject.options}
            projectValue={options.nameProject.value}
            changeOptions={changeOptions}
            editMode={true}
            onEvent={onEvent}
          />
        </div>
        <div className="modalAddDeliveryWorkMobile-btn-wrapper">
          <Button
            title={"На проверку"}
            color={"blue"}
            onClick={() => changeModalVisible("addDeliveryWork", false)}
          />
        </div>
      </form>
    </ModalWrapperMobile>
  );
};
export default ModalAddDeliveryWorkMobile;
