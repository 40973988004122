import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

export const getPosition = createAsyncThunk("positions/get_position", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.get("positions/get", { params: data?.params });
    return {
      positions: res.data,
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncСreatePosition = createAsyncThunk("positions/asyncСreatePosition", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/positions/create`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const asyncDeletePosition = createAsyncThunk("positions/asyncDeletePosition", async (data, heplers) => {
  const { rejectWithValue } = heplers;
  try {
    const res = await axios.delete(`/positions/delete/${data}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
const positionsReducer = createSlice({
  name: "positions",
  initialState: {
    positions: [],
  },

  extraReducers: extraReducersWrapper({
    [getPosition.fulfilled]: (state, action) => {
      const { positions } = action.payload;
      state.positions = positions;
    },

    [getPosition.rejected]: "Не удалось получить позиции",
    [asyncСreatePosition.fulfilled]: "Позиция успешно создана",
    [asyncСreatePosition.rejected]: "Не удалось создать позицию",
    [asyncDeletePosition.fulfilled]: "Позиция удалена",
    [asyncDeletePosition.rejected]: "Не удалось удалить позицию.",
  }),
});

export default positionsReducer.reducer;
