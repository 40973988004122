import React, { useState, useRef } from "react";
import "./ActiveHeaderTasks.scss";
import { useDispatch } from "react-redux";
import Checkbox from "../Checkbox/Checkbox";
import Icons from "../Icons/Icons";
import Select from "../Select/Select";
import { useModal } from "../../hooks/useModal";
import ActiveHeaderWrapper from "../ActiveHeaderWrapper/ActiveHeaderWrapper";
import {
  asyncPostponedTask,
  asyncEditStatusTask,
} from "../../store/reducers/tasksReducer";
import InputDate from "../InputDate/InputDate";
import Button from "../Button/Button";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useEffect } from "react";
import { statusesSelect } from "../../data/task";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { toast } from "react-toastify";
import { useSearch } from "../../hooks/useSearch";

const ActiveHeaderTasks = ({
  check,
  onCheck,
  onDelete,
  selectItems,
  tasks,
  handleChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const { search: getTasks } = useSearch();

  const [visiblePostponed, setVisiblePostponed] = useState(false);
  const [visibleStatus, setVisibleStatus] = useState(false);

  const [selectedTasks, setSelectedTasks] = useState([]);

  const postponedRef = useRef();
  useOnClickOutside(postponedRef, () => setVisiblePostponed(false));

  const statusRef = useRef();
  useOnClickOutside(statusRef, () => setVisibleStatus(false));

  const { changeModalVisible, getVisible } = useModal();
  const dispatch = useDispatch();

  const { options, changeOptions } = useChangeOptions({
    status: {
      value: null,
      options: statusesSelect,
    },
  });

  const filterTasks = () => {
    let sortTask = tasks.filter((task) => {
      return selectItems?.find((item) => task._id === item);
    });
    setSelectedTasks(sortTask);
  };

  const postponedTask = async () => {
    setLoading(true);
    await dispatch(
      asyncPostponedTask({
        deadline: date,
        tasks: selectItems,
      })
    );
    handleChange ? handleChange() : getTasks({ nativePage: "tasks" });
    setLoading(false);
  };

  const changeStatusTasks = async () => {
    setLoading(true);
    if (options.status.value === null) return toast.error("Выберите статус");
    await dispatch(
      asyncEditStatusTask({
        status: options.status.value.value,
        tasks: selectItems,
      })
    );
    handleChange ? handleChange() : getTasks({ nativePage: "tasks" });
    setLoading(false);
  };

  useEffect(() => {
    filterTasks();
  }, [selectItems]);

  return (
    <ActiveHeaderWrapper check={check} onCheck={onCheck} onDelete={onDelete}>
      <div className="activeHeaderTasks">
        <div className="activeHeaderTasks-item postponed">
          {visiblePostponed ? (
            <div
              className={`activeHeaderTasks-item-wrapper ${
                visiblePostponed ? "active" : ""
              }`}
              ref={postponedRef}
            >
              <InputDate
                onChange={(date) => setDate(date)}
                value={date}
                withPortal={true}
                time={true}
              />
              <Button
                color="blue"
                icon={{ only: true, type: "confirm" }}
                loading={loading}
                onClick={() => postponedTask()}
              />
              <Button
                color="dark"
                // title="Отмена"
                onClick={() => setVisiblePostponed(false)}
                icon={{ only: true, type: "closeWhite" }}
              />
            </div>
          ) : (
            <div
              className="activeHeaderTasks-item-wrapper"
              onClick={() => setVisiblePostponed(true)}
            >
              <Icons
                type={"dateIcon"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Отложить задачу" }}
              />
              <span className="activeHeaderTasks-item-text">
                Отложить дедлайн
              </span>
            </div>
          )}
        </div>
        <div className="activeHeaderTasks-item status">
          {visibleStatus ? (
            <div
              className={`activeHeaderTasks-item-wrapper ${
                visibleStatus ? "active" : ""
              }`}
              ref={statusRef}
            >
              <Select
                handleChange={(value) =>
                  changeOptions("status", "value", value)
                }
                placeholder="Выберите статус"
                forInput
                options={options.status.options}
                value={options.status.value}
                isClearable={false}
              />
              <Button
                color="blue"
                icon={{ only: true, type: "confirm" }}
                loading={loading}
                onClick={() => changeStatusTasks()}
                disabled={options.status.value === null}
              />
              <Button
                color="dark"
                // title="Отмена"
                onClick={() => setVisiblePostponed(false)}
                icon={{ only: true, type: "closeWhite" }}
              />
            </div>
          ) : (
            <div
              className="activeHeaderTasks-item-wrapper "
              onClick={() => setVisibleStatus(true)}
            >
              <Icons
                type={"changeSatus"}
                size={"md"}
                cursor
                title={{ visible: true, text: "Сменить статус" }}
              />
              <span className="activeHeaderTasks-item-text">
                Сменить статус
              </span>
            </div>
          )}
        </div>
        <div
          className="activeHeaderTasks-item addUser"
          onClick={() =>
            changeModalVisible("addEmployeesTask", true, selectedTasks)
          }
        >
          <div className="activeHeaderTasks-item-wrapper">
            <Icons
              type={"addUser"}
              size={"md"}
              cursor
              title={{ visible: true, text: "Добавить сотрудника" }}
            />
            <span className="activeHeaderTasks-item-text">
              Добавить сотрудника
            </span>
          </div>
        </div>
        <div
          className="activeHeaderTasks-item addUser"
          onClick={() =>
            changeModalVisible("delEmployeesTask", true, selectedTasks)
          }
        >
          <div className="activeHeaderTasks-item-wrapper">
            <Icons
              type={"delUser"}
              size={"md"}
              cursor
              title={{ visible: true, text: "Удалить сотрудника" }}
            />
            <span className="activeHeaderTasks-item-text">
              Удалить сотрудника
            </span>
          </div>
        </div>
        {selectItems.length === 1 && (
          <>
            <div
              className="activeHeaderTasks-item info"
              onClick={() =>
                changeModalVisible("infoTask", true, selectedTasks[0])
              }
            >
              <div className="activeHeaderTasks-item-wrapper">
                <Icons
                  type={"infoItem"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "" }}
                />
                <span className="activeHeaderTasks-item-text">Информация</span>
              </div>
            </div>
            <div
              className="activeHeaderTasks-item edit"
              onClick={() =>
                changeModalVisible("editTask", true, selectedTasks[0], true)
              }
            >
              <div className="activeHeaderTasks-item-wrapper">
                <Icons
                  type={"edit"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "" }}
                />
                <span className="activeHeaderTasks-item-text">
                  Редактировать
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </ActiveHeaderWrapper>
  );
};

export default ActiveHeaderTasks;
