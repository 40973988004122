const statuses = [
  { name: "Назначено", name_en: "assigned", _id: "assigned" },
  { name: "Отклонено", name_en: "rejected", _id: "rejected" },
  { name: "В работе", name_en: "atWork", _id: "atWork" },
  { name: "Выполнено", name_en: "completed", _id: "completed" },
  { name: "На проверке", name_en: "onVerification", _id: "onVerification" },
];
const statusesSelect = statuses.map((s) => ({ label: s.name, value: s._id }));

const priorities = [
  { name: "Самый высокий", name_en: "highest", _id: "highest" },
  { name: "Высокий", name_en: "high", _id: "high" },
  { name: "Средний", name_en: "medium", _id: "medium" },
  { name: "Низкий", name_en: "low", _id: "low" },
];
const prioritiesSelect = priorities.map((s) => ({ label: s.name, value: s._id }));

const notifications = [
  { name: "30 минут", _id: "30min" },
  { name: "1 час", _id: "1hour" },
  { name: "2 часа", _id: "2hour" },
  { name: "3 часа", _id: "3hour" },
  { name: "4 часа", _id: "4hour" },
  { name: "5 часов", _id: "5hour" },
  { name: "6 часов", _id: "6hour" },
];
const notificationsSelect = notifications.map((s) => ({ label: s.name, value: s._id }));

const overdue = [
  { name: "В срок", name_en: "nooverdue", _id: "nooverdue" },
  { name: "Просроченные", name_en: "overdue", _id: "overdue" },
];

const overdueSelect = overdue.map((s) => ({ label: s.name, value: s._id }));

export {
  statuses,
  statusesSelect,
  priorities,
  prioritiesSelect,
  notifications,
  notificationsSelect,
  overdue,
  overdueSelect,
};
