import React from "react";
import "./FileUploader.scss";
import { FileUploader as FU } from "react-drag-drop-files";
import Icons from "../Icons/Icons";
import { useEffect } from "react";

const DragZone = () => {
  return (
    <div className="fileUploader-dragZone">
      <span>Нажмите или перетащите файл</span>
    </div>
  );
};

const Button = () => {
  return (
    <button className="fileUploader-button">
      Прикрепите файлы
      <Icons
        className="fileUploader-button-img"
        type={"paperIcon"}
        size={"md"}
        cursor
        title={{
          visible: true,
          text: "",
        }}
      />
    </button>
  );
};

function FileUploader(props) {
  const {
    title,
    handleChange,
    name,
    multiple,
    fileOrFiles = {},
    label,
    typeBtn,
    types,
  } = props;

  const propsForLib = {
    handleChange: handleChange,
    name: name,
    multiple: multiple,
    fileOrFiles: fileOrFiles,
    label: label,
    children: typeBtn ? <Button /> : <DragZone />,
  };

  if (Array.isArray(types) && types.length) {
    propsForLib.types = types;
  }

  return (
    <div className="fileUploader">
      <span className="fileUploader-title">{title}</span>
      <div className="fileUploader-zone">
        <FU {...propsForLib} />
      </div>
    </div>
  );
}

export default FileUploader;
