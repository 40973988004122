import React, { useEffect, useState, useId } from "react";
import LeftInfo from "../LeftInfo/LeftInfo";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import SearchPanel from "../SearchPanel/SearchPanel";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Pagination from "../Pagination/Pagination";
import Button from "../Button/Button";
import "./ModalAddChat.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useSelects } from "../../hooks/useSelects";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useModal } from "../../hooks/useModal";
import axios from "../../axios/axios";
import { useDispatch } from "react-redux";
import { asyncCreateChat } from "../../store/reducers/chatReduser";
import { toast } from "react-toastify";
import { getLimitElements } from "../../utils/getLimitElements";
import CardRowsParticMinChat from "../CardRowsParticMinChat/CardRowsParticMinChat";
import Screen from "../Screen/Screen";
import EmployeesCardMobile from "../EmployeesCardMobile/EmployeesCardMobile";

const ModalAddChat = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const { IV, changeIV } = useChangeIV({
    name: "",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [employeesList, setEmployeesList] = useState([]);
  const [count, setCount] = useState(0);
  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(employeesList);

  const changePage = (page = 1) => {
    setPageNumber(page);
  };

  const getEmployees = async (page = pageNumber, options = {}) => {
    const res = await axios.post(`/users/get/${page}/?limit=${getLimitElements()}`, options);
    if (res.error) return;

    setEmployeesList(res.data.users);
    setCount(res.data.count);
  };

  const onSubmitSearch = async (value) => {
    await getEmployees(1, { name: value, fromJointProjects: true });
  };

  const onChange = async (value) => {
    if (value === "") setPageNumber(1);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!selectItems.length) return toast.error("Необходимо выбрать хотя бы одного сотрудника");

    if (selectItems.length > 1) {
      if (!IV.name.trim()) return toast.error("Заполнител поле Название чата");
    }

    const res = await dispatch(
      asyncCreateChat({
        name: IV.name,
        userIdList: selectItems,
      }),
    );

    if (res.error) return;
    changeModalVisible("addChat", false);
    handleChange && handleChange();
  };

  useEffect(() => {
    getEmployees();
  }, [pageNumber]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addChat")}
      title="Создать чат"
      // noscroll={false}
      btns={
        <div className="modalAddChat-table-btns">
          <Button
            disabled={!selectItems.length}
            color="blue"
            title="Создать"
            type="submit"
            form={formId}
          />
        </div>
      }
    >
      <div className="modalAddChat">
        <div className="modalAddChat-header">
          <Screen size={"lg"}>
            <LeftInfo nowrap color="#F6F6F6" title={`Всего сотрудников: ${count}`} />
          </Screen>
          <Screen size={"lg"} down>
            <div className="modalAddChat-header-mobile">
              <span>Всего сотрудников: {count}</span>
              <div className="modalAddChat-header-mobile-check">
                <Checkbox
                  onCheck={addAllItemSelect}
                  check={selectItems?.length === employeesList?.length}
                />
                <span>Выбрать всех</span>
              </div>
            </div>
          </Screen>
          <SearchPanel
            onChange={onChange}
            onSubmit={onSubmitSearch}
            inputSearch={{
              visible: true,
              placeholder: "Найти сотрудника по Ф.И.О.",
            }}
          />
        </div>

        <div className="modalAddChat-main">
          <div className="flex-dir-column gap-10">
            <span className="modalAddChat-main-title">Название чата:</span>
            <Input
              placeholder="Введите название чата не более 50 символов"
              value={IV.name}
              name="name"
              onChange={changeIV}
            />
          </div>
        </div>
        <form onSubmit={onSubmit} className="modalAddChat-table" id={formId}>
          <Screen size={"lg"}>
            <div className="modalAddChat-table-header">
              <Checkbox
                onCheck={addAllItemSelect}
                check={selectItems.length === employeesList.length}
              />
              <span>ФИО:</span>
              <span>Email:</span>
              <span>Компания:</span>
              <span>Отдел:</span>
              <span>Должность:</span>
            </div>
            <div className="modalAddChat-table-list customScroll">
              {employeesList.map((employee) => (
                <CardRowsParticMinChat
                  key={employee._id}
                  check={selectItems.includes(employee._id)}
                  info={employee}
                  addItemSelect={() => addItemSelect(employee._id)}
                />
              ))}
            </div>
          </Screen>
          <Screen size={"lg"} down>
            <div className="modalAddChat-grid customScroll">
              {employeesList.map((user, i) => (
                <EmployeesCardMobile
                  key={user._id}
                  info={user}
                  modalCard={true}
                  check={selectItems.includes(user._id)}
                  addItemSelect={addItemSelect}
                />
                // <span>{i}</span>
              ))}
            </div>
          </Screen>
          <Pagination
            currentPage={pageNumber}
            count={count}
            onPageChange={(page) => changePage(page)}
            modal={true}
          />
          <Screen size={"lg"}>
            <div className="modalAddChat-table-btns">
              <Button
                disabled={!selectItems.length}
                color="blue"
                title="Создать"
                type="submit"
                form={formId}
              />
            </div>
          </Screen>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ModalAddChat;
