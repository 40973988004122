import React, { useState, useRef } from "react";
import "./StructuresGridCard.scss";
import StructuresGridCardContact from "./components/StructuresGridCardContact/StructuresGridCardContact";
import StructuresGridCardFooter from "./components/StructuresGridCardFooter/StructuresGridCardFooter";
import Icons from "../../../../../Icons/Icons";
import { useModal } from "../../../../../../hooks/useModal";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";
import Screen from "../../../../../Screen/Screen";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";

const StructuresGridCardItem = ({ grade, info }) => {
  const ref = useRef();

  const [menuVisible, setMenuVisible] = useState(false);

  useOnClickOutside(ref, () => setMenuVisible(false));

  const { edit, add, remove } = useHasPermissions("structuresCard", info);
  const { changeModalVisible } = useModal();

  const onEvent = (name, info) => {
    if (name === "add") {
      setMenuVisible(false);
      return changeModalVisible("addDepartment", true, info);
    }

    if (name === "edit") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info, true);
    }

    if (name === "del") {
      setMenuVisible(false);
      return changeModalVisible("deleteDepartment", true, info);
    }

    if (name === "info") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info);
    }
    if (name === "menu") {
      return changeModalVisible("menu", true, info);
    }
  };
  return (
    <div className={`structuresGridCard-item structuresGridCard-item-${grade}`}>
      <div
        className={`structuresGridCard-item__header structuresGridCard-item-header structuresGridCard-item-header-${grade}`}
      >
        <h5
          className={`structuresGridCard-item-header__title structuresGridCard-item-header-title structuresGridCard-item-header-title-${grade}`}
        >
          {info.name}
        </h5>
        <div className="structuresGridCard-item-header-btn">
          <Screen size={"lg"}>
            <Icons
              type={"menu"}
              size={"xs"}
              title={{ visible: true, text: "" }}
              cursor
              onClick={() => setMenuVisible(!menuVisible)}
            />
          </Screen>
          <Screen size={"lg"} down>
            <Icons
              type={"menu"}
              size={"xs"}
              title={{ visible: true, text: "" }}
              cursor
              onClick={() => onEvent("menu", info)}
            />
          </Screen>
        </div>
      </div>

      <div
        className={`structuresGridCard-item__info structuresGridCard-item-info structuresGridCard-item-info-${grade} ${
          info.employees?.length < 0 ? "structuresGridCard-item-info-noBorder" : ""
        }`}
      >
        {!!info.director?.user ? (
          <StructuresGridCardContact
            avatar={info.director.user.avatar}
            name={info.director.user}
            position={info.director.position.name}
            phone={info.director.user.phone}
            mail={info.director.user.mail}
            grade={grade}
          />
        ) : (
          <div className="d-flex justify-center">
            <span className="structuresGridCard-item-header-title">Руководитель не назначен</span>
          </div>
        )}
      </div>
      <Screen size={"lg"}>
        {menuVisible && (
          <div
            ref={ref}
            className="structuresGridCard-menu-window animate__animated animate__fadeIn modal"
          >
            <span onClick={() => onEvent("info", info)}>Информация об отделе</span>
            {edit && <span onClick={() => onEvent("edit", info)}>Редактировать отдел</span>}

            {(grade === "one" || grade === "two") && add && (
              <span onClick={() => onEvent("add", info)}>Добавить отдел</span>
            )}

            {remove && <span onClick={() => onEvent("del", info)}>Удалить отдел</span>}
          </div>
        )}
      </Screen>
      {!!info.profiles?.length && <StructuresGridCardFooter employees={info.profiles} />}
    </div>
  );
};

const StructuresGridCard = ({ structures, grade }) => {
  return (
    <ul className="structuresGridCard">
      {structures?.map((item, i) => (
        <li className={`structuresGridCard-li `} key={item._id} id={item._id}>
          <StructuresGridCardItem grade={grade} info={item} />
          {!!item.departments?.length && (
            <StructuresGridCard structures={item.departments} grade={"two"} />
          )}
        </li>
      ))}
    </ul>
  );
};

export default StructuresGridCard;
