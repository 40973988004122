import React, { useState, useEffect } from "react";
import { useModal } from "../../hooks/useModal";
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import "./DocumentationList.scss";
import { useDispatch, useSelector } from "react-redux";
import { useSelects } from "../../hooks/useSelects";
import Checkbox from "../Checkbox/Checkbox";
import DocumentationItemRow from "./components/DocumentationItemRow/DocumentationItemRow";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import EmptySection from "../EmptySection/EmptySection";
import DocumentationItemGrid from "./components/DocumentationItemGrid/DocumentationItemGrid";
import Pagination from "../Pagination/Pagination";
import ActiveHeaderDocumentation from "../ActiveHeaderDocumentation/ActiveHeaderDocumentation";
import ModalDeleteItem from "../ModalDeleteItem/ModalDeleteItem";
import { asyncDeleteFile } from "../../store/reducers/documentationReduser";
import { useSearch } from "../../hooks/useSearch";

const DocumentationList = ({
  viewCards,
  getFatherFolders,
  activeChapter = "",
  fatherFolder,
  getHeadFolder,
  visibleHeader,
  setVisibleHeader,
  changeCurrentFolder,
  handleChange,
}) => {
  const dispatch = useDispatch();

  const { setQueryParam } = useSearch();

  const { changeModalVisible, getVisible } = useModal();
  const { folders } = useSelector((state) => state.documentation);

  const [infoHeader, setInfoHeader] = useState({});
  const { father, documents, count, loading } = folders;

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(documents);

  const getFolder = async (folder) => {
    setQueryParam({ father: folder._id });
  };

  const findItem = (arr, itemId) => {
    setInfoHeader(arr.find((elem) => elem._id === itemId));
  };

  const deleteFiles = async () => {
    let files = [];
    if (!!selectItems.length) {
      files = selectItems;
    } else {
      files.push(getInfo("deleteFiles", "data")._id);
    }

    await dispatch(
      asyncDeleteFile({
        document_ids: files,
      }),
    );

    changeModalVisible("deleteFiles");
    handleChange && handleChange();
    await getFolder(fatherFolder);
    clearItems();
  };

  useEffect(() => {
    if (selectItems.length > 0) {
      setVisibleHeader(true);
      if (selectItems.length === 1) {
        findItem(folders.documents, selectItems[0]);
      }
    } else {
      setVisibleHeader(false);
    }
  }, [selectItems]);

  return (
    <div className="documentationList">
      <Breadcrumbs
        path={folders?.father?.full_path_to_folder}
        getFatherFolders={getFatherFolders}
        activeChapter={activeChapter}
        father={father}
      />
      <WrapperLoader loading={loading} chapter={"documentation"} viewCards={viewCards}>
        {!!count ? (
          viewCards === "rows" ? (
            <div className="documentationList-row">
              <ul className="documentationList-row-header">
                {visibleHeader && (
                  <ActiveHeaderDocumentation
                    check={selectItems.length === documents.length}
                    onCheck={addAllItemSelect}
                    onDelete={() => changeModalVisible("deleteFiles", true)}
                    visible={selectItems.length === 1}
                    info={infoHeader}
                    father={father}
                    clearItems={clearItems}
                  />
                )}
                <li className="tasks-row-header__item numder-item">
                  {!!father && (
                    <Checkbox
                      check={selectItems.length === documents.length}
                      onCheck={addAllItemSelect}
                    />
                  )}
                </li>
                <li className="documentationList-row-header-item company-item"></li>
                <li className="documentationList-row-header-item name-item">
                  <span title="Название">Название:</span>
                </li>
                <li className="documentationList-row-header-item type-item">
                  <span title="Тип">Тип:</span>
                </li>
                <li className="documentationList-row-header-item size-item">
                  <span title="Размер">Размер:</span>
                </li>
                <li className="documentationList-row-header-item size-item">
                  <span title="Создатель">Создатель:</span>
                </li>
                <li className="documentationList-row-header-item company-item"></li>
              </ul>
              <ul className="documentationList-row-list">
                {documents.map((doc) => (
                  <DocumentationItemRow
                    info={doc}
                    check={selectItems.includes(doc._id)}
                    onCheck={() => addItemSelect(doc._id)}
                    key={doc._id}
                    type={doc.type}
                    father={father}
                    getHeadFolder={getHeadFolder}
                    changeCurrentFolder={changeCurrentFolder}
                  />
                ))}
              </ul>
            </div>
          ) : (
            <div className="documentationList-grid">
              {documents.map((doc) => (
                <DocumentationItemGrid
                  info={doc}
                  key={doc._id}
                  type={doc.type}
                  father={father}
                  getHeadFolder={getHeadFolder}
                  changeCurrentFolder={changeCurrentFolder}
                />
              ))}
            </div>
          )
        ) : (
          !loading && (
            <EmptySection
              text={"Документов нет"}
              // textBtn={"Добавить"}
              // onClick={() => {
              //   changeModalVisible("addTask", true);
              // }}
            />
          )
        )}
        <Pagination count={count} type={"documentation"} />
      </WrapperLoader>
      {/* Удалить компанию */}
      {getVisible("deleteFiles") && (
        <ModalDeleteItem
          onDelete={deleteFiles}
          changeModalVisible={() => changeModalVisible("deleteFiles")}
          modalName="deleteFiles"
          haderTitle="Удалить файлы"
          title="Вы уверены, что хотите удалить эти файлы?"
        />
      )}
    </div>
  );
};

export default DocumentationList;
