import React, { useState, useEffect } from "react";
import SceletonLine from "../SkeletonLine/SkeletonLine";
import "./SkeletonPermissions.scss";

const SkeletonPermissions = ({ chapter }) => {
  const [elem, setElem] = useState(6);

  useEffect(() => {
    if (
      chapter === "permissionsFolder" ||
      chapter === "permissionsFolderEdit"
    ) {
      setElem(6);
    } else if (
      chapter === "permissionsFile" ||
      chapter === "permissionsFileEdit"
    ) {
      setElem(5);
    }
  }, [chapter]);

  return (
    <div className="skeletonPermissions">
      <div className={`skeletonPermissions-header ${chapter ? chapter : ""}`}>
        <SceletonLine width={"40%"} />
        {Array(elem)
          .fill(0)
          .map((e, i) => (
            <div className="skeletonPermissions-box">
              <SceletonLine key={i} e={e} width={"70px"} />
            </div>
          ))}
      </div>
      <ul className="skeletonPermissions-list">
        {Array(3)
          .fill(0)
          .map((e, i) => (
            <li
              className={`skeletonPermissions-item ${chapter ? chapter : ""}`}
              key={i}
            >
              <SceletonLine e={e} width={"40%"} />
              {Array(elem)
                .fill(0)
                .map((e, n) => (
                  <div className="skeletonPermissions-box">
                    <SceletonLine key={n} e={e} width={"40px"} />
                  </div>
                ))}
              {(chapter === "permissionsFolderEdit" ||
                chapter === "permissionsFileEdit") && (
                <SceletonLine width={"20px"} />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SkeletonPermissions;
