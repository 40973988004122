import { createArray } from "./createArray.js";
import { clearDobleObj } from "./clearDobleObg";

export const countCompanyEmployess = (profiles) => {
  if (!profiles) return;
  const users = createArray(profiles, "user");
  if (!users) return;
  const clearUsers = clearDobleObj(users);
  if (!clearUsers) return;
  return clearUsers?.length || 0;
};
