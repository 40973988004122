import React, { useState, useRef } from "react";
import "./Reports.scss";
import ChartPie from "../../../ChartPie/ChartPie";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../../Icons/Icons";
import { taskReportsAnalitics } from "../../../../store/reducers/analyticsReducer";
import { useEffect } from "react";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import Select from "../../../Select/Select";
import InputSearch from "../../../InputSearch/InputSearch";
import Button from "../../../Button/Button";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { toast } from "react-toastify";
import { transformStatus } from "../../../../utils/transformStatus";
import { transformForSelect } from "../../../../utils/transformForSelect";
import ModalReportsMobile from "../../../ModalReportsFilterMobile/ModalReportsFilterMobile";
import Screen from "../../../Screen/Screen";
import { useModal } from "../../../../hooks/useModal";
import { useSearch } from "../../../../hooks/useSearch";
import Sunburst from "../../../Sunburst/Sunburst";
import { reset } from "ol/transform";
import { transformOverdue } from "../../../../utils/transformOverdue";
import { getFontSizeBase } from "../../../../utils/getFontSizeBase";

function Reports() {
  const dispatch = useDispatch();

  const { changeModalVisible, getVisible } = useModal();

  const { goToPage } = useSearch();

  const taskReports = useSelector((state) => state.analytic.taskReports);
  const { userData } = useSelector((state) => state.user);

  const [filterVisible, setFilterVisible] = useState(false);
  const [title, setTitle] = useState({ type: "", name: "" });

  const [isActiveCard, setIsActiveCard] = useState("");

  const data = [
    {
      id: "Выполненные",
      label: "Выполненные",
      title: "Выполненные",
      titleEn: "completed",
      value: taskReports?.completed?.completed,
      color: "hsl(144, 80%, 43%)",
      procent: {
        value: 12,
        type: "up",
      },
    },
    {
      id: "В работе",
      label: "В работе",
      title: "В работе",
      titleEn: "atWork",
      value: taskReports?.atWork?.atWork,
      color: "hsl(210, 95%, 29%)",
      procent: {
        value: 12,
        type: "down",
      },
    },
    {
      id: "На проверке",
      label: "На проверке",
      title: "На проверке",
      titleEn: "onVerification",
      value: taskReports?.onVerification?.onVerification,
      color: "hsl(43, 100%, 43%)",
      procent: {
        value: 12,
        type: "up",
      },
    },
    {
      id: "Отмененные",
      label: "Отмененные",
      title: "Отмененные",
      titleEn: "rejected",
      value: taskReports?.rejected?.rejected,
      color: "hsl(0, 0%, 75%)",
      procent: {
        value: 12,
        type: "up",
      },
    },
    // {
    //   id: "Просроченные",
    //   label: "Просроченные",
    //   title: "Просроченные",
    //   titleEn: "overdue",
    //   value: taskReports.overdue,
    //   color: "hsl(353, 75%, 49%)",

    //   procent: {
    //     value: 12,
    //     type: "down",
    //   },
    // },
    {
      id: "Назначенные",
      label: "Назначенные",
      title: "Назначенные",
      titleEn: "assigned",
      value: taskReports?.assigned?.assigned,
      color: "hsl(270, 100%, 50%)",
      procent: {
        value: 12,
        type: "up",
      },
    },
  ];
  const [dataRepots, setDataReports] = useState({
    isActive: false,
    title: {
      text: null,
      count: null,
    },
    data: data,
    colors: ["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"],
  });

  const [titleSelect, setTitleSelect] = useState({
    title: "Сотрудник:",
    placeholder: "Выбрать сотрудника",
  });

  const refFilterVisible = useRef();
  useOnClickOutside(refFilterVisible, () => setFilterVisible(false));
  const { _id: userId } = userData;

  const refPie = useRef();

  useOnClickOutside(refPie, () =>
    setDataReports({
      isActive: false,
      title: "",
      data: data,
      colors: ["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"],
    }),
  );

  const { options, changeOptions } = useChangeOptions({
    type: {
      options: [
        { label: "Компания", value: "company" },
        { label: "Проект", value: "project" },
        { label: "Сотрудник", value: "user" },
      ],
      value: null,
    },
    options: {
      options: [],
      value: null,
    },
  });

  const getReports = async (type, id) => {
    if (type === "project") {
      await dispatch(taskReportsAnalitics({ project: id }));
      setTitle({
        type: "по проекту",
        name: options.options.value.label,
      });
    } else if (type === "company") {
      await dispatch(taskReportsAnalitics({ company: id }));
      setTitle({
        type: "компании",
        name: options.options.value.label,
      });
    } else if (type === "user") {
      await dispatch(taskReportsAnalitics({ user: id }));
      setTitle({
        type: "сотрудника",
        name: options.options.value.label,
      });
    } else {
      await dispatch(taskReportsAnalitics());
    }
  };

  function sumCount(obj) {
    let sum = 0;
    for (let key in obj) {
      if (key !== "allOverdue") {
        for (let nestedKey in obj[key]) {
          if (nestedKey !== "overdue") {
            sum += obj[key][nestedKey];
          }
        }
      }
    }
    return sum;
  }

  const goToTaskPage = (e) => {
    let status = null;
    let overdue = null;
    // console.log(e);
    if (typeof e === "string") {
      if (e === "allOverdue") {
        overdue = transformForSelect(transformOverdue("overdue"));
      } else {
        status = transformForSelect(transformStatus(e));
      }
    } else if (e.length > 1) {
      status = transformForSelect(transformStatus(e[0]));
      overdue = transformForSelect(transformOverdue(e[1]));
    } else {
      status = { label: e.data.title, value: e.data.titleEn };
      overdue = { label: e.data?.subTitle, value: e?.data?.subTitleEn };
    }

    let textSuccess = `Применен фильтр: ${status?.label ? status?.label : ""}, ${
      overdue?.label ? overdue?.label : ""
    }`;
    const locationState = { status, overdue };

    if (options.type.value?.value === "user") {
      textSuccess += ` ${options.options.value.label}`;
      locationState.executor = options.options.value;
    } else if (options.type.value?.value === "project") {
      locationState.project = options.options.value;
    } else if (options.type.value?.value === "company") {
      locationState.company = options.options.value;
    }

    toast.success(textSuccess);

    goToPage("tasks", {}, locationState);
  };

  const openReport = (e) => {
    if (e.id === "Выполненные") {
      setIsActiveCard("completed");
      return setDataReports({
        isActive: true,
        title: {
          text: "Выполненные",
          count: taskReports?.completed?.completed,
        },
        data: [
          {
            id: "В срок",
            label: "Выполненные",
            title: "Выполненные",
            titleEn: "completed",
            subTitle: "В срок",
            subTitleEn: "nooverdue",
            value: taskReports?.completed?.completed - taskReports?.completed?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
          {
            id: "Просрочено",
            label: "Выполненные",
            title: "Выполненные",
            titleEn: "completed",
            subTitle: "Просрочено",
            subTitleEn: "overdue",
            value: taskReports?.completed?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
        ],
        colors: ["#16c35b", "#db1f35"],
      });
    } else if (e.id === "В работе") {
      setIsActiveCard("atWork");
      return setDataReports({
        isActive: true,
        title: {
          text: "В работе",
          count: taskReports?.atWork?.atWork,
        },
        data: [
          {
            id: "В срок",
            label: "В срок",
            title: "В работе",
            titleEn: "atWork",
            subTitle: "В срок",
            subTitleEn: "nooverdue",
            value: taskReports?.atWork?.atWork - taskReports?.atWork?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
          {
            id: "Просрочено",
            label: "Просрочено",
            title: "В работе",
            titleEn: "atWork",
            subTitle: "Просрочено",
            subTitleEn: "overdue",
            value: taskReports?.atWork?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
        ],
        colors: ["#04488e", "#db1f35"],
      });
    } else if (e.id === "На проверке") {
      setIsActiveCard("onVerification");
      return setDataReports({
        isActive: true,
        title: {
          text: "На проверке",
          count: taskReports?.onVerification?.onVerification,
        },
        data: [
          {
            id: "В срок",
            label: "В срок",
            title: "На проверке",
            titleEn: "onVerification",
            subTitle: "В срок",
            subTitleEn: "nooverdue",
            value:
              taskReports?.onVerification?.onVerification - taskReports?.onVerification?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
          {
            id: "Просрочено",
            label: "Просрочено",
            title: "На проверке",
            titleEn: "onVerification",
            subTitle: "Просрочено",
            subTitleEn: "overdue",
            value: taskReports?.onVerification?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
        ],
        colors: ["#dc9d00", "#db1f35"],
      });
    } else if (e.id === "Отмененные") {
      setIsActiveCard("rejected");
      return setDataReports({
        isActive: true,
        title: {
          text: "Отмененные",
          count: taskReports?.rejected?.rejected,
        },
        data: [
          {
            id: "В срок",
            label: "Отмененные",
            title: "Отмененные",
            titleEn: "rejected",
            subTitle: "В срок",
            subTitleEn: "nooverdue",
            value: taskReports?.rejected?.rejected - taskReports?.rejected?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
          {
            id: "Просрочено",
            label: "Отмененные",
            title: "Отмененные",
            titleEn: "rejected",
            subTitle: "С нарушением срока",
            subTitleEn: "overdue",
            value: taskReports?.rejected?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
        ],
        colors: ["#c0c0c0", "#db1f35"],
      });
    } else {
      setIsActiveCard("assigned");
      return setDataReports({
        isActive: true,
        title: {
          text: "Назаначено",
          count: taskReports?.assigned?.assigned,
        },
        data: [
          {
            id: "В срок",
            label: "В срок",
            title: "Назаначено",
            titleEn: "assigned",
            subTitle: "В срок",
            subTitleEn: "nooverdue",
            value: taskReports?.assigned?.assigned - taskReports?.assigned?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
          {
            id: "Просрочено",
            label: "Просрочено",
            title: "Назаначено",
            titleEn: "assigned",
            subTitle: "Просрочено",
            subTitleEn: "overdue",
            value: taskReports?.assigned?.overdue,
            color: "hsl(144, 80%, 43%)",
          },
        ],
        colors: ["#7f00ff", "#db1f35"],
      });
    }
  };

  useEffect(() => {
    if (options.type.value?.value === "company") {
      setTitleSelect({
        title: "Компания:",
        placeholder: "Выбрать компанию",
      });
    } else if (options.type.value?.value === "project") {
      setTitleSelect({
        title: "Проект:",
        placeholder: "Выбрать проект",
      });
    } else if (options.type.value?.value === "user") {
      setTitleSelect({
        title: "Сотрудник:",
        placeholder: "Выбрать сотрудника",
      });
    } else {
    }
  }, [options.type.value]);

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (userData.role !== "admin") {
      changeOptions("type", "value", { label: "Сотрудник", value: "user" });
      changeOptions(
        "options",
        "value",
        titleSelect.title === "Сотрудник:"
          ? { label: getFormatFIO(userData), value: userId }
          : null,
      );
    }
  }, [userData]);

  useEffect(() => {
    setDataReports({
      isActive: false,
      title: "",
      data: data,
      colors: ["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"],
    });
  }, [taskReports]);

  const resetDataRepots = () => {
    setIsActiveCard("");
    setDataReports({
      isActive: false,
      title: "",
      data: data,
      colors: ["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"],
    });
  };

  return (
    <div className="reports">
      <div className="reports-header">
        <h2 className="reports-title">
          Отчеты по задачам {title.type || ""} {title.name || ""}:
        </h2>
        <Screen size={"lg"}>
          <div className="reports-header-filter" onClick={() => setFilterVisible((prev) => !prev)}>
            <Icons type={"filterIcon"} size={"xl"} title={{ visible: true, text: "" }} cursor />
          </div>
          {filterVisible && (
            <div className="reports-header-modal" ref={refFilterVisible}>
              <Select
                options={options.type.options}
                value={options.type.value}
                handleChange={(data) => changeOptions("type", "value", data)}
                forInput
                title="Тип:"
                placeholder="Выбрать тип"
                maxMenuHeigh={30}
                isSearchable={false}
              />
              <InputSearch
                options={options.options.options}
                value={options.options.value}
                handleChange={(value) => changeOptions("options", "value", value)}
                placeholder={titleSelect.placeholder}
                forInput
                title={titleSelect.title}
                type={options.type.value?.value}
              />

              <div className="reports-header-modal-btns">
                <Button
                  title="Применить"
                  color="blue"
                  type="submit"
                  onClick={() => getReports(options.type.value.value, options.options.value.value)}
                />
                <Button
                  color="dark"
                  title="Сбросить"
                  onClick={() => {
                    changeOptions("type", "value", null);
                    changeOptions("options", "value", null);
                  }}
                />
              </div>
            </div>
          )}
        </Screen>
        <Screen size={"lg"} down>
          <div
            className="reports-header-filter"
            onClick={() => changeModalVisible("reportsFilter", true)}
          >
            <Icons type={"filterIcon"} size={"xl"} title={{ visible: true, text: "" }} cursor />
          </div>
        </Screen>
      </div>

      <div className="reports-content">
        <div className="reports-content-progress" ref={refPie}>
          <Screen size={"xxs"}>
            <ChartPie
              onClick={dataRepots.isActive ? goToTaskPage : openReport}
              data={dataRepots.data}
              colors={dataRepots.colors}
              fontSize={getFontSizeBase()}
            />
          </Screen>
          <Screen size={"xxs"} down>
            <ChartPie
              onClick={dataRepots.isActive ? goToTaskPage : openReport}
              data={dataRepots.data}
              colors={dataRepots.colors}
              enableArcLinkLabels={false}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              fontSize={14}
            />
          </Screen>

          <div className="reports-content-progress-wrapper">
            <span className="reports-content-progress-title">
              {!!dataRepots.title.text ? dataRepots.title.text : "Всего задач"}
            </span>
            <div className="reports-content-progress-count">
              <span className="reports-content-progress-count-value">
                {!!dataRepots.title.count ? dataRepots.title.count : sumCount(taskReports)}
              </span>
              {/* <span
                className={`reports-content-progress-count-procent ${donutContent.procent.type}`}
              >
                {donutContent.procent.value + " %"}
              </span> */}
              {dataRepots.isActive && (
                <div onClick={() => resetDataRepots()}>
                  <Icons
                    type={"arrowLeft"}
                    size={"md"}
                    cursor
                    // color={"blue"}
                    title={{ visible: true, text: "Назад" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="reports-content-activeZone">
          <div className="reports-content-cards">
            {/* Выполненные */}
            <div
              className={`reports-content-cards-card completed ${
                isActiveCard === "completed" ? "activeCard" : ""
              }`}
            >
              <span className="reports-content-cards-card-title completed">Выполненные</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("completed")}
                  >
                    {taskReports?.completed?.completed}
                  </span>
                  <div className="reports-content-cards-card-content-count-box">
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["completed", "nooverdue"])}
                    >
                      {taskReports?.completed?.completed - taskReports?.completed?.overdue} в рамках
                      срока
                    </span>
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["completed", "overdue"])}
                    >
                      {taskReports?.completed?.overdue} просрочено
                    </span>
                  </div>
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"twoChechmark"} size={"xxl"} />
                </div>
              </div>
            </div>

            {/* В работе */}
            <div
              className={`reports-content-cards-card atWork ${
                isActiveCard === "atWork" ? "activeCard" : ""
              }`}
            >
              <span className="reports-content-cards-card-title atWork">В работе</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("atWork")}
                  >
                    {taskReports?.atWork?.atWork}
                  </span>
                  <div className="reports-content-cards-card-content-count-box">
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["atWork", "nooverdue"])}
                    >
                      {taskReports?.atWork?.atWork - taskReports?.atWork?.overdue} в рамках срока
                    </span>
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["atWork", "overdue"])}
                    >
                      {taskReports?.atWork?.overdue} просрочено
                    </span>
                  </div>
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"circleOfArrows"} size={"xxl"} />
                </div>
              </div>
            </div>

            {/* На проверке */}
            <div
              className={`reports-content-cards-card onVerification ${
                isActiveCard === "onVerification" ? "activeCard" : ""
              }`}
            >
              <span className="reports-content-cards-card-title onVerification">На проверке</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("onVerification")}
                  >
                    {taskReports?.onVerification?.onVerification}
                  </span>
                  <div className="reports-content-cards-card-content-count-box">
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["onVerification", "nooverdue"])}
                    >
                      {taskReports?.onVerification?.onVerification -
                        taskReports?.onVerification?.overdue}{" "}
                      в рамках срока
                    </span>
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["onVerification", "overdue"])}
                    >
                      {taskReports?.onVerification?.overdue} просрочено
                    </span>
                  </div>
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"pen"} size={"xxl"} />
                </div>
              </div>
            </div>

            {/* Назначенные */}
            <div
              className={`reports-content-cards-card assigned ${
                isActiveCard === "assigned" ? "activeCard" : ""
              }`}
            >
              <span className="reports-content-cards-card-title assigned">Назначенные</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("assigned")}
                  >
                    {taskReports?.assigned?.assigned}
                  </span>
                  <div className="reports-content-cards-card-content-count-box">
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["assigned", "nooverdue"])}
                    >
                      {taskReports?.assigned?.assigned - taskReports?.assigned?.overdue} в рамках
                      срока
                    </span>
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["assigned", "overdue"])}
                    >
                      {taskReports?.assigned?.overdue} просрочено
                    </span>
                  </div>
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"assigned"} size={"xxl"} />
                </div>
              </div>
            </div>
            {/* Отмененные */}
            <div
              className={`reports-content-cards-card rejected  ${
                isActiveCard === "rejected" ? "activeCard" : ""
              }`}
            >
              <span className="reports-content-cards-card-title rejected">Отмененные</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("rejected")}
                  >
                    {taskReports?.rejected?.rejected}
                  </span>
                  <div className="reports-content-cards-card-content-count-box">
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["rejected", "nooverdue"])}
                    >
                      {taskReports?.rejected?.rejected - taskReports?.rejected?.overdue} в рамках
                      срока
                    </span>
                    <span
                      className="reports-content-cards-card-content-count-box-text"
                      onClick={() => goToTaskPage(["rejected", "overdue"])}
                    >
                      {taskReports?.rejected?.overdue} просрочено
                    </span>
                  </div>
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"cancelled"} size={"xxl"} />
                </div>
              </div>
            </div>
            {/* Просроченные */}
            <div className="reports-content-cards-card overdue">
              <span className="reports-content-cards-card-title overdue">Просроченные</span>
              <div className="reports-content-cards-card-content">
                <div className="reports-content-cards-card-content-count">
                  <span
                    className="reports-content-cards-card-content-count-value"
                    onClick={() => goToTaskPage("allOverdue")}
                  >
                    {taskReports?.allOverdue}
                  </span>
                  {/* <span className="reports-content-cards-card-content-count-procent down">
                    12%
                  </span> */}
                </div>
                <div className="reports-content-cards-card-content-img">
                  <Icons type={"overdue"} size={"xxl"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getVisible("reportsFilter") && (
        <ModalReportsMobile
          setTitleSelect={setTitleSelect}
          titleSelect={titleSelect}
          getReports={getReports}
          setTitle={setTitle}
        />
      )}
    </div>
  );
}

export default Reports;
