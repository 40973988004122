import React, { useState } from "react";
import "./SettingsGeneralElem.scss";
import СheckboxSwitch from "../../../СheckboxSwitch/СheckboxSwitch";

const SettingsGeneralElem = ({ title, text, onCheck, checked }) => {
  return (
    <div className="profileNotificationCart">
      <div className="profileNotificationCart__info">
        <h5 className="profileNotificationCart__title">{title || ""}</h5>
        <span className="profileNotificationCart__text">{text || ""}</span>
      </div>
      <СheckboxSwitch check={checked} onCheck={onCheck} />
    </div>
  );
};

export default SettingsGeneralElem;
