import React, { useId } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import Input from "../Input/Input";
import Button from "../Button/Button";
import "./ModalAddEditReference.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useDispatch } from "react-redux";
import Textarea from "../Textarea/Textarea";
import { asyncCreateReferences, asyncEditReferences } from "../../store/reducers/referencesReducer";
import Screen from "./../Screen/Screen";

const ModalAddEditReference = (props) => {
  const { edit = false, handleChange } = props;

  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const infoReference = getInfo("editReference", "data");

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: infoReference?.title || "",
    description: infoReference?.description || "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const checkValidateFields = [
      checkValidateIV("name", { minHeight: 3, maxHeight: 100 }),
      checkValidateIV("description", { maxHeight: 5000 }),
    ].every((item) => item === true);
    if (checkValidateFields) {
      let res = { error: true };
      if (edit) {
        res = await dispatch(
          asyncEditReferences({
            referencesId: infoReference._id,
            data: { title: IV.name, description: IV.description },
          }),
        );
      } else {
        res = await dispatch(
          asyncCreateReferences({ title: IV.name, description: IV.description }),
        );
      }
      if (res.error) return;
      handleChange && handleChange();
      changeModalVisible(edit ? "editReference" : "addReference", false);
    }
  };

  return (
    <ModalWrapper
      onClose={() => changeModalVisible(edit ? "editReference" : "addReference", false)}
      title={"Добавить справку"}
      btns={
        <div className="modalAddEditReference-btn-wrapper">
          <Button
            disabled={IV.name.length <= 3 && IV.description.length <= 20}
            type="submit"
            title={edit ? "Сохранить" : "Создать"}
            color="blue"
            form={formId}
          />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddEditReference" id={formId}>
        <Input
          title="Название справки:*"
          placeholder="Введите название справки"
          name="name"
          value={IV.name}
          onChange={changeIV}
          validate={checkValidateIV("name")}
        />
        <Textarea
          onChange={changeIV}
          value={IV.description}
          height="170px"
          name="description"
          title="Описание:*"
          placeholder="Введите описание задачи не более 5000 символов"
          validate={checkValidateIV("description")}
        />
        <Screen size={"lg"}>
          <div className="modalAddEditReference-btn-wrapper">
            <Button
              disabled={IV.name.length <= 3 && IV.description.length <= 20}
              type="submit"
              title={edit ? "Сохранить" : "Создать"}
              color="blue"
              form={formId}
            />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddEditReference;
