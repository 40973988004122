import React, { useState } from "react";
import { getCalendarMatrix, monthName, getRangeYears } from "../../utils/utilsCalendar";
import "./CalendarTable.scss";
import dayjs from "dayjs";
import Screen from "../Screen/Screen";
import CalendarPanel from "../CalendarPanel/CalendarPanel";
import { useModal } from "../../hooks/useModal";
import { useDispatch } from "react-redux";
import { asyncGetEvent } from "../../store/reducers/calendarReducer";
import { getFormatTime } from "../../utils/getFormatTime";
import Icons from "../Icons/Icons";
import CalendarTableItem from "./components/CalendarTableItem/CalendarTableItem";

function CalendarTable(props) {
  const { currentDate, changeDate, events, viewCards, mobile = false } = props;
  const { year, month } = currentDate;
  const matrix = getCalendarMatrix(year, month);
  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const eventListForDay = (day) => {
    const lists = [];
    for (let i = 0; i < events.length; i++) {
      const { start, end } = events[i];
      if (dayjs(start).subtract(1, "day") <= dayjs(day) && dayjs(end) >= dayjs(day)) {
        lists.push(events[i]);
      }
    }
    return lists;
  };

  const checkMonthDay = (day) => {
    if (month !== dayjs(day).month()) {
      return "noMonth";
    }
    return "";
  };

  const checkDayWeek = (day) => {
    switch (day) {
      case 1:
        return "Пн";
      case 2:
        return "Вт";
      case 3:
        return "Ср";
      case 4:
        return "Чт";
      case 5:
        return "Пт";
      case 6:
        return "Сб";
      case 7:
        return "Вс";
      default:
        return "Вс";
    }
  };

  const showModalEvents = (data) => {
    changeModalVisible("eventList", true, data);
  };

  const showModalEvent = async (name, data) => {
    if (name === "info" || name === "edit") {
      const res = await dispatch(asyncGetEvent(data._id));
      if (res.error) return;
      if (name === "edit") changeModalVisible("editEvent", true, res.payload.data);
      else if (name === "info") changeModalVisible("infoEvent", true, res.payload.data);
    } else if (name === "delete") {
      changeModalVisible("deleteEvent", true, { _id: data._id });
    }
  };
  return (
    <div className="calendarTable">
      <Screen size="lg">
        <CalendarPanel currentDate={currentDate} changeDate={changeDate} />
      </Screen>
      <div className="calendarTable-table ">
        {viewCards === "columns" && (
          <div className="calendarTable-table-header">
            <Screen size="xl">
              <span>Понедельник</span>
              <span>Вторник</span>
              <span>Среда</span>
              <span>Четверг</span>
              <span>Пятница</span>
              <span className="color-red">Суббота</span>
              <span className="color-red">Воскесенье</span>
            </Screen>
            <Screen size="xl" down>
              <span>Пн</span>
              <span>Вт</span>
              <span>Ср</span>
              <span>Чт</span>
              <span>Пт</span>
              <span className="color-red">Сб</span>
              <span className="color-red">Вс</span>
            </Screen>
          </div>
        )}

        <div className="calendarTable-table-margix">
          {matrix.map((week, indexWeek) => (
            <div
              key={indexWeek}
              className={`calendarTable-table-margix-week ${
                viewCards === "rows" ? "calendarTable-table-margix-week-rows" : ""
              }`}
            >
              {week.map((day, indexDay) => (
                <div
                  key={indexDay}
                  className={`calendarTable-table-margix-day ${
                    dayjs(day).format("DD.MM.YYYY") === dayjs().format("DD.MM.YYYY")
                      ? "calendarTable-table-margix-day-today"
                      : ""
                  }`}
                >
                  <div
                    className={`calendarTable-table-margix-day-title ${checkMonthDay(day)}  ${
                      eventListForDay(day)?.length ? "evetsHeader" : ""
                    }`}
                  >
                    <span
                      onClick={() => {
                        if (eventListForDay(day)?.length) {
                          showModalEvents({
                            list: eventListForDay(day),
                            date: day,
                          });
                        }
                      }}
                    >
                      <Screen size="xxl">
                        {(viewCards === "rows" ? checkDayWeek(dayjs(day).day()) : "") +
                          " " +
                          dayjs(day).format("DD.MM.YYYY")}
                      </Screen>
                      <Screen size="xxl" down>
                        {(viewCards === "rows" ? checkDayWeek(dayjs(day).day()) : "") +
                          " " +
                          dayjs(day).format("DD.MM")}
                      </Screen>
                    </span>
                    {dayjs(day).diff(dayjs(), "day") >= 0 && (
                      <div
                        onClick={() =>
                          changeModalVisible("addEvent", true, {
                            start: dayjs(day)
                              .hour(dayjs().hour())
                              .minute(dayjs().minute())
                              .toDate(),
                            end: dayjs(day)
                              .hour(dayjs().hour())
                              .minute(dayjs().minute())
                              .add(1, "hour")
                              .toDate(),
                          })
                        }
                      >
                        <Icons type={"addEvent"} size={"md"} color={"grey"} cursor />
                      </div>
                    )}
                  </div>
                  <div
                    className={`calendarTable-table-margix-day-eventList customScroll ${
                      viewCards === "rows" ? "calendarTable-table-margix-day-eventList-row" : ""
                    }`}
                  >
                    {eventListForDay(day).map((event) => (
                      <CalendarTableItem viewCards={viewCards} info={event} key={event._id} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CalendarTable;
