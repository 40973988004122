import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const get_priorities = createAsyncThunk("priorities/get_priorities", async () => {
  try {
    const res = await axios.get("/priorities");
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const prioritiesReducer = createSlice({
  name: "priorities",
  initialState: {
    priorities: [],
  },
  extraReducers: {
    [get_priorities.fulfilled]: (state, action) => {
      const { priorities } = action.payload;
      state.priorities = priorities;
    },
  },
});

export default prioritiesReducer.reducer;
