import React, { useState, useEffect } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import "./EmployeesMobile.scss";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import {
  asyncGetStructures,
  asyncRemoveDepartament,
} from "../../../store/reducers/structuresReducer";
import { asyncGetGroups, asyncRemoveGroup } from "../../../store/reducers/groupsReducer";
import { asyncGetCompanies, asyncRemoveCompany } from "../../../store/reducers/companiesReducer";
import EmployeesGridMobile from "./components/EmployeesGridMobile/EmployeesGridMobile";
import CompaniesMobile from "./components/CompaniesMobile/CompaniesMobile";
import GorupsMobile from "./components/GorupsMobile/GorupsMobile";
import Structures from "../../../components/Structures/Structures";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetUsers, asyncRemoveUser } from "../../../store/reducers/userReducer";
import { useFilter } from "../../../hooks/useFilter";
import { useSelects } from "../../../hooks/useSelects";
import { getPageCount } from "../../../utils/getPageCount";
import { useNavigate, useParams } from "react-router-dom";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import ModalAddCompanyMobile from "./components/ModalAddCompanyMobile/ModalAddCompanyMobile";
import ModalAddGroupMobile from "./components/ModalAddGroupMobile/ModalAddGroupMobile";
import ModalAddEmployee from "../../../components/ModalAddEmployee/ModalAddEmployee";
import ModalAddDepartmentMobile from "./components/ModalAddDepartmentMobile/ModalAddDepartmentMobile";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import { useModal } from "../../../hooks/useModal";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { transformForSelect } from "../../../utils/transformForSelect";
import { useChangeOptions } from "../../../hooks/useChangeOptions";
import ModalInfoEditDepartment from "../../../components/ModalInfoEditDepartment/ModalInfoEditDepartment";
import ModalEditEmployee from "../../../components/ModalEditEmployee/ModalEditEmployee";
import ModalProject from "../../../components/ModalProject/ModalProject";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalInfoGroup from "../../../components/ModalInfoGroup/ModalInfoGroup";
import ModalAddGroup from "../../../components/ModalAddGroup/ModalAddGroup";
import ModalEditGroup from "../../../components/ModalEditGroup/ModalEditGroup";
import ModalAddCompany from "../../../components/ModalAddCompany/ModalAddCompany";
import ModalInfoEditCompany from "../../../components/ModalInfoEditCompany/ModalInfoEditCompany";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const EmployeesMobile = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { params, search, setQueryParam, queries } = useSearch();

  const [chapter, setChapter] = useState("users");

  const [activePermission, setActivePermission] = useState(chapter);

  const { add } = useHasPermissions(activePermission);

  const [viewCards, setViewCards] = useState("rows"); // columns | rows

  const [departmentLength, setDepartmentLength] = useState(0);
  const [employeeLength, setEmployeeLength] = useState(0);
  const [groupLength, setGroupLength] = useState(0);
  const [companyLength, setCompanyLength] = useState(0);
  const [infoTitle, setInfoTitle] = useState("Всего сотрудников: 15");

  const [companyForStructures, setCompanyForStructures] = useState(null);

  // employess
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const changeViewCard = (value) => {
    setViewCards(value);
  };

  const changeChapter = (value) => setChapter(value);

  const [searchInput, setSearchInput] = useState({
    inputSearch: {
      visible: true,
      placeholder: "Найти сотрудника по ФИО",
    },
  });

  const [added, setAdded] = useState({ visible: true, onClick: null });
  const changeWiewChapter = (value) => {
    setChapter(value);
    setActivePermission(value);
  };

  useEffect(() => {
    switch (chapter) {
      case "structures":
        return setInfoTitle(`Всего отделов: ${departmentLength}`);
      case "employees":
        return setInfoTitle(`Всего сотрудников: ${employeeLength}`);
      case "groups":
        return setInfoTitle(`Всего групп: ${groupLength}`);
      case "companies":
        return setInfoTitle(`Всего компаний: ${companyLength}`);
      default:
        setInfoTitle(`Всего сотрудников: ${employeeLength}`);
    }
  }, [departmentLength, employeeLength, companyLength, groupLength, chapter]);

  const handleChange = (type) => {
    search({ nativePage: type });
  };

  const removeCompany = async () => {
    const { _id: company_id } = getInfo("deleteCompany", "data");
    const res = await dispatch(asyncRemoveCompany({ company_id }));
    if (res.error) return changeModalVisible("deleteCompany", false, null);
    handleChange("employees:companies");
    changeModalVisible("deleteCompany", false, null);
  };

  const removeGroup = async () => {
    const { _id: groupId } = getInfo("deleteGroup", "data");
    const res = await dispatch(asyncRemoveGroup(groupId));
    if (res.error) return changeModalVisible("deleteGroup", false, null);
    handleChange("employees:groups");
    changeModalVisible("deleteGroup", false, null);
  };

  const removeUser = async () => {
    const { _id: user_id } = getInfo("deleteEmployee", "data");
    const res = await dispatch(asyncRemoveUser({ user_id }));
    if (res.error) return changeModalVisible("deleteEmployee", false, null);
    handleChange("employees:users");
    changeModalVisible("deleteEmployee", false, null);
  };

  const removeDepartment = async () => {
    const { _id: departament_id } = getInfo("deleteDepartment", "data");
    const res = await dispatch(asyncRemoveDepartament(departament_id));
    if (res.error) return changeModalVisible("deleteDepartment", false, null);
    handleChange("employees:structures");
    changeModalVisible("deleteDepartment", false, null);
  };

  useEffect(() => {
    switch (chapter) {
      case "users":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addEmployee", true),
        });
        return setSearchInput({
          inputSearch: {
            visible: true,
            placeholder: "Найти сотрудника по ФИО",
          },
        });
      case "groups":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addGroup", true),
        });
        return setSearchInput({
          inputSearch: {
            visible: true,
            placeholder: "Найти по названию группы",
          },
        });
      case "companies":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addCompany", true),
        });
        return setSearchInput({
          inputSearch: {
            visible: true,
            placeholder: "Найти по названию компании",
          },
        });
      case "infoEmployee":
        return setSearchInput({
          inputSearch: {
            visible: false,
          },
        });
      case "structures":
        if (!companyForStructures) {
          setAdded({
            visible: false,
          });
        } else {
          setAdded({
            visible: true,
            onClick: () => changeModalVisible("addDepartment", true),
          });
        }

      default:
        return setSearchInput({
          inputSearch: {
            visible: false,
          },
        });
    }
  }, [chapter, companyForStructures]);

  useEffect(() => {
    if (!params.chapter) return changeWiewChapter("users");

    changeWiewChapter(params.chapter);
  }, [params]);

  useEffect(() => {
    if (!queries.company) {
      setDepartmentLength(0);
      return setCompanyForStructures(null);
    }

    setCompanyForStructures(queries.company);
  }, [queries.company]);

  useEffect(() => {}, []);

  return (
    <LayoutMobile
      filterDisabled={
        chapter === "structures" ||
        getVisible("infoEditEmployee") ||
        getVisible("infoEditGroup") ||
        getVisible("infoEditCompany") ||
        getVisible("menu")
          ? true
          : false
      }
      activePage={chapter}
      viewCards={viewCards}
      onViewClick={changeViewCard}
      footerBtnRotate={
        chapter === "structures" && !getVisible("infoEditDepartment") && { visible: true }
      }
      footerBtnAddCircle={
        !(
          getVisible("infoEditEmployee") ||
          getVisible("infoEditGroup") ||
          getVisible("infoEditCompany") ||
          getVisible("menu") ||
          getVisible("infoEditDepartment")
        ) &&
        add &&
        added
      }
      search={searchInput}
      footerBtnBack={
        (getVisible("infoEditEmployee") ||
          getVisible("infoEditGroup") ||
          getVisible("infoEditCompany") ||
          getVisible("infoEditDepartment")) && {
          visible: true,
          onClick: () => {
            changeModalVisible("infoEditDepartment", false);
            changeModalVisible("infoEditCompany", false);
            changeModalVisible("infoEditGroup", false);
            changeModalVisible("infoEditEmployee", false);
          },
        }
      }
    >
      <ErrorBoundary>
        <HeaderMobilePage
          title={infoTitle}
          active={chapter}
          currentPage={"employees"}
          changeWiewChapter={changeWiewChapter}
          visibleNav={true}
        />

        <div className="employeesMobile-content">
          {chapter === "structures" && (
            <div className="employeesMobile-content-strutures">
              <InputSearch
                placeholder="Найти компанию"
                type="company"
                value={companyForStructures}
                handleChange={(data) => setQueryParam({ company: data })}
              />

              <Structures setDepartmentLength={setDepartmentLength} viewCards={viewCards} />
            </div>
          )}

          {chapter === "users" && (
            <EmployeesGridMobile
              setEmployeeLength={setEmployeeLength}
              changeWiewChapter={changeWiewChapter}
            />
          )}
          {chapter === "groups" && <GorupsMobile setGroupLength={setGroupLength} />}
          {chapter === "companies" && <CompaniesMobile setCompanyLength={setCompanyLength} />}

          {/* Информация о проекте */}
          {getVisible("project") && (
            <ModalProject
              changeModalVisible={changeModalVisible}
              info={getInfo("project", "data")}
            />
          )}

          {/* Информация о сотруднике */}
          {getVisible("participant") && (
            <ModalParticipant
              changeModalVisible={changeModalVisible}
              info={getInfo("participant", "data")}
            />
          )}

          {/* Делегировать задачи сотруднкиа перед удалением */}
          {getVisible("delegateTasksEmployee") && (
            <ModalDelegateTasksEmployee employees={getInfo("delegateTasksEmployee", "data")} />
          )}

          {getVisible("menu") && (
            <ModalMobileMenu
              chapter={chapter}
              nameModal={"menu"}
              // setCompanyLength={setCompanyLength}
            />
          )}
          {getVisible("menuTasks") && (
            <ModalMobileMenu
              chapter={"tasks"}
              nameModal={"menuTasks"}
              onClose={() => changeModalVisible("menuTasks", false)}
              // setCompanyLength={setCompanyLength}
            />
          )}
          {/* Добавление сотрудника */}
          {getVisible("addEmployee") && <ModalAddEmployee handleChange={handleChange} />}
          {/* Редактирование сотрудника */}
          {getVisible("editEmployee") && <ModalEditEmployee handleChange={handleChange} />}

          {/* Добавление группы */}
          {getVisible("addGroup") && <ModalAddGroup handleChange={handleChange} />}

          {/* Информация о компании / Редактирование компании */}
          {getVisible("infoCompany") && <ModalInfoEditCompany handleChange={handleChange} />}
          {/* Информация о группе */}
          {getVisible("infoGroup") && <ModalInfoGroup setChapter={setChapter} />}

          {/* Редактировать о группу */}
          {getVisible("editGroup") && <ModalEditGroup handleChange={handleChange} />}

          {/* Добавление компании */}
          {getVisible("addCompany") && <ModalAddCompany handleChange={handleChange} />}
          {/* Добавление компании */}
          {getVisible("addDepartment") && <ModalAddDepartmentMobile handleChange={handleChange} />}

          {/* Редактировать отдел */}
          {getVisible("editDepartment") && <ModalInfoEditDepartment handleChange={handleChange} />}

          {/* Удалить отдел */}
          {getVisible("deleteDepartment") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteDepartment", false)}
              title="Вы уверены, что хотите удалить этот отдел?"
              headerTitle="Удалить отдел"
              onDelete={removeDepartment}
            />
          )}

          {/* Удалить компанию */}
          {getVisible("deleteCompany") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteCompany", false)}
              title="Вы уверены, что хотите удалить компанию?"
              headerTitle="Удалить компанию"
              onDelete={removeCompany}
            />
          )}
          {/* Удалить группу */}
          {getVisible("deleteGroup") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteGroup", false)}
              title="Вы уверены, что хотите удалить группу?"
              headerTitle="Удалить группу"
              onDelete={removeGroup}
            />
          )}
          {/* Удалить сотрудника */}
          {getVisible("deleteEmployee") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteEmployee", false)}
              title="Вы уверены, что хотите удалить сотрудника?"
              headerTitle="Удалить сотрудника"
              onDelete={removeUser}
            />
          )}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default EmployeesMobile;
