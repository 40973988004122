import React from "react";
import InputSearch from "../InputSearch/InputSearch";
import Select from "../Select/Select";
import Icons from "../Icons/Icons";
import "./UserRowModal.scss";
import Screen from "../Screen/Screen";

const UserRowModal = ({
  removeProfile,
  saveProfile,
  createProfile,
  resetProfile,
  changeProfile,
  info,
  optionsPosition,
  disabledUsers,
  disabledPositions,
  visibleRemove,
  visibleSave,
  visibleCreate,
  visibleReset,
  type,
}) => {
  const { index, user, position } = info;
  return (
    <div className={`userRowModal ${type ? "userRowModal-" + type : ""}`}>
      <div className="userRowModal-item position">
        <Select
          options={optionsPosition}
          handleChange={(data) => changeProfile(index, "position", data)}
          placeholder="Выберите должность"
          title="Должность:*"
          forInput
          menuPlacement="top"
          value={position}
          isDisabled={disabledPositions}
        />
      </div>
      <div className="userRowModal-item user">
        <InputSearch
          title="Пользователь:*"
          forInput
          placeholder="Введите пользователя"
          handleChange={(data) => changeProfile(index, "user", data)}
          value={user}
          isDisabled={disabledUsers}
        />
      </div>
      <div className="userRowModal-btns">
        <Screen size={"xs"}>
          <span className="userRowModal-btns-span">{"."}</span>
        </Screen>
        <div className="userRowModal-btns-list">
          {visibleRemove || (
            <div
              className="userRowModal-btn red "
              onClick={removeProfile}
              title={"Удалить профайл"}
            >
              <Icons
                type={"remove"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Удалить профайл" }}
              />
            </div>
          )}
          {visibleSave && (
            <div
              className="profileRowModal-btn blue"
              onClick={saveProfile}
              title={"Создать профайл"}
            >
              {" "}
              <Icons
                type={"editSave"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Создать профайл" }}
              />
            </div>
          )}
          {visibleCreate && (
            <div
              className="profileRowModal-btn blue"
              onClick={createProfile}
              title={"Сохранить изменения"}
            >
              {" "}
              <Icons
                type={"save"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Сохранить изменения" }}
              />
            </div>
          )}
          {visibleReset && (
            <div
              className="profileRowModal-btn grey"
              onClick={resetProfile}
              title={"Отменить изменения"}
            >
              <Icons
                type={"reset"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Отменить изменения" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRowModal;
