import React from "react";
import "./ProfileNotification.scss";
import ProfileNotificationCart from "./components/ProfileNotificationCart/ProfileNotificationCart";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  asyncEditAlloweNotification,
  asyncGetAlloweNotification,
} from "../../store/reducers/userReducer";

const ProfileNotification = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  const [notifications, setNotifications] = useState([]);

  const changeAllowed = async (name) => {
    const newAllowed = !notifications.find((n) => n.name === name).allowed;
    const res = await dispatch(
      asyncEditAlloweNotification({
        userId: userData._id,
        data: { name, allowed: newAllowed },
      }),
    );
    if (res.error) return;

    setNotifications((prev) =>
      prev.map((n) => {
        if (n.name === name) return { ...n, allowed: newAllowed };
        return n;
      }),
    );
  };

  useEffect(() => {
    setNotifications(userData?.userNotifications || []);
  }, [userData]);

  return (
    <div className="profileNotification">
      <h2 className="profileNotification__title profileNotification-title">Уведомления:</h2>
      <ul className="profileNotification__list">
        {notifications?.map((elem, i) => {
          return (
            <li className="profileNotification__item" key={i}>
              <ProfileNotificationCart handleCheck={changeAllowed} info={elem} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileNotification;
