import React, { useState, useRef } from "react";
import "./InputDate.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

function InputDate(props) {
  const {
    value,
    onChange,
    title,
    dev,
    placeholderText,
    time,
    style,
    validate,
    withPortal = false,
    includeDateIntervals,
    defultTime,
  } = props;
  const [myRef, setMyRef] = useState(false);

  const closeCalendar = () => {
    myRef.setOpen(false);
  };
  const openCalendar = () => {
    myRef.setOpen(true);
  };

  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");

  const devCustom = dev?.join(" ");
  const dateFormat = time ? "dd.MM.yyyy   HH:mm" : "dd.MM.yyyy";

  const checkRequaerd = (title) => {
    const arr = title.split("");
    if (arr.at(-1) === "*") {
      arr.splice(arr.length - 1);
      return (
        <div className="inputDate-title">
          <span>{arr}</span>
          <span className="inputDate-title-red"> *</span>
        </div>
      );
    } else {
      return <span className="inputDate-title">{title}</span>;
    }
  };

  const ExampleCustomTimeInput = ({ date, value, onChange }) => (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ border: "solid 1px pink" }}
    />
  );

  const handleChange = (date) => {
    const newDate = new Date(date);
    if (defultTime && date && !newDate.getHours() && !newDate.getMinutes()) {
      newDate.setHours(defultTime?.hour || 18); // Устанавливаем время по умолчанию
      newDate.setMinutes(defultTime?.minutes || 0); // Устанавливаем минуты по умолчанию
      onChange && onChange(newDate);
    } else {
      onChange && onChange(date);
    }
  };

  return (
    <div style={style} className={`inputDate ${devCustom || ""} ${validate ? "validate" : ""}`}>
      {!!title && checkRequaerd(title)}
      <div className={`inputDate-input ${devCustom || ""} ${time ? "inputDate-input-time" : ""} `}>
        <DatePicker
          selected={value}
          onChange={handleChange}
          locale={ru}
          showMonthDropdown
          showYearDropdown
          showTimeSelect={time ? true : false}
          timeInputLabel="Время:"
          withPortal={withPortal}
          placeholderText={placeholderText || "Выберите дату"}
          ref={(r) => {
            setMyRef(r);
          }}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          dateFormat={dateFormat}
          includeDateIntervals={includeDateIntervals}
        >
          <div onClick={closeCalendar} className="inputDate-input-close">
            <Icons type={"crossBlack"} size={"xs"} cursor title={{ visible: true, text: "" }} />
          </div>
        </DatePicker>
        <div onClick={openCalendar} className={`inputDate-icon ${devCustom || ""}`}>
          <Screen size={"lg"}>
            <Icons type={"dateIcon"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </Screen>
          <Screen size={"lg"} down>
            <Icons type={"dateIcon"} size={"md"} color={"grey"} />
          </Screen>
        </div>
      </div>
      {!!validate && (
        <div className="inputDate-validate">
          <span>{validate}</span>
        </div>
      )}
    </div>
  );
}

export default InputDate;
