import React, { useState, useEffect } from "react";
import "./EmployeeInfo.scss";
import EmployeeBaseInfo from "./components/EmployeeBaseInfo/EmployeeBaseInfo";
import EmployeeProject from "./components/EmployeeProject/EmployeeProject";
import EmployeeTasksList from "./components/EmployeeTasksList/EmployeeTasksList";
import { useModal } from "../../../../hooks/useModal";
import { asyncGetUser } from "../../../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import { useSearch } from "../../../../hooks/useSearch";
import LeftInfo from "../../../../components/LeftInfo/LeftInfo";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import { getFormatFIO } from "../../../../utils/getFormatFIO";

const EmployeeInfo = ({ viewCards }) => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(null);

  const [chapter, setChapter] = useState("info");
  const [activeFilter, setActiveFilter] = useState("all");

  const { changeModalVisible, getVisible } = useModal();

  const { params, goToPage, setQueryParam, queries } = useSearch();

  const { visible } = useHasPermissions(`userInfo:info`, userInfo);

  const changeWiewChapter = (value) => {
    if (value === "info") return goToPage(`userInfo:info`);

    goToPage(`userInfo:${value}`);
  };

  const changeWiewTasks = (value) => setQueryParam({ tab: value });

  const changeWiewProjects = (value) => setQueryParam({ tab: value });

  const getCurrentData = async (userId) => {
    // if (userInfo && userInfo._id === userId) return;

    const res = await dispatch(asyncGetUser(userId));

    if (res.error) return toast.error("Не удалось получить актуальные данные");

    setUserInfo(res.payload);
  };

  useEffect(() => {
    if (params.userId) getCurrentData(params.userId);
    if (params.list) return setChapter(params.list);
    else setChapter("info");
  }, [params.userId, params.list]);

  useEffect(() => {
    if (queries.tab) setActiveFilter(queries.tab);
    else setActiveFilter("all");
  }, [queries.tab]);

  return (
    <div className="employeeInfo">
      {getVisible() && <div className="bgc-shadow"></div>}
      <div className="employeeInfo-content">
        <LeftInfo icon onClick={() => goToPage("employees:users")} />
        {visible && (
          <div className="employeeInfo__nav employeeInfo-nav">
            <ul className="employeeInfo-nav__list">
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  chapter === "info" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewChapter("info")}
              >
                <span className="employeeInfo-nav__span">Основная информация</span>
              </li>
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  chapter === "projects" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewChapter("projects")}
              >
                <span className="employeeInfo-nav__span">Проекты</span>
              </li>
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  chapter === "tasks" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewChapter("tasks")}
              >
                <span className="employeeInfo-nav__span">Задачи</span>
              </li>
            </ul>
            {chapter === "tasks" && (
              <ul className="employeeInfo-nav__list">
                <li
                  className={`employeeInfo-nav__item employeeInfo-nav-item ${
                    activeFilter === "all" && "employeeInfo-nav-item-active"
                  }`}
                  onClick={() => changeWiewTasks("all")}
                >
                  <span className="employeeInfo-nav__span">Все</span>
                </li>
                <li
                  className={`employeeInfo-nav__item employeeInfo-nav-item ${
                    activeFilter === "outgoing" && "employeeInfo-nav-item-active"
                  }`}
                  onClick={() => changeWiewTasks("outgoing")}
                >
                  <span className="employeeInfo-nav__span">Исходящие</span>
                </li>
                <li
                  className={`employeeInfo-nav__item employeeInfo-nav-item ${
                    activeFilter === "incoming" && "employeeInfo-nav-item-active"
                  }`}
                  onClick={() => changeWiewTasks("incoming")}
                >
                  <span className="employeeInfo-nav__span">Входящие</span>
                </li>
              </ul>
            )}
            {/* {chapter === "projects" && (
            <ul className="employeeInfo-nav__list">
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  activeFilter === "all" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewProjects("all")}
              >
                <span className="employeeInfo-nav__span">Все</span>
              </li>
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  activeFilter === "creator" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewProjects("creator")}
              >
                <span className="employeeInfo-nav__span">Создатель проектов</span>
              </li>
              <li
                className={`employeeInfo-nav__item employeeInfo-nav-item ${
                  activeFilter === "member" && "employeeInfo-nav-item-active"
                }`}
                onClick={() => changeWiewProjects("member")}
              >
                <span className="employeeInfo-nav__span">Участник в проектах</span>
              </li>
            </ul>
          )} */}
          </div>
        )}
        {userInfo && (
          <div className="employeeInfo__wrapper customScroll">
            {chapter === "info" && (
              <EmployeeBaseInfo
                info={userInfo}
                handleChange={() => getCurrentData(params.userId)}
              />
            )}
            {chapter === "projects" && visible && <EmployeeProject viewCards={viewCards} />}
            {chapter === "tasks" && visible && <EmployeeTasksList />}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeInfo;
