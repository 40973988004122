import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

export const asyncGetLayer = createAsyncThunk("layers/asyncGetLayer", async (data, helpers) => {
  const { rejectWithValue } = helpers
  try {
    const res = await axios.post(`/layers/get_layer/${data}`)
    return res
  } catch (error) {
    return rejectWithValue(error)
  }
});

const layersReducer = createSlice({
  name: "layers",
  initialState: {
    layers: [],
  },
  reducers: {},
  extraReducers: extraReducersWrapper({
    [asyncGetLayer.rejected]: "Не удалось получить слой"
  }),
});

export const { } = layersReducer.actions;
export default layersReducer.reducer;
