import React from "react";
import "./TaskListHeader.scss";
import Icons from "../../../../../../components/Icons/Icons";

const TaskListHeader = ({
  headerHeight,
  showTaskSection,
  setTaskSection,
  fontFamily,
  fontSize,
  rowWidth,
}) => {
  return (
    <div
      className={`taskListHeader ${showTaskSection ? "taskListHeader-showTaskSection" : ""}`}
      style={{ height: headerHeight }}
    >
      <div className="taskListHeader-item taskListHeader-item-number">
        <span className="taskListHeader-item-text">№</span>
      </div>
      <div className="taskListHeader-item taskListHeader-item-name">
        <span className="taskListHeader-item-text">Наименование:</span>
      </div>
      <div className="taskListHeader-item "></div>
      <div
        className={`taskListHeader-item-showSection ${
          showTaskSection ? "taskListHeader-item-showSection-open" : ""
        }`}
      >
        {showTaskSection && (
          <>
            <div className="taskListHeader-item taskListHeader-item-startDate">
              <span className="taskListHeader-item-text">Начало:</span>
            </div>
            <div className="taskListHeader-item taskListHeader-item-endDate">
              <span className="taskListHeader-item-text">Завершение:</span>
            </div>
            <div className="taskListHeader-item taskListHeader-item-endDate">
              <span className="taskListHeader-item-text">Дни:</span>
            </div>
            <div className="taskListHeader-item taskListHeader-item-percent">
              <span className="taskListHeader-item-text">%</span>
            </div>
          </>
        )}
      </div>
      <div className="taskListHeader-item taskListHeader-item-deadlineDeviation">
        <Icons
          className={`taskListHeader-item-deadlineDeviation ${
            showTaskSection ? "taskListHeader-item-deadlineDeviation-open" : ""
          }`}
          type={"arrowDouble"}
          size={"md"}
          cursor
          onClick={() => setTaskSection(!showTaskSection)}
        />
      </div>
    </div>
  );
};

export default TaskListHeader;
