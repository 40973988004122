import React from "react";
import "./ModalStage.scss";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ModalStageEdit from "./components/ModalStageEdit/ModalStageEdit";
import ModalStageInfo from "./components/ModalStageInfo/ModalStageInfo";

function ModalStage(props) {
  const {
    changeModalVisible,
    createMode,
    editMode,
    info,
    footing,
    scheduleWorksId,
    getWorksAndSteps,
  } = props;
  const title =
    createMode || editMode
      ? createMode
        ? "Добавить этап работы"
        : "Редактировать этап работы"
      : "Информация о этапе";

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("infoStage", false)}
      title={title}
    >
      <div className="modalStage">
        <div className="modalStage-main">
          {editMode || createMode ? (
            <ModalStageEdit
              getWorksAndSteps={getWorksAndSteps}
              scheduleWorksId={scheduleWorksId}
              info={info}
              createMode={createMode}
              changeModalVisible={changeModalVisible}
            />
          ) : (
            <ModalStageInfo
              changeModalVisible={changeModalVisible}
              footing={footing}
              info={info || {}}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalStage;
