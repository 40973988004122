import React, { useState, useEffect, useId } from "react";
import "./ModalAddDoc.scss";
import FileUploader from "..//FileUploader/FileUploader";
import Button from "..//Button/Button";
import Select from "..//Select/Select";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import DocItem from "../DocItem/DocItem";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { asyncCreateDocuments } from "../../store/reducers/documentationReduser";
import { useDispatch } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { getForSelect } from "../../utils/getForSelect";
import Screen from "../Screen/Screen";
import AccessEdit from "../AccessEdit/AccessEdit";
import { asyncGetPermissions } from "../../store/reducers/documentationReduser";
import { createArray } from "../../utils/createArray";
import { formatLabel } from "../../utils/formatLabel";
import { asyncGetProjectAllCompanies } from "../../store/reducers/projectsReducer";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import { toast } from "react-toastify";
import { useSearch } from "../../hooks/useSearch";

function ModalAddDoc(props) {
  const { typeFolder, pages, fatherFolder, handleChange, headFolder, type = "" } = props;

  const { changeModalVisible, getInfo } = useModal();

  const { queries } = useSearch();

  const formId = useId();

  const dispatch = useDispatch();

  const [permissionsListСompanies, setPermissionsListСompanies] = useState({}); // все доступные компании
  const [permissionsListProfiles, setPermissionsListProfiles] = useState({}); // все доступные компании
  const [permissionsСompanies, setPermissionsСompanies] = useState({}); // активные доступы
  const [permissionsProfiles, setPermissionsProfiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  const [customers, setCustumers] = useState([]);
  const [executors, setExecutors] = useState([]);

  const [titleModal, setTitleModal] = useState("Добавить документ");

  const { options, changeOptions } = useChangeOptions({
    folder: {
      options: [],
      value: null,
    },
  });

  const getProjectPermissions = async () => {
    setLoading(true);

    const res = await dispatch(
      asyncGetProjectAllCompanies({
        project_id: fatherFolder.ref_id,
      }),
    );
    // console.log("getProjectPermissions", res.payload);

    const genContractor = res.payload.generalContractor;
    const custumersPermissions =
      res.payload.customers.map((company) => ({
        name: company.name,
        _id: company._id,
      })) || [];

    const executorsPermissions =
      res.payload.executors.map((company) => ({
        name: company.name,
        _id: company._id,
      })) || [];
    console.log(
      "custumersPermissions executorsPermissions",
      custumersPermissions,
      executorsPermissions,
    );
    // console.log("getProjectPermissions type", type);
    if (type === "customer") {
      // console.log(custumersPermissions);
      setCustumers(custumersPermissions);
      setPermissionsListСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...custumersPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...custumersPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    } else if (type === "executor") {
      setExecutors(executorsPermissions);
      setPermissionsListСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...executorsPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...executorsPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    } else {
      setPermissionsListСompanies({
        view: [
          { _id: genContractor._id, name: genContractor.name },
          ...custumersPermissions,
          ...executorsPermissions,
        ],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    }
    setPermissionsListProfiles({
      view: [],
      add: [],
      edit: [],
      copy: [],
      move: [],
      remove: [],
    });
    setPermissionsProfiles({
      view: [],
      add: [],
      edit: [],
      copy: [],
      move: [],
      remove: [],
    });
    setLoading(false);
  };

  const getPermissions = async (type = "child", id) => {
    setLoading(true);
    const res = await dispatch(asyncGetPermissions({ fileId: id }));

    if (res.error) return;
    // if (type === "father") {
    const companiesAccesses = res.payload.companies_accesses;
    const profilesAccesses = res.payload.profiles_accesses;

    setPermissionsListСompanies({
      view: companiesAccesses.view || [],
      add: companiesAccesses.add || [],
      edit: companiesAccesses.edit || [],
      copy: companiesAccesses.copy || [],
      move: companiesAccesses.move || [],
      remove: companiesAccesses.remove || [],
    });
    setPermissionsListProfiles({
      view:
        profilesAccesses.view.map((u) => ({
          name: formatLabel(u),
          _id: u._id,
        })) || [],
      add: profilesAccesses.add || [],
      edit: profilesAccesses.edit || [],
      copy: profilesAccesses.copy || [],
      move: profilesAccesses.move || [],
      remove: profilesAccesses.remove || [],
    });

    setPermissionsСompanies({
      view: companiesAccesses.view || [],
      add: companiesAccesses.add || [],
      edit: companiesAccesses.edit || [],
      copy: companiesAccesses.copy || [],
      move: companiesAccesses.move || [],
      remove: companiesAccesses.remove || [],
    });
    setPermissionsProfiles({
      view:
        profilesAccesses.view.map((u) => ({
          name: formatLabel(u),
          _id: u._id,
        })) || [],
      add: profilesAccesses.add || [],
      edit: profilesAccesses.edit || [],
      copy: profilesAccesses.copy || [],
      move: profilesAccesses.move || [],
      remove: profilesAccesses.remove || [],
    });

    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("type", "file");
    formData.append("father_folder", fatherFolder._id);
    // formData.append("document", file);
    file.forEach((d) => formData.append("document", d));
    let companies_accesses = JSON.stringify({
      view: createArray(permissionsСompanies.view, "_id"),
      add: createArray(permissionsСompanies.add, "_id"),
      edit: createArray(permissionsСompanies.edit, "_id"),
      copy: createArray(permissionsСompanies.copy, "_id"),
      move: createArray(permissionsСompanies.move, "_id"),
      remove: createArray(permissionsСompanies.remove, "_id"),
    });
    formData.append("companies_accesses", companies_accesses);
    let profiles_accesses = JSON.stringify({
      view: createArray(permissionsProfiles.view, "_id"),
      add: createArray(permissionsProfiles.add, "_id"),
      edit: createArray(permissionsProfiles.edit, "_id"),
      copy: createArray(permissionsProfiles.copy, "_id"),
      move: createArray(permissionsProfiles.move, "_id"),
      remove: createArray(permissionsProfiles.remove, "_id"),
    });
    formData.append("profiles_accesses", profiles_accesses);
    // formData.append("folder", options.folder.value.value);
    await dispatch(asyncCreateDocuments(formData));
    // await findDocumets();
    // changeCurrentFolder("all");
    handleChange && handleChange();
    changeModalVisible("addDocument", false);
  };

  const addFile = (data) => {
    setFile((prev) => [...prev, ...data]);
  };

  const removeFile = (lastModified) => {
    setFile(file.filter((obj) => obj.lastModified != lastModified));
  };

  // уведомления
  const startNotif = (text) => {
    return toast.warning(text);
  };
  // проверка добавлен ли зак\подрядчик
  const compareAccesses = (permisssinos, list) => {
    let sortArr = permisssinos?.filter((elem) => {
      return list?.find((item) => elem._id === item._id);
    });
    return sortArr.length > 0 ? false : true;
  };

  const checkAccesses = (permission) => {
    if (fatherFolder.main && fatherFolder.ref === "project") {
      if (permission.length === 0 && permissionsProfiles?.view?.length === 0) {
        startNotif("При сохарнении без доступов, будут унаследованы доступы родительской папки");
      }
      if (type === "customer" && compareAccesses(permission, customers)) {
        startNotif("Для создания файла заказчика, добавьте хотя бы одну компанию заказчика");
      } else if (type === "executor" && compareAccesses(permission, executors)) {
        startNotif("Для создания файла подрядчика, добавьте хотя бы одну компанию подрядчика");
      }
    }
  };

  useEffect(() => {
    if (type === "customer" && pages === "project") {
      return setTitleModal("Добавить документ для заказчика");
    } else if (type === "performer" && pages === "project") {
      return setTitleModal("Добавить документ для исполнителя");
    } else {
      setTitleModal(
        `Добавить ${typeFolder === "photos" ? "фотографию" : "документ"}
         ${fatherFolder?._id === headFolder?._id ? "" : `в папку ${fatherFolder.name}`}`,
      );
      //setType("");
    }
  }, [type, pages]);

  useEffect(() => {
    if (getInfo("addDocument", "data")) {
      const folder = options.folder.options.find((p) => p.value === getInfo("addDocument", "data"));
      if (folder && !options.folder.value) changeOptions("folder", "value", folder);
    }
  }, [options.folder.options]);

  useEffect(() => {
    if (fatherFolder?.main && fatherFolder?.ref === "project") {
      getProjectPermissions();
    } else {
      getPermissions("father", fatherFolder?._id);
    }
  }, []);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addDocument", false)}
      title={titleModal}
      btns={
        <Button disabled={!file.length} color="blue" title="Добавить" type="submit" form={formId} />
      }
    >
      <form onSubmit={onSubmit} className="modalAddDoc" id={formId}>
        <div className="modalAddDoc-main">
          {!file.length ? (
            <div className="modalAddDoc-main-upload">
              <Screen size={"lg"}>
                <FileUploader
                  types={typeFolder === "photos" ? ["jpeg", "png", "svg", "jpg"] : []}
                  handleChange={(data) => addFile(data)}
                  multiple={true}
                />
              </Screen>
              <Screen size={"lg"} down>
                <FileUploader
                  title="Фотография:"
                  types={typeFolder === "photos" ? ["jpeg", "png", "svg", "jpg"] : []}
                  handleChange={(data) => addFile(data)}
                  typeBtn
                  multiple={true}
                />
              </Screen>
            </div>
          ) : (
            <div className="modalAddDoc-main-form-file">
              <div className="modalAddDoc-main-form-file-grid">
                <FileUploader
                  types={typeFolder === "photos" ? ["jpeg", "png", "svg", "jpg"] : []}
                  handleChange={(data) => {
                    addFile(data);
                  }}
                  typeBtn
                  multiple={true}
                />
              </div>
              <span className="modalAddDoc-main-form-file-title">Файлы:</span>
              {/* <DocItem
                shadow
                event={{ remove: () => setFile(null) }}
                btnVisible={{ remove: true }}
                info={file}
              /> */}
              <div className="modalAddDoc-main-form-file-grid">
                {!!file &&
                  file?.map((elem, index) => {
                    return (
                      <DocItem
                        key={elem.lastModified}
                        shadow
                        event={{ remove: () => removeFile(elem.lastModified) }}
                        btnVisible={{ remove: true }}
                        info={elem}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          <div className="hr"></div>
          <span className="modalInfoEditFolder-main-title">Доступы:</span>
          <WrapperLoader loading={loading} chapter={"permissionsFileEdit"}>
            <AccessEdit
              permissionsList={permissionsListСompanies}
              permissions={permissionsСompanies}
              setPermissions={setPermissionsСompanies}
              typePermissions={"company"}
              fatherFolder={fatherFolder}
              typeFile={"file"}
              searchProfile={fatherFolder?._id === headFolder?._id}
              checkAccesses={checkAccesses}
            />
            <AccessEdit
              permissionsList={permissionsListProfiles}
              permissions={permissionsProfiles}
              setPermissions={setPermissionsProfiles}
              typePermissions={"profile"}
              fatherFolder={fatherFolder}
              typeFile={"file"}
              searchProfile={fatherFolder?._id === headFolder?._id}
            />
          </WrapperLoader>
        </div>
        <Screen size={"lg"}>
          <Button
            disabled={!file.length}
            color="blue"
            title="Добавить"
            type="submit"
            form={formId}
          />
        </Screen>
      </form>
    </ModalWrapper>
  );
}

export default ModalAddDoc;
