const statusesWork = [
  { name: "Назначено", name_en: "assigned", _id: "assigned" },
  { name: "Отклонено", name_en: "rejected", _id: "rejected" },
  { name: "В работе", name_en: "atWork", _id: "atWork" },
  { name: "Просрочено", name_en: "overdue", _id: "overdue" },
  { name: "Выполнено", name_en: "completed", _id: "completed" },
  { name: "На проверке", name_en: "onVerification", _id: "onVerification" },
];

const typesWork = [
  { name: "Приемка работы", name_en: "acceptance_work", _id: "acceptance_work" },
  { name: "Контроль исполнения", name_en: "execution_control", _id: "execution_control" },
];

const chapterWork = [
  { name: "Физическая приемка", name_en: "physical", _id: "physical" },
  { name: "Документальная приемка", name_en: "documentary", _id: "documentary" },
];

export const transformSection = (section) => {
  const data = chapterWork.find((c) => c._id === section);

  return data || chapterWork[0];
};

export const transformTypeWork = (type) => {
  const data = typesWork.find((c) => c._id === type);

  return data || typesWork[0];
};
