import React, { useState, useEffect } from "react";
import Button from "../../../../../components/Button/Button";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Pagination from "../../../../../components/Pagination/Pagination";
import SearchInputMobile from "../../../../../components/SearchInputMobile/SearchInputMobile";
import { useSelects } from "../../../../../hooks/useSelects";
import "./ModalAddParticipantMobile.scss";
import axios from "../../../../../axios/axios";
import { useSelector } from "react-redux";
import { asyncAddEmployeesToProject } from "../../../../../store/reducers/projectsReducer";
import { useDispatch } from "react-redux";
import ModalWrapperMobile from "../../../../../components/ModalWrapperMobile/ModalWrapperMobile";
import EmployeesCardMobile from "../../../../../components/EmployeesCardMobile/EmployeesCardMobile";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import { useSearch } from "../../../../../hooks/useSearch";

function ModalAddParticipantMobile(props) {
  const { changeModalVisible, get_employees } = props;
  const dispatch = useDispatch();
  const { _id: project_id } = useSelector((state) => state.projects.project);
  const [participants, setParticipants] = useState([]);
  const [count, setCount] = useState([]);
  const [numberPage, setNumberPage] = useState(1);
  const { search } = useSearch();
  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(participants);

  const getParticipants = async (userFio = "") => {
    const res = await axios.post(`/projects/get_employees_for_add/${project_id}/${numberPage}`, {
      userFio,
    });
    if (res.data) {
      setParticipants(res.data.users);
      setCount(res.data.count);
    }
  };

  const add_employee_from_project = async () => {
    await dispatch(asyncAddEmployeesToProject(selectItems));

    changeModalVisible("addParticipant");
    await search({ nativePage: "project:participants" });
    clearItems();
  };

  const changeSearchIV = (v) => {
    if (v === "") getParticipants();
  };

  useEffect(() => {
    getParticipants();
  }, [numberPage]);

  return (
    <ModalWrapperMobile
      title={"Добавить участников в проект"}
      onClose={() => changeModalVisible("addParticipant", false)}
      btns={
        <div className="modalAddParticipantMobile-btns">
          {" "}
          <Button
            onClick={add_employee_from_project}
            title="Добавить"
            color="blue"
            icon
            disabled={!selectItems?.length}
          />
        </div>
      }
    >
      <div className="modalAddParticipantMobile">
        <div className="modalAddParticipantMobile-header">
          <span>Всего сотрудников: {count}</span>
          <div className="modalAddParticipantMobile-header-check">
            <Checkbox
              onCheck={addAllItemSelect}
              check={selectItems?.length === participants?.length}
            />
            <span>Выбрать всех</span>
          </div>
        </div>
        <div className="modalAddParticipantMobile-searchPanel">
          <SearchInputMobile
            onSubmit={(v) => getParticipants(v)}
            onChange={(v) => changeSearchIV(v)}
            inputSearch={{
              visible: true,
              placeholder: "Найти сотрудника по ФИО",
            }}
          />
        </div>
        <div className="modalAddParticipantMobile-grid">
          {participants?.map((participant, i) => (
            // <CardRowsParticMin
            //   key={participant._id}
            //   check={selectItems.includes(participant._id)}
            //   info={participant}
            //   addItemSelect={addItemSelect}
            // />
            <EmployeesCardMobile
              key={participant._id}
              info={participant}
              modalCard={true}
              check={selectItems.includes(participant._id)}
              addItemSelect={addItemSelect}
              type="user"
            />
            // <span>{i}</span>
          ))}
        </div>
        <div className="modalAddParticipantMobile-footer">
          <div className="modalAddParticipantMobile-footer-pagination">
            {count > getLimitElements() && (
              <Pagination
                count={Math.ceil(count / 10)}
                onPageChange={(n) => setNumberPage(n)}
                modalAddChat
                mobile={true}
              />
            )}
          </div>
        </div>
      </div>
    </ModalWrapperMobile>
  );
}

export default ModalAddParticipantMobile;
