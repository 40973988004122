import React from "react";
import "./EmptySection.scss";
import Button from "../Button/Button";
import bulding from "../../assets/imgEmpty/bulding.svg";
import pipe from "../../assets/imgEmpty/pipe.svg";
import shovel from "../../assets/imgEmpty/shovel.svg";
import cone from "../../assets/imgEmpty/cone.svg";
import barrier from "../../assets/imgEmpty/barrier.svg";
import zaplan from "../../assets/imgEmpty/zaplan.svg";
import Screen from "../Screen/Screen";

const EmptySection = ({ text, textBtn, onClick, nobtn = false }) => {
  return (
    <div className="emptySection customScroll">
      <div className="emptySection-content">
        <div className="emptySection-img">
          <Screen size={"lg"}>
            <img src={bulding} alt="" className="emptySection-img-building" />
            <img src={pipe} alt="" className="emptySection-img-pipe" />
            <img src={shovel} alt="" className="emptySection-img-shovel" />
            <img src={cone} alt="" className="emptySection-img-cone" />
            <img src={barrier} alt="" className="emptySection-img-barrier" />
            <img src={zaplan} alt="" className="emptySection-img-zaplan" />
          </Screen>
          <Screen size={"lg"} down>
            <img src={zaplan} alt="" className="emptySection-img-zaplan-logo" />
          </Screen>
        </div>
        <h2 className="emptySection__title">{text}</h2>
        {/* {nobtn || (
          <Button
            color="blue"
            title={textBtn}
            icon={{ position: "left" }}
            onClick={onClick}
          />
        )} */}
      </div>
    </div>
  );
};
export default EmptySection;
