import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitsListChart } from "../../utils/getLimitsListChart";

export const taskReportsAnalitics = createAsyncThunk(
  "analytics/taskReportsAnalitics",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;

    try {
      const res = await axios.post(`/analytics/task_reports`, data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const taskEmployeesAnalitics = createAsyncThunk(
  "analytics/taskEmployeesAnalitics",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    const page = data?.page || 1;
    const limit = getLimitsListChart() || 10;
    try {
      const res = await axios.post(`/analytics/employee_tasks/${page}/?limit=${limit}`, {
        company: data.company,
        status: data.status || "",
        scale: data.scale || "",
        global_scale: data.global_scale || null,
      });

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const analyticsReducer = createSlice({
  name: "analytics",
  initialState: {
    taskReports: [],
    taskEmployees: [],

    ProjectsOnMap: [
      {
        id: 1,
        name: "ДОУ 330",
        address: "г. Москва, ул. Центральная, 153",
        position: {
          top: "50",
          left: "50",
        },
      },
      {
        id: 2,
        name: "ДОУ 331",
        address: "г. Москва, ул. Центральная, 154",
        position: {
          top: "60",
          left: "65",
        },
      },
      {
        id: 3,
        name: "ДОУ 332",
        address: "г. Москва, ул. Центральная, 155",
        position: {
          top: "70",
          left: "50",
        },
      },
    ],

    loading: false,
    error: false,
  },
  reducers: {
    setTaskReportsAnalitics: (state, action) => {
      state.taskReports = action.payload;
    },
    setTaskEmployeesAnalitics: (state, action) => {
      state.taskReports = action.payload;
    },
  },
  extraReducers: extraReducersWrapper({
    [taskReportsAnalitics.fulfilled]: (state, { payload }) => {
      state.taskReports = payload;
    },

    [taskReportsAnalitics.rejected]: "Не удалось получить отчет по задачам.",

    [taskEmployeesAnalitics.fulfilled]: (state, { payload }) => {
      state.taskEmployees = payload;
    },
    [taskEmployeesAnalitics.rejected]: "Не удалось получить отчет по задачам сотрудников компании.",
  }),
});
export const { setTaskReportsAnalitics } = analyticsReducer.actions;

export default analyticsReducer.reducer;
