import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const get_regions = createAsyncThunk("regions/get_regions", async () => {
  try {
    const res = await axios.get("regions/get");
    return {
      regions: res.data,
    };
  } catch (error) {
    console.log(error);
  }
});

const regionsReducer = createSlice({
  name: "regions",
  initialState: {
    regions: [],
  },
  extraReducers: {
    [get_regions.fulfilled]: (state, action) => {
      const { regions } = action.payload;
      state.regions = regions;
    },
  },
});

export default regionsReducer.reducer;
