import React, { useState } from "react";
import Icons from "../../../../../Icons/Icons";
import Screen from "../../../../../Screen/Screen";
import { useModal } from "../../../../../../hooks/useModal";
import { getFormatDate } from "../../../../../../utils/getFormatDate";
import dayjs from "dayjs";
import "./ProjectWorkScheduleColListContent.scss";
import ProjectWorkScheduleColListRow from "./components/ProjectWorkScheduleColListRow/ProjectWorkScheduleColListRow";
import ProjectWorkScheduleColListCard from "./components/ProjectWorkScheduleColListCard/ProjectWorkScheduleColListCard";

const ProjectWorkScheduleColListContent = ({ tasks, stageId, edit }) => {
  const { changeModalVisible } = useModal();

  return (
    <div className="projectWorkScheduleColListContent">
      <Screen size={"xs"}>
        <div className="projectWorkScheduleColListContent-header">
          <span className="projectWorkScheduleColListContent-header-number">№:</span>
          <span title="Наименование работы:">Наименование работы:</span>
          <span title="Подрядчик:">Подрядчик:</span>
          <span title="Дата начала:">Дата начала:</span>
          <span title="Дата окончания:">Дата окончания:</span>
          <span title="Кол-во дней:">Кол-во дней:</span>
          <span title="Процент выполнения:">Процент:</span>
          <div className="projectWorkScheduleCol-list-stages-item-works-header-btns"></div>
        </div>
      </Screen>
      {tasks?.map((task, i) => (
        <>
          <Screen size={"xs"}>
            <ProjectWorkScheduleColListRow key={task._id} info={task} edit={edit} />
          </Screen>
          <Screen size={"xs"} down>
            <ProjectWorkScheduleColListCard key={task._id} info={task} edit={edit} />
          </Screen>
        </>
      ))}
    </div>
  );
};

export default ProjectWorkScheduleColListContent;
