import React, { useState } from "react";
import Input from "../../../../../components/Input/Input";
import Textarea from "../../../../../components/Textarea/Textarea";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import Select from "../../../../../components/Select/Select";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import InputDate from "../../../../../components/InputDate/InputDate";
import Button from "../../../../../components/Button/Button";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import FileUploader from "../../../../../components/FileUploader/FileUploader";
import DocItem from "../../../../../components/DocItem/DocItem";
import "./ModalEditLetterDeliveryWork.scss";
import { useDispatch } from "react-redux";
import { useModal } from "../../../../../hooks/useModal";
import { getFullPath } from "../../../../../utils/getFullPath";
import { getFormatFIO } from "../../../../../utils/getFormatFIO";
import InputSearch from "../../../../../components/InputSearch/InputSearch";
import { getValueForOptions } from "./utils/getValueForOptions";
import {
  asyncCreateLetterDeliveryWork,
  asyncEditLetterDeliveryWork,
} from "../../../../../store/reducers/deliveryWorkReducer";
import InputFile from "../../../../../components/InputFile/InputFile";

const ModalEditLetterDeliveryWork = (props) => {
  const { mode, handleChange } = props;

  const dispatch = useDispatch();

  const modalName = mode === "edit" ? "editLetterDeliveryWork" : "addLetterDeliveryWork";

  const { changeModalVisible, getInfo, intermediateSaving } = useModal();

  const infoWork = getInfo(modalName, "data");
  const infoAnswer = getInfo(modalName, "data")?.engineer_answer;
  const infoLetter = infoAnswer?.letter;

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      name: infoLetter?.name || "",
      description: infoLetter?.description || "",
      delivery_dates: infoLetter?.delivery_dates
        ? new Date(infoLetter?.delivery_dates)
        : new Date(),
      documents: infoLetter?.documents || [],
    }),
    modalName,
  );

  const { options, changeOptions, checkValidateOptions } = intermediateSaving(
    useChangeOptions({
      director_ssk: {
        value: getValueForOptions(infoLetter, "director_ssk"),
        options: [],
      },
      chief_director: {
        value: getValueForOptions(infoLetter, "chief_director"),
        options: [],
      },
      contractor: {
        value: getValueForOptions(infoLetter, "contractor"),
        options: [],
      },
    }),
    modalName,
  );

  const changeFiles = (event, data) => {
    if (event === "add") changeIV("set", { name: "documents", value: data.documents });
    else if (event === "delete") {
      const newArr = IV.documents.filter((_, i) => i !== data.i);
      changeIV("set", { name: "documents", value: newArr });
    }
  };

  const closeModal = () => {
    changeModalVisible(modalName, false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const arrForValidate = [
      checkValidateIV("name", { required: true, maxHeight: 50 }),
      checkValidateIV("description", { maxHeight: 5000 }),
      checkValidateOptions("director_ssk", { required: true }),
      checkValidateOptions("chief_director", { required: true }),
      checkValidateOptions("contractor", { required: true }),
    ];

    if (mode === "add") {
      arrForValidate.push(
        checkValidateIV("delivery_dates", {
          noMoreDate: { start: new Date(), end: IV.delivery_dates },
        }),
      );
    }

    if (IV.documents.length > 5) return toast.error("Максимальное количество документов 5");

    if (arrForValidate.some((el) => !el)) return;

    const formData = new FormData();

    if (IV.documents.length) {
      IV.documents.forEach((document) => {
        if (document instanceof File) formData.append("document", document);
      });
    }

    if (mode === "edit") {
      formData.append(
        "documents",
        JSON.stringify(IV.documents.filter((d) => !(d instanceof File))),
      );
    }

    formData.append("name", IV.name);
    formData.append("description", IV.description);
    formData.append("delivery_dates", IV.delivery_dates);
    formData.append("director_ssk", options.director_ssk.value.value);
    formData.append("chief_director", options.chief_director.value.value);
    formData.append("contractor", options.contractor.value.value);

    let res = { error: true };
    if (mode === "add") {
      res = await dispatch(
        asyncCreateLetterDeliveryWork({
          answerId: infoAnswer._id,
          data: formData,
        }),
      );
    } else if (mode === "edit") {
      formData.append("send", true);
      res = await dispatch(
        asyncEditLetterDeliveryWork({
          letterId: infoLetter._id,
          data: formData,
        }),
      );
    }

    if (res.error) return;

    closeModal();
    handleChange && handleChange();
  };

  return (
    <ModalWrapper
      title={mode === "add" ? "Создание письма о сдаче ИД" : "Письмо о сдаче ИД"}
      onClose={closeModal}
    >
      <form onSubmit={onSubmit} className="modalDeliveryWorkEmail-form modalDeliveryWorkEmail-form">
        <Input
          title="Название письма:*"
          value={IV.name}
          onChange={changeIV}
          name="name"
          placeholder="Введите название задачи не более 50 символов"
          validate={checkValidateIV("name")}
        />

        <ul className="modalDeliveryWorkEmail-form-person">
          <li className="modalDeliveryWorkEmail-form-person-item">
            <span className="modalDeliveryWorkEmail-form-person-title">Инженер ССК:</span>
            <div className="modalDeliveryWorkEmail-form-person-info ">
              <img
                src={getFullPath(infoWork.engineer.user.avatar, "avatar")}
                alt="Фото инспектора"
                className="modalDeliveryWorkEmail-form-person-avatar"
              />
              <div className="modalDeliveryWorkEmail-form-person-info-wrapper">
                <span className="modalDeliveryWorkEmail-form-person-info-name">
                  {getFormatFIO(infoWork.engineer.user)}
                </span>
                <span className="modalDeliveryWorkEmail-form-person-info-position">
                  {infoWork.engineer.company.name}, {infoWork.engineer.position.name}
                </span>
              </div>
            </div>
          </li>
          <div className="modalDeliveryWorkEmail-form-project">
            <span className="modalDeliveryWorkEmail-form-project-title">Проект:</span>
            <span className="modalDeliveryWorkEmail-form-project-name">
              {infoWork?.project?.name || "-"}
            </span>
          </div>
          <InputDate
            title="Срок сдачи:"
            value={IV.delivery_dates}
            onChange={(data) => {
              changeIV("set", { name: "delivery_dates", value: data });
            }}
            validate={checkValidateIV("delivery_dates")}
          />
        </ul>
        <ul className="modalDeliveryWorkEmail-form-center">
          <li className="modalDeliveryWorkEmail-form-center-column">
            <InputSearch
              options={options.director_ssk.options}
              value={options.director_ssk.value}
              handleChange={(value) => changeOptions("director_ssk", "value", value)}
              placeholder={"Выберите руководителя"}
              forInput
              menuPlacement="top"
              title="Руководитель ССК:*"
              // optionsForSearch={{ position: "Руководитель ССК", profile: true }}
              optionsForSearch={{ profile: true }}
              validate={checkValidateOptions("director_ssk")}
            />
          </li>

          <li className="modalDeliveryWorkEmail-form-center-column">
            <InputSearch
              options={options.chief_director.options}
              value={options.chief_director.value}
              handleChange={(value) => changeOptions("chief_director", "value", value)}
              placeholder={"Выберите главного инжeнера"}
              forInput
              menuPlacement="top"
              title="Главный инжeнер:*"
              // optionsForSearch={{ position: "Главный инженер" }}
              optionsForSearch={{ profile: true }}
              validate={checkValidateOptions("chief_director")}
            />
          </li>
          <li className="modalDeliveryWorkEmail-form-center-column">
            <InputSearch
              type="company"
              options={options.contractor.options}
              value={options.contractor.value}
              handleChange={(value) => changeOptions("contractor", "value", value)}
              placeholder={"Выберите подрядчика"}
              forInput
              menuPlacement="top"
              title="Подрядчик:*"
              optionsForSearch={{ engineer: true }}
              validate={checkValidateOptions("contractor")}
            />
          </li>
        </ul>
        <div className="modalDeliveryWorkEmail-form-center">
          <InputFile
            title="Файлы:"
            value={IV.documents}
            placeholder="Прикрепите файлы работы"
            onChange={(documents) => changeFiles("add", { documents })}
            deleteFile={(i) => changeFiles("delete", { i })}
          />
        </div>

        <Textarea
          onChange={changeIV}
          value={IV.description}
          height="170px"
          name="description"
          title="Какая документация не сдана:"
          placeholder="Введите описание задачи не более 5000 символов"
          validate={checkValidateIV("description")}
        />
        <div className="modalDeliveryWorkEmail-form-btns">
          {mode === "add" && <Button type="submit" title="Создать" color="blue" />}
          {mode === "edit" && <Button type="submit" title="Отправить" color="dark" />}
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditLetterDeliveryWork;
