import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import Pagination from "../../../components/Pagination/Pagination";
import { useSearch } from "../../../hooks/useSearch";
import Button from "../../../components/Button/Button";
import "./Notifications.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  asyncDeleteAllNotification,
  asyncDeleteNotification,
  asyncReadAllNotification,
  asyncReadNotification,
} from "../../../store/reducers/notificationsReducer";
import Icons from "../../../components/Icons/Icons";
import { toast } from "react-toastify";
import { ErrorBoundary } from "../../../ui";

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notificationsState = useSelector((state) => state.notifications);

  const { search } = useSearch("notifications");

  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const goToLink = (notification) => {
    if (notification.link) {
      const linkTo = notification.link;

      notification.read || toRead(notification);

      return navigate(linkTo);
    }

    notification.read || toRead(notification);
  };

  const toRead = async (notification) => {
    setLoading(true);
    const res = await dispatch(asyncReadNotification(notification._id));
    setLoading(false);

    if (res.error) return;

    const newNotifications = notifications.map((n) => {
      if (n._id === notification._id) {
        return {
          ...n,
          read: true,
        };
      }

      return n;
    });

    setNotifications(newNotifications);
  };

  const toReadAll = async () => {
    if (!count) {
      return toast.success("Нет новых уведомлений");
    }

    setLoading(true);
    const res = await dispatch(asyncReadAllNotification());
    setLoading(false);

    if (res.error) return;

    search();
  };

  const remove = async (e, notification) => {
    e.stopPropagation();

    if (!notification.read && notification.link) {
      return toast.error("Чтобы удалить уведомление, необходимо его отметить, как прочитанное");
    }

    setLoading(true);
    const res = await dispatch(asyncDeleteNotification(notification._id));
    setLoading(false);

    if (res.error) return;

    search();
  };

  const removeAll = async () => {
    if (!count) {
      return toast.success("Нет новых уведомлений");
    }

    setLoading(true);
    const res = await dispatch(asyncDeleteAllNotification());
    setLoading(false);

    if (res.error) return;

    search();
  };

  useEffect(() => {
    setNotifications(notificationsState.notifications);
    setCount(notificationsState.count);
  }, [notificationsState]);

  return (
    <ErrorBoundary>
      <div className="notifications">
        <div className="notifications-content">
          <div className="notifications-content-header">
            <LeftInfo title={`Новых уведомлений: ${count}`} />

            <div className="notifications-content-header-btns">
              <div onClick={toReadAll} className="notifications-content-header-btns-button">
                {/* Прочесть все */}
                <Icons
                  type={"notif"}
                  size={"xl"}
                  title={{ visible: true, text: "Удалить все уведомления" }}
                />
              </div>
              <div onClick={removeAll} className="notifications-content-header-btns-button">
                {/* Удалить все */}
                <Icons
                  type={"cleaning"}
                  size={"xl"}
                  title={{ visible: true, text: "Удалить все уведомления" }}
                />
              </div>
            </div>
          </div>
          <div className="notifications-list customScroll">
            {notifications.map((notification) => (
              <div className={`notifications-list-item`}>
                <div
                  onClick={() => goToLink(notification)}
                  key={notification._id}
                  className={`notifications-list-item-title ${loading ? "disabled" : ""}`}
                >
                  <div className="notifications-list-item-title-status">
                    {notification.read && <Icons type={"twoChechmark"} size={"md"} />}

                    {!notification.read && (
                      <div className="notifications-list-item-title-status-circle" />
                    )}
                  </div>

                  <span>{notification.title}</span>

                  <Icons onClick={(e) => remove(e, notification)} type={"remove"} size={"md"} />
                </div>
              </div>
            ))}
          </div>
          <Pagination count={count} type={"notifications"} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Notifications;
