import React, { useEffect, useState, useRef } from "react";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Textarea from "../Textarea/Textarea";
import "./ModalEditProtocol.scss";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import InputSearch from "../InputSearch/InputSearch";
import SubtaskList from "../SubtaskList/SubtaskList";
import ModalVersion from "./components/modalVersion/modalVersion";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const ModalEditProtocol = (props) => {
  const {
    IV,
    changeIV,
    changeOptions,
    options,
    checkValidateIV,
    checkValidateOptions,
    project,
    mode,
    modal,
    handleChange, // отработает после изменения протокола
  } = props;

  const [tasks, setTasks] = useState([]);
  const [menuImport, setMenuImport] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setMenuImport(false));

  const { changeModalVisible, getInfo } = useModal();

  const infoEvent = mode === "add" ? getInfo("addEvent", "data") : getInfo("editEvent", "data");

  useEffect(() => {
    if (modal) return;
    if (infoEvent?.tasks) {
      setTasks(infoEvent.tasks);

      if (infoEvent.protocol?.tasks?.length) {
        setTasks((prev) => {
          const arr = [];
          for (let i = 0; i < infoEvent.protocol.tasks.length; i++) {
            const taskProtocol = infoEvent.protocol.tasks[i];

            if (!prev.find((task) => task._id === taskProtocol._id)) {
              arr.push(taskProtocol);
            }
          }
          return [...prev, ...arr];
        });
      }
    } else if (infoEvent?.protocol && infoEvent.protocol?.tasks?.length) {
      setTasks(infoEvent.protocol.tasks);
    }
  }, [infoEvent]);

  useEffect(() => {
    if (getInfo("editTask", "data")) {
      const { _id, name, start, end, projects, status } = getInfo("editTask", "data");

      setTasks((prev) =>
        prev.map((task) => {
          if (task._id === _id) {
            return {
              ...task,
              name,
              start,
              end,
              status,
              project: projects.value
                ? { _id: projects.value.value, name: projects.value.label }
                : null,
            };
          }
          return task;
        }),
      );
    }
  }, []);

  if (modal) return <ModalVersion handleChange={handleChange} mode={mode || "edit"} />;

  return (
    <div className={`modalEditProtocol`}>
      <div className="modalEditProtocol-header">
        <h5 className="modalEditProtocol-section-title">{`Протокол совещания ${IV.protocolNumber}`}</h5>
        <div className="modalEditProtocol-header-modalMenu">
          <div onClick={() => setMenuImport((prev) => !prev)}>
            <Icons type={"download"} size={"md"} color={"white"} />
          </div>
          {menuImport && (
            <div ref={ref} className="modalEditProtocol-header-menuImport">
              <span>Экспорт в PDF</span>
              <span>Экспорт в EXCEL</span>
            </div>
          )}
        </div>
      </div>
      <div className="modalEditProtocol-grid">
        <InputSearch
          type="project"
          forInput
          value={options.project.value}
          options={options.project.options}
          placeholder="Выберите проект"
          title="Проект:*"
          menuPlacement="top"
          handleChange={(data) => changeOptions("project", "value", data)}
          validate={checkValidateOptions("project")}
        />
      </div>
      <Input
        validate={checkValidateIV("protocolName")}
        title="Название:*"
        placeholder="Введите название протокола"
        name="protocolName"
        value={IV.protocolName}
        onChange={changeIV}
      />
      <Textarea
        value={IV.protocolDescription}
        name="protocolDescription"
        onChange={changeIV}
        height="170px"
        title="Протокол:"
        placeholder="Опишите ход мероприятия не более 5000 символов"
        validate={checkValidateIV("protocolDescription")}
      />
      <div className="modalEditProtocol-grid">
        <InputSearch
          value={options.secretary.value}
          options={options.secretary.options}
          handleChange={(data) => changeOptions("secretary", "value", data)}
          placeholder="Выберите сотрудника"
          title="Автор протокола:*"
          menuPlacement="top"
          forInput
          validate={checkValidateOptions("secretary")}
          optionsForSearch={{ project: project?.value, profile: true, my_profile: true }}
          isDisabled={!project}
        />
      </div>
      <div className="modalEditProtocol-participants">
        <h5 className="modalEditProtocol-title">Участники:</h5>

        <InputSearch
          type="users+companies"
          value={options.employees_gencontractor.value}
          options={options.employees_gencontractor.options}
          handleChange={(data) => changeOptions("employees_gencontractor", "value", data)}
          title="от Ген.подрядчика:*"
          menuPlacement="top"
          isDisabled={!project}
          multi={true}
          forInput
          validate={checkValidateOptions("employees_gencontractor")}
          optionsForSearch={{ from_gen_contractor: true, project: project?.value, profile: true }}
          listModal={true}
        />
        <InputSearch
          type="users+companies"
          value={options.employees_customer.value}
          options={options.employees_customer.options}
          handleChange={(data) => changeOptions("employees_customer", "value", data)}
          title="от Заказчика:"
          menuPlacement="top"
          isDisabled={!project}
          multi
          forInput
          optionsForSearch={{ from_customer: true, project: project?.value, profile: true }}
          listModal={true}
        />
        <InputSearch
          type="users+companies"
          value={options.employees_executor.value}
          options={options.employees_executor.options}
          handleChange={(data) => changeOptions("employees_executor", "value", data)}
          title="от Исполнителя:"
          menuPlacement="top"
          isDisabled={!project}
          multi
          forInput
          optionsForSearch={{ from_executor: true, project: project?.value, profile: true }}
          listModal={true}
        />
      </div>

      {!!tasks.length && (
        <>
          <div className="hr"></div>
          <SubtaskList
            title="Задачи"
            buttonAddTask={false}
            mode={`protocol.${mode}`}
            tasks={tasks}
            fatherId={infoEvent?.protocol?._id}
          />
        </>
      )}

      <Button
        onClick={() => changeModalVisible("addTask", true)}
        title="Задача"
        icon={{ position: "left" }}
        color="dark"
      />
    </div>
  );
};

export default ModalEditProtocol;
