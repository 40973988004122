export const mediaBreakpoints = {
  xxxs: 350,
  xxs: 450,
  xs: 600,
  sm: 768,
  md: 979,
  lg: 1024.5,
  xl: 1200,
  xxl: 1300,
  xxxl: 1455,
  xxxxl: 1700,
};
