import React from "react";
import "./SkeletonCircle.scss";

const SkeletonCircle = ({ size }) => {
  return (
    <div
      className="skeletonCircle"
      style={{ width: size || "70px", height: size || "70px" }}
    />
  );
};

export default SkeletonCircle;
