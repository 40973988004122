import React, { useState, useEffect } from "react";
import "./LayoutMobileHeader.scss";
import Icons from "../../../Icons/Icons";
import SearchBox from "./components/SearchBox/SearchBox";
import NavbarMobile from "./components/NavbarMobile/NavbarMobile";
import { useSearch } from "../../../../hooks/useSearch";

const linkPage = [
  {
    name: "Аналитика",
    path: "analytics",
  },
  {
    name: "Проекты",
    path: "projects",
  },
  {
    name: "Задачи",
    path: "tasks",
  },
  // {
  //   name: "Сдача работ",
  //   path: "deliveryWork",
  // },

  {
    name: "Календарь",
    path: "calendar",
  },
  {
    name: "Сотрудники",
    path: "employees",
  },
  {
    name: "Документы",
    path: "documentation",
  },
  {
    name: "Чат",
    path: "chat",
  },
  {
    name: "Справка",
    path: "reference",
  },
  // {
  //   name: "Формы",
  //   path: "forms",
  // },
  {
    name: "Профиль",
    path: "profile",
  },
  {
    name: "Уведомления",
    path: "notifications",
  },
  {
    name: "Настройки",
    path: "settings",
  },
  {
    name: "Выйти",
    path: "exit",
  },
];

const linkPageHeader = [
  {
    name: "Аналитика",
    path: "analytics",
  },
  {
    name: "Проекты",
    path: "projects",
  },
  {
    name: "Задачи",
    path: "tasks",
  },
  // {
  //   name: "Сдача работ",
  //   path: "deliveryWork",
  // },

  {
    name: "Календарь",
    path: "calendar",
  },
  {
    name: "Сотрудники",
    path: "employees",
  },
  {
    name: "Информация о сотруднике",
    path: "userInfo",
  },
  {
    name: "Документы",
    path: "documentation",
  },
  {
    name: "Чат",
    path: "chat",
  },
  {
    name: "Справка",
    path: "reference",
  },
  // {
  //   name: "Формы",
  //   path: "forms",
  // },
  {
    name: "Профиль",
    path: "profile",
  },
  {
    name: "Уведомления",
    path: "notifications",
  },
  {
    name: "Настройки",
    path: "settings",
  },
  {
    name: "Выйти",
    path: "exit",
  },
];

const LayoutMobileHeader = ({
  titleLayout,
  currentPage,
  activePage,
  search = {
    inputSearch: {
      visible: false,
    },
  },
}) => {
  const { getCurrentPage } = useSearch();

  const page = getCurrentPage();

  const [activeSearch, setActiveSearch] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  const getInfo = () => {
    return linkPageHeader
      .map((el) => {
        if (page.includes(el.path)) {
          return el;
        } else {
          return null;
        }
      })
      .filter((element) => element != null);
  };
  useEffect(() => {
    if (!!activePage) setActiveSearch(false);
  }, [activePage]);

  return (
    <>
      <header className="layoutMobileHeader">
        <div className="mobile-container">
          <div className="layoutMobileHeader-wrapper">
            <div className="layoutMobileHeader-left">
              {activeSearch ? (
                <Icons
                  type={"arrow"}
                  size={"m-xl"}
                  className={"header-icon"}
                  onClick={() => setActiveSearch(false)}
                />
              ) : (
                <div className="layoutMobileHeader-menu">
                  <div
                    id="nav-icon3"
                    className={`layoutMobileHeader-menu-icon ${activeMenu ? "open" : ""}`}
                    onClick={() => setActiveMenu(!activeMenu)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
            {activeMenu ? (
              <>
                <div className="layoutMobileHeader-name">
                  <h1 className="layoutMobileHeader-name-title">Меню</h1>
                </div>
                <div className="layoutMobileHeader-plug"></div>
              </>
            ) : (
              <>
                {!activeSearch && (
                  <div className="layoutMobileHeader-name">
                    <Icons type={getInfo()[0]?.path} size={"m-xl"} className={"header-icon"} />
                    <h1 className="layoutMobileHeader-name-title">
                      {titleLayout ? titleLayout : getInfo()[0]?.name || "Страница"}
                    </h1>
                  </div>
                )}
                <SearchBox
                  onClickActive={() => setActiveSearch(true)}
                  activeSearch={activeSearch}
                  inputSearch={search.inputSearch}
                  currentPage={currentPage}
                  onChange={search.onChange}
                  onSubmit={search.onSubmit}
                  setActiveSearch={setActiveSearch}
                />
              </>
            )}
          </div>
        </div>
      </header>
      <NavbarMobile
        linkPage={linkPage}
        activeMenu={activeMenu}
        currentPage={currentPage}
        setActiveMenu={setActiveMenu}
      />
    </>
  );
};

export default LayoutMobileHeader;
