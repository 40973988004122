import React, { useState, useEffect, useRef } from "react";
import "./MenuMarks.scss";
import allMarks from "../../assets/icons/allMarks.svg";
import rejectedMarks from "../../assets/icons/rejectedMarks.svg";
import overdueMarks from "../../assets/icons/overdueMarks.svg";
import atWorkMarks from "../../assets/icons/atWorkMarks.svg";
import completedTasksMarks from "../../assets/icons/completedTasksMarks.svg";
import assignedMarks from "../../assets/icons/assignedMarks.svg";
import onVerificationMarks from "../../assets/icons/onVerificationMarks.svg";
import hideMarks from "../../assets/icons/eyeCloseBlack.svg";
import pointWhite from "../../assets/icons/point-white.svg";
import pointBlack from "../../assets/icons/point-black.svg";
import markersWhite from "../../assets/icons/markers-white.svg";
import markersBlack from "../../assets/icons/markers-black.svg";
import Select from "../Select/Select";
import { useDispatch } from "react-redux";
import { asyncEditTask, asyncGetTask } from "../../store/reducers/tasksReducer";
import { useModal } from "../../hooks/useModal";
import { useSelector } from "react-redux";
import { transformForSelect } from "../../utils/transformForSelect";
import { getRangePin } from "../../utils/layer/utils";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Button from "../../components/Button/Button";

function MenuMarks(props) {
  const {
    options,
    changeOptions,
    formAdding,
    visiblePoints,
    setVisiblePoints,
    drawingChoose,
    setDrawingChoose,
    currentLayer,
    taskPoint,
    setTaskPoint,
    setLinesTask,
    editLayer,
  } = props;

  const dispatch = useDispatch();

  const { project } = useSelector((state) => state.projects);

  const { changeModalVisible } = useModal();

  const [taskList, setTaskList] = useState([]);
  const [taskListVisible, setTaskListVisible] = useState(false);

  const taskListRef = useRef();
  useOnClickOutside(taskListRef, () => setTaskListVisible(false));

  useEffect(() => {
    if (options.marks.value.value === "all") {
      setTaskList(currentLayer?.tasks);
    } else {
      const currentTask = currentLayer?.tasks.filter((task) => {
        return task?.status === options.marks.value.value;
      });

      setTaskList(currentTask);
    }
  }, [options.marks.value?.value, currentLayer]);

  const getOptionsWithIcon = (opts) => {
    return opts.map((o) => {
      switch (o.value) {
        case "all": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={allMarks} alt="all" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        case "rejected": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={rejectedMarks} alt="rejected" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        // case "overdue": {
        //   return {
        //     ...o,
        //     label: (
        //       <div className="menuMarks-selectMarks-item">
        //         <img src={overdueMarks} alt="overdue" />
        //         <span>{o.label}</span>
        //       </div>
        //     ),
        //   };
        // }
        case "atWork": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={atWorkMarks} alt="atWork" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        case "completedTasks": {
          console.log("completed");
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={completedTasksMarks} alt="completedTasks" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        case "assigned": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={assignedMarks} alt="assigned" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        case "onVerification": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={onVerificationMarks} alt="onVerification" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        case "disabled": {
          return {
            ...o,
            label: (
              <div className="menuMarks-selectMarks-item">
                <img src={hideMarks} alt="disabled" />
                <span>{o.label}</span>
              </div>
            ),
          };
        }
        default: {
          return {
            ...o,
          };
        }
      }
    });
  };

  const changeChoose = (name) => {
    setDrawingChoose((prev) => (prev === name ? "" : name));
  };

  const getTask = async (taskId) => {
    if (!taskId) return;
    const task = await dispatch(asyncGetTask(taskId));
    if (task.error) return;
    changeModalVisible("infoTask", true, task.payload);
  };

  const addTask = () => {
    changeModalVisible("addTask", true, {
      project: transformForSelect(project),
      layer: transformForSelect(currentLayer),
    });
  };

  const deletePoint = async () => {
    const coordinates = JSON.parse(taskPoint.task.coordinates);
    let lines = getRangePin(coordinates.lines);
    const rangeForDelete = taskPoint.line.range;
    const newLines = lines.filter((l) => {
      if (l.isPoint && !Array.isArray(rangeForDelete)) {
        if (
          !(
            l.range.x.first === rangeForDelete.x.first &&
            l.range.y.first === rangeForDelete.y.first &&
            l.range.x.last === rangeForDelete.x.last &&
            l.range.y.last === rangeForDelete.y.last
          )
        ) {
          return l;
        }
      } else {
        const { range } = l;
        const totalRange = (() => {
          if (Array.isArray(range)) {
            return range.reduce((acc, r) => (acc += +r.x + +r.y), 0);
          }
        })();
        const totalRangeForDelete = (() => {
          if (Array.isArray(rangeForDelete)) {
            return rangeForDelete.reduce((acc, r) => (acc += +r.x + +r.y), 0);
          }
        })();
        if (range.length !== rangeForDelete.length && totalRange !== totalRangeForDelete) return l;
      }
    });
    const newCoordinates = { ...coordinates, lines: newLines };
    const newTasksLayer = taskList.map((t) => {
      if (t._id === taskPoint.task._id) {
        return {
          ...t,
          coordinates: JSON.stringify(newCoordinates),
        };
      }
      return t;
    });

    const res = await dispatch(
      asyncEditTask({
        taskId: taskPoint.task._id,
        data: {
          coordinates: JSON.stringify(newCoordinates),
        },
      }),
    );

    if (res.error) return;

    setTaskList(newTasksLayer);
    setLinesTask(taskPoint.task._id, newTasksLayer);
    setTaskPoint(null);
  };

  return (
    <div className="menuMarks">
      <Screen size={"lg"}>
        <div className="menuMarks-btns">
          <span onClick={addTask} className="menuMarks-btns-title">
            {formAdding ? "Отменить" : "Добавить задачу"}
          </span>
          {/* <div className="hr"></div>
        <div
          onClick={() =>
            formAdding ? null : setVisiblePoints((prev) => !prev)
          }
          className="menuMarks-btns-elem menuMarks-btns-visibleMarks"
        >
          <Icons
            type={formAdding || visiblePoints ? "eyeOpen" : "eyeClose"}
            size={"md"}
            color={"white"}
            cursor
          />
        </div> */}
        </div>
      </Screen>
      {/* {!!taskPoint && (
        <div className="menuMarks-delelePoint">
          <span>Поинт задачи: {taskPoint.task.name}</span>
          <div className="hr"></div>
          <div className="menuMarks-delelePoint-delete">
            <Icons type="remove" color="white" size="md" />
          </div>
        </div>
      )} */}

      {formAdding && (
        <div className="menuMarks-choose">
          <BtnsChoose drawingChoose={drawingChoose} changeChoose={changeChoose} />
        </div>
      )}
      {formAdding || (
        <div className="menuMarks-selectMarks">
          <Select
            isClearable={false}
            maxMenuHeight={600}
            isSearchable={false}
            handleChange={(data) => changeOptions("marks", "value", data)}
            options={getOptionsWithIcon(options.marks.options)}
            value={options.marks.value}
          />
          <Screen size={"sm"} down>
            <Button
              color="blue"
              icon={{ only: true, type: "tasks" }}
              onClick={() => setTaskListVisible((prev) => !prev)}
            />
          </Screen>
          <Screen size={"sm"} down>
            {taskListVisible && (
              <div className="menuMarks-task-mobile" ref={taskListRef}>
                <span className="menuMarks-task-mobile-title">Задачи</span>
                <ul className="menuMarks-task-mobile-list customScroll">
                  {taskList.map((elem) => {
                    return (
                      <li
                        key={elem._id}
                        className="menuMarks-task-mobile-item"
                        onClick={() => getTask(elem._id)}
                      >
                        <span>{elem.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Screen>
        </div>
      )}
      <Screen size={"sm"}>
        <div className="menuMarks-task">
          <span className="menuMarks-task-title">Задачи</span>
          <ul className="menuMarks-task-list customScroll">
            {taskList.map((elem) => {
              return (
                <li
                  key={elem._id}
                  className="menuMarks-task-item"
                  onClick={() => getTask(elem._id)}
                >
                  <span>{elem.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </Screen>
    </div>
  );
}

function BtnsChoose({ drawingChoose, changeChoose }) {
  return (
    <>
      <div
        className={`menuMarks-choose-item ${drawingChoose === "pin" ? "active" : ""}`}
        onClick={() => changeChoose("pin")}
      >
        <img src={drawingChoose === "pin" ? pointWhite : pointBlack} alt="pin" />
      </div>
      <div
        className={`menuMarks-choose-item ${drawingChoose === "pen" ? "active" : ""}`}
        onClick={() => changeChoose("pen")}
      >
        <img src={drawingChoose === "pen" ? markersWhite : markersBlack} alt="pen" />
      </div>
    </>
  );
}

export default MenuMarks;
