import React from "react";
import "./DeliveryWorkRequestsMobileCard.scss";
import Icons from "../../../../../components/Icons/Icons";
import { getBaseUrl } from "../../../../../utils/getBaseUrl";
import { getFormatFIO } from "../../../../../utils/getFormatFIO";
import { transformRole } from "../../../../../utils/transformRole";
import { useModal } from "../../../../../hooks/useModal";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { getFormatDate } from "../../../../../utils/getFormatDate";
import { getFormatStatus } from "../../../../../utils/getFormatStatus";
import { transformSection } from "../../../../../utils/transformSection";
import dayjs from "dayjs";

const DeliveryWorkRequestsMobileCard = ({ number, check, onCheck, info }) => {
  const { getVisible, changeModalVisible } = useModal();
  const {
    name,
    engineer,
    section,
    call_date,
    status,
    project,
    engineer_answer,
    creator,
  } = info;

  return (
    <li className="deliveryWorkRequestsMobileCard">
      <div className="deliveryWorkRequestsMobileCard-header">
        <div className="deliveryWorkRequestsMobileCard-header-info">
          {/* <div className="deliveryWorkRequestsMobileCard-header-info-check">
            <Checkbox check={check} onCheck={onCheck} />
            <span>№ {number}</span>
          </div> */}
          <div className="tasksGridCardMobile-header-status">
            <span className="tasksGridCardMobile-header-status-title">
              Статус:
            </span>{" "}
            <span
              className={`tasksGridCardMobile-header-status-type ${status}`}
              style={{ color: getFormatStatus(status).color }}
            >
              {getFormatStatus(status).text}
            </span>
          </div>
          <Icons
            type={"menu"}
            color={"black"}
            size={"m-md"}
            onClick={() => changeModalVisible("menu", true, info)}
          />
        </div>
        <h4 className="deliveryWorkRequestsMobileCard-header-title">{name}</h4>
      </div>
      <ul
        className="deliveryWorkRequestsMobileCard-content"
        onClick={() => changeModalVisible("сheckingDeliveryWork", true, info)}
      >
        <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Проект:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {project?.name || "-"}
          </span>
        </li>
        <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Вид работы:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {transformSection(section).name}
          </span>
        </li>
        <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Дата вызова:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {dayjs(call_date).format("DD.MM.YYYY")}
          </span>
        </li>

        <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Автор:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {getFormatFIO(creator)}
          </span>
        </li>
        <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Инженер:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {getFormatFIO(engineer)}
          </span>
        </li>

        {/* <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Дата вызова:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {getFormatDate(dateStart)}
          </span>
        </li> */}

        {/* <li className="deliveryWorkRequestsMobileCard-content-item">
          <span className="deliveryWorkRequestsMobileCard-content-item-title">
            Раздел:
          </span>
          <span className="deliveryWorkRequestsMobileCard-content-item-text">
            {typeReceiving.name}
          </span>
        </li> */}
      </ul>
    </li>
  );
};

export default DeliveryWorkRequestsMobileCard;
