import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, createSearchParams } from "react-router-dom";
import {
  useQueryParams,
  StringParam,
  ObjectParam,
  ArrayParam,
  NumberParam,
  DateParam,
  encodeObject,
  encodeDate,
  encodeArray,
  encodeNumber,
  withDefault,
} from "use-query-params";
import { asyncGetEvents } from "../store/reducers/calendarReducer";
import { getCatalogWork } from "../store/reducers/catalogWorkReducer";
import { asyncGetChats } from "../store/reducers/chatReduser";
import { asyncGetCompanies } from "../store/reducers/companiesReducer";
import { asyncGetDeliveryWorks } from "../store/reducers/deliveryWorkReducer";
import { asyncGetFolders, asyncGetFoldersEntity } from "../store/reducers/documentationReduser";
import { asyncGetGroups } from "../store/reducers/groupsReducer";
import { asyncGetNotifications } from "../store/reducers/notificationsReducer";
import { getPosition } from "../store/reducers/positionsReducer";
import {
  asyncGetLayers,
  asyncGetProject,
  asyncGetProjectCompanies,
  asyncGetProjectEmployees,
  asyncGetProjects,
  asyncGetProjectTasks,
  asyncGetProtocols,
  asyncGetScheduleProductionWorks,
  asyncGetUserProjects,
  setProject,
} from "../store/reducers/projectsReducer";
import { asyncGetReferences } from "../store/reducers/referencesReducer";
import { asyncGetStructures } from "../store/reducers/structuresReducer";
import {
  asyncGetTasks,
  asyncGetTasksForKanban,
  asyncGetUserTasks,
} from "../store/reducers/tasksReducer";
import { asyncGetAlloweNotification, asyncGetUsers, logout } from "../store/reducers/userReducer";
import { clearModals } from "../store/reducers/modalsReduces";

const pages = {
  default: "analytics",
  analytics: 1,
  projects: 1,
  project: 1,
  "project:basicInformation": 1,
  "project:participants": 1,
  "project:companies": 1,
  "project:tasks": 1,
  "project:layers": 1,
  "project:documentation": 1,
  "project:protocols": 1,
  "project:workSchedule": 1,
  "project:photoReports": 1,
  tasks: 1,
  tasksKanban: 1,
  deliveryWork: 1,
  calendar: 1,

  userInfo: 1,
  "userInfo:info": 1,
  "userInfo:projects": 1,
  "userInfo:tasks": 1,

  employees: 1,
  "employees:companies": 1,
  "employees:users": 1,
  "employees:groups": 1,
  "employees:structures": 1,

  company: 1,
  "company:info": 1,
  "company:projects": 1,
  "company:tasks": 1,
  "company:users": 1,

  documentation: 1,
  chat: 1,
  reference: 1,
  profile: 1,
  notifications: 1,
  settings: 1,
  "settings:general": 1,
  "settings:employees": 1,
  "settings:project": 1,
  exit: 1,
  notifications: 1,
};

const pagesQueries = {
  analytics: {},
  projects: {
    tab: {
      all: 1,
      creator: 1,
      member: 1,
      default: "all",
      clear: false,
    },
    limit: { default: undefined },
    period: { default: undefined },
    region: { default: undefined },
    roomType: { default: undefined },
    name: { default: "" },
    company: { default: undefined },
  },
  "project:basicInformation": {
    edit: {
      default: 0,
      1: 1,
      0: 1,
      clear: false,
    },
  },
  "project:participants": {
    tab: {
      genContractor: 1,
      customer: 1,
      executor: 1,
      default: "genContractor",
    },
    limit: { default: undefined },
    position: { default: undefined },
    name: { default: "" },
  },
  "project:companies": {
    tab: {
      genContractor: 1,
      customer: 1,
      executor: 1,
      default: "genContractor",
    },
    limit: { default: undefined },
    name: { default: "" },
  },
  "project:tasks": {
    tab: {
      all: 1,
      incoming: 1,
      outgoing: 1,
      default: "all",
    },
    limit: { default: undefined },
    name: { default: "" },
  },
  "project:layers": {
    limit: { default: undefined },
    name: { default: "" },
  },
  "project:documentation": {
    tab: {
      genContractor: 1,
      customer: 1,
      executor: 1,
      default: "genContractor",
    },
    limit: { default: undefined },
    name: { default: "" },
    father: { default: undefined, clear: false },
  },
  "project:protocols": {
    limit: { default: undefined },
    name: { default: "" },
    period: { default: undefined },
  },
  "project:workSchedule": {
    limit: { default: undefined },
    name: { default: "" },
    period: { default: undefined },
    work: { default: undefined },
    edit: { default: 0, 1: 1, 0: 1, clear: false },
    create: { default: 0, 1: 1, 0: 1, clear: false },
  },
  "project:photoReports": {
    tab: {
      genContractor: 1,
      customer: 1,
      default: "genContractor",
    },
    limit: { default: undefined },
    name: { default: "" },
    father: { default: undefined, clear: false },
  },

  tasks: {
    tab: {
      all: 1,
      incoming: 1,
      outgoing: 1,
      default: "all",
    },
    limit: { default: undefined },
    name: { default: "" },
    status: { default: undefined },
    overdue: { default: undefined },
    author: { default: undefined },
    executor: { default: undefined },
    project: { default: undefined },
    start: { default: undefined },
    end: { default: undefined },
    isImportant: { default: undefined },
    company: { default: undefined },
  },

  tasksKanban: {
    tab: {
      all: 1,
      incoming: 1,
      outgoing: 1,
      default: "all",
    },
    name: { default: "" },
    author: { default: undefined },
    executor: { default: undefined },
    project: { default: undefined },
    start: { default: undefined },
    end: { default: undefined },
    isImportant: { default: undefined },
    company: { default: undefined },
    overdue: { default: undefined },
  },

  deliveryWork: {
    limit: { default: undefined },
    name: { default: "" },
    status: { default: undefined },
    author: { default: undefined },
    project: { default: undefined },
    section: { default: undefined },
    period: { default: undefined },
  },

  calendar: {
    date: { default: undefined },
  },

  employees: {
    limit: { default: undefined },
    name: { default: "" },
    region: { default: undefined },
    position: { default: undefined },
    company: { default: undefined },
    role: { default: undefined },
  },
  "employees:users": {
    limit: { default: undefined },
    name: { default: "" },
    region: { default: undefined },
    position: { default: undefined },
    company: { default: undefined },
    role: { default: undefined },
  },
  "employees:groups": {
    limit: { default: undefined },
    name: { default: "" },
    director: { default: undefined },
    company: { default: undefined },
    quantity: { default: undefined },
  },
  "employees:companies": {
    limit: { default: undefined },
    name: { default: "" },
    region: { default: undefined },
    director: { default: undefined },
    quantity: { default: undefined },
    typeCompanies: { default: undefined },
  },
  "employees:structures": {
    company: { default: undefined },
  },

  userInfo: {},
  "userInfo:info": { limit: { default: undefined } },
  "userInfo:projects": {
    tab: {
      all: 1,
      creator: 1,
      member: 1,
      default: "all",
      clear: false,
    },
    limit: { default: undefined },
    period: { default: undefined },
    region: { default: undefined },
    roomType: { default: undefined },
    name: { default: "" },
    company: { default: undefined },
  },
  "userInfo:tasks": {
    tab: {
      all: 1,
      incoming: 1,
      outgoing: 1,
      default: "all",
    },
    limit: { default: undefined },
    name: { default: "" },
    status: { default: undefined },
    overdue: { default: undefined },
    author: { default: undefined },
    executor: { default: undefined },
    project: { default: undefined },
    start: { default: undefined },
    end: { default: undefined },
    isImportant: { default: undefined },
    company: { default: undefined },
  },
  company: {},
  "company:info": { limit: { default: undefined } },
  "company:users": {
    limit: { default: undefined },
    name: { default: "" },
    region: { default: undefined },
    position: { default: undefined },
    company: { default: undefined },
    role: { default: undefined },
  },
  "company:projects": {
    tab: {
      all: 1,
      creator: 1,
      member: 1,
      default: "all",
      clear: false,
    },
    limit: { default: undefined },
    period: { default: undefined },
    region: { default: undefined },
    roomType: { default: undefined },
    name: { default: "" },
    company: { default: undefined },
    limit: { default: undefined },
  },
  "company:tasks": {
    tab: {
      all: 1,
      incoming: 1,
      outgoing: 1,
      default: "all",
    },
    limit: { default: undefined },
    name: { default: "" },
    status: { default: undefined },
    overdue: { default: undefined },
    author: { default: undefined },
    executor: { default: undefined },
    project: { default: undefined },
    start: { default: undefined },
    end: { default: undefined },
    isImportant: { default: undefined },
    company: { default: undefined },
  },
  documentation: {
    tab: {
      genContractor: 1,
      customer: 1,
      executor: 1,
      default: undefined,
    },
    limit: { default: undefined },
    companyId: { default: undefined },
    projectId: { default: undefined },
    name: { default: "" },
    father: { default: undefined },
    chapter: {
      companies: 1,
      projects: 1,
      project: 1,
      company: 1,
      clear: false,
      default: "projects",
    },
  },

  chat: {
    limit: { default: undefined },
    name: { default: "" },
  },

  reference: {
    limit: { default: undefined },
    name: { default: "" },
  },

  profile: {},

  "settings:general": { limit: { default: undefined } },

  "settings:employees": {
    limit: { default: undefined },
    name: { default: "" },
  },

  "settings:project": {
    limit: { default: undefined },
    name: { default: "" },
  },

  notifications: {
    limit: { default: undefined },
  },
};

const useHelpers = () => {
  const dispatch = useDispatch();
  const dataProjects = useSelector((state) => state.projects);

  const getProjectAndSet = useCallback(async (id) => {
    if (dataProjects.project) return;

    const project = await dispatch(asyncGetProject(id));

    if (project.error) return goToPage("projects");

    dispatch(setProject(project.payload));
  });

  return { getProjectAndSet };
};

export const useSearch = (page) => {
  const [currentPage] = useState(page);
  const { globalLimit } = useSelector((state) => state.search);
  // * список фильтров, которые буду применены
  const [filtersQuery, setFiltersQuery] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { getProjectAndSet } = useHelpers();

  const limitFromLocalStorage = localStorage.getItem("limit");
  const deafultLimit = globalLimit ? globalLimit : limitFromLocalStorage;

  const LimitParam = withDefault(NumberParam, deafultLimit);

  const [queries, setQueries] = useQueryParams({
    tab: StringParam,
    edit: NumberParam,
    name: StringParam,
    roomType: ObjectParam,
    region: ObjectParam,
    project: ObjectParam,
    author: ObjectParam,
    status: ObjectParam,
    section: ObjectParam,
    executor: ObjectParam,
    overdue: ObjectParam,
    period: ArrayParam,
    periodСontract: ArrayParam,

    director: ObjectParam,
    participant: ObjectParam,
    customer: ObjectParam,
    responsible: ObjectParam,
    position: ObjectParam,
    company: ObjectParam,
    role: ObjectParam,
    author: ObjectParam,
    executor: ObjectParam,
    numberEmployees: ObjectParam,
    typeCompanies: ObjectParam,
    start: DateParam,
    end: DateParam,
    date: DateParam,
    isImportant: ObjectParam,
    instructions: ObjectParam,
    quantity: ObjectParam,

    father: StringParam,
    work: StringParam,
    create: StringParam,
    companyId: StringParam,
    projectId: StringParam,
    chapter: StringParam,

    chatId: StringParam,

    limit: LimitParam,
  });

  const checkingQueryes = (options = {}) => {
    let page = currentPage;

    if (options?.nativePage) {
      page = options.nativePage;
    }

    if (!page) return;

    const allPageQueries = pagesQueries[page];

    if (!allPageQueries) return clearParams();

    const resultQueries = JSON.parse(JSON.stringify(queries));

    for (const key in queries) {
      if (!allPageQueries.hasOwnProperty(key)) {
        resultQueries[key] = undefined;
      } else {
        if (
          queries[key] === undefined ||
          queries[key] === null ||
          queries[key] === "undefined" ||
          queries[key] === "null" ||
          (Array.isArray(queries[key]) && queries[key].includes("undefined")) ||
          (typeof queries[key] === "object" && queries[key].hasOwnProperty("undefined"))
        ) {
          resultQueries[key] = allPageQueries[key]?.default || undefined;
        } else {
          resultQueries[key] = queries[key];
        }
      }
    }

    setQueries(resultQueries);

    return JSON.stringify(queries) === JSON.stringify(resultQueries);
  };

  const getCurrentPage = () => {
    let pathNameArray = location.pathname.split("/");
    let possiblePage = pathNameArray[1];

    if (possiblePage === "project" && !!params.chapter) {
      possiblePage = `${possiblePage}:${params.chapter}`;
    }

    if (possiblePage === "employees" && !!params.chapter) {
      possiblePage = `${possiblePage}:${params.chapter}`;
    }

    if (possiblePage === "settings" && !!params.chapter) {
      possiblePage = `${possiblePage}:${params.chapter}`;
    }

    if (possiblePage === "userInfo" && !!params.list) {
      possiblePage = `${possiblePage}:${params.list}`;
    }

    if (possiblePage === "company") {
      let type = "tasks";
      if (location.pathname.includes("tasks")) {
        type = "tasks";
      } else if (location.pathname.includes("projects")) {
        type = "projects";
      } else if (location.pathname.includes("users")) {
        type = "users";
      }
      possiblePage = `${possiblePage}:${type}`;
    }

    if (possiblePage && pages[possiblePage]) {
      return possiblePage;
    } else {
      return pages.default;
    }
  };

  const setQueryParam = (data, options = {}) => {
    const newQueries = {};

    for (const key in data) {
      if (!queries.hasOwnProperty(key) || data[key] === undefined) continue;

      if (key === "name" && data[key] === "") {
        newQueries[key] = null;
      } else {
        newQueries[key] = data[key];
      }
    }

    // console.log("setQueryParam", { queries, newQueries });

    // * проверка, если в newQueries есть ключ name
    // * то обнуляем страницу до 1
    if (newQueries.hasOwnProperty("name")) {
      const page = getCurrentPage();
      const { paramsForNavigateSearch } = getParamsForRequest();
      console.info("getCurrentPage", page);
      goToPage(
        page,
        { numberPage: 1 },
        {
          ...paramsForNavigateSearch,
          ...newQueries,
        },
      );
      return;
    }

    // * проверка, если в newQueries есть ключ tab
    // * то обнуляем страницу до 1
    if (newQueries.hasOwnProperty("tab")) {
      const page = getCurrentPage();
      const { paramsForNavigateSearch } = getParamsForRequest();
      console.info("getCurrentPage", page);
      goToPage(
        page,
        { numberPage: 1 },
        {
          ...paramsForNavigateSearch,
          ...newQueries,
        },
      );
      return;
    }

    if (options.clear) {
      clearParams();
      setQueries(newQueries);
      return;
    }

    setQueries({
      ...queries,
      ...newQueries,
    });
  };

  const setFilter = (filter, data) => {
    if (!filter || !data) return;
    if (typeof data !== "object") return;
    if (typeof data === "object" && data.hasOwnProperty("undefined")) return;

    if (!queries.hasOwnProperty(filter)) return;

    const checkFilter = filtersQuery.find((f) => f.name === filter);

    const newFilter = { name: filter, data };

    // console.log('setFilter', newFilter);

    if (checkFilter) {
      setFiltersQuery((prev) => {
        return prev.map((f) => {
          if (f.name === filter) return newFilter;
          return f;
        });
      });

      return;
    }

    setFiltersQuery((prev) => [...prev, newFilter]);
  };

  const changePage = (numberPage) => {
    // let page = currentPage;
    const page = getCurrentPage();

    // * если currentPage = null, то есть она не указана, мы совершаем поиск возможной страницы
    // if (!page) page = getCurrentPage();

    if (numberPage === +params.page) {
      return console.log("numberPage === +params.page");
    }

    // console.log("changePage", numberPage, page);

    goToPage(page, { numberPage, withoutDefaultQueries: true });
  };

  const setParams = (param, value) => {
    //console.log("setParams", { param, value });
    if (!params.hasOwnProperty(param) || !value) return;

    if (param === "page" && !!+value) changePage(value);
  };

  // TODO сделать проверку на queries, чтобы не делать лишний запрос
  const queriesIsClean = () => {
    for (const key in queries) {
      if (queries[key]) return false;
    }

    return true;
  };

  const clearParams = () => {
    let page = currentPage;
    if (!page) page = getCurrentPage();

    const clearedParams = {};

    // * если страница не известна, то просто чистим все параметры
    if (!page) {
      for (const key in queries) {
        clearedParams[key] = undefined;
      }
    } else {
      for (const key in queries) {
        if (pagesQueries[page] && pagesQueries[page][key]) {
          if (pagesQueries[page][key].clear || pagesQueries[page][key].clear === undefined) {
            clearedParams[key] = undefined;
          }
        } else {
          clearedParams[key] = undefined;
        }
      }
    }

    setParams("page", 1);
    setQueries(clearedParams);
    setFiltersQuery([]);
  };

  const getParamsForRequest = () => {
    const paramsForRequest = {};
    const paramsForNavigateSearch = {};

    // * поиск фильров в query параметрах адресной строки
    for (const key in queries) {
      if (!queries[key]) continue;
      if (typeof queries[key] === "string" || typeof queries[key] === "number") {
        paramsForRequest[key] = queries[key];
        paramsForNavigateSearch[key] = queries[key];
      } else if (Array.isArray(queries[key])) {
        paramsForRequest[key] = queries[key];
        paramsForNavigateSearch[key] = queries[key];
      } else if (queries[key] instanceof Date) {
        paramsForRequest[key] = queries[key];
        paramsForNavigateSearch[key] = encodeDate(queries[key]);
      } else {
        paramsForRequest[key] = queries[key].value;
        paramsForNavigateSearch[key] = encodeObject(queries[key]);
      }
    }

    return { paramsForRequest, paramsForNavigateSearch };
  };

  const getParamsForQuery = () => {
    const paramsForQuery = {};

    // * провяем список фильтров
    if (filtersQuery.length) {
      for (let i = 0; i < filtersQuery.length; i++) {
        const { name, data } = filtersQuery[i];

        if (data === "string") {
          if (!data.includes("undefined")) paramsForQuery[name] = data;
        } else if (Array.isArray(data)) {
          paramsForQuery[name] = data;
        } else if (data instanceof Date) {
          paramsForQuery[name] = encodeDate(data);
        } else {
          paramsForQuery[name] = encodeObject(data);
        }
      }
    }

    // console.log("getParamsForQuery", paramsForQuery);
    return paramsForQuery;
  };

  const searchByFilter = () => {
    if (!filtersQuery.length) return;

    const paramsForQuery = getParamsForQuery();

    const page = getCurrentPage();

    // console.log("searchByFilter", { filtersQuery });

    goToPage(page, {}, paramsForQuery);

    setFiltersQuery([]);
  };

  const getDefaultQueries = (page) => {
    const allPageQueries = pagesQueries[page];

    if (!allPageQueries) return {};

    const resultQueries = {};

    for (const key in queries) {
      if (allPageQueries.hasOwnProperty(key)) {
        if (allPageQueries[key]?.default !== queries[key]) {
          if (allPageQueries[key]?.default === "") {
            resultQueries[key] = undefined;
          } else {
            if (allPageQueries[key]?.default !== undefined) {
              resultQueries[key] = allPageQueries[key]?.default;
            } else {
              resultQueries[key] = undefined;
            }
          }
        } else {
          resultQueries[key] = undefined;
        }
      } else {
        resultQueries[key] = undefined;
      }
    }

    //console.log("getDefaultQueries", resultQueries, page);

    return resultQueries;
  };

  const search = (options = {}) => {
    if (!options?.nativePage) {
      if (!currentPage) return;
    }
    const { paramsForRequest } = getParamsForRequest();

    let page = options?.nativePage || currentPage;

    if (!page) page = options?.nativePage || getCurrentPage();

    let dataForRequest = {
      page: params.page || 1,
      params: paramsForRequest,
      queries: params,
    };

    if (options?.dataForRequest) {
      dataForRequest = {
        ...dataForRequest,
        ...options.dataForRequest,
      };
    }

    const check = checkingQueryes(options);

    if (!check) return;

    if (!dataForRequest.page || !dataForRequest.params) return;

    // console.log("search", { page, queries });

    if (page === "projects") {
      dispatch(asyncGetProjects(dataForRequest));
    }
    if (page.includes("project:")) {
      getProjectAndSet(params.projectId);
    }
    if (page === "project:basicInformation") {
    } else if (page === "project:participants") {
      dispatch(asyncGetProjectEmployees(dataForRequest));
    } else if (page === "project:companies") {
      dispatch(asyncGetProjectCompanies(dataForRequest));
    } else if (page === "project:tasks") {
      dataForRequest.params.project = params.projectId;
      dispatch(asyncGetProjectTasks(dataForRequest));
    } else if (page === "project:layers") {
      dispatch(asyncGetLayers(dataForRequest));
    } else if (page === "project:documentation") {
      console.log("searc queries.father", queries.father);
      if (!queries.father) return;
      dispatch(asyncGetFolders(dataForRequest));
    } else if (page === "project:protocols") {
      dispatch(asyncGetProtocols(dataForRequest));
    } else if (page === "project:workSchedule") {
      dispatch(asyncGetScheduleProductionWorks(dataForRequest));
    } else if (page === "project:photoReports") {
      if (!queries.father) return;
      dispatch(asyncGetFolders(dataForRequest));
    } else if (page === "tasks") {
      dispatch(asyncGetTasks(dataForRequest));
    } else if (page === "tasksKanban") {
    } else if (page === "deliveryWork") {
      dispatch(asyncGetDeliveryWorks(dataForRequest));
    } else if (page === "calendar") {
      dispatch(asyncGetEvents(dataForRequest));
    } else if (page === "employees:users") {
      dispatch(asyncGetUsers(dataForRequest));
    } else if (page === "employees:groups") {
      dispatch(asyncGetGroups(dataForRequest));
    } else if (page === "employees:companies") {
      dispatch(asyncGetCompanies(dataForRequest));
    } else if (page === "employees:structures") {
      if (!queries.company) return;
      dispatch(asyncGetStructures(dataForRequest));
    } else if (page === "userInfo:info") {
    } else if (page === "userInfo:projects") {
      if (!params.userId) return;
      dispatch(asyncGetUserProjects(dataForRequest));
    } else if (page === "userInfo:tasks") {
      if (!params.userId) return;
      dispatch(asyncGetUserTasks(dataForRequest));
    } else if (page === "company:projects") {
      if (!params.companyId) return;

      const updateDataForRequest = {
        page: dataForRequest.page,
        params: { ...dataForRequest.params, company: params.companyId },
      };
      dispatch(asyncGetProjects(updateDataForRequest));
    } else if (page === "company:tasks") {
      if (!params.companyId) return;
      const updateDataForRequest = {
        page: dataForRequest.page,
        params: { ...dataForRequest.params, company: params.companyId },
      };
      dispatch(asyncGetTasks(updateDataForRequest));
    } else if (page === "company:users") {
      if (!params.companyId) return;
      const updateDataForRequest = {
        page: dataForRequest.page,
        params: { ...dataForRequest.params, company: params.companyId },
      };
      dispatch(asyncGetUsers(updateDataForRequest));
    } else if (page === "documentation") {
      if (queries.chapter === "companies") {
        dataForRequest.queries.chapter = "companies";
        dispatch(asyncGetFoldersEntity(dataForRequest));
      } else if (queries.chapter === "projects") {
        dataForRequest.queries.chapter = "projects";
        dispatch(asyncGetFoldersEntity(dataForRequest));
      } else if (queries.chapter === "project") {
        if (!queries.father) return;
        dispatch(asyncGetFolders(dataForRequest));
      } else if (queries.chapter === "company") {
        if (!queries.father) return;
        dispatch(asyncGetFolders(dataForRequest));
      }
    } else if (page.includes("chat")) {
      dispatch(asyncGetChats(dataForRequest));
    } else if (page === "reference") {
      dispatch(asyncGetReferences(dataForRequest));
    } else if (page === "settings:general") {
      dispatch(asyncGetAlloweNotification(dataForRequest));
    } else if (page === "settings:employees") {
      dispatch(getPosition(dataForRequest));
    } else if (page === "settings:project") {
      dispatch(getCatalogWork(dataForRequest));
    } else if (page === "notifications") {
      dispatch(asyncGetNotifications(dataForRequest));
    }
  };

  const encodValues = (values) => {
    const encodedQueries = {};
    for (const key in values) {
      if (typeof values[key] === "string") {
        encodedQueries[key] = values[key];
      } else if (typeof values[key] === "number") {
        encodedQueries[key] = encodeNumber(values[key]);
      } else if (Array.isArray(values[key])) {
        encodedQueries[key] = encodeArray(values[key]);
      } else if (typeof values[key] === "object") {
        encodedQueries[key] = encodeObject(values[key]);
      }
    }

    return encodedQueries;
  };

  const goToPage = async (page, options = {}, query) => {
    if (!pages.hasOwnProperty(page)) return;

    const numberPage = +options?.numberPage || 1;

    let resultPage = "analytics";

    if (page === "analytics") resultPage = `/analytics`;
    if (page === "projects") resultPage = `/projects/${numberPage}`;

    // * при переходе на страницу проекта, делаем запрос на получение проекта
    if (page === "project") {
      const project = await dispatch(asyncGetProject(options.projectId));

      if (project.error) return;
      dispatch(setProject(project.payload));
      resultPage = `/project/${options.projectId}/basicInformation`;
    }

    if (page.includes("project:")) {
      const projectId = params.projectId;
      const subPage = page.split(":")[1];

      if (subPage === "basicInformation") {
        resultPage = `/project/${projectId}/basicInformation`;
      } else if (subPage === "participants") {
        resultPage = `/project/${projectId}/participants/${numberPage}`;
      } else if (subPage === "companies") {
        resultPage = `/project/${projectId}/companies/${numberPage}`;
      } else if (subPage === "tasks") {
        resultPage = `/project/${projectId}/tasks/${numberPage}`;
      } else if (subPage === "layers") {
        resultPage = `/project/${projectId}/layers/${numberPage}`;
      } else if (subPage === "documentation") {
        resultPage = `/project/${projectId}/documentation/${numberPage}`;
      } else if (subPage === "protocols") {
        resultPage = `/project/${projectId}/protocols/${numberPage}`;
      } else if (subPage === "workSchedule") {
        resultPage = `/project/${projectId}/workSchedule/${numberPage}`;
      } else if (subPage === "photoReports") {
        resultPage = `/project/${projectId}/photoReports/${numberPage}`;
      }
      dispatch(clearModals()); // ! временно - переписать график
    }

    if (page === "tasks") resultPage = `/tasks/${numberPage}`;
    if (page === "tasksKanban") resultPage = `/tasksKanban`;
    if (page === "deliveryWork") resultPage = `/deliveryWork/${numberPage}`;
    if (page === "calendar") resultPage = `/calendar`;

    if (page === "employees") resultPage = `/employees/users/${numberPage}`;

    if (page === "userInfo") {
      const userId = options.userId || params.userId;
      resultPage = `/userInfo/${userId}/info`;
    }

    if (page.includes("userInfo:")) {
      const subPage = page.split(":")[1];
      const userId = params.userId;
      if (subPage === "info") {
        resultPage = `/userInfo/${userId}/info`;
      } else if (subPage === "projects") {
        resultPage = `/userInfo/${userId}/projects/${numberPage}`;
      } else if (subPage === "tasks") {
        resultPage = `/userInfo/${userId}/tasks/${numberPage}`;
      }
    }

    if (page.includes("employees:")) {
      const subPage = page.split(":")[1];

      if (subPage === "users") {
        resultPage = `/employees/users/${numberPage}`;
      } else if (subPage === "companies") {
        resultPage = `/employees/companies/${numberPage}`;
      } else if (subPage === "groups") {
        resultPage = `/employees/groups/${numberPage}`;
      } else if (subPage === "structures") {
        resultPage = `/employees/structures`;
      }
    }

    if (page === "documentation") resultPage = `/documentation/${numberPage}`;

    if (page.includes("documentation:")) {
      const subPage = page.split(":")[1];

      if (subPage === "companies") {
        resultPage = `/documentation/${numberPage}`;
      } else if (subPage === "projects") {
        resultPage = `/documentation/${numberPage}`;
      } else if (subPage === "company") {
        resultPage = `/documentation/${numberPage}`;
      } else if (subPage === "project") {
        resultPage = `/documentation/${numberPage}`;
      }
    }

    if (page === "chat") {
      const chatId = options.chatId;
      if (chatId) {
        resultPage = `/chat/${chatId}`;
      } else {
        resultPage = `/chat`;
      }
    }
    if (page === "reference") resultPage = `/reference/${numberPage}`;
    if (page === "profile") resultPage = `/profile/`;

    if (page === "settings") resultPage = `/settings/general`;

    if (page.includes("settings:")) {
      const subPage = page.split(":")[1];

      if (subPage === "general") resultPage = `/settings/general`;
      if (subPage === "employees") resultPage = `/settings/employees`;
      if (subPage === "project") resultPage = `/settings/project`;
    }

    if (page.includes("company:")) {
      const subPage = page.split(":")[1];

      const companyId = params.companyId;
      if (subPage === "projects") {
        resultPage = `/company/${companyId}/projects/${numberPage}`;
      } else if (subPage === "tasks") {
        resultPage = `/company/${companyId}/tasks/${numberPage}`;
      } else if (subPage === "users") {
        resultPage = `/company/${companyId}/employees/users/${numberPage}`;
      }
    }

    if (page === "exit") return dispatch(logout());

    if (page === "notifications") resultPage = `/notifications/${numberPage}`;

    let { paramsForNavigateSearch } = getParamsForRequest();

    if (!options.withoutDefaultQueries) {
      paramsForNavigateSearch = {
        ...paramsForNavigateSearch,
        ...getDefaultQueries(page),
      };
    }

    if (query) {
      paramsForNavigateSearch = {
        ...paramsForNavigateSearch,
        ...encodValues(query),
      };
    }

    for (const key in paramsForNavigateSearch) {
      if (!paramsForNavigateSearch[key]) delete paramsForNavigateSearch[key];
    }

    navigate({
      pathname: resultPage,
      search: `?${createSearchParams(paramsForNavigateSearch)}`,
    });
  };

  useEffect(() => {
    search();
  }, [queries, currentPage, params.page]);

  return {
    queries,
    filtersQuery,
    params,
    search,
    setParams,
    setFilter,
    changePage,
    setQueryParam,
    clearParams,
    getCurrentPage,
    searchByFilter,
    goToPage,
    getParamsForRequest,
  };
};
