import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Icons from "../../components/Icons/Icons";
import classNames from "classnames";
import MenuDropdownWrapper from "../MenuDropdownWrapper";
import "./MenuDots.scss";

export const MenuDots = ({ schema, horizon, position = "bottom-right", list = [] }) => {
  const [isActive, setActive] = useState(false);
  const refActive = useRef(null);

  useOnClickOutside(refActive, () => setActive(false));

  const activeMenu = (e) => {
    e.preventDefault();
    setActive((prev) => !prev);
  };

  const clickElem = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    item.onClick && item.onClick();
  };

  let filterList = list.filter((item) => item.disabled === undefined || item.disabled);
  console.log(filterList, list, isActive);
  return (
    <div className="menuDots">
      <div
        className={classNames("menuDots-menu", {
          blueButton: schema === "blueButton",
          horizon,
        })}
        onClick={(e) => activeMenu(e)}
      >
        <Icons type={"menu"} color={"black"} size={"md"} cursor />
      </div>
      {isActive && (
        <div className="menuDots-wrapper" ref={refActive}>
          <MenuDropdownWrapper position={position} type="navigate">
            {filterList?.length > 0 &&
              filterList.map((elem, i) => {
                if (elem.path) {
                  return (
                    <Link
                      to={elem.path}
                      target={elem.target}
                      className="menuDots-item menuDots-item-link"
                      key={i}
                    >
                      {elem.text}
                    </Link>
                  );
                } else {
                  return (
                    <span
                      onClick={(e) => clickElem(e, elem)}
                      key={i}
                      className={classNames("menuDots-item", {
                        active: elem.name,
                      })}
                    >
                      {elem.text}
                    </span>
                  );
                }
              })}
          </MenuDropdownWrapper>
        </div>
      )}
    </div>
  );
};
