import React, { useState } from "react";
import { useEffect } from "react";
import "./Settings.scss";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import SettingsEmployees from "../../../components/SettingsEmployees/SettingsEmployees";
import SettingsGeneral from "../../../components/SettingsGeneral/SettingsGeneral";
import SettingsProject from "../../../components/SettingsProject/SettingsProject";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const Settings = () => {
  const { params } = useSearch();

  const { visible, edit, remove } = useHasPermissions("settings");

  const [chapter, setChapter] = useState(null);

  const changeWiewChapter = (value) => setChapter(value);

  useEffect(() => {
    if (!params.chapter) return setChapter("general");

    setChapter(params.chapter);
  }, [params.chapter]);

  return (
    <ErrorBoundary>
      <div className="settings">
        <div className="settings-content">
          {visible && (
            <div className="settings-content-filter">
              <div className="settings-content-filter-set">
                <AdditionalFilterPanel
                  active={chapter}
                  currentPage="settings"
                  changeWiewChapter={changeWiewChapter}
                />
                <Filter disabled={true} currentPage="settings" />
              </div>
            </div>
          )}

          <div className="settings-content-main customScroll">
            {chapter === "general" && <SettingsGeneral />}
            {chapter === "employees" && visible && <SettingsEmployees />}
            {chapter === "project" && visible && <SettingsProject />}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Settings;
