import React, { useState, useEffect, useId } from "react";
import { useDispatch } from "react-redux";
import "./ModalAddProject.scss";
import { asyncCreateProject, asyncGetProjects } from "../../store/reducers/projectsReducer";
import gpsIcon from "../../assets/icons/gps.svg";
import Input from "../Input/Input";
import InputDate from "../InputDate/InputDate";
import Textarea from "../Textarea/Textarea";
import { useChangeIV } from "../../hooks/useChangeIV";
import Select from "../Select/Select";
import Slider from "../Slider/Slider";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import InputSearch from "../InputSearch/InputSearch";
import Screen from "../Screen/Screen";
import { useModal } from "../../hooks/useModal";
import { useDraft } from "../../hooks/useDraft";

const ModalAddProject = (props) => {
  const { changeModalVisible } = useModal();

  const dispatch = useDispatch();
  const formId = useId();

  const [imagesList, setimagesList] = useState([]);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
    description: "",
    contractNumber: "",
    address: "",
    work_start: "",
    work_end: "",
    conclusion_contract: "",
    contract_start: "",
    contract_end: "",
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    customers: {
      options: [],
      value: [],
    },
    genContractor: {
      options: [],
      value: null,
    },

    roomType: "default",
    region: "default",
  });

  const setDataFromDraft = (data) => {
    const newIV = { ...IV };
    const newOptions = { ...options };

    for (const key in data) {
      if (newIV.hasOwnProperty(key)) {
        if (
          key === "work_start" ||
          key === "work_end" ||
          key === "conclusion_contract" ||
          key === "contract_start" ||
          key === "contract_end"
        ) {
          newIV[key] = new Date(data[key]);
        } else {
          newIV[key] = data[key];
        }
      } else if (newOptions.hasOwnProperty(key)) {
        newOptions[key] = data[key];
      }
    }

    changeIV("setAll", newIV);
    changeOptions("setAll", newOptions);
  };

  const { addDataToDraft, removeDataFromDraft } = useDraft(IV, "addProject", {
    onPush: setDataFromDraft,
    hasOptions: true,
    filterData: {
      fieldNotEqual: {
        work_start: new Date(),
        work_end: new Date(),
        conclusion_contract: new Date(),
        contract_start: new Date(),
        contract_end: new Date(),
      },
      withOutField: {
        photos: 1,
      },
    },
  });

  const validateDate = () => {
    let validate = false;
    const { work_start, work_end, contract_start, contract_end, conclusion_contract } = IV;

    if (dayjs(contract_start).diff(conclusion_contract, "day") < 0) {
      validate = "Дата начала контракта не может быть раньше даты заключения контракта";
    }

    if (dayjs(work_start).diff(contract_start, "day") < 0) {
      validate = "Дата начала работ не может быть раньше даты начала контракта";
    }

    if (dayjs(contract_end).diff(work_end, "day") < 0) {
      validate = "Дата завершения контракта не может быть раньше даты завершения работ";
    }

    if (dayjs(work_end).diff(work_start, "day") < 0) {
      validate = "Завершение работ не может быть раньше начала работ";
    }

    if (dayjs(contract_end).diff(contract_start, "day") < 0) {
      validate = "Завершение контракта не может быть раньше начала контракта";
    }
    if (imagesList.length > 5) {
      validate = "Максимальное колличество фото 5";
    }
    return validate;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validate = validateDate();
    if (validate) return toast.warning(validate);
    const checkValidateFields = [
      checkValidateIV("name", { required: true, minHeight: 3, maxHeight: 100 }),
      checkValidateIV("description", { maxHeight: 5000 }),
      checkValidateIV("address", { required: true, minHeight: 8 }),
      checkValidateIV("contractNumber", { required: true }),

      checkValidateIV("work_start", { required: true }),
      checkValidateIV("work_end", { required: true }),
      checkValidateIV("conclusion_contract", { required: true }),
      checkValidateIV("contract_start", { required: true }),
      checkValidateIV("contract_end", { required: true }),

      checkValidateOptions("roomType", { required: true }),
      checkValidateOptions("customers", { requiredArray: true }),
      checkValidateOptions("genContractor", { required: true }),
      checkValidateOptions("region", { required: true }),
    ].every((item) => item === true);
    if (checkValidateFields) {
      const formData = new FormData();

      formData.append("name", IV.name);
      formData.append("description", IV.description);
      formData.append("contractNumber", IV.contractNumber);

      formData.append("work_start", IV.work_start);
      formData.append("work_end", IV.work_end);
      formData.append("conclusion_contract", IV.conclusion_contract);
      formData.append("contract_start", IV.contract_start);
      formData.append("contract_end", IV.contract_end);
      formData.append("address", IV.address);

      formData.append("customers", JSON.stringify(options.customers.value.map((c) => c.value)));
      formData.append("genContractor", options.genContractor.value.value);
      formData.append("roomType", options.roomType.value.value);
      formData.append("region", options.region.value.value);

      for (let i = 0; i < imagesList.length; i++) {
        formData.append(`photos`, imagesList[i]);
      }

      const res = await dispatch(asyncCreateProject(formData));
      if (res.error) return;
      await dispatch(asyncGetProjects({ page: 1 }));
      changeModalVisible("addProject", false);
      removeDataFromDraft();
    }
  };

  const closeModal = () => {
    addDataToDraft(
      {
        ...IV,
        ...options,
      },
      { setAll: true },
    );

    changeModalVisible("addProject");
  };

  return (
    <ModalWrapper
      onClose={closeModal}
      title="Создать проект"
      draftModal={{
        name: "addProject",
        onPush: setDataFromDraft,
        onDelete: removeDataFromDraft,
      }}
      btns={
        <div className="modalAddProject-main-btns">
          <Button color="blue" form={formId} title="Сохранить" type="submit" />
        </div>
      }
    >
      <div className="modalAddProject">
        <form onSubmit={onSubmit} className="modalAddProject-main" id={formId}>
          <div className="modalAddProject-main-wrapper">
            <div className="modalAddProject-main-form">
              <div className="modalAddProject-main-form-grid">
                <Input
                  title="Название проекта:*"
                  name="name"
                  value={IV.name}
                  onChange={changeIV}
                  placeholder="Введите название проекта не более 100 символов"
                  validate={checkValidateIV("name")}
                />
                <Input
                  name="contractNumber"
                  value={IV.contractNumber}
                  onChange={changeIV}
                  placeholder="Введите номер контракта"
                  title="№ контракта:*"
                  validate={checkValidateIV("contractNumber")}
                />
                <InputSearch
                  forInput
                  multi
                  validate={checkValidateOptions("customers")}
                  type="company"
                  placeholder="Выберите заказчика"
                  title="Заказчик:*"
                  handleChange={(value) => changeOptions("customers", "value", value)}
                  placeholderSelect="Выберите город"
                  optionsForSearch={{ type: "customer" }}
                  value={options.customers.value}
                  listModal={true}
                />
                <InputSearch
                  forInput={true}
                  validate={checkValidateOptions("genContractor")}
                  type="company"
                  value={options.genContractor.value}
                  placeholder="Выберите генерального подрядчика"
                  title="Генеральный подрядчик:*"
                  handleChange={(value) => changeOptions("genContractor", "value", value)}
                  placeholderSelect="Выберите город"
                  optionsForSearch={{ type: "gencontractor" }}
                />

                <Select
                  title="Тип помещения:*"
                  placeholder="Выберите тип помещения"
                  value={options.roomType.value}
                  options={options.roomType.options}
                  forInput
                  handleChange={(value) => changeOptions("roomType", "value", value)}
                  validate={checkValidateOptions("roomType")}
                />
                <InputDate
                  value={IV.conclusion_contract}
                  onChange={(data) => {
                    changeIV("set", {
                      name: "conclusion_contract",
                      value: data,
                    });
                  }}
                  title="Дата заключения контракта:*"
                  validate={checkValidateIV("conclusion_contract")}
                />

                <InputDate
                  value={IV.contract_start}
                  onChange={(data) => {
                    changeIV("set", { name: "contract_start", value: data });
                  }}
                  title="Начало контракта:*"
                  validate={checkValidateIV("contract_start")}
                />
                <InputDate
                  value={IV.contract_end}
                  onChange={(data) => {
                    changeIV("set", { name: "contract_end", value: data });
                  }}
                  title="Завершение контракта:*"
                  validate={checkValidateIV("contract_end")}
                />
                <InputDate
                  value={IV.work_start}
                  onChange={(data) => {
                    changeIV("set", { name: "work_start", value: data });
                  }}
                  title="Начало работ:*"
                  validate={checkValidateIV("work_start")}
                />
                <InputDate
                  value={IV.work_end}
                  onChange={(data) => {
                    changeIV("set", { name: "work_end", value: data });
                  }}
                  title="Завершение работ:*"
                  validate={checkValidateIV("work_end")}
                />
                <div className="modalAddProject-main-form-region">
                  <InputSearch
                    forInput={true}
                    type="region"
                    name="region"
                    value={options.region.value}
                    placeholder="Введите город"
                    title="Город:*"
                    handleChange={(data) => {
                      changeOptions("region", "value", data);
                    }}
                    placeholderSelect="Выберите город"
                    validate={checkValidateOptions("region")}
                  />
                </div>
                <Input
                  placeholder="Введите адрес"
                  title="Адрес:*"
                  name="address"
                  value={IV.address}
                  onChange={changeIV}
                  validate={checkValidateIV("address")}
                >
                  <div className="input-icon">
                    <img src={gpsIcon} alt="gps" />
                  </div>
                </Input>
              </div>
              <Textarea
                title="Описание:"
                height="170px"
                name="description"
                onChange={changeIV}
                value={IV.description}
                placeholder="Введите описание проекта не более 5000 символов"
                validate={checkValidateIV("description")}
              />
            </div>
            <div className="modalAddProject-main-images">
              <Slider
                border
                title="Фотографии проекта:"
                edit
                btnVisible
                onChange={setimagesList}
                images={imagesList}
              />
            </div>
          </div>
          <Screen size={"lg"}>
            <div className="modalAddProject-main-btns">
              <Button color="blue" title="Сохранить" type="submit" form={formId} />
            </div>
          </Screen>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ModalAddProject;
