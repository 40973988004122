import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import "./CardRowCompanies.scss";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import Icons from "../../../../../../../components/Icons/Icons";
import { transformTypeCompany } from "../../../../../../../utils/transformTypeCompany";
import { countCompanyEmployess } from "../../../../../../../utils/countCompanyEmployess.js";
import { MenuDots } from "../../../../../../../ui/index.js";

function CardRowCompanies(props) {
  const { info, number, changeModalVisible, addItemSelect, check } = props;
  const { _id, name, director, region, email, phone, profiles, type } = info;

  const onEvent = (name) => {
    switch (name) {
      case "info":
        return changeModalVisible("infoCompany", true, info);
      case "edit":
        return changeModalVisible("infoCompany", true, info, true);
      case "delete":
        return changeModalVisible("deleteCompany", true, info);
    }
  };

  const listMenu = [
    {
      text: "Информация",
      onClick: () => onEvent("info"),
      name: "info",
    },
    {
      text: "Удалить",
      onClick: () => onEvent("delete"),
      name: "delete",
    },
  ];
  return (
    <div className="cardRowCompanies">
      <div className="cardRowCompanies-list">
        {/* <div className="cardRowCompanies-item number">
          <Checkbox onCheck={() => addItemSelect(_id)} check={check} />
          <span>{number}</span>
        </div> */}
        <div onClick={() => event("info")} className="cardRowCompanies-item name">
          <span title={name}>{name}</span>
        </div>
        <div className="cardRowCompanies-item directorFIO">
          <span title={getFormatFIO(director)}>{getFormatFIO(director)}</span>
        </div>
        <div className="cardRowCompanies-item region">
          <span title={region.name}>{region.name}</span>
        </div>
        <div className="cardRowCompanies-item email">
          <span title={email}>{email}</span>
        </div>
        <div className="cardRowCompanies-item phone">
          <span title={phone}>{phone}</span>
        </div>
        <div className="cardRowCompanies-item quentityParticipants">
          <span>{countCompanyEmployess(profiles) || 1}</span>
        </div>
        <div className="cardRowCompanies-item type">
          <span title={transformTypeCompany(type)}>{transformTypeCompany(type)}</span>
        </div>
        <div className="cardRowCompanies-item btns">
          <MenuDots list={listMenu} />
          {/* <Icons
            onClick={() => event("info")}
            type={"infoItem"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
          />
          {/* <Icons type={"edit"} size={"md"} cursor onClick={() => event("edit")}/> */}
          {/* <Icons
            onClick={() => event("delete")}
            type={"remove"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
          />  */}
        </div>
      </div>
    </div>
  );
}

export default CardRowCompanies;
