import React, { useState } from "react";
import "./SelectMulti.scss";
import SelectLib, { components, InputProps } from "react-select";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

const Input = (props) => {
  // console.log(!!props.selectProps.value.length);

  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <div
      className={`react-select-multi-listModal__input-container ${
        props.selectProps.value !== null && !!props.selectProps.value.length
          ? "react-select-multi-listModal__input-container-border"
          : ""
      }`}
    >
      <components.Input {...props} />
    </div>
  );
};

const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <div className="react-select-multi-listModal__multi-value__label" title={props.data.label}>
        {props.data.label}
      </div>
    </components.MultiValueLabel>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icons
        type={"crossBlack"}
        size={"xs"}
        color={"grey"}
        title={{
          visible: true,
          text: "Удалить из списка",
        }}
      />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (props) => {
  const { menuIsOpen } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <Screen size={"lg"}>
        <Icons
          type={"arrowDown"}
          size="md"
          cursor
          className={`dropdownIndicator ${menuIsOpen ? "dropdownIndicator-open" : ""}`}
          title={{
            visible: true,
            text: menuIsOpen ? "Свернуть" : "Развернуть",
          }}
        />
      </Screen>
      <Screen size={"lg"} down>
        <Icons
          type={"arrowDown"}
          size="md"
          color={"grey"}
          className={`dropdownIndicator ${menuIsOpen ? "dropdownIndicator-open" : ""}`}
        />
      </Screen>
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icons
        type={"crossBlack"}
        size="xs"
        className={`selectMulti-close-icon`}
        // color={"grey"}
        cursor
        title={{ visible: true, text: "Очистить" }}
      />
    </components.ClearIndicator>
  );
};

function SelectMulti(props) {
  const {
    value,
    handleChange,
    options,
    placeholder,
    title,
    menuPlacement,
    forInput,
    isSearchable,
    titleForInput,
    styleTitle,
    className,
    validate,
    onInputChange,
    isDisabled,
    noOptionsText,
    listModal, // отоброжение в камстомном стиле
    maxMenuHeight,
    styles,
    isClearable = true,
  } = props;
  const [optionsText, setOptionsText] = useState("Введите текст, чтобы начать поиск");
  const onInputChangeHandler = (value) => {
    getPlacholderOptions(value, options);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const getPlacholderOptions = (selectValue, selectOptions) => {
    if (selectValue?.length > 0) {
      // if (selectOptions?.length === 0) {
      return setOptionsText("Ничего не найдено");
      // }
    } else {
      return setOptionsText("Введите текст, чтобы начать поиск");
    }
  };
  return (
    <div
      className={`selectMulti 
      ${forInput ? "forInput" : ""} 
      ${titleForInput ? "titleForInput" : ""} 
      ${className ? className : ""} 
      ${validate ? "validate" : ""} ${isDisabled ? "isDisabled" : ""}`}
    >
      {title && (
        <span style={styleTitle} className="select-title">
          {title}
        </span>
      )}
      {listModal ? (
        <>
          <SelectLib
            className="react-select-multi-listModal"
            classNamePrefix="react-select-multi-listModal"
            isMulti
            isSearchable={isSearchable}
            menuPlacement={menuPlacement || "top"}
            onInputChange={(value) => onInputChangeHandler(value)}
            components={{
              DropdownIndicator,
              ClearIndicator,
              MultiValueRemove,
              Input,
              MultiValueLabel,
            }}
            value={value}
            onChange={handleChange}
            options={options}
            placeholder={<div className="select-placholder">{placeholder}</div>}
            isDisabled={isDisabled}
            noOptionsMessage={() => (noOptionsText ? noOptionsText : optionsText)}
            maxMenuHeight={maxMenuHeight || 150}
            isClearable={false}
            styles={styles}
          />
        </>
      ) : (
        <>
          <SelectLib
            className="react-select-multi"
            classNamePrefix="react-select-multi"
            isMulti
            isSearchable={isSearchable}
            menuPlacement={menuPlacement}
            onInputChange={(value) => onInputChangeHandler(value)}
            components={{
              DropdownIndicator,
              ClearIndicator,
              MultiValueRemove,
              MultiValueLabel,
              Input,
            }}
            value={value}
            onChange={handleChange}
            options={options}
            placeholder={<div className="select-placholder">{placeholder}</div>}
            isDisabled={isDisabled}
            noOptionsMessage={() => (noOptionsText ? noOptionsText : optionsText)}
            maxMenuHeight={maxMenuHeight || 150}
            isClearable={false}
            styles={styles}
          />
        </>
      )}
      {!!validate && (
        <div className="selectMulti-validate">
          <span>{validate}</span>
        </div>
      )}
    </div>
  );
}

export default SelectMulti;
