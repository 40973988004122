import React from "react";
import "./ActiveHeaderDocumentation.scss";
import Checkbox from "../Checkbox/Checkbox";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import axios from "../../axios/axios";
import { getBaseUrl } from "../../utils/getBaseUrl";
import ActiveHeaderWrapper from "../ActiveHeaderWrapper/ActiveHeaderWrapper";
import { useSearch } from "../../hooks/useSearch";
const ActiveHeaderDocumentation = ({ check, onCheck, onDelete, info, visible }) => {
  const { changeModalVisible } = useModal();
  const { name, type, path_to_doc } = info;

  const { setQueryParam } = useSearch();

  const getFolder = (folder) => {
    setQueryParam({ father: folder._id });
  };

  const downloadFile = (e) => {
    e.preventDefault();
    axios
      .get(getBaseUrl() + "/folders" + path_to_doc, { responseType: "blob" })
      .then((response) => {
        // console.log(response);
        const link = document.createElement("a");
        const blob = new Blob([response.data]);
        let urll = window.URL.createObjectURL(blob);
        link.href = urll;
        link.download = info.name;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urll);
          document.body.removeChild(link);
        }, 0);
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  };

  const onEvent = (name) => {
    if (type === "folder") {
      if (name === "info") return changeModalVisible("infoFolder", true, info);
      if (name === "open") return getFolder(info);
      if (name === "edit") return changeModalVisible("infoFolder", true, info, true);
      if (name === "delete") return changeModalVisible("deleteFolder", true, info);
      if (name === "add") return changeModalVisible("addDocument", true, { folder: info._id });
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
    } else {
      if (name === "info") return changeModalVisible("viewDoc", true, info, false, true);
      if (name === "edit") return changeModalVisible("editFile", true, info, true);
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
      if (name === "delete") return changeModalVisible("deleteDocument", true, info);
    }
  };

  return (
    <ActiveHeaderWrapper check={check} onCheck={onCheck} onDelete={onDelete}>
      <div className="activeHeaderDocumentation">
        {visible && (
          <>
            {" "}
            {type === "folder" ? (
              <div
                className="activeHeaderDocumentation-item download"
                onClick={() => onEvent("open")}
              >
                <Icons
                  type={"folderClear"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "Открыть папку" }}
                />
                <span className="activeHeaderDocumentation-item-text">Открыть папку</span>
              </div>
            ) : (
              <div
                className="activeHeaderDocumentation-item download"
                onClick={() => onEvent("info")}
              >
                <Icons
                  type={"file"}
                  size={"md"}
                  cursor
                  title={{ visible: true, text: "Открыть файл" }}
                />
                <span className="activeHeaderDocumentation-item-text">Открыть файл</span>
              </div>
            )}
            {type === "file" && (
              <div
                className="activeHeaderDocumentation-item download"
                onClick={(e) => downloadFile(e)}
              >
                <Icons type={"download"} size={"md"} cursor title={{ visible: true, text: "" }} />
                <span className="activeHeaderDocumentation-item-text">Скачать</span>
              </div>
            )}
            <div className="activeHeaderDocumentation-item edit" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
              <span className="activeHeaderDocumentation-item-text">Редактировать</span>
            </div>
            <div className="activeHeaderDocumentation-item info" onClick={() => onEvent("info")}>
              <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
              <span className="activeHeaderDocumentation-item-text">Информация</span>
            </div>
            <div className="activeHeaderDocumentation-item copy" onClick={() => onEvent("copy")}>
              <Icons type={"copy"} size={"md"} cursor title={{ visible: true, text: "" }} />
              <span className="activeHeaderDocumentation-item-text">Копировать</span>
            </div>
            <div
              className="activeHeaderDocumentation-item delete"
              onClick={() => onEvent("addToFolder")}
            >
              <Icons type={"addToFolder"} size={"md"} cursor title={{ visible: true, text: "" }} />
              <span className="activeHeaderDocumentation-item-text">Переместить</span>
            </div>
          </>
        )}
      </div>
    </ActiveHeaderWrapper>
  );
};

export default ActiveHeaderDocumentation;
