import React from "react";
import Icons from "../../../../../Icons/Icons";
import Screen from "../../../../../Screen/Screen";
import { useModal } from "../../../../../../hooks/useModal";
import "./ProjectWorkScheduleColListHeader.scss";

const ProjectWorkScheduleColListHeader = ({ stage, edit }) => {
  const { changeModalVisible } = useModal();

  const filterExecuters = (stage) => {
    const works = stage.works || [];
    if (!works.length) return "";
    const arr = [];

    for (let i = 0; works.length > i; i++) {
      if (!!works[i].executor) {
        arr.push(works[i].executor.name);
      }
    }
    const filterArr = arr.filter((element, index) => {
      return arr.indexOf(element) === index;
    });
    return filterArr.join(", ");
  };
  // filterExecuters(stage);
  return (
    <div className="projectWorkScheduleColListHeader">
      <div className="projectWorkScheduleColListHeader-title">
        <span className="title">
          {" "}
          Этап № {stage.number}: {stage.name}.{" "}
          {filterExecuters(stage).length ? `Подрядчики: ${filterExecuters(stage)}` : ""}
        </span>

        <div className="projectWorkScheduleColListHeader-title-btns">
          <Screen size={"lg"}>
            <Icons
              type={"infoItem"}
              size={"md"}
              cursor
              onClick={() => changeModalVisible("infoStage", true, stage)}
              title={{ visible: true, text: "" }}
            />
            {edit && (
              <>
                {" "}
                <Icons
                  type={"edit"}
                  size={"md"}
                  cursor
                  onClick={() => changeModalVisible("infoStage", true, stage, true)}
                  title={{ visible: true, text: "" }}
                />
                <Icons
                  type={"remove"}
                  size={"md"}
                  cursor
                  onClick={() => changeModalVisible("deleteStage", true, stage)}
                  title={{ visible: true, text: "" }}
                />
              </>
            )}
          </Screen>
          <Screen size={"lg"} down>
            <Icons
              type={"menu"}
              size={"m-md"}
              cursor
              onClick={() => changeModalVisible("menu", true, stage)}
            />
          </Screen>
        </div>
      </div>
    </div>
  );
};

export default ProjectWorkScheduleColListHeader;
