import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../utils/getFullPath";
import DocItem from "../../../DocItem/DocItem";
import "./Main.scss";
import forwardSelect from "../../../../assets/icons/forward-select.svg";
import Message from "./components/Message/Message";

const Main = (props) => {
  const { myId, selectItems, addItemSelect, messages, onReadMessage } = props;

  let count = 0;
  const checkToDay = (date) => {
    if (count === 0) {
      if (dayjs(new Date()).diff(date, "h") < 12) {
        count++;
        return true;
      }
    }
  };

  const chatRef = useRef(null);

  const isRead = (msg) => {
    const me = msg.toUserList.find((u) => u.user === myId);
    return msg.creatorUser?._id === myId || me?.isRead;
  };

  const scrollTo = () => {
    const msgNoreadList = document.querySelectorAll(".msg_noread");

    const firstMsgNoread = msgNoreadList[0];

    if (firstMsgNoread) return firstMsgNoread.scrollIntoView(false);

    chatRef.current.scrollTo({ top: chatRef.current.scrollHeight });
  };

  useEffect(() => {
    scrollTo();
    const noreadMessageList = [...document.querySelectorAll(".msg_noread")];

    let observer = new IntersectionObserver((entries, obs) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onReadMessage(entry.target.getAttribute("id"));
          entry.target.classList.remove("msg_noread");
          entry.target.classList.add("msg_read");
          obs.unobserve(entry.target);
        }
      });
    });

    noreadMessageList.forEach((el) => {
      observer.observe(el);
    });
  }, [messages]);

  const visibleAvatar = (elem, index) => {
    const prevMessage = messages[index - 1];
    if (
      prevMessage?.creatorUser?._id === elem?.creatorUser?._id &&
      !prevMessage?.event
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="windowChatMain">
      <div ref={chatRef} className="windowChatMain-list customScroll">
        {messages &&
          messages.map((msg, index) => (
            <>
              {msg.event ? (
                <div
                  id={msg._id}
                  key={msg._id}
                  className={`windowChatMain-list-event msg_${
                    isRead(msg) ? "read" : "noread"
                  }`}
                >
                  <span>
                    {getFormatFIO(msg?.creatorUser)} {msg.text}
                  </span>
                </div>
              ) : (
                <div
                  id={msg._id}
                  key={msg._id}
                  className={`msg_${isRead(msg) ? "read" : "noread"}`}
                >
                  {checkToDay(msg.createdAt) && (
                    <div className="windowChatMain-list-title">
                      <span>Сегодня</span>
                    </div>
                  )}
                  <Message
                    visibleAvatar={visibleAvatar}
                    info={msg}
                    addItemSelect={addItemSelect}
                    selectItems={selectItems}
                    myId={myId}
                    index={index}
                  />
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
};
export default Main;
