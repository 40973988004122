import React, { useState, useRef, useEffect } from "react";
import "./ReferenceItem.scss";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import Screen from "../Screen/Screen";

const ReferenceItem = ({ defaultActiveTab, index, disabled, animated, info, edit = false }) => {
  const { changeModalVisible } = useModal();
  const [height, setHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };
  const panelRef = useRef(null);
  const contentRef = useRef(null);

  const { title, description } = info;

  useEffect(() => {
    const height = contentRef.current.lastChild.clientHeight;
    setHeight(height);
  }, [isActive]);

  const activateTab = (tab) => {
    setActiveTab(activeTab === tab ? -1 : tab);
  };
  const onEvent = (name) => {
    if (name === "edit") return changeModalVisible("editReference", true, info, true);
    if (name === "remove") return changeModalVisible("deleteReference", true, info);
    if (name === "menu") return changeModalVisible("menu", true, info, false, true);
  };
  return (
    <div className="referenceItem">
      <div
        className="referenceItem__panel referenceItem-panel"
        role="tabpanel"
        aria-expanded={isActive}
        ref={panelRef}
      >
        <div className="referenceItem-panel-header">
          <button
            className={`referenceItem-panel__label referenceItem-panel-label ${
              isActive && "referenceItem-panel__label-active"
            }`}
            role="tab"
            onClick={() => activateTab(index)}
            disabled={disabled}
          >
            {isActive ? (
              <Icons
                type={"circleMinus"}
                size={"md"}
                cursor
                className="referenceItem-panel-label__icon referenceItem-panel-label__icon-minus"
              />
            ) : (
              <Icons
                type={"сirclePlus"}
                size={"md"}
                cursor
                className="referenceItem-panel-label__icon referenceItem-panel-label__icon-plus"
              />
            )}

            <span className="referenceItem-panel-label__text">{title}</span>
          </button>
          {edit && (
            <div className="referenceItem-panel-header-btns">
              <Screen size={"lg"}>
                <button
                  className="referenceItem-panel-header-btns-btn"
                  onClick={() => onEvent("edit")}
                >
                  <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
                </button>
                <button
                  onClick={() => onEvent("remove")}
                  className="referenceItem-panel-header-btns-btn"
                >
                  <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
                </button>
              </Screen>
              <Screen size={"lg"} down>
                {" "}
                <button
                  onClick={() => onEvent("menu")}
                  className="referenceItem-panel-header-btns-btn"
                >
                  <Icons type={"menu"} size={"md"} cursor />
                </button>
              </Screen>
            </div>
          )}
        </div>
        <div
          ref={contentRef}
          className="referenceItem-panel__inner"
          aria-hidden={!isActive}
          style={animated && innerStyle}
        >
          <div className="referenceItem-panel__content referenceItem-panel-content">
            <p className="referenceItem-panel-content__text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceItem;
