export const getVisibleFilterPage = (filterName, pageName) => {
  const visiblePages = {
    analytics: ["region", "period", "project", "employee", "company", "status"],
    projects: ["region", "period", "roomType", "company"],
    "project:participants": ["position"],
    "project:meetingMinutes": ["period"],
    "project:workSchedule": ["period"],
    tasks: [
      "author",
      "executor",
      "projects",
      "start",
      "end",
      "isImportant",
      "status",
      "overdue",
      "company",
    ],
    tasksKanban: [
      "author",
      "executor",
      "projects",
      "start",
      "end",
      "isImportant",
      "company",
      "overdue",
    ],
    deliveryWork: ["section", "project", "period", "status", "author"],

    "employees:users": ["region", "position", "company", "role"],
    "employees:companies": ["director", "region", "quantity", "typeCompanies"],
    "employees:groups": ["company", "director", "quantity"],
    "employees:structures": [],

    "company:tasks": [
      "author",
      "executor",
      "projects",
      "start",
      "end",
      "isImportant",
      "status",
      "overdue",
    ],
    "company:projects": ["region", "period", "roomType"],
    "company:users": ["region", "position", "role"],
  };
  return visiblePages[pageName]?.includes(filterName);
};
