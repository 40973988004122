import dayjs from "dayjs";
import React from "react";
import { getFormatDate } from "../../../../utils/getFormatDate";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import "./ModalStageInfo.scss";
import HistoryChanges from "../../../HistoryChanges/HistoryChanges";
import ListWorks from "../../../ListWorks/ListWorks";

function ModalStageInfo(props) {
  const { info, changeModalVisible } = props;
  const { name, desc, startDate, endDate, works, historyChanges, start, end } =
    info;

  return (
    <div className="modalStageInfo">
      <div className="modalStageInfo-item">
        <span className="modalStageInfo-title">Название этапа:</span>
        <span className="modalStageInfo-text">{name || ""}</span>
      </div>
      <ul className="modalStageInfo-list">
        <li className="modalStageInfo-item">
          <span className="modalStageInfo-title">Дата начала:</span>
          <span className="modalStageInfo-text">
            {getFormatDate(start) || ""}
          </span>
        </li>
        <li className="modalStageInfo-item">
          <span className="modalStageInfo-title">Дата завершения:</span>
          <span className="modalStageInfo-text">
            {getFormatDate(end) || ""}
          </span>
        </li>
        <li className="modalStageInfo-item">
          <span className="modalStageInfo-title">Кол-во дней:</span>
          <span className="modalStageInfo-text">
            {dayjs(end).diff(start, "day") || ""}
          </span>
        </li>
        <li className="modalStageInfo-item">
          <span className="modalStageInfo-title">Кол-во дней просрочки:</span>
          <span className="modalStageInfo-text">
            {(dayjs().diff(end, "day") < 0 ? 0 : dayjs().diff(end, "day")) ||
              "0"}
          </span>
        </li>
      </ul>
      {desc && (
        <div className="modalStageInfo-item">
          <span className="modalStageInfo-title">Причина переноса срока:</span>
          <span className="modalStageInfo-text">{desc || "-"}</span>
        </div>
      )}
      {works?.length && (
        <ListWorks
          works={works}
          changeModalVisible={changeModalVisible}
          title={"Работы в этапе:"}
        />
      )}
      {historyChanges?.length && (
        <HistoryChanges historyChanges={historyChanges} />
      )}
    </div>
  );
}

export default ModalStageInfo;
