import React, { useEffect, useState } from "react";
import "./Company.scss";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { asyncGetCompany } from "../../../store/reducers/companiesReducer";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import { useSelector } from "react-redux";
import EmptySection from "../../../components/EmptySection/EmptySection";
import Filter from "../../../components/Filter/Filter";
import { useModal } from "../../../hooks/useModal";

const Company = () => {
  const dispatch = useDispatch();
  const { getInfo, getVisible, changeModalVisible } = useModal();
  const [filterDisbled, setFilterDisabled] = useState(true);

  const { company } = useSelector((state) => state.companies);
  const { params, goToPage, setQueryParam, queries } = useSearch();

  const location = useLocation();
  const path = location.pathname;

  const { companyId } = useParams();

  const [chapter, setChapter] = useState();
  const [activeFilter, setActiveFilter] = useState("all");
  const [loading, setLoading] = useState(false);

  const changeWiewTasks = (value) => setQueryParam({ tab: value });

  const changeWiewChapter = () => {
    if (path.includes("users")) {
      setChapter("users");
    } else if (path.includes("tasks")) {
      setChapter("tasks");
    } else if (path.includes("projects")) {
      setChapter("projects");
    } else if (path.includes("projects")) {
      setChapter("projects");
    } else {
      setChapter();
    }
  };

  const getCompany = async () => {
    if (!companyId) return;
    setLoading(true);
    const res = await dispatch(asyncGetCompany(companyId));
    setLoading(false);
    if (res.error) return;
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    changeWiewChapter();
  }, [location]);

  useEffect(() => {
    if (queries.tab) setActiveFilter(queries.tab);
    else setActiveFilter("all");
  }, [queries.tab]);

  // const filterDisbled = chapter === "tasks" || chapter === "projects" ? false : true;

  useEffect(() => {
    if (chapter === "tasks") {
      setFilterDisabled(false);
    } else if (chapter === "projects") {
      setFilterDisabled(false);
    } else if (chapter === "users") {
      setFilterDisabled(false);
    } else {
      setFilterDisabled(true);
    }
  }, [chapter]);

  return (
    <ErrorBoundary>
      <div className="company">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="company-navigate">
          <LeftInfo icon onClick={() => goToPage("employees:companies")} />
          <Filter currentPage={`company:${chapter}`} disabled={filterDisbled} />
        </div>

        <div className="company-nav">
          <ul className="company-nav__list">
            <NavLink
              to={"baseInfo"}
              className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""}`}
            >
              <span className="company-nav__span">Основная информация</span>
            </NavLink>
            <NavLink
              to={"employees"}
              className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""}`}
            >
              <span className="company-nav__span">Сотрудники</span>
            </NavLink>

            <NavLink
              to={"projects/1"}
              className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""}`}
            >
              <span className="company-nav__span">Проекты</span>
            </NavLink>
            <NavLink
              to={"tasks/1"}
              className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""}`}
            >
              <span className="company-nav__span">Задачи</span>
            </NavLink>
          </ul>
          {path.includes("employees") && (
            <ul className="company-nav__list">
              <NavLink
                to={"employees/users/1"}
                className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""}`}
              >
                <span className="company-nav__span">Сотрудники</span>
              </NavLink>
              <NavLink
                to={"employees/profiles"}
                className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""}`}
              >
                <span className="company-nav__span">Должности</span>
              </NavLink>
              <NavLink
                to={"employees/responsibles"}
                className={`company-nav__item company-nav-item ${({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""}`}
              >
                <span className="company-nav__span">Ответственные по проекту</span>
              </NavLink>
            </ul>
          )}
          {chapter === "tasks" && (
            <ul className="company-nav__list">
              <li
                className={`company-nav__item company-nav-item ${
                  activeFilter === "all" && "active"
                }`}
                onClick={() => changeWiewTasks("all")}
              >
                <span className="company-nav__span">Все</span>
              </li>
              <li
                className={`company-nav__item company-nav-item ${
                  activeFilter === "outgoing" && "active"
                }`}
                onClick={() => changeWiewTasks("outgoing")}
              >
                <span className="company-nav__span">Исходящие</span>
              </li>
              <li
                className={`company-nav__item company-nav-item ${
                  activeFilter === "incoming" && "active"
                }`}
                onClick={() => changeWiewTasks("incoming")}
              >
                <span className="company-nav__span">Входящие</span>
              </li>
            </ul>
          )}
        </div>
        <WrapperLoader loading={loading}>
          <div className="company-content customScroll">
            {company ? <Outlet /> : <EmptySection text={"Данных о компании нет"} />}
          </div>
        </WrapperLoader>
      </div>
    </ErrorBoundary>
  );
};

export default Company;
