import React, { useState, useRef } from "react";
import "./EmployeesListGridCard.scss";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import Icons from "../../../../../../../components/Icons/Icons";
import { useModal } from "../../../../../../../hooks/useModal";
import { getFullPath } from "../../../../../../../utils/getFullPath";
import { transformRole } from "../../../../../../../utils/transformRole";
import { useOnClickOutside } from "../../../../../../../hooks/useOnClickOutside";
import CardGridHeader from "../../../../../../../components/CardGridHeader/CardGridHeader";
import { profilesParsing } from "../../../../../../../utils/profilesParsing.js";
import { useSearch } from "../../../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../../../hooks/useHasPermissions";

const EmployeesListGridCard = ({ info, onInfo }) => {
  const { total_tasks, avatar, role, phone, email, profiles, isLogin } = info;
  const { edit, remove } = useHasPermissions("employeesCard", info);
  const { changeModalVisible } = useModal();

  const { goToPage } = useSearch();

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const handleModal = (event) => {
    event.preventDefault();
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  const onEvent = (name) => {
    if (name === "info") {
      return onInfo ? onInfo(info) : goToPage("userInfo", { userId: info._id });
    }

    if (name === "edit") return changeModalVisible("editEmployee", true, info);

    if (name === "remove") return changeModalVisible("deleteEmployee", true, info);
  };
  return (
    <li className={`employeesListGridCard  ${isLogin ? "" : "not-login"}`}>
      <div className="employeesListGridCard__header employeesListGridCard-header">
        <div className="employeesListGridCard-header__content">
          <CardGridHeader
            title={"Задач:"}
            text={total_tasks || 0}
            onClick={(event) => handleModal(event)}
          />
          <img
            className="employeesListGridCard-header__avatar"
            src={getFullPath(avatar, "avatar")}
            alt={getFormatFIO(info)}
            crossOrigin="anonymous"
          />
        </div>
        <span className="employeesListGridCard-header__name">{getFormatFIO(info)}</span>
      </div>
      <ul className="employeesListGridCard__info employeesListGridCard-info">
        <li className="employeesListGridCard-info__item">
          <span className="employeesListGridCard-info__title">Компания:</span>
          <span
            className="employeesListGridCard-info__text"
            title={profilesParsing("company", profiles) || "-"}
          >
            {profilesParsing("company", profiles) || "-"}
          </span>
        </li>
        <li className="employeesListGridCard-info__item">
          <span className="employeesListGridCard-info__title">Отдел:</span>
          <span
            className="employeesListGridCard-info__text"
            title={profilesParsing("departments", profiles) || "-"}
          >
            {profilesParsing("departments", profiles) || "-"}
          </span>
        </li>
        <li className="employeesListGridCard-info__item">
          <span className="employeesListGridCard-info__title">Должность:</span>
          <span
            className="employeesListGridCard-info__text"
            title={profilesParsing("position", profiles) || "-"}
          >
            {profilesParsing("position", profiles) || "-"}
          </span>
        </li>

        <li className="employeesListGridCard-info__item">
          <span className="employeesListGridCard-info__title">Роль:</span>
          <span className="employeesListGridCard-info__text">{transformRole(role)}</span>
        </li>
      </ul>
      <ul className="employeesListGridCard__footer employeesListGridCard-footer">
        <li className="employeesListGridCard-footer__item">
          <Icons
            type={"phone"}
            size={"md"}
            className="employeesListGridCard-footer__img"
            color={"white"}
          />
          <a className="employeesListGridCard-footer__text" href={`tel: ${phone}`}>
            {phone}
          </a>
        </li>
        <li className="employeesListGridCard-footer__item">
          <Icons
            type={"mail"}
            size={"md"}
            className="employeesListGridCard-footer__img"
            color={"white"}
          />
          <a className="employeesListGridCard-footer__text" href={`mailto:${email}`}>
            {email}
          </a>
        </li>
      </ul>
      {menuVisible && (
        <div
          className="employeesListGridCard__menu-window employeesListGridCard-menu-window"
          ref={ref}
        >
          <span onClick={() => onEvent("info")}>Информация о сотруднике</span>
          {edit && <span onClick={() => onEvent("edit")}>Редактировать сотрудника</span>}
          {remove && <span onClick={() => onEvent("remove")}>Удалить сотрудника</span>}
        </div>
      )}
    </li>
  );
};
export default EmployeesListGridCard;
