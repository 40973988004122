import React, { useState } from "react";
import "./SearchModal.scss";
import Icons from "../Icons/Icons";

const SearchModal = ({ onChange, onSubmit, placeholder = null }) => {
  const [IV, setIV] = useState("");

  const clearInput = () => {
    setIV("");
    // onSubmit("");
    onChange("");
  };

  //   const onSearchSubmit = (e) => {
  //     e.preventDefault();
  //     onSubmit && onSubmit(IV);
  //   };
  return (
    <div className="searchModal">
      <div
        onClick={() => clearInput()}
        className={`searchModal-icon-close ${IV.length > 0 ? "searchModal-icon-close-active" : ""}`}
      >
        <Icons type={"crossBlackIcon"} size={"xs"} cursor />
      </div>

      <input
        value={IV}
        onChange={(e) => {
          setIV(e.target.value);
          onChange && onChange(e.target.value);
        }}
        type="text"
        maxLength={30}
        placeholder={placeholder || "Найти"}
      />
    </div>
  );
};

export default SearchModal;
