import React, { useEffect } from "react";
import "./Select.scss";
import SelectLib, { components } from "react-select";
import { useState } from "react";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

const DropdownIndicator = (props) => {
  const { menuIsOpen } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <Screen size={"lg"}>
        <Icons
          type={"arrowDown"}
          size="md"
          cursor
          className={`dropdownIndicator ${menuIsOpen ? "dropdownIndicator-open" : ""}`}
          title={{
            visible: true,
            text: menuIsOpen ? "Свернуть" : "Развернуть",
          }}
        />
      </Screen>
      <Screen size={"lg"} down>
        <Icons
          type={"arrowDown"}
          size="md"
          color={"grey"}
          className={`dropdownIndicator ${menuIsOpen ? "dropdownIndicator-open" : ""}`}
        />
      </Screen>
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icons
        type={"crossBlack"}
        size="xs"
        className={`react-select-close-icon`}
        color={"grey"}
        title={{
          visible: true,
          text: "Очистить",
        }}
      />
    </components.ClearIndicator>
  );
};

function Select(props) {
  const {
    value,
    handleChange,
    options,
    placeholder,
    title,
    menuPlacement,
    forInput,
    isSearchable,
    titleForInput,
    styleTitle,
    style,
    maxMenuHeight,
    className,
    onInputChange,
    validate,
    noOptionsText,
    isDisabled,
    isClearable = true,
    styles,
  } = props;

  const [selectValue, setSelectValue] = useState(value);
  const [selectOptions, setSelectOptions] = useState(options);
  const [optionsText, setOptionsText] = useState("Введите текст, чтобы начать поиск");
  useEffect(() => {
    if (value || value === null) return setSelectValue(value);
  }, [value]);

  const checkRequaerd = (title) => {
    const arr = title.split("");
    if (arr.at(-1) === "*") {
      arr.splice(arr.length - 1);
      return (
        <div style={styleTitle} className="select-title">
          <span>{arr}</span>
          <span className="select-title-red"> *</span>
        </div>
      );
    } else {
      return (
        <span style={styleTitle} className="select-title">
          {title}
        </span>
      );
    }
  };

  const onInputChangeHandler = (value) => {
    getPlacholderOptions(value, options);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  useEffect(() => {
    if (options && Array.isArray(options)) return setSelectOptions(options);
  }, [options]);

  const getPlacholderOptions = (selectValue, selectOptions) => {
    if (selectValue?.length > 0) {
      if (selectOptions?.length === 0) {
        return setOptionsText("Ничего не найдено");
      }
    } else {
      return setOptionsText("Введите текст, чтобы начать поиск");
    }
  };

  return (
    <div
      style={{ ...style }}
      className={`select 
      ${className ? className : ""} 
      ${forInput ? "forInput" : ""} 
      ${validate ? "validate" : ""}
      ${titleForInput ? "titleForInput" : ""} ${isDisabled ? "isDisabled" : ""}`}
    >
      {title &&
        // <span style={styleTitle} className="select-title">
        //   {title}
        // </span>
        checkRequaerd(title)}
      <SelectLib
        className="react-select"
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        // clearValue={() => handleChange(options, "value", null)}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
        isClearable={isClearable}
        value={selectValue}
        onChange={handleChange}
        onInputChange={(value) => onInputChangeHandler(value)}
        options={selectOptions}
        placeholder={<div className="select-placholder">{placeholder}</div>}
        maxMenuHeight={maxMenuHeight || 150}
        noOptionsMessage={() => (noOptionsText ? noOptionsText : optionsText)}
        styles={styles}
      />
      {!!validate && (
        <div className="select-validate">
          <span>{validate}</span>
        </div>
      )}
    </div>
  );
}

export default Select;
