import React, { useRef, useState, useEffect } from "react";
import "./CanvasPlan.scss";
import CanvasDraw from "../reactCanvasDraw/src";
import { colorsMatchStatus } from "../../../../../../../utils/layer/changeColorCoordinates";
import Button from "../../../../../../../components/Button/Button";

function CanvasPlan(props) {
  let {
    width,
    height,
    lines,
    onLoadCanvas,
    formAdding,
    visiblePoints,
    onChangelines,
    changeCurrentTask,
    imgSrc, // ссылка на картинку
    colorPinByStatus, //  atWork | rejected | overdue | completed | assigned | onVerification

    mode = "watch", // watch | pin | draw
    isPin = false,
    filterPin,
    onClickCanvas,
    buttonZoom,
    zoomExtents,
  } = props;

  const canvasRef = useRef(0);

  const [canvasSize] = useState({
    canvasWidth: 1070,
    canvasHeight: 756,
  });

  const getColorPin = (name) => {
    const obj = {
      atWork: "blue",
      rejected: "grey",
      overdue: "red",
      completed: "green",
      assigned: "#7f1cff",
      onVerification: "yellow",
      draw: "#f74912",
    };

    if (!name) return obj["draw"];
    return obj[name];
  };

  const getInfo = (e) => {
    const {
      nativeEvent: { layerX, layerY },
    } = e;
    changeCurrentTask(layerX, layerY, filterPin, e);
  };

  const changeScale = (eventName) => {
    if (!canvasRef) return;

    if (eventName === "clear") {
      canvasRef.current.loadSaveData(lines);
    } else {
      const event = new WheelEvent("wheel", {
        deltaY: eventName === "zoom" ? 3 : -3,
        clientX: 1070,
        clientY: 660,
      });

      canvasRef.current.canvas.interface.dispatchEvent(event);
    }
  };

  const canvasProps = {
    onClickCanvas: (e) => {
      onClickCanvas && onClickCanvas(e);
      getInfo(e);
    },
    onChange: (e) => {
      onChangelines(canvasRef.current.getSaveData(), e?.lazy.pointer, e);
    },
    mode,
    isPin,
    colorPinByStatus,

    // brushRadius: drawingChoose === "pin" ? 30 : 3,

    ref: canvasRef,
    style: { position: "absolute", top: "0px", background: "none" },
    canvasWidth: width || canvasSize.canvasWidth,
    canvasHeight: height || canvasSize.canvasHeight,
    brushSize: 1,
    brushRadius: 2,
    enablePanAndZoom: true,
    imgSrc: imgSrc,
    loadTimeOffset: 0,
    brushColor: getColorPin(colorPinByStatus),
    filterPin: filterPin,
    colorsMatchStatus: colorsMatchStatus,
    immediateLoading: true,
    zoomExtents: zoomExtents || { min: 0.33, max: 3 },
    // filterPin: colorPinByStatus,
    // hideInterface: drawingChoose === "pin" ? true : false, // ? для чего?
    // hideInterface: true,
    // brushColor: formAdding ? getColorPin(filterPin) : getColorPin(),
  };

  // filterPin = visiblePoints ? filterPin : "watch";
  if (!formAdding || !visiblePoints) {
    if (lines) {
      canvasProps["saveData"] = lines;
    }
  }

  useEffect(() => {
    onChangelines(canvasRef.current.getSaveData(), canvasRef.current.lazy.pointer);
  }, []);

  useEffect(() => {
    if (!canvasRef) return;

    onLoadCanvas && onLoadCanvas(canvasRef.current);
  }, [canvasRef]);

  useEffect(() => {
    if (!canvasRef) return;

    if (lines) canvasRef.current.loadSaveData(lines);
  }, [filterPin]);

  return (
    <div className="canvasPlanWrapper">
      <div
        className="canvasPlanWrapper-draw"
        style={{
          width: `${width || canvasSize.canvasWidth}px`,
          height: `${height || canvasSize.canvasHeight}px`,
        }}
      >
        {buttonZoom && (
          <div className="canvasPlanWrapper-btns">
            <div className="canvasPlanWrapper-btns-scale">
              <Button
                color="blue"
                icon={{ only: true, type: "magnifierPlus" }}
                onClick={() => changeScale("zoom")}
              />
            </div>
            <div className="canvasPlanWrapper-btns-scale">
              <Button
                color="blue"
                icon={{ only: true, type: "magnifierMinus" }}
                onClick={changeScale}
              />
            </div>
            <div className="canvasPlanWrapper-btns-scale clear">
              <Button
                color="blue"
                icon={{ only: true, type: "closeWhite" }}
                onClick={() => changeScale("clear")}
              />
            </div>
          </div>
        )}
        <CanvasDraw {...canvasProps} />
      </div>
    </div>
  );
}

export default CanvasPlan;
