import React, { useState } from "react";
import "./SkeletonGrid.scss";
import SceletonLine from "../SkeletonLine/SkeletonLine";
import SceletonCircle from "../SkeletonCircle/SkeletonCircle";
import Screen from "../../../../../Screen/Screen";
import { getLimitElements } from "../../../../../../utils/getLimitElements";
const SkeletonGrid = () => {
  const [elem, setElem] = useState(8);
  return (
    <div className="skeletonGrid">
      {Array(getLimitElements() || elem)
        .fill(0)
        .map((e, i) => (
          <div className="skeletonGrid-card" key={i}>
            <div className="skeletonGrid-card-content">
              <div className="skeletonGrid-card-header">
                <SceletonLine width={"15%"} />
              </div>
              <Screen size={"xxxxl"}>
                <SceletonCircle />
              </Screen>
              <Screen size={"xxxxl"} down>
                <SceletonCircle size={"45px"} />
              </Screen>
              <SceletonLine width={"50%"} />
            </div>

            <div className="skeletonGrid-card-main">
              {Array(4)
                .fill(0)
                .map((e, i) => (
                  <SceletonLine width={"80%"} key={i} />
                ))}
            </div>
            <div className="skeletonGrid-card-footer">
              {Array(2)
                .fill(0)
                .map((e, i) => (
                  <SceletonLine width={"60%"} key={i} />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default SkeletonGrid;
