import React, { useState, useEffect } from "react";
import "./AuthPageMobile.scss";
import logo from "../../../assets/images/pages/auth/logo.svg";
import { useChangeIV } from "../../../hooks/useChangeIV";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin, asyncRestoteAccess } from "../../../store/reducers/userReducer";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Icons from "../../../components/Icons/Icons";
import { toast } from "react-toastify";
import InputPassword from "../../../components/InputPassword/InputPassword";
import { useModal } from "../../../hooks/useModal";
import ModalAddChatMobile from "../ChatMobile/components/ModalAddChatMobile/ModalAddChatMobile";
import roboweb from "../../../assets/images/roboweb-team.svg";
import AuthPageResetPassword from "../../../components/AuthPageResetPassword/AuthPageResetPassword";
import AuthPageForm from "../../../components/AuthPageForm/AuthPageForm";
import { ErrorBoundary } from "../../../ui";

function AuthPageMobile() {
  const dispath = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const [restoreVisible, setReverseVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [resetPasswordVisible, setRresetPasswordVisible] = useState(false);

  const [resetPasswordLoading, setRresetPasswordLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    login: "",
    password: "",
    confirm: false,
    passwordNewAuth: "",
    passwordRepeatAuth: "",
  });

  const auth = async (e) => {
    e.preventDefault();

    if (!IV.login || !IV.password || !IV.confirm) return toast.error("Заполните все поля");
    setLoading(true);
    const res = await dispath(
      asyncLogin({
        login: IV.login.toLowerCase().replaceAll(" ", ""),
        password: IV.password,
        confirm: IV.confirm,
      }),
    );
    setLoading(false);
    if (res.error) return;
  };

  const resetPassword = async (e) => {
    e.preventDefault(IV);
    if (!IV.login) return toast.error("Ведите почту");
    setRresetPasswordLoading(true);
    const res = await dispath(
      asyncRestoteAccess({ email: IV.login.toLowerCase().replaceAll(" ", "") }),
    );
    setRresetPasswordLoading(false);
    if (res.error)
      return toast.error(res.payload.response.data.message || "Проверьте введенную почту");
    if (res) return toast.success("Ссылка на сброс пароля отправлена на почту");
  };

  const createPassword = async (e) => {
    e.preventDefault();

    if (IV.passwordNewAuth !== IV.passwordRepeatAuth) return toast.error("Пароли не совпадают");

    if (!IV.passwordNewAuth || !IV.passwordRepeatAuth) return toast.error("Заполните все поля");

    checkValidateIV("passwordNewAuth", {
      minHeight: 7,
      maxHeight: 10,
      required: true,
    });
    checkValidateIV("passwordRepeatAuth", {
      same: "passwordNewAuth",
      required: true,
    });

    const res = await dispath(
      asyncRestoteAccess({
        token: location.pathname.split("/")[2],
        new_password: IV.passwordRepeatAuth,
      }),
    );

    if (res.error) return toast.error("Не удалось изменить пароль");
    if (res) {
      toast.success("Пароль изменен");
    }
    navigate(`/auth`);
  };

  useEffect(() => {
    if (location.pathname.includes("restore")) {
      if (location.pathname.split("/")[2]) {
        setReverseVisible(true);
      } else {
        setReverseVisible(false);
        navigate(`/auth`);
        toast.error("Ссылка для смены пароля не корректна");
      }
    } else {
      setReverseVisible(false);
    }
  }, [location.pathname]);

  return (
    <ErrorBoundary>
      <div className="authPageMobile">
        <div className="authPageMobile-owerflow"></div>
        <div className="authPageMobile-container">
          <div className="authPageMobile-wrapper">
            <div className="authPageMobile-auth-logo">
              <img src={logo} alt="logo" />
            </div>
            {restoreVisible ? (
              <form onSubmit={createPassword} className="authPage-auth-form">
                <span className="authPage-auth-form-title">Введите новый пароль.</span>
                <div className="authPage-auth-form-inputs">
                  <div className="authPage-auth-form-inputs-input">
                    <InputPassword
                      value={IV.passwordNewAuth}
                      onChange={(e) => changeIV(e)}
                      auth
                      title={"Новый пароль"}
                      name="passwordNewAuth"
                    />
                  </div>
                  <div className="authPage-auth-form-inputs-input">
                    <InputPassword
                      value={IV.passwordRepeatAuth}
                      name="passwordRepeatAuth"
                      onChange={(e) => changeIV(e)}
                      auth
                      title={"Повторите новый пароль"}
                    />
                  </div>
                </div>
                <div className="authPage-auth-form-confirm">
                  <Checkbox
                    className="authPage-auth-form-confirm-checbox"
                    onCheck={() =>
                      changeIV("set", {
                        name: "confirm",
                        value: !IV.confirm,
                      })
                    }
                    check={IV.confirm}
                  />
                  <span className="authPage-auth-form-confirm-text">
                    Я даю своё согласие на обработку персональных даннных в соответсвии с{" "}
                    <a href="#" className="authPage-auth-form-confirm-text-link">
                      Политикой конфиденциальности
                    </a>{" "}
                    и условиями пользования
                  </span>
                </div>
                <Button
                  disabled={!IV.confirm}
                  className="authPage-auth-form-btnSub"
                  title="Отправить"
                  color="blue"
                  type="submit"
                  loading={loading}
                />

                <span
                  onClick={() => setReverseVisible(false)}
                  className="authPage-auth-form-btnResetPasw"
                >
                  Отмена
                </span>
              </form>
            ) : (
              <>
                {!!resetPasswordVisible && (
                  <AuthPageResetPassword
                    changeIV={changeIV}
                    resetPassword={resetPassword}
                    IV={IV}
                    setRresetPasswordVisible={setRresetPasswordVisible}
                    loading={resetPasswordLoading}
                  />
                )}
                {!!resetPasswordVisible || (
                  <AuthPageForm
                    IV={IV}
                    changeIV={changeIV}
                    auth={auth}
                    loading={loading}
                    setRresetPasswordVisible={setRresetPasswordVisible}
                  />
                )}
              </>
            )}
          </div>

          <div className="authPageMobile-copyrigh">
            <span className="authPageMobile-copyrigh-text">Разработано: </span>
            <a href="https://roboweb.team" target="_blank">
              <img className="authPageMobile-copyrigh-logo" src={roboweb} alt="Roboweb" />
            </a>
          </div>
        </div>
        <div className="authPageMobile-footer">
          <span className="authPageMobile-footer-text">
            © ООО "Дэвин" 2023-2024 г. Использование контента сайта, его кода, механизмов
            взаимодействия с пользователем, идеи и технической реализации продукта без согласия
            авторов строго запрещено.
          </span>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default AuthPageMobile;
