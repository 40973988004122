import React from "react";
import Screen from "../../../../components/Screen/Screen";
import Icons from "../../../../components/Icons/Icons";
import { useModal } from "../../../../hooks/useModal";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import { asyncGetEvent } from "../../../../store/reducers/calendarReducer";
import { getFormatTime } from "../../../../utils/getFormatTime";
import { useDispatch } from "react-redux";

const CalendarTableItem = ({ viewCards, info }) => {
  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();
  const { edit, remove } = useHasPermissions("calendar", info);

  const showModalEvent = async (name, data) => {
    if (name === "info" || name === "edit") {
      const res = await dispatch(asyncGetEvent(data._id));
      if (res.error) return;
      if (name === "edit") changeModalVisible("editEvent", true, res.payload.data);
      else if (name === "info") changeModalVisible("infoEvent", true, res.payload.data);
    } else if (name === "delete") {
      changeModalVisible("deleteEvent", true, { _id: data._id });
    }
  };

  return (
    <div
      className={`calendarTable-table-margix-day-eventList-item ${
        viewCards === "rows" ? "calendarTable-table-margix-day-eventList-item-row" : ""
      }`}
    >
      <div
        className="calendarTable-table-margix-day-eventList-item-title"
        onClick={() => showModalEvent("info", info)}
      >
        <span>
          {getFormatTime(info.start)} - {getFormatTime(info.end)}
        </span>
        <span>{info.name}</span>
      </div>
      {viewCards === "rows" && (
        <>
          <Screen size={"lg"}>
            <div className="calendarTable-table-margix-day-eventList-item-row-btns">
              <Icons
                onClick={() => showModalEvent("info", info)}
                type={"infoItem"}
                size={"md"}
                cursor
                title={{
                  visible: true,
                  text: "",
                }}
              />
              {edit && (
                <Icons
                  type={"edit"}
                  size={"md"}
                  cursor
                  onClick={() => showModalEvent("edit", info)}
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              )}
              {remove && (
                <Icons
                  onClick={() => showModalEvent("delete", info)}
                  type={"remove"}
                  size={"md"}
                  cursor
                  title={{
                    visible: true,
                    text: "",
                  }}
                />
              )}
            </div>
          </Screen>
          <Screen size={"lg"} down>
            <div
              className="calendarTable-table-margix-day-eventList-item-row-menu"
              onClick={() => changeModalVisible("menu", true, info, false, false, "calendar")}
            >
              <Icons type={"menu"} size={"md"} />
            </div>
          </Screen>
        </>
      )}
    </div>
  );
};

export default CalendarTableItem;
