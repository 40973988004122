import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createArray } from "../utils/createArray";

export const useHasPermissions = (type, info) => {
  const { userData } = useSelector((state) => state.user);
  const [permissions, setPermissions] = useState({
    visible: false,
    edit: false,
    remove: false,
    add: false,
  });
  const [typeComponet, setTypeComponent] = useState(""); // settings // reference // companiesRow
  const [infoComponent, setInfoComponent] = useState(null);
  const { _id: userId, role, profiles: userProfiles } = userData;

  const searchProfilesUserId = (profiles, type) => {
    const profileUser = profiles?.filter((profile) => {
      return profile?.position?.name === type && profile?.user?._id === userId;
    });
    return profileUser;
  };

  // проверяем профайлы юзера и выдаем массив с id компаний
  const checkProfileToDirector = (profiles) => {
    if (!profiles) return;

    const arr = [];

    for (let i = 0; i < profiles?.length; i++) {
      if (
        profiles[i]?.position?.name === "Администратор компании" ||
        profiles[i]?.position?.name === "Руководитель компании"
      ) {
        arr.push(profiles[i]?.company?._id);
      }
    }
    return arr;
  };

  // проверяем профайлы юзера и выдаем массив с id компаний
  const checkProfileToTopPosition = (profiles) => {
    if (!profiles) return;

    const arr = [];

    for (let i = 0; i < profiles?.length; i++) {
      if (
        profiles[i]?.position?.name === "Администратор компании" ||
        profiles[i]?.position?.name === "Руководитель компании" ||
        profiles[i]?.position?.name === "Ответственный по проекту"
      ) {
        arr.push(profiles[i]?.company?._id);
      }
    }
    return arr;
  };

  // собираем массив из id компаний
  const createArrayCompanyToProfiles = (profiles) => {
    if (!profiles) return;

    const arr = [];
    for (let i = 0; i < profiles?.length; i++) {
      if (profiles[i]?.company?._id) {
        arr.push(profiles[i]?.company?._id);
      } else if (profiles[i]?.company?.value) {
        arr.push(profiles[i]?.company?.value);
      }
    }
    return arr;
  };

  // сортировка профалов по компании и проекту
  const createArrayProgectToResponsible = (profiles, companyId, projectId) => {
    if (!profiles) return;

    const arr = [];
    for (let i = 0; i < profiles?.length; i++) {
      if (profiles[i]?.position?.name === "Ответственный по проекту") {
        if (!!companyId && profiles[i]?.company?._id === companyId) {
          if (!!projectId && profiles[i]?.projects[0]?._id === projectId) {
            arr.push(profiles[i]?.projects[0]?._id);
          }
        }
        // } else {
        //   arr.push(profiles[i]?.projects[0]?._id); // у ответсвенного только 1 проект может быть
        // }
      }
    }
    return arr;
  };

  // сравниваем массив компаний и те компании в которых юзер директор\админ
  const checkProfileEmployee = (arrayComany) => {
    if (!arrayComany) return;

    const arrIdsCompanyDirector = checkProfileToDirector(userProfiles);

    const arrIdCompany = createArrayCompanyToProfiles(arrayComany);

    const result = [];

    for (let i = 0; i < arrIdCompany?.length; i++) {
      for (let j = 0; j < arrIdsCompanyDirector?.length; j++) {
        if (arrIdCompany[i] === arrIdsCompanyDirector[j]) {
          result?.push(arrIdCompany[i]);
        }
      }
    }
    return result;
  };

  // проверка на ответсвенного
  const checkResponsibleProject = (info) => {
    if (info?.project?._id) {
      const authorCompanyId = info.author?.company?._id;
      const sortCompanyProfiles = userProfiles?.filter(
        (el) =>
          el.company._id === authorCompanyId && el?.position?.name === "Ответственный по проекту",
      );
      // проверка на ответсенного
      for (let i = 0; i < sortCompanyProfiles?.length; i++) {
        const profile = sortCompanyProfiles[i];
        if (profile?.projects[0]?._id === info?.project?._id) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  // проверка юзера на то что он из компании ген подрядчика проекта
  const checkGenContractorProject = (info) => {
    const generalContractorId = info?.generalContractor?._id;
    const arrIdsCompanyDirector = checkProfileToDirector(userProfiles);
    const projectId = info?._id;
    const arrIdsProgectResponsible = createArrayProgectToResponsible(
      userProfiles,
      generalContractorId,
      projectId,
    );
    // находим id компании в профайле директоров
    if (arrIdsCompanyDirector?.includes(generalContractorId)) {
      return true;
    } else if (arrIdsProgectResponsible?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //проверка на автора
  const checkAuthor = (author) => {
    if (author?.user?._id === userId) {
      return true;
    } else {
      return false;
    }
  };

  // проверка является ли юзер одним из участникнов
  const checkParticipantToUser = (profiles) => {
    //userId
    let findUserProfile = profiles?.find((profile) => profile?.user?._id === userId);

    if (findUserProfile) {
      return true;
    } else {
      return false;
    }
  };

  const checkArraysDouble = (first, second) => {
    let res = [];

    for (var i = 0; i < first?.length; i++) {
      //проходимся по первому масиву
      for (var j = 0; j < second?.length; j++) {
        // ищем соотвествия во втором массиве
        if (first[i] === second[j]) {
          res.push(first[i]); // если совпадаем делаем что либо с этим значением
        }
      }
    }
    return res;
  };
  // проверяем есть ли id зказачика или гп в профайлах юзера
  const checkPermissionBaseInfo = (infoComponent) => {
    const companyIdUser = createArrayCompanyToProfiles(userProfiles);
    const customersIds = createArray(infoComponent?.customers, "_id");

    const res = checkArraysDouble(
      [infoComponent?.generalContractor?._id, ...customersIds],
      companyIdUser,
    );
    return res;
  };

  const checkPermisson = () => {
    // console.log(userData);

    if (role === "admin") {
      return setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
        types: ["genContractor"], /// для корректного отображения документов
      });
    } else if (typeComponet === "companiesCard") {
      // элемент компании (строчный и грид)
      if (infoComponent?.director?._id === userId) {
        setPermissions({
          visible: true,
          edit: true,
          remove: true,
          add: true,
        });
      } else if (
        searchProfilesUserId(infoComponent?.profiles, "Администратор компании")?.length > 0
      ) {
        setPermissions({
          visible: true,
          edit: true,
          remove: true,
          add: true,
        });
      }
      // проверка страницы employees и карточек
    } else if (
      (typeComponet === "employees:users" || typeComponet === "users") &&
      checkProfileToDirector(userProfiles)?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      // элемент компании (строчный и грид)
      typeComponet === "employeesCard" &&
      checkProfileEmployee(infoComponent?.profiles)?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "profileRowModal" &&
      checkProfileEmployee([infoComponent])?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      (typeComponet === "employees:groups" || typeComponet === "groups") &&
      checkProfileToDirector(userProfiles)?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "groupsCard" &&
      checkProfileEmployee([infoComponent?.director])?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "structuresCard" &&
      checkProfileEmployee([infoComponent])?.length > 0
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "userInfo:info" &&
      checkProfileEmployee(infoComponent?.profiles)?.length > 0
    ) {
      setPermissions({
        visible: true, // отображать или нет страницы спроектами и задачами
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "calendar" &&
      (checkAuthor(infoComponent?.author) ||
        checkProfileEmployee([infoComponent?.author])?.length > 0)
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (typeComponet === "infoEvent") {
      // console.log(checkParticipantToUser(infoComponent?.employees));
      if (
        checkAuthor(infoComponent?.author) ||
        checkProfileEmployee([infoComponent?.author])?.length > 0
      ) {
        setPermissions({
          visible: true,
          edit: true,
          remove: true,
          add: true,
        });
      } else if (
        checkParticipantToUser(infoComponent?.employees) ||
        checkProfileEmployee(infoComponent?.employees)?.length > 0
      ) {
        setPermissions({
          visible: true,
          edit: false,
          remove: true,
          add: true,
        });
      }
    } else if (
      typeComponet === "infoProtocol" &&
      (checkAuthor(infoComponent?.secretary) ||
        checkProfileEmployee([infoComponent?.secretary])?.length > 0)
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (
      typeComponet === "taskCard" &&
      (checkAuthor(infoComponent?.author) ||
        checkProfileEmployee([infoComponent?.author])?.length > 0 ||
        checkResponsibleProject(infoComponent))
    ) {
      if (infoComponent?.status === "completed") {
        setPermissions({
          visible: true,
          edit: true,
          remove: false,
          add: true,
        });
      } else {
        setPermissions({
          visible: true,
          edit: true,
          remove: false,
          add: true,
        });
      }
    } else if (typeComponet === "projectCard" && checkGenContractorProject(infoComponent)) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else if (typeComponet === "documentation") {
      const types = [];
      let visible = false;

      if (
        checkArraysDouble(
          [infoComponent?.generalContractor],
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("genContractor");
        visible = true;
      }
      if (
        checkArraysDouble(infoComponent?.customers, createArrayCompanyToProfiles(userProfiles))
          ?.length > 0
      ) {
        // console.log(`!!customers`);
        types.push("customer");
        visible = types.includes("genContractor") ? true : false;
      }
      if (
        checkArraysDouble(infoComponent?.executors, createArrayCompanyToProfiles(userProfiles))
          ?.length > 0
      ) {
        // console.log(`!!executors`);
        types.push("executor");
        visible = types.includes("genContractor") || types.includes("customer") ? true : false;
      }
      setPermissions({
        visible: visible,
        edit: true,
        remove: true,
        add: true,
        types: types,
      });
    } else if (typeComponet === "project:documentation") {
      const types = [];
      let visible = false;

      if (
        checkArraysDouble(
          [infoComponent?.generalContractor?._id],
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("genContractor");
        visible = true;
      }
      if (
        checkArraysDouble(
          createArray(infoComponent?.customers, "_id"),
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        // console.log(`!!customers`);
        types.push("customer");
        visible = types.includes("genContractor") ? true : false;
      }
      if (
        checkArraysDouble(
          createArray(infoComponent?.executors, "_id"),
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("executor");
        visible = types.includes("genContractor") || types.includes("customer") ? true : false;
      }
      setPermissions({
        visible: visible,
        edit: false,
        remove: false,
        add: true,
        types: types,
      });
    } else if (typeComponet === "project:participants") {
      const types = [];
      let visible = true;
      if (
        checkArraysDouble(
          [infoComponent?.generalContractor?._id],
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("genContractor");
      }
      if (
        checkArraysDouble(
          createArray(infoComponent?.customers, "_id"),
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("customer");
      }
      if (
        checkArraysDouble(
          createArray(infoComponent?.executors, "_id"),
          createArrayCompanyToProfiles(userProfiles),
        )?.length > 0
      ) {
        types.push("executor");
        visible = types.includes("genContractor") || types.includes("customer") ? true : false;
      }
      setPermissions({
        visible: true,
        edit: false,
        remove: false,
        add: true,
        types: types,
      });
    } else if (typeComponet === "project:basicInformation") {
      if (checkPermissionBaseInfo(infoComponent)?.length > 0) {
        setPermissions({
          visible: true,
          edit: true,
          remove: true,
          add: true,
        });
      } else {
        setPermissions({
          visible: false,
          edit: false,
          remove: false,
          add: false,
        });
      }
    } else if (
      typeComponet === "project:workSchedule" &&
      checkGenContractorProject(infoComponent)
    ) {
      setPermissions({
        visible: true,
        edit: true,
        remove: true,
        add: true,
      });
    } else {
      return setPermissions({ visible: false, edit: false, remove: false, add: false });
    }
  };

  useEffect(() => {
    setTypeComponent(type);
    setInfoComponent(info);
  }, [type, info]);

  useEffect(() => {
    checkPermisson();
  }, [typeComponet, infoComponent]);

  return { ...permissions, checkPermisson };
};
