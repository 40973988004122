import React from "react";
import Reports from "./components/Reports/Reports";
import Map from "./components/Map/Map";
import ListChart from "../ListChart/ListChart";
import ColumnsChart from "../ColumnsChart/ColumnsChart";
import BanbsChart from "../BandsChart/BandsChart";
import AreasChart from "../AreasChart/AreasChart";
import "./AnalyticsInfoChart.scss";
import Screen from "../Screen/Screen";

const AnalyticsInfoChart = ({ heightChart }) => {
  return (
    <div className="analyticsInfoChart">
      {/* Отчеты по задачам */}
      <div className="analyticsInfoChart-reports">
        {" "}
        <Reports />{" "}
      </div>
      {/* <Screen size={"lg"}> */}
      {/* Список задач сотрудников
        <div className="analyticsInfoChart-list">
          <ListChart heightChart={heightChart} title="Список задач сотрудников:" full />
        </div> */}
      {/* </Screen> */}
      <div className="analyticsInfoChart-charts">
        {/* <div className="analyticsInfoChart-charts-completedTasks">
          <ColumnsChart
            heightChart={heightChart}
            title="Выполненные задачи за февраль:"
          />
        </div> */}
        {/* <div className="analyticsInfoChart-charts-completedTasksStages">
          <BanbsChart
            heightChart={heightChart}
            title="График выполненых работ (этапы):"
          />
        </div>
        <div className="analyticsInfoChart-charts-closedProjects">
          <AreasChart
            heightChart={heightChart}
            title="Колличество закрытых проектов за 2021 год:"
          />
        </div> */}
      </div>
      {/* Наши проекты на карте */}
      {/* <div className="analyticsInfoChart-map">
        <Map />
      </div> */}
    </div>
  );
};

export default AnalyticsInfoChart;
