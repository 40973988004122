import React, { useState, useEffect } from "react";
import "./CompaniesMobile.scss";
import { useSelects } from "../../../../../hooks/useSelects";
import { useFilter } from "../../../../../hooks/useFilter";
import { useDispatch, useSelector } from "react-redux";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import { useNavigate, useParams } from "react-router-dom";
import CompaniesCardMobile from "../../../../../components/CompaniesCardMobile/CompaniesCardMobile";
import { asyncGetCompanies } from "../../../../../store/reducers/companiesReducer";
import CompanyInfoEditMobile from "../../../../../components/CompanyInfoEditMobile/CompanyInfoEditMobile";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";

const CompaniesMobile = ({ setCompanyLength, infoCompany, editCompany }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useSearch("employees:companies");

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const pageNumber = params.page;

  const { companies, count, loading } = useSelector((state) => state.companies);

  const { selectItems, addItemSelect, addAllItemSelect } =
    useSelects(companies);

  const getNumber = (i) =>
    i + 1 + companies.length * pageNumber - companies.length;

  useEffect(() => {
    setCompanyLength(count);
  }, [companies]);

  return (
    <>
      <div className="companiesMobile">
        <WrapperLoader loading={loading} chapter={"companies"}>
          {companies.length > 0 ? (
            <div className="companiesMobile-section">
              <div className="companiesMobile-grid">
                {companies.map((company) => (
                  <CompaniesCardMobile
                    info={company}
                    key={company._id}
                    changeModalVisible={changeModalVisible}
                  />
                ))}
              </div>
              {count > getLimitElements() && (
                <div className="employeesGridMobile-pagination">
                  <Pagination count={count} />
                </div>
              )}
            </div>
          ) : (
            !loading && (
              <EmptySection
                text={"Компании не добавлены"}
                textBtn={"Добавить"}
                onClick={() => {
                  changeModalVisible("addCompany", true);
                }}
              />
            )
          )}
        </WrapperLoader>
      </div>
    </>
  );
};

export default CompaniesMobile;
