import axios from "../axios/axios";
import { transformForSelect } from "./transformForSelect";

const fieldForDelete = ["projectId", "addField"];

const getFilter = (filter) => {
  const filterCopy = { ...filter };
  for (let i = 0; i < fieldForDelete.length; i++) {
    delete filterCopy[fieldForDelete[i]];
  }
  return filterCopy;
};

export const getForSelect = {
  getUsers: (filter, callback) => {
    try {
      const usersData = axios
        .post("/users/get_for_select/", { ...getFilter(filter) })
        .then((res) => res?.data || [])
        .then((users) => {
          const { addField } = filter;
          if (addField && Array.isArray(addField)) {
            callback &&
              callback(
                users.map((u) => {
                  return transformForSelect(u, addField);
                }),
                users
              );
          } else {
            callback && callback(users.map(transformForSelect), users);
          }
        });
      return usersData;
    } catch {
      return [];
    }
  },
  getCompanies: (filter, callback) => {
    try {
      const companiesData = axios
        .post("/companies/get_for_select/", { ...filter })
        .then((res) => res?.data || [])
        .then(
          (companies) =>
            callback && callback(companies.map(transformForSelect), companies)
        );

      return companiesData;
    } catch {
      return [];
    }
  },
  getStagesAndWorks: (filter, callback) => {
    const stagesAndWorksData = axios
      .post(
        `/scheduleWorks/get_stages_work_for_select/${filter.scheduleWorksId}`,
        { ...filter }
      )
      .then((res) => res?.data || [])
      .then(
        (stagesAndWorks) =>
          callback && callback(stagesAndWorks.map(transformForSelect))
      );

    return stagesAndWorksData;
  },
  getStructures: (filter, callback) => {
    const structuresData = axios
      .post(`/structures/get_for_select`, { ...filter })
      .then((res) => res?.data || [])
      .then(
        (structures) => callback && callback(structures.map(transformForSelect))
      );

    return structuresData;
  },
  getGroups: (filter, callback) => {
    const groupsData = axios
      .post(`/groups/get_for_select`, { ...filter })
      .then((res) => res?.data || [])
      .then((groups) => callback && callback(groups.map(transformForSelect)));

    return groupsData;
  },
  getProjects: (filter, callback) => {
    const projectsData = axios
      .post(`/projects/get_projects_for_select`, { ...filter })
      .then((res) => res?.data || [])
      .then(
        (projects) => callback && callback(projects.map(transformForSelect))
      );

    return projectsData;
  },
  getUsersProject: (filter, callback) => {
    try {
      const usersData = axios
        .post(
          `/projects/get_project_employees_for_select/${filter.projectId}/`,
          { ...getFilter(filter) }
        )
        .then((res) => res?.data || [])
        .then((users) => {
          const { addField } = filter;
          if (addField && Array.isArray(addField)) {
            callback &&
              callback(
                users.map((u) => {
                  return transformForSelect(u, addField);
                }),
                users
              );
          } else {
            callback && callback(users.map(transformForSelect), users);
          }
        });

      return usersData;
    } catch {
      return [];
    }
  },
  getLayersProject: (filter, callback) => {
    const layersData = axios
      .post(`/tasks/get_project_layers/${filter.projectId}`, { ...filter })
      .then((res) => res?.data || [])
      .then((layers) => callback && callback(layers.map(transformForSelect)));

    return layersData;
  },
  getFolders: (filter, callback) => {
    const foldersData = axios
      .post("/documents/get_folders_for_select", { ...filter })
      .then((res) => res?.data?.folders || [])
      .then((folders) => callback && callback(folders.map(transformForSelect)));

    return foldersData;
  },
};

/**
 * addField - массив в котором нужно указать, значение какого поля необходимо добавить
 * в label, значение будет заключено в круглые скобки
 */
