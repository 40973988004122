import React, { useState, useEffect, useId } from "react";
import { useChangeIV } from "../../hooks/useChangeIV";
import Input from "../Input/Input";
import "./ModalAddFolder.scss";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useDispatch } from "react-redux";
import { asyncCreateFolders } from "../../store/reducers/projectsReducer";
import { useModal } from "../../hooks/useModal";
import { useSelector } from "react-redux";
import AccessEdit from "../AccessEdit/AccessEdit";
import { asyncGetPermissions } from "../../store/reducers/documentationReduser";
import { createArray } from "../../utils/createArray";
import { formatLabel } from "../../utils/formatLabel";
import { asyncGetProjectAllCompanies } from "../../store/reducers/projectsReducer";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import { toast } from "react-toastify";
import Screen from "../Screen/Screen";
import { useSearch } from "../../hooks/useSearch";

const ModalAddFolder = (props) => {
  const { pages, handleChange, changeCurrentFolder, fatherFolder, headFolder, type } = props;

  const formId = useId();

  const { changeModalVisible } = useModal();

  const dispatch = useDispatch();

  const [permissionsListСompanies, setPermissionsListСompanies] = useState({
    view: [],
    add: [],
    edit: [],
    copy: [],
    move: [],
    remove: [],
  }); // все доступные компании
  const [permissionsListProfiles, setPermissionsListProfiles] = useState({
    view: [],
    add: [],
    edit: [],
    copy: [],
    move: [],
    remove: [],
  }); // все доступные профили
  const [permissionsСompanies, setPermissionsСompanies] = useState({
    view: [],
    add: [],
    edit: [],
    copy: [],
    move: [],
    remove: [],
  }); // активные доступы
  const [permissionsProfiles, setPermissionsProfiles] = useState({
    view: [],
    add: [],
    edit: [],
    copy: [],
    move: [],
    remove: [],
  });

  const [customers, setCustomers] = useState([]);
  const [executors, setExecutors] = useState([]);
  const [loading, setLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
  });
  const [titleModal, setTitleModal] = useState("Создать папку документов");

  const getProjectPermissions = async () => {
    setLoading(true);

    const res = await dispatch(
      asyncGetProjectAllCompanies({
        project_id: fatherFolder.ref_id,
      }),
    );

    const genContractor = res.payload.generalContractor;
    const executorsPermissions =
      res.payload.executors.map((company) => ({
        name: company.name,
        _id: company._id,
      })) || [];
    setExecutors(executorsPermissions);
    const custumersPermissions =
      res.payload.customers.map((company) => ({
        name: company.name,
        _id: company._id,
      })) || [];
    setCustomers(custumersPermissions);
    if (type === "customer") {
      setPermissionsListСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...custumersPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...custumersPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    } else if (type === "executor") {
      setPermissionsListСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...executorsPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }, ...executorsPermissions],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    } else {
      setPermissionsListСompanies({
        view: [
          { _id: genContractor._id, name: genContractor.name },
          ...custumersPermissions,
          ...executorsPermissions,
        ],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
      setPermissionsСompanies({
        view: [{ _id: genContractor._id, name: genContractor.name }],
        add: [],
        edit: [],
        copy: [],
        move: [],
        remove: [],
      });
    }
    setPermissionsListProfiles({
      view: [],
      add: [],
      edit: [],
      copy: [],
      move: [],
      remove: [],
    });
    setPermissionsProfiles({
      view: [],
      add: [],
      edit: [],
      copy: [],
      move: [],
      remove: [],
    });
    setLoading(false);
  };

  const getPermissions = async (type = "child", id) => {
    setLoading(true);
    const res = await dispatch(asyncGetPermissions({ fileId: id }));
    if (res.error) return;
    // if (type === "father") {
    const companiesAccesses = res.payload.companies_accesses;
    const profilesAccesses = res.payload.profiles_accesses;

    setPermissionsListСompanies({
      view: companiesAccesses.view || [],
      add: companiesAccesses.add || [],
      edit: companiesAccesses.edit || [],
      copy: companiesAccesses.copy || [],
      move: companiesAccesses.move || [],
      remove: companiesAccesses.remove || [],
    });
    setPermissionsListProfiles({
      view:
        profilesAccesses.view.map((u) => ({
          name: formatLabel(u),
          _id: u._id,
        })) || [],
      add: profilesAccesses.add || [],
      edit: profilesAccesses.edit || [],
      copy: profilesAccesses.copy || [],
      move: profilesAccesses.move || [],
      remove: profilesAccesses.remove || [],
    });

    setPermissionsСompanies({
      view: companiesAccesses.view || [],
      add: companiesAccesses.add || [],
      edit: companiesAccesses.edit || [],
      copy: companiesAccesses.copy || [],
      move: companiesAccesses.move || [],
      remove: companiesAccesses.remove || [],
    });
    setPermissionsProfiles({
      view:
        profilesAccesses.view.map((u) => ({
          name: formatLabel(u),
          _id: u._id,
        })) || [],
      add: profilesAccesses.add || [],
      edit: profilesAccesses.edit || [],
      copy: profilesAccesses.copy || [],
      move: profilesAccesses.move || [],
      remove: profilesAccesses.remove || [],
    });

    // }
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3, maxHeight: 50 })) return;
    setLoading(true);

    await dispatch(
      asyncCreateFolders({
        name: IV.name,
        profiles_accesses: {
          view: createArray(permissionsProfiles.view, "_id"),
          add: createArray(permissionsProfiles.add, "_id"),
          edit: createArray(permissionsProfiles.edit, "_id"),
          copy: createArray(permissionsProfiles.copy, "_id"),
          move: createArray(permissionsProfiles.move, "_id"),
          remove: createArray(permissionsProfiles.remove, "_id"),
        },
        companies_accesses: {
          view: createArray(permissionsСompanies.view, "_id"),
          add: createArray(permissionsСompanies.add, "_id"),
          edit: createArray(permissionsСompanies.edit, "_id"),
          copy: createArray(permissionsСompanies.copy, "_id"),
          move: createArray(permissionsСompanies.move, "_id"),
          remove: createArray(permissionsСompanies.remove, "_id"),
        },
        type: "folder",
        father_folder: fatherFolder._id,
        ref: null,
        ref_id: null,
      }),
    );

    handleChange && handleChange();
    setLoading(false);
    changeCurrentFolder && changeCurrentFolder("all");
    changeModalVisible("addFolder", false);
  };

  useEffect(() => {
    if (type === "customer" && pages === "project") {
      return setTitleModal("Создать папку документов для заказчика");
    } else if (type === "performer" && pages === "project") {
      return setTitleModal("Создать папку документов для исполнителя");
    } else {
      setTitleModal(
        `Создать папку документов ${
          fatherFolder?._id === headFolder?._id ? "" : `в папке ${fatherFolder.name}`
        }`,
      );
    }
  }, [type, pages]);

  console.log("permissionsСompanies", permissionsСompanies);

  useEffect(() => {
    console.log("ModalAddFolder", fatherFolder);
    if (fatherFolder.main && fatherFolder.ref === "project") {
      getProjectPermissions();
    } else {
      getPermissions("father", fatherFolder._id);
    }
  }, []);

  // уведомления
  const startNotif = (text) => {
    return toast.warning(text);
  };

  // проверка добавлен ли зак\подрядчик
  const compareAccesses = (permisssinos, list) => {
    let sortArr = permisssinos?.filter((elem) => {
      return list?.find((item) => elem._id === item._id);
    });
    return sortArr.length > 0 ? false : true;
  };

  const checkAccesses = (permission) => {
    if (fatherFolder.main && fatherFolder.ref === "project") {
      if (permission.length === 0 && permissionsProfiles?.view?.length === 0) {
        startNotif("При сохарнении без доступов, будут унаследованы доступы родительской папки");
      }
      if (type === "customer" && compareAccesses(permission, customers)) {
        startNotif("Для создания папки заказчика, добавьте хотя бы одну компанию заказчика");
      } else if (type === "executor" && compareAccesses(permission, executors)) {
        startNotif("Для создания папки подрядчика, добавьте хотя бы одну компанию подрядчика");
      }
    }
  };

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addFolder")}
      title={titleModal}
      minHeight={false}
      btns={
        <div className="d-flex justify-center">
          <Button
            disabled={IV.name.length <= 3}
            type="submit"
            title="Создать"
            color="blue"
            form={formId}
          />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddFolder" id={formId}>
        <div className="modalAddFolder-main">
          <Input
            title="Папка документов:*"
            placeholder="Введите название папки"
            name="name"
            value={IV.name}
            onChange={changeIV}
            validate={checkValidateIV("name")}
          />
          <div className="hr"></div>
          <span className="modalInfoEditFolder-main-title">Доступы:</span>
          <WrapperLoader loading={loading} chapter={"permissionsFolderEdit"}>
            <AccessEdit
              permissionsList={permissionsListСompanies}
              permissions={permissionsСompanies}
              setPermissions={setPermissionsСompanies}
              typePermissions={"company"}
              fatherFolder={fatherFolder}
              searchProfile={fatherFolder?._id === headFolder?._id}
              checkAccesses={checkAccesses}
            />
            <AccessEdit
              permissionsList={permissionsListProfiles}
              permissions={permissionsProfiles}
              setPermissions={setPermissionsProfiles}
              typePermissions={"profile"}
              fatherFolder={fatherFolder}
              searchProfile={fatherFolder?._id === headFolder?._id}
              checkAccesses={checkAccesses}
            />
          </WrapperLoader>
          <Screen size={"lg"}>
            <div className="d-flex justify-center">
              <Button
                disabled={IV.name.length <= 3}
                type="submit"
                title="Создать"
                color="blue"
                form={formId}
              />
            </div>
          </Screen>
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddFolder;
