import { getBaseUrl } from "./getBaseUrl";
import noAvatar from "../assets/images/pages/noavatar.svg";
import noLogo from "../assets/images/pages/noImages.svg";
import noImages from "../assets/images/pages/noImages.jpg";

export const getFullPath = (path, type) => {
  if (!path) {
    if (type === "avatar") {
      return noAvatar;
    } else if (type === "logo") {
      return noLogo;
    } else if (type === "noImage") {
      return noImages;
    } else if (!type) {
      return noImages;
    }
  }
  if (path && (type === "task" || type === "comments")) {
    if (path?.includes("photos")) {
      return `${getBaseUrl()}/task_photos${path.replace("photos/", "")}`;
    } else if (path?.includes("comments")) {
      const docPath = path.replace("comments/", "");

      return `${getBaseUrl()}/task_comments${docPath}`;
    }
  } else if (path && (type === "deliveryWork" || type === "delivery_work")) {
    const docPath = path.replace("delivery_work/", "");
    return `${getBaseUrl()}/delivery_work${docPath}`;
  } else if (path && (type === "chat" || type === "chats")) {
    const docPath = path.replace("chats/", "");
    return `${getBaseUrl()}/chat_files${docPath}`;
  } else if (
    type === "avatar" ||
    type === "project" ||
    type === "layer" ||
    type === "logo" ||
    type === "event" ||
    type === "events"
  ) {
    return getBaseUrl() + path;
  } else if (type === "url") return URL.createObjectURL(path);
};
