import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitElements } from "../../utils/getLimitElements";

export const asyncGetTasks = createAsyncThunk("tasks/asyncGetTasks", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const limit = data?.params?.limit || getLimitElements() || 10;
    const res = await axios.post(`tasks/get/${data?.page || 1}/?limit=${limit}`, data.params);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetTasksForKanban = createAsyncThunk(
  "tasks/asyncGetTasksForKanban",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = 10;
      const res = await axios.post(
        `tasks/get/${data.status}/${data?.page || 1}/?limit=${limit}`,
        data.params,
      );

      return {
        data: res.data,
        status: data.status,
        concat: data.concat,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetUserTasks = createAsyncThunk(
  "tasks/asyncGetUserTasks",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = getLimitElements() || 10;
      const res = await axios.post(
        `tasks/get_for_user/${data.queries.userId}/${data?.page || 1}/?limit=${limit}`,
        data.params,
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetSubTasks = createAsyncThunk(
  "tasks/asyncGetSubTasks",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`tasks/get_subtasks/${data}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetTask = createAsyncThunk("tasks/asyncGetTask", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`tasks/get_task/${data}`);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateTask = createAsyncThunk("tasks/asyncCreateTask", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post("tasks/create", data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateSubTask = createAsyncThunk(
  "tasks/asyncCreateSubTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post("tasks/create", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditTask = createAsyncThunk("tasks/asyncEditTask", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`tasks/edit/${data.taskId}`, data.data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCopyTask = createAsyncThunk("tasks/asyncCopyTask", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`tasks/copy/${data.taskId}`, data.data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncDelegationTask = createAsyncThunk(
  "tasks/asyncDelegationTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`tasks/delegate/${data.taskId}`, data.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteTask = createAsyncThunk("tasks/asyncDeleteTask", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.delete(`tasks/delete`, { data });
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncDeleteSubTask = createAsyncThunk(
  "tasks/asyncDeleteSubTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`tasks/delete_sub_task`, { data });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteLayerFromTasks = createAsyncThunk(
  "tasks/asyncDeleteLayerFromTasks",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`tasks/delete_layer_from_tasks/${data.layerId}`, {
        data: { tasks_list: data.taskIds },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncPostponedTask = createAsyncThunk(
  "tasks/asyncPostponedTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/postponed`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditStatusTask = createAsyncThunk(
  "tasks/asyncEditStatusTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/status/${data.status}`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asynсAddEmployees = createAsyncThunk(
  "tasks/asynсAddEmployees",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/add_employees`, {
        type: data.type,
        employees: data.employees,
        tasks: data.tasks,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asynсDelEmployees = createAsyncThunk(
  "tasks/asynсDelEmployees",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/delete_employees`, {
        type: data.type,
        employees: data.employees,
        tasks: data.tasks,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// * comments

export const asyncAddCommentTask = createAsyncThunk(
  "tasks/asyncAddCommentTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`tasks/comment/${data.taskId}`, data.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncUpdateCommentTask = createAsyncThunk(
  "tasks/asyncUpdateCommentTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/comment/update/${data.commentId}`, data.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncRemoveCommentTask = createAsyncThunk(
  "tasks/asyncRemoveCommentTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`tasks/comment/remove/${data.commentId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncReadCommentTask = createAsyncThunk(
  "tasks/asyncReadCommentTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/comment/read/${data.commentId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncReadAllCommentsTask = createAsyncThunk(
  "tasks/asyncReadAllCommentsTask",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`tasks/comment/readAll/${data.taskId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const tasksReducer = createSlice({
  name: "tasks",
  initialState: {
    tasks: [],
    count: 0,

    tasksKanban: {
      assigned: { count: 0, tasks: [] },
      rejected: { count: 0, tasks: [] },
      atWork: { count: 0, tasks: [] },
      overdue: { count: 0, tasks: [] },
      onVerification: { count: 0, tasks: [] },
      completed: { count: 0, tasks: [] },
    },

    userTasks: null,

    task: null,
    loading: false,
  },
  reducers: {
    clearTasksKanban(state) {
      const cleared = {};
      for (const key in state.tasksKanban) {
        cleared[key] = {
          count: 0,
          tasks: [],
        };
      }

      state.tasksKanban = cleared;
    },

    setUnReadComments(state, { payload }) {
      const { taskId, unReadComments } = payload;

      state.tasks = state.tasks.map((task) => {
        if (task._id === taskId) {
          return {
            ...task,
            unReadComments: unReadComments,
          };
        }

        return task;
      });
    },

    addCommentTask(state, { payload }) {
      const { taskId, comment } = payload;

      state.tasks = state.tasks.map((task) => {
        if (task._id === taskId) {
          return {
            ...task,
            comments: [...(task.comments || []), comment],
          };
        }

        return task;
      });
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetTasks.fulfilled]: (state, action) => {
      state.tasks = action.payload.tasks;
      state.count = action.payload.count;
    },
    [asyncGetTasks.rejected]: "Не удалось получить задачи",

    [asyncGetTasksForKanban.fulfilled]: (state, { payload }) => {
      state.tasksKanban[payload.status].tasks = [
        ...(payload.concat ? state.tasksKanban[payload.status].tasks : []),
        ...payload.data.tasks,
      ];

      state.tasksKanban[payload.status].count = payload.data.count;
    },

    [asyncGetTasksForKanban.rejected]: "Не удалось получить задачи",

    [asyncGetUserTasks.fulfilled]: (state, action) => (state.userTasks = action.payload),

    [asyncGetUserTasks.rejected]: "Не удалось получить задачи пользователя",

    [asyncGetSubTasks.rejected]: "Не удалось получить задачу",

    [asyncGetTask.fulfilled]: (state, action) => {
      state.task = action.payload;
    },
    [asyncGetTask.rejected]: "Не удалось получить задачу",

    [asyncCreateTask.fulfilled]: () => toast.success("Задача успешно создана"),
    [asyncCreateTask.rejected]: "Не удалось создать задачу",

    [asyncCreateSubTask.fulfilled]: () => toast.success("Подзадача успешно создана"),
    [asyncCreateSubTask.rejected]: "Не удалось создать подзадачу",

    [asyncEditTask.fulfilled]: () => toast.success("Задача успешно изменена"),
    [asyncEditTask.rejected]: "Не удалось изменить задачу",

    [asyncCopyTask.fulfilled]: () => toast.success("Задача успешно скопирована"),
    [asyncCopyTask.rejected]: "Не удалось скопировать задачу",

    [asyncDeleteTask.fulfilled]: () => toast.success("Задача успешно удалена"),
    [asyncDeleteTask.rejected]: "Не удалось удалить задачу.",

    [asyncDeleteSubTask.fulfilled]: () => toast.success("Подзадача успешно удалена"),
    [asyncDeleteSubTask.rejected]: "Не удалось удалить подзадачу",

    [asyncDeleteLayerFromTasks.fulfilled]: () => toast.success("Слой успешно удален из задач"),
    [asyncDeleteLayerFromTasks.rejected]: "Не удалось удалить слой из задач",

    [asyncPostponedTask.fulfilled]: () => toast.success("Задача успешно отложена"),
    [asyncPostponedTask.rejected]: "Не удалось отложить задачу",

    [asyncDelegationTask.fulfilled]: () => toast.success("Задача успешно делегирована"),
    [asyncDelegationTask.rejected]: "Не удалось делегировать задачу",

    [asyncEditStatusTask.fulfilled]: () => toast.success("Статус задачи успешно изменен"),
    [asyncEditStatusTask.rejected]: "Не удалось изменить статус задачи",

    [asynсAddEmployees.fulfilled]: () => toast.success("Сотрудники добавлены в задачи."),
    [asynсAddEmployees.rejected]: "Не удалось добавить сотрудников в задачи.",

    [asynсDelEmployees.fulfilled]: () => toast.success("Сотрудники удалены из задачи."),
    [asynсDelEmployees.rejected]: "Не удалось удалить сотрудников в задачи.",
  }),
});

export const { clearTasksKanban, setUnReadComments, addCommentTask } = tasksReducer.actions;

export default tasksReducer.reducer;
