import React, { useState, useEffect } from "react";
import SceletonLine from "../SkeletonLine/SkeletonLine";
import "./SkeletonRow.scss";
import { getLimitElements } from "../../../../../../utils/getLimitElements";

const SkeletonRow = ({ chapter, countRow }) => {
  const [elem, setElem] = useState(4);

  useEffect(() => {
    if (chapter === "employees") {
      setElem(9);
    } else if (chapter === "groups") {
      setElem(5);
    } else if (
      chapter === "companies" ||
      chapter === "projects" ||
      chapter === "participants" ||
      chapter === "tasks"
    ) {
      setElem(8);
    } else if (chapter === "companies-project" || chapter === "workSchedule") {
      setElem(9);
    }
  }, [elem]);

  return (
    <div className="skeletonRow">
      <div className={`skeletonRow-header ${chapter + "-grid-skeleton"}`}>
        {Array(elem - 1)
          .fill(0)
          .map((e, i) => (
            <SceletonLine key={i} e={e} />
          ))}
      </div>
      <ul className="skeletonRow-list">
        {Array(countRow ? countRow : getLimitElements())
          .fill(0)
          .map((e, i) => (
            <li
              className={`skeletonRow-item ${chapter + "-grid-skeleton"}`}
              key={i}
            >
              {Array(elem)
                .fill(0)
                .map((e, i) => (
                  <SceletonLine key={i} e={e} />
                ))}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SkeletonRow;
