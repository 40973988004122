import React, { useEffect, useState, useId } from "react";
import "./ModalEditEvent.scss";
import gpsIcon from "../../assets/icons/gps.svg";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Select from "../Select/Select";
import SelectMulti from "../SelectMulti/SelectMulti";
import Button from "../Button/Button";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useSelector } from "react-redux";
import RegularityForm from "../RegularityForm/RegularityForm";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import InputFile from "../InputFile/InputFile";
import Textarea from "../Textarea/Textarea";
import InputDate from "../InputDate/InputDate";
import ModalEditProtocol from "../ModalEditProtocol/ModalEditProtocol";
import NotificationFrequency from "../NotificationFrequency/NotificationFrequency";
import RegularityCheck from "../RegularityCheck/RegularityCheck";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";
import InputSearch from "../InputSearch/InputSearch";
import { notificationsSelect } from "../../data/task";
import { getModalName } from "./utils/getModalName";
import { useMemo } from "react";
import { getValueForOptions } from "./utils/getValueForOptions";
import { getFormDataTasks } from "./utils/getFormTasks";
import { setData } from "../../store/reducers/modalsReduces";
import { useDispatch } from "react-redux";
import {
  asyncEditEvent,
  asyncCreateEvent,
  asyncCreateProtocol,
  asyncEditProtocol,
  asyncDeleteProtocolFromEvent,
  asyncGetEvent,
} from "../../store/reducers/calendarReducer";
import { asyncCreateTask } from "../../store/reducers/tasksReducer";
import { getFullInfoTask } from "../ModalEditTask/utils/getFullInfoTask";
import Screen from "../Screen/Screen";
import ModalInfoProtocol from "../ModalInfoProtocol/ModalInfoProtocol";
import dayjs from "dayjs";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const ModalEditEvent = (props) => {
  const {
    mode,
    handleChange, // сработает, когда создадут/сохранят
  } = props;
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible, intermediateSaving, getInfo } = useModal();

  const modalName = getModalName(mode);

  const infoEvent = getInfo(modalName, "data");

  const userData = useSelector((state) => state.user.userData);

  const [protocolStatus, setProtocolStatus] = useState(null);

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      name: infoEvent?.name || "",
      description: infoEvent?.description || "",
      address: infoEvent?.address || "",
      start: infoEvent?.start ? new Date(infoEvent.start) : new Date(),
      end: infoEvent?.end ? new Date(infoEvent.end) : dayjs().add(1, "hour").toDate(),
      documents: infoEvent?.documents || [],
      minutesMeeting: false,
      regularity: infoEvent?.regularity,
      notificationEveryDay: infoEvent?.notificationEveryDay || !!infoEvent?.notifications,

      protocol: infoEvent?.protocol,
      protocolNumber: infoEvent?.protocol?.number || infoEvent?.protocolNumber || "",
      protocolName: infoEvent?.protocol?.name || infoEvent?.protocolName || "",
      protocolDescription: infoEvent?.protocol?.description || infoEvent?.protocolDescription || "",
    }),
    modalName,
  );

  const { options, changeOptions, checkValidateOptions } = intermediateSaving(
    useChangeOptions({
      employees: {
        value: getValueForOptions(infoEvent, "employees"),
        options: [],
      },
      notificationFrequency: {
        value: getValueForOptions(infoEvent, "notificationFrequency"),
        options: notificationsSelect,
      },
      project: {
        value: getValueForOptions(infoEvent, "project"),
        options: [],
      },
      employees_gencontractor: {
        value: getValueForOptions(infoEvent, "employees_gencontractor"),
        options: [],
      },
      employees_customer: {
        value: getValueForOptions(infoEvent, "employees_customer"),
        options: [],
      },
      employees_executor: {
        value: getValueForOptions(infoEvent, "employees_executor"),
        options: [],
      },
      secretary: {
        value: getValueForOptions(infoEvent, "secretary"),
        options: [],
      },

      author: {
        value: getValueForOptions(infoEvent, "author"),
        options: [],
      },
    }),
    modalName,
  );

  const optionsForProtocol = useMemo(() => options, [infoEvent?.protocol]);

  const changeFiles = (event, data) => {
    if (event === "add") changeIV("set", { name: "documents", value: data.documents });
    else if (event === "delete") {
      const newArr = IV.documents.filter((_, i) => i !== data.i);
      changeIV("set", { name: "documents", value: newArr });
    }
  };

  const changeVisibleFormProtocol = (bool) => {
    changeIV("set", { name: "protocol", value: bool });
    setProtocolStatus((prev) => (bool ? null : prev));
    if (bool) return;

    changeIV("set", { name: "protocolNumber", value: "" });
    changeIV("set", { name: "protocolName", value: "" });
    changeIV("set", { name: "protocolDescription", value: "" });

    changeOptions("project", "value", null);
    changeOptions("secretary", "value", null);
    changeOptions("employees_gencontractor", "value", []);
    changeOptions("employees_customer", "value", []);
    changeOptions("employees_executor", "value", []);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let arrForValidate = [
      checkValidateIV("name", { minHeight: 3 }),
      checkValidateIV("description", { maxHeight: 5000 }),
      checkValidateOptions("employees", { requiredArray: true }),
      checkValidateIV("end", { noMoreDate: { start: IV.start, end: IV.end } }),
      checkValidateOptions("author", { required: true }),
    ];

    if (IV.protocol) {
      arrForValidate = [
        ...arrForValidate,
        checkValidateIV("protocolName", { minHeight: 3 }),
        checkValidateIV("protocolDescription", { maxHeight: 5000 }),
        checkValidateOptions("employees_gencontractor", {
          requiredArray: true,
        }),
        checkValidateOptions("project", { required: true }),
        checkValidateOptions("secretary", { required: true }),
      ];
    }

    if (IV.notificationEveryDay) {
      arrForValidate.push(checkValidateOptions("notificationFrequency", { required: true }));
    }

    if (arrForValidate.some((el) => !el)) return;

    const formData = new FormData();

    formData.append("name", IV.name);
    formData.append("description", IV.description);
    formData.append("employees", JSON.stringify(options.employees.value.map((e) => e.value)));
    formData.append("author", options.author.value.value);
    formData.append("start", new Date(IV.start));
    formData.append("end", new Date(IV.end));
    formData.append("address", IV.address);

    if (IV.notificationEveryDay) {
      formData.append("notifications", options.notificationFrequency.value.value || null);
    }

    if (IV.regularity) {
      formData.append("regularity", JSON.stringify(IV.regularity));
    } else {
      if (mode === "edit") formData.append("regularity", "none");
    }

    IV.documents.forEach((d) => {
      if (d instanceof File) formData.append("document", d);
    });

    let dataProtocol = null;
    if (IV.protocol) {
      dataProtocol = {};
      dataProtocol.name = IV.protocolName;
      dataProtocol.description = IV.protocolDescription;
      dataProtocol.project = options.project.value.value;
      dataProtocol.secretary = options.secretary.value.value;

      dataProtocol.employees_gencontractor = options.employees_gencontractor.value.map(
        (e) => e.value,
      );
      dataProtocol.employees_customer = options.employees_customer.value.map((e) => e.value);
      dataProtocol.employees_executor = options.employees_executor.value.map((e) => e.value);
    }

    // * создание мероприятия
    if (mode === "add") {
      const resCreateEvent = await dispatch(asyncCreateEvent(formData));
      if (resCreateEvent.error) return;

      // создание протокола
      if (IV.protocol) {
        let taskIds = [];

        // создание задачь, если они есть
        if (infoEvent?.tasks?.length) {
          const tasks = infoEvent?.tasks;

          let formDataTasks = getFormDataTasks(tasks);

          // return console.log(tasks);
          formDataTasks = formDataTasks.map((formDataTask) =>
            dispatch(asyncCreateTask(formDataTask)),
          );

          const responses = await Promise.all(formDataTasks);

          taskIds = responses.filter((task) => !task.error).map((task) => task.payload._id);

          dataProtocol.tasks = taskIds;
          await dispatch(
            asyncCreateProtocol({
              eventId: resCreateEvent.payload.data._id,
              data: dataProtocol,
            }),
          );
        } else {
          await dispatch(
            asyncCreateProtocol({
              eventId: resCreateEvent.payload.data._id,
              data: dataProtocol,
            }),
          );
        }
      }
    } else if (mode === "edit") {
      formData.append(
        "documents",
        JSON.stringify(IV.documents.filter((d) => !(d instanceof File))),
      );
      const resEditEvent = await dispatch(
        asyncEditEvent({
          eventId: infoEvent._id,
          data: formData,
        }),
      );
      if (resEditEvent.error) return;

      // редактирование протокола
      // если протокол удалили и создали новый
      // if (!IV.protocol?._id) {
      //   if (dataProtocol) {
      //     await dispatch(asyncCreateProtocol({ eventId: infoEvent._id, data: dataProtocol }));
      //   }
      //   if (protocolStatus) {
      //     await dispatch(
      //       asyncDeleteProtocolFromEvent({
      //         protocolId: protocolStatus.protocolId,
      //         eventId: infoEvent._id,
      //       }),
      //     );
      //   }
      // }
      // // редактирование протокола
      // else {
      //   // если добавили новый задачи
      //   const tasks = infoEvent?.tasks || [];

      //   if (tasks.length) {
      //     let formDataTasks = getFormDataTasks(tasks.filter((task) => task._id.length !== 24));
      //     formDataTasks = formDataTasks.map((formDataTask) =>
      //       dispatch(asyncCreateTask(formDataTask)),
      //     );

      //     const responses = await Promise.all(formDataTasks);

      //     const taskIds = responses.filter((task) => !task.error).map((task) => task.payload._id);

      //     dataProtocol.tasks = [...(IV.protocol?.tasks || []), ...taskIds];
      //   }

      //   await dispatch(
      //     asyncEditProtocol({
      //       protocolId: IV.protocol._id,
      //       data: dataProtocol,
      //     }),
      //   );
      // }
    }

    changeModalVisible(modalName, false, mode === "edit" ? "prev" : null);
    handleChange && handleChange(mode);
  };

  const onClose = () => {
    changeModalVisible(modalName, false);
    changeModalVisible("clear", "deleteTask");
  };

  const setNewInfoProtocol = async () => {
    if (!infoEvent._id) return;
    const res = await dispatch(asyncGetEvent(infoEvent._id));

    if (res.error || !res?.payload?.data?.protocol) return;

    changeIV("set", { name: "protocol", value: res.payload.data.protocol });
  };

  useEffect(() => {
    if (getInfo("addTask", "data")) {
      getFullInfoTask({
        ...getInfo("addTask", "data"),
        creator: userData,
      }).then((newTask) => {
        if (!newTask) return;
        dispatch(
          setData({
            modalName,
            data: {
              tasks: [...(infoEvent?.tasks || []), newTask],
            },
          }),
        );
        changeModalVisible("clear", "addTask");
      });
    }

    if (getInfo("deleteTask", "data")) {
      const taskId = getInfo("deleteTask", "data")._id;
      changeIV(
        (prev) => ({
          ...prev,
          tasks: prev.tasks?.filter((t) => t._id !== taskId) || [],
        }),
        { name: "protocol" },
      );
      dispatch(
        setData({
          modalName,
          data: {
            tasks: (infoEvent.tasks || infoEvent.protocol.tasks).filter((t) => t._id !== taskId),
          },
        }),
      );
      changeModalVisible("clear", "deleteTask");
    }

    if (getInfo("editSubTask", "data")) {
      getFullInfoTask({
        ...getInfo("editSubTask", "data"),
        creator: userData,
      }).then((newTask) => {
        if (!newTask) return;
        dispatch(
          setData({
            modalName,
            data: {
              tasks: (infoEvent?.tasks || []).map((task) => {
                if (task._id === newTask._id) return newTask;
                return task;
              }),
            },
          }),
        );
        changeModalVisible("clear", "editSubTask");
      });
    }
  }, []);

  useEffect(() => {
    if (getInfo("editProtocol", "data")) {
      setNewInfoProtocol();
    }
  }, [getInfo("editProtocol", "data")]);

  useEffect(() => {
    if (getInfo("addProtocol", "data")) {
      setNewInfoProtocol();
    }
  }, [getInfo("addProtocol", "data")]);

  useEffect(() => {
    if (getInfo("deleteProtocol", "data")) {
      changeIV("set", { name: "protocol", value: null });
    }
  }, [getInfo("deleteProtocol", "data")]);

  return (
    <ModalWrapper
      onClose={onClose}
      title={getModalName(mode, true)}
      btns={
        <div className="d-flex justify-center">
          <Button
            type="submit"
            title={mode === "add" ? "Создать" : "Сохранить"}
            color="blue"
            form={formId}
          />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalEditEvent" id={formId}>
        <div className="modalEditEvent">
          <Input
            placeholder="Введите название мероприятия"
            onChange={changeIV}
            value={IV.name}
            name="name"
            title="Название мероприятия:*"
            validate={checkValidateIV("name")}
          />
          <Textarea
            onChange={changeIV}
            value={IV.description}
            height="170px"
            name="description"
            title="Описание мероприятия:"
            placeholder="Введите описание мероприятия"
            validate={checkValidateIV("description")}
          />
          {/* ------ */}
          <div className="modalEditEvent-grid">
            <div className="modalEditEvent-person">
              <InputSearch
                value={options.author.value}
                options={options.author.options}
                title="Автор:*"
                placeholder="Выберите автора"
                forInput
                handleChange={(data) => changeOptions("author", "value", data)}
                validate={checkValidateOptions("author")}
                optionsForSearch={{ profile: true, my_profile: true }}
              />
            </div>
          </div>
          <div className="modalEditEvent-row">
            <InputSearch
              type="users+companies"
              value={options.employees.value}
              options={options.employees.options}
              placeholder="Выберите участников"
              title="Участники:*"
              forInput
              handleChange={(data) => changeOptions("employees", "value", data)}
              multi
              validate={checkValidateOptions("employees")}
              optionsForSearch={{ profile: true }}
              listModal
            />
          </div>

          <div className="modalEditEvent-grid">
            <div className="modalEditEvent-grid-item">
              <InputDate
                time
                title="Дата начала:"
                value={IV.start}
                onChange={(value) => changeIV("set", { name: "start", value })}
              />
            </div>
            <div className="modalEditEvent-grid-item">
              <InputDate
                time
                title="Дата завершения:"
                value={IV.end}
                onChange={(value) => changeIV("set", { name: "end", value })}
                validate={checkValidateIV("end")}
              />
            </div>
            <div className="modalEditEvent-grid-item adress">
              <Input
                placeholder="Укажите место проведение"
                title="Адрес:"
                name="address"
                value={IV.address}
                onChange={changeIV}
                className="address-input"
              >
                <div className="input-icon">
                  <img src={gpsIcon} alt="gps" />
                </div>
              </Input>
            </div>
            <div className="modalEditEvent-grid-item">
              <NotificationFrequency
                changeIV={changeIV}
                IV={IV}
                notificationFrequencyOptions={options.notificationFrequency.options}
                notificationFrequencyValue={options.notificationFrequency.value}
                changeOptions={(data) => changeOptions("notificationFrequency", "value", data)}
                meeting={true}
              />
            </div>
            <div className="modalEditEvent-grid-item">
              <InputFile
                title="Файл:"
                value={IV.documents}
                placeholder="Прикрепите файлы мерпориятия"
                onChange={(documents) => changeFiles("add", { documents })}
                deleteFile={(i) => changeFiles("delete", { i })}
              />
            </div>
          </div>

          <div className="editInfoTask-regularity">
            <Checkbox
              check={!!IV.regularity}
              onCheck={() => {
                changeIV("set", {
                  name: "regularity",
                  value: IV.regularity ? null : {},
                });
              }}
            />
            <span className="editInfoTask-regularity-title">Сделать мероприятие регулярным </span>
          </div>

          {!!IV.regularity && (
            <RegularityForm changeIVTask={changeIV} IVRegularity={IV.regularity} type="event" />
          )}

          {/* Протокол совещания */}
          <div className="hr" />
          <div className="flex-dir-column gap-20">
            {mode === "edit" && IV.protocol && <ModalInfoProtocol protocolInfo={IV.protocol} />}

            {mode === "add" && IV.protocol && (
              <>
                <ModalEditProtocol
                  IV={IV}
                  changeIV={changeIV}
                  options={optionsForProtocol}
                  changeOptions={changeOptions}
                  checkValidateIV={checkValidateIV}
                  checkValidateOptions={checkValidateOptions}
                  project={options.project.value}
                  mode={mode}
                />

                <Button
                  onClick={() => changeVisibleFormProtocol(false)}
                  color="dark"
                  title="Отмена"
                />
              </>
            )}

            {mode === "add" && IV.protocol && (
              <Button
                onClick={() => {
                  changeModalVisible("deleteProtocol", true, {
                    ...IV.protocol,
                    eventId: infoEvent._id,
                  });
                }}
                color="red"
                title="Удалить"
              />
            )}

            {mode === "add" && !IV.protocol && (
              <Button
                onClick={() => changeVisibleFormProtocol(true)}
                color="dark"
                title="Протокол"
                icon={{ position: "left" }}
              />
            )}

            {mode === "edit" && !IV.protocol && (
              <Button
                onClick={() => changeModalVisible("addProtocol", true, { event: infoEvent })}
                color="dark"
                title="Протокол"
                icon={{ position: "left" }}
              />
            )}

            {/* {mode === "add" && IV.protocol ? (
              <>
                <ModalEditProtocol
                  IV={IV}
                  changeIV={changeIV}
                  options={optionsForProtocol}
                  changeOptions={changeOptions}
                  checkValidateIV={checkValidateIV}
                  checkValidateOptions={checkValidateOptions}
                  project={options.project.value}
                  mode={mode}
                />

                {mode === "edit" ? (
                  <Button
                    onClick={() => {
                      changeVisibleFormProtocol(false);
                      setProtocolStatus({
                        status: "remove",
                        protocolId: IV.protocol._id,
                      });
                    }}
                    color="red"
                    title="Удалить"
                  />
                ) : (
                  <Button
                    onClick={() => changeVisibleFormProtocol(false)}
                    color="dark"
                    title="Отмена"
                  />
                )}
              </>
            ) : (
              <Button
                onClick={() => changeVisibleFormProtocol(true)}
                color="dark"
                title="Протокол"
                icon={{ position: "left" }}
              />
            )} */}
          </div>
          <Screen size={"lg"}>
            <div className="hr" />
            <div className="d-flex justify-center">
              <Button
                type="submit"
                title={mode === "add" ? "Создать" : "Сохранить"}
                color="blue"
                form={formId}
              />
            </div>
          </Screen>
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalEditEvent;
