import React, { useState, useEffect, useId } from "react";
import "./ModalAddToFolder.scss";
import Button from "../Button/Button";
import Select from "../Select/Select";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import axios from "../../axios/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { asyncMoveFiles, asyncCopyFiles } from "../../store/reducers/documentationReduser";
import { useModal } from "../../hooks/useModal";
import FolderStructure from "../FolderStructure/FolderStructure";
import Screen from "../Screen/Screen";

function ModalAddToFolder(props) {
  const formId = useId();

  const { headFolder, fatherFolder, handleChange } = props;

  const { getInfo } = useModal();

  const infoDoc = getInfo("addToFolder");

  const typeFile = infoDoc.data.type === "folder" ? "папку" : "файл";
  const titleModal = infoDoc.editMode
    ? `Переместить ${typeFile} ${infoDoc?.data.name}`
    : `Копировать ${typeFile} ${infoDoc?.data.name}`;

  const dispatch = useDispatch();
  const [structure, setStructure] = useState(null);
  const [activeFolder, setActiveFolder] = useState({ _id: "", name: "" });
  const { changeModalVisible } = useModal();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (infoDoc.editMode) {
      await dispatch(
        asyncMoveFiles({
          fileId: infoDoc.data._id,
          folderId: activeFolder._id,
        })
      );
    } else {
      await dispatch(
        asyncCopyFiles({
          fileId: infoDoc.data._id,
          folderId: activeFolder._id,
        })
      );
    }
    handleChange && handleChange();
    changeModalVisible("addToFolder", false);
  };

  return (
    <ModalWrapper
      minHeight={false}
      onClose={() => changeModalVisible("addToFolder")}
      title={titleModal}
      btns={
        <div className="modalAddToFolder-main-btn-wrapper">
          <Button
            type="submit"
            // disabled={!options.folder.value}
            color="blue"
            title={`${infoDoc.editMode ? "Переместить" : "Копировать"}`}
            form={formId}
          />
        </div>
      }
    >
      <div className="modalAddToFolder">
        <div className="modalAddToFolder-main">
          <form
            onSubmit={onSubmit}
            className="modalAddToFolder-main-form"
            id={formId}
          >
            <FolderStructure
              headFolder={headFolder}
              fatherFolder={fatherFolder}
              structure={structure}
              title={titleModal}
              activeFolder={activeFolder}
              setActiveFolder={setActiveFolder}
            />
            <Screen size={"lg"}>
              <div className="modalAddToFolder-main-btn-wrapper">
                <Button
                  type="submit"
                  // disabled={!options.folder.value}
                  color="blue"
                  title={`${infoDoc.editMode ? "Переместить" : "Копировать"}`}
                  form={formId}
                />
              </div>
            </Screen>
          </form>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalAddToFolder;
