import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";
import "./NotificationFrequency.scss";
import Screen from "../Screen/Screen";
import { is } from "date-fns/locale";
const NotificationFrequency = (props) => {
  const {
    IV,
    changeIV,
    changeOptions,
    notificationFrequencyOptions,
    notificationFrequencyValue,
    isDisabled = false,
    meeting = false,
  } = props;

  return (
    <div className="notificationFrequency">
      <span className="notificationFrequency-title">Уведомления:</span>
      <div className="notificationFrequency-wrapper">
        <div className="notificationFrequency-checbox">
          <Checkbox
            onCheck={() =>
              changeIV("set", {
                name: "notificationEveryDay",
                value: !IV.notificationEveryDay,
              })
            }
            check={IV.notificationEveryDay}
          />
          <span className="notificationFrequency-checbox-text">{`Предупредить ${
            meeting ? "участников" : "исполнителей"
          } за`}</span>
        </div>
        <Select
          options={notificationFrequencyOptions}
          value={notificationFrequencyValue}
          handleChange={changeOptions}
          isSearchable={false}
          menuPlacement="top"
          placeholder="Время"
          forInput
          isDisabled={isDisabled}
          isClearable={false}
        />
      </div>
    </div>
  );
};

export default NotificationFrequency;
