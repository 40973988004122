import { getFormatFIO } from "../../../utils/getFormatFIO";
import { transformForSelect } from "../../../utils/transformForSelect";

export const getValueForOptions = (data, field) => {

  if (!data) {
    if (field === "employees_gencontractor" || field === "employees_customer" || field === "employees_executor") {
      return [];
    }

    return null;

  }


  if (data[field]?.value) return data[field]?.value;

  if (field === "project") {
    if (data && data["project"]?.value) return transformForSelect(data["project"]);
    else if (data && data["project"]?.name) return transformForSelect(data["project"]);
    return null;
  }

  if (field === "employees_gencontractor") {
    if (data["employees_gencontractor"] && Array.isArray(data["employees_gencontractor"])) {
      return data["employees_gencontractor"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_gencontractor"])) {
      return data["protocol"]["employees_gencontractor"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "employees_customer") {
    if (data["employees_customer"] && Array.isArray(data["employees_customer"])) {
      return data["employees_customer"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_customer"])) {
      return data["protocol"]["employees_customer"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "employees_executor") {
    if (data["employees_executor"] && Array.isArray(data["employees_executor"])) {
      return data["employees_executor"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_executor"])) {
      return data["protocol"]["employees_executor"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "secretary") {

    if (data && data["secretary"]?.value) return data["secretary"];

    else if (data && data["secretary"]?.user) {
      return {
        label: `${getFormatFIO(data["secretary"].user)} ${data["secretary"].position.name} ${data["secretary"].company.name
          }`,
        value: data["secretary"]._id,
      };
    }
    return null;

  }

  return null;
};
