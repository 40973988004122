const typesWork = [
  {
    name: "Приемка работы",
    name_en: "acceptance_work",
    _id: "acceptance_work",
  },
  {
    name: "Контроль исполнения",
    name_en: "execution_control",
    _id: "execution_control",
  },
];

export const transformTypeWork = (type) => {
  const data = typesWork.find((c) => c._id === type);

  return data || typesWork[0];
};
