import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./WindowChat.scss";
import { useSelects } from "../../hooks/useSelects";
import { useModal } from "../../hooks/useModal";
import useWebSocket from "react-use-websocket";
import { useDispatch } from "react-redux";
import {
  asyncGetChat,
  asyncGetChats,
  asyncGetMessagesChat,
  asyncReadMessage,
  deleteChat,
  readChat,
  setChat,
} from "../../store/reducers/chatReduser";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import { useSearch } from "../../hooks/useSearch";

class Sender {
  constructor(cb) {
    this.cb = cb;
  }
  isBusy = false;
  ids = [];

  async send(ids) {
    if (this.isBusy) return void this.ids.push(...ids);
    this.isBusy = true;

    const res = await this.cb(ids);

    this.isBusy = false;

    if (res.payload) return { messageIds: this.ids };

    if (this.ids.length) this.send(this.ids.splice(0));
  }
}

const WindowChat = (props) => {
  const { currentChat, changeCurrentChat } = props;

  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const { _id: myId } = useSelector((state) => state.user.userData);
  const { messages: messagesState } = useSelector((state) => state.chats);

  const [messages, setMessages] = useState([]);
  const [usersOnline, setUsersOnline] = useState([]);

  const { selectItems, addItemSelect, clearItems } = useSelects();

  const [sender] = useState(
    new Sender((list) => dispatch(asyncReadMessage({ messageIdList: list }))),
  );

  async function getMessages() {
    if (!currentChat) return;
    await dispatch(asyncGetMessagesChat(currentChat._id));
  }

  const forwardMessage = () => {
    if (selectItems.length)
      changeModalVisible("forward", true, {
        messageIds: selectItems,
        callback: (chatId) => {
          if (chatId === currentChat._id) {
            clearItems();
            getMessages();
          }
        },
      });
  };

  const addMessage = (message) => setMessages((prev) => [...prev, message]);

  const onMessage = async (e) => {
    if (!e.data) return;
    if (e.data[0] !== "{") return;

    const data = JSON.parse(e.data);
    const { event, chatId } = data;

    switch (event) {
      case "addedMessage":
        {
          const res = await dispatch(asyncGetChat(chatId));
          if (res.error) return;
          dispatch(setChat({ chatId, chat: res.payload }));
          await getMessages();
        }
        break;
      case "createdChat":
        {
          dispatch(asyncGetChats());
        }
        break;
      case "deletedChat":
        {
          const { chatToRemove, userId } = data;
          if (chatToRemove === currentChat?._id) {
            if (!userId || userId === myId) {
              changeCurrentChat(null);
              dispatch(deleteChat(chatToRemove));
            }
          }
        }
        break;
      case "clearedChat":
        {
          const { clearChat } = data;
          if (clearChat === currentChat._id) await getMessages();
        }
        break;
      case "addedUser":
        {
          const res = await dispatch(asyncGetChat(chatId));
          if (res.error) return;
          changeCurrentChat(res.payload._id);
          dispatch(setChat({ chatId, chat: res.payload }));
        }
        break;
      case "deletedUser":
        {
          await getMessages();
        }
        break;
      case "userStatus":
        {
          const { userId, isOnline } = data;

          if (isOnline) {
            setUsersOnline((prev) => [...prev, userId]);
          } else {
            setUsersOnline((prev) => prev.filter((u) => u !== userId));
          }
        }
        break;
      default:
        break;
    }
  };

  const onReadMessage = async (list) => {
    const data = await sender.send([list]);

    if (!data?.messageIds) return;

    dispatch(
      readChat({
        chatId: currentChat._id,
      }),
    );
  };

  const token = localStorage.getItem("token");

  const soketUrl = process.env.REACT_APP_SOCKET_URL || "ws://localhost:4011";
  useWebSocket(soketUrl, {
    onOpen: (e) => {
      // toast.success("Соединение восстановлено");
    },
    onClose: (e) => {
      // toast.error("Соединение прервано");
    },
    onMessage,
    queryParams: {
      "x-access-token": token,
    },
  });

  useEffect(() => {
    getMessages();
    clearItems();
  }, [currentChat]);

  useEffect(() => {
    setMessages(messagesState);
  }, [messagesState]);

  // useEffect(() => {
  //   if() getMessages()

  // },[])
  // useEffect(() => {
  //   if (getInfo("addInChat", "data")) {
  //     if (changeModalVisible("addInChat", false)) {
  //       getMessages();
  //     }
  //   }
  // }, [getInfo("addInChat")]);

  return (
    <div className="windowChat">
      {currentChat ? (
        <>
          <Header
            getMessages={getMessages}
            usersOnline={usersOnline}
            myId={myId}
            currentChat={currentChat}
          />
          <Main
            messages={messages}
            selectItems={selectItems}
            addItemSelect={addItemSelect}
            myId={myId}
            currentChat={currentChat}
            onReadMessage={onReadMessage}
            getMessages={getMessages}
          />
          <Footer
            getMessages={getMessages}
            addMessage={addMessage}
            myId={myId}
            currentChat={currentChat}
            forwardMessage={forwardMessage}
            selectItems={selectItems}
          />
        </>
      ) : (
        <div className="windowChat-noChat">
          <span>Выберите чат</span>
        </div>
      )}
    </div>
  );
};

export default WindowChat;
