import React, { useEffect } from "react";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import "./DocumentationProject.scss";
import { useState } from "react";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalAddDoc from "../../../../../components/ModalAddDoc/ModalAddDoc";
import ModalAddToFolder from "../../../../../components/ModalAddToFolder/ModalAddToFolder";
import { useModal } from "../../../../../hooks/useModal";
import ModalAddFolder from "../../../../../components/ModalAddFolder/ModalAddFolder";
import ModalInfoEditFolder from "../../../../../components/ModalInfoEditFolder/ModalInfoEditFolder";
import { useDispatch, useSelector } from "react-redux";
import ModalViewDoc from "../../../../../components/ModalViewDoc/ModalViewDoc";
import {
  asyncDeleteFile,
  asyncGetFolder,
} from "../../../../../store/reducers/documentationReduser";
import DocumentationList from "../../../../../components/DocumentationList/DocumentationList";
import ModalInfoEditFile from "../../../../../components/ModalInfoEditFile/ModalInfoEditFile";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";
import { getTypePages } from "../../../../../utils/getTypePages";

const DocumentationProject = ({ viewCards }) => {
  const project = useSelector((state) => state.projects.project);
  const { folder } = project;

  const dispatch = useDispatch();

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { search, setQueryParam, queries } = useSearch("project:documentation");

  const { visible, types = [] } = useHasPermissions("project:documentation", project);

  const [headFolder, setHeadFolder] = useState(null);

  const [fatherFolder, setFatherFolder] = useState(null);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");

  const [visibleHeader, setVisibleHeader] = useState(false);

  // * получаем папку
  const getFolder = async () => {
    if (!queries.father) return;
    search();
  };

  const getFolderById = async () => {
    const folderId = queries.father;
    const res = await dispatch(asyncGetFolder({ folderId }));
    if (res.error) return;

    setFatherFolder(res.payload);
    setHeadFolder(res.payload);

    return res.payload._id;
  };

  const deleteFolder = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteFolder", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteFolder", false, null);
  };

  const deleteDocument = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteDocument", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteDocument", false, null);
  };

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    (async () => {
      let father = folder._id;

      if (queries.father) {
        const id = await getFolderById();
        if (id) father = id;
      } else {
        setFatherFolder(folder);
        setHeadFolder(folder);
      }

      setQueryParam({ father });
    })();
  }, []);

  let type = types.reverse()[0];
  // const typePages = () => {
  //   if()
  // }
  return (
    <>
      {getVisible() && <div className="bgc-shadow"></div>}
      <div className="documentationProject">
        <div className="documentationProject-searchPanel">
          <SearchPanel
            navigationLeft={{
              visible: visible,
            }}
            buttons={{
              list: [
                {
                  title: "Папка",
                  icon: true,
                  onClick: () => changeModalVisible("addFolder", true),
                },
                {
                  title: "Документ",
                  icon: true,
                  onClick: () => changeModalVisible("addDocument", true, fatherFolder),
                },
              ],
            }}
            inputSearch={{
              visible: true,
              placeholder: "Найти файл или папку по названию",
            }}
            typePages={getTypePages(types)}
          />
        </div>
        <div className="documentationProject-content">
          <DocumentationList
            viewCards={viewCards}
            setFatherFolder={setFatherFolder}
            fatherFolder={fatherFolder}
            visibleHeader={visibleHeader}
            setVisibleHeader={setVisibleHeader}
            handleChange={getFolder}
          />
        </div>

        {/* Добавление документа */}
        {getVisible("addDocument") && (
          <ModalAddDoc
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Добавление папки */}
        {getVisible("addFolder") && (
          <ModalAddFolder
            handleChange={getFolder}
            fatherFolder={fatherFolder}
            headFolder={headFolder}
          />
        )}

        {/* Просмотр/реадктирование папки */}
        {getVisible("infoFolder") && (
          <ModalInfoEditFolder
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Редактирование файла */}
        {getVisible("infoFile") && (
          <ModalInfoEditFile
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Добавление в папку */}
        {getVisible("addToFolder") && (
          <ModalAddToFolder
            headFolder={headFolder}
            fatherFolder={fatherFolder}
            handleChange={getFolder}
          />
        )}

        {/* Удаление документа */}
        {getVisible("deleteDocument") && (
          <ModalDeleteItem
            headerTitle="Удалить документ"
            title="Вы уверены, что хотите удалить этот документ?"
            nameModal="deleteDocument"
            onDelete={deleteDocument}
          />
        )}

        {/* Просмотр документов*/}
        {getVisible("viewDoc") && <ModalViewDoc />}

        {/* Удаление папки */}
        {getVisible("deleteFolder") && (
          <ModalDeleteItem
            headerTitle="Удалить папку"
            title="Вы уверены, что хотите удалить эту папку?"
            nameModal="deleteFolder"
            onDelete={deleteFolder}
          />
        )}
      </div>
    </>
  );
};

export default DocumentationProject;
