import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import { setData } from "../../store/reducers/modalsReduces";
import { asyncGetTask } from "../../store/reducers/tasksReducer";
import Button from "../Button/Button";
import "./SubtaskList.scss";
import { useSelects } from "../../hooks/useSelects";
import Checkbox from "../Checkbox/Checkbox";
import ActiveHeaderTasks from "../ActiveHeaderTasks/ActiveHeaderTasks";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import axios from "../../axios/axios";
import CardRowTask from "../CardRowTask/CardRowTask";
import Screen from "../Screen/Screen";
import TasksGridCardMobile from "../TasksGridCardMobile/TasksGridCardMobile";
import TasksRowHeader from "../TasksRowHeader/TasksRowHeader";

const SubtaskList = (props) => {
  const { title: titleProp, tasks: tasksProp, mode, buttonAddTask = true, fatherId } = props;

  const editMode =
    mode === "add" ||
    mode === "edit" ||
    mode === "copy" ||
    mode === "protocol.add" ||
    mode === "protocol.edit";

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const [visibleHeader, setVisibleHeader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOnSubtaskInfo = async (info, name = "infoTask", editMode = false) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible(name, true, res.payload, editMode);
  };

  const onEvent = async (event, taskId, infoTask) => {
    setLoading(true);
    if (event === "info") {
      if (mode === "copy") {
        toast.error("До сохранения скопированной задачи - просмотр подзадач невозможен");
      }
      // при создании задачи добавили задачу
      else if (mode === "add") {
        toast.error("До создания задачи - просмотр подзадач невозможен");
      }
      // при создании протокола добавили задачу
      else if (mode === "protocol.add") {
        toast.error("До создания протокола - просмотр задач невозможен");
      }
      // при редактирование добавили новую задачу
      else if (mode === "protocol.edit" && taskId.length !== 24) {
        toast.error("До сохранения протокола - просмотр задач невозможен");
      } else {
        handleOnSubtaskInfo({ _id: taskId }, "infoTask");
      }
    }
    // при редактировании подзадачи при добавлении задачи
    else if (event === "edit") {
      if (!infoTask) return;

      // TODO проверить
      if (mode === "protocol.edit" && infoTask._id.length === 24) {
        // changeModalVisible("editTask", true, infoTask);
        handleOnSubtaskInfo(infoTask, "infoTask");
      } else {
        changeModalVisible("editSubTask", true, infoTask);
        // handleOnSubtaskInfo(infoTask, "editSubTask");
      }
    } else if (event === "remove") {
      if (mode === "protocol.add" || mode === "protocol.edit") {
        return changeModalVisible("deleteTask", true, {
          _id: taskId,
          mode: mode,
          task: taskId.length === 24 ? "true" : "fake",
        });
      }
      changeModalVisible("deleteSubTask", true, { _id: taskId });
    }
    setLoading(false);
  };

  const handleOnInfo = async (info) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (!res.error) changeModalVisible("infoTask", true, res.payload);
  };

  const handleOnEdit = async (info, async) => {
    if (async && info?._id?.length === 24) {
      const res = await dispatch(asyncGetTask(info._id));
      if (!res.error) onEvent("edit", false, res.payload);
      return;
    }
    onEvent("edit", false, info);
  };

  const handleOnDelete = (info, deleteSubTask) => {
    if (deleteSubTask) {
      return onEvent("remove", info._id);
    }
    onEvent("remove", info._id);
  };

  // для header
  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(tasks);

  useEffect(() => {
    if (selectItems.length > 0) {
      setVisibleHeader(true);
    } else {
      setVisibleHeader(false);
    }
  }, [selectItems]);

  // получение подзадач в задачах
  const getSubTask = async () => {
    setLoading(true);

    if (mode === "protocol.edit" || mode === "protocol.info") {
      const res = await axios.post(`calendar/get_protocols_tasks/${fatherId}`);
      if (res.error) {
        toast.error("Не удалось получить задачу");
        return setLoading(false);
      }
      setTasks(res.data);
    } else {
      const res = await axios.post(`tasks/get_subtasks/${fatherId}`);
      if (res.error) {
        toast.error("Не удалось получить задачу");
        return setLoading(false);
      }
      setTasks(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (mode === "add" || mode === "protocol.add" || mode === "protocol.edit") {
      setTasks(tasksProp && Array.isArray(tasksProp) ? tasksProp : []);
    } else {
      getSubTask();
    }
  }, [tasksProp, mode]);

  useEffect(() => {
    if (getInfo("deleteSubTask", "data")) {
      if (changeModalVisible("deleteSubTask", false)) {
        getSubTask();
      }
    }
  }, [getInfo("deleteSubTask")]);

  // проверка _id для блокировки множественного взаимодействия
  const checkIds = (arr) => {
    const res = arr.map((el) => {
      if (el._id.length === 24) {
        return true;
      } else {
        return false;
      }
    });
    if (res.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="subtaskList">
      <div className="subtaskList-item">
        <span className="subtaskList-item-title">{titleProp ? titleProp : "Подзадачи"}</span>
        <WrapperLoader loading={loading} chapter={"tasks"} viewCards={"rows"} countRow={1}>
          <Screen size={"lg"}>
            {!!tasks?.length && (
              <>
                {/* <ul
                  className={`subtaskList-header ${
                    (mode === "edit" || mode === "protocol.edit") &&
                    checkIds(tasks)
                      ? "edit"
                      : ""
                  }`}
                >
                  {visibleHeader &&
                    (mode === "edit" || mode === "protocol.edit") && (
                      <ActiveHeaderTasks
                        check={selectItems.length === tasks.length}
                        onCheck={addAllItemSelect}
                        onDelete={() => {
                          changeModalVisible(
                            "deleteSubTask",
                            true,
                            selectItems
                          );
                        }}
                        visible={selectItems.length === 1}
                        selectItems={selectItems}
                        getTasks={getSubTask}
                        tasks={tasks}
                      />
                    )}
                  {editMode &&
                    mode !== "add" &&
                    mode !== "protocol.add" &&
                    checkIds(tasks) && (
                      <li className="subtaskList-header-item numder-item">
                        <Checkbox
                          check={selectItems.length === tasks.length}
                          onCheck={addAllItemSelect}
                        />
                      </li>
                    )}
                  <li className="tasks-row-header-item company-item">
                    <span title="Номер задачи">№:</span>
                  </li>
                  <li className="subtaskList-header-item company-item">
                    <span title="Задача">Задача:</span>
                  </li>
                  <li className="subtaskList-header-item author-item">
                    <span title="Автор">Автор:</span>
                  </li>
                  <li className="subtaskList-header-item performer-item">
                    <span title="Исполнитель">Исполнитель:</span>
                  </li>
                  <Screen size={"xxxl"}>
                    <li className="tasks-row-header-item performer-item">
                      <span title="Компания исполнитель">Компания исполнитель:</span>
                    </li>
                  </Screen>
                  <li className="subtaskList-header-item project-item">
                    <span title="Проект">Проект:</span>
                  </li>
                  <li className="tasks-row-header-item create-item">
                    <span title="Период">Период:</span>
                  </li>
                  <Screen size={"xxxl"}>
                    <li className="tasks-row-header-item deadline-item">
                      <span title="Срок">Дней:</span>
                    </li>
                  </Screen>
                  <li className="subtaskList-header-item status-item">
                    <span title="Статус">Статус</span>
                  </li>
                </ul> */}
                <TasksRowHeader
                  visibleHeader={visibleHeader}
                  edit={(mode === "edit" || mode === "protocol.edit") && checkIds(tasks)}
                  check={selectItems.length === tasks.length}
                  onCheck={addAllItemSelect}
                  onDelete={() => {
                    changeModalVisible("deleteSubTask", true, selectItems);
                  }}
                  visible={selectItems.length === 1}
                  selectItems={selectItems}
                  handleChange={getSubTask}
                  tasks={tasks}
                />
              </>
            )}
            <ul className="subtaskList-list">
              {!!tasks?.length &&
                tasks.map((task) => (
                  <CardRowTask
                    editMode={(mode === "edit" || mode === "protocol.edit") && checkIds(tasks)}
                    btnEdit={
                      mode === "edit" ||
                      mode === "protocol.edit" ||
                      mode === "protocol.add" ||
                      mode === "add"
                    }
                    btnDelete={
                      mode === "edit" ||
                      mode === "protocol.edit" ||
                      mode === "protocol.add" ||
                      mode === "add"
                    }
                    handleOnInfo={
                      mode === "view" ||
                      mode === "protocol.info" ||
                      mode === "protocol.edit" ||
                      mode === "add"
                        ? handleOnInfo
                        : null
                    }
                    handleOnEdit={
                      mode === "view"
                        ? (info) => handleOnEdit(info, true)
                        : (info) => handleOnEdit(info, true)
                    }
                    handleOnDelete={
                      mode === "view" ? (info) => handleOnDelete(info, true) : handleOnDelete
                    }
                    data={task}
                    info={task}
                    key={task._id}
                    onCheck={(taskId) => {
                      addItemSelect(taskId);
                    }}
                    check={(taskId) => selectItems.includes(taskId)}
                  />
                ))}
            </ul>
          </Screen>
          <Screen size={"lg"} down>
            {!!tasks?.length && (
              <div className="subtaskList-gridMobile">
                {tasks.map((task) => (
                  <TasksGridCardMobile info={task} key={task._id} />
                ))}
              </div>
            )}
          </Screen>
        </WrapperLoader>
      </div>
      {editMode && buttonAddTask && (
        <div className="subtaskList-item">
          <Button
            className="subtaskList-item-btn"
            onClick={() => changeModalVisible("addSubTask", true)}
            color="dark"
            title="Подзадача"
            icon={{ position: "left" }}
          />
        </div>
      )}
    </div>
  );
};

export default SubtaskList;
