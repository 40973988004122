import React, { useEffect } from "react";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import "./ModalAddDepartment.scss";
import Input from "../../../../../components/Input/Input";
import Select from "../../../../../components/Select/Select";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import Button from "../../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import {
  asyncCreateDepartament,
  asyncGetStructures,
} from "../../../../../store/reducers/structuresReducer";
import { getForSelect } from "../../../../../utils/getForSelect";
import InputSearch from "../../../../../components/InputSearch/InputSearch";
import { useModal } from "../../../../../hooks/useModal";

const ModalAddDepartment = ({ handleChange }) => {
  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameDepartment: "",
  });

  const info = getInfo("addDepartment", "data");
  const { options, changeOptions } = useChangeOptions({
    director: {
      options: [],
      value: null,
    },
    profiles: {
      options: [],
      value: [],
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("nameDepartment", {
      minHeight: 3,
      maxHeight: 51,
    });

    if (!checkName) return;

    await dispatch(
      asyncCreateDepartament({
        parent_id: info._id,
        name: IV.nameDepartment,
        director: options.director?.value?.value || null,
        profiles: options.profiles.value.map((e) => e.value),
      }),
    );
    handleChange && handleChange("employees:structures");
    changeModalVisible("addDepartment", false);
  };

  return (
    <ModalWrapper
      title={"Добавить отдел"}
      onClose={() => changeModalVisible("addDepartment", false)}
    >
      <div className="modalAddDepartment">
        <form
          onSubmit={onSubmit}
          action="modalAddDepartment__form"
          className="modalAddDepartment__form"
        >
          <div className="modalAddDepartment__content modalAddDepartment-content">
            <Input
              onChange={changeIV}
              value={IV.nameDepartment}
              name="nameDepartment"
              title="Название отдела:*"
              placeholder="Введите название отдела не более 50 символов"
              validate={checkValidateIV("nameDepartment")}
            />
            <div className="modalAddDepartment-content__select modalAddDepartment-content-select">
              <InputSearch
                forInput
                value={options.director.value}
                title="Руководитель отдела:"
                handleChange={(data) => changeOptions("director", "value", data)}
                placeholderSelect="Выберите руководителя"
                optionsForSearch={{ company: info?.company?._id, profile: true }}
              />
            </div>
          </div>
          <div className="modalAddDepartment__content modalAddDepartment-content">
            <InputSearch
              multi={true}
              forInput={true}
              listModal={true}
              value={options.profiles.value}
              title={"Сотрудники:"}
              handleChange={(data) => changeOptions("profiles", "value", data)}
              placeholderSelect="Выберите сотрудника"
              optionsForSearch={{ company: info?.company?._id, profile: true }}
            />
          </div>
          <div className="modalAddDepartment__btn-wrapper">
            <Button type="submit" title="Добавить" color="blue" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ModalAddDepartment;
