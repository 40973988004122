export const getLimitElements = () => {
  let limitElements = 10;
  let width = document.documentElement.clientWidth;
  // if (width >= 2001) {
  //   limitElements = 18;
  // } else if (width >= 1900 && width <= 2000) {
  //   limitElements = 15;
  // } else if (width >= 1300 && width <= 1899) {
  //   limitElements = 15;
  // } else if (width >= 1200 && width <= 1299) {
  //   limitElements = 12;
  // } else
  if (width >= 768 && width <= 1024) {
    limitElements = 9;
  } else if (width <= 767.9) {
    limitElements = 6;
  }
  // else if (width >= 768 && width <= 1024) {
  //   limitElements = 9;
  // }
  // console.log("Limit elements: " + limitElements);
  return limitElements;
};
