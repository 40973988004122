import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import DocItem from "../../../../../components/DocItem/DocItem";
import InputFile from "../../../../../components/InputFile/InputFile";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import { useModal } from "../../../../../hooks/useModal";
import { getFormatFIO } from "../../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../../utils/getFullPath";
import ProjectInfo from "../../../../../components/ModalTask/components/ProjectInfo/ProjectInfo";
import "./ModalInfoDeliveryWork.scss";
import Button from "../../../../../components/Button/Button";
import { asyncEditStatusDeliveryWork } from "../../../../../store/reducers/deliveryWorkReducer";
import { useDispatch } from "react-redux";
import { transformSection } from "../../../../../utils/transformSection";
/**
 * 
 *   { name: "Назначено", name_en: "assigned", _id: "assigned" },
  { name: "Отклонено", name_en: "rejected", _id: "rejected" },
  { name: "В работе", name_en: "atWork", _id: "atWork" },
  { name: "Просрочено", name_en: "overdue", _id: "overdue" },
  { name: "Выполнено", name_en: "completed", _id: "completed" },
  { name: "На проверке", name_en: "onVerification", _id: "onVerification" },
 */

const ModalInfoDeliveryWork = ({ handleChange }) => {
  const dispatch = useDispatch();

  let { _id: userId, role: userRole } = useSelector(
    (state) => state.user.userData
  );
  const { changeModalVisible, getInfo } = useModal();

  // userId = "63a96396a1a6c9c4bcdc8176";

  const infoWork = getInfo("infoDeliveryWork", "data");

  const {
    _id: workId,
    name,
    description,
    call_date,
    author,
    documents,
    engineer,
    engineer_answer,
    project,
    layer,
    status,
    photos,
    type_work,
    section,
  } = infoWork;

  const editStatus = async (status) => {
    let newStatus = null;
    if (userId === engineer._id) {
      if (status === "atWork") newStatus = "atWork";
      if (status === "rejected") newStatus = "rejected";
      if (status === "completed") newStatus = "onVerification";
    } else if (userId === creator._id) {
      if (status === "atWork") newStatus = "atWork";
      if (status === "rejected") newStatus = "rejected";
      if (status === "completed") newStatus = "completed";
    }
    if (newStatus)
      await dispatch(
        asyncEditStatusDeliveryWork({ workId, status: newStatus })
      );

    changeModalVisible("infoDeliveryWork", false);

    handleChange && handleChange();
  };

  const letterVisible = (() => {
    if (engineer_answer?.letter) {
      if (userRole === "admin" || userId === engineer._id) return true;
    }
  })();

  return (
    <ModalWrapper
      title={"Информация о работе"}
      onClose={() => changeModalVisible("infoDeliveryWork")}
    >
      <div className="modalInfoDeliveryWork">
        <div className="modalInfoDeliveryWork-work">
          <div className="modalInfoDeliveryWork-work-wrapper">
            <div className="modalInfoDeliveryWork-work-info">
              <div className="modalInfoDeliveryWork-item">
                <span className="title">Название работы:</span>
                <span className="bold">{name}</span>
              </div>
              <div className="modalInfoDeliveryWork-item">
                <span className="title">Описание работы:</span>
                <span className="bold word-break">{description}</span>
              </div>
              <div className="modalInfoDeliveryWork-work-info-grid">
                <div className="modalInfoDeliveryWork-item-person">
                  <span className="title">Автор: </span>
                  <div className="d-flex-jcsb gap-10">
                    <img
                      className="modalInfoDeliveryWork-item-person-logo"
                      src={getFullPath(author.user.avatar, "avatar")}
                      alt={author.user.surname}
                    />

                    <div className="modalInfoDeliveryWork-item-person-info">
                      <span className="bold">{getFormatFIO(author.user)}</span>
                      <span className="modalInfoDeliveryWork-item-person-info-text">
                        {author.company.name}, {author.position.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="modalInfoDeliveryWork-item-person">
                  <span className="title">Инженер: </span>
                  <div className="d-flex-jcsb gap-10">
                    <img
                      className="modalInfoDeliveryWork-item-person-logo"
                      src={getFullPath(engineer.avatar, "avatar")}
                      // alt={engineer.surname}
                    />
                    <div className="modalInfoDeliveryWork-item-person-info">
                      <span className="bold">
                        {getFormatFIO(engineer.user)}
                      </span>
                      <span className="modalInfoDeliveryWork-item-person-info-text">
                        {engineer.company.name}, {engineer.position.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="modalInfoDeliveryWork-item">
                  <span className="title">Раздел:</span>
                  <span className="bold">
                    {" "}
                    {transformSection(section).name}
                  </span>
                </div>
              </div>
              <div className="modalInfoDeliveryWork-work-info-grid">
                {" "}
                <div className="modalInfoDeliveryWork-item">
                  <span className="title">Файлы:</span>
                  <div className="modalInfoDeliveryWork-fileList customScroll">
                    {documents?.map((file, i) => (
                      <DocItem
                        download
                        shadow
                        index={i}
                        info={file}
                        btnVisible={{ remove: false, download: true }}
                      />
                    ))}
                  </div>
                </div>
                <div className="modalInfoDeliveryWork-item-date-elem">
                  <span className="title">Дата вызова:</span>
                  <span className="bold">
                    {dayjs(call_date).format("DD.MM.YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="modalInfoDeliveryWork-project">
              <ProjectInfo
                showSelects={["type_work"]}
                hideSelects={["priorities"]}
                projectData={{ project, status, layer, photos, type_work }}
              />
            </div>
          </div>
          <div className="modalInfoDeliveryWork-work">
            {!!engineer_answer && (
              <div className="modalInfoDeliveryWork-work-wrapper">
                <div className="modalInfoDeliveryWork-work-engineerAnswer">
                  <spam className="title">
                    Ответ инженера: {engineer_answer ? "" : "-"}
                  </spam>
                  <div className="modalInfoDeliveryWork-work-engineerAnswer-content">
                    <div className="modalInfoDeliveryWork-item">
                      <span className="title">Файлы:</span>
                      <div className="modalInfoDeliveryWork-fileList customScroll">
                        {engineer_answer.documents?.map((file, i) => (
                          <DocItem
                            download
                            shadow
                            index={i}
                            info={file}
                            btnVisible={{ remove: false, download: true }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="modalInfoDeliveryWork-item-date-elem">
                      <span className="title">Дата проверки:</span>
                      <span className="bold">
                        {dayjs(
                          new Date(engineer_answer.date_verification)
                        ).format("DD.MM.YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="modalInfoDeliveryWork-item">
                    <span className="title">Комментарий инженера ССК:</span>
                    <span className="bold">{engineer_answer.description}</span>
                  </div>
                </div>
              </div>
            )}
            {letterVisible && (
              <div className="modalInfoDeliveryWork-work-wrapper">
                <div className="modalInfoDeliveryWork-work-mail">
                  <div className="modalInfoDeliveryWork-item">
                    <span className="title">Название письма:</span>
                    <span className="bold">{engineer_answer.letter.name}</span>
                  </div>

                  <div className="modalInfoDeliveryWork-work-mail-grid">
                    <div className="modalInfoDeliveryWork-item">
                      <span className="title">Проект:</span>
                      <span className="bold">{project?.name || "-"}</span>
                    </div>
                    <div className="modalInfoDeliveryWork-item">
                      <span className="title">Подрядчик:</span>
                      <span className="bold">
                        {engineer_answer.letter.contractor.name}
                      </span>
                    </div>

                    <div className="modalInfoDeliveryWork-item-date-elem">
                      <span className="title">Срок сдачи:</span>
                      <span className="bold">
                        {dayjs(
                          new Date(engineer_answer.letter.delivery_dates)
                        ).format("DD.MM.YYYY")}
                      </span>
                    </div>
                  </div>

                  <div className="modalInfoDeliveryWork-work-mail-grid">
                    <div className="modalInfoDeliveryWork-item-person">
                      <span className="title">Инженер ССК:</span>
                      <div className="d-flex-jcsb gap-10">
                        <img
                          className="modalInfoDeliveryWork-item-person-logo"
                          src={getFullPath(engineer.user.avatar, "avatar")}
                          alt={getFormatFIO(engineer.user)}
                        />
                        <div className="modalInfoDeliveryWork-item-person-info">
                          <span className="bold">
                            {getFormatFIO(engineer.user)}
                          </span>
                          <span className="modalInfoDeliveryWork-item-person-info-text">
                            {engineer.company.name}, {engineer.position.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="modalInfoDeliveryWork-item-person">
                      <span className="title">Руководитель ССК:</span>
                      <div className="d-flex-jcsb gap-10">
                        <img
                          className="modalInfoDeliveryWork-item-person-logo"
                          src={getFullPath(
                            engineer_answer.letter.director_ssk.avatar,
                            "avatar"
                          )}
                          alt={getFormatFIO(
                            engineer_answer.letter.director_ssk.user
                          )}
                        />
                        <div className="modalInfoDeliveryWork-item-person-info">
                          <span className="bold">
                            {getFormatFIO(
                              engineer_answer.letter.director_ssk.user
                            )}
                          </span>
                          <span className="modalInfoDeliveryWork-item-person-info-text">
                            {engineer_answer.letter.director_ssk.company.name},{" "}
                            {engineer_answer.letter.director_ssk.position.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="modalInfoDeliveryWork-item-person">
                      <span className="title">Главный инженер:</span>
                      <div className="d-flex-jcsb gap-10">
                        <img
                          className="modalInfoDeliveryWork-item-person-logo"
                          src={getFullPath(
                            engineer_answer.letter.chief_director.user.avatar,
                            "avatar"
                          )}
                          alt={getFormatFIO(
                            engineer_answer.letter.chief_director.user
                          )}
                        />
                        <div className="modalInfoDeliveryWork-item-person-info">
                          <span className="bold">
                            {getFormatFIO(
                              engineer_answer.letter.chief_director.user
                            )}
                          </span>
                          <span className="modalInfoDeliveryWork-item-person-info-text">
                            {engineer_answer.letter.chief_director.company.name}
                            ,{" "}
                            {
                              engineer_answer.letter.chief_director.position
                                .name
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modalInfoDeliveryWork-work-mail-grid">
                    <div className="modalInfoDeliveryWork-item">
                      <span className="title">Файлы:</span>
                      <div className="modalInfoDeliveryWork-fileList customScroll">
                        {engineer_answer.letter.documents?.map((file, i) => (
                          <DocItem
                            download
                            shadow
                            index={i}
                            info={file}
                            btnVisible={{ remove: false, download: true }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="modalInfoDeliveryWork-item">
                    <span className="title">Какая документация не сдана:</span>
                    <span className="bold">
                      {engineer_answer.letter.description}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modalInfoDeliveryWork-btns">
          {userId === engineer._id && (
            <div className="modalInfoDeliveryWork-btns-engineer">
              {/* Работа назначена */}
              {(status === "assigned" || status === "rejected") && (
                <Button
                  onClick={() => editStatus("atWork")}
                  title="Взять в работу"
                  color="blue"
                />
              )}
              {(status === "assigned" || status === "atWork") && (
                <Button
                  onClick={() => editStatus("rejected")}
                  title="Отклонить"
                  color="red"
                />
              )}

              {/* Работа В работе */}
              {status === "atWork" && (
                <Button
                  onClick={() => editStatus("completed")}
                  title="Выполнить"
                  color="blue"
                />
              )}
            </div>
          )}

          {userId === author._id && userId !== engineer._id && (
            <div className="modalInfoDeliveryWork-btns-creator">
              {/* Работа На проверке */}
              {status === "onVerification" && (
                <Button
                  onClick={() => editStatus("completed")}
                  title="Выполнить"
                  color="blue"
                />
              )}
              {status === "onVerification" && (
                <Button
                  onClick={() => editStatus("rejected")}
                  title="Отклонить"
                  color="red"
                />
              )}

              {/* Работа Отклонена */}
              {status === "rejected" && (
                <Button
                  onClick={() => editStatus("atWork")}
                  title="Отправить в работу"
                  color="blue"
                />
              )}

              {/* Работа Выполнена */}
              {status === "completed" && (
                <Button
                  onClick={() => editStatus("atWork")}
                  title="Отправить в работу"
                  color="blue"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalInfoDeliveryWork;
