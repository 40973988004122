import React from "react";
import "./InputFile.scss";
import DocItem from "../DocItem/DocItem";
import Icons from "../Icons/Icons";

const InputFile = (props) => {
  const { title, onChange, placeholder, value, deleteFile } = props;

  const downloadFile = (e) => {
    const arr = [];
    const fileList = e.target.files;

    for (const key in fileList) {
      if (key === "length" || key === "item") continue;
      arr.push(fileList[key]);
    }
    if (value?.length) {
      return onChange([...value, ...arr]);
    }
    onChange(arr);
  };
  return (
    <div className="inputFile">
      {!!title && <span className="inputFile-title">{title}</span>}
      <div className="inputFile-zone">
        <input onChange={downloadFile} id="file" type="file" multiple />
        <label htmlFor="file" className="inputFile-zone-input">
          <span>{placeholder || "Загрузите файл"}</span>
          <Icons type={"paperIcon"} size={"md"} cursor />
        </label>
        {!!value?.length && (
          <div className="inputFile-zone-list customScroll">
            {value?.map((file, i) => (
              <DocItem
                shadow
                index={i}
                event={{
                  remove: (i) => deleteFile && deleteFile(i),
                }}
                info={file}
                btnVisible={{ remove: true }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFile;
