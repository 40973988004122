import React, { useEffect, useState } from "react";
import { useSelects } from "../../../../../../../../../hooks/useSelects";
import CardRowTask from "../../../../../../../../../components/CardRowTask/CardRowTask";
import "./MeetingMinutesTaskList.scss";
import { useModal } from "../../../../../../../../../hooks/useModal";
import Checkbox from "../../../../../../../../../components/Checkbox/Checkbox";
import ActiveHeaderTasks from "../../../../../../../../../components/ActiveHeaderTasks/ActiveHeaderTasks";
import { useSearch } from "../../../../../../../../../hooks/useSearch";
import Screen from "../../../../../../../../../components/Screen/Screen";
import TasksRowHeader from "../../../../../../../../../components/TasksRowHeader/TasksRowHeader";

const MeetingMinutesTaskList = (props) => {
  const { getTasks, tasks, handleOnEdit, handleOnInfo } = props;

  const { changeModalVisible } = useModal();

  const {} = useSearch();

  const [visibleHeader, setVisibleHeader] = useState(false);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } =
    useSelects(tasks);

  useEffect(() => {
    if (selectItems.length > 0) {
      setVisibleHeader(true);
    } else {
      setVisibleHeader(false);
    }
  }, [selectItems]);

  return (
    <div className="meetingMinutesTaskList-row">
      <div className="meetingMinutesTaskList-row-wrapper">
        <TasksRowHeader
          check={selectItems.length === tasks.length}
          onCheck={addAllItemSelect}
          onDelete={() => changeModalVisible("deleteTask", true, selectItems)}
          visible={selectItems.length === 1}
          selectItems={selectItems}
          handleChange={getTasks}
          tasks={tasks}
          visibleHeader={visibleHeader}
        />
        <ul className="meetingMinutesTaskList-row-cards">
          {tasks &&
            tasks.map((task) => {
              return (
                <CardRowTask
                  info={task}
                  data={task}
                  key={task._id}
                  onCheck={(taskId) => {
                    addItemSelect(taskId);
                  }}
                  handleOnEdit={handleOnEdit}
                  handleOnInfo={handleOnInfo}
                  check={(taskId) => selectItems.includes(taskId)}
                />
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default MeetingMinutesTaskList;
