import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Analytics from "./pages/desktop/Analytics/Analytics";
import AuthPage from "./pages/desktop/AuthPage/AuthPage";
import { useDispatch, useSelector } from "react-redux";
import Projects from "./pages/desktop/Projects/Projects";
import Project from "./pages/desktop/Project/Project";
import Tasks from "./pages/desktop/Tasks/Tasks";
import DeliveryWork from "./pages/desktop/DeliveryWork/DeliveryWork";
import Employees from "./pages/desktop/Employees/Employees";
import Reference from "./pages/desktop/Reference/Reference";
import Profile from "./pages/desktop/Profile/Profile";
import { useEffect, useState } from "react";
import { get_status } from "./store/reducers/statusReducer";
import { get_priorities } from "./store/reducers/prioritiesReducer";
import Calendar from "./pages/desktop/Calendar/Calendar";
import Documentation from "./pages/desktop/Documentation/Documentation";
import Chat from "./pages/desktop/Chat/Chat";
import Notifications from "./pages/desktop/Notifications/Notifications";
import Settings from "./pages/desktop/Settings/Settings";
import { checkIsAuth, asyncGetFullInfo, logout } from "./store/reducers/userReducer";
import { get_regions } from "./store/reducers/regionsReducer";
import { asyncGetCompanyTypes } from "./store/reducers/companiesReducer";
import { getPosition } from "./store/reducers/positionsReducer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useWindowDimensions } from "./hooks/useWindowDimensions";
import Layout from "./components/Layout/Layout";
import AuthPageMobile from "./pages/mobile/AuthPageMobile/AuthPageMobile";
import ProfileMobile from "./pages/mobile/ProfileMobile/ProfileMobile";
import AnalyticsMobile from "./pages/mobile/AnalyticsMobile/AnalyticsMobile";
import ProjectsMobile from "./pages/mobile/ProjectsMobile/ProjectsMobile";
import ProjectMobile from "./pages/mobile/ProjectMobile/ProjectMobile";
import TasksMobile from "./pages/mobile/TasksMobile/TasksMobile";
import CalendarMobile from "./pages/mobile/CalendarMobile/CalendarMobile";
import DeliveryWorkMobile from "./pages/mobile/DeliveryWorkMobile/DeliveryWorkMobile";
import EmployeesMobile from "./pages/mobile/EmployeesMobile/EmployeesMobile";
import ReferenceMobile from "./pages/mobile/ReferenceMobile/ReferenceMobile";
import DocumentationMobile from "./pages/mobile/DocumentationMobile/DocumentationMobile";
import ChatMobile from "./pages/mobile/ChatMobile/ChatMobile";
import NotificationsMobile from "./pages/mobile/NotificationsMobile/NotificationsMobile";
import SettingsMobile from "./pages/mobile/SettingsMobile/SettingsMobile";
import Screen from "./components/Screen/Screen";
import EmployeeInfo from "./pages/desktop/Employees/EmployeeInfo/EmployeeInfo";
import EmployeeMobile from "./pages/mobile/EmployeeMobile/EmployeeMobile";
import { useModal } from "./hooks/useModal";
import TasksKanban from "./pages/desktop/TasksKanban/TasksKanban";
import TasksKanbanMobile from "./pages/mobile/TasksKanbanMobile/TasksKanbanMobile";
import Company from "./pages/desktop/Company/Company";
import CompanyBaseInfo from "./components/CompanyBaseInfo/CompanyBaseInfo";
import CompanyProject from "./components/CompanyProject/CompanyProject";
import CompanyTaskList from "./components/CompanyTaskList/CompanyTaskList";
import CompanyEmployees from "./components/CompanyEmployees/CompanyEmployees";
import CompanyEmployeesList from "./components/CompanyEmployees/components/CompanyEmployeesList/CompanyEmployeesList";
import CompanyProfiles from "./components/CompanyEmployees/components/CompanyProfiles/CompanyProfiles";
import CompanyResponsibles from "./components/CompanyEmployees/components/CompanyResponsibles/CompanyResponsibles";

function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);
  const [isMobile, setIsMobile] = useState(false);
  const { disabled } = useSelector((state) => state.blockScroll);
  const { width } = useWindowDimensions();
  const { getVisible } = useModal();

  const location = useLocation();

  useEffect(() => {
    dispatch(checkIsAuth());
  }, [dispatch]);

  useEffect(() => {
    if (width > 1024) {
      setIsMobile(false);
    } else if (width <= 1024) {
      setIsMobile(true);
    }
  }, [width]);

  useEffect(() => {
    (async () => {
      if (isAuth) {
        const resAuth = await dispatch(asyncGetFullInfo());

        if (resAuth.error) return dispatch(logout());
        dispatch(get_status());
        dispatch(asyncGetCompanyTypes());
        dispatch(get_priorities());
        dispatch(getPosition());
      }
    })();
  }, [isAuth]);

  useEffect(() => {
    if (getVisible()) {
      jivo_destroy();
    } else {
      jivo_init();
    }
  }, [getVisible()]);

  return (
    <div className={`app ${isMobile && disabled ? "app-mobile-fixed" : ""}`}>
      <Routes>
        {!isMobile ? (
          <>
            {isAuth ? (
              <>
                <Route element={<Layout />}>
                  <Route index path="/analytics" element={<Analytics />} />

                  <Route path="/projects/:page" element={<Projects />} />

                  <Route path="/project/:projectId/:chapter" element={<Project />} />
                  <Route path="/project/:projectId/:chapter/:page" element={<Project />} />
                  {/*  */}
                  <Route path="/tasks/:page" element={<Tasks />} />
                  <Route path="/tasksKanban" element={<TasksKanban />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/deliveryWork/:page" element={<DeliveryWork />} />
                  {/*  */}
                  <Route path="/employees/:chapter/:page" element={<Employees />} />
                  <Route path="/employees/:chapter" element={<Employees />} />
                  {/*  */}
                  <Route path="/company/:companyId" element={<Company />}>
                    <Route index={true} element={<Navigate to="baseInfo" />} />
                    <Route path="baseInfo" element={<CompanyBaseInfo />} />
                    <Route path="employees" element={<CompanyEmployees />}>
                      <Route index={true} element={<Navigate to="users/1" />} />
                      <Route path="users/:page" element={<CompanyEmployeesList />} />
                      <Route path="profiles" element={<CompanyProfiles />} />
                      <Route path="responsibles" element={<CompanyResponsibles />} />
                    </Route>
                    <Route path="projects/:page" element={<CompanyProject />} />
                    <Route path="tasks/:page" element={<CompanyTaskList />} />
                  </Route>
                  <Route path="/company/:companyId/:list" element={<Company />} />
                  <Route path="/company/:companyId/:list/:page" element={<Company />} />
                  {/*  */}
                  <Route path="/userInfo/:userId/:list" element={<EmployeeInfo />} />
                  <Route path="/userInfo/:userId/:list/:page" element={<EmployeeInfo />} />
                  {/*  */}

                  <Route path="/documentation/:page" element={<Documentation />} />

                  {/*  */}
                  <Route path="/settings/:chapter" element={<Settings />} />
                  {/*  */}
                  <Route path="/reference/:page" element={<Reference />} />
                  <Route path="/profile" element={<Profile />} />
                  {/*  */}

                  <Route path="/chat/" element={<Chat />} />
                  <Route path="/chat/:chatId" element={<Chat />} />

                  <Route path="/notifications/:page" element={<Notifications />} />
                  <Route path="/exit" element={<Navigate to="/" />} />
                  <Route path="*" element={<Navigate to="analytics" />} />
                </Route>
              </>
            ) : (
              <>
                <Route path="/restore/:id" element={<AuthPage />} />
                <Route path="/auth" element={<AuthPage />} />
                <Route path="/exit" element={<Navigate to="/" />} />
                <Route path="*" element={<AuthPage />} />
              </>
            )}
          </>
        ) : (
          <>
            {isAuth ? (
              <Route>
                <Route path="/analytics" element={<AnalyticsMobile />} />

                <Route path="/projects/:page" element={<ProjectsMobile />} />

                <Route path="/project/:projectId/:chapter" element={<ProjectMobile />} />

                <Route path="/project/:projectId/:chapter/:page" element={<ProjectMobile />} />

                <Route path="/tasks/:page" element={<TasksMobile />} />
                <Route path="/tasksKanban" element={<TasksKanbanMobile />} />

                <Route path="/calendar" element={<CalendarMobile />} />

                <Route path="/deliveryWork/:page" element={<DeliveryWorkMobile />} />

                <Route path="/employees/:chapter/:page" element={<EmployeesMobile />} />

                <Route path="/employees/:chapter" element={<EmployeesMobile />} />

                {/*  */}
                <Route path="/userInfo/:userId/:list" element={<EmployeeMobile />} />
                <Route path="/userInfo/:userId/:list/:page" element={<EmployeeMobile />} />
                {/*  */}

                <Route path="/documentation/:page" element={<DocumentationMobile />} />

                <Route path="/settings/:chapter" element={<SettingsMobile />} />

                <Route path="/reference/:page" element={<ReferenceMobile />} />
                <Route path="/profile" element={<ProfileMobile />} />

                <Route path="/chat" element={<ChatMobile />} />
                <Route path="/chat/:chatId" element={<ChatMobile />} />

                <Route path="/notifications/:page" element={<NotificationsMobile />} />
                <Route path="/exit" element={<Navigate to="/" />} />

                <Route path="*" element={<Navigate to="analytics" />} />
              </Route>
            ) : (
              <>
                <Route path="/restore/:id" element={<AuthPageMobile />} />
                <Route path="/auth" element={<AuthPageMobile />} />
                <Route path="/exit" element={<Navigate to="/" />} />
                <Route path="*" element={<AuthPageMobile />} />
              </>
            )}
          </>
        )}
      </Routes>
      <Screen size={"lg"}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          progressClassName="toastProgress"
          bodyClassName="toastBody"
        />
      </Screen>
      <Screen size={"lg"} down>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Screen>
      {/* {loading && <Loader />} */}
    </div>
  );
}

export default App;
