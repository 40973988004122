import React from "react";
import "./ProjectWorkScheduleAdd.scss";
import Select from "../Select/Select";
import Period from "../Period/Period";
import InputDate from "../InputDate/InputDate";
import Input from "../Input/Input";
import SelectMulti from "../SelectMulti/SelectMulti";
import { useSelector } from "react-redux";
const ProjectWorkScheduleAdd = (props) => {
  const { changeIV, IV, changeOptions, options, checkValidateIV, checkValidateOptions } = props;
  const { work_start, work_end } = useSelector((state) => state.projects.project);

  return (
    <div className="projectWorkScheduleAdd">
      <div className="projectWorkScheduleAdd-wrapper">
        <Input
          placeholder="Введите название графика"
          onChange={changeIV}
          value={IV.name}
          name="name"
          title="Название графика:"
          validate={checkValidateIV("name")}
        />
        <span className="projectWorkScheduleAdd-title">Сроки:</span>
        <div className="projectWorkScheduleAdd-grid-list">
          <div className="projectWorkScheduleAdd-grid-item">
            <InputDate
              value={IV.contractWorkStart}
              onChange={(data) => {
                changeIV("set", { name: "contractWorkStart", value: data });
              }}
              title="Старт работ по контракту:*"
              includeDateIntervals={[{ start: new Date(work_start), end: new Date(work_end) }]}
              withPortal={true}
            />
          </div>
          <div className="projectWorkScheduleAdd-grid-item">
            <InputDate
              value={IV.contractWorkEnd}
              onChange={(data) => {
                changeIV("set", { name: "contractWorkEnd", value: data });
              }}
              title="Завершение работ по контракту:*"
              includeDateIntervals={[{ start: new Date(work_start), end: new Date(work_end) }]}
              withPortal={true}
            />
          </div>

          {/* <div className="projectWorkScheduleAdd-grid-item">
            {" "}
            <Period
              state={IV.periodTimeline}
              handleSelect={(range2) =>
                changeIV("set", { name: "periodTimeline", value: range2 })
              }
              title={"Согласно графику:"}
              withPortal={true}
            />
          </div> */}
        </div>
        <div className="projectWorkScheduleAdd-grid-list">
          <div className="projectWorkScheduleAdd-grid-item">
            <InputDate
              value={IV.chartWorkStart}
              onChange={(data) => {
                changeIV("set", { name: "chartWorkStart", value: data });
              }}
              title="Старт работ по графику:"
              // includeDateIntervals={[
              //   { start: new Date(work_start), end: new Date(work_end) },
              // ]}
              withPortal={true}
            />
          </div>
          <div className="projectWorkScheduleAdd-grid-item">
            <InputDate
              value={IV.chartWorkEnd}
              onChange={(data) => {
                changeIV("set", { name: "contractWorkEnd", value: data });
              }}
              title="Завершение работ по графику:"
              // includeDateIntervals={[
              //   { start: new Date(work_start), end: new Date(work_end) },
              // ]}
              withPortal={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectWorkScheduleAdd;
