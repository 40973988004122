import React, { useEffect, useState } from "react";

const useCreateStatusDay = (data) => {
  const { end, status, completed } = data;
  const [statusDay, setStatusDay] = useState({
    type: "",
    text: "",
    title: "",
  });
  let startDay = new Date();
  let endDay = new Date(end);

  const createStatusDay = () => {
    // Преобразование времени в дни, часы, минуты и секунды

    //  console.log(`${days} days ${hours} hours ${minutes} minutes`);
    if (status !== "completed") {
      let diff = Date.parse(endDay) - Date.parse(startDay);
      let days = Math.floor(diff / (1000 * 3600 * 24));
      let hours = Math.floor((diff / (1000 * 3600)) % 24);
      let minutes = Math.floor((diff / 1000 / 60) % 60);
      if (diff >= 0) {
        setStatusDay({
          type: "atWork",
          text: `Осталось: `,
          day: days > 0 ? `${days}д ${hours}ч` : hours > 0 ? `${hours}ч` : `${minutes}мин`,
          title: `Осталось до дедлайна: ${days}д ${hours}ч ${minutes}мин`,
        });
      } else {
        setStatusDay({
          type: "overdue",
          text: `Просрочено: `,
          day:
            -days > 0 ? `${-days - 1}д ${-hours}ч` : -hours > 0 ? `${-hours}ч` : `${-minutes}мин`,
          title: `Просочено от дедлайна: ${-days - 1}д ${-hours}ч ${-minutes}мин`,
        });
      }
    }
    if (!!completed && status === "completed") {
      let diff = Date.parse(endDay) - Date.parse(data?.completed);
      let days = Math.floor(diff / (1000 * 3600 * 24));
      let hours = Math.floor((diff / (1000 * 3600)) % 24);
      let minutes = Math.floor((diff / 1000 / 60) % 60);

      if (diff >= 0) {
        setStatusDay({
          type: "atWork",
          text: `С опережением: `,
          day: days > 0 ? `${days}д ${hours}ч` : hours > 0 ? `${hours}ч` : `${minutes}мин`,
          title: `Выполнено с опережением: ${days}д ${hours}ч ${minutes}мин`,
        });
      } else {
        setStatusDay({
          type: "overdue",
          text: `Просрочено: `,
          day:
            -days > 0 ? `${-days - 1}д ${-hours}ч` : -hours > 0 ? `${-hours}ч` : `${-minutes}мин`,
          title: `На момент выполнения просочено от дедлайна: ${
            -days - 1
          }д ${-hours}ч ${-minutes}мин`,
        });
      }
    }
  };

  useEffect(() => {
    createStatusDay();
  }, []);
  return { statusDay };
};

export { useCreateStatusDay };
