import { getFormatFIO } from "./getFormatFIO";
import { transformRole } from "./transformRole";
import { transformTypeCompany } from "./transformTypeCompany";
const roles = ["admin", "director", "customer", "executor", "user"];
const typeCompanies = ["gencontractor", "subcontractor", "sontractor", "deleted"];

/* eslint-disable no-unreachable-loop */
const getNestingValue = (obj, str) => {
  str = str.split(".");

  for (let i = 0; i < str.length; i++) {
    if (typeof obj !== "object") return obj;

    return getNestingValue(obj[str[i]], str.filter((el) => el !== str[i]).join(""));
  }

  return obj;
};

export const transformForSelect = (item, additionalField = []) => {
  if (!item) return null;

  if (typeof item !== "object") {
    if (roles.includes(item)) {
      return {
        label: transformRole(item),
        value: item,
      };
    } else if (typeCompanies.includes(item)) {
      return {
        label: transformTypeCompany(item),
        value: item,
      };
    } else {
      return {
        label: item,
        value: item,
      };
    }
  }

  const obj = {
    label: item?.label ? item.label : item?.surname ? getFormatFIO(item) : item.name,
    value: item?.value ? item.value : item._id,
  };

  if (additionalField?.length) {
    for (let i = 0; i < additionalField.length; i++) {
      const field = getNestingValue(item, additionalField[i]);
      obj.label = `${obj.label}`;

      if (field) obj.label += ` (${field})`;
    }
  }

  return obj;
};
