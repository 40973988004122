import React from "react";
import "./AccessInfoRow.scss";
import Icons from "../../../Icons/Icons";

const AccessInfoRow = ({ info, permissions, typeFile }) => {
  const { name, _id } = info;
  const findId = (arr, id) => {
    if (arr?.find((e) => e._id === id)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={`accessInfoRow ${typeFile === "file" ? "file" : ""}`}>
      <div className="accessInfoRow-item name">
        <span className="accessInfoRow-name" title={name}>
          {name}
        </span>
      </div>

      <div
        className="accessInfoRow-item"
        title={
          findId(permissions.view, _id)
            ? "Просмотр разрешен"
            : "Просмотр запрещен"
        }
      >
        {findId(permissions.view, _id) ? (
          <Icons type={"allowedIcon"} size={"xs"} />
        ) : (
          <Icons type={"bannedIcon"} size={"xs"} />
        )}
      </div>
      {typeFile === "folder" && (
        <div
          className="accessInfoRow-item"
          title={
            findId(permissions.add, _id)
              ? "Добавление разрешено"
              : "Добавление запрещено"
          }
        >
          {findId(permissions.add, _id) ? (
            <Icons type={"allowedIcon"} size={"xs"} />
          ) : (
            <Icons type={"bannedIcon"} size={"xs"} />
          )}
        </div>
      )}
      <div
        className="accessInfoRow-item"
        title={
          findId(permissions.edit, _id)
            ? "Редактирование разрешено"
            : "Редактирование запрещено"
        }
      >
        {findId(permissions.edit, _id) ? (
          <Icons type={"allowedIcon"} size={"xs"} />
        ) : (
          <Icons type={"bannedIcon"} size={"xs"} />
        )}
      </div>

      <div
        className="accessInfoRow-item"
        title={
          findId(permissions.copy, _id)
            ? "Копирование разрешено"
            : "Копирование запрещено"
        }
      >
        {findId(permissions.copy, _id) ? (
          <Icons type={"allowedIcon"} size={"xs"} />
        ) : (
          <Icons type={"bannedIcon"} size={"xs"} />
        )}
      </div>
      <div
        className="accessInfoRow-item"
        title={
          findId(permissions.move, _id)
            ? "Перемещение разрешено"
            : "Перемещение запрещено"
        }
      >
        {findId(permissions.move, _id) ? (
          <Icons type={"allowedIcon"} size={"xs"} />
        ) : (
          <Icons type={"bannedIcon"} size={"xs"} />
        )}
      </div>
      <div
        className="accessInfoRow-item"
        title={
          findId(permissions.remove, _id)
            ? "Удаление разрешено"
            : "Удаление запрещено"
        }
      >
        {findId(permissions.remove, _id) ? (
          <Icons type={"allowedIcon"} size={"xs"} />
        ) : (
          <Icons type={"bannedIcon"} size={"xs"} />
        )}
      </div>
    </div>
  );
};

export default AccessInfoRow;
