/* eslint-disable no-lonely-if */
/* eslint-disable no-undefined */

import { useDispatch, useSelector } from "react-redux";
import {
  changeVisible as changeVisibleRedux,
  changePrevModals as changePrevModalsRedux,
  clearModals as clearModalsRedux,
  setData,
} from "../store/reducers/modalsReduces";

// * список модалок, которые не будут записываться в цепочку
const MODAL_NOT_ADD_TO_PREVLIST = [
  "infoWorkSchedule",
  "menu",
  "menuAddDocumenattion",
  "infoEmployee",
  "infoEditEmployee", // mobile
  "eventListMobile",
];

const getModalWhereVisibleTrue = (modals) => {
  let modal = null;
  for (const key in modals) {
    if (modals[key].visible) {
      modal = {
        modalName: key,
        modalData: modals[key],
      };
    }
  }
  return modal;
};

const useModal = () => {
  const modals = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const changeModalVisible = (
    modalName,
    visible,
    data,
    editMode,
    createMode,
    openFromComponent,
  ) => {
    if (typeof modalName === "function") {
      const dataFromCB = modalName(modals[visible]);
      if (!dataFromCB) return;

      return dispatch(
        changeVisibleRedux({
          modalName: visible,
          visible: dataFromCB?.visible || false,
          data: dataFromCB?.data || null,
          editMode: dataFromCB?.editMode || false,
          createMode: dataFromCB?.createMode || false,
          openFromComponent: dataFromCB?.openFromComponent || null,
        }),
      );
    }

    if (modalName === "clear") {
      // visible = название модалки, которую нужно зачистить
      return dispatch(clearModalsRedux({ modalName: visible }));
    }

    if (!modals[modalName]) return;

    // проверка то что открыли модальное окно в модальном окне (образуем цепочку)
    // находим окно, которое сейчас открыто
    const currentModal = getModalWhereVisibleTrue(modals);

    if (data === "prev") data = currentModal.modalData.data;

    if (currentModal && visible && !MODAL_NOT_ADD_TO_PREVLIST.includes(currentModal.modalName)) {
      // закрываем текущее окно, чтобы открыть новое, записываем его в цепочку
      dispatch(
        changeVisibleRedux({
          modalName: currentModal.modalName,
          ...currentModal.modalData,
          visible: false,
        }),
      );

      dispatch(
        changePrevModalsRedux({
          modalName: currentModal.modalName,
          modalData: { ...currentModal.modalData, visible: false },
          eventName: "add",
        }),
      );
    }

    if (visible === undefined) visible = !modals[modalName]?.visible;

    dispatch(
      changeVisibleRedux({
        modalName,
        visible,
        data: data || null,
        editMode: editMode || false,
        createMode: createMode || false,
        openFromComponent: openFromComponent || null,
      }),
    );

    if (modals.prevModals.length && !visible) {
      const lastModal = modals.prevModals.at(-1);
      // удаляем из цепочки
      dispatch(
        changePrevModalsRedux({
          modalName: lastModal.modalName,
          eventName: "remove",
        }),
      );
      // открываем последнюю модалку в цепочке
      dispatch(
        changeVisibleRedux({
          modalName: lastModal.modalName,
          visible: true,
          data: lastModal.data.data,
          editMode: lastModal.data.editMode,
          createMode: lastModal.data.createMode,
          openFromComponent: lastModal.data.openFromComponent,
        }),
      );
    }

    // удаление модалки из intermediateSavingList если все модалки закрыты
    const modalVisibles = [];

    for (const key in modals) {
      if (key === modalName) continue;
      modalVisibles.push(modals[key].visible);
    }

    if (!modals.prevModals.length && !visible && modalVisibles.every((m) => !m))
      dispatch(clearModalsRedux());
  };

  const getInfo = (modalName, filed) => {
    if (!modals[modalName]) return null;
    if (filed) {
      return modals[modalName][filed];
    }
    return modals[modalName];
  };

  const getVisible = (modalName, options) => {
    if (!modalName) {
      let result = false;
      for (const key in modals) {
        if (options?.without && Array.isArray(options?.without)) {
          if (modals[key].visible && !options?.without.includes(key)) {
            result = true;
          }
        } else {
          if (modals[key].visible) {
            result = true;
          }
        }
      }
      return result;
    }

    if (!modals[modalName]) return false;

    return modals[modalName].visible;
  };

  const intermediateSaving = (fn, modalName) => {
    // для useChangeIV
    if (fn?.changeIV) {
      const { IV } = fn;
      dispatch(
        setData({
          modalName,
          data: IV,
        }),
      );
    }
    // для useChangeOptions
    if (fn?.changeOptions) {
      const { options } = fn;
      dispatch(
        setData({
          modalName,
          data: options,
        }),
      );
    }
    return fn;
  };

  return {
    modals,
    changeModalVisible,
    getInfo,
    getVisible,
    intermediateSaving,
  };
};

export { useModal };
