import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const async_get_all_employees = createAsyncThunk(
  "employees/async_get_all_employees",
  async () => {
    try {
      const res = await axios.get("employees/getAll");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const employeesReducer = createSlice({
  name: "employees",
  initialState: {
    employees: [],
    employee: null,
    edit: false,
    loading: false,
  },
  reducers: {
    set_employees: (state, action) => {
      const { employees } = action.payload;
      state.employees = employees;
    },
    set_employee: (state, action) => {
      const { employee } = action.payload;
      state.employee = employee;
    },
    set_edit_employee_mode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: {
    [async_get_all_employees.pending]: (state, action) => {
      state.loading = true;
    },
    [async_get_all_employees.fulfilled]: (state, action) => {
      const { employees } = action.payload;
      state.employees = employees;
      state.loading = false;
    },
  },
});

export const { set_employee, set_edit_employee_mode } =
  employeesReducer.actions;

export default employeesReducer.reducer;
