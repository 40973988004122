import React, { useState, useEffect, useId } from "react";
import { useChangeIV } from "../../hooks/useChangeIV";
import Input from "../Input/Input";
import "./ModalInfoEditFolder.scss";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useDispatch } from "react-redux";
import { asyncGetPermissions } from "../../store/reducers/documentationReduser";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { asyncEditFile } from "../../store/reducers/documentationReduser";
import { getForSelect } from "../../utils/getForSelect";
import { useModal } from "../../hooks/useModal";
import AccessInfo from "../AccessInfo/AccessInfo";
import AccessEdit from "../AccessEdit/AccessEdit";
import { createArray } from "../../utils/createArray";
import { formatLabel } from "../../utils/formatLabel";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import Screen from "../Screen/Screen";

const ModalInfoEditFolder = (props) => {
  const { fatherFolder, handleChange, headFolder } = props;

  const { changeModalVisible, getInfo } = useModal();

  const info = getInfo("infoFolder", "data");
  const edit = getInfo("infoFolder", "editMode");
  const formId = useId();

  const dispatch = useDispatch();

  const [permissionsListСompanies, setPermissionsListСompanies] = useState({}); // все доступные компании
  const [permissionsListProfiles, setPermissionsListProfiles] = useState({}); // все доступные компании

  const [permissionsСompanies, setPermissionsСompanies] = useState({}); // активные доступы
  const [permissionsProfiles, setPermissionsProfiles] = useState({});
  const [loading, setLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: info?.name,
  });

  const titleModal = edit ? "Редактировать папку" : "Информация о папке";

  const getPermissions = async (type = "child", id) => {
    setLoading(true);
    const res = await dispatch(asyncGetPermissions({ fileId: id }));
    if (res.error) return setLoading(false);
    const companiesAccesses = res.payload.companies_accesses;
    const profilesAccesses = res.payload.profiles_accesses;

    if (type === "father") {
      setPermissionsListСompanies({
        view: companiesAccesses.view || [],
        add: companiesAccesses.add || [],
        edit: companiesAccesses.edit || [],
        copy: companiesAccesses.copy || [],
        move: companiesAccesses.move || [],
        remove: companiesAccesses.remove || [],
      });
      setPermissionsListProfiles({
        view:
          profilesAccesses.view.map((u) => ({
            name: formatLabel(u),
            _id: u._id,
          })) || [],
        add: profilesAccesses.add || [],
        edit: profilesAccesses.edit || [],
        copy: profilesAccesses.copy || [],
        move: profilesAccesses.move || [],
        remove: profilesAccesses.remove || [],
      });
    } else {
      setPermissionsСompanies({
        view: companiesAccesses.view || [],
        add: companiesAccesses.add || [],
        edit: companiesAccesses.edit || [],
        copy: companiesAccesses.copy || [],
        move: companiesAccesses.move || [],
        remove: companiesAccesses.remove || [],
      });
      setPermissionsProfiles({
        view:
          profilesAccesses.view.map((u) => ({
            name: formatLabel(u),
            _id: u._id,
          })) || [],
        add: profilesAccesses.add || [],
        edit: profilesAccesses.edit || [],
        copy: profilesAccesses.copy || [],
        move: profilesAccesses.move || [],
        remove: profilesAccesses.remove || [],
      });
    }
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3, maxHeight: 50 })) return;
    setLoading(true);

    await dispatch(
      asyncEditFile({
        fileId: info._id,
        data: {
          name: IV.name,
          companies_accesses: {
            view: createArray(permissionsСompanies.view, "_id"),
            add: createArray(permissionsСompanies.add, "_id"),
            edit: createArray(permissionsСompanies.edit, "_id"),
            copy: createArray(permissionsСompanies.copy, "_id"),
            move: createArray(permissionsСompanies.move, "_id"),
            remove: createArray(permissionsСompanies.remove, "_id"),
          },
          profiles_accesses: {
            view: createArray(permissionsProfiles.view, "_id"),
            add: createArray(permissionsProfiles.add, "_id"),
            edit: createArray(permissionsProfiles.edit, "_id"),
            copy: createArray(permissionsProfiles.copy, "_id"),
            move: createArray(permissionsProfiles.move, "_id"),
            remove: createArray(permissionsProfiles.remove, "_id"),
          },
        },
      })
    );

    handleChange && handleChange();
    setLoading(false);
    changeModalVisible("infoFolder", false);
  };

  useEffect(() => {
    getPermissions("child", info._id);
    if (!fatherFolder) return;
    if (fatherFolder) getPermissions("father", fatherFolder._id);
  }, []);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("infoFolder", false)}
      title={titleModal}
      minHeight={false}
      btns={
        edit ? (
          <div className="d-flex justify-center">
            <Button
              disabled={IV.name?.length <= 3}
              type="submit"
              title="Сохранить"
              color="blue"
              loading={loading}
              form={formId}
            />
          </div>
        ) : null
      }
    >
      {edit ? (
        <form
          onSubmit={onSubmit}
          className={`modalInfoEditFolder ${edit ? "edit" : ""} `}
          id={formId}
        >
          <div className="modalInfoEditFolder-main">
            <Input
              title="Папка документов:"
              placeholder="Введите название папки"
              name="name"
              value={IV.name}
              onChange={changeIV}
              validate={checkValidateIV("name")}
            />
            <div className="hr"></div>
            <span className="modalInfoEditFolder-main-title">Доступы:</span>
            <WrapperLoader loading={loading} chapter={"permissionsFolderEdit"}>
              <AccessEdit
                permissionsList={permissionsListСompanies}
                permissions={permissionsСompanies}
                setPermissions={setPermissionsСompanies}
                typePermissions={"company"}
                fatherFolder={fatherFolder}
                searchProfile={fatherFolder?._id === headFolder?._id}
              />
              <AccessEdit
                permissionsList={permissionsListProfiles}
                permissions={permissionsProfiles}
                setPermissions={setPermissionsProfiles}
                typePermissions={"profile"}
                fatherFolder={fatherFolder}
                searchProfile={fatherFolder?._id === headFolder?._id}
              />
            </WrapperLoader>
            <Screen size={"lg"}>
              <div className="d-flex justify-center">
                <Button
                  disabled={IV.name?.length <= 3}
                  type="submit"
                  title="Сохранить"
                  color="blue"
                  loading={loading}
                  form={formId}
                />
              </div>
            </Screen>
          </div>
        </form>
      ) : (
        <div className="modalInfoEditFolder-info">
          <div className="modalInfoEditFolder-info-name">
            <span className="modalInfoEditFolder-info-name-title">Папка документов:</span>
            <span className="modalInfoEditFolder-info-name-text">{info?.name}</span>
          </div>
          <div className="hr"></div>
          <span className="modalInfoEditFolder-info-title">Доступы:</span>
          <WrapperLoader loading={loading} chapter={"permissionsFolder"}>
            <AccessInfo permissions={permissionsСompanies} typePermissions={"company"} />
            <AccessInfo permissions={permissionsProfiles} typePermissions={"profile"} />
          </WrapperLoader>
        </div>
      )}
    </ModalWrapper>
  );
};

export default ModalInfoEditFolder;
