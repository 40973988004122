import React from "react";
import Icons from "../Icons/Icons";
import "./CardGridHeader.scss";
import Screen from "../Screen/Screen";

const CardGridHeader = ({ title, text, onClick, styleText }) => {
  return (
    <div className="cardGridHeader">
      <div className="cardGridHeader-info">
        <span className="cardGridHeader-info-title">{title}</span>
        {styleText ? (
          <span className="cardGridHeader-info-text" style={styleText}>
            {text}
          </span>
        ) : (
          <span className="cardGridHeader-info-text">{text}</span>
        )}
      </div>
      <div className="cardGridHeader-btn">
        {" "}
        <Screen size={"lg"}>
          <Icons
            type={"menu"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
            onClick={onClick}
          />
        </Screen>
        <Screen size={"lg"} down>
          <Icons
            type={"menu"}
            size={"m-md"}
            cursor
            title={{ visible: true, text: "" }}
            onClick={onClick}
          />
        </Screen>
      </div>
    </div>
  );
};

export default CardGridHeader;
