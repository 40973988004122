import React from "react";
import { useState, useRef } from "react";
import ProjectWorkScheduleInfo from "../ProjectWorkScheduleInfo/ProjectWorkScheduleInfo";
import ProjectWorkScheduleColList from "./components/ProjectWorkScheduleColList/ProjectWorkScheduleColList";
import settingIcon from "../../assets/icons/setting-white.svg";
import "./ProjectWorkScheduleCol.scss";
// import Select from "../../../../../../../components/Select/Select";

// import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatDate } from "../../utils/getFormatDate";
import dayjs from "dayjs";
import SearchPanel from "../SearchPanel/SearchPanel";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export default function ProjectWorkScheduleCol({ changeModalVisible, edit, data, tasks }) {
  const [menuImport, setMenuImport] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuImport(false));

  const [workItems, setWorkItems] = useState([]);

  const addWorkInItems = (id) => {
    setWorkItems((prev) => {
      if (prev.includes(id)) return prev.filter((el) => el !== id);
      else return [...prev, id];
    });
  };

  const numberPeopleTakingPart = 0;
  // !!director + !!responsible + observers.length + additionalExecutors.length;

  return (
    <div className="projectWorkScheduleCol">
      {/* <div className="projectWorkScheduleCol-searchPanel">
        <SearchPanel
          inputSearch={{ visible: true, placeholder: "Найти этап" }}
        />
      </div> */}
      <Screen size={"lg"}>
        <ProjectWorkScheduleInfo edit={edit} data={data} />
      </Screen>
      <div className="projectWorkScheduleCol-list">
        <div className="projectWorkScheduleCol-list-header">
          <span className="projectWorkScheduleCol-title">График:</span>
          <div className="ganttChartHeader-left">
            {/* <div className="ganttChartHeader-setting">
              <div
                onClick={() => setMenuImport((prev) => !prev)}
                className="ganttChartHeader-btn setting"
              >
                <img src={settingIcon} alt="setting" />
              </div>
              {menuImport && (
                <div ref={ref} className="ganttChartHeader-setting-menuImport">
                  <span>Экспорт в PDF</span>
                  <span>Экспорт в EXCEL</span>
                </div>
              )}
            </div> */}
            {edit && (
              <div
                onClick={() => changeModalVisible("chooseStageOrWork", true)}
                className="ganttChartHeader-btn ganttChartHeader-plus-btn"
              >
                <Icons
                  type={"plus"}
                  size={"lg"}
                  color={"white"}
                  title={{ text: "Добавить этап/работу", visible: true }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="projectWorkScheduleCol-list-stages">
          <ProjectWorkScheduleColList tasks={tasks} edit={edit} />
        </div>
      </div>
    </div>
  );
}
