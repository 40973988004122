import { ResponsivePie } from "@nivo/pie";
const ChartPie = ({ data, onClick, enableArcLinkLabels = true, margin, fontSize, colors }) => {
  return (
    // "#db1f35",

    <ResponsivePie
      colors={colors ? colors : ["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"]}
      data={data}
      margin={margin ? margin : { top: 20, right: 80, bottom: 20, left: 80 }}
      innerRadius={0.65}
      key={["value"]}
      indexBy="title"
      padAngle={1}
      cornerRadius={10}
      enableArcLinkLabels={enableArcLinkLabels}
      // height={280}
      fit={false}
      activeOuterRadiusOffset={10}
      arcLinkLabelsDiagonalLength={5} //полоска от кружка
      arcLinkLabelsStraightLength={7} //полоска к tittle
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.4"]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      // arcLabelsTextColor={{
      //   from: "color",
      //   modifiers: [["brighter", "10"]],
      // }}
      arcLabelsTextColor="#ffffff"
      arcLabelsTextSize={10}
      legends={[]}
      onClick={(e) => onClick && onClick(e)}
      theme={{ labels: { text: { fontSize: fontSize ? fontSize : 10, fontWeight: 600 } } }}
    />
  );
};

export default ChartPie;
