import React, { useState } from "react";
import "./TaskListTable.scss";
import TaskListTableItem from "./components/TaskListTableItem/TaskListTableItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TaskListTable = ({
  tasks,
  rowHeight,
  onExpanderClick = () => {},
  showTaskSection = () => {},
  handleOnDragEnd = () => {},
  edit,
}) => {
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="taskListTable">
          {(provided) => (
            <ul
              className="taskListTable"
              {...provided.droppableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {tasks.map((task, index) => {
                return (
                  <Draggable key={task._id} draggableId={task._id} index={index}>
                    {(provided) => {
                      let positiomMenu = "top";
                      if (index > tasks.length - 4) {
                        positiomMenu = "bottom";
                      }
                      return (
                        <TaskListTableItem
                          key={`${task.id}row`}
                          task={task}
                          showTaskSection={showTaskSection}
                          rowHeight={rowHeight}
                          onExpanderClick={onExpanderClick}
                          provided={provided}
                          positiomMenu={positiomMenu}
                          edit={edit}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default TaskListTable;
