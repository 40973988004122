import React, { useState, useRef, useEffect } from "react";
import "./Period.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

function Period({ dev, title, handleSelect, state, withPortal = false }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const devCustom = dev?.join(" ");

  const [myRef, setMyRef] = useState(false);

  const closeCalendar = () => {
    myRef.setOpen(false);
  };
  const openCalendar = () => {
    myRef.setOpen(true);
  };

  useEffect(() => {
    if (Array.isArray(state) && state.length) {
      setDateRange(state);
    }
  }, [state]);

  return (
    <div className={`period ${devCustom || ""}`}>
      <span>{title ? title : `Период:`}</span>
      <div className={`period-input ${devCustom || ""}`}>
        <DatePicker
          locale={ru}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          showMonthDropdown
          showYearDropdown
          placeholderText={"Выберите период"}
          ref={(r) => {
            setMyRef(r);
          }}
          onChange={handleSelect}
          withPortal={withPortal}
          dateFormat={"dd.MM.yyyy"}
        >
          <div onClick={closeCalendar} className="inputDate-input-close">
            <Screen size={"lg"}>
              <Icons type={"crossBlack"} size={"xs"} cursor />
            </Screen>
            <Screen size={"lg"} down>
              <Icons type={"crossBlack"} size={"md"} cursor />
            </Screen>
          </div>
        </DatePicker>
        <div
          onClick={openCalendar}
          className={`period-icon ${devCustom || ""}`}
        >
          <Screen size={"lg"}>
            <Icons type={"dateIcon"} size={"md"} cursor />
          </Screen>
          <Screen size={"lg"} down>
            <Icons type={"dateIcon"} size={"md"} color={"grey"} />
          </Screen>
        </div>
      </div>
    </div>
  );
}

export default Period;
