import React, { useState, useEffect } from "react";
import "./TooltipContent.scss";

const TooltipContent = ({ task }) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const deltaOverdue = today.getTime() - task.end.getTime(); // дельта между сегодняшним дом и датой завершения
  const overdueTask = Math.floor(deltaOverdue / 1000 / 60 / 60 / 24);

  const datasTask =
    task.start.getDate() +
    "." +
    (task.start.getMonth() + 1) +
    "." +
    task.start.getFullYear() +
    " г. - " +
    task.end.getDate() +
    "." +
    (task.end.getMonth() + 1) +
    "." +
    task.end.getFullYear() +
    " г.";

  return (
    <div className="tooltipContent">
      <div className="tooltipContent-header">
        <h5 className="toltipContent-header-title">{task.name}</h5>
        <span className="toltipContent-header-date">{datasTask}</span>
      </div>
      <div className="tooltipContent-info">
        {task.end.getTime() - task.start.getTime() !== 0 && (
          <div className="tooltipContent-info-item">
            <span className="tooltipContent-info-item-title">Продолжительность:</span>
            <span className="tooltipContent-info-item-text">
              {((task.end.getTime() - task.start.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0) +
                `д`}
            </span>
          </div>
        )}
        {!!task.progress && (
          <div className="tooltipContent-info-item">
            <span className="tooltipContent-info-item-title">Прогресс:</span>
            <span className="tooltipContent-info-item-text">{task.progress + " %"}</span>
          </div>
        )}
        {task.progress !== 100 && overdueTask !== 0 && overdueTask > 0 && (
          <div className="tooltipContent-info-item">
            <span className="tooltipContent-info-item-title">Просрочено дней:</span>
            <span className="tooltipContent-info-item-text overdueTask">{overdueTask + " д"}</span>
          </div>
        )}
        {task?.executor && (
          <div className="tooltipContent-info-item">
            <span className="tooltipContent-info-item-title">Подрядчик:</span>
            <span className="tooltipContent-info-item-text ">{task?.executor?.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default TooltipContent;
