import React, { useState } from "react";
import { getFormatDate } from "../../utils/getFormatDate";
import { getFormatTime } from "../../utils/getFormatTime";
import "./HistoryChanges.scss";
import Icons from "../Icons/Icons";

const HistoryChanges = ({ history }) => {
  const [activeTab, setActiveTab] = useState(false);

  const reverseHistory = [...(history || [])]?.reverse();

  return (
    <div className="historyChanges">
      <div className={`historyChanges-title`} onClick={() => setActiveTab(!activeTab)}>
        <div className="historyChanges-title-content">
          <Icons type={"history"} size={"md"} />
          <span className="historyChanges-title-text">История изменений</span>
        </div>

        <div className="historyChanges-title-btn">
          <Icons
            type={"arrowDown"}
            size={"md"}
            cursor
            className={`historyChanges-title-icon ${
              activeTab ? "historyChanges-title-icon-rotate" : ""
            }`}
          />
        </div>
      </div>
      {activeTab && (
        <ul className="historyChanges-list">
          {reverseHistory?.map((history, i) => (
            <li className="historyChanges-item" key={i}>
              <span className="historyChanges-item-number"> {i + 1}. </span>

              <span className="historyChanges-item-date"> {getFormatDate(history.createdAt)}</span>
              <span className="historyChanges-item-time">{getFormatTime(history.createdAt)}</span>

              <span className="historyChanges-item-span">
                <span>{history.title}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HistoryChanges;
