import React from "react";
import Icons from "../../../Icons/Icons";
import "./AccessInfoEditMobileCardElem.scss";
import СheckboxSwitch from "../../../СheckboxSwitch/СheckboxSwitch";

const AccessInfoMobileCardElem = ({ access, title, edit, check, onCheck }) => {
  return (
    <div className={`accessInfoMobileCardElem ${edit ? "edit" : ""}`}>
      {access ? (
        <Icons type={"allowedIcon"} size={"xs"} />
      ) : (
        <Icons type={"bannedIcon"} size={"xs"} />
      )}
      <span className="accessInfoMobileCardElem-text">
        {title} {access ? " разрешено" : " запрещено"}
      </span>
      {edit && <СheckboxSwitch check={check} onCheck={onCheck} />}
    </div>
  );
};

export default AccessInfoMobileCardElem;
