import React from "react";
import "./ModalParticipant.scss";
import { getFormatFIO } from "../../utils/getFormatFIO";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import Button from "../Button/Button";
import ListModal from "../../components/ListModal/ListModal";
import { transformRole } from "../../utils/transformRole";
import { useModal } from "../../hooks/useModal";
import { getFormatDate } from "../../utils/getFormatDate";
import { getFullPath } from "../../utils/getFullPath";

function ModalParticipant(props) {
  const { edit } = props;

  const { changeModalVisible, getInfo } = useModal();

  const info = getInfo("infoParticipant", "data");

  const { position, departments, region, projects, company, user, date_of_birth } = info;
  const { role, phone, email, avatar, surname, name, patronymic } = user;

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("infoParticipant")}
      title="Информация об участнике"
    >
      <form className="modalParticipant">
        <div className="modalParticipant-main">
          <div className="modalParticipant-main-info">
            <div className="modalParticipant-main-basicInfo">
              <span className="modalParticipant-main-basicInfo-title">Основная информация:</span>
              <div className="modalParticipant-main-basicInfo-wrapper">
                <div className="modalParticipant-main-basicInfo-content">
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Фамилия:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {surname}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Имя:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {name}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Отчество:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {patronymic || ""}
                    </span>
                  </div>

                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Компания:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {company?.name}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Отдел:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {departments?.map((d) => d.name).join(", ")}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Должность:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {position.name}
                    </span>
                  </div>

                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Email:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {email}
                    </span>
                  </div>

                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Телефон:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {phone}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Город:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {/* {region.name || ""} */}
                    </span>
                  </div>
                  <div className="modalParticipant-main-basicInfo-content-col">
                    <span className="modalParticipant-main-basicInfo-content-col-item-title">
                      Дата рождения:
                    </span>
                    <span className="modalParticipant-main-basicInfo-content-col-item-value">
                      {getFormatDate(date_of_birth) || ""}
                    </span>
                  </div>
                </div>
                <div className="modalParticipant-main-avatar">
                  <span className="modalParticipant-main-avatar-title">Фотография:</span>
                  <img
                    className="modalInfoUser-avatar-img"
                    src={getFullPath(avatar, "noImage")}
                    alt={getFormatFIO(user)}
                  />
                </div>
              </div>
            </div>
            <div className="hr"></div>
            <div className="modalParticipant-main-projects">
              <ListModal
                title={"Проекты:"}
                type={"projects"}
                info={projects || []}
                infoList={projects}
                onClickInfo={(info) => {
                  changeModalVisible("infoProject", true, info);
                }}
              />
            </div>
          </div>

          {edit && (
            <div className="d-flex justify-center">
              <Button type="submit" color="blue" title="Сохранить" />
            </div>
          )}
        </div>
      </form>
    </ModalWrapper>
  );
}

export default ModalParticipant;
