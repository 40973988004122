import React from "react";
import "./SettingElementCard.scss";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";

const SettingElementCard = ({ info, onDelete, edit = false, onEdit }) => {
  const { name, protection } = info;
  return (
    <div className="settingElementCard">
      <span className="settingElementCard-text" title={name}>
        {name}
      </span>
      <div className="settingElementCard-btns">
        {protection ? (
          <div className="settingElementCard-btn-lock">
            <Screen size={"lg"}>
              <Icons
                type={"lock"}
                size={"xs"}
                color={"grey"}
                title={{ visible: true, text: "Удалить невозможно" }}
              />
            </Screen>
            <Screen size={"lg"} down>
              <Icons
                type={"lock"}
                size={"md"}
                color={"grey"}
                title={{ visible: true, text: "Удалить невозможно" }}
              />
            </Screen>
          </div>
        ) : (
          <>
            {edit && (
              <div className="settingElementCard-btn">
                <Screen size={"lg"}>
                  <Icons
                    onClick={onEdit}
                    type={"edit"}
                    size={"xs"}
                    cursor
                    title={{ visible: true, text: "Удалить" }}
                  />
                </Screen>
                <Screen size={"lg"} down>
                  <Icons
                    onClick={onEdit}
                    type={"edit"}
                    size={"m-md"}
                    cursor
                    title={{ visible: true, text: "Удалить" }}
                  />
                </Screen>
              </div>
            )}
            <div className="settingElementCard-btn">
              <Screen size={"lg"}>
                <Icons
                  onClick={onDelete}
                  type={"remove"}
                  size={"xs"}
                  cursor
                  title={{ visible: true, text: "Удалить" }}
                />
              </Screen>
              <Screen size={"lg"} down>
                <Icons
                  onClick={onDelete}
                  type={"remove"}
                  size={"m-md"}
                  cursor
                  title={{ visible: true, text: "Удалить" }}
                />
              </Screen>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SettingElementCard;
