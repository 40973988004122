import dayjs from "dayjs";
import React, { useEffect } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { getFormatFIO } from "../../utils/getFormatFIO";
import "./ModalInfoEvent.scss";
import DocItem from "../DocItem/DocItem";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";
import { transformNotification } from "../../utils/transformNotification";
import ModalInfoProtocol from "../ModalInfoProtocol/ModalInfoProtocol";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import { getFormatDateTime } from "../../utils/getFormatDateTime";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { asyncGetEvent } from "../../store/reducers/calendarReducer";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const ModalInfoEvent = () => {
  const dispatch = useDispatch();

  const { getInfo, changeModalVisible } = useModal();

  const info = getInfo("infoEvent", "data");

  const { add, edit } = useHasPermissions("infoEvent", info);

  const {
    _id,
    name,
    description,
    address,
    start,
    end,
    documents,
    notifications,
    protocol,
    author,
    employees,
  } = info;

  const getInfoEvent = async () => {
    const res = await dispatch(asyncGetEvent(_id));

    if (res.error) return;

    changeModalVisible("infoEvent", true, res.payload.data);
  };

  useEffect(() => {
    if (getInfo("editEvent", "data")) getInfoEvent();
  }, [getInfo("editEvent", "data")]);

  useEffect(() => {
    if (getInfo("editProtocol", "data")) getInfoEvent();
  }, [getInfo("editProtocol", "data")]);

  useEffect(() => {
    if (getInfo("addProtocol", "data")) getInfoEvent();
  }, [getInfo("addProtocol", "data")]);

  useEffect(() => {
    if (getInfo("deleteProtocol", "data")) getInfoEvent();
  }, [getInfo("deleteProtocol", "data")]);

  return (
    <ModalWrapper
      editBtn={
        edit && {
          visible: true,
          onClick: () => changeModalVisible("editEvent", true, info),
        }
      }
      onClose={() => {
        changeModalVisible("infoEvent");
        changeModalVisible("clear");
      }}
      title="Информация о мероприятии"
    >
      <form className="modalInfoEvent">
        <div className="modalInfoEvent-info">
          <div className="modalInfoEvent-info-box">
            <span className="modalInfoEvent-info-title">Название мероприятия:</span>
            <span className="modalInfoEvent-info-bold">{name}</span>
          </div>
          <div className="modalInfoEvent-info-box">
            <span className="modalInfoEvent-info-title">Описание мероприятия:</span>
            <span className="modalInfoEvent-info-bold">{description}</span>
          </div>
          <div className="modalInfoEvent-info-grid">
            <div className="modalInfoEvent-info-grid-item">
              <span className="modalInfoEvent-info-title">Адрес:</span>
              <span className="modalInfoEvent-info-bold">{address}</span>
            </div>
            <div className="modalInfoEvent-info-grid-item date">
              <span className="modalInfoEvent-info-title">Дата начала:</span>
              <span className="modalInfoEvent-info-bold">{getFormatDateTime(start)}</span>
            </div>
            <div className="modalInfoEvent-info-grid-item date">
              <span className="modalInfoEvent-info-title">Дата завершения:</span>
              <span className="modalInfoEvent-info-bold">{getFormatDateTime(end)}</span>
            </div>
            {notifications && (
              <div className="modalInfoEvent-info-grid-item">
                <span className="modalInfoEvent-info-title">Уведомление:</span>
                <span className="modalInfoEvent-info-bold">{`${
                  transformNotification(notifications).name
                } `}</span>
              </div>
            )}
          </div>
          <div className="modalInfoEvent-person">
            <span className="modalInfoEvent-info-title">Автор: </span>
            <div className="d-flex gap-10">
              <div
                style={{
                  backgroundImage: `url(${getFullPath(author?.user?.avatar, "avatar")})`,
                }}
                className="modalInfoEvent-person-logo"
              ></div>
              <div className="modalInfoEvent-person-info">
                <span className="modalInfoEvent-info-bold">{getFormatFIO(author?.user)}</span>
                {author?.position && (
                  <span className="modalInfoEvent-info-text">
                    {author?.company && author?.company?.name + ", "}
                    {author?.position?.name}{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="modalInfoEvent-info-gridLists">
            {employees?.lenght > 0 && (
              <div className="modalInfoEvent-info-gridLists-item">
                <span className="modalInfoEvent-info-title">Участники:</span>
                <ListModalProfile
                  profiles={employees}
                  onClickInfo={(info) => {
                    changeModalVisible("infoUser", true, info);
                  }}
                />
              </div>
            )}

            {documents?.lenght > 0 && (
              <div className="modalInfoEvent-info-gridLists-item">
                <span className="modalInfoEvent-info-title">Файлы:</span>
                <div className="modalInfoEvent-info-fileList customScroll">
                  {documents?.map((file, i) => (
                    <DocItem
                      download
                      shadow
                      index={i}
                      info={file}
                      btnVisible={{ remove: false, download: true }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {!protocol && add && (
          <>
            <div className="hr"></div>
            <Button
              onClick={() => changeModalVisible("addProtocol", true, { event: info })}
              color="dark"
              title="Протокол"
              icon={{ position: "left" }}
            />
          </>
        )}
        {protocol && (
          <>
            <div className="hr"></div>
            <ModalInfoProtocol protocolInfo={protocol} eventId={_id} />
          </>
        )}
      </form>
    </ModalWrapper>
  );
};

export default ModalInfoEvent;
