import React from "react";
import "./FolderStructureItem.scss";
import Icons from "../../../Icons/Icons";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncGetTree } from "../../../../store/reducers/documentationReduser";
import { defaultOrder } from "ol/renderer/vector";

const FolderStructureItem = ({ info, setActiveFolder, activeFolder, headFolder }) => {
  const { name, _id, children } = info;
  const [childrenData, setChildrenData] = useState([]);
  const [arrowActive, setArrowActive] = useState(false);
  const dispatch = useDispatch();

  const getStructure = async (activeFolder) => {
    setActiveFolder(activeFolder);
    const res = await dispatch(
      asyncGetTree({
        head: activeFolder._id,
      }),
    );
    if (res.error) return;
    setChildrenData(res.payload.children);
  };

  return (
    <div className="folderStructureItem">
      <div
        className={`folderStructureItem-row ${activeFolder?._id === _id ? "active" : ""}`}
        onClick={() => {
          getStructure(info);
          setArrowActive(!arrowActive);
        }}
      >
        {children && (
          <Icons
            type={"arrow"}
            size={"xs"}
            className={`folderStructureItem-row-arrow ${arrowActive ? "active" : ""}`}
          />
        )}
        <div className="folderStructureItem-row-icon">
          {headFolder ? (
            <Icons type={"folderOpen"} size={"xl"} title={{ visible: true, text: "" }} />
          ) : arrowActive ? (
            <Icons type={"folderOpen"} size={"xl"} title={{ visible: true, text: "" }} />
          ) : (
            <Icons
              type={"folder"}
              size={"xl"}
              className={"folderStructureItem-row-folder"}
              title={{ visible: true, text: "" }}
            />
          )}
        </div>

        <span className="folderStructureItem-row-name">{name || ""}</span>
      </div>
      <div className="folderStructureItem-children">
        {childrenData.length > 0 &&
          children &&
          arrowActive &&
          childrenData?.map((clid) => (
            <FolderStructureItem
              info={clid}
              key={clid._id}
              setActiveFolder={setActiveFolder}
              activeFolder={activeFolder}
            />
          ))}
      </div>
    </div>
  );
};
export default FolderStructureItem;
