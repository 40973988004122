import React, { useState, useRef } from "react";
import { ViewMode } from "../../dist/index.js";
import settingIcon from "../../../../../../assets/icons/setting-white.svg";
import eyeOpen from "../../../../../../assets/icons/eye-white-open.svg";
import eyeClose from "../../../../../../assets/icons/eyeWithLine.svg";
import magnifierMinus from "../../../../../../assets/icons/magnifierMinus-white.svg";
import magnifierPlus from "../../../../../../assets/icons/magnifierPlus-white.svg";
import "./GanttChartHeader.scss";
import Icons from "../../../../../Icons/Icons.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import Screen from "../../../../../Screen/Screen.jsx";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";

// .svg
const GanttChartHeader = ({
  setArrowVisible,
  arrowVisible,
  setView,
  view,
  edit,
  visibleChangeHeght,
  setHeigthGantt,
}) => {
  const { changeModalVisible } = useModal();

  const [menuImport, setMenuImport] = useState(false);

  const [visible, setVisible] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setMenuImport(false));

  const [countViewMode, setCountViewMode] = useState(true);
  // const [sizeValue, setSizeValue] = useState("year");
  const changeSize = (event) => {
    setView((prev) => {
      if (event === "-") {
        if (prev === ViewMode.Month) return ViewMode.Year;
        if (prev === ViewMode.Week) return ViewMode.Month;
        if (prev === ViewMode.Day) return ViewMode.Week;
        if (prev === ViewMode.HalfDay) return ViewMode.Day;
        if (prev === ViewMode.QuarterDay) return ViewMode.HalfDay;
        if (prev === ViewMode.Hour) return ViewMode.QuarterDay;
      } else {
        if (prev === ViewMode.Year) return ViewMode.Month;
        if (prev === ViewMode.Month) return ViewMode.Week;
        if (prev === ViewMode.Week) return ViewMode.Day;
        if (prev === ViewMode.Day) return ViewMode.HalfDay;
        if (prev === ViewMode.HalfDay) return ViewMode.QuarterDay;
        if (prev === ViewMode.QuarterDay) return ViewMode.Hour;
      }
      return prev;
    });
  };

  const changeHeigthGant = (bool) => {
    setVisible(bool);
    if (visible) {
      setHeigthGantt(350);
    } else {
      setHeigthGantt(null);
    }
  };

  return (
    <div className="ganttChartHeader">
      <div className="ganttChartHeader-left">
        {/* <div className="ganttChartHeader-setting">
          <div
            onClick={() => setMenuImport((prev) => !prev)}
            className="ganttChartHeader-btn setting"
          >
            <Icons
              type={"settings"}
              color={"white"}
              title={{ text: "Экспорт", visible: true }}
              size={"md"}
            />
          </div>
          {menuImport && (
            <div ref={ref} className="ganttChartHeader-setting-menuImport">
              <span>Экспорт в PDF</span>
              <span>Экспорт в EXCEL</span>
            </div>
          )}
        </div> */}
        <Screen size={"lg"}>
          {edit && (
            <div
              onClick={() => changeModalVisible("chooseStageOrWork", true)}
              className="ganttChartHeader-btn ganttChartHeader-plus-btn"
            >
              <Icons
                type={"plus"}
                size={"lg"}
                color={"white"}
                title={{ text: "Добавить этап/работу", visible: true }}
              />
            </div>
          )}
        </Screen>
        <div
          className="ganttChartHeader-btn ganttChartHeader-eye-btn"
          onClick={() => setArrowVisible(!arrowVisible)}
        >
          {arrowVisible ? (
            <Icons
              type={"eyeOpen"}
              size={"md"}
              color={"white"}
              title={{ text: "Скрыть зависимости", visible: true }}
            />
          ) : (
            <Icons
              type={"eyeClose"}
              size={"md"}
              color={"white"}
              title={{ text: "Отобразить зависимости", visible: true }}
            />
          )}
        </div>
      </div>
      {/* <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Час
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Четверть дня
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Половина дня
      </button>
      <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
        День
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Неделя
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Месяц
      </button>
      <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        Год
      </button> */}
      <div className="ganttChartHeader-right">
        {visibleChangeHeght && (
          <button
            className="ganttChartHeader-btn ganttChartHeader-eye-btn"
            onClick={() => changeHeigthGant(!visible)}
          >
            {visible ? (
              <Icons
                type={"arrowDown"}
                size={"md"}
                color={"white"}
                title={{ text: "Развернуть", visible: true }}
              />
            ) : (
              <Icons
                type={"arrowTop"}
                size={"md"}
                color={"white"}
                title={{ text: "Скрыть", visible: true }}
              />
            )}
          </button>
        )}
        <button
          onClick={() => changeSize("+")}
          className={`ganttChartHeader-btn ganttChartHeader-btn-size-btn ${
            view === ViewMode.Hour ? "ganttChartHeader-size-btn-disabled" : ""
          }`}
        >
          <Icons type={"magnifierPlus"} size={"md"} title={{ text: "", visible: true }} />
        </button>
        <button
          onClick={() => changeSize("-")}
          className={`ganttChartHeader-btn ganttChartHeader-btn-size-btn ${
            view === ViewMode.Year ? "ganttChartHeader-btn-size-btn-disabled" : ""
          }`}
        >
          <Icons type={"magnifierMinus"} size={"md"} title={{ text: "", visible: true }} />
        </button>
      </div>
      {/* <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        Показать список задач
      </div> */}
    </div>
  );
};
export default GanttChartHeader;
