import React, { useEffect, useState } from "react";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import "./ModalMobileMenu.scss";
import { useModal } from "../../hooks/useModal";
import { getBaseUrl } from "../../utils/getBaseUrl";
import ModalMobileMenuItem from "./components/ModalMobileMenuItem/ModalMobileMenuItem";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import { useSearch } from "../../hooks/useSearch";
import { transformForSelect } from "../../utils/transformForSelect";
import { asyncGetEvent } from "../../store/reducers/calendarReducer";
import { useDispatch } from "react-redux";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import { asyncGetTask } from "../../store/reducers/tasksReducer";

const ModalMobileMenu = ({
  currentPage: currentPageProps,
  chapter,
  handleOpenProject,
  openProject,
  onClose,
  project,
  currentLayer,
  nameModal,
}) => {
  const dispatch = useDispatch();

  const { changeModalVisible, getVisible, getInfo } = useModal();

  const info = getInfo(nameModal || "menu").data;
  const { visible, add, edit, remove } = useHasPermissions(
    getInfo(nameModal || "menu").openFromComponent || "",
    info,
  );
  const [currentPage, setCurrentPage] = useState();

  const { userData } = useSelector((state) => state.user);
  const { _id: userId } = userData;

  const { getCurrentPage, goToPage } = useSearch();

  const handleOnSubtaskInfo = async (info, name = "infoTask", editMode = false) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible(name, true, res.payload, editMode);
  };

  const onEventTask = (name) => {
    let modalName = "infoTask";
    let editMode = false;

    if (name === "edit") {
      modalName = "editTask";
      editMode = true;
    }

    if (name === "remove") {
      modalName = "deleteTask";
    }

    return handleOnSubtaskInfo(info, modalName, editMode);
  };

  const downloadFile = (e) => {
    e.preventDefault();
    axios
      .get(getBaseUrl() + "/folders" + info?.path_to_doc, {
        responseType: "blob",
      })
      .then((response) => {
        const link = document.createElement("a");
        const blob = new Blob([response.data]);
        let urll = window.URL.createObjectURL(blob);
        link.href = urll;
        link.download = info.name;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urll);
          document.body.removeChild(link);
        }, 0);
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  };

  const goToPageProject = (edit, info) => {
    goToPage("project", { projectId: info._id }, { edit: Number(edit) });
  };

  useEffect(() => {
    if (currentPageProps) return;

    const page = getCurrentPage();

    if (page) setCurrentPage(page);
  }, [currentPageProps]);

  const showModalEvent = async (name, data) => {
    if (name === "info" || name === "edit") {
      const res = await dispatch(asyncGetEvent(data._id));
      if (res.error) return;
      if (name === "edit") changeModalVisible("editEvent", true, res.payload.data);
      else if (name === "info") changeModalVisible("infoEvent", true, res.payload.data);
    } else if (name === "delete") {
      changeModalVisible("deleteEvent", true, { _id: data._id });
    }
  };

  return (
    <ModalWrapperMobile
      onClose={onClose ? () => onClose() : () => changeModalVisible("menu", false)}
      heightContent={"200px"}
    >
      <div className="modalMobileMenu">
        {chapter === "users" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о сотруднике"}
              onClick={() => {
                changeModalVisible("menu", false);
                goToPage("userInfo", { userId: info._id });
              }}
            />
            {edit && (
              <ModalMobileMenuItem
                typeIcon={"edit"}
                title={"Редактировать сотрудника"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("editEmployee", true, info, true);
                }}
              />
            )}
            {remove && (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить сотрудника"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("deleteEmployee", true, info, true);
                }}
              />
            )}
          </ul>
        )}
        {chapter === "groups" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о группе"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoGroup", true, info);
              }}
            />
            {edit && (
              <ModalMobileMenuItem
                typeIcon={"edit"}
                title={"Редактировать группу"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("editGroup", true, info);
                }}
              />
            )}
            {remove && (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить группу"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("deleteGroup", true, info, true);
                }}
              />
            )}
          </ul>
        )}
        {chapter === "companies" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о компании"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoCompany", true, info);
              }}
            />
            {currentPage !== "project:companies" && (
              <>
                {edit && (
                  <ModalMobileMenuItem
                    typeIcon={"edit"}
                    title={"Редактировать компанию"}
                    onClick={() => {
                      changeModalVisible("menu", false);
                      changeModalVisible("infoCompany", true, info, true);
                    }}
                  />
                )}
                {remove && (
                  <ModalMobileMenuItem
                    typeIcon={"remove"}
                    title={"Удалить компанию"}
                    onClick={() => {
                      changeModalVisible("menu", false);
                      changeModalVisible("deleteCompany", true, info, true);
                    }}
                  />
                )}
              </>
            )}
          </ul>
        )}

        {chapter === "participants" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация об участнике"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoParticipant", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"remove"}
              title={"Удалить участника из проекта"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("deleteParticipant", true, info, true);
              }}
            />
          </ul>
        )}
        {chapter === "companiesInProject" && (
          <ul className="modalMobileMenu-list">
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoEditCompany", true, info);
              }}
            >
              <span className="modalMobileMenu-item">Информация о компании</span>
            </li>

            <li
              className="modalMobileMenu-item"
              onClick={() => {
                // changeModalVisible("deleteDepartment", true, info, true);
                changeModalVisible("menu", false);
              }}
            >
              <span className="modalMobileMenu-item">Удалить компанию из проекта</span>
            </li>
          </ul>
        )}
        {chapter === "protocols" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о протоколе"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoProtocol", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"edit"}
              title={"Редактировать протокол"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editProtocol", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"remove"}
              title={"Удалить протокол"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editProtocol", true, info);
              }}
            />
          </ul>
        )}
        {chapter === "projectWorkSchedule" && (
          <>
            {getVisible("infoWorkSchedule") ? (
              <ul className="modalMobileMenu-list">
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible(
                      info.type === "project" ? "infoStage" : "infoWork",
                      true,
                      info,
                    );
                  }}
                >
                  <span className="modalMobileMenu-item">
                    Информация
                    {info.type === "project" ? " об этапе" : " о работе"}
                  </span>
                </li>
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible(
                      info.type === "project" ? "infoStage" : "infoWork",
                      true,
                      info,
                      true,
                    );
                  }}
                >
                  <span className="modalMobileMenu-item">
                    Редактировать {info.type === "project" ? " этап" : "работу"}
                  </span>
                </li>
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                  }}
                >
                  <span className="modalMobileMenu-item">
                    Удалить {info.type === "project" ? "этап" : "работу"}
                  </span>
                </li>
              </ul>
            ) : (
              <ul className="modalMobileMenu-list">
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("infoWorkSchedule", true, info, false, false);
                  }}
                >
                  <span className="modalMobileMenu-item">Информация о графике</span>
                </li>
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("infoWorkSchedule", true, info, true, false);
                  }}
                >
                  <span className="modalMobileMenu-item">Редактировать график</span>
                </li>
                <li
                  className="modalMobileMenu-item"
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("deleteWorkSchedule", true, info);
                  }}
                >
                  <span className="modalMobileMenu-item">Удалить график</span>
                </li>
              </ul>
            )}
          </>
        )}
        {chapter === "calendar" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о мероприятии"}
              onClick={() => {
                changeModalVisible("menu", false);
                showModalEvent("info", info);
              }}
            />
            {edit && (
              <ModalMobileMenuItem
                typeIcon={"edit"}
                title={"Редактировать мероприятие"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  showModalEvent("edit", info);
                }}
              />
            )}
            {remove && (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить мероприятие"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  showModalEvent("delete", info);
                }}
              />
            )}
          </ul>
        )}

        {chapter === "deliveryWork" && !openProject && (
          <ul className="modalMobileMenu-list">
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                handleOpenProject(info.id);
              }}
            >
              <span className="modalMobileMenu-item">Просмотреть запросы проекта</span>
            </li>
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                // changeModalVisible("deleteChat", true, info, true);
                changeModalVisible("menu", false);
              }}
            >
              <span className="modalMobileMenu-item">Удалить проект</span>
            </li>
          </ul>
        )}
        {chapter === "deliveryWork" && openProject && (
          <ul className="modalMobileMenu-list">
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("сheckingDeliveryWork", true, info);
              }}
            >
              <span className="modalMobileMenu-item">Редактировать работу</span>
            </li>
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                // changeModalVisible("deleteChat", true, info, true);
                changeModalVisible("menu", false);
              }}
            >
              <span className="modalMobileMenu-item">Удалить работу</span>
            </li>
          </ul>
        )}
        {chapter === "layers" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"layers"}
              title={"Редактировать слой"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editLayer", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"tasks"}
              title={"Задачи слоя"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("tasksLayer", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"remove"}
              title={"Добавить задачу"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("deleteLayer", true, info);
              }}
            />
          </ul>
        )}
        {chapter === "menuLayer" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"layers"}
              title={"Добавить слой"}
              onClick={() => {
                changeModalVisible("menuLayer", false);
                changeModalVisible("addLayer", true);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"tasks"}
              title={"Добавить задачу"}
              onClick={() => {
                changeModalVisible("menuLayer", false);
                changeModalVisible("addTask", true, {
                  project: transformForSelect(project),
                  layer: transformForSelect(currentLayer),
                });
              }}
            />
          </ul>
        )}
        {chapter === "tasks" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о задаче"}
              onClick={() => {
                changeModalVisible("menuTasks", false);
                onEventTask("info");
              }}
            />
            {edit && (
              <ModalMobileMenuItem
                typeIcon={"edit"}
                title={"Редактировать задачу"}
                onClick={() => {
                  changeModalVisible("menuTasks", false);
                  onEventTask("edit");
                }}
              />
            )}
            {remove && (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить задачу"}
                onClick={() => {
                  changeModalVisible("menuTasks", false);
                  onEventTask("remove");
                }}
              />
            )}
          </ul>
        )}
        {chapter === "photoReports" && (
          <ul className="modalMobileMenu-list">
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoFolder", true, info);
              }}
            >
              <span className="modalMobileMenu-item">Информация о папке</span>
            </li>
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("addPhoto", true);
              }}
            >
              <span className="modalMobileMenu-item"> Добавить фото</span>
            </li>
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("infoFolder", true, info, true);
              }}
            >
              <span className="modalMobileMenu-item">Редактировать папку</span>
            </li>
            <li
              className="modalMobileMenu-item"
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("deleteFolder", true, info);
              }}
            >
              <span className="modalMobileMenu-item">Удалить папку</span>
            </li>
          </ul>
        )}
        {chapter === "projects" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о проекте"}
              onClick={() => {
                goToPageProject(false, info);
                changeModalVisible("menu", false);
              }}
            />
            {edit && (
              <ModalMobileMenuItem
                typeIcon={"edit"}
                title={"Редактировать проект"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  goToPageProject(true, info);
                }}
              />
            )}
            {remove && (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить проект"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("deleteProject", true, info);
                }}
              />
            )}
          </ul>
        )}
        {chapter === "structures" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"infoItem"}
              title={"Информация о отделе"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editDepartment", true, info);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"edit"}
              title={"Редактировать отдел"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editDepartment", true, info, true);
              }}
            />
            {info?.main || (
              <ModalMobileMenuItem
                typeIcon={"remove"}
                title={"Удалить отдел"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("deleteDepartment", true, info, true);
                }}
              />
            )}
          </ul>
        )}
        {chapter === "documentation" &&
          (info?.type === "folder" ? (
            <ul className="modalMobileMenu-list">
              <ModalMobileMenuItem
                typeIcon={"infoItem"}
                title={"Информация о папке"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("infoFolder", true, info);
                }}
              />
              {info?.main || (
                <ModalMobileMenuItem
                  typeIcon={"edit"}
                  title={"Редактировать папку"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("infoFolder", true, info, true);
                  }}
                />
              )}
              {info?.main || (
                <ModalMobileMenuItem
                  typeIcon={"copy"}
                  title={"Копировать папку"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("addToFolder", true, info, false, true);
                  }}
                />
              )}
              {info?.main || (
                <ModalMobileMenuItem
                  typeIcon={"addToFolder"}
                  title={"Переместить папку"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("addToFolder", true, info, true, false);
                  }}
                />
              )}
              {info?.main || (
                <ModalMobileMenuItem
                  typeIcon={"remove"}
                  title={"Удалить папку"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("deleteFolder", true, info);
                  }}
                />
              )}
            </ul>
          ) : (
            <ul className="modalMobileMenu-list">
              {/* <li
                className="modalMobileMenu-item"
                onClick={() => {
                  changeModalVisible("menu", false);
                }}
              >
                <a
                  href={getBaseUrl() + info.path}
                  download={info.name}
                  className="modalMobileMenu-item"
                >
                  Скачать файл
                </a>
              </li> */}
              <ModalMobileMenuItem
                typeIcon={"infoItem"}
                title={"Информация о файле"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("infoFile", true, info, false, true);
                }}
              />
              <ModalMobileMenuItem
                typeIcon={"download"}
                title={"Скачать файл"}
                onClick={(e) => {
                  changeModalVisible("menu", false);
                  // changeModalVisible("addToFolder", true, info, false, true);
                  downloadFile(e);
                }}
              />
              <ModalMobileMenuItem
                typeIcon={"copy"}
                title={"Копировать файл"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("addToFolder", true, info, false, true);
                }}
              />
              <ModalMobileMenuItem
                typeIcon={"addToFolder"}
                title={"Переметить файл"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("addToFolder", true, info, true, false);
                }}
              />
              {info?.main || (
                <ModalMobileMenuItem
                  typeIcon={"remove"}
                  title={"Удалить файл"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("deleteDocument", true, info);
                  }}
                />
              )}
            </ul>
          ))}
        {chapter === "menuAddDocumenattion" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"folderClear"}
              title={"Создать папку"}
              onClick={() => {
                changeModalVisible("menuAddDocumenattion", false);
                changeModalVisible("addFolder", true);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"file"}
              title={"Добавить документ"}
              onClick={() => {
                changeModalVisible("menuAddDocumenattion", false);
                changeModalVisible("addDocument", true);
              }}
            />
          </ul>
        )}
        {chapter === "chat" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"addUser"}
              title={"Добавить участников"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("addInChat", true, info);
              }}
            />

            <ModalMobileMenuItem
              typeIcon={"exit"}
              title={"Покинуть чат"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("leaveChat", true, info, true);
              }}
            />
            {info.creatorUser === userId && !info?.group && (
              <ModalMobileMenuItem
                typeIcon={"cleaning"}
                title={"Очистить чат"}
                onClick={() => {
                  changeModalVisible("menu", false);
                  changeModalVisible("clearChat", true, info, true);
                }}
              />
            )}
            {!!info?.group ||
              (info.creatorUser === userId && (
                <ModalMobileMenuItem
                  typeIcon={"remove"}
                  title={"Удалить чат"}
                  onClick={() => {
                    changeModalVisible("menu", false);
                    changeModalVisible("deleteChat", true, info, true);
                  }}
                />
              ))}
          </ul>
        )}
        {chapter === "reference" && (
          <ul className="modalMobileMenu-list">
            <ModalMobileMenuItem
              typeIcon={"edit"}
              title={"Редатировать справку"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("editReference", true, info, true);
              }}
            />
            <ModalMobileMenuItem
              typeIcon={"remove"}
              title={"Удалить справку"}
              onClick={() => {
                changeModalVisible("menu", false);
                changeModalVisible("deleteReference", true, info);
              }}
            />
          </ul>
        )}
      </div>
    </ModalWrapperMobile>
  );
};

export default ModalMobileMenu;
