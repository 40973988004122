import React, { useRef } from "react";
import CanvasPlan from "../../pages/desktop/Project/components/Layers/components/CanvasPlan/CanvasPlan";
import { getBaseUrl } from "../../utils/getBaseUrl";
import Button from "../Button/Button";
import Select from "../Select/Select";
import { useState } from "react";
import "./EditInfoProject.scss";
import { useEffect } from "react";
import ImageList from "../ImageList/ImageList";
import { useModal } from "../../hooks/useModal";
import { getForSelect } from "../../utils/getForSelect";
import { useDispatch } from "react-redux";
import { asyncGetLayer } from "../../store/reducers/layersReducer";
import { getModalName } from "../../utils/getModalName";
import InputSearch from "../InputSearch/InputSearch";
import { useSelector } from "react-redux";
import Checkbox from "../Checkbox/Checkbox";
import Icons from "../Icons/Icons";

function EditInfoProject(props) {
  const {
    modalName = getModalName(props.mode || "add"),
    changeIV,
    optionsProject,
    changeOptions,
    checkValidateOptionsProject,
    showSelects = [], // type_work
    hideSelects = [], // isImportant
    mode,
    viewModal = false,
    hardValues,
    IVProject,
  } = props;

  const refSize = useRef();

  const dispatch = useDispatch();
  const { sizeModal } = useSelector((state) => state.settings);
  const { userData } = useSelector((state) => state.user);
  const { task } = useSelector((state) => state.tasks);
  const { getInfo, changeModalVisible } = useModal();

  const [images, setImages] = useState([]);
  const [lines, setLines] = useState(null);
  const [loadin, setLoading] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);
  const [sizeLayer, setSizeLayer] = useState({ width: 1070, height: 660 });
  const infoModal = getInfo(modalName, "data");

  const [permission, setPermission] = useState({
    status: true,
  });

  const [canvas, setCanvas] = useState(null);

  const getLayerProject = (projectId) => {
    getForSelect.getLayersProject({ projectId }, (layers) => {
      changeOptions("layers", "options", layers);
    });
  };

  const getLayer = async (layerId) => {
    setLoading(true);
    const res = await dispatch(asyncGetLayer(layerId));
    if (!res.error) setCurrentLayer(res.payload.data);
    setLoading(false);
  };

  useEffect(() => {
    if (infoModal.coordinates) {
      setLines(infoModal.coordinates);
    }
  }, [infoModal]);

  useEffect(() => {
    if (!optionsProject.layers.value) return;
    getLayer(optionsProject.layers.value.value);
  }, [optionsProject.layers.value]);

  useEffect(() => {
    if (!optionsProject.projects.value) {
      changeOptions("layers", "value", null);
      setCurrentLayer(null);
      return;
    }

    getLayerProject(optionsProject.projects.value.value);
  }, [optionsProject.projects.value]);

  useEffect(() => {
    if (!optionsProject.layers.value) setCurrentLayer(null);
  }, [optionsProject.layers.value]);

  useEffect(() => {
    setImages(infoModal?.photos || []);
  }, [infoModal.photos]);

  // размер слоя от размера родителя
  const { offsetWidth } = refSize.current || {};

  const resizeHandler = () => {
    setSizeLayer({ width: offsetWidth, height: offsetWidth / 1.6 });
  };

  const onCheckChangeStatus = () => {
    if (task && task?._id !== infoModal?._id) {
      setPermission({
        status: false,
      });
    }
    if (
      userData?.role !== "admin" &&
      (task?.status === "assigned" || task?.status === "completed" || task?.status === "atWork")
    ) {
      setPermission({
        status: false,
      });
    } else {
      setPermission({
        status: true,
      });
    }
  };
  useEffect(() => {
    resizeHandler();
  }, [offsetWidth, sizeModal, viewModal]);

  useEffect(() => {
    if (modalName === "editTask") {
      onCheckChangeStatus();
    }
  }, []);

  return (
    <div className={`projectEditInfo ${viewModal ? "rows" : "columns"}`}>
      <div className={`projectEditInfo-content ${viewModal ? "rows" : "columns"}`}>
        {showSelects.includes("type_work") && (
          <div className="projectEditInfo-item">
            <Select
              forInput
              title="Тип работы:*"
              placeholder="Выберите тип работы"
              options={optionsProject.type_work.options}
              value={optionsProject.type_work.value}
              handleChange={(data) => changeOptions("type_work", "value", data)}
              validate={checkValidateOptionsProject("type_work")}
            />
          </div>
        )}
        {hideSelects.includes("isImportant") || (
          <div className="projectEditInfo-item-textBox checkBox">
            {/* <span className="projectEditInfo-item-textBox-title">Тип задачи:</span> */}
            <div className="projectEditInfo-item-textBox-title-checkBox">
              <Checkbox
                check={IVProject.isImportant}
                onCheck={() =>
                  changeIV("set", { name: "isImportant", value: !IVProject.isImportant })
                }
              />

              <span
                className={`projectEditInfo-item-textBox-type ${
                  !IVProject.isImportant ? "grey" : ""
                }`}
              >
                Важная задача
              </span>
              {IVProject.isImportant && <Icons type="fire" size={"md"} />}
            </div>
          </div>
        )}

        {mode === "add" || mode === "subTask" || mode === "copy" ? (
          <div className="projectEditInfo-item-textBox">
            <span className="projectEditInfo-item-textBox-title">Статус:</span>
            <span className="projectEditInfo-item-textBox-type">Назначено</span>
          </div>
        ) : (
          <div className="projectEditInfo-item">
            <Select
              handleChange={(value) => changeOptions("status", "value", value)}
              title="Статус:*"
              placeholder="Выберите статус"
              forInput
              options={optionsProject.status.options}
              value={optionsProject.status.value}
              validate={checkValidateOptionsProject("status")}
              isClearable={false}
              isDisabled={!permission?.status}
            />
          </div>
        )}
      </div>
      <div className="projectEditInfo-item">
        <div className="projectEditInfo-item-select">
          {hardValues?.project ? (
            <div className="projectEditInfo-item-textBox">
              <span className="projectEditInfo-item-textBox-title">Проект:</span>
              <span className="projectEditInfo-item-textBox-type">{hardValues.project.label}</span>
            </div>
          ) : (
            <InputSearch
              handleChange={(value) => changeOptions("projects", "value", value)}
              title="Проект:"
              type="project"
              placeholder="Выберите проект"
              forInput
              value={optionsProject.projects.value}
            />
          )}
        </div>
      </div>
      <div
        className={`projectEditInfo-content ${viewModal ? "rows" : "columns"} ${
          sizeModal ? "big" : "small"
        }`}
      >
        <div className="projectEditInfo-item" ref={refSize}>
          <div className="projectEditInfo-item-select">
            <Select
              isDisabled={!optionsProject?.projects?.value}
              options={optionsProject.layers.options}
              forInput
              title="Слой:"
              value={optionsProject.layers.value}
              placeholder="Выберите слой"
              handleChange={(value) => changeOptions("layers", "value", value)}
            />
          </div>

          {loadin ? (
            <div className="submit-spinner-lm"></div>
          ) : (
            <>
              {!!currentLayer && (
                <div
                  className={`projectEditInfo-item-canvas ${
                    viewModal ? "zoomRows" : "zoomColumns"
                  } ${sizeModal ? "zoomBig" : "zoomSmall"}`}
                  style={{
                    width: `${sizeLayer.width}px`,
                    height: `${sizeLayer.height}px`,
                  }}
                >
                  <CanvasPlan
                    lines={lines}
                    imgSrc={getBaseUrl() + currentLayer.image}
                    visiblePoints={true}
                    filterPin={"all"}
                    changeCurrentTask={() => {}}
                    onChangelines={() => {}}
                    width={1070}
                    height={660}
                    drawingChoose={"watch"}
                    onLoadCanvas={setCanvas}
                  />
                </div>
              )}
            </>
          )}
          {!!optionsProject.layers.value?.value && (
            <Button
              disabled={!!!optionsProject.layers.value?.value}
              color="dark"
              title="Отметка"
              icon={{ position: "left" }}
              onClick={() =>
                changeModalVisible("addPoint", true, {
                  ...currentLayer,
                  coordinates: infoModal.coordinates,
                })
              }
            />
          )}
        </div>
        <ImageList
          onChange={(value) => changeIV("set", { name: "photos", value })}
          images={images}
          title="Фото задачи:"
        />
      </div>
    </div>
  );
}

export default EditInfoProject;
