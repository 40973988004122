import React from "react";
import { useSelector } from "react-redux";
import Pagination from "../Pagination/Pagination";
import TasksGridCardMobile from "../TasksGridCardMobile/TasksGridCardMobile";
import "./TasksGridMobile.scss";

const TasksGridMobile = ({ taskInfo, onDelete }) => {
  let { tasks, count } = useSelector((state) => state.tasks);
  if (taskInfo) {
    tasks = taskInfo.tasks;
    count = taskInfo.count;
  }
  return (
    <div className="tasksGridMobile">
      <div className="tasksGridMobile-grid">
        {tasks.map((task) => (
          <TasksGridCardMobile info={task} key={task._id} onDelete={onDelete} />
        ))}
      </div>
      <Pagination count={count} />
    </div>
  );
};

export default TasksGridMobile;
