import React from "react";
import "./LayoutMobileFooter.scss";
import Icons from "../../../Icons/Icons";
import Filter from "../../../Filter/Filter";
/**
 *footerBtnBack - кнопка возврата на предыдущую страницу
 *footerBtnAddCircle - кнопка открытия модального окна для добавления
 */

import { useSelector } from "react-redux";
const LayoutMobileFooter = ({
  footerBtnBack = { visible: false, onClick: null },
  footerBtnAddCircle = { visible: false, onClick: null },
  filterOnAccept,
  filterDisabled,
  currentPage,
  onViewClick,
  viewCards,
  activePage,
  footerBtnRotate = { visible: false },
  viewChapterPhoto,
  changeChapterPhoto,
}) => {
  const { disabled } = useSelector((state) => state.blockScroll);

  const visibleFooters = () => {
    if (footerBtnBack.visible || !!footerBtnAddCircle.visible || !filterDisabled) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(footerBtnBack.visible, visibleFooters());
  return (
    <>
      {visibleFooters() && (
        <footer className={`layoutMobileFooter ${disabled ? "layoutMobileFooter-disabled" : ""}`}>
          <div className="mobile-container">
            <ul className="layoutMobileFooter-btns">
              <li className="layoutMobileFooter-btns-item">
                {footerBtnBack.visible && (
                  <div className="layoutMobileFooter-btn-circle" onClick={footerBtnBack.onClick}>
                    <Icons
                      type={"arrow"}
                      size={"md"}
                      color={"white"}
                      className="layoutMobileFooter-btn-icon-arrow"
                    />
                  </div>
                )}
              </li>
              <li className="layoutMobileFooter-btns-item">
                {!filterDisabled && (
                  <div className="layoutMobileFooter-btn-filter">
                    <Filter
                      currentPage={currentPage}
                      mobile
                      onAccept={filterOnAccept}
                      disabled={filterDisabled}
                    />
                  </div>
                )}
              </li>
              <li className="layoutMobileFooter-btns-item">
                {footerBtnRotate.visible && (
                  <>
                    {viewCards === "columns" ? (
                      <div className="layoutMobileFooter-btn-rectangle">
                        <Icons type={"viewCard"} onClick={() => onViewClick("rows")} size={"xl"} />
                      </div>
                    ) : (
                      <div className="layoutMobileFooter-btn-rectangle">
                        <Icons
                          type={"viewCard"}
                          onClick={() => onViewClick("columns")}
                          className="rotate90"
                          size={"xl"}
                        />
                      </div>
                    )}
                  </>
                )}
                {viewChapterPhoto && activePage === "projects/info/photoReports" && (
                  <>
                    {viewChapterPhoto === "small-grid" && (
                      <div
                        className="layoutMobileFooter-btn-rectangle"
                        onClick={() => changeChapterPhoto("small-grid")}
                      >
                        <Icons type={"small-grid"} size={"xl"} />
                      </div>
                    )}
                    {viewChapterPhoto === "grid" && (
                      <div
                        className="layoutMobileFooter-btn-rectangle"
                        onClick={() => changeChapterPhoto("grid")}
                      >
                        <Icons type={"grid"} size={"xl"} />
                      </div>
                    )}
                    {viewChapterPhoto === "big-grid" && (
                      <div
                        className="layoutMobileFooter-btn-rectangle"
                        onClick={() => changeChapterPhoto("big-grid")}
                      >
                        <Icons type={"big-grid"} size={"xl"} />
                      </div>
                    )}
                  </>
                )}
              </li>
              <li className="layoutMobileFooter-btns-item">
                {footerBtnAddCircle.visible && (
                  <div
                    className="layoutMobileFooter-btn-circle"
                    onClick={footerBtnAddCircle.onClick}
                  >
                    <Icons
                      type={"closeWhite"}
                      size={"md"}
                      color={"white"}
                      className="layoutMobileFooter-btn-plus"
                    />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </footer>
      )}
    </>
  );
};

export default LayoutMobileFooter;
