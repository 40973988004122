import React, { useEffect } from "react";
import Input from "../../../Input/Input";
import InputDate from "../../../InputDate/InputDate";
import Textarea from "../../../Textarea/Textarea";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import dayjs from "dayjs";
import Button from "../../../Button/Button";
import "./ModalStageEdit.scss";
import HistoryChanges from "../../../HistoryChanges/HistoryChanges";
import ListWorks from "../../../ListWorks/ListWorks";
import { toast } from "react-toastify";
import { asyncCreateStage, asyncEditStage } from "../../../../store/reducers/projectsReducer";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import { useDispatch } from "react-redux";
import InputSearch from "../../../InputSearch/InputSearch";
import { transformForSelect } from "../../../../utils/transformForSelect";
import axios from "../../../../axios/axios";
import Select from "../../../Select/Select";

function ModalStageEdit(props) {
  const { info, createMode, changeModalVisible, scheduleWorksId, getWorksAndSteps } = props;

  const dispatch = useDispatch();

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    scheduleStage: {
      options: [],
      value: null,
    },
    scheduleWorks: {
      options: [],
      value: null,
    },
  });

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    desc: info?.desc,
    start: (info?.start && new Date(info?.start)) || new Date(),
    end: (info?.end && new Date(info?.end)) || new Date(),
    works: info?.works || [],
    historyChanges: info?.historyChanges || [],
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const validate = [checkValidateIV("name", { minHeight: 3, maxHeight: 100, required: true })];

    if (!validate) return;

    if (dayjs(IV.end).diff(IV.start, "day") < 0)
      return toast.warning("Дата завершения не может быть раньше даты начала");

    let res;

    const data = {
      name: options.scheduleStage.value.label,
      start: IV.start,
      end: IV.end,
    };

    if (createMode) {
      res = await dispatch(asyncCreateStage({ ...data, scheduleWorksId }));
    } else {
      if (options?.scheduleWorks?.value?.length) {
        data.works = options?.scheduleWorks?.value?.map((value) => value.value);
      }
      res = await dispatch(
        asyncEditStage({
          ...data,
          stageId: info._id,
          desc: IV.desc,
          transfer: IV.transfer,
        }),
      );
    }
    if (res.error) return;
    await getWorksAndSteps();
    changeModalVisible("infoStage", false, null);
  };

  const getWorkName = async () => {
    const workItem = await axios.post(`/scheduleWorks/get_work_by_name/${info.name}`);
    if (workItem.data && Array.isArray(workItem.data) && workItem.data[0]) {
      const value = workItem.data[0];
      changeOptions("scheduleStage", "value", transformForSelect(value));
    }
  };

  useEffect(() => {
    getWorkName();
  }, []);

  const removeTask = (task, event) => {
    const { value } = task;
    if (event === "add") {
      const newArr = [...options.scheduleWorks.value, task];
      changeOptions("scheduleWorks", "value", newArr);
      changeOptions(
        "scheduleWorks",
        "options",
        options.scheduleWorks.options.filter((task) => task.value !== value),
      );
    } else {
      const newArr = options.scheduleWorks.value.filter((task) => task.value !== value);
      changeOptions("scheduleWorks", "value", newArr);
      changeOptions("scheduleWorks", "options", [...options.scheduleWorks.options, task]);
    }
  };

  useEffect(() => {
    if (createMode) {
      changeIV("set", { name: "name", value: "" });
      changeIV("set", { name: "start", value: new Date() });
      changeIV("set", { name: "end", value: new Date() });
    }
  }, [createMode]);

  useEffect(() => {
    if (createMode) return;
    const { works } = info;
    changeOptions(
      "scheduleWorks",
      "value",
      works.map((work) => {
        return {
          label: work.name,
          value: work._id,
        };
      }),
    );
  }, []);

  return (
    <form onSubmit={onSubmit} className="modalStageEdit">
      <InputSearch
        forInput
        type="scheduleWorks"
        placeholder="Введите название этапа"
        title="Название этапа:*"
        handleChange={(data) => changeOptions("scheduleStage", "value", data)}
        placeholderSelect="Введите название этапа"
        value={options.scheduleStage.value}
        validate={checkValidateOptions("scheduleStage")}
      />
      <div className="modalStageEdit-row">
        <InputDate
          value={IV.start}
          onChange={(value) => changeIV("set", { name: "start", value })}
          title="Дата начала:"
          withPortal={true}
        />
        <InputDate
          value={IV.end}
          onChange={(value) => changeIV("set", { name: "end", value })}
          title="Дата завершения:"
          withPortal={true}
        />
        <div className="modalStageEdit-row-item">
          <span className="modalStageEdit-row-item-title">Кол-во дней:</span>
          <span className="modalStageEdit-row-item-text">
            {dayjs(IV.end).diff(IV.start, "day")}
          </span>
        </div>
      </div>
      {!createMode && (
        <>
          <div className="modalStageEdit-row">
            <InputDate
              value={IV.end}
              onChange={(value) => changeIV("set", { name: "end", value })}
              title="Перенести срок на / дней:"
            />
            <div className="modalStageEdit-row-item">
              <span className="modalStageEdit-row-item-title">Кол-во дней просрочки:</span>
              <span className="modalStageEdit-row-item-text">
                {dayjs().diff(IV.end, "day") < 0 ? 0 : dayjs().diff(IV.end, "day")}
              </span>
            </div>
          </div>
          <Textarea
            title="Причина переноса срока:"
            value={IV.desc}
            onChange={changeIV}
            name="desc"
            height="170px"
            placeholder="Введите причину переноса срока"
            validate={checkValidateIV("desc")}
          />
        </>
      )}
      {options?.scheduleWorks?.options?.length > 0 && (
        <div className="modalStageEdit-row">
          <Select
            forInput
            title="Доступные работы:"
            value={null}
            options={options.scheduleWorks.options}
            handleChange={(value) => removeTask(value, "add")}
            placeholder="Выберите работy"
            // isDisabled={!options.stages.value}
          />
        </div>
      )}
      {options?.scheduleWorks?.value?.length > 0 && (
        <ListWorks
          works={options?.scheduleWorks?.value}
          title={"Работы в этапе:"}
          changeModalVisible={changeModalVisible}
          editMode={true}
          changeRelationshipTasks={removeTask}
        />
      )}
      {/* <Button
        onClick={() => changeModalVisible("work", true, null, false, true)}
        color="red"
        icon={{ position: "left" }}
        title="Добавить"
      /> */}
      <div className="d-flex justify-center">
        <Button type="submit" color="blue" title={createMode ? "Создать" : "Сохранить"} />
      </div>
      {!!IV?.historyChanges?.length && <HistoryChanges historyChanges={IV.historyChanges} />}
    </form>
  );
}

export default ModalStageEdit;
