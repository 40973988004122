import React from "react";
import Icons from "../../../Icons/Icons";
import Screen from "../../../Screen/Screen";

const ScreenIcon = ({ type }) => {
  return (
    <Screen size="xxl" down>
      <Icons type={type} size={"xl"} className={"additionalFilterPanel-icon"} />
    </Screen>
  );
};

export default ScreenIcon;
