import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import "./CardRowsParticMinChat.scss";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { profilesParsing } from "../../utils/profilesParsing.js";
import { useModal } from "../../hooks/useModal";

function CardRowsParticMinChat(props) {
  const { addItemSelect, check, number, info } = props;
  const { _id, profiles, department, company, email } = info;
  const { changeModalVisible } = useModal();
  return (
    <div className="cardRowsParticMinChat">
      <div className="cardRowsParticMinChat-item number">
        <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
        {/* <span>{number}</span> */}
      </div>
      <div
        className="cardRowsParticMinChat-item name"
        title={getFormatFIO(info)}
        onClick={() => changeModalVisible("infoUser", true, info)}
      >
        <span>{getFormatFIO(info)}</span>
      </div>
      <div className="cardRowsParticMinChat-item email" title={email}>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div
        className="cardRowsParticMinChat-item company"
        title={profilesParsing("company", profiles)}
      >
        <span className="cardRowsParticMinChat-item company-container">
          {profilesParsing("company", profiles) || "-"}
        </span>
      </div>
      <div
        className="cardRowsParticMinChat-item department"
        title={profilesParsing("departments", profiles) || "-"}
      >
        <span>
          {/* {department?.name || "-"} */}
          {profilesParsing("departments", profiles) || "-"}
        </span>
      </div>
      <div
        className="cardRowsParticMinChat-item position"
        title={profilesParsing("position", profiles) || "-"}
      >
        <span className="cardRowsParticMinChat-item position-container">
          {profilesParsing("position", profiles) || "-"}
        </span>
      </div>
    </div>
  );
}

export default CardRowsParticMinChat;
