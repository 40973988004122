import React, { useEffect } from "react";
import InputTime from "../InputTime/InputTime";
import InputRadio from "../InputRadio/InputRadio";
import Select from "../Select/Select";
import Input from "../Input/Input";
import InputDate from "../InputDate/InputDate";
import Checkbox from "../Checkbox/Checkbox";
import "./RegularityForm.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import dayjs from "dayjs";

const RegularityForm = (props) => {
  const { changeIVTask, IVRegularity, type = "task" } = props;

  const { IV: baseSetting, changeIV: setBaseSetting } = useChangeIV({
    type: IVRegularity?.type || "day",

    start_repeating: IVRegularity?.start_repeating
      ? new Date(IVRegularity?.start_repeating)
      : new Date(),

    time_created_task: IVRegularity?.time_created_task
      ? new Date(IVRegularity?.time_created_task)
      : new Date(),

    date_of_create_task: IVRegularity?.date_of_create_task
      ? new Date(IVRegularity?.date_of_create_task)
      : null,

    completed: IVRegularity?.completed || false,

    repeat_until: IVRegularity?.repeat_until
      ? typeof IVRegularity?.repeat_until === "number"
        ? String(IVRegularity?.repeat_until)
        : IVRegularity?.repeat_until === null
        ? null
        : new Date(IVRegularity?.repeat_until)
      : null,
  });

  console.log("time_created_task", new Date(IVRegularity?.time_created_task));

  const { IV: dayRegularity, changeIV: setDayRegularity } = useChangeIV({
    each: IVRegularity?.day?.each || 1,
    day_type: IVRegularity?.day?.day_type || "any",
    interva_month: IVRegularity?.day?.interva_month || 0,
  });

  const { IV: weekRegularity, changeIV: setWeekregularity } = useChangeIV({
    each: IVRegularity?.week?.each || 1,
    day_of_the_week: IVRegularity?.week?.day_of_the_week || [],
  });

  const { IV: monthRegularity, changeIV: setMonthRegularity } = useChangeIV({
    choose: IVRegularity?.month?.each_day && IVRegularity?.month?.each_day_month ? 0 : 1,

    each_day: IVRegularity?.month?.each_day || 1,
    each_day_month: IVRegularity?.month?.each_day_month || 1,

    each_order_day: IVRegularity?.month?.each_order_day || 1,
    each_order_day_day_of_week: IVRegularity?.month?.each_order_day_day_of_week || "mo",
    each_order_day_month: IVRegularity?.month?.each_order_day_month || 1,
  });

  const { IV: yearRegularity, changeIV: setYearRegularity } = useChangeIV({
    choose:
      IVRegularity?.year?.each_month !== null && IVRegularity?.year?.each_month_month !== null
        ? 0
        : 1,

    each_month: IVRegularity?.year?.each_month || 1,
    each_month_month: IVRegularity?.year?.each_month_month || 0,

    each_order_month: IVRegularity?.year?.each_order_month || 1,
    each_order_month_day_of_week: IVRegularity?.year?.each_order_month_day_of_week || "mo",
    each_order_month_month: IVRegularity?.year?.each_order_month_month || 0,
  });

  const getOptionsMonth = () => {
    // * each_order_day
    const options_each_order_day = [
      { label: "первый", value: 1 },
      { label: "второй", value: 2 },
      { label: "третий", value: 3 },
      { label: "четвертый", value: 4 },
      { label: "последний", value: 5 },
    ];

    const value_each_order_day = monthRegularity.each_order_day
      ? options_each_order_day.find((o) => o.value === monthRegularity.each_order_day)
      : options_each_order_day[0];

    // * each_order_day_day_of_week
    const options_each_order_day_day_of_week = [
      { label: "понедельник", value: "mo" },
      { label: "вторник", value: "tu" },
      { label: "среда", value: "we" },
      { label: "четверг", value: "th" },
      { label: "пятница", value: "fr" },
      { label: "суббота", value: "sa" },
      { label: "воскресенье", value: "su" },
    ];

    const value_each_order_day_day_of_week = monthRegularity.each_order_day_day_of_week
      ? options_each_order_day_day_of_week.find(
          (o) => o.value === monthRegularity.each_order_day_day_of_week,
        )
      : options_each_order_day_day_of_week[0];

    return {
      each_order_day: {
        value: value_each_order_day,
        options: options_each_order_day,
      },
      each_order_day_day_of_week: {
        value: value_each_order_day_day_of_week,
        options: options_each_order_day_day_of_week,
      },
    };
  };

  const getOptionsYear = () => {
    // * each_order_month
    const options_each_order_month = [
      { label: "первый", value: 1 },
      { label: "второй", value: 2 },
      { label: "третий", value: 3 },
      { label: "четвертый", value: 4 },
      { label: "последний", value: 5 },
    ];

    const value_each_order_month = yearRegularity.each_order_month
      ? options_each_order_month.find((o) => o.value === yearRegularity.each_order_month)
      : options_each_order_month[0];

    // * each_order_month_day_of_week
    const options_each_order_month_day_of_week = [
      { label: "понедельник", value: "mo" },
      { label: "вторник", value: "tu" },
      { label: "среда", value: "we" },
      { label: "четверг", value: "th" },
      { label: "пятница", value: "fr" },
      { label: "суббота", value: "sa" },
      { label: "воскресенье", value: "su" },
    ];

    const value_each_order_month_day_of_week = yearRegularity.each_order_month_day_of_week
      ? options_each_order_month_day_of_week.find(
          (o) => o.value === yearRegularity.each_order_month_day_of_week,
        )
      : options_each_order_month_day_of_week[0];

    // * each_month_month
    const options_each_month_month = [
      { label: "январь", value: 0 },
      { label: "февраль", value: 1 },
      { label: "март", value: 2 },
      { label: "апрель", value: 3 },
      { label: "май", value: 4 },
      { label: "июнь", value: 5 },
      { label: "июль", value: 6 },
      { label: "август", value: 7 },
      { label: "сентябрь", value: 8 },
      { label: "октябрь", value: 9 },
      { label: "ноябрь", value: 10 },
      { label: "декабрь", value: 11 },
    ];

    const value_each_month_month = yearRegularity.each_order_month_day_of_week
      ? options_each_month_month.find((o) => o.value === yearRegularity.each_month_month)
      : options_each_month_month[0];

    // * each_order_month_month
    const value_each_order_month_month = yearRegularity.each_order_month_month
      ? options_each_month_month.find((o) => o.value === yearRegularity.each_order_month_month)
      : options_each_month_month[0];

    return {
      each_order_month: {
        value: value_each_order_month,
        options: options_each_order_month,
      },
      each_order_month_day_of_week: {
        value: value_each_order_month_day_of_week,
        options: options_each_order_month_day_of_week,
      },
      each_month_month: {
        value: value_each_month_month,
        options: options_each_month_month,
      },
      each_order_month_month: {
        value: value_each_order_month_month,
        options: options_each_month_month,
      },
    };
  };

  const { options, changeOptions } = useChangeOptions({
    // for month
    each_order_day: getOptionsMonth().each_order_day,
    each_order_day_day_of_week: getOptionsMonth().each_order_day_day_of_week,

    // for year
    each_order_month_day_of_week: getOptionsYear().each_order_month_day_of_week,
    each_order_month: getOptionsYear().each_order_month,
    each_month_month: getOptionsYear().each_month_month,
    each_order_month_month: getOptionsYear().each_order_month_month,

    // for day
    day_type: {
      value:
        dayRegularity.day_type === "any"
          ? { label: "любой день", value: "any" }
          : { label: "рабочий день", value: "working" },
      options: [
        { label: "любой день", value: "any" },
        { label: "рабочий день", value: "working" },
      ],
    },
  });

  const changeRegularityIV = (e) => {
    const type = baseSetting.type;
    if (type === "day") {
      setDayRegularity(e);
    } else if (type === "week") {
      setWeekregularity(e);
    } else if (type === "month") {
      setMonthRegularity(e);
    } else if (type === "year") {
      setYearRegularity(e);
    }
  };

  const changeRegularityOptions = (name, value) => {
    const type = baseSetting.type;
    if (type === "day") {
      changeOptions(name, "value", value);
      if (name === "day_type") {
        setDayRegularity("set", { name: "day_type", value: value.value });
      }
    } else if (type === "month") {
      changeOptions(name, "value", value);
      if (name === "each_order_day") {
        setMonthRegularity("set", {
          name: "each_order_day",
          value: value.value,
        });
      }
      if (name === "each_order_day_day_of_week") {
        setMonthRegularity("set", {
          name: "each_order_day_day_of_week",
          value: value.value,
        });
      }
    } else if (type === "year") {
      changeOptions(name, "value", value);
      if (name === "each_order_month") {
        setYearRegularity("set", {
          name: "each_order_month",
          value: value.value,
        });
      }
      if (name === "each_order_month_day_of_week") {
        setYearRegularity("set", {
          name: "each_order_month_day_of_week",
          value: value.value,
        });
      }
      if (name === "each_month_month") {
        setYearRegularity("set", {
          name: "each_month_month",
          value: value.value,
        });
      }
      if (name === "each_order_month_month")
        setYearRegularity("set", {
          name: "each_order_month_month",
          value: value.value,
        });
    }
  };

  const changeType = (value) => {
    setBaseSetting("set", { name: "type", value });
  };

  const setWeekdays = (day) => {
    if (weekRegularity.day_of_the_week.includes(day)) {
      return setWeekregularity("set", {
        name: "day_of_the_week",
        value: weekRegularity.day_of_the_week.filter((d) => d !== day),
      });
    }

    setWeekregularity("set", {
      name: "day_of_the_week",
      value: [...weekRegularity.day_of_the_week, day],
    });
  };

  const getRegularityForTask = () => {
    let resultRegularity = {};

    if (baseSetting.type === "day") {
      resultRegularity = {
        day: dayRegularity,
        week: null,
        month: null,
        year: null,
      };
    } else if (baseSetting.type === "week") {
      resultRegularity = {
        day: null,
        week: weekRegularity,
        month: null,
        year: null,
      };
    } else if (baseSetting.type === "month") {
      resultRegularity = {
        day: null,
        week: null,
        month: {
          ...monthRegularity,
          ...(monthRegularity.choose === 0
            ? {
                each_order_day: null,
                each_order_day_day_of_week: null,
                each_order_day_month: null,
              }
            : {
                each_day: null,
                each_day_month: null,
              }),
        },
        year: null,
      };
    } else if (baseSetting.type === "year") {
      resultRegularity = {
        day: null,
        week: null,
        month: null,
        year: {
          ...yearRegularity,
          ...(yearRegularity.choose === 0
            ? {
                each_order_month: null,
                each_order_month_day_of_week: null,
                each_order_month_month: null,
              }
            : {
                each_month: null,
                each_month_month: null,
              }),
        },
      };
    }

    resultRegularity = { ...baseSetting, ...resultRegularity };

    return resultRegularity;
  };

  const setMonthChoose = (value) => {
    setMonthRegularity("set", { name: "choose", value });
  };

  useEffect(() => {
    changeIVTask("set", {
      name: "regularity",
      value: getRegularityForTask(),
    });
  }, [baseSetting, dayRegularity, weekRegularity, monthRegularity, yearRegularity, options]);

  const getInfoRegular = () => {
    return {
      status: IVRegularity && IVRegularity.completed ? "Завершена" : "Активна",
      date_of_create_task: IVRegularity?.date_of_create_task
        ? dayjs(IVRegularity?.date_of_create_task).format("DD.MM.YYYY hh:mm")
        : "-",
    };
  };

  const getDayOfWeekFullName = (dayOfWeekArr) => {
    return dayOfWeekArr.map((day) => {
      if (day === "mo") {
        return "понедельник";
      } else if (day === "tu") {
        return "вторник";
      } else if (day === "we") {
        return "среда";
      } else if (day === "th") {
        return "четверг";
      } else if (day === "fr") {
        return "пятница";
      } else if (day === "sa") {
        return "суббота";
      } else if (day === "su") {
        return "воскресенье";
      }
    });
  };

  const getDescriptionReg = () => {
    let title = type === "task" ? "Задача повторяется " : "Мероприятие повторяется ";

    // * day
    if (baseSetting.type === "day") {
      title += `каждый ${dayRegularity.each}`;
      title += ` ${options.day_type.value.label}`;

      if (+dayRegularity.interva_month) {
        title += ` с интервалом в ${dayRegularity.interva_month} месяц`;
      }
    }
    // * week
    else if (baseSetting.type === "week") {
      title += `каждую ${weekRegularity.each} неделю`;
      if (weekRegularity.day_of_the_week.length) {
        const days = getDayOfWeekFullName(weekRegularity.day_of_the_week);
        title += ` в ${days.join(", ")}`;
      }
    }
    // * month
    else if (baseSetting.type === "month") {
      if (monthRegularity.choose === 0) {
        title += `каждое ${monthRegularity.each_day} число каждого`;
        title += ` ${monthRegularity.each_day_month} месяца`;
      } else {
        title += `каждый ${options.each_order_day.value.label}`;
        title += ` ${options.each_order_day_day_of_week.value.label}`;
        title += ` каждого ${monthRegularity.each_order_day_month} месяца`;
      }
    }
    // * year
    else if (baseSetting.type === "year") {
      if (yearRegularity.choose === 0) {
        title += `каждый ${yearRegularity.each_month} день`;
        title += ` месяца ${options.each_month_month.value.label}`;
      } else {
        title += `каждый ${options.each_order_month.value.label}`;
        title += ` ${options.each_order_month_day_of_week.value.label}`;
        title += ` месяца ${options.each_order_month_month.value.label}`;
      }
    }

    if (baseSetting.repeat_until === null) {
      title += `, без даты окончания.`;
    } else if (baseSetting.repeat_until instanceof Date) {
      title += `, завершиться ${dayjs(baseSetting.repeat_until).format("YYYY.MM.DD")}`;
    } else if (typeof baseSetting.repeat_until === "string") {
      title += `, завершиться после ${baseSetting.repeat_until} повторений`;
    }

    return title;
  };

  return (
    <div className="regularityForm">
      <div className="regularityForm-repeatability">
        <h2 className="regularityForm-repeatability-subtitle">Регулярность</h2>
        {/* <div className="regularityForm-repeatability-textBox">
          <span className="regularityForm-repeatability-title">Статус:</span>
          <span className="regularityForm-repeatability-text"> {getInfoRegular().status}</span>
        </div>
        <div className="regularityForm-repeatability-textBox">
          <span className="regularityForm-repeatability-title">Когда создастся еще раз:</span>
          <span className="regularityForm-repeatability-text">
            {getInfoRegular().date_of_create_task}
          </span>
        </div> */}
        {/* <div className="regularityForm-repeatability-textBox">
          <span className="regularityForm-repeatability-title">Завершение: </span>
          <span className="regularityForm-repeatability-text">
            {IVRegularity?.repeat_until
              ? typeof IVRegularity?.repeat_until === "string"
                ? `После ${String(IVRegularity?.repeat_until)} повторений`
                : IVRegularity?.repeat_until === null
                ? "Никогда"
                : dayjs(IVRegularity?.repeat_until).format("DD.MM.YYYY hh:mm")
              : "Никогда"}
          </span>
        </div> */}

        <div className="regularityForm-repeatability-choose">
          <span className="regularityForm-title">Повторяемость:</span>
          <div className="regularityForm-repeatability-choose-btns">
            <div
              onClick={() => changeType("day")}
              className={`regularityForm-repeatability-choose-btn-item ${
                baseSetting.type === "day" ? "active" : ""
              }`}
            >
              <span className="regularityForm-text">День</span>
            </div>
            <div
              onClick={() => changeType("week")}
              className={`regularityForm-repeatability-choose-btn-item ${
                baseSetting.type === "week" ? "active" : ""
              }`}
            >
              <span className="regularityForm-text">Неделя</span>
            </div>
            <div
              onClick={() => changeType("month")}
              className={`regularityForm-repeatability-choose-btn-item ${
                baseSetting.type === "month" ? "active" : ""
              }`}
            >
              <span className="regularityForm-text">Месяц</span>
            </div>
            <div
              onClick={() => changeType("year")}
              className={`regularityForm-repeatability-choose-btn-item ${
                baseSetting.type === "year" ? "active" : ""
              }`}
            >
              <span className="regularityForm-text">Год</span>
            </div>
          </div>
        </div>
        {/* Повторяемость ДЕНЬ */}
        {baseSetting.type === "day" && (
          <div className="regularityForm-repeatability-choose-item">
            <div className="regularityForm-repeatability-choose-item-wrapper">
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <span className="regularityForm-text">Каждый</span>
                <Input
                  onChange={changeRegularityIV}
                  value={dayRegularity.each}
                  type="number"
                  name="each"
                  min
                />
                <Select
                  options={options.day_type.options}
                  value={options.day_type.value}
                  handleChange={(data) => changeRegularityOptions("day_type", data)}
                  style={{ width: "209px" }}
                  isClearable={false}
                />
              </div>
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <span className="regularityForm-text">с интервалом</span>
                <Input
                  onChange={changeRegularityIV}
                  value={dayRegularity.interva_month}
                  type="number"
                  name="interva_month"
                  min
                />
                <span className="regularityForm-text">мес</span>
              </div>
            </div>
          </div>
        )}
        {/* Повторяемость НЕДЕЛЯ */}
        {baseSetting.type === "week" && (
          <div className="regularityForm-repeatability-choose-item">
            <div className="d-flex gap-10 align-center">
              <span className="regularityForm-text">Каждую:</span>
              <Input
                onChange={changeRegularityIV}
                value={weekRegularity.each}
                type="number"
                name="each"
                min
              />
              <span className="regularityForm-text">неделю</span>
            </div>
            <div className="regularityForm-repeatability-choose-item-week">
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("mo")}
                  onCheck={() => setWeekdays("mo")}
                />
                <span className="regularityForm-text">Пн</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("tu")}
                  onCheck={() => setWeekdays("tu")}
                />
                <span className="regularityForm-text">Вт</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("we")}
                  onCheck={() => setWeekdays("we")}
                />
                <span className="regularityForm-text">Ср</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("th")}
                  onCheck={() => setWeekdays("th")}
                />
                <span className="regularityForm-text">Чт</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("fr")}
                  onCheck={() => setWeekdays("fr")}
                />
                <span className="regularityForm-text">Пт</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("sa")}
                  onCheck={() => setWeekdays("sa")}
                />
                <span className="regularityForm-text">Сб</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-week-day">
                <Checkbox
                  check={weekRegularity.day_of_the_week.includes("su")}
                  onCheck={() => setWeekdays("su")}
                />
                <span className="regularityForm-text">Вс</span>
              </div>
            </div>
          </div>
        )}
        {/* Повторяемость МЕСЯЦ */}
        {baseSetting.type === "month" && (
          <div className="regularityForm-repeatability-choose-item">
            <div className="regularityForm-repeatability-choose-item-wrapperMonth">
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <InputRadio
                  check={monthRegularity.choose === 0}
                  onCheck={() => setMonthChoose(0)}
                  title="Каждое:"
                  position="left"
                />
                <Input
                  value={monthRegularity.each_day}
                  onChange={changeRegularityIV}
                  name="each_day"
                  min
                />
                <span className="regularityForm-text">число</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <span className="regularityForm-text">каждого</span>
                <Input
                  value={monthRegularity.each_day_month}
                  onChange={changeRegularityIV}
                  name="each_day_month"
                  min
                />
                <span className="regularityForm-text">месяца</span>
              </div>
            </div>
            <div className="regularityForm-repeatability-choose-item-wrapperMonth">
              <InputRadio
                check={monthRegularity.choose === 1}
                onCheck={() => setMonthChoose(1)}
                title="Каждый:"
                position="left"
              />
              <Select
                value={options.each_order_day.value}
                options={options.each_order_day.options}
                handleChange={(value) => changeRegularityOptions("each_order_day", value)}
                style={{ width: "209px" }}
                isClearable={false}
              />
              <Select
                value={options.each_order_day_day_of_week.value}
                options={options.each_order_day_day_of_week.options}
                handleChange={(value) =>
                  changeRegularityOptions("each_order_day_day_of_week", value)
                }
                style={{ width: "209px" }}
                isClearable={false}
              />
              <span className="regularityForm-text">каждого</span>
              <Input
                value={monthRegularity.each_order_day_month}
                onChange={changeRegularityIV}
                name="each_order_day_month"
              />
              <span className="regularityForm-text">месяца</span>
            </div>
          </div>
        )}
        {/* Повторяемость ГОД */}
        {baseSetting.type === "year" && (
          <div className="regularityForm-repeatability-choose-item">
            <div className="regularityForm-repeatability-choose-item-wrapperYear">
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <InputRadio
                  check={yearRegularity.choose === 0}
                  onCheck={() => setYearRegularity("set", { name: "choose", value: 0 })}
                  title="Каждый:"
                  position="left"
                />
                <Input
                  value={yearRegularity.each_month}
                  onChange={(e) => changeRegularityIV(e)}
                  name="each_month"
                  min
                />
                <span className="regularityForm-text">день</span>
              </div>
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <span className="regularityForm-text">месяца</span>
                <Select
                  options={options.each_month_month.options}
                  value={options.each_month_month.value}
                  handleChange={(value) => changeRegularityOptions("each_month_month", value)}
                  style={{ width: "209px" }}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="regularityForm-repeatability-choose-item-wrapperYear">
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <InputRadio
                  check={yearRegularity.choose === 1}
                  onCheck={() => setYearRegularity("set", { name: "choose", value: 1 })}
                  title="Каждый:"
                  position="left"
                />
                <Select
                  value={options.each_order_month.value}
                  options={options.each_order_month.options}
                  handleChange={(value) => changeRegularityOptions("each_order_month", value)}
                  style={{ width: "209px" }}
                  isClearable={false}
                />
              </div>
              <Select
                value={options.each_order_month_day_of_week.value}
                options={options.each_order_month_day_of_week.options}
                handleChange={(value) =>
                  changeRegularityOptions("each_order_month_day_of_week", value)
                }
                style={{ width: "209px" }}
                isClearable={false}
              />
              <div className="regularityForm-repeatability-choose-item-wrapper-item">
                <span className="regularityForm-text">месяца</span>
                <Select
                  options={options.each_order_month_month.options}
                  value={options.each_order_month_month.value}
                  handleChange={(value) => changeRegularityOptions("each_order_month_month", value)}
                  style={{ width: "209px" }}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        )}

        {/* -------------------- */}
        <div className="regularityForm-repeatability-addTimeDate">
          <InputTime
            type="time"
            onChange={(value) => {
              value = dayjs().hour(value.split(":")[0]).minute(value.split(":")[1]);
              setBaseSetting("set", {
                name: "time_created_task",
                value: value.toDate(),
              });
            }}
            value={dayjs(baseSetting.time_created_task).format("HH:mm")}
            name="time_created_task"
            title={`Время создания ${type === "task" ? "задачи" : "мероприятия"}:`}
          />
          <InputDate
            onChange={(value) => setBaseSetting("set", { name: "start_repeating", value })}
            value={baseSetting.start_repeating}
            name="start_repeating"
            title="Начинать повторение:"
          />
        </div>
        <div className="regularityForm-repeatUntil">
          <span className="regularityForm-title">Повторять до:</span>
          <InputRadio
            check={baseSetting.repeat_until === null}
            onCheck={() => setBaseSetting("set", { name: "repeat_until", value: null })}
            title="Нет даты окончания"
          />
          <div className="regularityForm-repeatability-choose-item-wrapper">
            <div className="regularityForm-repeatability-choose-item-wrapper-item">
              <InputRadio
                check={baseSetting.repeat_until instanceof Date}
                onCheck={() =>
                  setBaseSetting("set", {
                    name: "repeat_until",
                    value: new Date(),
                  })
                }
                title="Дата окончания"
              />
              <InputDate
                value={
                  baseSetting.repeat_until instanceof Date ? baseSetting.repeat_until : new Date()
                }
                onChange={(value) => {
                  setBaseSetting("set", { name: "repeat_until", value });
                }}
              />
            </div>
          </div>
          <div className="regularityForm-repeatability-choose-item-wrapper">
            <div className="regularityForm-repeatability-choose-item-wrapper-item">
              <InputRadio
                check={typeof baseSetting.repeat_until === "string"}
                onCheck={() =>
                  setBaseSetting("set", {
                    name: "repeat_until",
                    value: "0",
                  })
                }
                title="Завершить после"
              />
              <Input
                value={
                  typeof baseSetting.repeat_until === "string" ? baseSetting.repeat_until : "0"
                }
                type="number"
                onChange={(e) =>
                  setBaseSetting("set", {
                    name: "repeat_until",
                    value: `${e.target.value}`,
                  })
                }
                min
              />
              <span className="regularityForm-text">повторений</span>
            </div>
          </div>
        </div>
        <span className="regularityForm-description">{getDescriptionReg()}</span>
      </div>
    </div>
  );
};

export default RegularityForm;
