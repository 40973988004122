import React, { useMemo, useState } from "react";
import "./ListProfiles.scss";
import SearchModal from "../../components/SearchModal/SearchModal";

const ListProfileItem = ({ info, onClick }) => {
  return (
    <li className={`listProfileItem `} title={info.name} onClick={onClick}>
      <span> {info.name}</span>

      {/* <div className="listProfileItem-btns">
        {loading ? <div className="submit-spinner-lm"></div> : ""}
      </div> */}
    </li>
  );
};

const ListProfiles = ({ title, profiles, onClick, placeholder = "Введите ФИО" }) => {
  const [textValue, setTextValue] = useState("");

  const changeValue = (value) => {
    setTextValue(value);
  };

  const filteredProfiles = useMemo(() => {
    const res = profiles.filter((elem) => {
      if (elem?.name?.toLowerCase()?.includes(textValue?.toLowerCase())) {
        return elem;
      }
      if (elem.name === null) {
        return elem;
      }
    });

    return res;
  }, [textValue, profiles]);

  return (
    <div className="listProfiles">
      {!!title && <h4 className="listProfiles-title">{title}</h4>}
      <SearchModal onChange={changeValue} placeholder={placeholder} />
      <div className="listProfiles-grid customScroll">
        {filteredProfiles.map((profile) => {
          return (
            <ListProfileItem info={profile} onClick={() => onClick(profile)} key={profile._id} />
          );
        })}
      </div>
    </div>
  );
};

export default ListProfiles;
