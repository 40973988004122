import React, { useId } from "react";
import ModalWrapper from "../../../../../ModalWrapper/ModalWrapper";
import { useModal } from "../../../../../../hooks/useModal";
import Input from "../../../../../Input/Input";
import Button from "../../../../../Button/Button";
import "./ModallAddEditCatalogWork.scss";
import { useChangeIV } from "../../../../../../hooks/useChangeIV";
import { asyncСreateCatalogWork, asyncUpdateCatalogWork } from "../../../../../../store/reducers/catalogWorkReducer";
import { useDispatch } from "react-redux";
import Screen from "../../../../../Screen/Screen";

const ModallAddEditCatalogWork = (props) => {
  const { edit = false, handleChange } = props;
  const formId = useId();

  const dispatch = useDispatch();
  const { changeModalVisible, getInfo } = useModal();

  const info = getInfo("editCatalogWork", "data");

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: edit ? info.name : "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3, maxHeight: 100 })) return;
    if (edit) {
      await dispatch(asyncUpdateCatalogWork({ id: info._id, name: IV.name }));
    } else {
      await dispatch(asyncСreateCatalogWork({ name: IV.name }));
    }

    changeModalVisible(edit ? "editCatalogWork" : "addCatalogWork", false);
    handleChange && handleChange();
  };
  return (
    <ModalWrapper
      onClose={() => changeModalVisible(edit ? "editCatalogWork" : "addCatalogWork", false)}
      title={`${edit ? "Редактировать" : "Добавить"} наименование работ в библиотеку`}
      minHeight={"150px"}
      btns={
        <div className="modallAddEditCatalogWork-btn-wrapper">
          <Button
            disabled={IV.name.length <= 3}
            type="submit"
            title={edit ? "Сохранить" : "Создать"}
            color="blue"
            form={formId}
          />
        </div>
      }
    >
      <form
        onSubmit={onSubmit}
        className="modallAddEditCatalogWork"
        id={formId}
      >
        <Input
          title="Название работы:*"
          placeholder="Введите название работы"
          name="name"
          value={IV.name}
          onChange={changeIV}
          validate={checkValidateIV("name")}
        />
        <Screen size={"lg"}>
          <div className="modallAddEditCatalogWork-btn-wrapper">
            <Button
              disabled={IV.name.length <= 3}
              type="submit"
              title={edit ? "Сохранить" : "Создать"}
              color="blue"
              form={formId}
            />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModallAddEditCatalogWork;
