import React, { useState, useRef } from "react";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import "./StructuresListHeader.scss";
import Icons from "../../../../../Icons/Icons";
import Screen from "../../../../../Screen/Screen";
import { getFullPath } from "../../../../../../utils/getFullPath";
import { useModal } from "../../../../../../hooks/useModal";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";

const StructuresListHeader = ({ employeesLength, director, info }) => {
  const { edit, add, remove } = useHasPermissions("structuresCard", info);
  const { changeModalVisible } = useModal();

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const onEvent = (name) => {
    if (name === "add") {
      setMenuVisible(false);
      return changeModalVisible("addDepartment", true, info);
    }

    if (name === "info") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info);
    }

    if (name === "edit") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info, true);
    }

    if (name === "menu") {
      return changeModalVisible("menu", true, info);
    }
  };

  return (
    <div className="structuresListHeader">
      <div className="structuresListHeader__left">
        <Screen size={"xxs"}>
          {!!director?.user && (
            <div className="structuresListHeader__avatar structuresListHeader-avatar">
              <img
                className="structuresListHeader-avatar__img"
                crossOrigin="anonymous"
                src={getFullPath(director.user.avatar, "avatar")}
                alt=""
              />
            </div>
          )}
        </Screen>
        <div className="structuresListHeader__info structuresListHeader-info">
          <h4 className="structuresListHeader__title">
            {info.name || "Администрация"}
            {` (сотрудников: ${employeesLength})`}
          </h4>
          {!!director?.user && (
            <span className="structuresListHeader__text">{`${getFormatFIO(director.user)} (${
              director?.position?.name
            })`}</span>
          )}
        </div>
      </div>
      <Screen size={"lg"}>
        <button className="structuresListHeader__btn" onClick={() => setMenuVisible(!menuVisible)}>
          <Icons
            type={"menu"}
            size={"md"}
            color={"white"}
            cursor
            title={{ visible: true, text: "" }}
          />
        </button>
      </Screen>
      <Screen size={"lg"} down>
        <div className="structuresListHeader__btn" onClick={() => onEvent("menu")}>
          <Icons type={"menu"} size={"m-md"} color={"white"} cursor />
        </div>
      </Screen>
      <Screen size={"lg"}>
        {menuVisible && (
          <div
            ref={ref}
            className="structuresListHeader__menu-window structuresListHeader-menu-window animate__animated animate__fadeIn  modal"
          >
            <span onClick={() => onEvent("info")}>Информация об отделе</span>
            {edit && <span onClick={() => onEvent("edit")}>Редактировать отдел</span>}
            {add && <span onClick={() => onEvent("add")}>Добавить отдел</span>}
          </div>
        )}
      </Screen>
    </div>
  );
};

export default StructuresListHeader;
