import React, { useState, useRef } from "react";
import { getFormatFIO } from "../../utils/getFormatFIO";
import "./CompaniesGridCard.scss";
import { transformTypeCompany } from "../../utils/transformTypeCompany";
import Icons from "../Icons/Icons";
import { getFullPath } from "../../utils/getFullPath";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import CardGridHeader from "../CardGridHeader/CardGridHeader";
import { countCompanyEmployess } from "../../utils/countCompanyEmployess.js";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const CompaniesGridCard = ({ info, changeModalVisible, project = false }) => {
  const { edit, remove } = useHasPermissions("companiesCard", info);

  const { profiles, logo, name, director, region, type, phone, email, inn } = info;
  const [menuVisible, setMenuVisible] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setMenuVisible(false));

  const onEvent = (name) => {
    if (name === "info") return changeModalVisible("infoCompany", true, info);
    if (name === "edit") return changeModalVisible("infoCompany", true, info, true);
    if (name === "remove") return changeModalVisible("deleteCompany", true, info);
  };

  return (
    <li className="companiesGridCard">
      <div className="companiesGridCard__header companiesGridCard-header">
        <div className="companiesGridCard-header__content">
          <CardGridHeader
            title={"Сотрудников:"}
            text={countCompanyEmployess(profiles) || 1}
            onClick={() => setMenuVisible(!menuVisible)}
          />
          <img
            crossOrigin="anonymous"
            className="companiesGridCard-header__logoCompany"
            src={getFullPath(logo, "logo")}
            alt={name}
          />
        </div>
        <span className="companiesGridCard-header__name">{name}</span>
      </div>
      <ul className="companiesGridCard__info companiesGridCard-info">
        <li className="companiesGridCard-info__item">
          <span className="companiesGridCard-info__title">Руководитель:</span>
          <span className="companiesGridCard-info__text">{getFormatFIO(director)}</span>
        </li>
        <li className="companiesGridCard-info__item">
          <span className="companiesGridCard-info__title">Город:</span>
          <span className="companiesGridCard-info__text">{region.name}</span>
        </li>
        <li className="companiesGridCard-info__item">
          <span className="companiesGridCard-info__title">Тип:</span>
          <span className="companiesGridCard-info__text">{transformTypeCompany(type)}</span>
        </li>
        <li className="companiesGridCard-info__item">
          <span className="companiesGridCard-info__title">ИНН:</span>
          <span className="companiesGridCard-info__text">{inn}</span>
        </li>
      </ul>
      <ul className="companiesGridCard__footer companiesGridCard-footer">
        <li className="companiesGridCard-footer__item">
          <Icons
            type={"phone"}
            size={"md"}
            className="companiesGridCard-footer__img"
            color={"white"}
          />
          <a className="companiesGridCard-footer__text" href={`tel: ${phone}`}>
            {phone}
          </a>
        </li>
        <li className="companiesGridCard-footer__item">
          <Icons
            type={"mail"}
            size={"md"}
            className="companiesGridCard-footer__img"
            color={"white"}
          />
          <a className="companiesGridCard-footer__text" href={`mailto:${email}`}>
            {email}
          </a>
        </li>
      </ul>
      {menuVisible && (
        <div ref={ref} className="companiesGridCard__menu-window companiesGridCard-menu-window">
          <span onClick={() => onEvent("info")}>Информация о компании</span>
          {!project && edit && <span onClick={() => onEvent("edit")}>Редактировать компанию</span>}
          {remove && <span onClick={() => onEvent("remove")}>Удалить компанию</span>}
        </div>
      )}
    </li>
  );
};
export default CompaniesGridCard;
