import React from "react";
import "./SkeletonStucuresRow.scss";
import SceletonLine from "../SkeletonLine/SkeletonLine";
import SceletonBox from "../SkeletonBox/SkeletonBox";
import Screen from "../../../../../Screen/Screen";
const SkeletonStucuresRow = () => {
  return (
    <div className="skeletonStucuresRow">
      <div className="skeletonStucuresRow-header">
        <SceletonBox />{" "}
        <div className="skeletonStucuresRow-header-wrapper">
          <SceletonLine width={"15%"} />
          <SceletonLine width={"20%"} />
        </div>
      </div>
      <ul className="skeletonStucuresRow-list">
        {Array(10)
          .fill(0)
          .map((e, i) => (
            <li className="skeletonStucuresRow-item" key={i}>
              <SceletonLine width={"15%"} />
              <Screen size={"lg"}>
                <SceletonLine width={"20%"} />
              </Screen>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SkeletonStucuresRow;
