import React, { useState, useEffect, useRef } from "react";
import "./StructuresGrid.scss";
import StructuresGridCard from "./components/StructuresGridCard/StructuresGridCard";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Icons from "./../../../Icons/Icons";

const StructuresGrid = ({ structures }) => {
  const arrStr = [structures];

  const transformOptions = {
    initialScale: 1,
    minScale: 0.5,
    maxScale: 2,
  };

  return (
    <div className="structuresGrid">
      <div className="structuresGrid-wrapper-transform">
        <TransformWrapper
          initialScale={1}
          options={transformOptions}
          initialPositionX={0}
          initialPositionY={100}
          limitToBounds={false}
          minScale={0.5}
          maxScale={3}
          // centerZoomedOut={true}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent>
                <div className="structuresGrid-content">
                  <StructuresGridCard structures={arrStr} grade={"one"} />
                </div>
              </TransformComponent>
              <div className="structuresGrid-tools">
                <button
                  className="structuresGrid-tools-btn plus"
                  onClick={() => zoomIn()}
                >
                  <Icons
                    type={"plus"}
                    size={"lg"}
                    color={"white"}
                    title={{ visible: true, text: "" }}
                  />
                </button>
                <button
                  className="structuresGrid-tools-btn minus"
                  onClick={() => zoomOut()}
                >
                  <Icons
                    type={"minus"}
                    size={"lg"}
                    title={{ visible: true, text: "Уменьшить" }}
                  />
                </button>
                <button
                  className="structuresGrid-tools-btn reset"
                  onClick={() => resetTransform()}
                >
                  <Icons
                    type={"plus"}
                    size={"lg"}
                    color={"white"}
                    title={{ visible: true, text: "Отмена" }}
                  />
                </button>
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default StructuresGrid;
