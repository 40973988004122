import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ProjectMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import {
  setProject,
  asyncGetProjectEmployees,
  asyncGetProjectCompanies,
  asyncGetProtocols,
} from "../../../store/reducers/projectsReducer";
import { useFilter } from "../../../hooks/useFilter";
import BasicInformationMobile from "./components/BasicInformationMobile/BasicInformationMobile";
import ParticipantsMobile from "./components/ParticipantsMobile/ParticipantsMobile";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import CompaniesMobile from "./components/CompaniesMobile/CompaniesMobile";
import DocumentationProjectMobile from "./components/DocumentationProjectMobile/DocumentationProjectMobile";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import { asyncDeleteEmployeesFromProject } from "../../../store/reducers/projectsReducer";
import { useSelects } from "../../../hooks/useSelects";
import ModalAddParticipantMobile from "./components/ModalAddParticipantMobile/ModalAddParticipantMobile";
import ModalAddCompanyMobile from "./components/ModalAddCompanyMobile/ModalAddCompanyMobile";
import PhotoReportsMobile from "./components/PhotoReportsMobile/PhotoReportsMobile";
import ProjectWorkScheduleMobile from "./components/ProjectWorkScheduleMobile/ProjectWorkScheduleMobile";
import { useModal } from "../../../hooks/useModal";
import MeetingMinutesMobile from "./components/MeetingMinutesMobile/MeetingMinutesMobile";
import LayersMobile from "./components/LayersMobile/LayersMobile";
import { useSearch } from "../../../hooks/useSearch";
import { transformForSelect } from "../../../utils/transformForSelect";
import ProjectTasksMobile from "./components/ProjectTasksMobile/ProjectTasksMobile";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const ProjectMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chageFitlers } = useFilter();
  const { getVisible, changeModalVisible, getInfo } = useModal();
  /* basicInformation | participants | companiesInProject | layers
   | documentation | meetingMinutes | projectWorkSchedule | photoReports */
  const [chapter, setChapter] = useState("basicInformation");
  const [viewChapterPhoto, setVievChapterPhoto] = useState("grid"); // small-grid | grid | big-grid
  const [viewCards, setViewCards] = useState("rows"); // columns | rows

  const [participants, setParticipants] = useState([]);
  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(participants);

  const [numberPageParticipants, setNumberPageParticipants] = useState(1);
  const [numberPageCompany, setNumberPageCompany] = useState(1);

  const [infoTitle, setInfoTitle] = useState("");
  const [participantsLength, setParticipantsLength] = useState(0);
  const [companiesInProjectLength, setCompaniesInProjectLength] = useState(0);
  const [layersLength, setLayersLength] = useState(0);
  const [tasksLength, setTasksLength] = useState(0);
  const [documentationLength, setDocumentationLength] = useState(0);
  const [meetingMinutesLength, setMeetingMinutesLength] = useState(0);
  const [projectWorkScheduleLength, setProjectWorkScheduleLength] = useState(0);
  const [photoReportsLength, setPhotoReportsLength] = useState(0);

  const [currentFolder, setCurrentFolder] = useState("all");

  const [currentLayer, setCurrentLayer] = useState(null);

  const { goToPage, params } = useSearch();

  const { project } = useSelector((state) => state.projects);
  const { visible } = useHasPermissions("project", project);

  const [added, setAdded] = useState({ visible: true, onClick: null });

  const [search, setSearch] = useState({
    inputSearch: {
      visible: false,
      placeholder: "Найти участника по ФИО",
    },
    onChange: null,
    onSubmit: null,
  });

  const changeWiewChapter = (value) => {
    setChapter(value);
    chageFitlers("clear");
  };

  const changeWiewChapterChild = (value) => {
    setChapterChild(value);
  };

  const changeChapterPhoto = () => {
    setVievChapterPhoto((prev) => {
      if (prev === "big-grid") return "grid";
      if (prev === "grid") return "small-grid";
      if (prev === "small-grid") return "big-grid";
    });
  };
  const changeViewCard = (value) => {
    setViewCards(value);
  };

  const importPdf = () => {};
  const importExcel = () => {};

  // ----------------------------------------------------------------
  const get_employees = async (numberPage = 1) => {
    await dispatch(
      asyncGetProjectEmployees({
        page: numberPage,
      }),
    );
  };
  // ----------------------------------------------------------------
  const get_companies = async (numberPage) => {
    await dispatch(
      asyncGetProjectCompanies({
        page: numberPage,
      }),
    );
  };
  // ----------------------------------------------------------------
  const searchProtocols = async (numberPage, name = "") => {
    dispatch(asyncGetProtocols({ page: numberPage, name }));
  };
  // ----------------------------------------------------------------

  const delete_employee_from_project = async () => {
    let employees_for_delete = [];
    if (!!selectItems.length) {
      employees_for_delete = selectItems;
    } else {
      employees_for_delete.push(getInfo("deleteParticipants", "data")._id);
    }

    await dispatch(asyncDeleteEmployeesFromProject(employees_for_delete));

    changeModalVisible("deleteParticipants");
    await get_employees(numberPageParticipants);
    clearItems();
  };

  const getProject = async () => {
    if (project || !params.projectId) return;

    const res = await dispatch(asyncGetProject(params.projectId));

    if (res.error) return goToPage("projects");

    dispatch(setProject(res.payload));
  };

  useEffect(() => {
    const { chapter } = params;

    if (chapter) return setChapter(chapter);
    else setChapter("basicInformation");
  }, [params.chapter]);

  useEffect(() => {
    getProject();
  }, [project]);

  useEffect(() => {
    switch (chapter) {
      case "participants":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addParticipant", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти участника по ФИО",
          },
        });
        return setInfoTitle(`Всего участников: ${participantsLength}`);
      case "companies":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addCompany", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти компанию по названию",
          },
        });
        return setInfoTitle(`Всего компаний: ${companiesInProjectLength}`);
      case "tasks":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("addTask", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти задачу по № или названию",
          },
        });
        return setInfoTitle(`Всего задач: ${tasksLength}`);
      case "layers":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("menuLayer", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти слой по названию",
          },
        });
        return setInfoTitle(`Всего слоев: ${layersLength}`);
      case "documentation":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("menuAddDocumenattion", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти документ по названию",
          },
        });
        return setInfoTitle(`Всего документов: ${documentationLength}`);
      case "projectWorkSchedule":
        setAdded({
          visible: true,
          onClick: () =>
            changeModalVisible(
              getVisible("infoWorkSchedule") ? "chooseStageOrWork" : "addWorkScheldule",
              true,
            ),
        });

        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти график по названию",
          },
        });
        return setInfoTitle(`Всего графиков: ${projectWorkScheduleLength}`);
      case "protocols":
        setAdded({
          visible: false,
          onClick: () => {
            navigate(`/calendar`);
            changeModalVisible("addEvent", true);
          },
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти протокол по названию",
          },
        });
        return setInfoTitle(`Всего протоколов: ${meetingMinutesLength}`);
      case "photoReports":
        setAdded({
          visible: true,
          onClick: () => changeModalVisible("menuAddDocumenattion", true),
        });
        setSearch({
          inputSearch: {
            visible: true,
            placeholder: "Найти фотографию по дате",
          },
        });
        return setInfoTitle(`Всего фотографий: ${photoReportsLength}`);
      default:
        return setInfoTitle(``);
    }
  }, [
    participantsLength,
    companiesInProjectLength,
    layersLength,
    documentationLength,
    projectWorkScheduleLength,
    chapter,
    photoReportsLength,
    currentFolder,
    getVisible("infoWorkSchedule"),
  ]);

  // documents

  return (
    <>
      <LayoutMobile
        activePage={chapter}
        filterDisabled={
          chapter === "basicInformation" ||
          getVisible("infoParticipant") ||
          getVisible("infoWorkSchedule") ||
          chapter === "companies" ||
          getVisible("infoEditCompany") ||
          chapter === "documentation" ||
          chapter === "photoReports" ||
          getVisible("infoProtocol") ||
          chapter === "layers" ||
          chapter === "tasks"
        }
        titleLayout={"Проект: " + project?.name}
        footerBtnBack={{
          visible: true,
          onClick: () => {
            goToPage("projects");
            dispatch(setProject(null));
            changeModalVisible("clear");
          },
        }}
        search={!getVisible("infoParticipant") && search}
        footerBtnAddCircle={
          !(
            chapter === "basicInformation" ||
            getVisible("menu") ||
            getVisible("infoParticipant") ||
            getVisible("infoEditCompany")
          ) && added
        }
        changeChapterPhoto={changeChapterPhoto}
        viewChapterPhoto={viewChapterPhoto}
        viewCards={viewCards}
        onViewClick={changeViewCard}
        footerBtnRotate={
          (getVisible("infoWorkSchedule") || chapter === "layers") && {
            visible: true,
          }
        }
      >
        <ErrorBoundary>
          <div className="projectMobile">
            <HeaderMobilePage
              currentPage={"project"}
              changeWiewChapter={changeWiewChapter}
              title={infoTitle}
              active={chapter}
              visibleNav={true}
              visiblePemission={visible}
            />
            <div className="projectMobile-content">
              {/* Основная информация */}
              {chapter === "basicInformation" && project && <BasicInformationMobile />}
              {/* Участники */}
              {chapter === "participants" && project && (
                <ParticipantsMobile
                  get_employees={get_employees}
                  // viewChapterParticipants={viewChapterParticipants}
                  participants={participants}
                  setParticipants={setParticipants}
                  chapter={chapter}
                  setParticipantsLength={setParticipantsLength}
                  setNumberPage={setNumberPageParticipants}
                  numberPage={numberPageParticipants}
                  infoParticipant={getInfo("participant")}
                  changeWiewChapter={changeWiewChapter}
                />
              )}
              {chapter === "tasks" && project && (
                <ProjectTasksMobile setTasksLength={setTasksLength} />
              )}
              {/* Компании */}
              {chapter === "companies" && project && (
                <CompaniesMobile setCompaniesInProjectLength={setCompaniesInProjectLength} />
              )}
              {chapter === "layers" && project && (
                <LayersMobile
                  viewCards={viewCards}
                  currentLayer={currentLayer}
                  setCurrentLayer={setCurrentLayer}
                  setLayersLength={setLayersLength}
                />
              )}
              {/* Документация */}
              {chapter === "documentation" && project && (
                <DocumentationProjectMobile viewCards={viewCards} />
              )}
              {chapter === "protocols" && project && (
                <MeetingMinutesMobile setMeetingMinutesLength={setMeetingMinutesLength} />
              )}
              {/* График производства работы */}
              {chapter === "workSchedule" && project && (
                <ProjectWorkScheduleMobile
                  //getWorkSchedules={getWorkSchedules}
                  setProjectWorkScheduleLength={setProjectWorkScheduleLength}
                  viewCards={viewCards}
                />
              )}
              {/* Фотоотчеты */}
              {chapter === "photoReports" && project && (
                <PhotoReportsMobile
                  viewCards={viewCards}
                  setPhotoReportsLength={setPhotoReportsLength}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                />
              )}
            </div>
            {getVisible("menu") && (
              <ModalMobileMenu
                changeModalVisible={changeModalVisible}
                chapter={chapter}
                nameModal={"menu"}
                // setCompanyLength={setCompanyLength}
              />
            )}
            {/* Удалить участника */}
            {getVisible("deleteParticipants") && (
              <ModalDeleteItem
                headerTitle="Удалить участника"
                title="Вы уверены, что хотите удалить участника из проекта?"
                modalName="delete"
                changeModalVisible={() => changeModalVisible("deleteParticipants")}
                onDelete={delete_employee_from_project}
              />
            )}
            {/* Удалить компанию */}
            {getVisible("deleteCompany") && (
              <ModalDeleteItem
                onDelete={delete_companies_from_project}
                changeModalVisible={() => changeModalVisible("deleteCompany")}
                modalName="delete"
                haderTitle="Удалить компанию"
                title="Вы уверены, что хотите удалить эту компанию?"
              />
            )}
            {/* Добавление участника */}
            {getVisible("addParticipant") && (
              <ModalAddParticipantMobile changeModalVisible={changeModalVisible} />
            )}
            {/* Добавить компанию */}
            {getVisible("addCompany") && (
              <ModalAddCompanyMobile
                get_companies={get_companies}
                changeModalVisible={changeModalVisible}
              />
            )}

            {/* Удаление папки
          {getVisible("deleteFolder") && (
            <ModalDeleteItem
              headerTitle="Удалить папку"
              title="Вы уверены, что хотите удалить эту папку?"
              nameModal="deleteFolder"
              changeModalVisible={changeModalVisible}
              onDelete={deleteFolder}
            />
          )} */}
          </div>
        </ErrorBoundary>
      </LayoutMobile>
    </>
  );
};

export default ProjectMobile;
