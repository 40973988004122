import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CardColumnProject from "../CardColumnProject/CardColumnProject";
import CardRowProject from "./components/CardRowProject/CardRowProject";
import Pagination from "../Pagination/Pagination";
import Checkbox from "../Checkbox/Checkbox";
import { useSelects } from "../../hooks/useSelects";
import "./ProjectsList.scss";

const ProjectsList = (props) => {
  const { projects: projectsProps, count: countProps, viewCards } = props;

  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState();

  const { projects: projectsState, count: countState } = useSelector((state) => state.projects);

  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(projects);

  useEffect(() => {
    if (projectsProps) setProjects(projectsProps);
    else setProjects(projectsState);

    if (countProps !== undefined) setCount(countProps);
    else setCount(countState);
  }, [count, projects, projectsProps, countProps]);

  return (
    <div className="projects-list">
      {viewCards === "columns" ? (
        <div className="projects-content-cards projects-content-cards-column">
          {projects.map((card, i) => (
            <CardColumnProject number={i + 1} key={card._id} info={card} />
          ))}
        </div>
      ) : (
        <div className="projects-content-cards rows">
          <div className="projects-content-cards-header">
            {/* <div className="projects-content-cards-header-item number">
                <Checkbox check={selectItems.length === projects.length} onCheck={addAllItemSelect} />
                <span>№:</span>
              </div> */}
            <div className="projects-content-cards-header-item customer">
              <span title="Название проекта:">Название проекта:</span>
            </div>
            <div className="projects-content-cards-header-item generalContractor">
              <span title="Номер договора:">№ договора:</span>
            </div>
            <div className="projects-content-cards-header-item dataStart">
              <span>Заказчик:</span>
            </div>
            <div className="projects-content-cards-header-item dataEnd">
              <span>Ген.подрядчик:</span>
            </div>
            <div className="projects-content-cards-header-item city">
              <span>Город:</span>
            </div>
            <div className="projects-content-cards-header-item address">
              <span>Адрес:</span>
            </div>
            <div className="projects-content-cards-header-item taskCount">
              <span>Количество задач:</span>
            </div>
            <div className="projects-content-cards-header-item btns"></div>
          </div>
          <div className="projects-content-cards rows">
            {projects.map((card, i) => (
              <CardRowProject
                check={selectItems.includes(card._id)}
                onCheck={() => {
                  addItemSelect(card._id);
                }}
                key={card._id}
                number={i}
                info={card}
              />
            ))}
          </div>
        </div>
      )}
      <Pagination count={countState} />
    </div>
  );
};

export default ProjectsList;
