import React from "react";
import "./ProfileTabsListRow.scss";
import Icons from "../../../Icons/Icons";

const ProfileTabsListRow = ({ info, page }) => {
  const { name, actionPermission, permission } = info;
  return (
    <ul
      className={`profileTabsListRow ${page ? "profileTabsListRow-page" : ""}`}
    >
      <li
        className={`profileTabsListRow__title ${
          page ? "profileTabsListRow__title-page" : ""
        }`}
      >
        {permission === true && page && (
          <Icons type={"allowedIcon"} size={"md"} />
        )}
        {permission === false && <Icons type={"bannedIcon"} size={"md"} />}
        <span
          className={`${
            permission === false ? "profileTabsListRow__title-disabled" : ""
          }`}
        >
          {name}
        </span>
      </li>
      {actionPermission.map((elem, i) => {
        return (
          <li
            className={`profileTabsListRow__item ${
              page ? "profileTabsListRow__item-page" : ""
            }`}
            key={i}
          >
            {elem.access === "all" && (
              <Icons type={"allowedIcon"} size={"md"} />
            )}
            {/* {elem.access === "blocked" && <img src={bannedIcon} alt="" />} */}
            {(elem.access === "Свои / Своего отдела" ||
              elem.access === "Свои" ||
              elem.access === "Своего отдела") && <span>{elem.access}</span>}
          </li>
        );
      })}
    </ul>
  );
};

export default ProfileTabsListRow;
