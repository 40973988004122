import React, { useState, useEffect } from "react";
import "./ModalWrapperMobile.scss";
import Icons from "../Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setBlockScroll } from "../../store/reducers/blockScrollReducer";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import ReactDOM from "react-dom";
import { closeAllNotificationDraft } from "../../store/reducers/draftReduces";
import { useDraft } from "../../hooks/useDraft";
import Button from "../Button/Button";

const ModalWrapperMobile = ({
  onClose,
  title,
  children,
  className,
  width,
  noscroll = true,
  heightContent = null,
  modal,
  btns,
  draftModal = null,
  editBtn = {
    visible: false,
    onClick: null,
  },
}) => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const [active, setActiveMenu] = useState(false);
  const [heigthtModalContent, setHeigthtModalContent] = useState(200);

  const { getVisibleDraft, getDataDraft } = useDraft("modalWrapper", {}, {});

  useEffect(() => {
    setTimeout(() => {
      setActiveMenu(true);
      dispatch(
        setBlockScroll({
          disabled: true,
        }),
      );
    }, 100);
    return () => {
      dispatch(
        setBlockScroll({
          disabled: false,
        }),
      );
    };
  }, []);

  const handelClose = async () => {
    await dispatch(
      setBlockScroll({
        disabled: false,
      }),
    );
    onClose();
  };
  useEffect(() => {
    if (heightContent) {
      setHeigthtModalContent(heightContent);
    } else {
      setHeigthtModalContent(height - 130);
    }
  }, [heightContent, height]);

  const onPush = () => {
    const data = getDataDraft(draftModal?.name);

    draftModal.onPush && draftModal.onPush(data);

    dispatch(closeAllNotificationDraft());
  };

  const onDelete = () => {
    draftModal.onDelete && draftModal.onDelete();

    dispatch(closeAllNotificationDraft());
  };

  return ReactDOM.createPortal(
    <div className={`modalWrapperMobile ${active ? "active" : ""}`}>
      {/* <div></div> */}
      <div className={`modalWrapperMobile-modal ${btns ? "btns" : ""}`}>
        <div className="modalWrapperMobile-modal-header">
          <span className="modalWrapperMobile-modal-header-title">{title && title}</span>
          <div className="modalWrapperMobile-modal-header-btns">
            {editBtn.visible && (
              <button
                className="modalWrapperMobile-modal-header-btns-btn"
                onClick={editBtn.onClick}
              >
                <Icons
                  type={"edit"}
                  size={"xl"}
                  cursor
                  color={"black"}
                  title={{ visible: true, text: "" }}
                />
              </button>
            )}
            <button
              className="modalWrapperMobile-modal-header-btns-btn close"
              onClick={() => handelClose()}
            >
              <Icons type={"crossBlackIcon"} size={"xl"} color={"black"} />
            </button>
          </div>
        </div>
        <div
          // style={{
          //   height: heigthtModalContent,
          // }}
          className={`modalWrapperMobile-modal-content ${noscroll ? "customScroll" : ""}  ${
            className ? className : ""
          }`}
        >
          {children}
          {getVisibleDraft(draftModal?.name) && draftModal && (
            <div className="modalWrapperMobile-modal-container-draftNotification animate__animated animate__fadeIn">
              <span className="modalWrapperDesctop-container-draftNotification-title">
                У Вас есть сохраненные данные в черновике
              </span>
              <div className="modalWrapperMobile-modal-container-draftNotification-btns">
                <Button onClick={onPush} title="Вставить" color="green" />
                <Button onClick={onDelete} title="Удалить" color="red" />
              </div>
            </div>
          )}
        </div>
        {btns && <div className="modalWrapperMobile-modal-btns">{btns}</div>}
      </div>
    </div>,
    document.getElementById("portal"),
  );
};

export default ModalWrapperMobile;
