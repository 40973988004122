import axios from "axios";
import { toast } from "react-toastify";
import { getBaseUrl } from "../utils/getBaseUrl";

axios.defaults.baseURL = getBaseUrl();

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      const refresh = localStorage.getItem("refresh");

      if (refresh) {
        try {
          const res = await axios.post("/users/refresh", { refresh });

          localStorage.setItem("token", res.data.token);
          localStorage.setItem("refresh", res.data.refresh);
          // обновление токена

          error.config.headers["Authorization"] = `Bearer ${res.data.token}`;

          return axios(error.config);
        } catch (error) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");

          toast.error("Пройдите авторизацию");
          return;
        }
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");

        toast.error("Пройдите авторизацию");
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
