import React, { useState } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Select from "../Select/Select";
import InputSearch from "../InputSearch/InputSearch";
import "./ModalAddProfile.scss";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { asyncEditProfileCompany } from "../../store/reducers/companiesReducer";
import { useParams } from "react-router-dom";

const ModalAddProfile = () => {
  const { changeModalVisible, getInfo } = useModal();
  const [loading, setLoading] = useState(null);

  const dispatch = useDispatch();

  const { companyId } = useParams();

  const { options, changeOptions } = useChangeOptions({
    position: {
      options: [],
      value: null,
    },
    user: { options: [], value: null },
    position: "default",
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await dispatch(
      asyncEditProfileCompany({
        companyId: companyId,
        data: {
          event: "add",
          data: {
            user: options.user.value.value,
            position: options.position.value.value,
          },
        },
      }),
    );
    setLoading(false);
    if (res.error) return;
    changeModalVisible("addProfile", false);
  };

  return (
    <ModalWrapper
      title={"Добавить должность и наначить сотрудника"}
      onClose={() => changeModalVisible("addProfile", false)}
      noscroll={false}
      minHeight={false}
    >
      <form onSubmit={onSubmit} className="modalAddProfile">
        <div className="modalAddProfile-list">
          <Select
            options={options.position.options}
            handleChange={(value) => changeOptions("position", "value", value)}
            placeholder="Выберите должность"
            title="Должность:*"
            forInput
            menuPlacement="bottom"
            value={options.position.value}
          />
          <InputSearch
            title="Пользователь:*"
            forInput
            placeholder="Введите пользователя"
            handleChange={(value) => changeOptions("user", "value", value)}
            value={options.user.value}
          />
        </div>

        <div className="modalAddProfile-btn-wrapper">
          <Button
            disabled={!options.user.value || !options.position.value}
            type="submit"
            title={"Создать"}
            color="blue"
            loading={loading}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddProfile;
