import React from "react";
import "./ProjectWorkScheduleColListCard.scss";
import dayjs from "dayjs";
import Icons from "../../../../../../../Icons/Icons";
import { useModal } from "../../../../../../../../hooks/useModal";
import { getFormatDate } from "../../../../../../../../utils/getFormatDate";

const ProjectWorkScheduleColListCard = ({ info, edit }) => {
  const { number, name, start, end } = info;
  const { changeModalVisible } = useModal();
  return (
    <div className="projectWorkScheduleColListCard">
      <div className="projectWorkScheduleColListCard-header">
        <div className="projectWorkScheduleColListCard-header-info">
          <div className="projectWorkScheduleColListCard-header-info-text">
            <span className="projectWorkScheduleColListCard-header-info-text-title">
              {" "}
              №:{" "}
            </span>
            <span className="projectWorkScheduleColListCard-header-info-text-count">
              {" "}
              {number || 0}
            </span>
          </div>
          <Icons
            type={"menu"}
            color={"black"}
            size={"m-md"}
            onClick={() => changeModalVisible("menu", true, info)}
            title={{ visible: true, text: "" }}
          />
        </div>

        <h4 className="projectWorkScheduleColListCard-header-title">{name}</h4>
      </div>
      <ul
        className="projectWorkScheduleColListCard-content"
        onClick={() => changeModalVisible("infoWork", true, info)}
      >
        <li className="projectWorkScheduleColListCard-content-item">
          <span className="projectWorkScheduleColListCard-content-item-title">
            Дата начала:
          </span>
          <span className="projectWorkScheduleColListCard-content-item-text">
            {getFormatDate(start)}
          </span>
        </li>
        <li className="projectWorkScheduleColListCard-content-item">
          <span className="projectWorkScheduleColListCard-content-item-title">
            Дата завершения:
          </span>
          <span className="projectWorkScheduleColListCard-content-item-text">
            {getFormatDate(end)}
          </span>
        </li>
        <li className="projectWorkScheduleColListCard-content-item">
          <span className="projectWorkScheduleColListCard-content-item-title">
            Кол-во дней:
          </span>
          <span className="projectWorkScheduleColListCard-content-item-text">
            {dayjs(new Date(end)).diff(start, "day")}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ProjectWorkScheduleColListCard;
