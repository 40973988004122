import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

export const asyncGetEvents = createAsyncThunk("calendar/asyncGetEvents", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/calendar/events/`);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetEvent = createAsyncThunk("calendar/asyncGetEvent", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/calendar/get_event/${data}`);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateEvent = createAsyncThunk("calendar/asyncCreateEvent", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/calendar/create_event/`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditEvent = createAsyncThunk("calendar/asyncEditEvent", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`/calendar/update_event/${data.eventId}`, data.data);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncDeleteEvent = createAsyncThunk("calendar/asyncDeleteEvent", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.delete(`/calendar/delete_event/${data}`);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateProtocol = createAsyncThunk("calendar/asyncCreateProtocol", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/calendar/create_protocol/${data.eventId}`, data.data);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditProtocol = createAsyncThunk("calendar/asyncEditProtocol", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`/calendar/update_protocol/${data.protocolId}`, data.data);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncDeleteProtocol = createAsyncThunk("calendar/asyncDeleteProtocol", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.delete(`/calendar/delete_protocol/${data.protocolId}`);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncDeleteTaskFromProtocol = createAsyncThunk(
  "calendar/asyncDeleteTaskFromProtocol",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/calendar/delete_task_from_protocol/${data.protocolId}`, {
        data: { task_id: data.taskId },
      });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteProtocolFromEvent = createAsyncThunk(
  "calendar/asyncDeleteProtocolFromEvent",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/calendar/delete_protocol_from_event/${data.protocolId}`, {
        data: { event_id: data.eventId },
      });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const calendarReducer = createSlice({
  name: "calendar",
  initialState: {
    events: [],
    count: 0,
  },
  reducers: {
    addEvent(state, { payload }) {
      state.events = [...state.events, payload];
    },
    deleteEvent(state, { payload }) {
      state.events = state.events.filter((e) => e._id !== payload);
    },
    changeEvent(state, { payload }) {
      state.events = state.events.map((e) => {
        if (e._id === payload.eventId) {
          return {
            ...e,
            ...payload.data,
          };
        }
        return e;
      });
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetEvents.fulfilled]: (state, { payload }) => {
      state.events = payload.data.events;
      state.count = payload.data.count;
    },
    [asyncGetEvents.rejected]: "Не удалось получить мероприятия",

    [asyncGetEvent.rejected]: "Не удалось получить мероприятие",

    [asyncCreateEvent.fulfilled]: () => toast.success("Мероприятие успешно создано"),
    [asyncCreateEvent.rejected]: "Не удалось создать мероприятие",

    [asyncEditEvent.fulfilled]: () => toast.success("Мероприятие успешно изменено"),
    [asyncEditEvent.rejected]: "Не удалось изменить мероприятие",

    [asyncDeleteEvent.fulfilled]: () => toast.success("Мероприятие успешно удалено"),
    [asyncDeleteEvent.rejected]: "Не удалось удалить мероприятие",

    [asyncCreateProtocol.fulfilled]: () => toast.success("Протокол успешно создан"),
    [asyncCreateProtocol.rejected]: "Не удалось создать протокол",

    [asyncEditProtocol.fulfilled]: () => toast.success("Протокол успешно изменено"),
    [asyncEditProtocol.rejected]: "Не удалось изменить протокол",

    [asyncDeleteProtocol.fulfilled]: () => toast.success("Протокол успешно удален"),
    [asyncDeleteProtocol.rejected]: "Не удалось удалить протокол",

    [asyncDeleteProtocolFromEvent.fulfilled]: () => toast.success("Протокол успешно удален из мероприятия"),
    [asyncDeleteProtocolFromEvent.rejected]: "Не удалось удалить протокол из мероприятия",

    [asyncDeleteTaskFromProtocol.fulfilled]: () => toast.success("Задача из протокола успешно удалена"),
    [asyncDeleteTaskFromProtocol.rejected]: "Не удалось удалить задачу из протокола",
  }),
});

export const { addEvent, changeEvent, deleteEvent } = calendarReducer.actions;
export default calendarReducer.reducer;
