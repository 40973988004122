const chapterWork = [
  {
    name: "Физическая приемка",
    name_en: "physical",
    _id: "physical",
  },
  {
    name: "Документальная приемка",
    name_en: "documentary",
    _id: "documentary",
  },
];

export const transformSection = (section) => {
  const data = chapterWork.find((c) => c._id === section);

  return data || chapterWork[0];
};
