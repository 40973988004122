import React, { useEffect } from "react";
import InputSearch from "../InputSearch/InputSearch";
import Select from "../Select/Select";
import "./ProfileRowModal.scss";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Icons from "../Icons/Icons";
import { useState } from "react";
import { transformForSelect } from "../../utils/transformForSelect";
import { useModal } from "../../hooks/useModal";
import { useDispatch } from "react-redux";
import { asyncEditProfile, asyncGetUser, setUser } from "../../store/reducers/userReducer";
import { toast } from "react-toastify";
import Screen from "../Screen/Screen";
import { useHasPermissions } from "../../hooks/useHasPermissions";

// * функция, которая сравнивает данные в массиве
const matchArray = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return true;

  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) return true;
  }

  return false;
};

const ProfileRowModal = ({ info, onDelete, onChangeProfile, edit, userProfiles, add }) => {
  const dispatch = useDispatch();

  const { getInfo } = useModal();

  const { edit: hasPermissionEdit } = useHasPermissions("profileRowModal", info);

  const [permissionEdit, setEditPermission] = useState(false);

  const userId = getInfo("editEmployee", "data")?._id;

  const [originalProfile, setOriginalProfile] = useState(null);

  const [loading, setLoading] = useState(false);

  const [isEdited, setIsEdited] = useState({
    bool: false,
    prev: null,
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    position: {
      options: "default",
      value: info.position,
    },
    company: {
      options: [],
      value: info.company,
    },
    departments: {
      options: [],
      value: info.departments || [],
    },
    groups: {
      options: [],
      value: info.groups || [],
    },
  });

  const reset = () => {
    changeOptions("company", "value", transformForSelect(originalProfile.company));
    changeOptions("position", "value", transformForSelect(originalProfile.position));
    changeOptions("departments", "value", originalProfile.departments.map(transformForSelect));
    changeOptions("groups", "value", originalProfile.groups.map(transformForSelect));
  };

  const save = async () => {
    if (!options.company.value || !options.position.value) {
      return toast.error("Заполните обязательные поля");
    }
    setLoading(true);
    const res = await dispatch(
      asyncEditProfile({
        userId,
        data: {
          event: "edit",
          data: {
            old_profile_id: originalProfile._id,
            new_profile: {
              company: options.company.value.value,
              position: options.position.value.value,
              departments: options.departments.value.map((d) => d.value),
              groups: options.groups.value.map((d) => d.value),
            },
          },
        },
      }),
    );

    if (res.error) return setLoading(false);

    // * получение актуальных данных пользователя
    const user = await dispatch(asyncGetUser(userId));

    if (user.error) {
      setLoading(false);
      return reset();
    }

    // * если мы изменили компанию, значит создастся новый профайл, поэтому найти currentProfile нельзя
    const currentProfile = user.payload.profiles.find((p) => p._id === originalProfile._id);

    if (currentProfile) {
      setOriginalProfile(currentProfile);
      dispatch(setUser({ user: user.payload }));
    }
    // * значит создан новый профайт
    else if (!currentProfile && originalProfile.company._id !== options.company.value.value) {
      // * * ищем должность по соответствию company & position
      const currentProfileByCompanyAndPosition = user.payload.profiles.find((p) => {
        return (
          p.company._id === options.company.value.value &&
          p.position._id === options.position.value.value
        );
      });
      if (currentProfileByCompanyAndPosition) {
        setOriginalProfile(currentProfileByCompanyAndPosition);
        dispatch(setUser({ user: user.payload }));
      }
    }

    setLoading(false);
  };

  const create = async () => {
    if (!options.company.value || !options.position.value) {
      return toast.error("Заполните обязательные поля");
    }

    setLoading(true);
    const res = await dispatch(
      asyncEditProfile({
        userId,
        data: {
          event: "add",
          data: {
            company: options.company.value.value,
            position: options.position.value.value,
            departments: options.departments.value.map((d) => d.value),
            groups: options.groups.value.map((d) => d.value),
          },
        },
      }),
    );
    if (res.error) {
      return setLoading(false);
    }

    // * получение актуальных данных пользователя
    const user = await dispatch(asyncGetUser(userId));

    if (user.error) return setLoading(false);

    const currentProfile = user.payload.profiles.find((p) => p._id === res.payload._id);

    if (currentProfile) {
      setOriginalProfile(currentProfile);
      dispatch(setUser({ user: user.payload }));
    }

    setLoading(false);
  };

  const remove = async () => {
    if (!originalProfile) return onDelete();

    setLoading(true);

    const res = await dispatch(
      asyncEditProfile({
        userId,
        data: {
          event: "remove",
          profile_id: originalProfile._id,
        },
      }),
    );

    if (!res.error) onDelete();

    setLoading(false);
  };

  useEffect(() => {
    if (edit) return;
    onChangeProfile(
      info.index,
      options.company.value,
      options.departments.value,
      options.position.value,
      options.groups.value,
    );
  }, [options]);

  // * следим за изменениями & сравнимаем с оригинальным профайлом
  useEffect(() => {
    if (edit && originalProfile) {
      const originalCompany = originalProfile?.company?._id;
      const originalPosition = originalProfile?.position?._id;
      const originalDepartments = originalProfile?.departments?.map((d) => d._id);
      const originalGroups = originalProfile?.groups.map((d) => d._id);

      if (
        originalCompany !== options.company.value?.value ||
        originalPosition !== options.position.value?.value ||
        matchArray(
          originalDepartments,
          options.departments.value.map((d) => d.value),
        ) ||
        matchArray(
          originalGroups,
          options.groups.value.map((d) => d.value),
        )
      ) {
        setIsEdited({ bool: true });
        onChangeProfile(
          info.index,
          options.company.value,
          options.departments.value,
          options.position.value,
          options.groups.value,
          { edit: true },
        );
      } else {
        setIsEdited({ bool: false });
        onChangeProfile(
          info.index,
          options.company.value,
          options.departments.value,
          options.position.value,
          options.groups.value,
          { edit: false },
        );
      }
    }
  }, [options, originalProfile]);

  // * достаем оригинальные данные профайла
  useEffect(() => {
    const profile = userProfiles?.find((p) => p._id === info.index);
    if (profile) {
      setOriginalProfile(profile);
    }
  }, []);

  // * чистим отделы и группы при изменении компании
  useEffect(() => {
    if (!options.company.value) {
      changeOptions("departments", "value", []);
      changeOptions("groups", "value", []);
    }
  }, [options.company.value]);

  useEffect(() => {
    if (add) {
      setEditPermission(true);
    } else {
      setEditPermission(hasPermissionEdit);
    }
  }, [edit, add, hasPermissionEdit]);

  return (
    <div className="profileRowModal">
      <div className="profileRowModal-wrapper">
        <ul className="profileRowModal-list">
          <li className="modalAddEmployee-content-info__item">
            <InputSearch
              forInput
              type="company"
              placeholder="Выберите компанию"
              title="Компания:*"
              handleChange={(data) => changeOptions("company", "value", data)}
              placeholderSelect="Выберите компанию"
              value={options.company.value}
              isDisabled={!permissionEdit || loading}
            />
          </li>
          <li className="modalAddEmployee-content-info__item">
            <Select
              options={options.position.options}
              handleChange={(data) => changeOptions("position", "value", data)}
              placeholder="Выберите должность"
              title="Должность:*"
              forInput
              menuPlacement="top"
              validate={checkValidateOptions("position")}
              value={options.position.value}
              isDisabled={!permissionEdit || loading}
            />
          </li>
          <li className="modalAddEmployee-content-info__item">
            <InputSearch
              forInput
              multi
              type="department"
              placeholder="Выберите отдел"
              title="Отдел:"
              handleChange={(data) => changeOptions("departments", "value", data)}
              placeholderSelect="Выберите отдел"
              optionsForSearch={{ companies: [options.company.value?.value] }}
              value={options.departments.value}
              isDisabled={!permissionEdit || !options.company.value || loading}
              listModal={true}
            />
          </li>
          <li className="modalAddEmployee-content-info__item">
            <InputSearch
              forInput
              multi
              type="group"
              placeholder="Выберите группы"
              title="Группы:"
              handleChange={(data) => changeOptions("groups", "value", data)}
              placeholderSelect="Выберите группы"
              optionsForSearch={{ company: options.company.value?.value }}
              value={options.groups.value}
              menuPlacement="top"
              isDisabled={!permissionEdit || !options.company.value || loading}
              listModal={true}
            />
          </li>
        </ul>
        <div className="profileRowModal-btns">
          <Screen size={"xs"}>
            <span className="profileRowModal-btns-span">{"."}</span>
          </Screen>
          {permissionEdit && (
            <div className="profileRowModal-btns-list">
              <div className="profileRowModal-btn red " onClick={remove} title={"Удалить профайл"}>
                <Icons
                  type={"remove"}
                  size={"md"}
                  cursor
                  color={"white"}
                  title={{ visible: true, text: "Удалить профайл" }}
                />
              </div>
              {edit && !originalProfile && (
                <div
                  className="profileRowModal-btn blue"
                  onClick={create}
                  title={"Создать профайл"}
                >
                  {" "}
                  <Icons
                    type={"save"}
                    size={"md"}
                    cursor
                    color={"white"}
                    title={{ visible: true, text: "Создать профайл" }}
                  />
                </div>
              )}
              {edit && isEdited.bool && (
                <div
                  className="profileRowModal-btn blue"
                  onClick={save}
                  title={"Сохранить изменения"}
                >
                  {" "}
                  <Icons
                    type={"editSave"}
                    size={"md"}
                    cursor
                    color={"white"}
                    title={{ visible: true, text: "Сохранить изменения" }}
                  />
                </div>
              )}
              {edit && isEdited.bool && originalProfile && (
                <div
                  className="profileRowModal-btn grey"
                  onClick={reset}
                  title={"Отменить изменения"}
                >
                  <Icons
                    type={"reset"}
                    size={"md"}
                    cursor
                    color={"white"}
                    title={{ visible: true, text: "Отменить изменения" }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileRowModal;
