import React, { useEffect, useRef, useState } from "react";
import "./DonutForProjecCard.scss";
import drawChart from "./drawChart";
import { ResponsivePie } from "@nivo/pie";

const DonutForProjecCard = (props) => {
  const { tasks, onClick, fontSize } = props;

  const [colors, setColors] = useState(["#16c35b", "#04488e", "#dc9d00", "#c0c0c0", "#7f00ff"]);
  const data = [
    // { value: tasks.overdue },
    {
      id: "onVerification",
      title: "На проверке",
      value: tasks.onVerification || 0,
      color: "hsl(43, 100%, 43%)",
    },
    { id: "atWork", title: "В работе", value: tasks.atWork || 0, color: "hsl(210, 95%, 29%)" },
    {
      id: "completed",
      title: "Выполненные",
      value: tasks.completed || 0,
      color: "hsl(144, 80%, 43%)",
    },
    { id: "rejected", title: "Отмененные", value: tasks.rejected || 0, color: "hsl(0, 0%, 75%)" },
    {
      id: "assigned",
      title: "Назначенные",
      value: tasks.assigned || 0,
      color: "hsl(270, 100%, 50%)",
    },
  ];
  const ref = useRef(null);

  // useEffect(() => {
  //   if (ref.current) {
  //     drawChart(ref.current, data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ref]);

  return (
    <div className="donutForProjecCard">
      <div className="donutForProjecCard-graph">
        <ResponsivePie
          colors={["#dc9d00", "#04488e", "#16c35b", "#c0c0c0", "#7f00ff"]}
          data={data}
          margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
          innerRadius={0.55}
          key={["value"]}
          enableArcLabels={false}
          indexBy="title"
          padAngle={1}
          cornerRadius={3}
          isInteractive={false}
          enableArcLinkLabels={false}
          height={110}
          fit={false}
          activeOuterRadiusOffset={10}
          // arcLinkLabelsDiagonalLength={5} //полоска от кружка
          // arcLinkLabelsStraightLength={7} //полоска к tittle
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", "1.4"]],
          }}
          // arcLinkLabelsSkipAngle={10}
          // arcLinkLabelsTextColor="#333333"
          // arcLinkLabelsThickness={2}
          // arcLinkLabelsColor={{ from: "color" }}
          // arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["brighter", "10"]],
          }}
          // arcLabelsTextColor="#ffffff"
          // arcLabelsTextSize={10}
          // legends={[]}
          onClick={(e) => onClick && onClick(e)}
          theme={{ labels: { text: { fontSize: fontSize ? fontSize : 9, fontWeight: 400 } } }}
        />
      </div>
    </div>
  );
};

export default React.memo(DonutForProjecCard);
