import React, { useId, useState } from "react";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import { transformForSelect } from "../../../../utils/transformForSelect";
import { transformTypeCompany } from "../../../../utils/transformTypeCompany";
import Button from "../../../Button/Button";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import { useSelector } from "react-redux";
import Input from "../../../Input/Input";
import InputSearch from "../../../InputSearch/InputSearch";
import SelectMulti from "../../../SelectMulti/SelectMulti";
import ImageUpload from "../../../ImageUpload/ImageUpload";
import "./CompanyBaseInfoEdit.scss";
import { useDispatch } from "react-redux";
import { asyncEditCompany } from "../../../../store/reducers/companiesReducer";
import Icons from "../../../Icons/Icons";

const CompanyBaseInfoEdit = ({ setEdit }) => {
  const { company } = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  const { _id, logo, name, inn, email, phone, director, region, type, groups } = company;

  const [loading, setLoading] = useState(null);

  const formId = useId();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameCompany: name,
    emailCompany: email,
    phoneCompany: phone,
    innCompany: inn,
    groups: groups,
    logo: logo,
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    director: {
      options: [],
      value: transformForSelect(director),
    },
    region: {
      value: transformForSelect(region),
    },
    typeCompanies: {
      options: "default",
      value: Array.isArray(type)
        ? type.map((t) => transformForSelect(transformTypeCompany(t, { forSelect: true })))
        : [transformForSelect(type)],
    },
    group: {
      options: [],
      value: [],
    },
    position: "default",
  });

  const onSubmit = async (e) => {
    const checkName = checkValidateIV("nameCompany", {
      minHeight: 3,
      maxHeight: 250,
    });
    const checkInn = checkValidateIV("innCompany", { minHeight: 10, maxHeight: 12 });
    const checkEmail = checkValidateIV("emailCompany", { email: true });
    const checkPhone = checkValidateIV("phoneCompany", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    const checkType = checkValidateOptions("typeCompanies", { required: true });

    if (!checkName || !checkEmail || !checkInn || !checkPhone || !checkRegion || !checkType) return;

    const formData = new FormData();
    formData.append("name", IV.nameCompany);
    formData.append("inn", IV.innCompany);
    formData.append("phone", IV.phoneCompany);
    formData.append("email", IV.emailCompany.replaceAll(" ", ""));
    formData.append("region", options.region.value.value);
    formData.append("director", options.director.value.value);

    formData.append("type", JSON.stringify(options.typeCompanies.value.map((t) => t.value)));

    if (IV.logo) formData.append("logo", IV.logo);

    setLoading(true);
    const res = await dispatch(asyncEditCompany({ formData, company_id: _id }));
    setLoading(false);
    if (res.error) return;
  };
  return (
    <form className="companyBaseInfoEdit">
      <div className="companyBaseInfoEdit-row">
        <Icons
          type={"crossBlack"}
          size={"md"}
          cursor
          color={"white"}
          title={{ visible: true, text: "Отменить редактирование" }}
          onClick={() => setEdit(false)}
        />
      </div>

      <div className="companyBaseInfoEdit-content">
        <ul className="companyBaseInfoEdit-content-info">
          <li className="companyBaseInfoEdit-content-info-item name-company">
            <Input
              onChange={changeIV}
              value={IV.nameCompany}
              name="nameCompany"
              title="Название:"
              placeholder="Введите название компании"
              validate={checkValidateIV("nameCompany")}
            />
          </li>
          <li className="companyBaseInfoEdit-content-info-item">
            <Input
              onChange={changeIV}
              value={IV.innCompany}
              name="innCompany"
              title="ИНН:"
              placeholder="Введите ИНН"
              validate={checkValidateIV("innCompany")}
            />
          </li>
          <li className="companyBaseInfoEdit-content-info-item">
            <Input
              onChange={changeIV}
              value={IV.emailCompany}
              name="emailCompany"
              title="Email:"
              placeholder="Введите email"
              validate={checkValidateIV("emailCompany")}
            />
          </li>

          <li className="companyBaseInfoEdit-content-info-item">
            <Input
              onChange={changeIV}
              value={IV.phoneCompany}
              name="phoneCompany"
              title="Телефон:"
              type="tel"
              placeholder="+ 7 000 000 00 00"
              validate={checkValidateIV("phoneCompany")}
            />
          </li>

          <li className="companyBaseInfoEdit-content-info-item">
            <InputSearch
              forInput={true}
              type="region"
              name="region"
              value={options.region.value}
              title="Город:*"
              handleChange={(data) => {
                changeOptions("region", "value", data);
              }}
              placeholderSelect="Выберите город"
              validate={checkValidateOptions("region")}
            />
          </li>
          <li className="companyBaseInfoEdit-content-info-item">
            <SelectMulti
              options={options.typeCompanies.options}
              value={options.typeCompanies.value}
              handleChange={(data) => changeOptions("typeCompanies", "value", data)}
              placeholder="Выберите тип"
              title="Тип:*"
              forInput
              validate={checkValidateOptions("typeCompanies")}
            />
          </li>
        </ul>
        <div className="companyBaseInfoEdit-content-info-logo">
          <span className="companyBaseInfoEdit-content-info-logo-title">Логотип:</span>
          <div className="companyBaseInfoEdit-content-info-img">
            <ImageUpload
              edit
              onChange={(value) => changeIV("set", { name: "logo", value })}
              logo={logo}
            />
          </div>
        </div>
      </div>
      <Button
        title="Сохранить"
        onClick={() => onSubmit()}
        color="blue"
        form={formId}
        loading={loading}
      />
    </form>
  );
};

export default CompanyBaseInfoEdit;
