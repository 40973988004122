import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_options, clear_options } from "../store/reducers/filterReduces";

export const useFilter = () => {
  const { options } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(options);

  const chageFitlers = (name, value) => {
    if (name === "clear") {
      dispatch(clear_options());
      const clearOptions = {};
      for (const key in filters) {
        if (Array.isArray(filters[key])) {
          if (filters[key].length) {
            clearOptions[key] = new Array(filters[key].length).fill(null);
          } else {
            clearOptions[key] = [];
          }
        } else {
          clearOptions[key] = null;
        }
      }
      setFilters(clearOptions);
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
      dispatch(set_options({ name, value }));
    }
  };

  const filtersWithoutNull = {};
  for (const key in filters) {
    if (filters[key]) filtersWithoutNull[key] = filters[key];
  }
  return {
    filters,
    filtersWithoutNull,
    chageFitlers,
  };
};
