import React, { useEffect } from "react";
import "./EmployeeTasksMobile.scss";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { useState } from "react";
import axios from "../../../../../axios/axios";
import TasksListRow from "../../../../../components/TaskListRow/TaskListRow";
import { toast } from "react-toastify";
import { useModal } from "../../../../../hooks/useModal";
import { getLimitElements } from "../../../../../utils/getLimitElements";
import { asyncDeleteTask } from "../../../../../store/reducers/tasksReducer";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalTask from "../../../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../../../components/ModalEditTask/ModalEditTask";
import ModalDelegationTask from "../../../../../components/ModalDelegationTask/ModalDelegationTask";
import ModalAddDellEmployees from "../../../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import { useDispatch } from "react-redux";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import TasksGridMobile from "../../../../../components/TasksGridMobile/TasksGridMobile";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";
import { useSelector } from "react-redux";
import { useSearch } from "../../../../../hooks/useSearch";

const EmployeeTasksMobile = ({ activeFilter, changeWiewTasks }) => {
  const dispatch = useDispatch();
  const { getInfo, getVisible, changeModalVisible } = useModal();

  // const [activeFilter, setActiveFilter] = useState("all");

  const { search, params, goToPage, setQueryParam, queries } = useSearch("userInfo:tasks");

  const { userTasks, loading } = useSelector((state) => state.tasks);

  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState(0);

  // const changeWiewTasks = (value) => setQueryParam({ tab: value });

  const deleteTask = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteTask", "data"));
    await dispatch(asyncDeleteTask({ tasks: idArray }));
    await search();

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }
    changeModalVisible("deleteTask", false);
  };

  useEffect(() => {
    if (!userTasks) return;
    setTasks(userTasks.tasks);
    setCount(userTasks.count);
  }, [userTasks]);

  // useEffect(() => {
  //   if (queries.tab) setActiveFilter(queries.tab);
  //   else setActiveFilter("all");
  // }, [queries.tab]);

  return (
    <div className="employeeTasksMobile">
      <WrapperLoader loading={loading}>
        {!!tasks.length && (
          <TasksGridMobile
            // pageNumber={pageNumber}
            // changePage={changePage}
            taskInfo={{ tasks, count }}
            // getTasks={getUserTask}
          />
        )}
      </WrapperLoader>
      {getVisible("menuTasks") && (
        <ModalMobileMenu
          changeModalVisible={changeModalVisible}
          chapter={"tasks"}
          nameModal={"menuTasks"}
          // setCompanyLength={setCompanyLength}
          onClose={() => changeModalVisible("menuTasks", false)}
        />
      )}
      {/* Информация задачи */}
      {getVisible("infoTask") && <ModalTask handleChange={() => search()} />}

      {/* Редактирование задачи */}
      {getVisible("editTask") && <ModalEditTask handleChange={() => search()} mode={"edit"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && (
        <ModalEditTask handleChange={() => search()} mode={"editSubTask"} />
      )}

      {/* Добавление задачи */}
      {getVisible("addTask") && <ModalEditTask handleChange={() => search()} mode={"add"} />}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask handleChange={() => search()} mode={"subTask"} />}

      {/* Копирование задачи */}
      {getVisible("copyTask") && <ModalEditTask handleChange={() => search()} mode={"copy"} />}

      {/* Делегирование задачи */}
      {getVisible("delegationTask") && <ModalDelegationTask handleChange={() => search()} />}

      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}

      {/* Просмотр рисунока */}
      {getVisible("showPoint") && <ModalAddPointInLayer show />}

      {/* Отложить задачу */}
      {getVisible("putAsideTask") && <ModalPutAside />}
      {/* Удалить задачу */}
      {getVisible("deleteTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту задачу?"
          headerTitle="Удалить задачу"
        />
      )}
      {/* Удалить подзадачу задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту подзадачу?"
          headerTitle="Удалить подзадачу"
        />
      )}

      {/* {getVisible("menuTasks") && (
        <ModalMobileMenu
          chapter={"tasks"}
          info={getInfo("menuTasks").data}
          onClose={changeModalVisible("menuTasks", false)}
          // setCompanyLength={setCompanyLength}
        />
      )} */}
    </div>
  );
};

export default EmployeeTasksMobile;
