import React from "react";
import PlanListCard from "./components/PlanListCard/PlanListCard";
import "./PlanList.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import Screen from "../Screen/Screen";
import Pagination from "../Pagination/Pagination";

const PlanList = (props) => {
  const { layersList, changeCurrentLayer, currentLayer, currentPage, count } = props;

  return (
    <div className="planList">
      <Screen size={"xxl"}>
        {layersList.map((layer) => (
          <PlanListCard
            info={layer}
            onclick={() => changeCurrentLayer(layer)}
            key={layer._id}
            active={currentLayer?._id === layer._id}
          />
        ))}
        <Pagination count={count} />
      </Screen>
      <Screen size={"xxl"} down>
        <Swiper
          modules={[FreeMode]}
          className={`swiper-planList`}
          slidesPerView={"auto"}
          spaceBetween={10}
          freeMode={{
            enabled: true,
            sticky: true,
          }}
          slideToClickedSlide={true}
        >
          {layersList.map((layer, i) => (
            <SwiperSlide key={i} className={`swiper-planList-item`}>
              <PlanListCard
                info={layer}
                onclick={() => changeCurrentLayer(layer)}
                key={layer._id}
                active={currentLayer?._id === layer._id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Screen>
    </div>
  );
};

export default PlanList;
