import React, { useState, useEffect } from "react";
import "./SearchPanel.scss";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";
import { useSearch } from "../../hooks/useSearch";

function SearchPanel(props) {
  const {
    navigationLeft = {
      visible: false,
      type: null,
      // list: [],
    },
    inputSearch = { visible: false, placeholder: null },
    buttons = { list: [] },
    onChange,
    onSubmit,
    clearButton = true,
    typePages: typePagesProp,
    noHandleSearch = false,
    submitButton = true,
  } = props;

  const [IV, setIV] = useState("");
  const [btnList, setBtnList] = useState([]);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");

  const [selectActive, setSelectActive] = useState({});
  const [activeBtnList, setActiveBtnList] = useState(false);

  const { setQueryParam, queries } = useSearch();

  const clearInput = () => {
    setIV("");
    onSubmit && onSubmit("");
    noHandleSearch || setQueryParam({ name: null });
    onChange && onChange("");
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) onSubmit(IV);
    else {
      if (IV.trim().length) setQueryParam({ name: IV });
    }
  };

  const onFormChange = (e) => {
    const value = e.target.value;
    setIV(value);

    if (onChange) {
      onChange(value);
    } else {
      if (value === "" && queries.name?.trim()?.length) {
        setQueryParam({ name: null });
      }
    }
  };

  useEffect(() => {
    if (queries.name && !noHandleSearch) setIV(queries.name);
    else setIV("");
  }, [queries.name]);

  const typePagesProjectTasks = [
    {
      chapter: "all",
      title: "Все",
    },
    {
      chapter: "incoming",
      title: "Входящие",
    },
    {
      chapter: "outgoing",
      title: "Исходящие",
    },
  ];

  const typePagesDefault = [
    {
      chapter: "genContractor",
      title: "Ген.подрядчика",
    },
    {
      chapter: "customer",
      title: "Заказчика",
    },
    {
      chapter: "executor",
      title: "Подрядчика",
    },
  ];

  const typePages = (() => {
    if (navigationLeft?.type === "project:tasks") {
      return typePagesProjectTasks;
    } else {
      if (typePagesProp) {
        return typePagesProp;
      } else {
        return typePagesDefault;
      }
    }
  })();

  const changeActiveNavSearch = (tab) => {
    setQueryParam({ tab });
  };

  useEffect(() => {
    setSelectActive(typePages.find((item) => item.chapter === activeSearchNav));
    setActiveBtnList(false);
  }, [activeSearchNav, typePagesProp]);

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  return (
    <div className={`searchPanel ${inputSearch.visible ? "" : "justify-end"} `}>
      {navigationLeft.visible && (
        <>
          <Screen size="xxl">
            <div className="searchPanel-navigationLeft">
              {typePages.map((page) =>
                page.title ? (
                  <div
                    key={page.title}
                    onClick={() => changeActiveNavSearch(page.chapter)}
                    className={`searchPanel-navigationLeft-btn ${
                      page.chapter === activeSearchNav
                        ? "searchPanel-navigationLeft-btn-active"
                        : ""
                    }`}
                  >
                    <span className="searchPanel-navigationLeft-btn-title">{page.title}</span>
                  </div>
                ) : (
                  ""
                ),
              )}
            </div>
          </Screen>
          <Screen size="xxl" down>
            <div className="searchPanel-navigationLeft-select">
              <div
                className="searchPanel-navigationLeft-select-btn"
                onClick={() => setActiveBtnList(!activeBtnList)}
              >
                <span className="searchPanel-navigationLeft-select-btn-title">
                  {selectActive?.title}
                </span>
                <Icons
                  type={"arrowDown"}
                  size={"md"}
                  color={"white"}
                  cursor
                  className={`searchPanel-navigationLeft-select-btn-arrow ${
                    activeBtnList ? "searchPanel-navigationLeft-select-btn-arrow-open" : ""
                  }`}
                  title={{
                    visible: true,
                    text: activeBtnList ? "Свернуть" : "Развернуть",
                  }}
                />
              </div>
              <div
                className={`searchPanel-navigationLeft-select-list ${
                  activeBtnList ? "searchPanel-navigationLeft-select-list-active" : ""
                }`}
              >
                {typePages.map((page) =>
                  page.title ? (
                    <span
                      className="searchPanel-navigationLeft-select-list-option"
                      key={page.title}
                      onClick={() => changeActiveNavSearch(page.chapter)}
                    >
                      {page.title}
                    </span>
                  ) : (
                    ""
                  ),
                )}
              </div>
            </div>
          </Screen>
        </>
      )}

      {inputSearch.visible && (
        <form onSubmit={onFormSubmit} className="searchPanel-input">
          {clearButton && (
            <div
              onClick={() => clearInput()}
              className={`searchPanel-input-icon-close ${
                IV.length > 0 ? "searchPanel-input-icon-close-active" : ""
              }`}
            >
              <Icons
                type={"crossBlackIcon"}
                size={"xs"}
                cursor
                title={{ visible: true, text: "Очистить поиск" }}
              />
            </div>
          )}

          {submitButton && (
            <div className="searchPanel-input-icon-search " onClick={(e) => onFormSubmit(e)}>
              <Icons
                type={"search"}
                size={"md"}
                cursor
                color={"black"}
                title={{ visible: true, text: "Найти" }}
              />
            </div>
          )}
          <input
            value={IV}
            onChange={onFormChange}
            type="text"
            maxLength={100}
            placeholder={inputSearch.placeholder || "Найти"}
          />
        </form>
      )}

      {!!buttons.list.length && (
        <div className="searchPanel-btns">
          {buttons.list.map((btn) => (
            <div key={btn.title} onClick={btn.onClick} className="searchPanel-btns-btn">
              {btn.icon && (
                <Icons
                  type={"plus"}
                  size={"lg"}
                  color={"white"}
                  className="searchPanel-btns-btn-icon"
                />
              )}
              <span className="searchPanel-btns-btn-title">{btn.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchPanel;
