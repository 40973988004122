import React, { useId } from "react";
import "./ModalAddEmployee.scss";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import Select from "../Select/Select";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { asyncCreateUser, asyncGetUser, asyncGetUsers } from "../../store/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import InputSearch from "../InputSearch/InputSearch";
import { useState } from "react";
import ProfileRowModal from "../ProfileRowModal/ProfileRowModal";
import InputDate from "../InputDate/InputDate";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import Screen from "../Screen/Screen";

const ModalAddEmployee = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { changeModalVisible } = useModal();

  const { userData } = useSelector((state) => state.user);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    surname: "",
    email: "",
    name: "",
    phone: "",
    patronymic: "",
    avatar: null,
    dateOfBirth: new Date(),
  });

  const [profiles, setProfiles] = useState([]);

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    region: "default",
    role: {
      options: "default",
      value: { label: "Пользователь", value: "user" },
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("name", { required: true });
    const checkSurname = checkValidateIV("surname", {
      minHeight: 3,
      maxHeight: 30,
    });
    const checkEmail = checkValidateIV("email", { email: true });
    const checkPhone = checkValidateIV("phone", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    const checkRole = checkValidateOptions("role", { required: true });

    if (!checkName || !checkEmail || !checkPhone || !checkRegion || !checkSurname || !checkRole)
      return;

    const formData = new FormData();
    formData.append("name", IV.name);
    formData.append("surname", IV.surname);
    formData.append("patronymic", IV.patronymic);
    formData.append("email", IV.email.toLowerCase().replaceAll(" ", ""));
    formData.append("phone", IV.phone);
    formData.append("region", options.region.value.value || null);
    formData.append("role", options.role.value.value || null);

    if (IV.avatar && IV.avatar !== "remove") formData.append("avatar", IV.avatar);

    // * проверка profiles
    if (userData.role !== "admin" && profiles.length === 0) {
      return toast.error("Добавьте профайл");
    }
    if (profiles.length) {
      for (let i = 0; i < profiles.length; i++) {
        const profile = profiles[i];
        if (!profile.company?.value || !profile.position?.value) {
          return toast.error("Заполните все поля в профайле");
        }
      }
      formData.append(
        "profiles",
        JSON.stringify(
          profiles.map((profile) => ({
            company: profile.company.value,
            position: profile.position.value,
            departments: profile.department.map((el) => el.value),
            groups: profile.groups.map((el) => el.value),
          })),
        ),
      );
    } else {
      formData.append("profiles", JSON.stringify([]));
    }

    const res = await dispatch(asyncCreateUser(formData));
    if (res.error) return;
    handleChange && handleChange("employees:users");
    changeModalVisible("addEmployee", false, null);
  };

  const createProfile = () => {
    const profile = new Object({
      index: Math.random(),
      company: null,
      department: [],
      position: null,
      groups: [],
    });
    setProfiles((prev) => [...prev, profile]);
  };

  const deleteProfile = (index) => {
    setProfiles((prev) => {
      return prev.filter((profile, i) => profile.index !== index);
    });
  };

  const clearProfile = () => {
    setProfiles([]);
  };

  const onChangeProfile = (index, company, department, position, groups) => {
    return setProfiles((prev) => {
      return prev.map((elem) => {
        if (elem.index === index) {
          return {
            ...elem,
            company,
            department,
            position,
            groups,
          };
        } else {
          return elem;
        }
      });
    });
  };

  return (
    <ModalWrapper
      title={"Добавить сотрудника"}
      onClose={() => changeModalVisible("addEmployee", false)}
      btns={
        <div className="modalAddEmployee-btn-wrapper">
          <Button type="submit" title="Создать" color="blue" form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddEmployee-content" id={formId}>
        <h4 className="modalAddEmployee-content-title">Основная информация</h4>
        <div className="modalAddEmployee-content-baseInfo">
          <div className="modalAddEmployee-content-baseInfo-wrapper">
            <ul className="modalAddEmployee-content-info">
              <li className="modalAddEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.surname}
                  name="surname"
                  title="Фамилия:*"
                  placeholder="Введите Фамилию"
                  validate={checkValidateIV("surname")}
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <Input
                  validate={checkValidateIV("name")}
                  onChange={changeIV}
                  value={IV.name}
                  name="name"
                  title="Имя:*"
                  placeholder="Введите Имя"
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.patronymic}
                  name="patronymic"
                  title="Отчество:"
                  placeholder="Введите Отчество"
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <InputDate
                  title="Дата рождения:"
                  value={IV.dateOfBirth}
                  onChange={(data) => {
                    changeIV("set", { name: "dateOfBirth", value: data });
                  }}
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.email}
                  name="email"
                  title="Email:*"
                  placeholder="Введите Email"
                  validate={checkValidateIV("email")}
                />
              </li>

              <li className="modalAddEmployee-content-info-item">
                <Input
                  onChange={changeIV}
                  value={IV.phone}
                  name="phone"
                  type="tel"
                  title="Телефон:*"
                  placeholder="Введите номер телефона"
                  validate={checkValidateIV("phone")}
                />
              </li>
              <li className="modalAddEmployee-content-info-item">
                <InputSearch
                  forInput={true}
                  type="region"
                  name="region"
                  placeholder="Введите город"
                  title="Город:*"
                  value={options.region.value}
                  handleChange={(data) => {
                    changeOptions("region", "value", data);
                  }}
                  placeholderSelect="Выберите город"
                  validate={checkValidateOptions("region")}
                />
              </li>

              {userData.role === "admin" && (
                <li className="modalAddEmployee-content-info-item">
                  <Select
                    options={options.role.options}
                    value={options.role.value}
                    handleChange={(data) => changeOptions("role", "value", data)}
                    placeholder="Выберите роль"
                    title="Роль в системе:*"
                    forInput
                    menuPlacement="top"
                    validate={checkValidateOptions("role")}
                  />
                </li>
              )}
            </ul>
          </div>

          <div className="modalAddEmployee-content-avatar">
            <span className="modalAddEmployee-content-info-title">Фотография:</span>
            <div className="modalAddEmployee-content-info-img">
              <ImageUpload onChange={(value) => changeIV("set", { name: "avatar", value })} />
            </div>
          </div>
        </div>
        <div className="hr"></div>
        <h4 className="modalAddEmployee-content-title">Профайлы</h4>
        {!!profiles.length &&
          profiles.map((profile) => (
            <ProfileRowModal
              key={profile.index}
              info={profile}
              onDelete={() => deleteProfile(profile.index)}
              onChangeProfile={onChangeProfile}
              setProfiles={setProfiles}
              add
            />
          ))}
        <div className="modalAddEmployee-content-baseInfo-btn-wrapper">
          <Button
            title="Профайл"
            color="dark"
            icon={{ position: "left" }}
            onClick={() => createProfile()}
          />
          {!!profiles.length > 0 && (
            <Button title="Удалить все" color="red" onClick={() => clearProfile()} />
          )}
        </div>
        <Screen size={"lg"}>
          <div className="modalAddEmployee-btn-wrapper">
            <Button type="submit" title="Создать" color="blue" form={formId} />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddEmployee;
