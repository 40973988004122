import React, { useEffect, useState, useId } from "react";
import "./ModalAddGroup.scss";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../../components/Button/Button";
import ListModal from "../../components/ListModal/ListModal";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { asyncCreateGroup, asyncGetGroups } from "../../store/reducers/groupsReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import InputSearch from "../InputSearch/InputSearch";
import Textarea from "../Textarea/Textarea";
import СheckboxSwitch from "../СheckboxSwitch/СheckboxSwitch";
import Screen from "../Screen/Screen";

const ModalAddGroup = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { changeModalVisible } = useModal();
  const [activeChat, setActiveChat] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
    description: "",
  });

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    director: {
      options: [],
      value: null,
    },
    company: {
      options: [],
      value: null,
    },
    profiles: {
      options: [],
      value: null,
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("name", { minHeight: 3, maxHeight: 100 });
    const checkСompany = checkValidateOptions("company", { required: true });
    const checkDirector = checkValidateOptions("director", { required: true });

    if (!checkName || !checkСompany || !checkDirector) return;
    const profiles = options.profiles?.value?.map((e) => e.value) || [];
    const profilesRes = [...profiles, options.director.value.value];
    const res = await dispatch(
      asyncCreateGroup({
        name: IV.name,
        description: IV.description,
        profiles: profilesRes,
        company: options.company.value.value,
        director: options.director.value.value,
        chat: activeChat,
      }),
    );

    if (res.error) return;

    handleChange && handleChange("employees:groups");
    changeModalVisible("addGroup", false, null);
  };

  return (
    <ModalWrapper
      title={"Добавить группу"}
      onClose={() => changeModalVisible("addGroup", false)}
      btns={
        <div className="modalAddGroup-btn-wrapper">
          <Button type="submit" title={"Создать"} color="blue" disabled={!IV.name} form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddGroup-form" action="#" id={formId}>
        <div className="modalAddGroup-content">
          <span className="modalAddGroup-content-title">Основная информация</span>
          <Input
            onChange={changeIV}
            value={IV.name}
            name="name"
            title="Название группы:*"
            placeholder="Введите название группы"
            validate={checkValidateIV("name")}
          />
          <Textarea
            onChange={changeIV}
            value={IV.description}
            height="170px"
            name="description"
            title="Описание:"
            placeholder="Введите описание"
          />
          <div className="modalAddGroup-chat">
            <span className="modalAddGroup-chat-text">Создать чат группы:</span>
            <СheckboxSwitch check={activeChat} onCheck={() => setActiveChat(!activeChat)} />
          </div>
        </div>
        <div className="hr"></div>
        <div className="modalAddGroup-content">
          <span className="modalAddGroup-content-title">Руководитель</span>
          <ul className="modalAddGroup-content-info">
            <li className="modalAddGroup-content-info-item">
              <InputSearch
                forInput
                type="company"
                placeholder="Выберите компанию"
                title="Компания:*"
                handleChange={(data) => changeOptions("company", "value", data)}
                placeholderSelect="Выберите компанию"
                value={options.company.value}
                validate={checkValidateOptions("company")}
              />
            </li>
            <li className="modalAddGroup-content-info-item">
              <InputSearch
                options={options.director.options}
                value={options.director.value}
                handleChange={(data) => changeOptions("director", "value", data)}
                placeholder="Выберите руководителя"
                title="ФИО:*"
                forInput
                isDisabled={!options.company.value}
                validate={checkValidateOptions("director")}
                optionsForSearch={{
                  profile: true,
                  company: options.company.value?.value,
                }}
              />
            </li>
          </ul>
        </div>
        <div className="hr"></div>
        <div className="modalAddGroup-content">
          <span className="modalAddGroup-content-title">Участники</span>
          <InputSearch
            value={options.profiles.value}
            options={options.profiles.options}
            placeholder="Выберите участников"
            // title="Участники:"
            forInput
            handleChange={(data) => changeOptions("profiles", "value", data)}
            multi={true}
            listModal={true}
            optionsForSearch={{ profile: true }}
          />
        </div>
        <div className="hr"></div>
        <Screen size={"lg"}>
          <div className="modalAddGroup-btn-wrapper">
            <Button
              type="submit"
              title={"Создать"}
              color="blue"
              disabled={!IV.name}
              form={formId}
            />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddGroup;
