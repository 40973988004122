import React, { useState, useEffect } from "react";
import "./ListModalProfile.scss";
import { profilesParsing } from "../../utils/profilesParsing";
import { clearDobleObj } from "../../utils/clearDobleObg";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useDispatch } from "react-redux";
import { asyncGetUser } from "../../store/reducers/userReducer";

const ListModalProfileItem = React.memo(function ListModalItem({
  handelClick,
  edit,
  onClickInfo,
  info,
  type,
}) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const clickInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await dispatch(asyncGetUser(info[0].user._id));
    if (res.error) return;
    onClickInfo(res.payload);
    setLoading(false);
  };

  const company = profilesParsing("company", info);
  const departments = profilesParsing("departments", info);
  const position = profilesParsing("position", info);
  const groups = profilesParsing("groups", info);

  const formatInfo = (info) => {
    if (type) {
      if (type === "employeesDepartmentPosition")
        return `${getFormatFIO(info[0].user)} (${
          departments ? `Отдел: ${departments}; ` : ""
        } Позиция: ${position} ${groups ? `Группы: ${groups}; ` : ""})`;
      if (type === "employeesCompanyPosition") {
        return `${getFormatFIO(info[0].user)} (${company ? `Компания: ${company}; ` : ""} ${
          position ? `Позиция: ${position}; ` : ""
        })`;
      }
    } else {
      return `${getFormatFIO(info[0].user)} (${company ? `Компания: ${company}; ` : ""} ${
        departments ? `Отдел: ${departments}; ` : ""
      } ${position ? `Позиция: ${position}; ` : ""} ${groups ? `Группы: ${groups}; ` : ""})`;
    }
  };
  return (
    <li className={`listModalProfileItem `} title={formatInfo(info)} onClick={clickInfo}>
      <span>{formatInfo(info)}</span>

      <div className="listModalProfileItem-btns">
        {loading ? <div className="submit-spinner-lm"></div> : ""}
      </div>
    </li>
  );
});

const ListModalProfile = ({ profiles, title, onClickInfo, type }) => {
  const profilesSort = Object?.values(
    profiles?.reduce((acc, profile) => {
      const name = profile?.user?._id;
      acc[name] ??= [];
      acc[name].push({ ...profile });
      return acc;
    }, {}),
  );

  return (
    <div className="listModalProfile">
      {!!title && <h4 className="listModalProfile-title">{title}</h4>}
      {!!profilesSort.length && (
        <ul className="listModalProfile-grid customScroll">
          {profilesSort.map((elem) => (
            <ListModalProfileItem
              onClickInfo={onClickInfo}
              key={elem[0]._id}
              info={elem}
              type={type}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListModalProfile;
