const roles = [
  {
    id: 1,
    type: {
      name: "Администратор",
      nameEn: "profileAdmin",
      icon: "admin",
    },
    pagePermission: [
      {
        name: "Аналитика;",
        nameEn: "analytics",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Проекты;",
        nameEn: "project",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
        pagePermission: [
          {
            name: "Основная информация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Участники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Слои;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Протоколы собрания;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Документация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "График производства работ;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Фотоотчёт;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Сдача работ;",
        nameEn: "deliveryWork",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Задачи;",
        nameEn: "tasks",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Календарь;",
        nameEn: "calendar",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Сотрудники;",
        nameEn: "employees",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
        pagePermission: [
          {
            name: "Наша структура;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Сотрудники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Группы;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Документы;",
        nameEn: "documents",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
        pagePermission: [
          {
            name: "Документация проекта;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Общая докментация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Справка;",
        nameEn: "faq",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Чат;",
        nameEn: "chat",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      // {
      //   name: "Профиль;",
      //   nameEn: "profile",
      //   permission: true,
      //   actionPermission: [
      //     {
      //       id: 1,
      //       type: "read",
      //       access: "all",
      //     },
      //     {
      //       id: 2,
      //       type: "create",
      //       access: "all",
      //     },
      //     {
      //       id: 3,
      //       type: "update",
      //       access: "all",
      //     },
      //     {
      //       id: 4,
      //       type: "delete",
      //       access: "all",
      //     },
      //     {
      //       id: 5,
      //       type: "approve",
      //       access: "all",
      //     },
      //     {
      //       id: 6,
      //       type: "export",
      //       access: "all",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 2,
    type: {
      name: "Директор",
      nameEn: "profileDirector",
      icon: "director",
    },
    pagePermission: [
      {
        name: "Аналитика;",
        nameEn: "analytics",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      {
        name: "Проекты;",
        nameEn: "project",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "all",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
        pagePermission: [
          {
            name: "Основная информация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Участники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Слои;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "all",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Протоколы собрания;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "Свои / Своего отдела",
              },
              {
                id: 6,
                type: "export",
                access: "Свои / Своего отдела",
              },
            ],
          },
          {
            name: "Документация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "Свои / Своего отдела",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "График производства работ;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "Свои / Своего отдела",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "Свои / Своего отдела",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Фотоотчёт;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Сдача работ;",
        nameEn: "deliveryWork",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Задачи;",
        nameEn: "tasks",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Календарь;",
        nameEn: "calendar",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "Свои / Своего отдела",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои / Своего отдела",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Сотрудники;",
        nameEn: "employees",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
        pagePermission: [
          {
            name: "Наша структура;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Сотрудники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Группы;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "Свои / Своего отдела",
              },
              {
                id: 3,
                type: "update",
                access: "Свои / Своего отдела",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "Свои / Своего отдела",
              },
              {
                id: 3,
                type: "update",
                access: "Свои / Своего отдела",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Документы;",
        nameEn: "documents",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "all",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
        pagePermission: [
          {
            name: "Документация проекта;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "all",
              },
              {
                id: 4,
                type: "delete",
                access: "all",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
          {
            name: "Общая докментация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "all",
              },
              {
                id: 3,
                type: "update",
                access: "Свои / Своего отдела",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои / Своего отдела",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Справка;",
        nameEn: "faq",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      {
        name: "Чат;",
        nameEn: "chat",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      // {
      //   name: "Профиль;",
      //   nameEn: "profile",
      //   permission: true,
      //   actionPermission: [
      //     {
      //       id: 1,
      //       type: "read",
      //       access: "all",
      //     },
      //     {
      //       id: 2,
      //       type: "create",
      //       access: "blocked",
      //     },
      //     {
      //       id: 3,
      //       type: "update",
      //       access: "Свои / Своего отдела",
      //     },
      //     {
      //       id: 4,
      //       type: "delete",
      //       access: "blocked",
      //     },
      //     {
      //       id: 5,
      //       type: "approve",
      //       access: "blocked",
      //     },
      //     {
      //       id: 6,
      //       type: "export",
      //       access: "blocked",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 3,
    type: {
      name: "Пользователь",
      nameEn: "profileUser",
      icon: "user",
    },
    pagePermission: [
      {
        name: "Аналитика;",
        nameEn: "analytics",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Проекты;",
        nameEn: "project",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Основная информация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Участники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Слои;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Протоколы собрания;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Документация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "График производства работ;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Фотоотчёт;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
        ],
      },
      {
        name: "Сдача работ;",
        nameEn: "deliveryWork",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Задачи;",
        nameEn: "tasks",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Календарь;",
        nameEn: "calendar",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Сотрудники;",
        nameEn: "employees",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
        pagePermission: [
          {
            name: "Наша структура;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Сотрудники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Группы;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
        ],
      },
      {
        name: "Документы;",
        nameEn: "documents",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Документация проекта;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Общая докментация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "all",
              },
            ],
          },
        ],
      },
      {
        name: "Чат;",
        nameEn: "chat",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      {
        name: "Справка;",
        nameEn: "faq",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      // {
      //   name: "Профиль;",
      //   nameEn: "profile",
      //   permission: true,
      //   actionPermission: [
      //     {
      //       id: 1,
      //       type: "read",
      //       access: "all",
      //     },
      //     {
      //       id: 2,
      //       type: "create",
      //       access: "blocked",
      //     },
      //     {
      //       id: 3,
      //       type: "update",
      //       access: "Свои / Своего отдела",
      //     },
      //     {
      //       id: 4,
      //       type: "delete",
      //       access: "blocked",
      //     },
      //     {
      //       id: 5,
      //       type: "approve",
      //       access: "blocked",
      //     },
      //     {
      //       id: 6,
      //       type: "export",
      //       access: "blocked",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 4,
    type: {
      name: "Заказчик",
      nameEn: "profileCustomer",
      icon: "customer",
    },
    pagePermission: [
      {
        name: "Аналитика;",
        nameEn: "analytics",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Проекты;",
        nameEn: "project",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Основная информация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Участники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Слои;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Протоколы собрания;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Документация;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "График производства работ;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Фотоотчёт;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Сдача работ;",
        nameEn: "deliveryWork",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Задачи;",
        nameEn: "tasks",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Календарь;",
        nameEn: "calendar",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Сотрудники;",
        nameEn: "employees",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Наша структура;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Сотрудники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Группы;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Документы;",
        nameEn: "documents",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Документация проекта;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Общая докментация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Чат;",
        nameEn: "chat",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "blocked",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      {
        name: "Справка;",
        nameEn: "faq",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "свои",
          },
        ],
      },
      // {
      //   name: "Профиль;",
      //   nameEn: "profile",
      //   permission: true,
      //   actionPermission: [
      //     {
      //       id: 1,
      //       type: "read",
      //       access: "all",
      //     },
      //     {
      //       id: 2,
      //       type: "create",
      //       access: "blocked",
      //     },
      //     {
      //       id: 3,
      //       type: "update",
      //       access: "Свои",
      //     },
      //     {
      //       id: 4,
      //       type: "delete",
      //       access: "blocked",
      //     },
      //     {
      //       id: 5,
      //       type: "approve",
      //       access: "blocked",
      //     },
      //     {
      //       id: 6,
      //       type: "export",
      //       access: "blocked",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 5,
    type: {
      name: "Исполнитель",
      nameEn: "profilePerformer",
      icon: "performer",
    },
    pagePermission: [
      {
        name: "Аналитика;",
        nameEn: "analytics",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "all",
          },
          {
            id: 3,
            type: "update",
            access: "all",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Проекты;",
        nameEn: "project",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Основная информация;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Участники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Слои;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Протоколы собрания;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Документация;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "График производства работ;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Фотоотчёт;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Сдача работ;",
        nameEn: "deliveryWork",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Задачи;",
        nameEn: "tasks",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      {
        name: "Календарь;",
        nameEn: "calendar",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "all",
          },
        ],
      },
      {
        name: "Сотрудники;",
        nameEn: "employees",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "Свои",
          },
          {
            id: 2,
            type: "create",
            access: "Свои",
          },
          {
            id: 3,
            type: "update",
            access: "Свои",
          },
          {
            id: 4,
            type: "delete",
            access: "Свои",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
        pagePermission: [
          {
            name: "Наша структура;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Сотрудники;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Группы;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "Свои",
              },
              {
                id: 2,
                type: "create",
                access: "Свои",
              },
              {
                id: 3,
                type: "update",
                access: "Свои",
              },
              {
                id: 4,
                type: "delete",
                access: "Свои",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "Свои",
              },
            ],
          },
          {
            name: "Компании;",
            nameEn: "baseInfo",
            permission: true,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "all",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Документы;",
        nameEn: "documents",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "blocked",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
        pagePermission: [
          {
            name: "Документация проекта;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
          {
            name: "Общая докментация;",
            nameEn: "baseInfo",
            permission: false,
            actionPermission: [
              {
                id: 1,
                type: "read",
                access: "blocked",
              },
              {
                id: 2,
                type: "create",
                access: "blocked",
              },
              {
                id: 3,
                type: "update",
                access: "blocked",
              },
              {
                id: 4,
                type: "delete",
                access: "blocked",
              },
              {
                id: 5,
                type: "approve",
                access: "blocked",
              },
              {
                id: 6,
                type: "export",
                access: "blocked",
              },
            ],
          },
        ],
      },
      {
        name: "Чат;",
        nameEn: "chat",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "approve",
            access: "all",
          },
          {
            id: 2,
            type: "blocked",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "blocked",
          },
        ],
      },
      {
        name: "Справка;",
        nameEn: "faq",
        permission: true,
        actionPermission: [
          {
            id: 1,
            type: "read",
            access: "all",
          },
          {
            id: 2,
            type: "create",
            access: "blocked",
          },
          {
            id: 3,
            type: "update",
            access: "blocked",
          },
          {
            id: 4,
            type: "delete",
            access: "blocked",
          },
          {
            id: 5,
            type: "approve",
            access: "blocked",
          },
          {
            id: 6,
            type: "export",
            access: "Свои",
          },
        ],
      },
      // {
      //   name: "Профиль;",
      //   nameEn: "profile",
      //   permission: true,
      //   actionPermission: [
      //     {
      //       id: 1,
      //       type: "read",
      //       access: "all",
      //     },
      //     {
      //       id: 2,
      //       type: "create",
      //       access: "blocked",
      //     },
      //     {
      //       id: 3,
      //       type: "update",
      //       access: "Свои",
      //     },
      //     {
      //       id: 4,
      //       type: "delete",
      //       access: "blocked",
      //     },
      //     {
      //       id: 5,
      //       type: "approve",
      //       access: "blocked",
      //     },
      //     {
      //       id: 6,
      //       type: "export",
      //       access: "blocked",
      //     },
      //   ],
      // },
    ],
  },
];
export default roles;
