import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "../components/Button/Button";
import { changeNotificationDraft } from "../store/reducers/draftReduces";
import { useModal } from "./useModal";

export const useDraft = (
  data,
  modalName,
  options = { onPush, conditionForCheck: true, onDelete, filterData: {}, hasOptions: false },
) => {
  if (!modalName) {
    throw new Error("modalName is required");
  }

  const dispatch = useDispatch();

  const [dataDraft, setDataDraft] = useState(data);

  const draftStore = useSelector((state) => state.draft);

  const getDataWithFilter = (data) => {
    const newData = data;

    if (options.filterData) {
      for (const key in newData) {
        if (Array.isArray(newData[key])) {
          if (!newData[key].length) delete newData[key];
        }

        if (!newData[key]) delete newData[key];

        if (options.filterData.withOutField) {
          if (options.filterData.withOutField[key]) {
            delete newData[key];
            continue;
          }
        }
      }
    }

    if (options.filterData && options.hasOptions) {
      for (const key in newData) {
        if (typeof newData[key] === "object" && newData[key].hasOwnProperty("value")) {
          const { value } = newData[key];
          if (Array.isArray(value)) {
            if (!value.length) {
              delete newData[key];

              continue;
            }
          }

          if (!value) delete newData[key];
        }
      }
    }

    if (options.filterData && options.hasOptions.interconnectedFields) {
      for (const key in options.hasOptions.interconnectedFields) {
        if (!options.hasOptions.interconnectedFields[key]) delete newData[key];
      }
    }

    if (typeof options.filterData === "object" && options.filterData.fieldNotEqual) {
      for (const key in options.filterData.fieldNotEqual) {
        if (options.filterData.fieldNotEqual) {
          const equal = String(options.filterData.fieldNotEqual[key]) === String(newData[key]);

          if (equal) delete newData[key];
        }
      }
    }

    return newData;
  };

  const changeDataDraft = (data) => {
    let newData = { ...data };

    if (options.filterData) newData = getDataWithFilter(newData);

    setDataDraft(newData);
  };

  const addDataToDraft = (data, localOptions = { setAll: false }) => {
    if (!options.conditionForCheck && options.conditionForCheck !== undefined) return;

    let newData = {};

    const draft = checkDraft(false);

    if (data) {
      if (localOptions.setAll) {
        newData = data;
      } else {
        const prevData = checkDraft(false) || {};
        newData = {
          ...prevData,
          ...data,
        };
      }
    } else {
      newData = dataDraft;
    }

    if (draft && !localOptions.setAll) {
      newData = draft;
    }

    if (options.filterData) newData = getDataWithFilter(newData);

    if (JSON.stringify(newData) === "{}" && draft) newData = draft;
    else {
      // return removeDataFromDraft();
    }

    localStorage.setItem(`draft:${modalName}`, JSON.stringify(newData));

    return newData;
  };

  const removeDataFromDraft = (data) => {
    if (data) {
      const draft = checkDraft(false);

      if (!draft) return;

      const newData = draft;

      for (const key in data) {
        delete newData[key];
      }

      localStorage.setItem(`draft:${modalName}`, JSON.stringify(newData));

      return newData;
    }

    localStorage.removeItem(`draft:${modalName}`);
  };

  const checkDraft = (showMeesage = true) => {
    if (modalName === "modalWrapper") return;

    if (!options.conditionForCheck && options.conditionForCheck !== undefined) return;

    const draft = localStorage.getItem(`draft:${modalName}`);

    if (!draft) return;

    if (draft === "{}") return removeDataFromDraft();

    const parseData = JSON.parse(draft);

    if (showMeesage) {
      // const onPush = () => {
      //   options.onPush && options.onPush(parseData);
      //   closeTost();
      //   removeDataFromDraft();
      // };

      // const onDelete = () => {
      //   options.onDelete && options.onDelete(parseData);
      //   closeTost();
      //   removeDataFromDraft();
      // };

      dispatch(
        changeNotificationDraft({
          draft: modalName,
          data: {
            visible: true,
            data: parseData,
          },
        }),
      );

      // toastId.current = toast(<Message onDelete={onDelete} onPush={onPush} />, {
      //   closeButton: false,
      //   // onClick: (e) => {},
      //   onClose: () => closeTost(),
      //   hideProgressBar: true,
      //   closeOnClick: false,
      // });
    }

    return parseData;
  };

  const getVisibleDraft = (draftName) => {
    if (!draftStore[draftName]) return false;

    return draftStore[draftName].visible;
  };

  const getDataDraft = (draftName) => {
    if (!draftStore[draftName]) return null;

    return draftStore[draftName].data;
  };

  const closeTost = () => {
    dispatch(
      changeNotificationDraft({
        draft: modalName,
        data: {
          visible: false,
          data: null,
        },
      }),
    );
  };

  useEffect(() => {
    checkDraft();
  }, []);

  return {
    changeDataDraft,
    addDataToDraft,
    removeDataFromDraft,
    checkDraft,
    closeTost,
    getVisibleDraft,
    getDataDraft,
  };
};
