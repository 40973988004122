import React, { useState, useEffect, useCallback } from "react";
import "./AccessEdit.scss";
import AccessEditRow from "./components/AccessEditRow/AccessEditRow";
import Select from "../Select/Select";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Icons from "../Icons/Icons";
import InputSearch from "../InputSearch/InputSearch";
import Screen from "../Screen/Screen";
import AccessInfoEditMobileCard from "../AccessInfoEditMobileCard/AccessInfoEditMobileCard";

const AccessEdit = ({
  typePermissions = "company",
  permissions,
  setPermissions,
  permissionsList,
  fatherFolder,
  typeFile = "folder",
  searchProfile = true,
  checkAccesses,
}) => {
  const [optionsSort, setOptionsSort] = useState([]);

  const createOptionAccess = (accessList, accessActual) => {
    let accessSort = accessList.view?.filter((elem) => {
      return !accessActual.view?.find((item) => elem._id === item._id);
    });
    setOptionsSort(
      accessSort?.map((u) => ({
        label: u.name,
        value: u._id,
      })),
    );
  };

  const { options, changeOptions } = useChangeOptions({
    permissions: {
      options: [],
      value: null,
    },
  });

  const changePermissions = useCallback(
    (type, info) => {
      if (type === "clearAll") {
        setPermissions((prev) => {
          return {
            ...prev,
            view: prev.view.filter((el) => el._id !== info._id),
            add: prev.add.filter((el) => el._id !== info._id),
            edit: prev.edit.filter((el) => el._id !== info._id),
            copy: prev.copy.filter((el) => el._id !== info._id),
            move: prev.move.filter((el) => el._id !== info._id),
            remove: prev.remove.filter((el) => el._id !== info._id),
          };
        });
        checkAccesses && checkAccesses(permissions.view.filter((el) => el._id !== info._id));
      } else {
        setPermissions((prev) => {
          return {
            ...prev,
            [type]: prev[type]?.find((el) => el._id === info._id)
              ? prev[type]?.filter((el) => el._id !== info._id)
              : [...prev[type], info],
          };
        });
      }
    },
    [permissions],
  );

  useEffect(() => {
    createOptionAccess(permissionsList, permissions);
  }, [permissionsList, permissions]);

  useEffect(() => {
    changeOptions("permissions", "options", optionsSort);
  }, [optionsSort]);

  return (
    <div className="accessEdit">
      {permissions?.view?.length > 0 && (
        <>
          <Screen size={"sm"}>
            <div className={`accessEdit-header ${typeFile === "file" ? "file" : ""}`}>
              <span className="accessEdit-header-title name">
                {typePermissions === "company" ? "Компании" : "Профили"}
              </span>
              <span className="accessEdit-header-title">Просмотр</span>
              {typeFile === "folder" && <span className="accessEdit-header-title">Добавление</span>}
              <span className="accessEdit-header-title">Редактирование</span>
              <span className="accessEdit-header-title">Копирование</span>
              <span className="accessEdit-header-title">Перемещение</span>
              <span className="accessEdit-header-title">Удаление</span>
            </div>
            <ul className="accessEdit-list customScroll">
              {permissions?.view?.map((elem) => (
                <AccessEditRow
                  info={elem}
                  permissions={permissions}
                  changePermissions={changePermissions}
                  key={elem._id}
                  typeFile={typeFile}
                />
              ))}
            </ul>
          </Screen>
          <Screen size={"sm"} down>
            <div className="accessEditMobile">
              <div className={`accessEditMobile-header ${typeFile === "file" ? "file" : ""}`}>
                <span className="accessEditMobile-header-title name">
                  {typePermissions === "company" ? "Компании" : "Профили"}
                </span>
              </div>
              <ul className="accessEditMobile-list customScroll">
                {permissions?.view?.map((elem) => (
                  <AccessInfoEditMobileCard
                    info={elem}
                    permissions={permissions}
                    key={elem._id}
                    typeFile={typeFile}
                    edit
                    changePermissions={changePermissions}
                  />
                ))}
              </ul>
            </div>
          </Screen>
        </>
      )}

      {options.permissions.options?.length > 0 && typePermissions === "company" && (
        <div className="accessEdit-addedSection">
          <Select
            options={options.permissions.options}
            value={options.permissions.value}
            title={`Добавить ${typePermissions === "company" ? "компанию" : "профиль"}`}
            placeholder={`Выберите ${typePermissions === "company" ? "компанию" : "профиль"}`}
            handleChange={(data) => {
              changeOptions("permissions", "value", data);
            }}
            menuPlacement="top"
          />

          <div
            className="accessEdit-addedSection-btn add"
            onClick={() => {
              changePermissions("view", {
                _id: options.permissions.value.value,
                name: options.permissions.value.label,
              });
              changeOptions("permissions", "value", null);
            }}
          >
            <Icons type={"plus"} size={"md"} color={"white"} />
          </div>
        </div>
      )}
      {searchProfile && typePermissions === "profile" && (
        <div className="accessEdit-addedSection">
          <InputSearch
            isClearable={true}
            value={options.permissions.value}
            // options={options.customer.options}
            handleChange={(data) => {
              changeOptions("permissions", "value", data);
            }}
            placeholder="Введите сотрудника"
            title="Добавить профиль"
            menuPlacement="top"
            optionsForSearch={
              fatherFolder?.ref === "project"
                ? {
                    project: fatherFolder?.ref_id,
                    profile: true,
                    from_gen_contractor: true,
                  }
                : {
                    company: fatherFolder?.ref_id,
                    profile: true,
                  }
            }
          />
          <div
            className="accessEdit-addedSection-btn add"
            onClick={() => {
              changePermissions("view", {
                _id: options.permissions.value.value,
                name: options.permissions.value.label,
              });
              changeOptions("permissions", "value", null);
            }}
          >
            <Icons type={"plus"} size={"md"} color={"white"} />
          </div>
        </div>
      )}
      {options.permissions.options?.length > 0 &&
        !searchProfile &&
        typePermissions === "profile" && (
          <div className="accessEdit-addedSection">
            <Select
              options={options.permissions.options}
              value={options.permissions.value}
              title={`Добавить ${typePermissions === "company" ? "компанию" : "профиль"}`}
              placeholder={`Выберите ${typePermissions === "company" ? "компанию" : "профиль"}`}
              handleChange={(data) => {
                changeOptions("permissions", "value", data);
              }}
              menuPlacement="top"
            />

            <div
              className="accessEdit-addedSection-btn add"
              onClick={() => {
                changePermissions("view", {
                  _id: options.permissions.value.value,
                  name: options.permissions.value.label,
                });
                changeOptions("permissions", "value", null);
              }}
            >
              <Icons type={"plus"} size={"md"} color={"white"} />
            </div>
          </div>
        )}
    </div>
  );
};

export default AccessEdit;
