import React, { useState, useRef } from "react";
import "./CardColumnPartic.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import { transformRole } from "../../../../../../../utils/transformRole";
import { getBaseUrl } from "../../../../../../../utils/getBaseUrl";
import noAvatar from "../../../../../../../assets/images/pages/noavatar.svg";
import { getFullPath } from "../../../../../../../utils/getFullPath";
import { useOnClickOutside } from "../../../../../../../hooks/useOnClickOutside";
import CardGridHeader from "../../../../../../../components/CardGridHeader/CardGridHeader";

function CardColumnPartic(props) {
  const { changeModalVisible, info } = props;
  const { avatar, position, department, company, role, phone, email, tasks = 0 } = info;

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const onEvent = (name) => {
    setMenuVisible(false);
    switch (name) {
      case "info":
        return changeModalVisible("infoParticipant", true, props.info);
      case "edit":
        return changeModalVisible("infoParticipant", true, props.info, true);
      case "remove":
        return changeModalVisible("deleteParticipant", true, props.info);
      default:
        return changeModalVisible("deleteParticipant", true, props.info);
    }
  };
  const baseURL = getBaseUrl();

  const handleModal = (event) => {
    event.preventDefault();
    if (menuVisible) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  return (
    <li className="cardColumnPartic">
      <div className="cardColumnPartic-header cardColumnPartic-header">
        <div className="cardColumnPartic-header-content">
          <CardGridHeader
            // title={"Задач:"}
            // text={tasks || 0}
            onClick={(event) => handleModal(event)}
          />
          <img
            className="cardColumnPartic-header-avatar"
            src={getFullPath(avatar, "avatar")}
            alt={getFormatFIO(info.user)}
            crossOrigin="anonymous"
          />
        </div>
        <span className="cardColumnPartic-header-name">{getFormatFIO(info.user)}</span>
      </div>
      <ul className="cardColumnPartic-info cardColumnPartic-info">
        <li className="cardColumnPartic-info-item">
          <span className="cardColumnPartic-info-title">Компания:</span>
          <span className="cardColumnPartic-info-text">{company.name}</span>
        </li>
        <li className="cardColumnPartic-info-item">
          <span className="cardColumnPartic-info-title">Должность:</span>
          <span className="cardColumnPartic-info-text">{position.name}</span>
        </li>
        <li className="cardColumnPartic-info-item">
          <span className="cardColumnPartic-info-title">Отдел:</span>
          <span className="cardColumnPartic-info-text">{department?.name || ""}</span>
        </li>

        {/* <li className="cardColumnPartic-info-item">
          <span className="cardColumnPartic-info-title">Роль:</span>
          <span className="cardColumnPartic-info-text">
            {" "}
            {transformRole(info.user.role)}
          </span>
        </li> */}
      </ul>
      <ul className="cardColumnPartic-footer cardColumnPartic-footer">
        <li className="cardColumnPartic-footer-item">
          <Icons
            type={"phone"}
            size={"md"}
            className="cardColumnPartic-footer-img"
            color={"white"}
          />
          <a className="cardColumnPartic-footer-text" href={`tel: ${info.user.phone}`}>
            {info.user.phone}
          </a>
        </li>
        <li className="cardColumnPartic-footer-item">
          <Icons
            type={"mail"}
            size={"md"}
            className="cardColumnPartic-footer-img"
            color={"white"}
          />
          <a className="cardColumnPartic-footer-text" href={`mailto:${info.user.email}`}>
            {info.user.email}
          </a>
        </li>
      </ul>
      {menuVisible && (
        <div ref={ref} className="cardColumnPartic-menu-window cardColumnPartic-menu-window">
          <span onClick={() => onEvent("info")}>Информация о сотруднике</span>

          <span onClick={() => onEvent("remove")}>Удалить сотрудника</span>
        </div>
      )}
    </li>
  );
}

function Item(props) {
  const { title, value } = props;
  return (
    <div className="cardColumnPartic-main-item">
      <span className="cardColumnPartic-main-item-title">{title}</span>
      <span className="cardColumnPartic-main-item-value">{value}</span>
    </div>
  );
}

export default CardColumnPartic;
