import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "../../../../../../../components/Select/Select";
import { useChangeOptions } from "../../../../../../../hooks/useChangeOptions";
import ProjectWorkScheduleInfo from "../../../../../../../components/ProjectWorkScheduleInfo/ProjectWorkScheduleInfo";
import "./ProjectWorkScheduleRows.scss";
import ProjectWorkScheduleChart from "../../../../../../../components/ProjectWorkScheduleChart/ProjectWorkScheduleChart";

function ProjectWorkScheduleRows({ edit, data, tasks }) {
  return (
    <>
      <div className="projectWorkSchedule">
        <ProjectWorkScheduleInfo data={data} edit={edit} />

        <div className="projectWorkSchedule-chart">
          <ProjectWorkScheduleChart tasks={tasks} edit={edit} />
        </div>
      </div>
    </>
  );
}

export default ProjectWorkScheduleRows;
