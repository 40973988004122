import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";

const localLimit = localStorage.getItem("limit");

const initialState = {
  pageNumber: 1,
  globalLimit: Number(localLimit) || 10,
};

const searchReducer = createSlice({
  name: "search",
  initialState: { globalLimit: Number(localLimit) || 10 },
  reducers: {
    changeLimit: (state, { payload }) => {
      console.log(payload);
      state.globalLimit = payload;
    },
  },
  extraReducers: extraReducersWrapper({}),
});

export const { changeLimit } = searchReducer.actions;

export default searchReducer.reducer;
