import React, { useState, useEffect } from "react";
import "./AnalyticsMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import AnalyticsAddChart from "../../../components/AnalyticsAddChart/AnalyticsAddChart";
import AnalyticsInfoChart from "../../../components/AnalyticsInfoChart/AnalyticsInfoChart";
import { ErrorBoundary } from "../../../ui";

const AnalyticsMobile = () => {
  const [visibleAddedChart, setVisibleAddedChart] = useState(false);
  const [heightChart, setHeightChart] = useState(400);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1700 && width >= 1300) {
      setHeightChart(300);
    } else if (width < 1300 && width >= 1000) {
      setHeightChart(200);
    } else if (width < 1000) {
      setHeightChart(200);
    }
  }, [width]);

  const chartsList = [
    { title: "Колонны", icon: "columnsChart" },
    { title: "Полосы", icon: "bandsChart" },
    { title: "Области", icon: "areasChart" },
    {
      title: "Шаговые области",
      icon: "stepAreasChart",
    },
    { title: "Линии", icon: "linesChart" },
    { title: "Пирог", icon: "pieChart" },
    { title: "Пончик", icon: "donutChart" },
    { title: "Список", icon: "listChart" },
  ];
  return (
    <LayoutMobile filterDisabled={true}>
      <ErrorBoundary>
        <div className="analyticsMobile">
          <AnalyticsInfoChart heightChart={heightChart} />
          {/* Создание графика */}
          {/* {visibleAddedChart && (
          <AnalyticsAddChart chartsList={chartsList} heightChart={300} />
        )} */}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default AnalyticsMobile;
