import React from "react";
import "./SettingsProject.scss";
import AddedCatalogWork from "./components/AddedCatalogWork/AddedCatalogWork";

const SettingsProject = () => {
  return (
    <div className="settingsProject">
      <AddedCatalogWork />
    </div>
  );
};

export default SettingsProject;
