import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import TaskListForLayer from "../TaskListForLayer/TaskListForLayer";
import "./ModalTasksLayer.scss";
import { useModal } from "../../hooks/useModal";

const ModalTasksLayer = () => {
  const { changeModalVisible } = useModal();

  return (
    <ModalWrapper onClose={() => changeModalVisible("tasksLayer", false)} title="Задачи слоя">
      <div className="modalTaskLayer">
        <TaskListForLayer />
      </div>
    </ModalWrapper>
  );
};

export default ModalTasksLayer;
