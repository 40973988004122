import React from "react";
import "./LeftInfo.scss";
import Icons from "../Icons/Icons";

function LeftInfo(props) {
  const { title, icon, onClick, color, nowrap } = props;
  const style = {
    backgroundColor: color ? color : "white",
    whiteSpace: nowrap ? "nowrap" : "wrap",
  };
  return (
    <div style={style} onClick={onClick} className="leftInfo">
      {icon && (
        <div className="leftInfo-icon">
          <Icons type={"arrowLeft"} size={"xl"} cursor />
        </div>
      )}
      <div className="leftInfo-title">
        <span>{title}</span>
      </div>
    </div>
  );
}

export default LeftInfo;
