const { createSlice } = require("@reduxjs/toolkit");

const checklocalStorageTitle = () => {
  if (!!localStorage.getItem("iconsTitle")) {
    return JSON.parse(localStorage.getItem("iconsTitle"));
  } else {
    localStorage.setItem("iconsTitle", true);
    return true;
  }
};
const checklocalStorageSizeModal = () => {
  if (!!localStorage.getItem("sizeModal")) {
    return JSON.parse(localStorage.getItem("sizeModal"));
  } else {
    localStorage.setItem("sizeModal", true);
    return true;
  }
};

const viewModal = () => {
  if (!!localStorage.getItem("viewModal")) {
    return JSON.parse(localStorage.getItem("viewModal"));
  } else {
    localStorage.setItem("viewModal", false);
    return true;
  }
};

const settingsReducer = createSlice({
  name: "settings",
  initialState: {
    iconsTitle: checklocalStorageTitle(),
    sizeModal: checklocalStorageSizeModal(),
    viewModal: viewModal(),
  },
  reducers: {
    setIconsTitle: (state, action) => {
      const { iconsTitle } = action.payload;

      localStorage.setItem("iconsTitle", iconsTitle);
      state.iconsTitle = iconsTitle;
    },
    setSizeModal: (state, action) => {
      const { sizeModal } = action.payload;
      localStorage.setItem("sizeModal", sizeModal);
      state.sizeModal = sizeModal;
    },
    setViewCardsModal: (state, action) => {
      const { viewModal } = action.payload;
      localStorage.setItem("viewModal", viewModal);
      state.viewModal = viewModal;
    },
  },
});
export const { setIconsTitle, setSizeModal, setViewCardsModal } = settingsReducer.actions;

export default settingsReducer.reducer;
