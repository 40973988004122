import React, { useEffect, useState } from "react";
import "./SettingsMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import SettingsGeneral from "../../../components/SettingsGeneral/SettingsGeneral";
import SettingsEmployees from "../../../components/SettingsEmployees/SettingsEmployees";
import SettingsProject from "../../../components/SettingsProject/SettingsProject";
import { useModal } from "../../../hooks/useModal";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const SettingsMobile = () => {
  const { changeModalVisible } = useModal();

  const { visible, edit, remove } = useHasPermissions("settings");

  const { params } = useSearch();

  const [chapter, setChapter] = useState(null);

  const [footerBtnAddCircle, setFooterBtnAddCircle] = useState({
    visible: false,
    onClick: null,
  });

  const changeWiewChapter = (value) => setChapter(value);

  useEffect(() => {
    if (!params.chapter) return setChapter("general");

    setChapter(params.chapter);
  }, [params.chapter]);

  useEffect(() => {
    if (chapter === "settingsEmployees") {
      setFooterBtnAddCircle({
        visible: true,
        onClick: () => changeModalVisible("addPosition", true),
      });
    } else if (chapter === "settingsProject") {
      setFooterBtnAddCircle({
        visible: true,
        onClick: () => changeModalVisible("addCatalogWork", true),
      });
    } else {
      setFooterBtnAddCircle({
        visible: false,
        onClick: null,
      });
    }
  }, [chapter]);

  return (
    <LayoutMobile filterDisabled={true} footerBtnAddCircle={footerBtnAddCircle}>
      <ErrorBoundary>
        <div className="settingsMobile">
          {visible && (
            <HeaderMobilePage
              changeWiewChapter={changeWiewChapter}
              active={chapter}
              visibleNav={visible}
            />
          )}
          <div className="settingsMobile-content">
            {chapter === "general" && <SettingsGeneral />}
            {chapter === "settingsEmployees" && visible && <SettingsEmployees />}
            {chapter === "settingsProject" && visible && <SettingsProject />}
          </div>
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default SettingsMobile;
