import React from "react";
import dayjs from "dayjs";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import "./EventList.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import Screen from "../../../Screen/Screen";
import { FreeMode } from "swiper";
import { useModal } from "../../../../hooks/useModal";

const EventList = ({ eventListForDay, activeDay }) => {
  const { changeModalVisible } = useModal();
  return (
    <>
      <Screen size={"lg"}>
        <div className="eventList customScroll">
          {eventListForDay(activeDay).map((event) => (
            <div
              key={event._id}
              className="eventList-item"
              onClick={() => changeModalVisible("infoEvent", true, event)}
            >
              <div className="eventList-item-title" title={event.name}>
                <span>{event.name}</span>
              </div>
              <div className="eventList-item-date">
                <span>{`${dayjs(event.start).format("DD/MM/YY-hh:mm")} - ${dayjs(event.end).format(
                  "DD/MM/YY-hh:mm",
                )}`}</span>
              </div>
              <div className="eventList-item-desc">
                <span className="eventList-item-desc-title ">Описание:</span>
                <span className="eventList-item-desc-text">{event.description}</span>
              </div>
              <div className="eventList-item-participants">
                <span className="eventList-item-participants-title">Участники:</span>
                <div className="eventList-item-participants-list customScroll">
                  {event.employees.map((partic) => (
                    <span key={partic._id}>
                      {getFormatFIO(partic.user)}( Компания: {partic.company.name}; Должность:{" "}
                      {partic.position.name}; )
                    </span>
                  ))}
                </div>
              </div>

              <div className="hr"></div>
            </div>
          ))}
        </div>
      </Screen>
      <Screen size={"lg"} down>
        <Swiper
          // modules={[FreeMode]}
          className={`swiper-eventList`}
          slidesPerView={"auto"}
          spaceBetween={10}
          freeMode={{
            enabled: true,
            sticky: true,
          }}
          slideToClickedSlide={true}
        >
          <div className="eventList">
            {eventListForDay(activeDay).map((event) => (
              <SwiperSlide className={`swiper-eventList-item`}>
                <div key={event._id} className="eventList-item ">
                  <div
                    className="eventList-item-title"
                    onClick={() => changeModalVisible("infoEvent", true, event)}
                  >
                    <span>{event.name}</span>
                  </div>
                  <div className="eventList-item-date">
                    <span>{`${dayjs(event.start).format("DD/MM/YY-hh:mm")} - ${dayjs(
                      event.end,
                    ).format("DD/MM/YY-hh:mm")}`}</span>
                  </div>
                  <div className="eventList-item-desc">
                    <span className="eventList-item-desc-title">Описание:</span>
                    <span className="word-break">{event.description}</span>
                  </div>
                  <div className="eventList-item-participants">
                    <span className="eventList-item-participants-title">Участники:</span>
                    <div className="eventList-item-participants-list customScroll">
                      {event.employees.map((partic) => (
                        <span key={partic._id}>
                          {getFormatFIO(partic.user)}
                          (Должность: {partic.position.name}; Компания: {partic.company.name})
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </Screen>
      <Screen size={"sm"} down>
        <div className="eventList customScroll">
          {eventListForDay(activeDay).map((event) => (
            <div key={event._id} className="eventList-item">
              <div className="eventList-item-title">
                <span>{event.name}</span>
              </div>
              <div className="eventList-item-date">
                <span>{`${dayjs(event.start).format("DD/MM/YY-hh:mm")} - ${dayjs(event.end).format(
                  "DD/MM/YY-hh:mm",
                )}`}</span>
              </div>
              <div className="eventList-item-desc">
                <span className="eventList-item-desc-title">Описание:</span>
                <span className="word-break">{event.description}</span>
              </div>
              <div className="eventList-item-participants">
                <span className="eventList-item-participants-title">Участники:</span>
                <div className="eventList-item-participants-list customScroll">
                  {event.employees.map((partic) => (
                    <span key={partic._id}>
                      {getFormatFIO(partic.user)}
                      (Должность: {partic.position.name}; Компания: {partic.company.name})
                    </span>
                  ))}
                </div>
              </div>

              <div className="hr"></div>
            </div>
          ))}
        </div>
      </Screen>
    </>
  );
};

export default EventList;
