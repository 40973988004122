import React, { useEffect, useState } from "react";
import "./ProjectTasksMobile.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import ModalAddPointInLayer from "../../../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalEditTask from "../../../../../components/ModalEditTask/ModalEditTask";
import ModalPutAside from "../../../../../components/ModalPutAside/ModalPutAside";
import ModalTask from "../../../../../components/ModalTask/ModalTask";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import TasksListRow from "../../../../../components/TaskListRow/TaskListRow";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import { useSelects } from "../../../../../hooks/useSelects";
import { asyncDeleteTask } from "../../../../../store/reducers/tasksReducer";
import TasksGridMobile from "../../../../../components/TasksGridMobile/TasksGridMobile";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";

const ProjectTasksMobile = ({ setTasksLength }) => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const { projectTasks } = projects;

  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState(0);
  const [activeFilter, setActiveFilter] = useState("all");

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(tasks);

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { setQueryParam, queries, search } = useSearch("project:tasks");

  const deleteTask = async () => {
    const idArray = [];
    if (Array.isArray(getInfo("deleteTask", "data"))) {
      idArray.push(...getInfo("deleteTask", "data"));
    } else {
      idArray.push(getInfo("deleteTask", "data"));
    }
    await dispatch(asyncDeleteTask({ tasks: idArray }));
    await search();

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }

    changeModalVisible("deleteTask", false);
  };

  useEffect(() => {
    setTasks(projectTasks?.tasks || []);
    setCount(projectTasks?.count || 0);
    setTasksLength(projectTasks?.count || 0);
  }, [projectTasks, activeFilter]);

  useEffect(() => {
    if (queries.tab) setActiveFilter(queries.tab);
    else setActiveFilter("all");
  }, [queries.tab]);

  return (
    <>
      {getVisible() && <div className="bgc-shadow"></div>}
      <div>
        <NavigationListMobile
          activeNav={activeFilter}
          listBtn={{
            visible: true,
            type: "project:tasks",
          }}
        />
        <WrapperLoader loading={projects.loading}>
          <div className="tasks-mobile">
            {!!count ? (
              <TasksGridMobile taskInfo={{ tasks, count }} />
            ) : (
              !projects.loading && (
                <EmptySection
                  text={"Задач не создано"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addTask", true);
                  }}
                />
              )
            )}
          </div>
        </WrapperLoader>
        {getVisible("menuTasks") && (
          <ModalMobileMenu
            changeModalVisible={changeModalVisible}
            chapter={"tasks"}
            nameModal={"menuTasks"}
            // setCompanyLength={setCompanyLength}
            onClose={() => changeModalVisible("menuTasks", false)}
          />
        )}
        {/* Информация задачи */}
        {getVisible("infoTask") && <ModalTask handleChange={search} />}
        {/* Редактирование задачи */}
        {getVisible("editTask") && <ModalEditTask handleChange={search} mode={"edit"} />}
        {/* Добавление задачи */}
        {getVisible("addTask") && (
          <ModalEditTask
            hardValues={{
              project: {
                label: projects.project.name,
                value: projects.project._id,
              },
            }}
            handleChange={search}
            mode={"add"}
          />
        )}
        {/* Редактирование подзадачи при добавлении */}
        {getVisible("editSubTask") && <ModalEditTask handleChange={search} mode={"editSubTask"} />}
        {/* Добавление подзадачи */}
        {getVisible("addSubTask") && <ModalEditTask handleChange={search} mode={"subTask"} />}
        {/* Копирование задачи */}
        {getVisible("copyTask") && <ModalEditTask handleChange={search} mode={"copy"} />}
        {/* Делегирование задачи */}
        {getVisible("delegationTask") && (
          <ModalEditTask handleChange={search} mode={"delegation"} />
        )}
        {/* Добавить рисунок или поинт */}
        {getVisible("addPoint") && <ModalAddPointInLayer />}
        {/* Просмотр рисунока */}
        {getVisible("showPoint") && <ModalAddPointInLayer show />}
        {/* Отложить задачу */}
        {getVisible("putAsideTask") && <ModalPutAside />}
        {/* Удалить задачу */}
        {getVisible("deleteTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteTask", false)}
            onDelete={deleteTask}
            title="Вы уверены, что хотите удалить эту задачу?"
            headerTitle="Удалить задачу"
          />
        )}
        {/* Удалить подзадачу задачи */}
        {getVisible("deleteSubTask") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
            onDelete={deleteSubTask}
            title="Вы уверены, что хотите удалить эту подзадачу?"
            headerTitle="Удалить подзадачу"
          />
        )}
      </div>
      ;
    </>
  );
};

export default ProjectTasksMobile;
