import React, { useState, useEffect } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import CalendarTable from "../../../components/CalendarTable/CalendarTable";
import dayjs from "dayjs";
import CalendarMini from "../../../components/CalendarMini/CalendarMini";
import "./CalendarMobile.scss";
import CalendarPanel from "../../../components/CalendarPanel/CalendarPanel";
import Screen from "../../../components/Screen/Screen";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalEditEvent from "../../../components/ModalEditEvent/ModalEditEvent";
import ModalEventList from "../../../components/ModalEventList/ModalEventList";
import ModalInfoEvent from "../../../components/ModalInfoEvent/ModalInfoEvent";
import ModalTask from "../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../components/ModalEditTask/ModalEditTask";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteEvent,
  asyncDeleteTaskFromProtocol,
  asyncGetEvents,
  asyncDeleteProtocolFromEvent,
} from "../../../store/reducers/calendarReducer";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../components/ModalProject/ModalProject";
import { useModal } from "../../../hooks/useModal";
import { useSearch } from "../../../hooks/useSearch";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import { ErrorBoundary } from "../../../ui";

const CalendarMobile = () => {
  const dispatch = useDispatch();
  const { changeModalVisible, getVisible, getInfo } = useModal();

  const { search, queries, setQueryParam } = useSearch("calendar");

  const dataEvent = useSelector((state) => state.calendar);

  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0);

  const [chapter, setChapter] = useState("calendar");

  const [currentDate, setCurrentDate] = useState({
    year: dayjs(new Date()).year(),
    month: dayjs(new Date()).month(),
  });

  const getEvents = () => search();

  const [viewCards, setViewCards] = useState("columns");

  const changeDate = (event, data) => {
    let date = new Date();

    const currentMonth = currentDate.month;
    const currentYear = currentDate.year;

    if (event === "set") {
      const { field, value } = data;

      if (field === "year") {
        date = dayjs().year(value).month(currentMonth).toDate();
      } else if (field === "month") {
        date = dayjs().year(currentYear).month(value).toDate();
      }
    }

    if (event === "next") {
      if (currentMonth >= 11) {
        date = dayjs()
          .year(currentYear + 1)
          .month(0)
          .toDate();
      } else {
        date = dayjs()
          .year(currentYear)
          .month(currentMonth + 1)
          .toDate();
      }
    } else if (event === "prev") {
      if (currentMonth <= 1) {
        date = dayjs()
          .year(currentYear - 1)
          .month(11)
          .toDate();
      } else {
        date = dayjs()
          .year(currentYear)
          .month(currentMonth - 1)
          .toDate();
      }
    }

    setQueryParam({ date });
  };

  const changeViewCard = (value) => setViewCards(value);

  const deleteTask = async () => {
    const { _id: taskId, mode, task } = getInfo("deleteTask", "data");

    if ((mode === "protocol.add" || mode === "protocol.edit") && task === "true") {
      const protocolId =
        getInfo("editEvent", "data")?.protocol?._id || getInfo("addEvent", "data")?.protocol?._id;

      if (protocolId) await dispatch(asyncDeleteTaskFromProtocol({ taskId, protocolId }));
    }

    changeModalVisible("deleteTask", false, "prev");
  };

  const deleteSubTask = async () => changeModalVisible("deleteSubTask", false, "prev");

  const deleteEvent = async () => {
    const { _id: eventId } = getInfo("deleteEvent", "data");

    await dispatch(asyncDeleteEvent(eventId));

    await getEvents();

    changeModalVisible("deleteEvent", false, "prev");
  };

  const deleteProtocol = async () => {
    const protocol = getInfo("deleteProtocol", "data");

    if (protocol) {
      const res = await dispatch(
        asyncDeleteProtocolFromEvent({ protocolId: protocol._id, eventId: protocol.eventId }),
      );

      if (res.error) {
        return changeModalVisible("deleteProtocol", false, null);
      }
    }

    changeModalVisible("deleteProtocol", false, "prev");
  };

  useEffect(() => {
    setEvents(dataEvent.events);
    setCount(dataEvent.count);
  }, [dataEvent.events]);

  useEffect(() => {
    if (queries.date) {
      const date = dayjs(queries.date);
      setCurrentDate({
        year: date.year(),
        month: date.month(),
      });
    } else {
      const date = dayjs().year(currentDate.year).month(currentDate.month).toDate();
      setQueryParam({ date });
    }
  }, [queries.date]);

  return (
    <LayoutMobile
      currentPage={"calendar"}
      search={{
        inputSearch: {
          visible: false,
        },
      }}
      viewCards={viewCards}
      onViewClick={changeViewCard}
      footerBtnRotate={!getVisible("eventListMobile") && { visible: true }}
      footerBtnAddCircle={
        !getVisible("eventListMobile") && {
          visible: true,
          onClick: () => changeModalVisible("addEvent", true),
        }
      }
      footerBtnBack={
        getVisible("eventListMobile") && {
          visible: true,
          onClick: () =>
            changeModalVisible(getVisible("infoEvent") ? "infoEvent" : "eventListMobile", false),
        }
      }
      filterDisabled={true}
    >
      <ErrorBoundary>
        <div className="calendarMobile">
          <div className="calendarMobile-content">
            <CalendarPanel currentDate={currentDate} changeDate={changeDate} />
            <Screen size={"sm"}>
              <>
                <CalendarMini
                  events={events}
                  changeDate={changeDate}
                  currentDate={currentDate}
                  // changeModalVisible={changeModalVisible}
                />
                <CalendarTable
                  events={events}
                  // showModalEvents={showModalEvents}
                  changeDate={changeDate}
                  currentDate={currentDate}
                  viewCards={viewCards}
                />
              </>
            </Screen>
            <Screen size={"sm"} down>
              {viewCards === "columns" ? (
                <CalendarMini
                  events={events}
                  changeDate={changeDate}
                  currentDate={currentDate}
                  // changeModalVisible={changeModalVisible}
                />
              ) : (
                <CalendarTable
                  events={events}
                  // showModalEvents={showModalEvents}
                  changeDate={changeDate}
                  currentDate={currentDate}
                  viewCards={"rows"}
                  mobile={true}
                />
              )}
            </Screen>
          </div>
          {getVisible("menu") && (
            <ModalMobileMenu
              chapter={"calendar"}
              nameModal={"menu"}
              // setCompanyLength={setCompanyLength}
              onClose={() => changeModalVisible("menu", false)}
            />
          )}
          {getVisible("menuTasks") && (
            <ModalMobileMenu
              chapter={"tasks"}
              nameModal={"menuTasks"}
              // setCompanyLength={setCompanyLength}
              onClose={() => changeModalVisible("menuTasks", false)}
            />
          )}
          {/* Добавить мероприятие */}
          {getVisible("addEvent") && <ModalEditEvent mode="add" handleChange={() => getEvents()} />}

          {/* Редактировать мероприятие */}
          {getVisible("editEvent") && (
            <ModalEditEvent mode="edit" handleChange={() => getEvents()} />
          )}

          {/* Список мероприятий */}
          {getVisible("eventList") && <ModalEventList />}

          {/* Информация/редактирование мероприятия */}
          {getVisible("infoEvent") && <ModalInfoEvent />}

          {/* Удалить мероприятие */}
          {getVisible("deleteEvent") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteEvent")}
              title="Вы уверены, что хотите удалить это мероприятие?"
              headerTitle="Удалить мероприятие"
              onDelete={deleteEvent}
            />
          )}
          {/* Удалить протокол */}
          {getVisible("deleteProtocol") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteProtocol")}
              title="Вы уверены, что хотите удалить этот протокол?"
              headerTitle="Удалить протокол"
              onDelete={deleteProtocol}
            />
          )}

          {/* информация о сотруднике */}
          {getVisible("infoParticipant") && <ModalParticipant edit={false} />}

          {/* Информация о проекте */}
          {getVisible("infoProject") && <ModalProject />}

          {/* Информация задачи */}
          {getVisible("infoTask") && <ModalTask restrictSelection modal />}

          {/* Добавление задачи */}
          {getVisible("addTask") && <ModalEditTask mode={"add"} />}

          {/* Добавить рисунок или поинт */}
          {getVisible("addPoint") && <ModalAddPointInLayer />}

          {/* Редактирование задачи */}
          {getVisible("editTask") && <ModalEditTask saveData mode={"edit"} />}

          {/* Добавление подзадачи */}
          {getVisible("addSubTask") && <ModalEditTask saveData mode={"subTask"} />}

          {/* Редактирование подзадачи при добавлении */}
          {getVisible("editSubTask") && (
            <ModalEditTask
              // getTasks={() => getTasks(pageNumber)}
              saveData
              mode={"editSubTask"}
            />
          )}

          {/* Удалить задачу */}
          {getVisible("deleteTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteTask", false)}
              onDelete={deleteTask}
              title="Вы уверены, что хотите удалить эту задачу?"
              headerTitle="Удалить задачу"
            />
          )}

          {/* Удалить подзадачу задачи */}
          {getVisible("deleteSubTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
              onDelete={deleteSubTask}
              title="Вы уверены, что хотите удалить эту подзадачу?"
              headerTitle="Удалить подзадачу"
            />
          )}
          {/* Информация о юзере */}
          {getVisible("infoUser") && <ModalInfoUser />}

          {getVisible("menu") && (
            <ModalMobileMenu
              changeModalVisible={changeModalVisible}
              chapter={"calendar"}
              nameModal={"menu"}
              getInfo={getInfo}
              // setCompanyLength={setCompanyLength}
            />
          )}

          {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default CalendarMobile;
