import React, { useState, useEffect } from "react";
import Button from "../../../../../../../components/Button/Button";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import SearchPanel from "../../../../../../../components/SearchPanel/SearchPanel";
import { useSelects } from "../../../../../../../hooks/useSelects";
import ModalWrapper from "../../../../../../../components/ModalWrapper/ModalWrapper";
import CardRowsParticMin from "../CardRowsParticMin/CardRowsParticMin";
import "./ModalAddPartic.scss";
import axios from "../../../../../../../axios/axios";
import { useSelector } from "react-redux";
import { asyncAddEmployeesToProject } from "../../../../../../../store/reducers/projectsReducer";
import { useDispatch } from "react-redux";
import { getPageCount } from "../../../../../../../utils/getPageCount";
import { useModal } from "../../../../../../../hooks/useModal";
import { useSearch } from "../../../../../../../hooks/useSearch";

function ModalAddPartic() {
  const dispatch = useDispatch();
  const { _id: project_id } = useSelector((state) => state.projects.project);
  const [participants, setParticipants] = useState([]);
  const [count, setCount] = useState(0);
  const [numberPage, setNumberPage] = useState(1);

  const { changeModalVisible } = useModal();

  const { search } = useSearch();

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(participants);

  const getParticipants = async (userFio = "") => {
    const res = await axios.post(`/projects/get_employees_for_add/${project_id}/${numberPage}`, {
      userFio,
    });
    if (res.data) {
      setParticipants(res.data.users);
      setCount(res.data.count);
    }
  };

  const addEmployeeFromProject = async () => {
    await dispatch(asyncAddEmployeesToProject(selectItems));

    changeModalVisible("addParticipant");
    await search({ nativePage: "project:participants" });
    clearItems();
  };

  const changeSearchIV = (v) => {
    if (v === "") getParticipants();
  };

  useEffect(() => {
    getParticipants();
  }, [numberPage]);

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addParticipant")}
      title="Добавить участников в проект"
      className="modalWrapperContainer"
    >
      <div className="modalAddPartic">
        <div className="modalAddPartic-searchPanel">
          <div className="modalAddPartic-searchPanel-info">
            <span>Всего сотрудников: {count}</span>
          </div>
          <SearchPanel
            onSubmit={(v) => getParticipants(v)}
            onChange={(v) => changeSearchIV(v)}
            inputSearch={{
              visible: true,
              placeholder: "Найти сотрудника по Ф.И.О",
            }}
          />
        </div>
        <div className="modalAddPartic-cards">
          <div className="modalAddPartic-cards-header">
            <div className="modalAddPartic-cards-header-item number">
              <Checkbox
                onCheck={addAllItemSelect}
                check={selectItems?.length === participants?.length}
              />
            </div>
            <div className="modalAddPartic-cards-header-item">
              <span title={"ФИО:"}>ФИО:</span>
            </div>
            <div className="modalAddPartic-cards-header-item">
              <span title="Email:">Email:</span>
            </div>

            <div className="modalAddPartic-cards-header-item">
              <span title="Компания:"> Компания:</span>
            </div>
            <div className="modalAddPartic-cards-header-item">
              <span title="Отдел:"> Отдел:</span>
            </div>
            <div className="modalAddPartic-cards-header-item">
              <span title="Должность:"> Должность:</span>
            </div>
          </div>
        </div>
        <div className="modalAddPartic-cards rows">
          {participants?.map((participant, i) => (
            <CardRowsParticMin
              key={participant._id}
              check={selectItems.includes(participant._id)}
              info={participant}
              addItemSelect={addItemSelect}
            />
          ))}
        </div>
        <div className="modalAddPartic-footer-pagination">
          <Pagination
            count={count}
            currentPage={numberPage}
            onPageChange={(page) => setNumberPage(page)}
            modal={true}
          />
        </div>
        <div className="modalAddPartic-footer-btns">
          <Button
            onClick={addEmployeeFromProject}
            title="Добавить"
            color="blue"
            icon
            disabled={!selectItems.length}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalAddPartic;
