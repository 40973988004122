export const transformFormData = (formData, customeId) => {
  const dataForSubTask = {
    _id: customeId || Math.random() * 10000,
  };
  const dataArray = [...formData];

  for (let i = 0; i < dataArray.length; i++) {
    const [key, value] = dataArray[i];

    // executors && observers
    if (key === "executors" || key === "observers") dataForSubTask[key] = JSON.parse(value);
    // photos
    else if (key === "[object File]") {
      if (dataForSubTask["photos"]) {
        dataForSubTask["photos"] = [...dataForSubTask["photos"], value];
      } else {
        dataForSubTask["photos"] = [value];
      }
    } else if (key === "photo") {
      if (dataForSubTask["photos"]) {
        dataForSubTask["photos"] = [...dataForSubTask["photos"], value];
      } else {
        dataForSubTask["photos"] = [value];
      }
    }
    // regularity
    else if (key === "regularity") {
      dataForSubTask[key] = JSON.parse(value);
    }
    // isImportant
    else if (key === "isImportant") {
      if (value === "false") {
        dataForSubTask[key] = false;
      } else {
        dataForSubTask[key] = true;
      }
    } else {
      dataForSubTask[key] = value;
    }
  }
  return dataForSubTask;
};
