const profileAdmin = [
  {
    chapter: "profileAdmin",
    text: "Администратор",
  },
  {
    chapter: "profileDirector",
    text: "Директор",
  },
  {
    chapter: "profileUser",
    text: "Пользователь",
  },
  {
    chapter: "profileCustomer",
    text: "Заказчик",
  },
  {
    chapter: "profilePerformer",
    text: "Исполнитель",
  },
];

const projects = [
  {
    chapter: "all",
    text: "Все",
  },
  {
    chapter: "creator",
    text: "Мои проекты",
  },
  {
    chapter: "member",
    text: "Участник в проектах",
  },
];

const project = [
  {
    chapter: "project:basicInformation",
    page: "basicInformation",
    text: "Основная информация",
  },
  {
    chapter: "project:participants",
    page: "participants",
    text: "Участники",
  },
  {
    chapter: "project:companies",
    page: "companies",
    text: "Компании",
  },
  {
    chapter: "project:tasks",
    page: "tasks",
    text: "Задачи",
  },
  {
    chapter: "project:layers",
    page: "layers",
    text: "Слои",
  },
  {
    chapter: "project:documentation",
    page: "documentation",
    text: "Документация",
  },
  {
    chapter: "project:protocols",
    page: "protocols",
    text: "Протокол совещания",
  },
  // {
  //   chapter: "projectWorkSchedule",
  //   text: "Графики работ",
  // },
  {
    chapter: "project:photoReports",
    page: "photoReports",
    text: "Фотоотчеты",
  },
];
const documentation = [
  {
    chapter: "project",
    text: "Документация проекта",
  },
  {
    chapter: "companies",
    text: "Общая документация",
  },
];
const employees = [
  {
    chapter: "structures",
    text: "Структура",
  },
  {
    chapter: "employees",
    text: "Сотрудники",
  },
  {
    chapter: "groups",
    text: "Группы",
  },
  {
    chapter: "companies",
    text: "Компании",
  },
];

const tasks = [
  {
    chapter: "all",
    text: "Все",
  },
  {
    chapter: "incoming",
    text: "Входящие",
  },
  {
    chapter: "outgoing",
    text: "Исходящие",
  },
];

const infoEmployee = [
  {
    chapter: "info",
    text: "Основная информация",
  },
  {
    chapter: "projects",
    text: "Проекты",
  },
  {
    chapter: "tasks",
    text: "Задачи",
  },
];

const settings = [
  {
    chapter: "general",
    text: "Общие",
  },
  {
    chapter: "settingsEmployees",
    text: "Сотрудники",
  },
  {
    chapter: "settingsProject",
    text: "Проект",
  },
];

export { profileAdmin, projects, project, documentation, employees, tasks, infoEmployee, settings };
