import React from "react";
import { ReactComponent as ClearCalendar } from "../../../../../../../../assets/icons/clearCalendar.svg";
import { ReactComponent as SucessDuoble } from "../../../../../../../../assets/icons/suucessDuoble.svg";

import "./StatusIcon.scss";
//suucessDuoble.svg
const StatusIcon = ({
  type,
  numberDay,
  error = false,
  success = false,
  warning = false,
}) => {
  return (
    <div
      className={`statusIcon ${error ? "statusIcon-error" : ""} ${
        success ? "statusIcon-success" : ""
      } ${warning ? "statusIcon-warning" : ""}`}
    >
      {success ? (
        <SucessDuoble className="statusIcon-success" />
      ) : (
        <>
          <ClearCalendar className="statusIcon-calendar" />
          <span className="statusIcon-text">{numberDay}</span>
        </>
      )}
    </div>
  );
};

export default StatusIcon;
