import React, { useState, useEffect } from "react";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import Select from "../../../../../components/Select/Select";
import Textarea from "../../../../../components/Textarea/Textarea";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import InputDate from "../../../../../components/InputDate/InputDate";
import Button from "../../../../../components/Button/Button";
import DocItem from "../../../../../components/DocItem/DocItem";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import "./ModalEditAnswerDeliveryWork.scss";
import { useDispatch } from "react-redux";
import { useModal } from "../../../../../hooks/useModal";
import InputFile from "../../../../../components/InputFile/InputFile";
import { toast } from "react-toastify";
import {
  asyncCreateAnswerDeliveryWork,
  asyncEditAnswerDeliveryWork,
} from "../../../../../store/reducers/deliveryWorkReducer";

const ModalEditAnswerDeliveryWork = (props) => {
  const { mode, handleChange } = props;

  const dispatch = useDispatch();

  const modalName = mode === "edit" ? "editAnswerDeliveryWork" : "addAnswerDeliveryWork";

  const { changeModalVisible, getInfo, intermediateSaving } = useModal();

  const infoWork = getInfo(modalName, "data");
  const infoAnswer = infoWork?.engineer_answer;

  const [file, setFile] = useState([]);

  const { IV, changeIV, checkValidateIV } = intermediateSaving(
    useChangeIV({
      description: infoAnswer?.description || "",
      date_verification: infoAnswer?.date_verification
        ? new Date(infoAnswer?.date_verification)
        : new Date(),
      documents: infoAnswer?.documents || [],
    }),
    modalName,
  );

  const removeFile = (name) => {
    setFile((prev) => {
      return prev.filter((el) => el.name !== name);
    });
  };

  const changeFiles = (event, data) => {
    if (event === "add") changeIV("set", { name: "documents", value: data.documents });
    else if (event === "delete") {
      const newArr = IV.documents.filter((_, i) => i !== data.i);
      changeIV("set", { name: "documents", value: newArr });
    }
  };

  const closeModal = () => {
    changeModalVisible(modalName, false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const arrForValidate = [
      checkValidateIV("description", { maxHeight: 5000, minHeight: 3 }),
      checkValidateIV("date_verification", {
        noMoreDate: { start: infoWork.call_date, end: IV.date_verification },
      }),
      checkValidateIV("date_verification", {
        noMoreDate: { start: new Date(), end: IV.date_verification },
      }),
    ];

    if (IV.documents.length > 5) return toast.error("Максимальное количество документов 5");

    if (arrForValidate.some((el) => !el)) return;

    const formData = new FormData();

    if (IV.documents.length) {
      IV.documents.forEach((document) => {
        if (document instanceof File) formData.append("document", document);
      });
    }

    if (mode === "edit") {
      formData.append(
        "documents",
        JSON.stringify(IV.documents.filter((d) => !(d instanceof File))),
      );
    }

    formData.append("description", IV.description);
    formData.append("date_verification", IV.date_verification);

    let res = { error: true };
    if (mode === "add") {
      res = await dispatch(asyncCreateAnswerDeliveryWork({ workId: infoWork._id, data: formData }));
    } else if (mode === "edit") {
      res = await dispatch(
        asyncEditAnswerDeliveryWork({
          answerId: infoAnswer._id,
          data: formData,
        }),
      );
    }

    if (res.error) return;

    closeModal();
    handleChange && handleChange();
  };

  return (
    <ModalWrapper
      title={mode === "edit" ? "Редактировать ответ" : "Создать ответ"}
      onClose={closeModal}
    >
      <form onSubmit={onSubmit} className="modalEditAnswerDeliveryWork">
        <Textarea
          onChange={changeIV}
          value={IV.description}
          height="170px"
          name="description"
          title="Комментарий инженера ССК:"
          placeholder="Введите описание, не более 5000 символов"
          validate={checkValidateIV("description")}
        />
        <div className="modalEditAnswerDeliveryWork-grid">
          {" "}
          <div className="modalEditAnswerDeliveryWork">
            <InputFile
              title="Файлы:"
              value={IV.documents}
              placeholder="Прикрепите файлы работы"
              onChange={(documents) => changeFiles("add", { documents })}
              deleteFile={(i) => changeFiles("delete", { i })}
            />
          </div>
          <div className="modalEditAnswerDeliveryWork-date">
            <InputDate
              title="Дата вызова:"
              value={IV.date_verification}
              validate={checkValidateIV("date_verification")}
              onChange={(data) => {
                changeIV("set", { name: "date_verification", value: data });
              }}
            />
          </div>
        </div>
        <div className="modalEditAnswerDeliveryWork-grid">
          {" "}
          {file.length > 0 && (
            <>
              {file.map((elem) => {
                return (
                  <DocItem
                    key={elem.lastModified}
                    shadow
                    event={{ remove: () => removeFile(elem.name) }}
                    btnVisible={{ remove: true }}
                    info={elem}
                  />
                );
              })}
            </>
          )}
        </div>

        <Button type="submit" color="blue" title={mode === "add" ? "Ответить" : "Сохранить"} />
      </form>
    </ModalWrapper>
  );
};

export default ModalEditAnswerDeliveryWork;
