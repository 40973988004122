import { toast } from "react-toastify";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitDocElements } from "../../utils/getLimitDocElements";

export const asyncGetFolders = createAsyncThunk(
  "documentation/asyncGetFolders",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const limit = data?.params?.limit || getLimitDocElements();
      const res = await axios.post(
        `/documents/get/${data?.page || 1}/?limit=${limit}`,
        data.params,
      );

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetFolder = createAsyncThunk(
  "documentation/asyncGetFolder",
  async (data, heplers) => {
    const folderId = data.folderId;
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/get_folder/${folderId}`, data?.params);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// * получение папок проектов/копаний
export const asyncGetFoldersEntity = createAsyncThunk(
  "documentation/asyncGetFoldersEntity",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const limit = data?.params?.limit || getLimitDocElements();
      const res = await axios.post(
        `/documents/get/${data.queries.chapter}/${data.page}/?limit=${limit}`,
        data.params,
      );

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetTree = createAsyncThunk(
  "documentation/asyncGetTree",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/get_tree`, {
        head: data.head || null,
      });

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateFolders = createAsyncThunk(
  "documentation/asyncCreateFolders",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/create`, data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateDocuments = createAsyncThunk(
  "documentation/asyncCreateDocuments",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.post(`/documents/create`, data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteFile = createAsyncThunk(
  "documentation/asyncDeleteFile",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const res = await axios.delete(`/documents/delete`, {
        data,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncMoveFiles = createAsyncThunk(
  "documentation/asyncMoveFiles",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    const fileId = data.fileId;
    try {
      const res = await axios.patch(`/documents/move/${fileId}`, {
        to: data.folderId,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCopyFiles = createAsyncThunk(
  "documentation/asyncCopyFiles",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    const fileId = data.fileId;
    try {
      const res = await axios.patch(`/documents/copy/${fileId}`, {
        to: data.folderId,
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditFile = createAsyncThunk(
  "documentation/asyncEditFile",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { fileId } = data;
      const res = await axios.patch(`/documents/edit/${fileId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetPermissions = createAsyncThunk(
  "documentation/asyncGetPermissions",
  async (data, heplers) => {
    const { rejectWithValue } = heplers;
    try {
      const { fileId } = data;
      const res = await axios.post(`/documents/permissions/${fileId}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const documentationReduser = createSlice({
  name: "documentation",
  initialState: {
    folders: [],
    folder: [],
    documents: [],
    structure: [],
    countDocuments: 0,
    edit: false,
    loading: false,
    error: null,
  },
  reducers: {
    setFolders: (state, action) => {
      const { folders } = action.payload;
      state.folders = folders;
    },
    setDocuments: (state, action) => {
      const { documents } = action.payload;
      state.documents = documents;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetFolders.fulfilled]: (state, action) => {
      state.folders = action.payload;
    },
    [asyncGetFolders.rejected]: "Не удалось получить папки.",

    // * получение папок проектов/копаний
    [asyncGetFoldersEntity.fulfilled]: (state, action) => {
      // ? Приходит тоже самое, что при action asyncGetFolders
      state.folders = action.payload;
    },
    [asyncGetFoldersEntity.rejected]: "Не удалось получить папки проектов/компаний.",

    [asyncGetFolder.fulfilled]: (state, action) => {
      state.folder = action.payload;
    },
    [asyncGetFolder.rejected]: "Не удалось получить документы.",

    [asyncGetTree.fulfilled]: (state, action) => {
      state.structure = action.payload;
    },
    [asyncGetTree.rejected]: "Не удалось получить папки.",

    [asyncCreateFolders.fulfilled]: () => toast.success("Папка создана."),
    [asyncCreateFolders.rejected]: "Не удалось создать папку.",

    [asyncEditFile.fulfilled]: () => toast.success("Файл изменен."),
    [asyncEditFile.rejected]: "Не удалось изменить файл.",

    [asyncCreateDocuments.fulfilled]: () => toast.success("Документы созданы."),
    [asyncCreateDocuments.rejected]: "Не удалось создать документ.",

    [asyncDeleteFile.fulfilled]: () => toast.success("Файл удален."),
    [asyncDeleteFile.rejected]: "Не удалось удалить файл.",

    [asyncMoveFiles.fulfilled]: () => toast.success("Файл перемещен."),
    [asyncMoveFiles.rejected]: "Не удалось переместить файл.",

    [asyncCopyFiles.fulfilled]: () => toast.success("Файл скопирован."),
    [asyncCopyFiles.rejected]: "Не удалось скопировать файл.",

    [asyncGetPermissions.rejected]: "Не удалось получить доступы.",
  }),
});

export const { setFolders, setDocuments } = documentationReduser.actions;

export default documentationReduser.reducer;
