import React from "react";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import "./InfoTask.scss";
import { useModal } from "../../../../hooks/useModal";
import dayjs from "dayjs";
import { transformNotification } from "../../../../utils/transformNotification";
import { useSelector } from "react-redux";
import ListModal from "../../../ListModal/ListModal";
import ListModalProfile from "../../../ListModalProfile/ListModalProfile";
import { getFullPath } from "../../../../utils/getFullPath";
import { toast } from "react-toastify";
import { getFormatDateTime } from "../../../../utils/getFormatDateTime";
import Textarea from "../../../Textarea/Textarea";

function InfoTask(props) {
  const { rejectedComment, IV, changeIV } = props;

  const { getInfo, changeModalVisible } = useModal();

  const { userData } = useSelector((state) => state.user);

  const infoTask = getInfo("infoTask", "data");

  const { name, description, start, end, executors, observers, notifications, status } = infoTask;

  const creator = infoTask?.author?.user;
  const creatorPosition = infoTask?.author?.position;
  const creatorCompany = infoTask?.author?.company;

  return (
    <div className="infoTask">
      <div className="infoTask-item">
        <span className="title">Название задачи: </span>
        <span className="bold">{name}</span>
      </div>
      <div className="infoTask-item">
        <span className="title">Описание задачи: </span>
        <span className="bold word-break">{description}</span>
      </div>
      {rejectedComment && (
        <div className="infoTask-item">
          <span className="title">Причина отклонения: </span>
          <Textarea
            minHeight="150px"
            placeholder="Введите причину отклонения задачи"
            name="rejectedComment"
            value={IV.rejectedComment}
            onChange={changeIV}
          />
        </div>
      )}

      <div className="infoTask-item-row">
        <div className="infoTask-item-person">
          <span className="title">Автор: </span>
          <div className="infoTask-item-person-box">
            <img
              className="infoTask-item-person-logo"
              src={getFullPath(creator?.avatar, "avatar")}
              alt={creator?.surname}
            />
            {creatorPosition && (
              <div className="infoTask-item-person-info">
                <span className="bold">{creator ? getFormatFIO(creator) : "Автор не найден"}</span>
                <span className="infoTask-item-person-info-text">
                  {creatorCompany && (
                    <span className="infoTask-item-person-info-text">
                      {" "}
                      {creatorCompany.name}
                      {", "}
                    </span>
                  )}
                  {creatorPosition.name}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="infoTask-item-date">
          <div className="infoTask-item-date-elem">
            <span className="title">Поставлена:</span>
            <span className="bold">{getFormatDateTime(start)}</span>
          </div>
          <div className="infoTask-item-date-elem">
            <span className="title">Крайний срок:</span>
            <span className="bold">{getFormatDateTime(end)}</span>
          </div>
          {status === "completed" && infoTask.completed && (
            <div className="infoTask-item-date-elem">
              <span className="title">Завершена:</span>
              <span className="bold">{getFormatDateTime(infoTask.completed)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="infoTask-item-row ">
        <div className="infoTask-item notification">
          <span className="title">Уведомление:</span>
          <span className="bold">
            {notifications ? `За ${transformNotification(notifications).name}` : "-"}
          </span>
        </div>
      </div>
      <div className="infoTask-item-row-employee">
        {!!executors?.length && (
          <div className="infoTask-item">
            <span className="title">Исполнители:</span>

            <ListModalProfile
              profiles={executors}
              type={"employeesCompanyPosition"}
              onClickInfo={(info) => {
                changeModalVisible("infoUser", true, info);
              }}
            />
          </div>
        )}

        {!!observers?.length && (
          <div className="infoTask-item">
            <span className="title">Наблюдатель:</span>

            <ListModalProfile
              profiles={observers}
              type={"employeesCompanyPosition"}
              onClickInfo={(info) => {
                changeModalVisible("infoUser", true, info);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoTask;
