import React from "react";
import "./ModalWork.scss";
import ModalWorkInfo from "./components/ModalWorkInfo/ModalWorkInfo";
import ModalWorkEdit from "./components/ModalWorkEdit/ModalWorkEdit";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

function ModalWork(props) {
  const { info, changeModalVisible, editMode, createMode, scheduleWorksId, getWorksAndSteps } =
    props;
  const title =
    createMode || editMode
      ? createMode
        ? "Добавить работу"
        : "Редактировать работу"
      : "Информация о работе";

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("infoWork", false, null)}
      title={title}
      minHeight={false}
    >
      <div className="modalWork">
        <div className="modalWork-main">
          {editMode || createMode ? (
            <ModalWorkEdit
              scheduleWorksId={scheduleWorksId}
              getWorksAndSteps={getWorksAndSteps}
              createMode={createMode}
              info={info}
              changeModalVisible={changeModalVisible}
            />
          ) : (
            <ModalWorkInfo
              info={info}
              createMode={createMode}
              changeModalVisible={changeModalVisible}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalWork;
