import React from "react";
import "./BreadcrumbsItem.scss";
import Icons from "../../../../../Icons/Icons";

const BreadcrumbsItem = ({ info, onClick, first }) => {
  const { name } = info;

  return (
    <div className="breadcrumbsItem" onClick={onClick} title={name}>
      {!first && (
        <Icons type={"arrow"} size={"xs"} className="breadcrumbsItem-arrow" />
      )}
      <Icons
        type={"folderOpen"}
        size={"xl"}
        title={{ visible: true, text: "" }}
      />{" "}
      {!first && <span className="breadcrumbsItem-name">{name}</span>}
    </div>
  );
};

export default BreadcrumbsItem;
