import React, { useState } from "react";
import "./Profile.scss";
import ProfileEdit from "./components/ProfileEdit/ProfileEdit";
import ProfileTabs from "./components/ProfileTabs/ProfileTabs";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

const Profile = () => {
  const [chapter, setChapter] = useState("profileEdit");

  const {} = useSearch("profile");

  const changeWiewChapter = (value) => {
    setChapter(value);
  };

  return (
    <ErrorBoundary>
      <div className="profile">
        <div className="profile-content">
          <div className="profile-content__wrapper customScroll">
            {chapter === "profileEdit" ? (
              <ProfileEdit changeWiewChapter={changeWiewChapter} />
            ) : (
              <ProfileTabs chapter={chapter} changeWiewChapter={changeWiewChapter} />
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Profile;
