import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import "./Layout.scss";
import { Outlet } from "react-router-dom";
const Layout = () => {
  return (
    <div className="layout">
      <Navbar />
      <div className="layout-content">
        <Header />
        <main className="layout-content-main">
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
