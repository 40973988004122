import React from "react";
import "./CardColumnTaskUser.scss";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../../../utils/getFullPath";

const CardColumnTaskUser = ({ title, info }) => {
  return (
    <div className="cardColumnTaskUser">
      <span className="cardColumnTaskUser-title">{title}:</span>
      <div className="cardColumnTaskUser-wrapper">
        <img
          className="cardColumnTaskUser-wrapper-avatar"
          src={getFullPath(info?.user?.avatar, "avatar")}
          alt={getFormatFIO(info?.user)}
        />

        <div className="cardColumnTaskUser-wrapper-info">
          <span className="cardColumnTaskUser-wrapper-name" title={getFormatFIO(info?.user)}>
            {getFormatFIO(info?.user)}
          </span>
          <span
            className="cardColumnTaskUser-wrapper-position"
            title={`${info?.company?.name || ""}, ${info?.position?.name || ""}`}
          >
            {info?.company?.name || ""}, {info?.position?.name || ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardColumnTaskUser;
