import React, { useEffect, useState, useId, useMemo } from "react";
import "./ModalInfoEditCompany.scss";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { transformTypeCompany } from "../../utils/transformTypeCompany";
import { useDispatch } from "react-redux";
import {
  asyncEditProfileCompany,
  asyncEditCompany,
  asyncGetResponsibles,
  asyncEditResponsibles,
} from "../../store/reducers/companiesReducer";
import InputSearch from "../InputSearch/InputSearch";
import { transformForSelect } from "../../utils/transformForSelect";
import { useModal } from "../../hooks/useModal";
import SelectMulti from "../SelectMulti/SelectMulti";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import { toast } from "react-toastify";
import UserRowModal from "../UserRowModal/UserRowModal";
import ResponsiblesItem from "../ResponsiblesItem/ResponsiblesItem";
import Screen from "../Screen/Screen";
import { useSearch } from "../../hooks/useSearch";
import ModalEditCompanyNavigate from "./components/ModalEditCompanyNavigate/ModalEditCompanyNavigate";
import SearchModal from "../SearchModal/SearchModal";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import ListProfiles from "../../ui/ListProfiles/ListProfiles";

const ModalInfoEditCompany = (props) => {
  const { info: infoProps, edit: editProps, handleChange } = props;

  const { changeModalVisible, getInfo } = useModal();

  const { goToPage, getCurrentPage } = useSearch();

  const info = infoProps || getInfo("infoCompany", "data");
  const edit = editProps !== undefined ? editProps : getInfo("infoCompany", "editMode");

  const { edit: editPermission } = useHasPermissions("companiesCard", info);

  const [editEvent, setEditEvent] = useState(edit);

  const {
    _id,
    logo,
    name,
    inn,
    email,
    phone,
    director,
    region,
    type,
    groups,
    profiles: companyProfiles,
  } = info;

  const formId = useId();

  const dispatch = useDispatch();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameСompany: name,
    emailСompany: email,
    phoneСompany: phone,
    innСompany: inn,
    groups: groups,
    logo: logo,
  });

  const [loading, setLoading] = useState(null);

  const [editedProfiles, setEditedProfiles] = useState({});

  const [profiles, setProfiles] = useState([]);

  const [responsibles, setResponsibles] = useState([]);
  const [responsiblesList, setResponsiblesList] = useState([]);
  const [chapter, setChapter] = useState("employees");

  const [textValue, setTextValue] = useState("");

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    director: {
      options: [],
      value: transformForSelect(director),
    },
    region: {
      value: transformForSelect(region),
    },
    typeCompanies: {
      options: "default",
      value: Array.isArray(type)
        ? type.map((t) => transformForSelect(transformTypeCompany(t, { forSelect: true })))
        : [transformForSelect(type)],
    },
    group: {
      options: [],
      value: [],
    },
    position: "default",
  });

  const changeWiewChapter = (value) => {
    setChapter(value);
    setTextValue("");
  };

  const changeValue = (value) => {
    setTextValue(value);
  };

  const filteredProfiles = useMemo(() => {
    const res = profiles.filter((elem) => {
      if (
        elem?.position?.label?.toLowerCase()?.includes(textValue?.toLowerCase()) ||
        elem?.user?.label?.toLowerCase()?.includes(textValue?.toLowerCase())
      ) {
        return elem;
      }
      if (elem.position === null) {
        return elem;
      }
    });
    return res;
  }, [textValue, profiles]);

  const filteredResponsible = useMemo(() => {
    const res = responsibles.filter((elem) => {
      if (
        elem?.project?.label?.toLowerCase()?.includes(textValue?.toLowerCase()) ||
        elem?.user?.label?.toLowerCase()?.includes(textValue?.toLowerCase())
      ) {
        return elem;
      }
      if (elem.project === null) {
        return elem;
      }
    });
    return res;
  }, [textValue, responsibles]);

  const convetToProfileList = (companyProfiles) => {
    const res = companyProfiles.map((profile) => {
      return {
        _id: profile._id,
        index: profile._id,
        user: {
          label: getFormatFIO(profile.user),
          value: profile.user._id,
        },
        position: {
          label: profile.position.name,
          value: profile.position._id,
        },
        departments: profile.departments.map((d) => d._id),
        groups: profile.groups.map((d) => d._id),
        origin: {
          _id: profile._id,
          index: profile._id,
          user: {
            label: getFormatFIO(profile.user),
            value: profile.user._id,
          },
          position: {
            label: profile.position.name,
            value: profile.position._id,
          },
        },
      };
    });
    return res;
  };

  const convertToResponsiblesList = (responsibles) => {
    const responsiblesConvert = responsibles.map((item) => {
      const responsibleObj = {
        index: item.responsible._id,
        user: {
          label: getFormatFIO(item.responsible.user),
          value: item.responsible.user._id,
        },
        project: transformForSelect(item),
        isCreator: true,
        status: "created",
      };
      return {
        ...responsibleObj,
        origin: responsibleObj,
      };
    });
    return responsiblesConvert;
  };
  // * profiles
  const addProfileToList = () => {
    setProfiles((prev) => [
      ...prev,
      {
        index: Math.random() * 1000,
        user: null,
        position: null,
      },
    ]);
  };

  const changeProfile = (index, field, data) => {
    setProfiles((prev) => {
      return prev.map((profile) => {
        if (profile.index === index) {
          return {
            ...profile,
            [field]: data,
          };
        }
        return profile;
      });
    });
  };

  const resetProfile = async (index) => {
    const originProfile = profiles.find((p) => p.index === index)?.origin;
    if (originProfile) {
      setProfiles((prev) =>
        prev.map((profile) => {
          if (profile.index === index) {
            return {
              ...profile.origin,
              origin: profile.origin,
            };
          }
          return profile;
        }),
      );
    }
  };

  const saveProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      setLoading(index);
      const res = await dispatch(
        asyncEditProfileCompany({
          companyId: _id,
          data: {
            event: "edit",
            data: {
              user: profile.user.value,
              profile_id: profile._id,
            },
          },
        }),
      );
      if (res.error) {
        setLoading(null);
        return resetProfile(index);
      }

      setLoading(null);

      setProfiles((prev) =>
        prev.map((p) => {
          if (p.index === res.payload._id) {
            return {
              ...p,
              _id: res.payload._id,
              index: res.payload.index,
              origin: {
                _id: res.payload._id,
                index: res.payload.index,
                user: profile.user,
                position: profile.position,
              },
            };
          }
          return p;
        }),
      );

      setEditedProfiles((prev) => ({
        ...prev,
        [res.payload._id]: false,
      }));

      handleChange && handleChange("employees:companies");
    }
  };

  const createProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      const res = await dispatch(
        asyncEditProfileCompany({
          companyId: _id,
          data: {
            event: "add",
            data: {
              user: profile.user.value,
              position: profile.position.value,
            },
          },
        }),
      );

      if (res.error) {
        return;
      }

      setProfiles((prev) =>
        prev.map((p) => {
          if (p.index === index) {
            return {
              ...p,
              _id: res.payload._id,
              index: res.payload.index,
              origin: {
                _id: res.payload._id,
                index: res.payload.index,
                user: profile.user,
                position: profile.position,
              },
            };
          }
          return p;
        }),
      );

      setEditedProfiles((prev) => ({
        ...prev,
        [res.payload._id]: false,
      }));

      handleChange && handleChange("employees:companies");
    }
  };

  const removeProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      if (profile._id) {
        const res = await dispatch(
          asyncEditProfileCompany({
            companyId: _id,
            data: {
              event: "remove",
              profile_id: profile._id,
            },
          }),
        );

        if (res.error) return resetProfile(index);

        setProfiles((prev) => {
          return prev.filter((p) => p.index !== index);
        });
        handleChange && handleChange("employees:companies");
      } else {
        setProfiles((prev) => {
          return prev.filter((p) => p.index !== index);
        });
      }
    }
  };

  // * responsibles
  const getResponsibles = async () => {
    const res = await dispatch(asyncGetResponsibles({ companyId: _id }));

    if (res.error) return;

    const sort = convertToResponsiblesList(res.payload);
    setResponsiblesList(res.payload);
    setResponsibles(sort);
  };

  const addResponsibles = () => {
    setResponsibles((prev) => [
      ...prev,
      {
        index: Math.random() * 1000,
        project: null,
        user: null,
        status: "new",
      },
    ]);
  };

  const changeResponsibles = (index, field, data) => {
    setResponsibles((prev) => {
      return prev.map((responsible) => {
        if (responsible.index === index) {
          return {
            ...responsible,
            [field]: data,
            status: "edited",
          };
        }
        return responsible;
      });
    });
  };

  const resetResponsibles = async (index) => {
    const originResponsible = responsibles.find((r) => r.index === index)?.origin;
    if (originResponsible) {
      setResponsibles((prev) =>
        prev.map((responsible) => {
          if (responsible.index === index) {
            return {
              ...responsible.origin,
              origin: responsible.origin,
            };
          }
          return responsible;
        }),
      );
    }
  };

  const saveResponsibles = async (index) => {
    const responsible = responsibles.find((p) => p.index === index);
    if (responsible) {
      setLoading(true);
      const res = await dispatch(
        asyncEditResponsibles({
          companyId: _id,
          data: {
            event: "edit",
            data: {
              project: responsible.project.value,
              user: responsible.user.value,
            },
          },
        }),
      );
      if (res.error) {
        setLoading(null);
        return resetResponsibles(index);
      }

      setLoading(null);

      setResponsibles((prev) => {
        return prev.map((p) => {
          if (p.index === index) {
            return {
              ...responsible,
              status: "created",
            };
          }
          return p;
        });
      });

      handleChange && handleChange("employees:companies");
    }
  };

  const createResponsibles = async (index) => {
    const responsible = responsibles.find((p) => p.index === index);

    if (responsible) {
      const res = await dispatch(
        asyncEditResponsibles({
          companyId: _id,
          data: {
            event: "add",
            data: {
              project: responsible.project.value,
              user: responsible.user.value,
            },
          },
        }),
      );

      if (res.error) {
        return;
      }

      setResponsibles((prev) => {
        return prev.map((r) => {
          if (r.index === index) {
            return {
              ...r,
              status: "created",
              isCreator: true,
              origin: {
                ...r,
                status: "created",
                isCreator: true,
              },
            };
          }
          return r;
        });
      });

      handleChange && handleChange("employees:companies");
    }
  };

  const removeResponsible = async (index) => {
    const responsible = responsibles.find((r) => r.index === index);
    if (responsible) {
      if (responsible.status === "new" || !responsible.isCreator) {
        setResponsibles((prev) => prev.filter((r) => r.index !== index));
      } else {
        const res = await dispatch(
          asyncEditResponsibles({
            companyId: _id,
            data: {
              event: "remove",
              data: {
                project: responsible.project.value,
                user: responsible.user.value,
              },
            },
          }),
        );

        if (res.error) return;

        setResponsibles((prev) => prev.filter((r) => r.index !== index));
      }
    }
  };

  const onSubmit = async (e) => {
    const checkName = checkValidateIV("nameСompany", {
      minHeight: 3,
      maxHeight: 250,
    });
    const checkInn = checkValidateIV("innСompany", { minHeight: 10, maxHeight: 12 });
    const checkEmail = checkValidateIV("emailСompany", { email: true });
    const checkPhone = checkValidateIV("phoneСompany", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    const checkType = checkValidateOptions("typeCompanies", { required: true });

    if (!checkName || !checkEmail || !checkInn || !checkPhone || !checkRegion || !checkType) return;

    const formData = new FormData();
    formData.append("name", IV.nameСompany);
    formData.append("inn", IV.innСompany);
    formData.append("phone", IV.phoneСompany);
    formData.append("email", IV.emailСompany.replaceAll(" ", ""));
    formData.append("region", options.region.value.value);
    formData.append("director", options.director.value.value);

    formData.append("type", JSON.stringify(options.typeCompanies.value.map((t) => t.value)));

    if (IV.logo) formData.append("logo", IV.logo);

    // * проверка profiles
    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];
      if (!profile.user || !profile.position) {
        return toast.error("Заполните все данные сотрудников");
      }
    }

    // * проверка на не отредактированные профили
    for (const key in editedProfiles) {
      if (key !== "undefined" && editedProfiles[key]) {
        return toast.error("У Вас есть несохранненые данные сотрудника");
      }
    }
    setLoading(true);
    const res = await dispatch(asyncEditCompany({ formData, company_id: _id }));
    setLoading(false);
    if (res.error) return;

    handleChange && handleChange("employees:companies");

    changeModalVisible("infoCompany", false);
  };

  const onInfoUser = (info) => {
    const page = getCurrentPage();

    goToPage("userInfo", { userId: info.user._id });
    return changeModalVisible("infoCompany", false);
  };

  const goToPageProject = async (info) => {
    goToPage("project", { projectId: info._id });
  };

  const sorted = (arr) => {
    return arr.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  const createProfileList = useMemo(() => {
    const profiles = info.profiles;
    const map = {};

    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];

      if (!map[profile.user._id]) {
        map[profile.user._id] = { ...profile, positions: [profile.position] };
      } else {
        map[profile.user._id].positions.push(profile.position);
      }
    }
    const res = [];
    for (let i = 0; i < Object.values(map).length; i++) {
      const current = map[Object.keys(map)[i]];
      const positionsName = current.positions.map((position) => position.name).join(", ");
      res.push({ ...current, name: getFormatFIO(current.user) + " ( " + positionsName + ")" });
    }
    return sorted(res);
  }, [info]);

  const createResponsiblesList = useMemo(() => {
    const res = [];

    for (let i = 0; i < responsiblesList.length; i++) {
      const current = responsiblesList[i];

      res.push({
        ...current,
        name: current.name + " ( " + getFormatFIO(current.responsible.user) + ")",
      });
    }
    return sorted(res);
  }, [responsiblesList]);

  useEffect(() => {
    profiles.forEach((profile) => {
      if (profile.user?.value !== profile.origin?.user?.value) {
        setEditedProfiles((prev) => ({
          ...prev,
          [profile._id]: true,
        }));
      } else {
        setEditedProfiles((prev) => ({
          ...prev,
          [profile._id]: false,
        }));
      }
    });
  }, [profiles]);

  useEffect(() => {
    if (companyProfiles && Array.isArray(companyProfiles)) {
      setProfiles(convetToProfileList(companyProfiles));
    }
    getResponsibles();
  }, []);

  return (
    <ModalWrapper
      title={edit ? "Редактировать компанию" : "Информация о компании"}
      onClose={() => changeModalVisible("infoCompany", false)}
      editBtn={{
        visible: editEvent ? false : editPermission ? true : false,
        onClick: () => setEditEvent(!editEvent),
      }}
      // btns={
      //   edit ? (
      //     <div className="modalEditCompany-btn-wrapper">
      //       <Button title="Сохранить" color="blue" type="submit" form={formId} />
      //     </div>
      //   ) : null
      // }
    >
      {editEvent ? (
        <>
          <form className="modalEditCompany" id={formId}>
            <div className="modalEditCompany-content modalEditCompany-content">
              <Screen size={"lg"}>
                <h4 className="modalEditCompany-content-title">Основная информация</h4>
              </Screen>
              <div className="modalEditCompany-content-baseInfo">
                <ul className="modalEditCompany-content-info modalEditCompany-content-info">
                  <li className="modalEditCompany-content-info-item name-company">
                    <Input
                      onChange={changeIV}
                      value={IV.nameСompany}
                      name="nameСompany"
                      title="Название:"
                      placeholder="Введите название компании"
                      validate={checkValidateIV("nameСompany")}
                    />
                  </li>
                  <li className="modalEditCompany-content-info-item">
                    <Input
                      onChange={changeIV}
                      value={IV.innСompany}
                      name="innСompany"
                      title="ИНН:"
                      placeholder="Введите ИНН"
                      validate={checkValidateIV("innСompany")}
                    />
                  </li>
                  <li className="modalEditCompany-content-info-item">
                    <Input
                      onChange={changeIV}
                      value={IV.emailСompany}
                      name="emailСompany"
                      title="Email:"
                      placeholder="Введите email"
                      validate={checkValidateIV("emailСompany")}
                    />
                  </li>

                  <li className="modalEditCompany-content-info-item">
                    <Input
                      onChange={changeIV}
                      value={IV.phoneСompany}
                      name="phoneСompany"
                      title="Телефон:"
                      type="tel"
                      placeholder="+ 7 000 000 00 00"
                      validate={checkValidateIV("phoneСompany")}
                    />
                  </li>

                  <li className="modalEditCompany-content-info-item">
                    <InputSearch
                      forInput={true}
                      type="region"
                      name="region"
                      value={options.region.value}
                      title="Город:*"
                      handleChange={(data) => {
                        changeOptions("region", "value", data);
                      }}
                      placeholderSelect="Выберите город"
                      validate={checkValidateOptions("region")}
                    />
                  </li>
                  <li className="modalEditCompany-content-info-item">
                    <SelectMulti
                      options={options.typeCompanies.options}
                      value={options.typeCompanies.value}
                      handleChange={(data) => changeOptions("typeCompanies", "value", data)}
                      placeholder="Выберите тип"
                      title="Тип:*"
                      forInput
                      validate={checkValidateOptions("typeCompanies")}
                    />
                  </li>
                </ul>
                <div className="modalEditCompany-content-info-logo">
                  <span className="modalEditCompany-content-info-title">Логотип:</span>
                  <div className="modalEditCompany-content-info-img">
                    <ImageUpload
                      edit
                      onChange={(value) => changeIV("set", { name: "logo", value })}
                      logo={logo}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Screen size={"lg"}> */}
            <div className="modalEditCompany-btn-wrapper">
              <Button
                title="Сохранить"
                onClick={() => onSubmit()}
                color="blue"
                form={formId}
                loading={loading}
              />
            </div>
            {/* </Screen> */}
            <div className="hr"></div>
            <ModalEditCompanyNavigate chapter={chapter} changeWiewChapter={changeWiewChapter} />
            {chapter === "employees" && (
              <div className="modalEditCompany-content">
                <div className="modalEditCompany-employee-list">
                  {" "}
                  <SearchModal
                    onChange={changeValue}
                    placeholder={"Найти сотрудника по должности или ФИО"}
                  />
                </div>

                <div className="modalEditCompany-employee-list">
                  <div className="modalEditCompany-employee-wrapper">
                    {filteredProfiles.map((profile) => (
                      <UserRowModal
                        info={profile}
                        changeProfile={changeProfile}
                        optionsPosition={options.position.options}
                        removeProfile={() => removeProfile(profile.index)}
                        saveProfile={() => saveProfile(profile.index)}
                        createProfile={() => createProfile(profile.index)}
                        resetProfile={() => resetProfile(profile.index)}
                        disabledPositions={!!profile._id}
                        disabledUsers={loading === profile.index}
                        visibleRemove={!!editedProfiles[profile._id] && !!profile._id}
                        visibleSave={!!profile._id && !!editedProfiles[profile._id]}
                        visibleCreate={!profile._id}
                        visibleReset={!!profile._id && !!editedProfiles[profile._id]}
                      />
                    ))}
                  </div>
                </div>
                <Button
                  onClick={addProfileToList}
                  title="Сотрудник"
                  color="dark"
                  icon={{ position: "left" }}
                />
              </div>
            )}
            {/* responsibles */}
            {chapter === "responsibles" && (
              <div className="modalEditCompany-content">
                <div className="modalEditCompany-employee-list">
                  <SearchModal
                    onChange={changeValue}
                    placeholder={"Найти отвественного по проекту или ФИО"}
                  />
                </div>
                <div className="modalEditCompany-employee-list">
                  <div className="modalEditCompany-employee-wrapper">
                    {filteredResponsible.map((responsible) => (
                      <ResponsiblesItem
                        info={responsible}
                        changeResponsibles={changeResponsibles}
                        resetResponsibles={resetResponsibles}
                        saveResponsibles={saveResponsibles}
                        removeResponsible={removeResponsible}
                        createResponsibles={createResponsibles}
                        key={responsible.index}
                        companyId={_id}
                        projectsIds={responsibles.map((item) => item.project?.value)}
                      />
                    ))}
                  </div>
                </div>
                <Button
                  onClick={addResponsibles}
                  title="Проект"
                  color="dark"
                  icon={{ position: "left" }}
                />
              </div>
            )}
          </form>
        </>
      ) : (
        <div className="modalInfoCompany">
          <div className="modalInfoCompany-content modalInfoCompany-content">
            <h4 className="modalInfoCompany-content-title">Основная информация</h4>
            <div className="modalInfoCompany-content-baseInfo">
              <div className="modalInfoCompany-content-baseInfo-wrapper">
                <div className="modalInfoCompany-content-info-item">
                  <span className="modalInfoCompany-content-info-title">Название:</span>
                  <span className="modalInfoCompany-content-info-text">{name}</span>
                </div>
                <ul className="modalInfoCompany-content-info">
                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">ИНН:</span>
                    <span className="modalInfoCompany-content-info-text">{inn}</span>
                  </li>

                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">Город:</span>
                    <span className="modalInfoCompany-content-info-text">{region.name}</span>
                  </li>
                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">Email:</span>
                    <span className="modalInfoCompany-content-info-text">{email}</span>
                  </li>

                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">Телефон:</span>
                    <span className="modalInfoCompany-content-info-text">{phone}</span>
                  </li>
                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">Тип:</span>
                    <span className="modalInfoCompany-content-info-text">
                      {transformTypeCompany(type)}
                    </span>
                  </li>
                  <li className="modalInfoCompany-content-info-item">
                    <span className="modalInfoCompany-content-info-title">Руководитель:</span>
                    <span className="modalInfoCompany-content-info-text">
                      {getFormatFIO(director)}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="modalInfoCompany-content-avatar">
                <span className="modalInfoCompany-content-info-title">Логотип:</span>
                <div className="modalInfoCompany-content-logo modalInfoCompany-content-logo">
                  <ImageUpload
                    onChange={(value) => changeIV("set", { name: "logo", value })}
                    logo={logo}
                    edit={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hr"></div>
          <ModalEditCompanyNavigate chapter={chapter} changeWiewChapter={changeWiewChapter} />
          {chapter === "employees" && (
            <>
              <div className="modalInfoCompany-content modalInfoCompany-content-listModalProfile">
                <ListProfiles
                  profiles={createProfileList}
                  placeholder={"Найти ответсвенного по проекту или ФИО"}
                  onClick={onInfoUser}
                />
              </div>
            </>
          )}
          {chapter === "responsibles" && (
            <>
              <ListProfiles
                profiles={createResponsiblesList}
                placeholder={"Найти ответсвенного по проекту или ФИО"}
                onClick={goToPageProject}
              />
            </>
          )}
        </div>
      )}
    </ModalWrapper>
  );
};

export default ModalInfoEditCompany;
