import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";
import arrowForPagination from "../../assets/icons/arrowForPagination.svg";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";
import { getPageCount } from "../../utils/getPageCount";
import { getLimitElements } from "../../utils/getLimitElements";
import { getLimitDocElements } from "../../utils/getLimitDocElements";
import { getLimitsListChart } from "../../utils/getLimitsListChart";
import { getLimitNotif } from "../../utils/getLimitNotif";
import { useSearch } from "../../hooks/useSearch";
import { useSelector } from "react-redux";
import { changeLimit } from "../../store/reducers/searchReducer";
import { useDispatch } from "react-redux";

function Pagination(props) {
  const {
    marginPagesDisplayed,
    pageRangeDisplayed,
    onPageChange: onPageChangeProps,
    count,
    type,
    currentPage: currentPageProps,
    modal = false,
  } = props;
  const dispatch = useDispatch();
  const { changePage, params, queries, setQueryParam, goToPage, setParams, getCurrentPage } =
    useSearch();

  const currentPage = currentPageProps || params.page;

  const { globalLimit } = useSelector((state) => state.search);

  const [countLimit, setCountLimit] = useState(getLimitElements() || 10);
  // const [mainLimit, setMainLimit] = useState(getLimitElements() || 10);

  const onPageChange = ({ selected }) => {
    const page = selected + 1;

    // if (+params.page === page) return console.log("return", { params: params.page, page });

    // console.log("onPageChange", { params: params.page, page });

    onPageChangeProps ? onPageChangeProps(page) : changePage(page);
  };

  useEffect(() => {
    if (queries.limit) return setCountLimit(queries.limit);

    // if (type === "documentation") {
    //   setCountLimit(getLimitDocElements());
    //   setMainLimit(getLimitDocElements());
    // } else if (type === "listChart") {
    //   setCountLimit(getLimitsListChart());
    //   setMainLimit(getLimitsListChart());
    // } else if (type === "notifications") {
    //   setCountLimit(getLimitNotif());
    //   setMainLimit(getLimitNotif());
    // } else {
    //   setCountLimit(getLimitElements());
    //   setMainLimit(getLimitElements());
    // }
  }, [type, queries.limit]);

  const changeCountLimit = (limit) => {
    const queriesGoToPage = { limit };
    localStorage.setItem("limit", String(limit || 10));
    dispatch(changeLimit(limit));
    const page = getCurrentPage();
    setCountLimit(limit);
    goToPage(page, { withoutDefaultQueries: true }, queriesGoToPage);
  };

  // console.log(globalLimit);

  return (
    <>
      {count > 10 && (
        <div className="pagination">
          {count > countLimit && (
            <div className="pagination-wrapper">
              <Screen size={"lg"}>
                <ReactPaginate
                  initialPage={
                    currentPage
                      ? currentPage && currentPage >= 0
                        ? currentPage - 1
                        : 0
                      : params.page
                  }
                  marginPagesDisplayed={marginPagesDisplayed || 1}
                  pageRangeDisplayed={pageRangeDisplayed || 7}
                  pageCount={getPageCount(count, countLimit) || 0}
                  nextLabel={
                    <Icons
                      type={"arrowForPagination"}
                      size={"xl"}
                      cursor
                      title={{ visible: true, text: "Вперед" }}
                    />
                  }
                  previousLabel={
                    <Icons
                      type={"arrowForPagination"}
                      size={"xl"}
                      cursor
                      className={"rotate180"}
                      title={{ visible: true, text: "Назад" }}
                    />
                  }
                  onPageChange={onPageChange}
                />
              </Screen>
              <Screen size={"lg"} down>
                <ReactPaginate
                  initialPage={currentPage && currentPage >= 0 ? currentPage - 1 : 0}
                  marginPagesDisplayed={marginPagesDisplayed || 0}
                  pageRangeDisplayed={pageRangeDisplayed || 3}
                  pageCount={getPageCount(count, countLimit) || 0}
                  nextLabel={
                    <Icons type={"arrowForPagination"} color={"white"} size={"xl"} cursor />
                  }
                  previousLabel={
                    <Icons type={"arrowForPagination"} size={"xl"} cursor className={"rotate180"} />
                  }
                  onPageChange={onPageChange}
                />
              </Screen>
            </div>
          )}
          {count > 20 && !modal && (
            <div className="pagination-countElement">
              <span>На странице:</span>
              <ul>
                <li
                  className={`pagination-countElement-item ${countLimit == 10 ? "selected" : ""}`}
                  onClick={() => changeCountLimit(10)}
                >
                  10
                </li>

                {count > 20 && (
                  <li
                    className={`pagination-countElement-item ${countLimit == 20 ? "selected" : ""}`}
                    onClick={() => changeCountLimit(20)}
                  >
                    20
                  </li>
                )}
                {count > 50 && (
                  <li
                    className={`pagination-countElement-item ${countLimit == 50 ? "selected" : ""}`}
                    onClick={() => changeCountLimit(50)}
                  >
                    50
                  </li>
                )}
                {count > 100 && (
                  <li
                    className={`pagination-countElement-item ${
                      countLimit == 100 ? "selected" : ""
                    }`}
                    onClick={() => changeCountLimit(100)}
                  >
                    100
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Pagination;
