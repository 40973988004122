import React, { useEffect, useState, useRef } from "react";
import "./CardColumnTask.scss";
import { Draggable } from "react-beautiful-dnd";
import Icons from "../../../Icons/Icons";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../utils/getFullPath";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { useModal } from "../../../../hooks/useModal";
import Screen from "../../../Screen/Screen";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";
import { getFormatDate } from "../../../../utils/getFormatDate";
import CardColumnTaskUser from "./components/CardColumnTaskUser/CardColumnTaskUser";
import { useCreateStatusDay } from "../../../../hooks/useCreateStatusDay";
import { asyncGetTask } from "../../../../store/reducers/tasksReducer";
import { useDispatch } from "react-redux";

const CardColumnTask = (props) => {
  const { data, draggableId, index, addClass } = props;

  const { changeModalVisible } = useModal();
  const {
    name,
    creator,
    author,
    executors,
    isImportant,
    number,
    start,
    end,
    unReadComments,
    status,
  } = data;
  const [menuVisible, setMenuVisible] = useState(false);

  const ref = useRef();
  const dispatch = useDispatch();

  const { edit, remove } = useHasPermissions(`taskCard`, data);

  const { statusDay } = useCreateStatusDay(data);

  useOnClickOutside(ref, () => setMenuVisible(false));

  const { height, width } = useWindowDimensions();

  const [count, setCount] = useState(0);

  const handleOnSubtaskInfo = async (info, name = "infoTask", editMode = false) => {
    const res = await dispatch(asyncGetTask(info._id));
    if (res.error) return;

    changeModalVisible(name, true, res.payload, editMode);
  };

  const onEvent = (name) => {
    let modalName = "infoTask";
    let editMode = false;

    if (name === "edit") {
      modalName = "editTask";
      editMode = true;
    }

    if (name === "remove") {
      modalName = "deleteTask";
    }

    return handleOnSubtaskInfo(data, modalName, editMode);
  };

  useEffect(() => {
    if (width >= 2000) {
      return setCount(6);
    } else if (width >= 1900 && width < 2000) {
      return setCount(5);
    } else if (width >= 1700 && width < 1900) {
      return setCount(4);
    } else if (width >= 1500 && width < 1700) {
      return setCount(5);
    } else if (width >= 1300 && width < 1500) {
      return setCount(4);
    } else if (width >= 1024 && width < 1300) {
      return setCount(5);
    } else if (width < 1024) {
      return setCount(10);
    }
  }, [width]);

  const unReadCommentsValue = unReadComments - 2 >= 5 ? "5+" : unReadComments;

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        return (
          <li
            className={`taskColumnCard`}
            data-status={data.status}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              userSelect: "none",
              filter: snapshot.isDragging ? "drop-shadow(3px 6px 6px rgba(0, 0, 0, 0.27))" : "",
              border: snapshot.isDragging ? "1px solid #0094e7" : "",
              ...provided.draggableProps.style,
            }}
          >
            {menuVisible && (
              <div className="taskColumnCard-modal" ref={ref}>
                <span onClick={() => onEvent("info")}>Информация о задаче</span>
                {edit && <span onClick={() => onEvent("edit")}>Редактировать задачу</span>}
                {remove && <span onClick={() => onEvent("remove")}>Удалить задачу</span>}
              </div>
            )}
            <div className="taskColumnCard-header">
              <div
                className="taskColumnCard-header-title"
                title={name}
                onClick={() => onEvent("info")}
              >
                {isImportant && <Icons type={"fire"} size={"md"} />}
                {unReadComments > 0 && (
                  <div className="taskColumnCard-commentsCount">
                    <span>{unReadCommentsValue}</span>
                  </div>
                )}
                <span>
                  №{number} {name}
                </span>
              </div>
              <div className="taskColumnCard-header-menu">
                <Screen size={"lg"}>
                  <button
                    className="taskColumnCard-header-menu-btn"
                    onClick={() => setMenuVisible((prev) => !prev)}
                  >
                    <Icons type={"menu"} size={"md"} cursor title={{ visible: true, text: "" }} />
                  </button>
                </Screen>
                <Screen size={"lg"} down>
                  <button
                    className="taskColumnCard-header-menu-btn"
                    onClick={() => changeModalVisible("menuTasks", true, data)}
                  >
                    <Icons type={"menu"} size={"md"} />
                  </button>
                </Screen>
              </div>
            </div>
            <div className="taskColumnCard-content">
              <div className="taskColumnCard-content-box">
                <span className="taskColumnCard-title">Период:</span>
                <div
                  className="taskColumnCard-content-box-period"
                  title={`Поставлена ${getFormatDate(start)} - Крайний срок ${getFormatDate(end)}`}
                >
                  <span className="taskColumnCard-content-box-period-startDate">
                    {getFormatDate(start)}
                  </span>
                  <span>-</span>
                  <span className="taskColumnCard-content-box-period-endDate">
                    {getFormatDate(end)}
                  </span>
                </div>
              </div>
              <div className="taskColumnCard-content-box day" title={statusDay.title}>
                <span className="taskColumnCard-title">{statusDay.text} </span>
                <div className={`taskColumnCard-content-box-day ${statusDay.type}`}>
                  <span>{statusDay.day}</span>
                </div>
              </div>
            </div>

            <CardColumnTaskUser info={author} title={"Автор"} />

            <div className="taskColumnCard-footer taskColumnCard-footer">
              {executors?.length > 1 ? (
                <>
                  <span className="taskColumnCard-title">Исполнители:</span>
                  <div className="taskColumnCard-footer-wrapper">
                    <div className="taskColumnCard-footer-avatars">
                      {executors.slice(0, count).map((elem) => {
                        return (
                          <img
                            className="taskColumnCard-footer-avatar"
                            src={getFullPath(elem.user.avatar, "avatar")}
                            alt={getFormatFIO(elem.user)}
                            key={elem._id}
                            title={`${getFormatFIO(elem.user)} (${elem.company.name || ""}, ${
                              elem.position.name || ""
                            })`}
                          />
                        );
                      })}
                      {executors.length > count && (
                        <div className="taskColumnCard-footer-other">
                          <span>+{executors.length - count}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <CardColumnTaskUser
                    info={!!executors[0] ? executors[0] : null}
                    title={"Исполнитель"}
                  />
                </>
              )}
            </div>
          </li>
        );
      }}
    </Draggable>
  );
};
export default CardColumnTask;
