import React from "react";

import SectionLoader from "./components/SectionLoader/SectionLoader";

const WrapperLoader = ({ loading, children, chapter, viewCards, countRow }) => {
  return (
    <>
      {loading ? (
        <SectionLoader
          chapter={chapter}
          viewCards={viewCards}
          countRow={countRow}
        />
      ) : (
        children
      )}
    </>
  );
};

export default WrapperLoader;
