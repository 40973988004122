import React, { useRef } from "react";
import CanvasPlan from "../../../../pages/desktop/Project/components/Layers/components/CanvasPlan/CanvasPlan";
import { useState } from "react";
import "./ProjectInfo.scss";
import { useEffect } from "react";
import ImageList from "../../../ImageList/ImageList";
import { useModal } from "../../../../hooks/useModal";
import { transformStatus } from "../../../../utils/transformStatus";
import { getFullPath } from "../../../../utils/getFullPath";
import { transformTypeWork } from "../../../../pages/desktop/DeliveryWork/utils";
import Icons from "../../../Icons/Icons";
import { useSelector } from "react-redux";
import Screen from "../../../Screen/Screen";
import { useNavigate } from "react-router-dom";
import { useCreateStatusDay } from "../../../../hooks/useCreateStatusDay";

function ProjectInfo(props) {
  const {
    projectData = null,
    showSelects = [], // type_work
    hideSelects = [], // priorities
    viewModal = false,
  } = props;

  const navigate = useNavigate();

  const refSize = useRef();

  const { sizeModal } = useSelector((state) => state.settings);

  const { getInfo, changeModalVisible } = useModal();

  const infoModal = projectData || getInfo("infoTask", "data");

  const { project, status, layer, photos, isImportant, coordinates } = infoModal;

  const { statusDay } = useCreateStatusDay(infoModal);

  const [images, setImages] = useState([]);
  const [lines, setLines] = useState(null);
  const [sizeLayer, setSizeLayer] = useState({ width: 1070, height: 660 });

  const navigateToProject = () => {
    if (!project?._id) return;
    navigate(`/project/${project?._id}/basicInformation`);
  };

  useEffect(() => {
    setImages(photos);
    setLines(coordinates);
  }, [project]);

  const { offsetWidth } = refSize.current || {};

  const resizeHandler = () => {
    setSizeLayer({ width: offsetWidth, height: offsetWidth / 1.6 });
  };

  useEffect(() => {
    resizeHandler();
  }, [offsetWidth, sizeModal, viewModal]);

  return (
    <div className={`projectInfo ${viewModal ? "rows" : "columns"}`}>
      <div className={`projectInfo-header ${viewModal ? "rows" : "columns"}`}>
        {showSelects.includes("type_work") && (
          <div className="projectInfo-item projectInfo-item-textBox">
            <span className="projectInfo-item-title">Тип работы: </span>
            <span className="bold">{transformTypeWork(infoModal.type_work).name}</span>
          </div>
        )}
        {isImportant && (
          <div className="projectInfo-item projectInfo-item-textBox">
            {/* <span className="projectInfo-item-title">Тип задачи: </span> */}
            <span className="bold projectInfo-item-text important">
              {isImportant && "Важная задача"}
              {isImportant && <Icons type="fire" size={"md"} />}
            </span>
          </div>
        )}
        {!!statusDay?.type?.length && (
          <div className="projectInfo-item projectInfo-item-textBox" title={statusDay?.title}>
            <span className="projectInfo-item-title">{statusDay?.text}</span>
            <span className={`bold projectInfo-item-text ${statusDay?.type}`}>
              {statusDay?.day}
            </span>
          </div>
        )}
        <div className="projectInfo-item projectInfo-item-textBox">
          <span className="projectInfo-item-title">Статус: </span>
          <span className={`projectInfo-item-text ${transformStatus(status)?.name_en}`}>
            {transformStatus(status).name}
          </span>
        </div>
        <Screen size={"lg"}>
          {!!project && (
            <>
              <div className="projectInfo-item projectInfo-item-textBox">
                <span className="projectInfo-item-title">Проект: </span>
                <span
                  onClick={navigateToProject}
                  className="projectInfo-item-text projectInfo-item-text-project"
                >
                  {project?.name || "-"}
                </span>
              </div>
            </>
          )}
        </Screen>
      </div>
      <Screen size={"lg"} down>
        {!!project && (
          <>
            <div className="projectInfo-item projectInfo-item-textBox">
              <span className="projectInfo-item-title">Проект: </span>
              <span className="bold">{project?.name || "-"}</span>
            </div>
          </>
        )}
      </Screen>
      <div className={`projectInfo-footer ${viewModal ? "rows" : "columns"}`}>
        {!!project && (
          <div className="projectInfo-layer" ref={refSize}>
            <div className="projectInfo-item projectInfo-item-textBox">
              <span className="projectInfo-item-title">Слой: </span>
              <span className="bold">{layer?.name || "-"}</span>
            </div>

            {!!layer && (
              <div
                className={`projectInfo-item-canvas  ${viewModal ? "zoomRows" : "zoomColumns"} ${
                  sizeModal ? "zoomBig" : "zoomSmall"
                }`}
                style={{
                  width: `${sizeLayer.width}px`,
                  height: `${sizeLayer.height}px`,
                }}
              >
                <Icons
                  onClick={() =>
                    changeModalVisible("showPoint", true, {
                      ...layer,
                      coordinates,
                    })
                  }
                  type="magnifier"
                  cursor
                  size="xl"
                  color="black"
                  className="projectInfo-item-canvas-magnifier"
                />
                <div className="projectInfo-item-canvas-wrapper">
                  <CanvasPlan
                    lines={lines}
                    imgSrc={getFullPath(layer.image, "layer")}
                    visiblePoints={true}
                    // filterPin={status}
                    filterPin={"all"}
                    changeCurrentTask={() => {}}
                    onChangelines={() => {}}
                    width={1070}
                    height={660}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {!!photos?.length && (
          <ImageList
            onChange={(list) => setImages(list)}
            images={images}
            title="Фото задачи:"
            editMode={false}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectInfo;
