import React, { useState, useEffect } from "react";
import { Gantt, ViewMode } from "./dist/index.js";
import "./index.scss";
import GanttChartHeader from "./components/GanttChartHeader/GanttChartHeader";
import TooltipContent from "./components/TooltipContent/TooltipContent.jsx";
import TaskListHeader from "./components/TaskListHeader/TaskListHeader.jsx";
import TaskListTable from "./components/TaskListTable/TaskListTable.jsx";
import { useDispatch } from "react-redux";
import {
  asyncEditWork,
  asyncGetWorksAndSteps,
  asyncMoveStageAndWork,
} from "../../../../store/reducers/projectsReducer.js";
import { useModal } from "../../../../hooks/useModal.js";
import { toast } from "react-toastify";
// import { Gantt, ViewMode } from "../../../Gantt/ganttTaskReact";

const GanttChart = ({ tasksData, edit }) => {
  const { changeModalVisible, getInfo } = useModal();
  const [view, setView] = useState(ViewMode.Day);
  const [showTaskSection, setTaskSection] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [arrowVisible, setArrowVisible] = useState(true);
  const [visible, setVisible] = useState(false);
  const [heightGantt, setHeigthGantt] = useState(350);
  const [sortTask, setSortTask] = useState([]);

  const dispatch = useDispatch();

  const getColumnWidth = () => {
    switch (view) {
      case ViewMode.Year:
        return 350;
      case ViewMode.Month:
        return 300;
      case ViewMode.Week:
        return 250;
      default:
        return 65;
    }
  };

  function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }

  let taskIdDate = null;
  const handleTaskChange = (task) => {
    if (taskIdDate === task.id) return;
    taskIdDate = task.id;

    // console.log("On date change Id:", task);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));

    if (task.type === "task") {
      changeModalVisible("infoWork", true, task, true);
    } else {
      changeModalVisible("infoStage", true, task, true);
    }
    setTasks(newTasks);
  };

  const getWorksAndSteps = async () => {
    const scheduleWorksId = getInfo("infoWorkSchedule", "data")?._id;
    // console.log(scheduleWorksId);
    if (!scheduleWorksId) return;
    const res = await dispatch(asyncGetWorksAndSteps(scheduleWorksId));
    if (res.error) return;
    setTasks(updateFormatTask(res.payload));
  };

  // удаление задачи можно повесить на любое из нажатий
  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    // changeModalVisible("deleteWork", true, task);
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  let taskIdProgress = null;
  const handleProgressChange = async (task, a) => {
    if (taskIdProgress === task.id) return;
    taskIdProgress = task.id;

    const data = {
      progress: task.progress,
      workId: task._id,
    };

    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    const res = await dispatch(asyncEditWork(data));
    if (res.meta.requestStatus === "fulfilled") {
    }
  };

  const handleDblClick = (task) => {
    // changeModalVisible("infoStage", true, task, true);
    // alert("On Double Click event Id:" + task.id);
    if (task.type === "task") {
      changeModalVisible("infoWork", true, task);
    } else {
      changeModalVisible("infoStage", true, task);
    }
  };

  const handleClick = (task) => {
    // console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On expander click Id:" + task.id);
  };

  const updateFormatTask = (tasksData) => {
    if (!tasksData.length) return [];
    const res = tasksData
      .reduce((acc, el, i) => {
        if (!el.number) el.number = el.index;
        const works = el.works.map((w) => ({
          ...w,
          project: el,
          number: w.index,
        }));
        return [...acc, el, ...works];
      }, [])
      .map((el, i) => {
        return {
          ...el,
          iterationIndex: i,
        };
      });
    return res;
  };

  useEffect(() => {
    setTasks(updateFormatTask(tasksData));
  }, [tasksData]);

  // оставлена для теста не подключена

  // export type OnDragEndResponder = (result: DropResult, provided: ResponderProvided) => void;
  async function handleOnDragEnd(result) {
    // console.log("handleOnDragEnd", result);

    if (!result.destination) return;

    const { destination, source } = result;
    console.log("destination", destination, "source", source);
    const toIndex = destination.index; // куда
    const fromIndex = source.index; // откуда

    if (fromIndex === toIndex) return;
    console.log({ fromIndex, toIndex });

    const fromTask = tasks.find((t) => t.iterationIndex === fromIndex);
    const toTask = tasks.find((t) => t.iterationIndex === toIndex);

    console.log({ fromTask, toTask });

    if (fromTask.type !== toTask.type)
      return toast.warning("Нельзя переместить работу в другой этап");

    const data = {
      type: fromTask.type === "project" ? "stage" : "work",
      scheduleWorkId: fromTask.scheduleWorks,
      fromIndex: fromTask.index,
      toIndex: toTask.index,
      stage: fromTask?.project?._id,
    };
    // if (fromIndex < toIndex) {
    //   data.toIndex++;
    // }
    if (fromTask.type === "project") {
      await dispatch(asyncMoveStageAndWork(data));
    }
    //
    else {
      if (fromTask.project !== toTask.project)
        return toast.warning("Нельзя переместить работу в другой этап");
      await dispatch(asyncMoveStageAndWork(data));
    }

    // const newItems = [...tasks];

    // const [removed] = newItems.splice(result.source.index, 1);
    // newItems.splice(result.destination.index, 0, removed);
    // // console.log("newItems", newItems);
    // return setTasks(newItems);
    getWorksAndSteps();
  }

  return (
    <div className="ganttChart">
      <GanttChartHeader
        // onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        setArrowVisible={setArrowVisible}
        arrowVisible={arrowVisible}
        view={view}
        setView={setView}
        edit={edit}
        setHeigthGantt={setHeigthGantt}
        visibleChangeHeght={true}
      />
      {tasks.length > 0 && (
        <div
          className={`ganttChart-content ${showTaskSection ? "ganttChart-content-open" : ""} ${
            tasks.length < 5 ? "ganttChart-content-noOweflow" : ""
          }`}
        >
          <div className="ganttChart-taskList">
            <TaskListHeader
              tasks={tasks}
              headerHeight={60}
              showTaskSection={showTaskSection}
              setTaskSection={setTaskSection}
            />
            <TaskListTable
              showTaskSection={showTaskSection}
              setTaskSection={setTaskSection}
              tasks={tasks}
              rowHeight={40}
              handleOnDragEnd={handleOnDragEnd}
              edit={edit}
            />
          </div>

          <Gantt
            tasks={tasks}
            locale={"ru"}
            viewMode={view}
            // viewDate={new Date()} // центрирование по дате
            onDateChange={edit ? handleTaskChange : ""}
            onDelete={handleTaskDelete}
            onProgressChange={edit ? handleProgressChange : ""}
            onDoubleClick={handleDblClick} //
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            //listCellWidth={isChecked ? "155px" : ""} // Задает ширину ячейки списка задач. Пустая строка означает "нет отображения".
            // listCellWidth={"500px"}
            columnWidth={getColumnWidth()}
            barCornerRadius={3} // скругление задач
            fontFamily={"Montserrat"}
            arrowColor={arrowVisible ? "grey" : "rgba(0, 0, 0, 0)"} // цвет стрелок c помощью rgba(0, 0, 0, 0) можно полностью убирать отображение
            //barProgressColor={"#db1f35"} // hz
            // milestoneBackgroundSelectedColor={"#db1f35"} // hz
            barFill={80} // высота задач
            handleWidth={15} // ширина элемента для изменения сроков задачи
            headerHeight={60} // высота загаловка таблицы
            rowHeight={40} // высота строки таблицы
            arrowIndent={20} //отступ стрелки
            ganttHeight={tasks.length > 9 && heightGantt}
            // ограничение
            // по
            // высоте
            // всей
            // таблицы
            TooltipContent={TooltipContent} // можно вываодить кастомные тултипы
            preStepsCount={1} // отступ перед первой задачей
            TaskListTable={(props) => (
              <TaskListTable
                showTaskSection={showTaskSection}
                setTaskSection={setTaskSection}
                tasks={tasks}
                rowHeight={40}
                handleOnDragEnd={handleOnDragEnd}
                edit={edit}
                {...props}
              />
            )}
            // <TaskListHeader
            //     tasks={tasks}
            //     headerHeight={60}
            //     showTaskSection={showTaskSection}
            //     setTaskSection={setTaskSection}
            //   />
            // }
            // TaskListHeader={TaskListHeader}
            TaskListHeader={(props) => {
              return (
                <TaskListHeader
                  showTaskSection={showTaskSection}
                  setTaskSection={setTaskSection}
                  tasks={tasks}
                  rowHeight={40}
                  handleOnDragEnd={handleOnDragEnd}
                  {...props}
                />
              );
            }}
            changeModalVisible={changeModalVisible}
          />
        </div>
      )}
    </div>
  );
};

export default GanttChart;
