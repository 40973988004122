import { transformForSelect } from "../../../../../../utils/transformForSelect";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";

export const getValueForOptions = (data, field) => {
  if (!data) return null;

  if (data[field]?.value) return data[field]?.value;

  if (field === "director_ssk") {
    if (data["director_ssk"] && data["director_ssk"]?._id) {
      return {
        label: `${getFormatFIO(data["director_ssk"]?.user)} (Компания:${
          data["director_ssk"]?.company?.name
        }; Должность: ${data["director_ssk"]?.position?.name})`,
        value: data["director_ssk"]?._id,
      };
    }

    return [];
  }

  if (field === "chief_director") {
    if (data["chief_director"] && data["chief_director"]?._id) {
      return {
        label: `${getFormatFIO(data["chief_director"]?.user)} (Компания:${
          data["chief_director"]?.company?.name
        }; Должность: ${data["chief_director"]?.position?.name})`,
        value: data["chief_director"]?._id,
      };
    }

    return [];
  }

  if (field === "contractor") {
    if (data["contractor"] && ["contractor"]?.value) return data["contractor"];
    else if (data["contractor"] && data["contractor"]?.name)
      return transformForSelect(data["contractor"]);
    return null;
  }

  return null;
};
