import React, { useState } from "react";
import "./SkeletonGridProject.scss";
import SkeletonLine from "../SkeletonLine/SkeletonLine";
import SkeletonCircle from "../SkeletonCircle/SkeletonCircle";

const SkeletonGridProject = () => {
  const [elem, setElem] = useState(8);
  return (
    <div className="skeletonGridProject">
      {Array(elem)
        .fill(0)
        .map((e, i) => (
          <div className="skeletonGridProject-card" key={i}>
            <div className="skeletonGridProject-card-img"></div>
            <div className="skeletonGridProject-card-main">
              <SkeletonLine width={"40%"} key={i} />
              {Array(3)
                .fill(0)
                .map((e, i) => (
                  <SkeletonLine width={"80%"} key={i} />
                ))}
            </div>
            <div className="skeletonGridProject-card-footer">
              <div className="skeletonGridProject-card-footer-left">
                <SkeletonLine width={"80%"} key={i} />
                {Array(6)
                  .fill(0)
                  .map((e, i) => (
                    <SkeletonLine width={"50%"} key={i} />
                  ))}
              </div>
              <SkeletonCircle size={"110px"} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default SkeletonGridProject;
