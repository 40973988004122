import React, { useState, useRef } from "react";
import { getFormatFIO } from "../../../../../../utils/getFormatFIO";
import "./StructuresListRow.scss";
import StructuresListLastRow from "../StructuresListLastRow/StructuresListLastRow";
import Icons from "../../../../../Icons/Icons";
import Screen from "../../../../../Screen/Screen";
import { useModal } from "../../../../../../hooks/useModal";
import { useOnClickOutside } from "../../../../../../hooks/useOnClickOutside";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";

const StructuresListRow = ({ director, name, employeesLength, info, last }) => {
  const { changeModalVisible } = useModal();
  const { edit, add, remove } = useHasPermissions("structuresCard", info);

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const [listVisible, setListVisible] = useState(false);

  const onEvent = (name) => {
    if (name === "add") {
      setMenuVisible(false);
      return changeModalVisible("addDepartment", true, info);
    }

    if (name === "edit") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info, true);
    }

    if (name === "del") {
      setMenuVisible(false);
      return changeModalVisible("deleteDepartment", true, info);
    }

    if (name === "info") {
      setMenuVisible(false);
      return changeModalVisible("editDepartment", true, info);
    }
    if (name === "menu") {
      return changeModalVisible("menu", true, info);
    }
  };

  return (
    <li className={`structuresListRow ${!!last ? "structuresListRow-last" : ""}`}>
      <div
        className={`structuresListRow__content ${!!last ? "structuresListRow__content-last" : ""}`}
      >
        <div className="structuresListRow__info">
          <h5 className="structuresListRow__title">
            {name} {` (сотрудников: ${employeesLength})`}
          </h5>
          <Screen size={"lg"}>
            {!!director?.user && (
              <span className="structuresListRow__text">
                Руководитель отдела: {getFormatFIO(director.user)}
              </span>
            )}
          </Screen>
        </div>
        <div className="structuresListRow__btns">
          {!!!last && info?.departments.length > 0 && (
            <button
              className="structuresListRow__btn structuresListRow__btn-structure"
              onClick={() => setListVisible(!listVisible)}
            >
              <Screen size={"lg"}>
                {listVisible ? (
                  <Icons
                    type={"arrowTop"}
                    size={"md"}
                    cursor
                    title={{ visible: true, text: "Свернуть" }}
                  />
                ) : (
                  <Icons
                    type={"structures"}
                    size={"md"}
                    cursor
                    title={{ visible: true, text: "Развернуть" }}
                  />
                )}
              </Screen>
              <Screen size={"lg"} down>
                {listVisible ? (
                  <Icons type={"arrowTop"} size={"m-md"} />
                ) : (
                  <Icons type={"structures"} size={"m-md"} />
                )}
              </Screen>
            </button>
          )}
          {/* {!!!last &&
            (listVisible?.visible && listVisible?.id === id ? (
              <button
                className="structuresListRow__btn structuresListRow__btn-structure"
                onClick={() => changeListVisible(id, false)}
              >
                <img src={arrowUpBlack} alt="icon: structures" />
              </button>
            ) : (
              <button
                className="structuresListRow__btn structuresListRow__btn-structure"
                onClick={() => changeListVisible(id, true)}
              >
                <img src={structures} alt="icon: structures" />
              </button>
            ))} */}
          <Screen size={"lg"}>
            <button
              className="structuresListRow__btn structuresListRow__btn-menu"
              onClick={() => setMenuVisible(!menuVisible)}
            >
              {!!last ? (
                <Icons
                  type={"menu"}
                  size={"md"}
                  color={"white"}
                  cursor
                  title={{ visible: true, text: "" }}
                />
              ) : (
                <Icons type={"menu"} size={"md"} cursor title={{ visible: true, text: "" }} />
              )}
            </button>
          </Screen>
          <Screen size={"lg"} down>
            <button
              className="structuresListRow__btn structuresListRow__btn-menu"
              onClick={() => onEvent("menu")}
            >
              {!!last ? (
                <Icons type={"menu"} size={"m-md"} color={"white"} />
              ) : (
                <Icons type={"menu"} size={"m-md"} />
              )}
            </button>
          </Screen>
        </div>
      </div>
      <Screen size={"lg"}>
        {menuVisible && (
          <div
            ref={ref}
            className="structuresListRow__menu-window structuresListRow-menu-window animate__animated animate__fadeIn modal"
          >
            <span onClick={() => onEvent("info")}>Информация об отделе</span>
            {edit && <span onClick={() => onEvent("edit")}>Редактировать отдел</span>}
            {!!!last && add && <span onClick={() => onEvent("add")}>Добавить отдел</span>}
            {remove && <span onClick={() => onEvent("del")}>Удалить отдел</span>}
          </div>
        )}
      </Screen>
      {listVisible && (
        <ul className="structuresListRow__rows-last structuresList-rows-last">
          {info?.departments.map((elem) => (
            <StructuresListLastRow
              key={elem._id}
              name={elem.name}
              director={elem.director}
              employeesLength={elem.profiles.length + Number(!!elem.director)}
              info={elem}
              changeModalVisible={changeModalVisible}
              last
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default StructuresListRow;
