/* eslint-disable no-prototype-builtins */
const { createSlice } = require("@reduxjs/toolkit");

const pattern = {
  visible: false,
  data: null,
  editMode: false,
  createMode: false,
  openFromComponent: null,
};

const modalReducer = createSlice({
  name: "modals",
  initialState: {
    prevModals: [],
    // аналитика {мобильная}
    reportsFilter: pattern,
    listChartFilter: pattern,
    // проект
    infoProject: pattern,
    addProject: pattern,
    deleteProject: pattern,
    completeProject: pattern,
    // участники в проекте
    infoParticipant: pattern,
    addParticipant: pattern,
    deleteParticipant: pattern,
    // слои
    addLayer: pattern,
    editLayer: pattern,
    deleteLayer: pattern,
    deleteLayerFromTasks: pattern,
    tasksLayer: pattern,
    // задачи
    infoTask: pattern,
    editTask: pattern,
    editSubTask: pattern,
    addTask: pattern,
    addSubTask: pattern,
    deleteTask: pattern,
    deleteTasks: pattern,
    deleteSubTask: pattern,
    delegationTask: pattern,
    putAsideTask: pattern,
    copyTask: pattern,
    addPoint: pattern,
    showPoint: pattern,
    addEmployeesTask: pattern,
    delEmployeesTask: pattern,
    // сдача работ
    infoDeliveryWork: pattern,
    addDeliveryWork: pattern,
    editDeliveryWork: pattern,
    deleteDeliveryWork: pattern,

    addAnswerDeliveryWork: pattern,
    editAnswerDeliveryWork: pattern,

    addLetterDeliveryWork: pattern,
    editLetterDeliveryWork: pattern,
    deleteLetterDeliveryWork: pattern,
    // протокол
    infoProtocol: pattern,
    editProtocol: pattern,
    addProtocol: pattern,
    deleteProtocol: pattern,
    // документы/документы в проекте
    infoFolder: pattern,
    addFolder: pattern,
    addToFolder: pattern,
    deleteFolder: pattern,
    addDocument: pattern,
    deleteDocument: pattern,
    infoEditFolder: pattern,
    viewDoc: pattern,
    infoFile: pattern,
    deleteFiles: pattern,
    // график работ
    infoWorkSchedule: pattern,
    deleteWorkSchedule: pattern,
    addWorkScheldule: pattern,
    // график производства
    chooseStageOrWork: pattern,
    infoStage: pattern,
    addStage: pattern,
    deleteStage: pattern,
    infoWork: pattern,
    addWork: pattern,
    deleteWork: pattern,
    // фотоотчет
    addPhoto: pattern,
    swiperPhoto: pattern,
    // структура
    addDepartment: pattern,
    editDepartment: pattern,
    deleteDepartment: pattern,
    // сотруднкии модальная
    infoEditDepartment: pattern,
    // сотрудники
    infoEmployee: pattern,
    addEmployee: pattern,
    editEmployee: pattern,
    deleteEmployee: pattern,
    infoUser: pattern,
    // сотрудники мобильная
    infoEditEmployee: pattern,
    // группы
    addGroup: pattern,
    infoGroup: pattern,
    editGroup: pattern,
    deleteGroup: pattern,
    // группы мобильная
    infoEditGroup: pattern,
    // компании
    infoCompany: pattern,
    addCompany: pattern,
    addCompanyToProject: pattern,
    deleteCompany: pattern,
    // компании мобильная
    infoEditCompany: pattern,
    // чат
    addChat: pattern,
    addInChat: pattern,
    forward: pattern,
    deleteChat: pattern,
    clearChat: pattern,
    leaveChat: pattern,
    // сдача работ
    addDeliveryWorkMobile: pattern,
    deliveryWorkRequestsMobile: pattern,
    сheckingDeliveryWork: pattern,
    // календарь
    infoEvent: pattern,
    addEvent: pattern,
    editEvent: pattern,
    eventList: pattern,
    deleteEvent: pattern,
    //календарь мобил
    eventListMobile: pattern,
    // настройки
    addPosition: pattern,
    deletePosition: pattern,
    addCatalogWork: pattern,
    deleteCatalogWork: pattern,
    editCatalogWork: pattern,
    // справка
    addReference: pattern,
    editReference: pattern,
    deleteReference: pattern,
    // мобил
    menu: pattern,
    menuAddDocumenattion: pattern,
    menuTasks: pattern,
    menuLayer: pattern,

    // компания
    addEditResponible: pattern,
    deleteResponible: pattern,
    addProfile: pattern,
    infoEditProfiles: pattern,
  },
  reducers: {
    changeVisible(state, { payload }) {
      const { modalName, visible, data, editMode, createMode, openFromComponent } = payload;
      state[modalName] = { visible, data, editMode, createMode, openFromComponent };
    },
    getInfo(state, { payload }) {
      const { modalName, field } = payload;
      if (field) return state[modalName][field];
      return state[modalName];
    },

    getVisible(state, { payload }) {
      const { modalName } = payload;
      return state[modalName]?.visible;
    },
    changePrevModals(state, { payload }) {
      const { modalName, modalData, eventName = "remove" } = payload;
      if (eventName === "remove") {
        state.prevModals = state.prevModals.filter((modal) => modal.modalName !== modalName);
      } else if (eventName === "add") {
        state.prevModals = [...state.prevModals, { modalName: modalName, data: modalData }];
      }
    },
    clearModals(state, { payload }) {
      if (payload?.modalName) {
        state[payload.modalName] = pattern;
        return;
      }

      for (const key in state) {
        if (Array.isArray(state[key])) state[key] = [];
        else if (typeof state[key] === "object" && state[key].hasOwnProperty("visible")) {
          state[key] = pattern;
        }
      }
    },
    setData(state, { payload }) {
      const { data, modalName } = payload;
      if (!state[modalName].data) state[modalName].data = {};
      for (const key in data) {
        state[modalName].data[key] = data[key];
      }
    },
  },
});

export const {
  changeVisible,
  getInfo,
  getVisible,
  changePrevModals,
  clearModals,
  setIntermediateData,
  clearIntermediateData,
  setData,
} = modalReducer.actions;

export default modalReducer.reducer;
