import React, { useEffect, useMemo, useState } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import Button from "../Button/Button";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { useDispatch } from "react-redux";
import { asyncEditProfileCompany } from "../../store/reducers/companiesReducer";
import { useParams } from "react-router-dom";
import UserRowModal from "../UserRowModal/UserRowModal";
import "./ModalInfoEditProfiles.scss";
import ListProfiles from "../../ui/ListProfiles/ListProfiles";
import { useSearch } from "../../hooks/useSearch";

const ModalInfoEditProfiles = () => {
  const { changeModalVisible, getInfo } = useModal();

  const dispatch = useDispatch();
  const { companyId } = useParams();

  const [profiles, setProfiles] = useState([]);
  const [editedProfiles, setEditedProfiles] = useState({});
  const [loading, setLoading] = useState(null);

  const info = getInfo("infoEditProfiles", "data");

  const edit = getInfo("infoEditProfiles", "editMode");

  const { goToPage, getCurrentPage } = useSearch();

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    position: "default",
  });
  // * profiles
  const addProfileToList = () => {
    setProfiles((prev) => [
      ...prev,
      {
        index: Math.random() * 1000,
        user: null,
        position: { value: info._id, label: info.name },
      },
    ]);
  };

  const changeProfile = (index, field, data) => {
    setProfiles((prev) => {
      return prev.map((profile) => {
        if (profile.index === index) {
          return {
            ...profile,
            [field]: data,
          };
        }
        return profile;
      });
    });
  };

  const resetProfile = async (index) => {
    const originProfile = profiles.find((p) => p.index === index)?.origin;
    if (originProfile) {
      setProfiles((prev) =>
        prev.map((profile) => {
          if (profile.index === index) {
            return {
              ...profile.origin,
              origin: profile.origin,
            };
          }
          return profile;
        }),
      );
    }
  };

  const saveProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      setLoading(index);
      const res = await dispatch(
        asyncEditProfileCompany({
          companyId: companyId,
          data: {
            event: "edit",
            data: {
              user: profile.user.value,
              profile_id: profile._id,
            },
          },
        }),
      );
      if (res.error) {
        setLoading(null);
        return resetProfile(index);
      }

      setLoading(null);

      setProfiles((prev) =>
        prev.map((p) => {
          if (p.index === res.payload._id) {
            return {
              ...p,
              _id: res.payload._id,
              index: res.payload.index,
              origin: {
                _id: res.payload._id,
                index: res.payload.index,
                user: profile.user,
                position: profile.position,
              },
            };
          }
          return p;
        }),
      );

      setEditedProfiles((prev) => ({
        ...prev,
        [res.payload._id]: false,
      }));
    }
  };

  const createProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      const res = await dispatch(
        asyncEditProfileCompany({
          companyId: companyId,
          data: {
            event: "add",
            data: {
              user: profile.user.value,
              position: profile.position.value,
            },
          },
        }),
      );

      if (res.error) {
        return;
      }

      setProfiles((prev) =>
        prev.map((p) => {
          if (p.index === index) {
            return {
              ...p,
              _id: res.payload._id,
              index: res.payload.index,
              origin: {
                _id: res.payload._id,
                index: res.payload.index,
                user: profile.user,
                position: profile.position,
              },
            };
          }
          return p;
        }),
      );

      setEditedProfiles((prev) => ({
        ...prev,
        [res.payload._id]: false,
      }));

      handleChange && handleChange("employees:companies");
    }
  };

  const removeProfile = async (index) => {
    const profile = profiles.find((p) => p.index === index);
    if (profile) {
      if (profile._id) {
        const res = await dispatch(
          asyncEditProfileCompany({
            companyId: companyId,
            data: {
              event: "remove",
              profile_id: profile._id,
            },
          }),
        );

        if (res.error) return resetProfile(index);

        setProfiles((prev) => {
          return prev.filter((p) => p.index !== index);
        });
      } else {
        setProfiles((prev) => {
          return prev.filter((p) => p.index !== index);
        });
      }
    }
  };

  const convetToProfileList = (companyProfiles) => {
    const res = companyProfiles.map((profile) => {
      return {
        _id: profile._id,
        index: profile._id,
        user: {
          label: getFormatFIO(profile.user),
          value: profile.user._id,
        },
        position: {
          label: profile.position.name,
          value: profile.position._id,
        },
        departments: profile.departments.map((d) => d._id),
        groups: profile.groups.map((d) => d._id),
        origin: {
          _id: profile._id,
          index: profile._id,
          user: {
            label: getFormatFIO(profile.user),
            value: profile.user._id,
          },
          position: {
            label: profile.position.name,
            value: profile.position._id,
          },
        },
      };
    });
    return res;
  };

  useEffect(() => {
    profiles.forEach((profile) => {
      if (profile.user?.value !== profile.origin?.user?.value) {
        setEditedProfiles((prev) => ({
          ...prev,
          [profile._id]: true,
        }));
      } else {
        setEditedProfiles((prev) => ({
          ...prev,
          [profile._id]: false,
        }));
      }
    });
  }, [profiles]);
  const onInfoUser = (info) => {
    goToPage("userInfo", { userId: info.user._id });
    return changeModalVisible("infoEditProfiles", false);
  };

  const sorted = (arr) => {
    return arr.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  const createProfileList = useMemo(() => {
    const profiles = info.profiles;
    const map = {};

    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];

      if (!map[profile.user._id]) {
        map[profile.user._id] = { ...profile, positions: [profile.position] };
      } else {
        map[profile.user._id].positions.push(profile.position);
      }
    }
    const res = [];
    for (let i = 0; i < Object.values(map).length; i++) {
      const current = map[Object.keys(map)[i]];
      const positionsName = current.positions.map((position) => position.name).join(", ");
      res.push({ ...current, name: getFormatFIO(current.user) + " ( " + positionsName + ")" });
    }
    return sorted(res);
  }, [info]);

  useEffect(() => {
    if (info.profiles && Array.isArray(info.profiles)) {
      setProfiles(convetToProfileList(info.profiles));
    }
  }, [info]);

  return (
    <ModalWrapper
      title={edit ? "Редактировать должность" : "Информация о должности"}
      onClose={() => changeModalVisible("infoEditProfiles", false)}
    >
      <div className="modalInfoEditProfiles">
        {edit ? (
          <>
            <div className="modalInfoEditProfiles-list">
              {profiles.map((profile) => (
                <UserRowModal
                  info={profile}
                  changeProfile={changeProfile}
                  optionsPosition={options.position.options}
                  removeProfile={() => removeProfile(profile.index)}
                  saveProfile={() => saveProfile(profile.index)}
                  createProfile={() => createProfile(profile.index)}
                  resetProfile={() => resetProfile(profile.index)}
                  disabledPositions={true}
                  disabledUsers={loading === profile.index}
                  visibleRemove={!!editedProfiles[profile._id] && !!profile._id}
                  visibleSave={!!profile._id && !!editedProfiles[profile._id]}
                  visibleCreate={!profile._id}
                  visibleReset={!!profile._id && !!editedProfiles[profile._id]}
                  type={"company"}
                />
              ))}
            </div>
            <Button
              onClick={addProfileToList}
              title="Сотрудник"
              color="dark"
              icon={{ position: "left" }}
            />
          </>
        ) : (
          <ListProfiles
            profiles={createProfileList}
            placeholder={"Найти сотрудника по ФИО"}
            onClick={onInfoUser}
          />
        )}
      </div>
    </ModalWrapper>
  );
};

export default ModalInfoEditProfiles;
