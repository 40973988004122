import React, { useState } from "react";
import "./ProfilePassword.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { asyncResetPassword } from "../../store/reducers/userReducer";
import InputPassword from "../InputPassword/InputPassword";

const ProfilePassword = () => {
  const dispatch = useDispatch();
  const { IV, changeIV, checkValidateIV } = useChangeIV({
    passwordOld: "",
    passwordNew: "",
    passwordRepeat: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    checkValidateIV("passwordOld", { required: true });
    checkValidateIV("passwordNew", {
      minHeight: 7,
      maxHeight: 10,
      required: true,
    });
    checkValidateIV("passwordRepeat", {
      same: "passwordRepeat",
      required: true,
    });

    dispatch(
      asyncResetPassword({
        currentPass: IV.passwordOld,
        newPass: IV.passwordRepeat,
      })
    );
  };

  return (
    <div className="profilePassword">
      <h2 className="profilePassword-title">Сменить пароль:</h2>
      <form onSubmit={onSubmit} className="profilePassword-form">
        <div className="profilePassword-form-grid">
          <div className="profilePassword-form-wrapper">
            <div className="profilePassword-form-input">
              <InputPassword
                value={IV.passwordOld}
                name="passwordOld"
                title="Старый пароль:"
                placeholder="Введите пароль"
                onChange={(e) => changeIV(e)}
                validate={checkValidateIV("passwordOld")}
                autocomplete="off"
              />
            </div>
            <div className="profilePassword-form-input">
              <InputPassword
                value={IV.passwordNew}
                name="passwordNew"
                title="Новый пароль:"
                placeholder="Введите новый пароль"
                onChange={(e) => changeIV(e)}
                validate={checkValidateIV("passwordNew")}
                autocomplete="off"
              />
            </div>

            <div className="profilePassword-form-input">
              {/* <Input
            onChange={changeIV}
            value={IV.passwordRepeat}
            type={`${showPassword ? "text" : "password"}`}
            name="passwordRepeat"
            title="Повторите пароль:"
            placeholder="Повторите новый пароль"
            validate={checkValidateIV("passwordRepeat")}
          /> */}
              <InputPassword
                value={IV.passwordRepeat}
                name="passwordRepeat"
                title="Повторите пароль:"
                placeholder="Повторите новый пароль"
                onChange={(e) => changeIV(e)}
                validate={checkValidateIV("passwordRepeat")}
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div className="profilePassword-form-grid">
          <Button type="submit" color="blue" title="Изменить" />
        </div>
      </form>
    </div>
  );
};

export default ProfilePassword;
