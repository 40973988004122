import React, { useId } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import "./ModalInfoEditDepartment.scss";
import Button from "../Button/Button";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { transformRole } from "../../utils/transformRole";
import { useDispatch } from "react-redux";
import { asyncEditDepartament } from "../../store/reducers/structuresReducer";
import { transformForSelect } from "../../utils/transformForSelect";
import InputSearch from "../InputSearch/InputSearch";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { getFullPath } from "../../utils/getFullPath";
import { useModal } from "../../hooks/useModal";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import Screen from "../Screen/Screen";
import Input from "../Input/Input";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useSearch } from "../../hooks/useSearch";

const ModalInfoEditDepartment = ({ handleChange, setChapter }) => {
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const { goToPage } = useSearch();

  const info = getInfo("editDepartment", "data");
  const edit = getInfo("editDepartment", "editMode");

  const { name, director, profiles, main } = info;

  const transformProfileWithPosition = (profile) => {
    return {
      label: `${getFormatFIO(profile.user)} (${profile.position.name})`,
      value: profile._id,
    };
  };

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameDepartment: name || "",
  });

  const { options, changeOptions } = useChangeOptions({
    director: {
      options: [],
      value: director ? transformProfileWithPosition(director) : null,
    },
    profiles: {
      options: [],
      value: profiles.map(transformProfileWithPosition),
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      asyncEditDepartament({
        departmentId: info._id,
        data: {
          parent_id: info.parent_id,
          name: IV.nameDepartment,
          director: info.director
            ? options.director.value?.value
              ? options.director.value?.value
              : "remove"
            : options.director.value?.value
            ? options.director.value?.value
            : "remove",
          profiles: options.profiles.value.map((e) => e.value),
        },
      }),
    );
    handleChange && handleChange("employees:structures");
    changeModalVisible("editDepartment", false, null);
  };

  return (
    <ModalWrapper
      title={`${name} (сотрудников: ${profiles?.length})`}
      onClose={() => changeModalVisible("editDepartment")}
      btns={
        edit ? (
          <div className="modalEditDepartment__btn-wrapper">
            <Button type="submit" title="Сохранить" color="blue" form={formId} />
          </div>
        ) : null
      }
    >
      <div className="modalEditDepartment">
        <form
          onSubmit={onSubmit}
          className="modalEditDepartment__form modalEditDepartment-form"
          id={formId}
        >
          {edit && !main && (
            <Input
              onChange={changeIV}
              value={IV.nameDepartment}
              name="nameDepartment"
              title="Название отдела:*"
              placeholder="Введите название отдела не более 50 символов"
              validate={checkValidateIV("nameDepartment")}
            />
          )}
          {!!director?.user && <h4 className="modalEditDepartment-content__title">Руководитель</h4>}

          <div className="modalEditDepartment-form__wrapper">
            <div className="modalEditDepartment__info modalEditDepartment-info">
              <div className="modalEditDepartment__content modalEditDepartment-content">
                {!!director && (
                  <ul className="modalEditDepartment-content__info modalEditDepartment-content-info">
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Фамилия:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {director.user.surname}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Имя:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {director.user.name}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Отчество:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {director.user.patronymic}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Email:</span>
                      <a
                        className="modalEditDepartment-content-info__text"
                        href={`mailto:${director.user.email}`}
                      >
                        {director.user.email}
                      </a>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Телефон:</span>
                      <a
                        className="modalEditDepartment-content-info__text"
                        href={`tel: ${director.user.phone}`}
                      >
                        {director.user.phone}
                      </a>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Город:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {director?.user?.region?.name}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Компания:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {info.company.name}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Должность:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {director.position.name}
                      </span>
                    </li>
                    <li className="modalEditDepartment-content-info__item modalEditDepartment-content-info-item">
                      <span className="modalEditDepartment-content-info__title">Роль:</span>
                      <span className="modalEditDepartment-content-info__text">
                        {transformRole(director.user.role)}
                      </span>
                    </li>
                  </ul>
                )}
              </div>
              {edit && !main && (
                <div className="modalEditDepartment-content__select">
                  <InputSearch
                    forInput
                    value={options.director.value}
                    title="Руководитель отдела:"
                    handleChange={(data) => changeOptions("director", "value", data)}
                    placeholderSelect="Выберите руководителя"
                    optionsForSearch={{ company: info?.company?._id, profile: true }}
                  />
                </div>
              )}
              <div className="hr"></div>
              <div className="modalEditDepartment__content modalEditDepartment-content">
                {edit ? (
                  <InputSearch
                    multi={true}
                    forInput={true}
                    listModal={true}
                    value={options.profiles.value}
                    title={"Сотрудники:"}
                    handleChange={(data) => changeOptions("profiles", "value", data)}
                    placeholderSelect="Выберите сотрудника"
                    optionsForSearch={{
                      company: info?.company?._id,
                      profile: true,
                    }}
                  />
                ) : (
                  <ListModalProfile
                    title={"Сотрудники"}
                    profiles={profiles}
                    type="employeesDepartmentPosition"
                    onClickInfo={(info) => {
                      goToPage("userInfo", { userId: info._id });
                      changeModalVisible("editDepartment", false);
                    }}
                  />
                )}
              </div>
            </div>
            {!!director?.user && (
              <div className="modalEditDepartment__avatar modalEditDepartment-avatar">
                <span className="modalEditDepartment-content-info__title">Фотография:</span>
                <div className="modalEditDepartment-avatar__wrapper">
                  <img
                    className="modalEditDepartment-avatar__img"
                    src={getFullPath(director.avatar, "avatar")}
                    alt={getFormatFIO(director.user)}
                    crossOrigin="anonymous"
                  />
                </div>
              </div>
            )}
          </div>

          {edit && (
            <>
              {" "}
              <Screen size={"lg"}>
                {" "}
                <div className="modalEditDepartment__btn-wrapper">
                  <Button type="submit" title="Сохранить" color="blue" form={formId} />
                </div>
              </Screen>
            </>
          )}
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ModalInfoEditDepartment;
