import React from "react";
import InputSearch from "../InputSearch/InputSearch";
import Screen from "../Screen/Screen";
import Icons from "../Icons/Icons";
import "./ResponsiblesItem.scss";

const ResponsiblesItem = (props) => {
  let {
    info,
    changeResponsibles,
    removeResponsible,
    saveResponsible,
    saveResponsibles,
    resetResponsibles,
    visibleRemove,
    companyId,
    createResponsibles,
    projectsIds = [],
  } = props;

  projectsIds = projectsIds.filter((item) => !!item);

  const { index, user, project, status, isCreator } = info;

  return (
    <div className="ResponsiblesItem">
      <div className="ResponsiblesItem-item position">
        <InputSearch
          forInput={true}
          type="project"
          name="project"
          value={project}
          title="Выберите проект:*"
          handleChange={(data) => changeResponsibles(index, "project", data)}
          placeholderSelect="Выберите проект"
          isDisabled={status !== "new" && isCreator}
          optionsForSearch={{ without_ids: projectsIds }}
        />
      </div>
      <div className="ResponsiblesItem-item user">
        <InputSearch
          title="Пользователь:*"
          forInput
          placeholder="Введите пользователя"
          handleChange={(data) => changeResponsibles(index, "user", data)}
          value={user}
          optionsForSearch={{ company: companyId, without_ids: [user?.value] }}
        />
      </div>
      <div className="ResponsiblesItem-btns">
        <Screen size={"xs"}>
          <span className="ResponsiblesItem-btns-span">{"."}</span>
        </Screen>
        <div className="ResponsiblesItem-btns-list">
          {visibleRemove || (
            <div
              className="ResponsiblesItem-btn red "
              onClick={() => removeResponsible(index)}
              title={"Удалить профайл"}
            >
              <Icons
                type={"remove"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Удалить профайл" }}
              />
            </div>
          )}
          {(status === "new" || status === "edited") && !isCreator && (
            <div
              className="profileRowModal-btn blue"
              onClick={() => createResponsibles(index)}
              title={"Создать профайл"}
            >
              <Icons
                type={"editSave"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Создать профайл" }}
              />
            </div>
          )}
          {status === "edited" && isCreator && (
            <div
              className="profileRowModal-btn blue"
              onClick={() => saveResponsibles(index)}
              title={"Сохранить изменения"}
            >
              <Icons
                type={"save"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Сохранить изменения" }}
              />
            </div>
          )}
          {status === "edited" && isCreator && (
            <div
              className="profileRowModal-btn grey"
              onClick={() => resetResponsibles(index)}
              title={"Отменить изменения"}
            >
              <Icons
                type={"reset"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "Отменить изменения" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiblesItem;
