import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import "./DeliveryWork.scss";
import { asyncDeleteDeliveryWork } from "../../../store/reducers/deliveryWorkReducer";
import DeliveryWorkRequests from "./components/DeliveryWorkRequests/DeliveryWorkRequests";
import ModalEditDeliveryWork from "../../../components/ModalEditDeliveryWork/ModalEditDeliveryWork";
import ModalEditLetterDeliveryWork from "./components/ModalEditLetterDeliveryWork/ModalEditLetterDeliveryWork";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import EmptySection from "../../../components/EmptySection/EmptySection";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import { useModal } from "../../../hooks/useModal";
import ModalInfoDeliveryWork from "./components/ModalInfoDeliveryWork/ModalInfoDeliveryWork";
import ModalEditAnswerDeliveryWork from "./components/ModalEditAnswerDeliveryWork/ModalEditAnswerDeliveryWork";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

const DeliveryWork = () => {
  const dispatch = useDispatch();

  const { search } = useSearch("deliveryWork");

  const { changeModalVisible, getVisible, getInfo } = useModal();

  const { deliveryWorks, count, loading } = useSelector((state) => state.deliveryWorks);

  const [importFile, setImportFile] = useState(false);

  const getWorks = () => search();

  const deleteWork = async () => {
    const { _id: workId } = getInfo("deleteDeliveryWork", "data");
    if (workId) {
      await dispatch(asyncDeleteDeliveryWork(workId));
      getWorks();
    }
    changeModalVisible("deleteDeliveryWork", false);
  };

  return (
    <ErrorBoundary>
      <div className="deliveryWork">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="deliveryWork-content">
          <div className="deliveryWork-content__filter">
            <LeftInfo title={`Всего работ: ${count}`} />

            <div className="deliveryWork-content__filter-set">
              <AdditionalFilterPanel
                importFile={importFile}
                showImportFile={() => setImportFile((prev) => !prev)}
              />
              <Filter />
            </div>
          </div>
          <div className="deliveryWork__searchPanel deliveryWork__searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти работу по названию",
              }}
              buttons={{
                list: [
                  {
                    title: "Создать",
                    // icon: true,
                    onClick: () => {
                      changeModalVisible("addDeliveryWork", true);
                    },
                  },
                ],
              }}
            />
          </div>
          <WrapperLoader loading={loading}>
            {!!count ? (
              <DeliveryWorkRequests
                deliveryWorks={deliveryWorks}
                count={count}
                pageNumber={pageNumber}
                changePage={changePage}
              />
            ) : (
              <EmptySection
                text={"Работ для сдачи не создано"}
                // textBtn={"Добавить"}
                // onClick={() => {
                //   changeModalVisible("addDeliveryWork", true);
                // }}
              />
            )}
          </WrapperLoader>
          {/* Полная информация о работе */}
          {getVisible("infoDeliveryWork") && (
            <ModalInfoDeliveryWork handleChange={() => getWorks(pageNumber)} />
          )}

          {/* Создать работу */}
          {getVisible("addDeliveryWork") && (
            <ModalEditDeliveryWork handleChange={() => getWorks(pageNumber)} mode="add" />
          )}

          {/* Редактировать работу */}
          {getVisible("editDeliveryWork") && (
            <ModalEditDeliveryWork handleChange={() => getWorks(pageNumber)} mode="edit" />
          )}

          {/* Создать письмо инженера */}
          {getVisible("addAnswerDeliveryWork") && (
            <ModalEditAnswerDeliveryWork handleChange={() => getWorks(pageNumber)} mode="add" />
          )}

          {/* Редактировать письмо инженера */}
          {getVisible("editAnswerDeliveryWork") && (
            <ModalEditAnswerDeliveryWork handleChange={() => getWorks(pageNumber)} mode="edit" />
          )}

          {/* Создать письмо */}
          {getVisible("addLetterDeliveryWork") && (
            <ModalEditLetterDeliveryWork handleChange={() => getWorks(pageNumber)} mode="add" />
          )}

          {/* Редактировать письмо */}
          {getVisible("editLetterDeliveryWork") && (
            <ModalEditLetterDeliveryWork handleChange={() => getWorks(pageNumber)} mode="edit" />
          )}

          {/* Удалить сдачу работ */}
          {getVisible("deleteDeliveryWork") && (
            <ModalDeleteItem
              onDelete={deleteWork}
              changeModalVisible={() => changeModalVisible("deleteDeliveryWork", false)}
              title="Вы уверены, что хотите удалить эту задачу?"
              headerTitle="Удалить задачу"
            />
          )}

          {/* Добавить рисунок или поинт */}
          {getVisible("addPoint") && <ModalAddPointInLayer />}

          {/*Проверка работы */}
          {/* {getVisible("сheckingDeliveryWork") && <ModalCheckingDeliveryWork />} */}
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default DeliveryWork;
