import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MeetingMinutesMobile.scss";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalEditProtocol from "../../../../../components/ModalEditProtocol/ModalEditProtocol";
import ModalEditTask from "../../../../../components/ModalEditTask/ModalEditTask";
import ModalInfoProtocol from "../../../../../components/ModalInfoProtocol/ModalInfoProtocol";
import ModalParticipant from "../../../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../../../components/ModalProject/ModalProject";
import ModalTask from "../../../../../components/ModalTask/ModalTask";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useModal } from "../../../../../hooks/useModal";
import { useSelects } from "../../../../../hooks/useSelects";
import { asyncDeleteTaskFromProtocol } from "../../../../../store/reducers/calendarReducer";
import { asyncDeleteProtocols } from "../../../../../store/reducers/projectsReducer";
import MeetingMinutesMobileCard from "./components/MeetingMinutesMobileCard/MeetingMinutesMobileCard";
import InfoProtocolMobile from "./components/InfoProtocolMobile/InfoProtocolMobile";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import ModalSwiperPhoto from "../../../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../../../../hooks/useSearch";

const MeetingMinutesMobile = ({ setMeetingMinutesLength }) => {
  const projects = useSelector((state) => state.projects);
  const { project_meetingMinutes } = projects;

  const dispatch = useDispatch();

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { search } = useSearch("project:protocols");

  const [protocols, setProtocols] = useState([]);
  const [count, setCount] = useState(0);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(protocols);
  const [numberPage, setNumberPage] = useState(1);

  const getProtocols = () => search();

  const deleteProtocol = () => {
    if (selectItems.length) {
      dispatch(asyncDeleteProtocols(JSON.stringify(selectItems)));
      clearItems();
    } else {
      const { _id: protocolId } = getInfo("deleteProtocol", "data");
      dispatch(asyncDeleteProtocols(JSON.stringify([protocolId])));
    }

    getProtocols();
    changeModalVisible("deleteProtocol", false, null);
  };

  const deleteTask = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteTask", "data"));

    await dispatch(asyncDeleteTask({ tasks: idArray }));

    getProtocols();
    changeModalVisible("deleteTask", false, idArray);
  };

  const deleteSubTask = () => {
    const idArray = [];
    idArray.push(getInfo("deleteSubTask", "data"));
    if (!getInfo("copyTask", "data") && !getInfo("addTask", "data")) {
      dispatch(asyncDeleteSubTask({ tasks: idArray }));
      getProtocols();
    }

    changeModalVisible("deleteSubTask", false, "prev");
  };

  useEffect(() => {
    setProtocols(project_meetingMinutes?.protocols || []);
    setCount(project_meetingMinutes?.count || 0);
    setMeetingMinutesLength(project_meetingMinutes?.count || 0);
  }, [project_meetingMinutes]);

  return (
    <div className="meetingMinutesMobile">
      {!!protocols.length ? (
        <div className="meetingMinutesMobile-grid">
          {protocols.map((protocol) => (
            <MeetingMinutesMobileCard
              key={protocol._id}
              info={protocol}
              addItemSelectProtocol={addItemSelect}
              check={selectItems.includes(protocol._id)}
              checkCard={false}
            />
          ))}
        </div>
      ) : (
        <EmptySection nobtn text={"В данном проекте ещё нет протоколов"} />
      )}
      {/* Информация о протоколе */}
      {getVisible("infoProtocol") && <ModalInfoProtocol modal />}

      {/* Редактировать протокол */}
      {getVisible("editProtocol") && (
        <ModalEditProtocol handleChange={() => getProtocols()} modal />
      )}

      {/* Удалить протокол */}
      {getVisible("deleteProtocol") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteProtocol", false)}
          onDelete={deleteProtocol}
          title="Вы уверены, что хотите удалить этот протокол?"
          headerTitle="Удалить протокол"
        />
      )}

      {/* информация о сотруднике */}
      {getVisible("infoParticipant") && <ModalParticipant edit={false} />}

      {/* Информация о проекте */}
      {getVisible("infoProject") && <ModalProject />}

      {/* Информация задачи */}
      {getVisible("infoTask") && <ModalTask restrictSelection />}

      {/* Добавление задачи */}
      {getVisible("addTask") && <ModalEditTask mode={"add"} restrictSelection />}

      {/* Редактирование задачи */}
      {getVisible("editTask") && (
        <ModalEditTask saveData mode={"edit"} handleChange={() => getProtocols()} />
      )}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask saveData mode={"subTask"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && <ModalEditTask saveData mode={"editSubTask"} />}

      {/* Удалить задачи */}
      {getVisible("deleteTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту задачу?"
          headerTitle="Удалить задачу"
        />
      )}

      {/* Удалить задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteSubTask}
          title="Вы уверены, что хотите удалить эти задачи?"
          headerTitle="Удалить задачу"
        />
      )}

      {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
    </div>
  );
};

export default MeetingMinutesMobile;
