import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import "./CardRowsParticMin.scss";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";

function CardRowsParticMin(props) {
  const { addItemSelect, check, number, info } = props;
  const { _id, position, department, company, user } = info;

  return (
    <div className="cardRowsParticMin">
      <div className="cardRowsParticMin-item number">
        <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
        <span>{number}</span>
      </div>
      <div className="cardRowsParticMin-item name">
        <span title={getFormatFIO(user)}>{getFormatFIO(user)}</span>
      </div>
      <div className="cardRowsParticMin-item email">
        <a title={user?.email} href={`mailto:${user?.email}`}>
          {user?.email}
        </a>
      </div>
      <div className="cardRowsParticMin-item company">
        <div className="cardRowsParticMin-item company-container">
          <span title={company?.name}>{company?.name}</span>
        </div>
      </div>
      <div className="cardRowsParticMin-item department">
        <span title={department?.name || "-"}>{department?.name || "-"}</span>
      </div>
      <div className="cardRowsParticMin-item position">
        <div className="cardRowsParticMin-item position-container">
          <span title={position?.name || "-"}>{position?.name || "-"}</span>
        </div>
      </div>
    </div>
  );
}

export default CardRowsParticMin;
