export const getLimitDocElements = () => {
  let getLimitDocElements = 10;
  let width = document.documentElement.clientWidth;
  // if (width >= 2201) {
  //   getLimitDocElements = 18;
  // } else if (width >= 2001 && width <= 2200) {
  //   getLimitDocElements = 16;
  // } else if (width >= 1901 && width <= 2000) {
  //   getLimitDocElements = 14;
  // } else if (width >= 1701 && width <= 1900) {
  //   getLimitDocElements = 18;
  // } else if (width >= 1456 && width <= 1700) {
  //   getLimitDocElements = 18;
  // } else if (width >= 1201 && width <= 1455) {
  //   getLimitDocElements = 15;
  // } else if (width >= 1025 && width <= 1200) {
  //   getLimitDocElements = 12;
  // } else
  if (width >= 767 && width <= 1024) {
    getLimitDocElements = 18;
  } else if (width >= 601 && width <= 768) {
    getLimitDocElements = 16;
  } else if (width <= 600) {
    getLimitDocElements = 12;
  }
  // else if (width >= 768 && width <= 1024) {
  //   limitElements = 9;
  // }
  // console.log("Limit elements: " + limitElements);
  return getLimitDocElements;
};
