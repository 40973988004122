import React, { useState } from "react";
import "./ModalDelegateTasksEmployee.scss";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import LeftInfo from "../../../../../components/LeftInfo/LeftInfo";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { useSelects } from "../../../../../hooks/useSelects";
import Pagination from "../../../../../components/Pagination/Pagination";
import ModalDelegateTasksEmployeeRow from "./components/ModalDelegateTasksEmployeeRow/ModalDelegateTasksEmployeeRow";
import Button from "../../../../../components/Button/Button";
import { useEffect } from "react";
import axios from "../../../../../axios/axios";
import { getBaseUrl } from "../../../../../utils/getBaseUrl";
import { toast } from "react-toastify";

const ModalDelegateTasksEmployee = () => {
  const { changeModalVisible } = useState();

  const [employees, setEmployees] = useState([]);
  const [countEmployees, setCountEmployees] = useState(0);
  const [numberPageEmployees, setNumberPageEmployees] = useState(1);
  const [employeesCount, setEmployeesCount] = useState(0);
  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(employees);

  const changeNumberPage = (page) => {
    if (page > numberPageEmployees) {
      setEmployeesCount(page * employees.length - employees.length);
    } else if (page < numberPageEmployees) {
      setEmployeesCount(page * employees.length - employees.length);
    }
    setNumberPageEmployees(page);
  };

  const nextStep = () => {
    if (selectItems.length) {
      changeModalVisible("deleteEmployee", true);
      changeModalVisible("delegateTasksEmployee", false);
    } else {
      toast.warning("Необходимо выбрать хотя бы одного сотрудника");
    }
  };

  const getEmployees = async () => {
    const res = await axios({
      url: `${getBaseUrl()}/users/get_users/?page=${numberPageEmployees}`,
      method: "POST",
      data: {},
    });
    if (res.data) {
      setEmployees(res.data.users);
      setCountEmployees(res.data.count);
    }
  };

  useEffect(() => {
    getEmployees();
  }, [numberPageEmployees]);

  return (
    <ModalWrapper
      title={"Перед удалением необходимо делегировать задачи сотрудника"}
      onClose={() => changeModalVisible("delegateTasksEmployee", false)}
    >
      <div className="modalDelegateTasksEmployee">
        <div className="modalDelegateTasksEmployee__header modalDelegateTasksEmployee-header">
          <LeftInfo title={`Всего сотрудников: ${countEmployees}`} />
          <div className="modalDelegateTasksEmployee__searchPanel modalDelegateTasksEmployee-searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти сотрудника по ФИО",
              }}
            />
          </div>
        </div>
        <div className="modalDelegateTasksEmployee__content modalDelegateTasksEmployee-content customScroll">
          <ul className="modalDelegateTasksEmployee-content__header modalDelegateTasksEmployee-content-header">
            <li className="modalDelegateTasksEmployee-content-header__item numder-item">
              <Checkbox
                check={selectItems.length === employees.length}
                onCheck={addAllItemSelect}
              />
              <span>№:</span>
            </li>
            <li className="modalDelegateTasksEmployee-content-header__item modalDelegateTasksEmployee-content-header__item-fio">
              <span>ФИО:</span>
            </li>
            <li className="modalDelegateTasksEmployee-content-header__item modalDelegateTasksEmployee-content-header__item-email">
              <span>Email:</span>
            </li>
            <li className="modalDelegateTasksEmployee-content-header__item modalDelegateTasksEmployee-content-header__item-position">
              <span>Должность:</span>
            </li>
            <li className="modalDelegateTasksEmployee-content-header__item modalDelegateTasksEmployee-content-header__item-сompany">
              <span>Компания:</span>
            </li>
            <li className="modalDelegateTasksEmployee-content-header__item modalDelegateTasksEmployee-content-header__item-group">
              <span>Группа:</span>
            </li>
          </ul>
          <ul className="modalDelegateTasksEmployee-content__list modalDelegateTasksEmployee-content-list">
            {employees.slice(0, 10).map((employee, index) => (
              <ModalDelegateTasksEmployeeRow
                key={employee._id}
                info={employee}
                number={index + 1 + employeesCount}
                changeModalVisible={changeModalVisible}
                onCheck={() => {
                  addItemSelect(employee._id);
                }}
                check={selectItems.includes(employee._id)}
              />
            ))}
          </ul>

          <div className="modalDelegateTasksEmployee-content__footer modalDelegateTasksEmployee-content-footer">
            <Button title="Далее" color="blue" onClick={nextStep} />
            {countEmployees > 10 && (
              <Pagination onPageChange={(page) => changeNumberPage(page)} modal={true} />
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalDelegateTasksEmployee;
