import React, { useEffect, useState } from "react";
import "./ModalAddEditResponible.scss";
import { useModal } from "../../hooks/useModal";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useSelector } from "react-redux";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import InputSearch from "../InputSearch/InputSearch";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { asyncEditResponsibles } from "../../store/reducers/companiesReducer";
import { getFormatFIO } from "../../utils/getFormatFIO";

const ModalAddEditResponible = ({ getData }) => {
  const { changeModalVisible, getInfo } = useModal();
  const { company } = useSelector((state) => state.companies);
  const { companyId } = useParams();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(null);

  const info = getInfo("addEditResponible", "data");

  const edit = getInfo("addEditResponible", "editMode");

  const { edit: editPermission } = useHasPermissions("companiesCard", company);
  const { options, changeOptions } = useChangeOptions({
    project: {
      options: [],
      value: null,
    },
    user: { options: [], value: null },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await dispatch(
      asyncEditResponsibles({
        companyId: companyId,
        data: {
          event: edit ? "edit" : "add",
          data: {
            project: options.project.value.value,
            user: options.user.value.value,
          },
        },
      }),
    );
    setLoading(false);
    if (res.error) return;
    getData && getData();
    changeModalVisible("addEditResponible", false);
  };

  useEffect(() => {
    if (edit) {
      changeOptions("project", "value", { value: info._id, label: info.name });
      changeOptions("user", "value", {
        value: info.responsible.user._id,
        label: getFormatFIO(info.responsible.user, "full"),
      });
    }
  }, []);
  return (
    <ModalWrapper
      title={edit ? "Редактировать ответсвенного" : "Добавить ответственного"}
      onClose={() => changeModalVisible("addEditResponible", false)}
      noscroll={false}
      minHeight={false}
    >
      <form onSubmit={onSubmit} className="modalAddEditResponible">
        <div className="modalAddEditResponible-list">
          <InputSearch
            forInput={true}
            type="project"
            name="project"
            value={options.project.value}
            title="Выберите проект:*"
            handleChange={(value) => changeOptions("project", "value", value)}
            placeholderSelect="Выберите проект"
            //   isDisabled={status !== "new" && isCreator}
            //   optionsForSearch={{ without_ids: projectsIds }}
            isDisabled={edit}
          />
          <InputSearch
            title="Пользователь:*"
            forInput
            placeholder="Введите пользователя"
            handleChange={(value) => changeOptions("user", "value", value)}
            value={options.user.value}
            optionsForSearch={{ company: companyId }}
          />
        </div>

        <div className="modalAddEditResponible-btn-wrapper">
          <Button
            disabled={!options.user.value || !options.project.value}
            type="submit"
            title={edit ? "Сохранить" : "Создать"}
            color="blue"
            loading={loading}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddEditResponible;
