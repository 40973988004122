import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./reducers/filterReduces";
import userReducer from "./reducers/userReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import projectsReducer from "./reducers/projectsReducer";
import layersReducer from "./reducers/layersReducer";
import tasksReducer from "./reducers/tasksReducer";
import statusReducer from "./reducers/statusReducer";
import prioritiesReducer from "./reducers/prioritiesReducer";
import deliveryWorkReducer from "./reducers/deliveryWorkReducer";
import structuresReducer from "./reducers/structuresReducer";
import employeesReducer from "./reducers/employeesReducer";
import groupsReducer from "./reducers/groupsReducer";
import companiesReducer from "./reducers/companiesReducer";
import referencesReducer from "./reducers/referencesReducer";
import rolesReducer from "./reducers/rolesReducer";
import regionsReducer from "./reducers/regionsReducer";
import positionsReducer from "./reducers/positionsReducer";
import modalsReduces from "./reducers/modalsReduces";
import documentationReduser from "./reducers/documentationReduser";
import calendarReducer from "./reducers/calendarReducer";
import blockScrollReducer from "./reducers/blockScrollReducer";
import { ignoredActionsList, ignoredPathsList } from "./utils";
import chatReduser from "./reducers/chatReduser";
import settingsReducer from "./reducers/settingsReducer";
import catalogWorkReducer from "./reducers/catalogWorkReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import draftReduces from "./reducers/draftReduces";
import searchReducer from "./reducers/searchReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    filter: filterReducer,

    analytic: analyticsReducer,
    projects: projectsReducer,
    layers: layersReducer,

    tasks: tasksReducer,

    status: statusReducer,
    priorities: prioritiesReducer,

    deliveryWorks: deliveryWorkReducer,

    chats: chatReduser,

    structures: structuresReducer,
    employees: employeesReducer,
    groups: groupsReducer,
    companies: companiesReducer,

    references: referencesReducer,
    roles: rolesReducer,
    regions: regionsReducer,
    positions: positionsReducer,

    modals: modalsReduces,
    documentation: documentationReduser,

    calendar: calendarReducer,

    blockScroll: blockScrollReducer,

    settings: settingsReducer,

    catalogWork: catalogWorkReducer,

    notifications: notificationsReducer,

    draft: draftReduces,

    search: searchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ignoredActionsList,
        ignoredPaths: ignoredPathsList,
      },
    }),
});

export default store;
