import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitNotif } from "../../utils/getLimitNotif";

export const asyncGetNotifications = createAsyncThunk(
  "notifications/asyncGetNotifications",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitNotif();
      const res = await axios.post(
        `/notifications/get/${data.page}`,
        {},
        {
          params: {
            limit,
          },
        },
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncReadNotification = createAsyncThunk(
  "notifications/asyncReadNotification",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/notifications/read/${data}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncReadAllNotification = createAsyncThunk(
  "notifications/asyncReadAllNotification",
  async (_, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/notifications/readAll`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteAllNotification = createAsyncThunk(
  "notifications/asyncDeleteAllNotification",
  async (_, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/notifications/removeAll`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteNotification = createAsyncThunk(
  "notifications/asyncDeleteNotification",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/notifications/remove/${data}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const notificationsReducer = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    count: 0,
  },
  reducers: {},
  extraReducers: extraReducersWrapper({
    [asyncGetNotifications.fulfilled]: (state, { payload }) => {
      state.notifications = payload.notifications;
      state.count = payload.count;
    },

    [asyncGetNotifications.rejected]: "Не удалось получить уведомления.",

    [asyncReadNotification.rejected]: "Не удалось отметить уведомление, как прочитанное.",

    [asyncReadAllNotification.fulfilled]: "Уведомления успешно отмечены, как прочитанные.",

    [asyncReadAllNotification.rejected]: "Не удалось отметить уведомления, как прочитанные.",

    [asyncDeleteAllNotification.fulfilled]: "Уведомления, которые не прочитаны успешно удалены.",

    [asyncDeleteAllNotification.rejected]: "Не удалось удалить уведомления.",

    [asyncDeleteNotification.rejected]: "Не удалось удалить уведомление.",
  }),
});

export const {} = notificationsReducer.actions;
export default notificationsReducer.reducer;
