import React from "react";
import "./ReferenceList.scss";
import ReferenceItem from "../ReferenceItem/ReferenceItem";
import Pagination from "../Pagination/Pagination";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const ReferenceList = ({ references, count }) => {
  const { edit } = useHasPermissions("reference");
  return (
    <div className="referenceList">
      {references.map((reference, index) => {
        return <ReferenceItem key={reference._id} info={reference} index={index} edit={edit} />;
      })}
      <Pagination count={count} />
    </div>
  );
};

export default ReferenceList;
