import React from "react";
import "./InputRadio.scss";

function InputRadio(props) {
  const { check, title, onCheck, titlePotions } = props;

  return (
    <div
      onClick={onCheck}
      style={{ flexDirection: titlePotions === "top" ? "column" : "row" }}
      className={`inputRadio ${check ? "check" : ""}`}
    >
      <div className="inputRadio-radio"></div>
      {!!title && <span className="inputRadio-title">{title}</span>}
    </div>
  );
}

export default InputRadio;
