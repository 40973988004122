import React, { useState } from "react";
import "./Map.scss";
import worldMap from "../../../../assets/images/pages/Analytics/worldMap.svg";
import AddressItem from "./components/AddressItem/AddressItem";
import Checkpoint from "./components/Checkpoint/Checkpoint";
import { useSelector } from "react-redux";

function Map() {
  const [currentAddress, setCurrentAddress] = useState(null);
  const projects = useSelector((state) => state.analytic.ProjectsOnMap);

  const changeCurrentAddress = (address) => {
    setCurrentAddress(address);
  };
  return (
    <div className="map">
      <span className="map-title">Наши проекты на карте:</span>
      <div className="map-content">
        <div className="map-content-worldMap">
          <img src={worldMap} alt="worldMap" />
          {projects.map((project) => (
            <Checkpoint
              key={project.id}
              active={currentAddress?.id === project.id}
              onActive={changeCurrentAddress}
              info={project}
            />
          ))}
        </div>
        <div className="map-content-projectslist">
          <span className="map-content-projectslist-title">
            Список проектов:
          </span>
          <div className="map-content-projectslist-content customScroll">
            {projects.map((project, i) => (
              <div key={project.id}>
                <AddressItem
                  onActive={changeCurrentAddress}
                  number={i + 1}
                  active={currentAddress?.id === project.id}
                  info={project}
                />
                <div className="map-content-projectslist-content-hr">
                  <hr />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
