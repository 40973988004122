import React, { useState, useEffect } from "react";
import "./SettingsGeneral.scss";
import SettingsGeneralElem from "./componetns/SettingsGeneralElem/SettingsGeneralElem";
import { setIconsTitle } from "../../store/reducers/settingsReducer";
import { useSelector, useDispatch } from "react-redux";
import ProfilePassword from "../ProfilePassword/ProfilePassword";
import ProfileNotification from "../ProfileNotification/ProfileNotification";
import { useSearch } from "../../hooks/useSearch";
const SettingsGeneral = () => {
  const dispatch = useDispatch();
  const { iconsTitle } = useSelector((state) => state.settings);

  const {} = useSearch("settings:general");

  const changeVisibleIconsTitle = async (check) => {
    if (check) {
      await dispatch(
        setIconsTitle({
          iconsTitle: false,
        })
      );
    } else {
      await dispatch(
        setIconsTitle({
          iconsTitle: true,
        })
      );
    }
  };

  return (
    <div className="settingsGeneral">
      <div className="settingsGeneral-section">
        <h2 className="settingsGeneral-title">Подсказки:</h2>
        <div className="settingsGeneral-section-grid">
          <SettingsGeneralElem
            title={"Подсказки над иконками"}
            text={"Отображения подсказок над иконками"}
            onCheck={() => changeVisibleIconsTitle(iconsTitle)}
            checked={iconsTitle}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="settingsGeneral-section">
        <ProfileNotification />
      </div>
      <div className="hr"></div>
      <div className="settingsGeneral-section">
        {/* <EmptySection text={"Данная секция находится в разработке"} nobtn /> */}
        <ProfilePassword />
      </div>
    </div>
  );
};

export default SettingsGeneral;
