export const getFormatFIO = (fullName, format) => {
  if (!fullName) return "Пользователь не найден";

  if (typeof fullName === "object") {
    const { name, surname, patronymic } = fullName;
    switch (format) {
      case "FI":
        return `${surname} ${name}`;
      case "F":
        return surname;
      case "I":
        return name;
      case "O":
        return patronymic || "";
      case "FO":
        return `${patronymic || ""} ${surname}`;
      case "full":
        return `${surname} ${name} ${patronymic || ""}`;
      default: {
        return `${surname} ${name[0]}. ${
          patronymic ? `${patronymic[0]}.` : ""
        }`;
      }
    }
  } else {
    const arr = fullName.split(" ");
    switch (format) {
      case "FI":
        return `${arr[0]} ${arr[1]}`;
      case "F":
        return arr[0];
      case "I":
        return arr[1];
      case "O":
        return arr[2];
      case "FO":
        return `${arr[0]} ${arr[2]}`;
      default: {
        const [surname, name, lastname] = arr;
        return `${surname} ${name[0]}${lastname ? "." + lastname : ""}`;
      }
    }
  }
};
