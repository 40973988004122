import React from "react";
import Loader from "../Loader/Loader";
import SkeletonGrid from "./components/SkeletonGrid/SkeletonGrid";
import SkeletonRow from "./components/SkeletonRow/SkeletonRow";
import Screen from "../../../Screen/Screen";
import SkeletonStucuresRow from "./components/SkeletonStucuresRow/SkeletonStucuresRow";
import SkeletonGridProject from "./components/SkeletonGridProject/SkeletonGridProject";
import SkeletonPermissions from "./components/SkeletonPermissions/SkeletonPermissions";
import "./SectionLoader.scss";

const SectionLoader = ({ chapter, viewCards, countRow }) => {
  return (
    <div
      className={`sectionLoader ${
        chapter === "permissionsFolder" ||
        chapter === "permissionsFolderEdit" ||
        chapter === "permissionsFile" ||
        chapter === "permissionsFileEdit"
          ? "permission"
          : ""
      }`}
    >
      {!chapter && <Loader />}
      {(chapter === "employees" ||
        chapter === "companies" ||
        chapter === "participants" ||
        chapter === "companies-project") && (
        <>
          <Screen size={"lg"}>
            {viewCards === "rows" ? (
              <SkeletonRow chapter={chapter} />
            ) : (
              <SkeletonGrid chapter={chapter} />
            )}
          </Screen>
          <Screen size={"lg"} down>
            <SkeletonGrid chapter={chapter} />
          </Screen>
        </>
      )}
      {chapter === "projects" && (
        <>
          <Screen size={"lg"}>
            {viewCards === "rows" ? (
              <SkeletonRow chapter={chapter} />
            ) : (
              <SkeletonGridProject chapter={chapter} />
            )}
          </Screen>
          <Screen size={"lg"} down>
            <SkeletonGridProject chapter={chapter} />
          </Screen>
        </>
      )}
      {chapter === "groups" && (
        <>
          <Screen size={"lg"}>
            <SkeletonRow chapter={chapter} />
          </Screen>
          <Screen size={"lg"} down>
            <SkeletonGrid chapter={chapter} />
          </Screen>
        </>
      )}
      {chapter === "structures" &&
        (viewCards === "rows" ? <SkeletonStucuresRow /> : <Loader />)}
      {chapter === "workSchedule" && <SkeletonRow chapter={chapter} />}
      {chapter === "tasks" &&
        (viewCards === "rows" ? (
          <SkeletonRow chapter={chapter} countRow={countRow} />
        ) : (
          <Loader />
        ))}
      {(chapter === "permissionsFolder" ||
        chapter === "permissionsFolderEdit" ||
        chapter === "permissionsFile" ||
        chapter === "permissionsFileEdit") && (
        <SkeletonPermissions chapter={chapter} />
      )}
    </div>
  );
};

export default SectionLoader;
