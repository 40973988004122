import React, { useState, useEffect } from "react";
import "./Employees.scss";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import Filter from "../../../components/Filter/Filter";
import EmployeesList from "./components/EmployeesList/EmployeesList";
import Structures from "../../../components/Structures/Structures";
import Groups from "./components/Groups/Groups";
import Companies from "./components/Companies/Companies";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import ModalAddEmployee from "../../../components/ModalAddEmployee/ModalAddEmployee";
import ModalEditEmployee from "../../../components/ModalEditEmployee/ModalEditEmployee";
import ModalDelegateTasksEmployee from "./components/ModalDelegateTasksEmployee/ModalDelegateTasksEmployee";
import ModalAddGroup from "../../../components/ModalAddGroup/ModalAddGroup";
import ModalInfoGroup from "../../../components/ModalInfoGroup/ModalInfoGroup";
import ModalEditGroup from "../../../components/ModalEditGroup/ModalEditGroup";
import ModalAddCompany from "../../../components/ModalAddCompany/ModalAddCompany";
import ModalInfoEditCompany from "../../../components/ModalInfoEditCompany/ModalInfoEditCompany";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalAddDepartment from "./components/ModalAddDepartment/ModalAddDepartment";
import ModalInfoEditDepartment from "../../../components/ModalInfoEditDepartment/ModalInfoEditDepartment";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../components/ModalProject/ModalProject";
import { asyncRemoveCompany } from "../../../store/reducers/companiesReducer";
import { useDispatch } from "react-redux";
import { asyncRemoveUser } from "../../../store/reducers/userReducer";
import { asyncRemoveGroup } from "../../../store/reducers/groupsReducer";
import { asyncRemoveDepartament } from "../../../store/reducers/structuresReducer";
import { useModal } from "../../../hooks/useModal";
import InputSearch from "../../../components/InputSearch/InputSearch";
import { useSearch } from "../../../hooks/useSearch";
import EmployeeInfo from "./EmployeeInfo/EmployeeInfo";
import { ErrorBoundary } from "../../../ui";

const Employees = () => {
  const dispatch = useDispatch();

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { params, search, setQueryParam, queries } = useSearch();

  const [companyForStructures, setCompanyForStructures] = useState(null);

  const [chapter, setChapter] = useState();

  const [leftInfoTitle, setLeftInfoTitle] = useState("Всего сотрудников: 15");
  const [importFile, setImportFile] = useState(false);
  const [viewCards, setViewCards] = useState("rows");

  const [departmentLength, setDepartmentLength] = useState(0);
  const [employeeLength, setEmployeeLength] = useState(0);
  const [groupLength, setGroupLength] = useState(0);
  const [companyLength, setCompanyLength] = useState(0);

  const changeViewCard = (value) => {
    setViewCards(value);
  };

  const changeChapter = (value) => setChapter(value);

  // TODO при удаление компании/сотрудника/группы проверять,
  // TODO что если после удаления на странице пусто - делать страницу предыдущей

  const handleChange = (type) => {
    search({ nativePage: type });
  };

  const removeCompany = async () => {
    const { _id: company_id } = getInfo("deleteCompany", "data");
    const res = await dispatch(asyncRemoveCompany({ company_id }));
    if (res.error) return changeModalVisible("deleteCompany", false, null);
    handleChange("employees:companies");
    changeModalVisible("deleteCompany", false, null);
  };

  const removeGroup = async () => {
    const { _id: groupId } = getInfo("deleteGroup", "data");
    const res = await dispatch(asyncRemoveGroup(groupId));
    if (res.error) return changeModalVisible("deleteGroup", false, null);
    handleChange("employees:groups");
    changeModalVisible("deleteGroup", false, null);
  };

  const removeUser = async () => {
    const { _id: user_id } = getInfo("deleteEmployee", "data");
    const res = await dispatch(asyncRemoveUser({ user_id }));
    if (res.error) return changeModalVisible("deleteEmployee", false, null);
    handleChange("employees:users");
    changeModalVisible("deleteEmployee", false, null);
  };

  const removeDepartment = async () => {
    const { _id: departament_id } = getInfo("deleteDepartment", "data");
    const res = await dispatch(asyncRemoveDepartament(departament_id));
    if (res.error) return changeModalVisible("deleteDepartment", false, null);
    handleChange("employees:structures");
    changeModalVisible("deleteDepartment", false, null);
  };

  useEffect(() => {
    let text = "Всего";
    let count = 0;
    switch (chapter) {
      case "structures":
        text = "Всего отделов";
        count = companyForStructures ? departmentLength : 0;
        break;
      case "users":
        text = "Всего сотрудников";
        count = employeeLength;
        break;
      case "groups":
        text = "Всего групп";
        count = groupLength;
        break;
      case "companies":
        text = "Всего компаний";
        count = companyLength;
        break;
    }

    setLeftInfoTitle(`${text}: ${count || 0}`);
  }, [departmentLength, employeeLength, companyLength, groupLength, chapter]);

  useEffect(() => {
    if (!params.chapter) return changeChapter("users");

    changeChapter(params.chapter);
  }, [params]);

  useEffect(() => {
    if (!queries.company) {
      setDepartmentLength(0);
      return setCompanyForStructures(null);
    }

    setCompanyForStructures(queries.company);
  }, [queries.company]);

  return (
    <ErrorBoundary>
      <div className="employees">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="employees-content">
          <div className="employees-content__filter">
            <div className="employees-leftInfo">
              <LeftInfo title={leftInfoTitle} />
              {chapter === "structures" && (
                <div className="employees-leftInfo-inputSearch">
                  <InputSearch
                    placeholder="Найти компанию"
                    type="company"
                    value={companyForStructures}
                    handleChange={(data) => setQueryParam({ company: data })}
                  />
                </div>
              )}
            </div>

            <div className="deliveryWork-content__filter-set">
              <AdditionalFilterPanel
                active={chapter}
                currentPage="employees"
                importFile={importFile}
                showImportFile={() => setImportFile((prev) => !prev)}
                viewCards={viewCards}
                onViewClick={changeViewCard}
              />
              <Filter currentPage={`employees:${chapter}`} disabled={chapter === "structures"} />
            </div>
          </div>
          <div className="employees-content__section">
            {chapter === "structures" && (
              <Structures
                setCompanyForStructures={setCompanyForStructures}
                setDepartmentLength={setDepartmentLength}
                viewCards={viewCards}
              />
            )}
            {chapter === "users" && (
              <EmployeesList viewCards={viewCards} setEmployeeLength={setEmployeeLength} />
            )}
            {chapter === "groups" && (
              <Groups viewCards={viewCards} setGroupLength={setGroupLength} />
            )}
            {chapter === "companies" && (
              <Companies viewCards={viewCards} setCompanyLength={setCompanyLength} />
            )}
          </div>
        </div>

        {/* Информация о проекте */}
        {getVisible("project") && <ModalProject />}

        {/* Информация о сотруднике */}
        {getVisible("participant") && <ModalParticipant />}

        {/* Добавление сотрудника */}
        {getVisible("addEmployee") && <ModalAddEmployee handleChange={handleChange} />}

        {/* Редактирование сотрудника */}
        {getVisible("editEmployee") && <ModalEditEmployee handleChange={handleChange} />}

        {/* Делегировать задачи сотруднкиа перед удалением */}
        {getVisible("delegateTasksEmployee") && (
          <ModalDelegateTasksEmployee employees={getInfo("delegateTasksEmployee", "data")} />
        )}

        {/* Удалить сотрудника */}
        {getVisible("deleteEmployee") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteEmployee", false)}
            title="Вы уверены, что хотите удалить сотрудника?"
            headerTitle="Удалить сотрудника"
            onDelete={removeUser}
          />
        )}

        {/* Добавление группы */}
        {getVisible("addGroup") && <ModalAddGroup handleChange={handleChange} />}

        {/* Информация о группе */}
        {getVisible("infoGroup") && <ModalInfoGroup setChapter={changeChapter} />}

        {/* Редактировать о группу */}
        {getVisible("editGroup") && <ModalEditGroup handleChange={handleChange} />}

        {/* Удалить группу */}
        {getVisible("deleteGroup") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteGroup", false)}
            title="Вы уверены, что хотите удалить группу?"
            headerTitle="Удалить группу"
            onDelete={removeGroup}
          />
        )}

        {/* Добавление компании */}
        {getVisible("addCompany") && <ModalAddCompany handleChange={handleChange} />}

        {/* Информация о компании / Редактирование компании */}
        {getVisible("infoCompany") && <ModalInfoEditCompany handleChange={handleChange} />}

        {/* Удалить компанию */}
        {getVisible("deleteCompany") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteCompany", false)}
            title="Вы уверены, что хотите удалить компанию?"
            headerTitle="Удалить компанию"
            onDelete={removeCompany}
          />
        )}

        {/* Добавить отдел */}
        {getVisible("addDepartment") && <ModalAddDepartment handleChange={handleChange} />}

        {/* Редактировать отдел */}
        {getVisible("editDepartment") && (
          <ModalInfoEditDepartment handleChange={handleChange} setChapter={changeChapter} />
        )}

        {/* Удалить отдел */}
        {getVisible("deleteDepartment") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteDepartment", false)}
            title="Вы уверены, что хотите удалить этот отдел?"
            headerTitle="Удалить отдел"
            onDelete={removeDepartment}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};
export default Employees;
