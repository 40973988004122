//getFontSizeBase

export const getFontSizeBase = () => {
  let fontSize = 10;
  let width = document.documentElement.clientWidth;
  if (width >= 2001) {
    fontSize = 14;
  } else if (width >= 1700 && width <= 2000) {
    fontSize = 12;
  } else if (width >= 1024 && width <= 1699) {
    fontSize = 10;
  } else if (width <= 1023) {
    fontSize = 14;
  }
  // else if (width >= 768 && width <= 1024) {
  //   limitElements = 9;
  // }
  // console.log("Limit elements: " + limitElements);
  return fontSize;
};
