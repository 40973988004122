import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import EmptySection from "../../../components/EmptySection/EmptySection";
import Filter from "../../../components/Filter/Filter";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalEditTask from "../../../components/ModalEditTask/ModalEditTask";
import ModalDelegationTask from "../../../components/ModalDelegationTask/ModalDelegationTask";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalPutAside from "../../../components/ModalPutAside/ModalPutAside";
import ModalTask from "../../../components/ModalTask/ModalTask";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import TasksListRow from "../../../components/TaskListRow/TaskListRow";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import { useModal } from "../../../hooks/useModal";
import {
  asyncDeleteSubTask,
  asyncDeleteTask,
  asyncEditStatusTask,
  asyncGetTasks,
  asyncGetTasksForKanban,
  clearTasksKanban,
} from "../../../store/reducers/tasksReducer";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../components/ModalProject/ModalProject";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalAddDellEmployees from "../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import { useSearch } from "../../../hooks/useSearch";
import TasksColumn from "../../../components/TasksDragDrop/components/TasksColumn/TasksColumn";
import { DragDropContext } from "react-beautiful-dnd";
import { ErrorBoundary } from "../../../ui";

function TasksKanban() {
  const dispatch = useDispatch();

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { getParamsForRequest, queries, goToPage } = useSearch("tasksKanban");

  const [totalCountTask, setTotalCountTask] = useState(0);

  const [chapter, setChapter] = useState("all");

  const [importFile, setImportFile] = useState(false);

  const { tasksKanban } = useSelector((state) => state.tasks);

  const [columns, setColumns] = useState({
    assigned: { name: "Назначено", type: "assigned", items: [], count: 0, load: false, page: 1 },
    rejected: { name: "Отклонено", type: "rejected", items: [], count: 0, load: false, page: 1 },
    atWork: { name: "В работе", type: "atWork", items: [], count: 0, load: false, page: 1 },
    overdue: { name: "Просрочено", type: "overdue", items: [], count: 0, load: false, page: 1 },
    onVerification: {
      name: "На проверке",
      type: "onVerification",
      items: [],
      count: 0,
      load: false,
      page: 1,
    },
    completed: { name: "Выполнено", type: "completed", items: [], count: 0, load: false, page: 1 },
  });

  const deleteTask = async () => {
    const idArray = [];
    if (Array.isArray(getInfo("deleteTask", "data"))) {
      idArray.push(...getInfo("deleteTask", "data"));
    } else {
      idArray.push(getInfo("deleteTask", "data"));
    }
    await dispatch(asyncDeleteTask({ tasks: idArray }));

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }
    changeModalVisible("deleteTask", false);
    onHandleEventTask("deleteTask", idArray);
  };

  const deleteSubTask = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteSubTask", "data"));
    if (!getInfo("copyTask", "data") && !getInfo("addTask", "data")) {
      await dispatch(asyncDeleteSubTask({ tasks: idArray }));
    }

    changeModalVisible("deleteSubTask", false, "prev");
  };

  const changeViewCard = (value) => {
    if (value === "rows") return goToPage("tasks");
    goToPage("tasksKanban");
  };

  const onHandleEventTask = async (event, data) => {
    if (event === "addTask") {
      const taskStatus = data.status;
      await getTasksKanban(taskStatus, 1, false);
    } else if (event === "deleteTask") {
      const tasks = data;
      const tasksStatuses = tasks.map((t) => t.status);

      await Promise.all(
        tasksStatuses.map(async (status) => {
          await getTasksKanban(status, 1, false);
        }),
      );
    }
  };

  const getTasksKanban = async (status, page = 1, concat = true) => {
    const { paramsForRequest } = getParamsForRequest();

    if (status) {
      if (!concat) changePage(status, 1);

      const res = await dispatch(
        asyncGetTasksForKanban({ status, page, concat, params: paramsForRequest }),
      );

      if (res.error) return;

      // const { tasks, count } = res.payload;

      // if (!concat) {
      //   const load = !(tasks.length >= count);
      //   changeLoad(load);
      // }
    } else {
      await Promise.all(
        ["assigned", "rejected", "atWork", "overdue", "onVerification", "completed"].map(
          async (s) => {
            await dispatch(
              asyncGetTasksForKanban({ status: s, page: 1, params: paramsForRequest }),
            );
          },
        ),
      );
    }
  };

  const changePage = (status, page) => {
    setColumns((prev) => ({
      ...prev,
      [status]: {
        ...prev[status],
        page,
      },
    }));
  };

  const changeTasks = (status, tasks) => {
    setColumns((prev) => ({
      ...prev,
      [status]: {
        ...prev[status],
        items: tasks,
      },
    }));
  };

  const loadTasks = (status) => {
    if (columns[status].load) {
      const page = columns[status].page + 1;
      getTasksKanban(status, page);
      changePage(status, page);
    }
  };

  const setColumnsInfo = (dataTasks) => {
    setColumns((prev) => ({
      ...prev,
      [dataTasks.status]: {
        ...prev[dataTasks.status],
        items: dataTasks.data.tasks,
        count: dataTasks.data.count,
        load: dataTasks.load,
      },
    }));
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]; // клонка из которой забирается
      const destColumn = columns[destination.droppableId]; // колонка в которую приходит
      const sourceItems = [...sourceColumn.items]; // все таски
      const destItems = [...destColumn.items]; // выбранная таска
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed); //клонирует

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
          count: columns[source.droppableId].count - 1,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
          count: columns[destination.droppableId].count + 1,
        },
      });

      await dispatch(
        asyncEditStatusTask({
          status: destination.droppableId,
          tasks: [draggableId],
        }),
      );

      await getTasksKanban(source.droppableId, 1, false);
      await getTasksKanban(destination.droppableId, 1, false);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const sumCount = (columns) => {
    let res = 0;
    for (const key in columns) {
      res += columns[key].count;
    }
    return res;
  };

  useEffect(() => {
    getTasksKanban();
  }, [queries]);

  useEffect(() => {
    for (const key in tasksKanban) {
      setColumnsInfo({
        status: key,
        data: tasksKanban[key],
        load: !(tasksKanban[key].tasks.length >= tasksKanban[key].count),
      });
    }
  }, [tasksKanban]);

  useEffect(() => {
    if (!queries.tab) return setChapter("all");

    setChapter(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    setTotalCountTask(sumCount(columns));
  }, [columns]);

  return (
    <ErrorBoundary>
      <div className="tasks">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="task__content tasks-content">
          <div className="tasks-content__filter">
            <LeftInfo title={`Всего задач: ${totalCountTask || 0}`} />
            <div className="tasks-content__filter-set">
              <AdditionalFilterPanel
                currentPage="tasks"
                active={chapter}
                onProjectsClick={""}
                importFile={importFile}
                showImportFile={() => setImportFile((prev) => !prev)}
                onChartsClick={""}
                viewCards={"columns"}
                onViewClick={changeViewCard}
              />
              <Filter />
            </div>
          </div>
          <div className="tasks-content__searchPanel tasks__searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти задачу по названию или номеру",
              }}
              buttons={{
                list: [
                  {
                    title: "Создать",
                    onClick: () => changeModalVisible("addTask", true, null, false, true),
                    // icon: true,
                  },
                ],
              }}
            />
          </div>
          <WrapperLoader loading={false} chapter={"tasks"} viewCards={"columns"}>
            {/* <TasksDragDrop onDrag={search} /> */}

            {/* ------ */}

            <div className="tasksDragDrop">
              <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                <ul className={`tasksDragDrop-column`}>
                  {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                      columnId !== "overdue" && (
                        <TasksColumn
                          title={column.name}
                          type={column.type}
                          key={columnId}
                          columnId={columnId}
                          loadTasks={loadTasks}
                          tasks={columns[columnId].items}
                          count={columns[columnId].count}
                          load={columns[columnId].load}
                        />
                      )
                    );
                  })}
                </ul>
              </DragDropContext>
            </div>

            {/* ------ */}
          </WrapperLoader>

          {/* Информация задачи */}
          {getVisible("infoTask") && <ModalTask />}

          {/* Редактирование задачи */}
          {getVisible("editTask") && <ModalEditTask mode={"edit"} />}

          {/* Добавление задачи */}
          {getVisible("addTask") && (
            <ModalEditTask
              mode={"add"}
              handleChange={(mode, data) => {
                onHandleEventTask("addTask", data);
              }}
            />
          )}

          {/* Редактирование подзадачи при добавлении */}
          {getVisible("editSubTask") && <ModalEditTask mode={"editSubTask"} />}

          {/* Добавление подзадачи */}
          {getVisible("addSubTask") && <ModalEditTask mode={"subTask"} />}

          {/* Копирование задачи */}
          {getVisible("copyTask") && <ModalEditTask mode={"copy"} />}

          {/* Делегирование задачи */}
          {getVisible("delegationTask") && <ModalEditTask mode={"delegation"} />}

          {/* Добавить рисунок или поинт */}
          {getVisible("addPoint") && <ModalAddPointInLayer />}

          {/* Просмотр рисунока */}
          {getVisible("showPoint") && <ModalAddPointInLayer show />}

          {/* Отложить задачу */}
          {getVisible("putAsideTask") && <ModalPutAside />}

          {/* Удалить задачу */}
          {getVisible("deleteTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteTask", false)}
              onDelete={deleteTask}
              title="Вы уверены, что хотите удалить эту задачу?"
              headerTitle="Удалить задачу"
            />
          )}

          {/* Удалить подзадачу задачи */}
          {getVisible("deleteSubTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
              onDelete={deleteSubTask}
              title="Вы уверены, что хотите удалить эту подзадачу?"
              headerTitle="Удалить подзадачу"
            />
          )}

          {/* информация о сотруднике */}
          {getVisible("infoParticipant") && (
            <ModalParticipant
              info={getInfo("infoParticipant", "data")}
              changeModalVisible={changeModalVisible}
              edit={false}
            />
          )}

          {/* Информация о проекте */}
          {getVisible("infoProject") && (
            <ModalProject
              info={getInfo("infoProject", "data")}
              changeModalVisible={changeModalVisible}
            />
          )}

          {getVisible("infoUser") && <ModalInfoUser />}

          {getVisible("addEmployeesTask") && <ModalAddDellEmployees add={true} />}
          {getVisible("delEmployeesTask") && <ModalAddDellEmployees />}
          {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
        </div>
      </div>
    </ErrorBoundary>
  );
}
export default TasksKanban;
