/* eslint-disable no-undef */

export const ignoredActionsList = [
  "filter/set_options",
  "layers/asyncGetLayer/fulfilled",
  "modals/setData",
  "modals/changeVisible",
  "calendar/asyncGetEvent/fulfilled",
];

export const ignoredPathsList = [
  "payload.modalData.data.start",
  "filter.options.period.0",
  "filter.options.period.1",
  "modals.addTask.data.end",
  "modals.editTask.data.start",
  "modals.editTask.data.end",
  "payload.modalData.data.start",
  "modals.addSubTask.data.start",
  "modals.addSubTask.data",
  "modals.addSubTask.end",
  "modals.addSubTask.data.end",
  "modals.copyTask.data.start",
  "modals.copyTask.data.end",
  "modals.prevModals.0.data.data.start",
  "modals.prevModals.0.data.data.end",
  "modals.addTask.data.photos.0",
  "modals.addTask.data.start",
  "modals.addEvent.data.eventStart",
  "modals.addEvent.data.eventEnd",
  "modals.prevModals.0.data.data.eventStart",
  "modals.prevModals.0.data.data.eventEnd",
  "modals.prevModals.0.data.data.date",
  "modals.eventList.data.date",
  "modals.addEvent.data.start",
  "modals.addEvent.data.end",
  "modals.addEvent.data.documents.0",
  "payload.config.adapter",
  "modals.prevModals.0.data.data.tasks.0.photos.0",
  "modals.prevModals.1.data.data.date",
  "modals.editEvent.data.start",
  "modals.editEvent.data.end",
  "payload.modalData.data.date",
  "modals.addDeliveryWork.data.call_date",
  "modals.prevModals.0.data.data.call_date",
  "modals.editDeliveryWork.data.call_date",
  "modals.forward.data.callback",
  "modals.editSubTask.data.start",
  "modals.editSubTask.data.end",
  "payload.modalData.data.start",
  "payload.modalData.data.end",
  "modals.editEvent.data.config.adapter",
  "modals.prevModals.1.data.data.start",
  "modals.editTask.data.regularity.start_repeating",
  "modals.editSubTask.data.regularity.start_repeating",
  "modals.addTask.data.regularity.start_repeating",
  "modals.editTask.data.regularity.time_created_task",
  "modals.editTask.data.regularity.date_of_create_task",
  "modals.addTask.data.regularity.time_created_task"
];
