import React, { useEffect, useState } from "react";
import "./EmployeeBaseInfo.scss";
import { getBaseUrl } from "../../../../../../utils/getBaseUrl";
import noImg from "../../../../../../assets/images/pages/noImages.jpg";
import { transformRole } from "../../../../../../utils/transformRole";
import Icons from "../../../../../../components/Icons/Icons";
import { useModal } from "../../../../../../hooks/useModal";
import EmployeeBaseInfoProfile from "../../../../../../components/EmployeeBaseInfoProfile/EmployeeBaseInfoProfile";
import { getFormatDate } from "../../../../../../utils/getFormatDate";
import { useSearch } from "../../../../../../hooks/useSearch";
import ModalEditEmployee from "../../../../../../components/ModalEditEmployee/ModalEditEmployee";
import { useHasPermissions } from "../../../../../../hooks/useHasPermissions";
import ModalDeleteItem from "../../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalTask from "../../../../../../components/ModalTask/ModalTask";
import ModalEditTask from "../../../../../../components/ModalEditTask/ModalEditTask";
import ModalAddPointInLayer from "../../../../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalAddDellEmployees from "../../../../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import ModalPutAside from "../../../../../../components/ModalPutAside/ModalPutAside";

const EmployeeBaseInfo = ({ info, handleChange }) => {
  const { changeModalVisible, getVisible } = useModal();
  const { search } = useSearch("userInfo:tasks");

  const {
    name,
    surname,
    patronymic,
    email,
    phone,
    region,
    position,
    company,
    role,
    groups,
    avatar,
    departments,
    date_of_birth,
    profiles,
  } = info;

  const baseURL = getBaseUrl();
  const { edit } = useHasPermissions(`userInfo:info`, info);
  return (
    <div className="employeeBaseInfo">
      {getVisible() && <div className="bgc-shadow"></div>}
      <div className="employeeBaseInfo-header">
        <h4 className="employeeBaseInfo-title">Основная информация</h4>
        {edit && (
          <div>
            <Icons
              type={"edit"}
              size={"md"}
              cursor
              title={{ visible: true, text: "" }}
              onClick={() => changeModalVisible("editEmployee", true, info)}
            />
          </div>
        )}
      </div>

      <div className="employeeBaseInfo-wrapper">
        <div className="employeeBaseInfo-content">
          <ul className="employeeBaseInfo-list">
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Фамилия:</span>
                <span className="employeeBaseInfo-item-text" title={surname}>
                  {surname}
                </span>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Имя:</span>
                <span className="employeeBaseInfo-item-text" title={name}>
                  {name}
                </span>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Отчество:</span>
                <span className="employeeBaseInfo-item-text" title={patronymic}>
                  {patronymic}
                </span>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Дата рождения:</span>
                <span className="employeeBaseInfo-item-text">
                  {getFormatDate(date_of_birth) || ""}
                </span>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Email:</span>
                <a href={`mailto:${email}`} className="employeeBaseInfo-item-text" title={email}>
                  {email}
                </a>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Телефон:</span>
                <a title={phone} href={`tel: ${phone}`} className="employeeBaseInfo-item-text">
                  {phone}
                </a>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Город:</span>
                <span className="employeeBaseInfo-item-text" title={region?.name}>
                  {region?.name}
                </span>
              </div>
            </li>
            <li className="employeeBaseInfo-item employeeBaseInfo-item">
              <div className="employeeBaseInfo-item-wrapper">
                <span className="employeeBaseInfo-item-title">Роль:</span>
                <span className="employeeBaseInfo-item-text">{transformRole(role)}</span>
              </div>
            </li>
          </ul>
        </div>

        <div className="employeeBaseInfo-avatar employeeBaseInfo-avatar">
          <span className="employeeBaseInfo-avatar-title">Фотография:</span>
          <img
            crossOrigin="anonymous"
            className="employeeBaseInfo-avatar-img"
            src={!!avatar ? baseURL + avatar : noImg}
            alt=""
          />
        </div>
      </div>
      <div className="hr"></div>
      <h4 className="employeeBaseInfo-title">Профили</h4>

      <div className="employeeBaseInfo-porfiles">
        {profiles.map((profile) => (
          <EmployeeBaseInfoProfile
            info={profile}
            key={profile._id}
            addTask={true}
            onClick={() =>
              changeModalVisible("addTask", true, {
                executors: [profile],
              })
            }
          />
        ))}
      </div>
      {/* Редактирование сотрудника */}
      {getVisible("editEmployee") && <ModalEditEmployee handleChange={handleChange} />}

      {/* Редактирование задачи */}
      {getVisible("editTask") && <ModalEditTask handleChange={() => search()} mode={"edit"} />}

      {/* Редактирование подзадачи при добавлении */}
      {getVisible("editSubTask") && (
        <ModalEditTask handleChange={() => search()} mode={"editSubTask"} />
      )}

      {/* Добавление задачи */}
      {getVisible("addTask") && <ModalEditTask handleChange={() => search()} mode={"add"} />}

      {/* Добавление подзадачи */}
      {getVisible("addSubTask") && <ModalEditTask handleChange={() => search()} mode={"subTask"} />}

      {/* Копирование задачи */}
      {getVisible("copyTask") && <ModalEditTask handleChange={() => search()} mode={"copy"} />}

      {/* Делегирование задачи */}
      {getVisible("delegationTask") && (
        <ModalEditTask handleChange={() => search()} mode={"delegation"} />
      )}

      {/* Добавить рисунок или поинт */}
      {getVisible("addPoint") && <ModalAddPointInLayer />}

      {/* Просмотр рисунока */}
      {getVisible("showPoint") && <ModalAddPointInLayer show />}

      {/* Отложить задачу */}
      {getVisible("putAsideTask") && <ModalPutAside />}

      {/* Удалить подзадачу задачи */}
      {getVisible("deleteSubTask") && (
        <ModalDeleteItem
          changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
          onDelete={deleteTask}
          title="Вы уверены, что хотите удалить эту подзадачу?"
          headerTitle="Удалить подзадачу"
        />
      )}
    </div>
  );
};

export default EmployeeBaseInfo;
