import React, { useState, useEffect } from "react";
import "./SearchInputMobile.scss";
import Icons from "../Icons/Icons";

function SearchInputMobile({
  inputSearch = { visible: false, placeholder: null },
  onChange,
  onSubmit,
}) {
  const [IV, setIV] = useState("");

  const clearInput = () => {
    setIV("");
    onSubmit("");
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit && onSubmit(IV);
  };

  return (
    <form onSubmit={onFormSubmit} className="searchInputMobile">
      <div
        onClick={() => clearInput()}
        className={`searchInputMobile-icon-close ${
          IV.length > 0 ? "searchInputMobile-icon-close-active" : ""
        }`}
      >
        <Icons type={"crossBlackIcon"} size={"m-xl"} cursor />
      </div>
      <div className="searchInputMobile-icon-search " onClick={(e) => onFormSubmit(e)}>
        <Icons type={"search"} size={"m-xl"} color={"black"} />
      </div>
      <input
        value={IV}
        onChange={(e) => {
          setIV(e.target.value);
          onChange && onChange(e.target.value);
        }}
        type="text"
        maxLength={30}
        placeholder={inputSearch.placeholder || "Найти"}
      />
    </form>
  );
}

export default SearchInputMobile;
