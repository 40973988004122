import React, { useState, useEffect, useRef } from "react";
import "./DocumentationItemGrid.scss";
import { useModal } from "../../../../hooks/useModal";
import { getBaseUrl } from "../../../../utils/getBaseUrl";
import Icons from "../../../Icons/Icons";
import Screen from "../../../Screen/Screen";
import axios from "../../../../axios/axios";
import Checkbox from "../../../Checkbox/Checkbox";
import DocIcons from "../../../DocIcons/DocIcons";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import CardGridHeader from "../../../CardGridHeader/CardGridHeader";
import { useSearch } from "../../../../hooks/useSearch";
const DocumentationItemGrid = ({ info, type, father, changeCurrentFolder }) => {
  const { name, size, type_file, path_to_doc, total_documents } = info;

  const { changeModalVisible } = useModal();

  const { setQueryParam, queries } = useSearch();

  const getFolder = (folder) => {
    changeCurrentFolder && changeCurrentFolder(folder);
    if (queries.chapter === "companies") {
      setQueryParam({ chapter: "company", companyId: folder.ref_id, father: folder._id });
    } else if (queries.chapter === "projects") {
      setQueryParam({ chapter: "project", projectId: folder.ref_id, father: folder._id });
    } else {
      setQueryParam({ father: folder._id });
    }
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setMenuVisible(false));

  const [typeIcon, setTypeIcon] = useState("");

  const downloadFile = (e) => {
    e.preventDefault();
    axios
      .get(getBaseUrl() + "/folders" + path_to_doc, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a");
        const blob = new Blob([response.data]);
        let urll = window.URL.createObjectURL(blob);
        link.href = urll;
        link.download = info.name;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urll);
          document.body.removeChild(link);
        }, 0);
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!!father) {
      if (path_to_doc.includes("png")) {
        return setTypeIcon("png");
      } else if (path_to_doc.includes("docx")) {
        return setTypeIcon("docx");
      } else if (path_to_doc.includes("doc")) {
        return setTypeIcon("doc");
      } else if (path_to_doc.includes("txt")) {
        return setTypeIcon("txt");
      } else if (path_to_doc.includes("ppt")) {
        return setTypeIcon("ppt");
      } else if (path_to_doc.includes("pdf")) {
        return setTypeIcon("pdf");
      } else if (path_to_doc.includes("xlsx")) {
        return setTypeIcon("xlsx");
      } else if (path_to_doc.includes("xls")) {
        return setTypeIcon("xls");
      } else if (path_to_doc.includes("jpeg")) {
        return setTypeIcon("jpeg");
      } else if (path_to_doc.includes("jpg")) {
        return setTypeIcon("jpg");
      } else if (path_to_doc.includes("mpp")) {
        return setTypeIcon("mpp");
      } else if (path_to_doc.includes("zip")) {
        return setTypeIcon("zip");
      } else {
        setTypeIcon("");
      }
    }
  }, [type_file, father]);

  const onEvent = (name) => {
    if (type === "folder") {
      if (name === "info") return changeModalVisible("infoFolder", true, info);
      if (name === "open") return getFolder(info);
      if (name === "edit") return changeModalVisible("infoFolder", true, info, true);
      if (name === "delete") return changeModalVisible("deleteFolder", true, info);
      if (name === "add") return changeModalVisible("addDocument", true, { folder: info._id });
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
    } else {
      if (name === "open") return changeModalVisible("viewDoc", true, info, false, true);
      if (name === "edit") return changeModalVisible("editFile", true, info, true);
      if (name === "copy") return changeModalVisible("addToFolder", true, info, false, true);
      if (name === "addToFolder") return changeModalVisible("addToFolder", true, info, true, false);
      if (name === "delete") return changeModalVisible("deleteDocument", true, info);
    }
  };

  return (
    <div className="documentationItemGrid">
      {/* <div className="documentationItemGrid-header">
        <Screen size={"lg"}>
          {type === "folder" ? (
            <CardGridHeader
              // title={!father ? "" : "Файлов:"}
              // text={father && total_documents}
              onClick={() => setMenuVisible(!menuVisible)}
            />
          ) : (
            <CardGridHeader
              // title={"Размер(mb):"}
              // text={size}
              onClick={() => setMenuVisible(!menuVisible)}
            />
          )}

        </Screen>
        <Screen size={"lg"} down>
          {type === "folder" ? (
            <CardGridHeader
              // title={!father ? "" : "Файлов:"}
              // text={father && total_documents}
              onClick={() => changeModalVisible("menu", true, info, false, true)}
            />
          ) : (
            <CardGridHeader
              // title={"Размер(mb):"}
              // text={size}
              onClick={() => changeModalVisible("menu", true, info, false, true)}
            />
          )}
        </Screen>
      </div> */}
      <div className="documentationItemGrid-wrapper" onClick={() => onEvent("open")}>
        <div className="documentationItemGrid-icon">
          {type === "folder" ? (
            <Icons type={"folder"} size={"100"} title={{ visible: true, text: "" }} cursor />
          ) : type_file === "photo" ? (
            <img
              className="documentationItemGrid-foto"
              src={getBaseUrl() + "/folders" + path_to_doc}
            />
          ) : (
            <DocIcons size={"100"} type={path_to_doc} cursor />
          )}
        </div>
        <div className="documentationItemGrid-text">
          <span className="documentationItemGrid-title" title={name}>
            {name}
          </span>
          <div className="documentationItemGrid-text-box">
            {type === "folder" ? (
              <>
                <span className="documentationItemGrid-text-box-title">
                  {!father ? "" : "Файлов: "}
                </span>
                <span className="documentationItemGrid-text-box-text">
                  {father && total_documents}
                </span>
              </>
            ) : (
              <>
                <span className="documentationItemGrid-text-box-title">Размер(mb): </span>
                <span className="documentationItemGrid-text-box-text">{size}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="documentationItemGrid-btn-menu">
        <Screen size={"lg"}>
          <>
            <Icons
              type={"menu"}
              size={"md"}
              cursor
              title={{ visible: true, text: "" }}
              onClick={() => setMenuVisible(!menuVisible)}
            />
            {menuVisible && (
              <div className="documentationItemGrid-menu-window" ref={ref}>
                {!father ? (
                  <>
                    {type === "folder" && (
                      <span
                        className="documentationItemGrid-menu-window-text"
                        onClick={() => onEvent("info")}
                      >
                        Информация
                      </span>
                    )}
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("open")}
                    >
                      Открыть
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    {type === "folder" && (
                      <span
                        className="documentationItemGrid-menu-window-text"
                        onClick={() => onEvent("info")}
                      >
                        Информация
                      </span>
                    )}
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("open")}
                    >
                      Открыть
                    </span>
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("edit")}
                    >
                      Редактировать
                    </span>
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("copy")}
                    >
                      Скопировать
                    </span>
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("addToFolder")}
                    >
                      Переместить
                    </span>
                    {type !== "folder" && (
                      <span
                        className="documentationItemGrid-menu-window-text"
                        onClick={(e) => downloadFile(e)}
                      >
                        Скачать
                      </span>
                    )}
                    <span
                      className="documentationItemGrid-menu-window-text"
                      onClick={() => onEvent("delete")}
                    >
                      Удалить
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        </Screen>
        <Screen size={"lg"} down>
          <Icons
            type={"menu"}
            size={"m-md"}
            cursor
            title={{ visible: true, text: "" }}
            onClick={() => changeModalVisible("menu", true, info, false, true)}
          />
        </Screen>
      </div>
    </div>
  );
};

export default DocumentationItemGrid;
