import React from "react";
import "./ModalInfoProtocol.scss";
import { getFormatFIO } from "../../utils/getFormatFIO";
import ListModal from "../ListModal/ListModal";
import { useModal } from "../../hooks/useModal";
import { getFullPath } from "../../utils/getFullPath";
import SubtaskList from "../SubtaskList/SubtaskList";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import dayjs from "dayjs";
import ListModalProfile from "../ListModalProfile/ListModalProfile";
import Icons from "../Icons/Icons";
import { useHasPermissions } from "../../hooks/useHasPermissions";

const ModalInfoProtocol = ({
  protocolInfo: protocolInfoProp,
  modal,
  projectMobile = false,
  eventId,
}) => {
  const { changeModalVisible, getInfo } = useModal();
  const protocolInfo = getInfo("infoProtocol", "data") || protocolInfoProp;

  const {
    name,
    number,
    description,
    secretary,
    project,
    employees_customer,
    employees_gencontractor,
    employees_executor,
    tasks,
    createdAt,
    _id,
  } = protocolInfo;

  const { edit, remove } = useHasPermissions("infoProtocol", protocolInfo);

  const onEvent = (event) => {
    if (event === "edit") {
      changeModalVisible("editProtocol", true, protocolInfo);
    } else if (event === "remove") {
      changeModalVisible("deleteProtocol", true, { ...protocolInfo, eventId: eventId });
    }
  };

  if (modal) {
    return (
      <ModalWrapper
        title={`Протокол совещания №${number} от ${dayjs(createdAt).format("DD.MM.YYYY")}`}
        onClose={() => changeModalVisible("infoProtocol", false)}
      >
        <div className="modalInfoProtocol modal">
          <div className="flex-dir-column gap-10">
            <span className="modalInfoProtocol-title">Проект:</span>
            <span className="modalInfoEvent-info-bold">{project.name || "-"}</span>
          </div>
          <div className="flex-dir-column gap-10">
            <span className="modalInfoProtocol-title">Назвнаие:</span>
            <span className="modalInfoEvent-info-bold">{protocolInfo?.name || "-"}</span>
          </div>
          <div className="flex-dir-column gap-10">
            <span className="modalInfoProtocol-title">Протокол:</span>
            <span className="modalInfoEvent-info-bold">{description || "-"}</span>
          </div>
          <div className="modalInfoProtocol-gridLists">
            <div className="modalInfoProtocol-person">
              <span className="modalInfoProtocol-title">Автор протокола:</span>
              <div className="modalInfoProtocol-person-wrapper">
                <img
                  className="modalInfoProtocol-person-logo"
                  src={getFullPath(secretary.user.avatar, "avatar")}
                  alt={getFormatFIO(secretary.user)}
                />
                <div className="modalInfoProtocol-person-info">
                  <span className="modalInfoProtocol-bold">{getFormatFIO(secretary.user)}</span>
                  <span className="modalInfoProtocol-text">{secretary.position.name}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hr"></div>
          <h5 className="modalInfoProtocol-section-title">Участники</h5>
          <div className="modalInfoProtocol-row">
            <div className="modalInfoProtocol-lists">
              {!!employees_gencontractor?.length && (
                <div className="flex-dir-column gap-10">
                  <span className="modalInfoProtocol-title">от Ген.подрядчика:</span>
                  {!!employees_gencontractor?.length && (
                    <ListModalProfile
                      profiles={employees_gencontractor}
                      type={"employeesCompanyPosition"}
                      onClickInfo={(info) => {
                        changeModalVisible("infoUser", true, info);
                      }}
                    />
                  )}
                </div>
              )}
              {!!employees_customer?.length && (
                <div className="flex-dir-column gap-10">
                  <span className="modalInfoProtocol-title">от Заказчика:</span>
                  {!!employees_customer?.length && (
                    <ListModalProfile
                      profiles={employees_customer}
                      type={"employeesCompanyPosition"}
                      onClickInfo={(info) => {
                        changeModalVisible("infoUser", true, info);
                      }}
                    />
                  )}
                </div>
              )}
              {!!employees_executor?.length && (
                <div className="flex-dir-column gap-10">
                  <span className="modalInfoProtocol-title">от Исполнителя:</span>
                  {!!employees_executor?.length && (
                    <ListModalProfile
                      profiles={employees_executor}
                      type={"employeesCompanyPosition"}
                      onClickInfo={(info) => {
                        changeModalVisible("infoUser", true, info);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {!!tasks.length && (
            <>
              <div className="hr"></div>
              <SubtaskList
                title="Задачи:"
                buttonAddTask={false}
                mode={`protocol.info`}
                tasks={tasks}
                fatherId={_id}
              />
            </>
          )}
        </div>
      </ModalWrapper>
    );
  } else {
    return (
      <div
        className={`modalInfoProtocol ${projectMobile ? "modalInfoProtocol-projectMobile" : ""}`}
      >
        {!projectMobile && (
          <div className="modalInfoProtocol-header">
            <h5 className="modalInfoProtocol-section-title">{`Протокол совещания ${number}`}</h5>

            <div className="modalInfoProtocol-header-btns">
              {edit && <Icons onClick={() => onEvent("edit")} type="edit" cursor size={"md"} />}
              {eventId && remove && (
                <Icons onClick={() => onEvent("remove")} type="remove" cursor size={"md"} />
              )}
            </div>
          </div>
        )}

        <div className="flex-dir-column gap-10">
          <span className="modalInfoProtocol-title">Проект:</span>
          <span className="modalInfoEvent-info-bold">{project.name}</span>
        </div>
        <div className="flex-dir-column gap-10">
          <span className="modalInfoProtocol-title">Название:</span>
          <span className="modalInfoEvent-info-bold">{name}</span>
        </div>
        <div className="flex-dir-column gap-10">
          <span className="modalInfoProtocol-title">Протокол:</span>
          <span className="modalInfoEvent-info-bold">{description}</span>
        </div>
        <div className="modalInfoProtocol-gridLists">
          <div className="modalInfoProtocol-person">
            <span className="modalInfoProtocol-title">Автор совещания:</span>
            <div className="modalInfoProtocol-person-wrapper">
              <img
                className="modalInfoProtocol-person-logo"
                src={getFullPath(secretary.user.avatar, "avatar")}
                alt={getFormatFIO(secretary.user)}
              />
              <div className="modalInfoProtocol-person-info">
                <span className="modalInfoProtocol-bold">{getFormatFIO(secretary.user)}</span>
                <span className="modalInfoProtocol-text">{secretary.position.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modalInfoProtocol-row">
          <h5 className="modalInfoProtocol-section-title">Участники</h5>
          <div className="modalInfoProtocol-lists">
            {!!employees_gencontractor?.length && (
              <div className="flex-dir-column gap-10">
                <span className="modalInfoProtocol-title">от Ген.подрядчика:</span>
                {!!employees_gencontractor?.length && (
                  <ListModalProfile
                    profiles={employees_gencontractor}
                    type={"employeesCompanyPosition"}
                    onClickInfo={(info) => {
                      changeModalVisible("infoUser", true, info);
                    }}
                  />
                )}
              </div>
            )}
            {!!employees_customer?.length && (
              <div className="flex-dir-column gap-10">
                <span className="modalInfoProtocol-title">от Заказчика:</span>
                {!!employees_customer?.length && (
                  <ListModalProfile
                    profiles={employees_customer}
                    type={"employeesCompanyPosition"}
                    onClickInfo={(info) => {
                      changeModalVisible("infoUser", true, info);
                    }}
                  />
                )}
              </div>
            )}
            {!!employees_executor?.length && (
              <div className="flex-dir-column gap-10">
                <span className="modalInfoProtocol-title">от Исполнителя:</span>
                {!!employees_executor?.length && (
                  <ListModalProfile
                    profiles={employees_executor}
                    type={"employeesCompanyPosition"}
                    onClickInfo={(info) => {
                      changeModalVisible("infoUser", true, info);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {!!tasks.length && (
          <>
            <div className="hr"></div>
            <SubtaskList
              title="Задачи:"
              buttonAddTask={false}
              mode={`protocol.info`}
              tasks={tasks}
              fatherId={_id}
            />
          </>
        )}
      </div>
    );
  }
};

export default ModalInfoProtocol;
