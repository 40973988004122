import React, { useState, useEffect } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import "./ReferenceMobile.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetReferences,
  asyncDeleteReferences,
} from "../../../store/reducers/referencesReducer";
import ReferenceItem from "../../../components/ReferenceItem/ReferenceItem";
import ReferenceList from "../../../components/ReferenceList/ReferenceList";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import ModalAddEditReference from "../../../components/ModalAddEditReference/ModalAddEditReference";
import { useModal } from "../../../hooks/useModal";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import { useNavigate, useParams } from "react-router-dom";
import EmptySection from "../../../components/EmptySection/EmptySection";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { ErrorBoundary } from "../../../ui";

const ReferenceMobile = () => {
  const dispatch = useDispatch();

  const { add } = useHasPermissions("reference");
  const { changeModalVisible, getVisible, getInfo } = useModal();
  const { references, count, loading } = useSelector((state) => state.references);

  const { search } = useSearch("reference");

  const deleteReference = async () => {
    const { _id } = getInfo("deleteReference", "data");
    let delReference = [];
    delReference.push(getInfo("deleteReference", "data")._id);
    if (_id) {
      await dispatch(asyncDeleteReferences(delReference));
      await search();
    }
    changeModalVisible("deleteReference", false);
  };

  // useEffect(() => {
  //   jivo_init();
  // }, []);

  return (
    <LayoutMobile
      search={{
        inputSearch: {
          visible: true,
          placeholder: "Найти вопрос по названию",
        },
      }}
      filterDisabled={true}
      footerBtnAddCircle={
        add && {
          visible: true,
          onClick: () => changeModalVisible("addReference", true),
        }
      }
    >
      <ErrorBoundary>
        <div className="referenceMobile">
          <div className="referenceMobile-content-wrapper  customScroll">
            <WrapperLoader loading={loading}>
              {!!count ? (
                <ReferenceList references={references} count={count} />
              ) : (
                <EmptySection
                  text={"Справок не найдено"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addReference", true);
                  }}
                />
              )}
            </WrapperLoader>
          </div>
        </div>
        {getVisible("menu") && <ModalMobileMenu chapter={"reference"} nameModal={"menu"} />}
        {/* Добавление позиции */}
        {getVisible("addReference") && <ModalAddEditReference />}
        {/* Добавление позиции */}
        {getVisible("editReference") && <ModalAddEditReference edit={true} />}
        {getVisible("deleteReference") && (
          <ModalDeleteItem
            changeModalVisible={() => changeModalVisible("deleteReference", false)}
            onDelete={deleteReference}
            title="Вы уверены, что хотите удалить справку?"
            headerTitle="Удалить справку"
          />
        )}
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default ReferenceMobile;
