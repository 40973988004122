import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { asyncGetStructures } from "../../store/reducers/structuresReducer";
import StructuresList from "./components/StructuresList/StructuresList";
import StructuresGrid from "./components/StructuresGrid/StructuresGrid";
import "./Structures.scss";
import WrapperLoader from "../WrapperLoader/WrapperLoader";
import { asyncGetCompany } from "../../store/reducers/companiesReducer";
import { useDispatch } from "react-redux";
import { asyncGetUser } from "../../store/reducers/userReducer";
import { useSearch } from "../../hooks/useSearch";

const Structures = ({ viewCards, setDepartmentLength }) => {
  const dispatch = useDispatch();

  const { queries, setQueryParam, search } = useSearch("employees:structures");

  const { structures: data, count } = useSelector((state) => state.structures);

  const [structures, setStructures] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDepartmentLength && setDepartmentLength(count || 0);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setStructures(data);
  }, [data]);

  return (
    <div
      className={`structures ${
        viewCards === "rows" ? "structures-columns" : ""
      } `}
    >
      <WrapperLoader
        loading={loading}
        chapter={"structures"}
        viewCards={viewCards}
      >
        {!!structures &&
          !!queries.company &&
          (viewCards === "rows" ? (
            <StructuresList structures={structures} />
          ) : (
            <StructuresGrid structures={structures} />
          ))}
        {(!!structures && !!queries.company) || (
          <div className="employees-content__section structures-noCompany">
            <h2 className="structures-noCompany-title">Выберите компанию</h2>
          </div>
        )}
      </WrapperLoader>
    </div>
  );
};
export default Structures;
