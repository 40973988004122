import React from "react";
import "./ProfileMobileTabsList.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import ProfileMobileTabsListItem from "./components/ProfileMobileTabsListItem/ProfileMobileTabsListItem";

const ProfileMobileTabsList = ({ chapter, data }) => {
  return (
    <div className="profileMobileTabsList">
      {/* <div className="profileMobileTabsList-header">
        <Icons type={data.type.icon} color={"blue"} size={"m-xl"} />
        <span className="profileMobileTabsList-header-title">
          {data.type.name}
        </span>
      </div> */}

      {data.pagePermission.map((elem, index) => (
        <ProfileMobileTabsListItem key={index} data={elem} index={index} page>
          {elem?.pagePermission?.map((data, i) => (
            <ProfileMobileTabsListItem key={i} data={data} index={i} />
          ))}
        </ProfileMobileTabsListItem>
      ))}
    </div>
  );
};

export default ProfileMobileTabsList;
