import React from "react";
import voicePlay from "../../assets/icons/voice-play.svg";
import voiceRoad from "../../assets/icons/voice-road.svg";
import DocItem from "../DocItem/DocItem";
import { getFormatFIO } from "../../utils/getFormatFIO";
import "./MessageItem.scss";
import { getBaseUrl } from "../../utils/getBaseUrl";
import { getFullPath } from "../../utils/getFullPath";
import { useModal } from "../../hooks/useModal";
import { useMemo } from "react";
import { getFormatDate } from "../../utils/getFormatDate";
import dayjs from "dayjs";
import { getFormatDateTime } from "../../utils/getFormatDateTime";

function MessageItem(props) {
  const { changeModalVisible } = useModal();

  const { info, visibleAvatar, myId } = props;
  const { author, text } = info;

  const isPhoto = (type) => {
    const typesPhoto = ["image/png", "image/svg+xml", "image/jpeg", "image/jpg"];
    if (typesPhoto.includes(type)) return true;
    return false;
  };

  const photos = useMemo(() => {
    return info.documents.filter((d) => isPhoto(d.type));
  }, [info.documents]);

  const documents = useMemo(() => {
    return info.documents.filter((d) => !isPhoto(d.type));
  }, [info.documents]);

  return (
    <div className={`messageItem  memberMessage ${visibleAvatar ? "visibleAvatar" : ""}`}>
      {visibleAvatar ? (
        <img
          className="messageItem-logo"
          src={getFullPath(author?.avatar, "avatar")}
          alt={author?.surname}
        />
      ) : (
        <div className="messageItem-logo"></div>
      )}
      <div className="messageItem-wrapper">
        {visibleAvatar && (
          <div className="messageItem-profile">
            <span className="messageItem-profile-title">{getFormatFIO(author, "FI")}</span>
          </div>
        )}
        <span className="messageItem-text">
          <span>{text}</span>
        </span>
        {!!info?.documents?.length && (
          <div className="d-flex flex-dir-column gap-20 ">
            {!!photos.length && (
              <div className="messageItem-photos">
                {photos?.map((img, index) => (
                  <div
                    key={img}
                    style={{
                      backgroundImage: `url(${getFullPath(img.path, "task")})`,
                    }}
                    className="messageItem-photos-item"
                    onClick={() =>
                      changeModalVisible("swiperPhoto", true, {
                        list: photos.map((p) => p.path),
                        initialSlide: index,
                      })
                    }
                  ></div>
                ))}
              </div>
            )}

            {!!documents?.length && (
              <div className="messageItem-files">
                {documents.map((document) => (
                  <DocItem
                    btnVisible={{ download: true }}
                    download
                    key={document.name}
                    info={document}
                  />
                ))}
              </div>
            )}

            {/* {!!voices.length && (
              <div className="messageItem-body-voices">
                {voices.map((voice) => (
                  <div key={voice.id} className="messageItem-body-voices-item">
                    <img src={voicePlay} alt="play" />
                    <div
                      className="messageItem-body-voices-item-road"
                      style={{
                        backgroundImage: `url(${voiceRoad})`,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        )}
        <div className="messageItem-footer">
          <span> {getFormatDateTime(info.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}

export default MessageItem;
