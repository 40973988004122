import React, { useEffect, useState } from "react";
import "./ProjectWorkScheduleInfo.scss";
import Select from "../Select/Select";
import { getFormatDate } from "../../utils/getFormatDate";
import dayjs from "dayjs";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { useChangeIV } from "../../hooks/useChangeIV";
import InputDate from "../../components/InputDate/InputDate";
import Input from "../../components/Input/Input";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { asyncEditScheduleProductionWorks } from "../../store/reducers/projectsReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getForSelect } from "../../utils/getForSelect";
import Icons from "../../components/Icons/Icons";

const ProjectWorkScheduleInfo = (props) => {
  const { data, edit } = props;
  const { _id: scheduleWorks_id, name, start, end, startChart, endChart } = data;

  const dispatch = useDispatch();

  const { _id: projectId } = useSelector((state) => state.projects.project);

  const generalContractor = useSelector((state) => state.projects.project.generalContractor);

  const [visible, setVisible] = useState(false);

  const getFormatDeviation = (number) => (number < 0 ? 0 : number);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name,
    periodСontract: [new Date(start), new Date(end)],
    periodTimeline: [new Date(startChart), new Date(endChart)],
    contractWorkStart: new Date(start),
    contractWorkEnd: new Date(end),
    chartWorkStart: new Date(startChart),
    chartWorkEnd: new Date(endChart),
  });

  // const { options, changeOptions, checkValidateOptions } = useChangeOptions({
  //   customer: {
  //     options: [],
  //     value: { label: customer.name, value: customer._id },
  //   },
  //   executor: {
  //     options: [],
  //     value: { label: executor.name, value: executor._id },
  //   },
  // });

  // const getCompanies = () => {
  //   getForSelect.getCompanies({ project: projectId }, (c) => {
  //     changeOptions("customer", "options", c);
  //     changeOptions("executor", "options", c);
  //   });
  // };

  const validateDate = () => {
    let validate = false;
    const { contractWorkStart, contractWorkEnd, chartWorkStart, chartWorkEnd } = IV;

    if (dayjs(contractWorkEnd).diff(contractWorkStart, "day") < 0) {
      validate = "Завершение работ по контракту, не может быть раньше начала работ по контракту";
    }
    if (dayjs(chartWorkEnd).diff(chartWorkStart, "day") < 0) {
      validate = "Завершение работ по графику, не может быть раньше начала работ по графику";
    }
    return validate;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validate = validateDate();
    if (validate) return toast.warning(validate);

    const res = await dispatch(
      asyncEditScheduleProductionWorks({
        scheduleWorks_id,
        name: IV.name,
        start: IV.contractWorkStart,
        end: IV.contractWorkEnd,
        startChart: IV.chartWorkStart,
        endChart: IV.chartWorkEnd,
      }),
    );
    if (res.error) return;
  };

  return (
    <div className="projectWorkScheduleInfo">
      <div className="projectWorkScheduleInfo-header">
        <div className="projectWorkScheduleInfo-gridScheduleInfo-item">
          {/* <span className="projectWorkScheduleInfo-title">Название графика:</span> */}
          <h4 className="projectWorkScheduleInfo-genTitle">{name}</h4>
        </div>
        <div
          className="ganttChartHeader-btn ganttChartHeader-eye-btn"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <Icons
              type={"arrowTop"}
              size={"md"}
              color={"white"}
              title={{ text: "Скрыть зависимости", visible: true }}
            />
          ) : (
            <Icons
              type={"arrowDown"}
              size={"md"}
              color={"white"}
              title={{ text: "Отобразить зависимости", visible: true }}
            />
          )}
        </div>
      </div>
      {visible && (
        <>
          {edit ? (
            <>
              <form onSubmit={onSubmit} className="projectWorkScheduleInfo-wrapper">
                <Input
                  placeholder="Введите название графика"
                  onChange={changeIV}
                  value={IV.name}
                  name="name"
                  title="Название графика:"
                  validate={checkValidateIV("name")}
                />
                {/* <div className="projectWorkScheduleInfo-grid-list">
                  <div className="projectWorkScheduleInfo-grid-item">
                    <Select
                      forInput
                      title="Заказчик:"
                      placeholder="Выберите заказчика"
                      options={options.customer.options}
                      handleChange={(data) => {
                        changeOptions("customer", "value", data);
                      }}
                      value={options.customer.value}
                      maxMenuHeigh={30}
                    />
                  </div>
                  <div className="projectWorkScheduleInfo-grid-item">
                    <Select
                  forInput
                  title="Исполнитель:"
                  placeholder="Выберите исполнителя"
                  options={options.executor.options}
                  handleChange={(data) => {
                    changeOptions("executor", "value", data);
                  }}
                  value={options.executor.value}
                  maxMenuHeigh={30}
                />
                  </div>
                </div> */}
                <span className="projectWorkScheduleInfo-text">Сроки:</span>
                <div className="projectWorkScheduleInfo-grid-list">
                  {/* <div className="projectWorkScheduleInfo-grid-item">
                <Period
                  state={IV.periodСontract}
                  handleSelect={(range1) =>
                    changeIV("set", { name: "periodСontract", value: range1 })
                  }
                  title={"Согласно контракта:"}
                  includeDateIntervals={true}
                />
              </div>
              <div className="projectWorkScheduleInfo-grid-item">
                {" "}
                <Period
                  state={IV.periodTimeline}
                  handleSelect={(range2) =>
                    changeIV("set", { name: "periodTimeline", value: range2 })
                  }
                  title={"Согласно графику:"}
                />
              </div> */}
                  <div className="projectWorkScheduleInfo-grid-item">
                    <InputDate
                      value={IV.contractWorkStart}
                      onChange={(data) => {
                        changeIV("set", { name: "contractWorkStart", value: data });
                      }}
                      title="Старт работ по контракту:*"
                      // includeDateIntervals={[
                      //   {
                      //     start: new Date(start),
                      //     end: new Date(end),
                      //   },
                      // ]}
                      withPortal={true}
                    />
                  </div>
                  <div className="projectWorkScheduleInfo-grid-item">
                    <InputDate
                      value={IV.contractWorkEnd}
                      onChange={(data) => {
                        changeIV("set", { name: "contractWorkEnd", value: data });
                      }}
                      title="Завершение работ по контракту:*"
                      // includeDateIntervals={[
                      //   {
                      //     start: new Date(start),
                      //     end: new Date(end),
                      //   },
                      // ]}
                      withPortal={true}
                    />
                  </div>
                  <div className="projectWorkScheduleAdd-grid-item">
                    <InputDate
                      value={IV.chartWorkStart}
                      onChange={(data) => {
                        changeIV("set", { name: "chartWorkStart", value: data });
                      }}
                      title="Старт работ по графику:"
                      // includeDateIntervals={[
                      //   { start: new Date(work_start), end: new Date(work_end) },
                      // ]}
                      withPortal={true}
                    />
                  </div>
                  <div className="projectWorkScheduleAdd-grid-item">
                    <InputDate
                      value={IV.chartWorkEnd}
                      onChange={(data) => {
                        changeIV("set", { name: "chartWorkEnd", value: data });
                      }}
                      title="Завершение работ по графику:"
                      // includeDateIntervals={[
                      //   { start: new Date(work_start), end: new Date(work_end) },
                      // ]}
                      withPortal={true}
                    />
                  </div>
                </div>
                <div className="projectWorkScheduleInfo-grid-item">
                  <span className="projectWorkScheduleInfo-title">Отклонение по срокам:</span>
                  <span className="projectWorkScheduleInfo-text">
                    {getFormatDeviation(dayjs(new Date()).diff(end, "day"))}
                  </span>
                  {/* </div> */}
                </div>
                <Button type="submit" title="Сохранить" color="blue" />
              </form>
            </>
          ) : (
            <div className="projectWorkScheduleInfo-wrapper">
              {/* <div className="projectWorkScheduleInfo-gridScheduleInfo-item">
            <span className="projectWorkScheduleInfo-title">Название графика:</span>
            <span className="projectWorkScheduleInfo-text"></span>
          </div> */}
              {/* <div className="projectWorkScheduleInfo-gridScheduleInfo">
                <div className="projectWorkScheduleInfo-gridScheduleInfo-item">
                  <span className="projectWorkScheduleInfo-title">Заказчик:</span>
                  <span className="projectWorkScheduleInfo-text">{customer.name}</span>
                </div>
                <div className="projectWorkScheduleInfo-gridScheduleInfo-item">
                  <span className="projectWorkScheduleInfo-title">Ген.подрядчик:</span>
                  <span className="projectWorkScheduleInfo-text">{generalContractor.name}</span>
                </div>
                <div className="projectWorkScheduleInfo-gridScheduleInfo-item">
                  <span className="projectWorkScheduleInfo-title">
                Исполнитель:
              </span>
              <span className="projectWorkScheduleInfo-text">
                {executor.name}{" "}
              </span>
                </div>
              </div> */}
              <div className="flex-dir-column gap-20">
                <span className="projectWorkScheduleInfo-text">Сроки:</span>
                <div className="projectWorkScheduleInfo-gridDateInfo">
                  <div className="projectWorkScheduleInfo-gridDateInfo-item">
                    <span className="projectWorkScheduleInfo-title">Согласно контракту:</span>
                    <span className="projectWorkScheduleInfo-text">
                      c {getFormatDate(start)} по {getFormatDate(end)}
                    </span>
                  </div>
                  <div className="projectWorkScheduleInfo-gridDateInfo-item">
                    <span className="projectWorkScheduleInfo-title">Согласно графика:</span>
                    <span className="projectWorkScheduleInfo-text">
                      c {getFormatDate(startChart)} по {getFormatDate(endChart)}
                    </span>
                  </div>
                  <div className="projectWorkScheduleInfo-gridDateInfo-item">
                    <span className="projectWorkScheduleInfo-title">Отклонение по срокам:</span>
                    <span className="projectWorkScheduleInfo-text">
                      {getFormatDeviation(dayjs(new Date()).diff(end, "day"))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectWorkScheduleInfo;
