import React from "react";
import "./DonutChart.scss";
import { PieChart } from "react-minimal-pie-chart";

function DonutChart(props) {
  const {
    children,
    title,
    forCreate,
    data = [
      { title: "null", value: 25, color: "#9C9CFF" },
      { title: "null", value: 25, color: "#7777FF" },
      { title: "null", value: 25, color: "#5555FF" },
      { title: "null", value: 25, color: "#3636FF" },
    ],
    heightChart,
    lineWidth = 15,
    onClick,
    onMouseOut,
    onMouseOver,
    segmentsStyle = null,
    labelStyle = null,
    labelPosition,
    label,
    segmentsShift,
    totalValue = 0,
    radius,
    onBlur,
    paddingAngle,
    rounded = false,
  } = props;

  return (
    <>
      {forCreate ? (
        <div className="donutChart-create">
          <span className="donutChart-create-title">{title}</span>
          <div
            className="donutChart-create-content"
            style={{ height: heightChart ? `${heightChart}px` : "484px" }}
          >
            <PieChart
              viewBoxSize={[100, 100]}
              radius={40}
              animate
              animationDuration={900}
              data={data}
              lineWidth={lineWidth}
              onClick={(_, index) => onClick(index)}
              onMouseOut={() => onMouseOut()}
              onMouseOver={(_, index) => onMouseOver(index)}
              segmentsStyle={segmentsStyle}
              labelStyle={labelStyle}
              labelPosition={labelPosition}
              label={label}
              segmentsShift={segmentsShift}
              totalValue={totalValue}
            />
          </div>
        </div>
      ) : (
        <div className="donutChart">
          {title && <div className="donutChart-title">{children}</div>}
          <div className="donutChart-progress">
            <PieChart
              animate
              animationDuration={900}
              data={data}
              lineWidth={lineWidth}
              // label={({ dataEntry }) => dataEntry.value}
              onClick={(_, index) => onClick(index)}
              onMouseOut={onMouseOut}
              onMouseOver={onMouseOver}
              segmentsStyle={segmentsStyle}
              labelStyle={labelStyle}
              labelPosition={labelPosition}
              label={label}
              segmentsShift={segmentsShift}
              totalValue={totalValue}
              radius={radius}
              onBlur={onBlur}
              rounded={rounded}
              paddingAngle={paddingAngle}
              // totalValue={100}
              // lineWidth={20}
              // label={({ dataEntry }) => dataEntry.value}
              // labelStyle={{
              //   fontSize: "0px",
              //   fontFamily: "sans-serif",
              //   fill: "#E38627",
              // }}
              // labelPosition={0}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default DonutChart;
