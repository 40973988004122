import React, { useState, useId } from "react";
import Button from "../../components/Button/Button";
import FileUploader from "../../components/FileUploader/FileUploader";
import Input from "../../components/Input/Input";
import "./ModalAddLayer.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import DocItem from "../../components/DocItem/DocItem";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import { useDispatch } from "react-redux";
import { asyncCreateLayers } from "../../store/reducers/projectsReducer";
import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { useSearch } from "../../hooks/useSearch";
import Screen from "../Screen/Screen";

function ModalAddLayer(props) {
  const { handleChange } = props;
  const formId = useId();
  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const { search } = useSearch();

  const { _id: projectId } = useSelector((state) => state.projects.project);

  const [loading, setLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
    file: null,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3 })) return;

    const formData = new FormData();
    formData.append("name", IV.name);
    formData.append("image", IV.file);

    setLoading(true);
    await dispatch(asyncCreateLayers({ formData, project_id: projectId }));
    await search({ nativePage: "project:layers" });
    changeModalVisible("addLayer");
    setLoading(false);

    handleChange && handleChange();
  };

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addLayer")}
      title="Создать слой"
      btns={
        <div className="modalAddLayer-main-btns">
          <Button
            loading={loading}
            type="submit"
            disabled={!IV.name}
            color="blue"
            title="Создать"
            form={formId}
          />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddLayer" id={formId}>
        <Input
          name="name"
          value={IV.name}
          onChange={changeIV}
          title="Название слоя:*"
          placeholder="Введите название слоя"
          validate={checkValidateIV("name")}
        />
        <div className="modalAddLayer-main">
          {!!IV.file || (
            <div className="modalAddLayer-main-upload">
              <Screen size={"lg"}>
                <FileUploader
                  handleChange={(data) => changeIV("set", { name: "file", value: data })}
                />
              </Screen>
              <Screen size={"lg"} down>
                <FileUploader
                  handleChange={(data) => changeIV("set", { name: "file", value: data })}
                  typeBtn
                />
              </Screen>
            </div>
          )}
          {!!IV.file && (
            <div className="modalAddLayer-main-files">
              <DocItem
                info={IV.file}
                shadow
                btnVisible={{ remove: true }}
                event={{
                  remove: () => changeIV("set", { name: "file", value: null }),
                }}
              />
            </div>
          )}
          <div className="modalAddLayer-main-form">
            <Screen size={"lg"}>
              <Button
                loading={loading}
                type="submit"
                disabled={!IV.name}
                color="blue"
                title="Создать"
                form={formId}
              />
            </Screen>
          </div>
        </div>
      </form>
    </ModalWrapper>
  );
}

export default ModalAddLayer;
