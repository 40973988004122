import React, { useEffect, useState, useRef } from "react";
import "./Tasks.scss";
import { useDispatch, useSelector } from "react-redux";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import EmptySection from "../../../components/EmptySection/EmptySection";
import Filter from "../../../components/Filter/Filter";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import ModalAddPointInLayer from "../../../components/ModalAddPointInLayer/ModalAddPointInLayer";
import ModalEditTask from "../../../components/ModalEditTask/ModalEditTask";
import ModalDelegationTask from "../../../components/ModalDelegationTask/ModalDelegationTask";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalPutAside from "../../../components/ModalPutAside/ModalPutAside";
import ModalTask from "../../../components/ModalTask/ModalTask";
import SearchPanel from "../../../components/SearchPanel/SearchPanel";
import TasksListRow from "../../../components/TaskListRow/TaskListRow";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import { useModal } from "../../../hooks/useModal";
import {
  asyncDeleteSubTask,
  asyncDeleteTask,
  clearTasksKanban,
} from "../../../store/reducers/tasksReducer";
import ModalParticipant from "../../../components/ModalParticipants/ModalParticipant";
import ModalProject from "../../../components/ModalProject/ModalProject";
import TasksDragDrop from "../../../components/TasksDragDrop/TasksDragDrop";
import ModalInfoUser from "../../../components/ModalInfoUser/ModalInfoUser";
import ModalAddDellEmployees from "../../../components/ModalAddDellEmployees/ModalAddDellEmployees";
import ModalSwiperPhoto from "../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import { useSearch } from "../../../hooks/useSearch";
import { ErrorBoundary } from "../../../ui";

function Tasks() {
  const dispatch = useDispatch();

  const { getVisible, getInfo, changeModalVisible } = useModal();

  const { search, queries, goToPage } = useSearch("tasks");

  const { count, loading } = useSelector((state) => state.tasks);

  const [chapter, setChapter] = useState("all");
  const [viewCards, setViewCards] = useState("rows");

  const [importFile, setImportFile] = useState(false);

  const getTasks = async () => search();

  const deleteTask = async () => {
    const idArray = [];
    if (Array.isArray(getInfo("deleteTask", "data"))) {
      idArray.push(...getInfo("deleteTask", "data"));
    } else {
      idArray.push(getInfo("deleteTask", "data"));
    }
    await dispatch(asyncDeleteTask({ tasks: idArray }));
    await getTasks();

    if (getInfo("infoTask", "data")) {
      return changeModalVisible("deleteTask", false, "prev");
    }
    changeModalVisible("deleteTask", false);
  };

  const deleteSubTask = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteSubTask", "data"));
    if (!getInfo("copyTask", "data") && !getInfo("addTask", "data")) {
      await dispatch(asyncDeleteSubTask({ tasks: idArray }));
      await getTasks();
    }

    changeModalVisible("deleteSubTask", false, "prev");
  };

  const changeViewCard = (value) => {
    if (value === "rows") return goToPage("tasks");
    goToPage("tasksKanban", {}, { tab: "all" });
  };

  useEffect(() => {
    if (viewCards === "rows") dispatch(clearTasksKanban());
  }, [viewCards]);

  useEffect(() => {
    if (!queries.tab) return setChapter("all");

    setChapter(queries.tab);
  }, [queries.tab]);

  return (
    <ErrorBoundary>
      <div className="tasks">
        {getVisible() && <div className="bgc-shadow"></div>}
        <div className="task__content tasks-content">
          <div className="tasks-content__filter">
            <LeftInfo title={`Всего задач: ${count || 0}`} />
            <div className="tasks-content__filter-set">
              <AdditionalFilterPanel
                active={chapter}
                onProjectsClick={""}
                importFile={importFile}
                showImportFile={() => setImportFile((prev) => !prev)}
                onChartsClick={""}
                viewCards={viewCards}
                onViewClick={changeViewCard}
              />
              <Filter />
            </div>
          </div>
          <div className="tasks-content__searchPanel tasks__searchPanel">
            <SearchPanel
              inputSearch={{
                visible: true,
                placeholder: "Найти задачу по названию или номеру",
              }}
              buttons={{
                list: [
                  {
                    title: "Создать",
                    onClick: () => changeModalVisible("addTask", true, null, false, true),
                    // icon: true,
                  },
                ],
              }}
            />
          </div>
          <WrapperLoader
            loading={loading && viewCards === "rows"}
            chapter={"tasks"}
            viewCards={viewCards}
          >
            {viewCards === "rows" &&
              (!!count ? (
                <div className="tasks-content__row customScroll">
                  <TasksListRow />
                </div>
              ) : (
                <EmptySection text={"Задач не создано"} />
              ))}

            {viewCards === "columns" && <TasksDragDrop onDrag={search} />}

            {/* {!!count ? (
            viewCards === "rows" ? (
              <div className="tasks-content__row customScroll">
                <TasksListRow />
              </div>
            ) : (
              <TasksDragDrop />
            )
          ) : (
            !loading && (
              <EmptySection
                text={"Задач не создано"}
                // textBtn={"Добавить"}
                // onClick={() => {
                //   changeModalVisible("addTask", true);
                // }}
              />
            )
          )} */}
          </WrapperLoader>
          {/* Информация задачи */}
          {getVisible("infoTask") && <ModalTask handleChange={getTasks} />}

          {/* Редактирование задачи */}
          {getVisible("editTask") && <ModalEditTask getTasks={getTasks} mode={"edit"} />}

          {/* Добавление задачи */}
          {getVisible("addTask") && <ModalEditTask getTasks={getTasks} mode={"add"} />}

          {/* Редактирование подзадачи при добавлении */}
          {getVisible("editSubTask") && <ModalEditTask getTasks={getTasks} mode={"editSubTask"} />}

          {/* Добавление подзадачи */}
          {getVisible("addSubTask") && <ModalEditTask getTasks={getTasks} mode={"subTask"} />}

          {/* Копирование задачи */}
          {getVisible("copyTask") && <ModalEditTask getTasks={getTasks} mode={"copy"} />}

          {/* Делегирование задачи */}
          {getVisible("delegationTask") && (
            <ModalEditTask getTasks={getTasks} mode={"delegation"} />
          )}

          {/* Добавить рисунок или поинт */}
          {getVisible("addPoint") && <ModalAddPointInLayer />}

          {/* Просмотр рисунока */}
          {getVisible("showPoint") && <ModalAddPointInLayer show />}

          {/* Отложить задачу */}
          {getVisible("putAsideTask") && <ModalPutAside />}

          {/* Удалить задачу */}
          {getVisible("deleteTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteTask", false)}
              onDelete={deleteTask}
              title="Вы уверены, что хотите удалить эту задачу?"
              headerTitle="Удалить задачу"
            />
          )}

          {/* Удалить подзадачу задачи */}
          {getVisible("deleteSubTask") && (
            <ModalDeleteItem
              changeModalVisible={() => changeModalVisible("deleteSubTask", false)}
              onDelete={deleteSubTask}
              title="Вы уверены, что хотите удалить эту подзадачу?"
              headerTitle="Удалить подзадачу"
            />
          )}

          {/* информация о сотруднике */}
          {getVisible("infoParticipant") && (
            <ModalParticipant
              info={getInfo("infoParticipant", "data")}
              changeModalVisible={changeModalVisible}
              edit={false}
            />
          )}

          {/* Информация о проекте */}
          {getVisible("infoProject") && (
            <ModalProject
              info={getInfo("infoProject", "data")}
              changeModalVisible={changeModalVisible}
            />
          )}

          {getVisible("infoUser") && <ModalInfoUser />}

          {getVisible("addEmployeesTask") && (
            <ModalAddDellEmployees add={true} getTasks={getTasks} />
          )}
          {getVisible("delEmployeesTask") && <ModalAddDellEmployees getTasks={getTasks} />}
          {getVisible("swiperPhoto") && <ModalSwiperPhoto />}
        </div>
      </div>
    </ErrorBoundary>
  );
}
export default Tasks;
