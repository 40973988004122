import React from "react";
import "./ErrorBoundary.scss";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorText: null, errorInfo: null };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorText: error.message, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary произошла ошибка: ", error, errorInfo);
    this.setState({ errorInfo: errorInfo.componentStack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorBoundary">
          <div className="errorBoundary-content">
            <h2 className="errorBoundary-title">
              Пожалуйста, сделайте скриншо ошибки и отправьте администратору системы
            </h2>{" "}
            <p>Error: {this.state.errorText}</p>
            <p>Info: {this.state.errorInfo}</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
  //   constructor(props) {
  //     super(props);
  //     this.state = { hasError: false };
  //   }

  //   static getDerivedStateFromError(error) {
  //     // Update state so the next render will show the fallback UI.
  //     return { hasError: true };
  //   }

  //   componentDidCatch(error, info) {
  //     // Example "componentStack":
  //     //   in ComponentThatThrows (created by App)
  //     //   in ErrorBoundary (created by App)
  //     //   in div (created by App)
  //     //   in App
  //     logErrorToMyService(error, info.componentStack);
  //   }

  //   render() {
  //     if (this.state.hasError) {
  //       // You can render any custom fallback UI
  //       return this.props.fallback;
  //     }

  //     return this.props.children;
  //   }
}
