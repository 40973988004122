export const getModalName = (mode, title = false) => {
  if (title) {
    if (mode === "add") return "Создать мероприятие";
    else if (mode === "edit") return "Редактировать мероприятие";
    return "Создать мероприятие";
  }

  if (mode === "add") return "addEvent";
  else if (mode === "edit") return "editEvent";
  return "addEvent";
};
