import React from "react";
import typePdf from "../../assets/icons/type-pdf.svg";
import typeWord from "../../assets/icons/type-word.svg";
import typeXls from "../../assets/icons/type-xls.svg";
import typePng from "../../assets/icons/type-png.svg";
import typeJpeg from "../../assets/icons/type-jpeg.svg";
import typeDefault from "../../assets/icons/type-default.svg";
import "./DocItem.scss";
import Icons from "../Icons/Icons";
import { getFullPath } from "../../utils/getFullPath";
import axios from "axios";
import { getBaseUrl } from "../../utils/getBaseUrl";
import DocIcons from "../DocIcons/DocIcons";

function DocItem(props) {
  const { btnVisible, shadow, event, download, index, forWhat, info } = props;
  const { name, type, size, path } = info;

  const sizeFile = Math.ceil(size / 1048576);

  const getTypeIcon = (t) => {
    if (t?.includes("pdf")) {
      return typePdf;
    } else if (t?.includes("xls")) {
      return typeXls;
    } else if (t.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
      return typeXls;
    else if (t?.includes("word")) return typeWord;
    else if (
      t.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      return typeWord;
    } else if (t?.includes("png")) {
      return typePng;
    } else if (t?.includes("jpeg")) {
      return typeJpeg;
    } else {
      return typeDefault;
    }
  };

  const getPathToDowload = () => {
    const splitPath = path.split("/");
    const from = splitPath[1];
    // console.log(splitPath);
    return getFullPath(path, from);
  };

  const downloadFile = (e) => {
    e.stopPropagation();
    axios
      .get(getPathToDowload(), { responseType: "blob" })
      .then((data) => {
        let urll = window.URL.createObjectURL(data.data);
        const link = document.createElement("a");
        link.href = urll;
        link.download = info.name;
        document.body.append(link);
        link.style = "display: none";
        link.click();
        link.remove();
        // setTimeout(() => {
        //   window.URL.revokeObjectURL(urll);
        //   document.body.removeChild(link);
        // }, 0);
        URL.revokeObjectURL(link);
      })
      .catch(console.error);
  };

  return (
    <div key={document._id} className={`docItem ${shadow ? "shadow" : ""}`}>
      {download ? (
        <div className="docItem-content donwload" onClick={(e) => downloadFile(e, path)}>
          {" "}
          <div className="taskInfo-documents-item-typeIcon">
            <DocIcons type={name} size={"md"} />
            {/* <img src={getTypeIcon(type)} alt="type" /> */}
          </div>
          <div className="docItem-info">
            <span className="docItem-info-name-full">{name}</span>

            <span className="docItem-info-size">{sizeFile} МБ.</span>
          </div>
          <div className="docItem-btns">
            {btnVisible.download && (
              <Icons
                type={"download"}
                size={"md"}
                cursor
                title={{
                  visible: true,
                  text: "",
                }}
              />
            )}
          </div>
          {/* // </a> */}
        </div>
      ) : (
        <div className="docItem-content">
          <div className="taskInfo-documents-item-typeIcon">
            <DocIcons type={name} size={"xxl"} />
          </div>
          <div className="docItem-info">
            <span className="docItem-info-name-full">{name}</span>

            <span className="docItem-info-size">{sizeFile} МБ.</span>
          </div>

          <div className="docItem-btns">
            {btnVisible?.remove && (
              <Icons
                onClick={() => event?.remove(index)}
                type={"remove"}
                size={"md"}
                cursor
                title={{
                  visible: true,
                  text: "",
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DocItem;
