import React, { useEffect, useId } from "react";
import ModalWrapperMobile from "../../../../../components/ModalWrapperMobile/ModalWrapperMobile";
import "./ModalAddDepartmentMobile.scss";
import Input from "../../../../../components/Input/Input";
import Select from "../../../../../components/Select/Select";
import { useChangeIV } from "../../../../../hooks/useChangeIV";
import { useChangeOptions } from "../../../../../hooks/useChangeOptions";
import Button from "../../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCreateDepartament,
  asyncGetStructures,
} from "../../../../../store/reducers/structuresReducer";
import { getForSelect } from "../../../../../utils/getForSelect";
import InputSearch from "../../../../../components/InputSearch/InputSearch";
import { useModal } from "../../../../../hooks/useModal";

const ModalAddDepartmentMobile = ({ handleChange }) => {
  const formId = useId();
  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const { structures, count, loading } = useSelector(
    (state) => state.structures
  );

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameDepartment: "",
  });
  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    department: {
      options: [],
      value: null,
    },
    director: {
      options: [],
      value: null,
    },
    profiles: {
      options: [],
      value: [],
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("nameDepartment", {
      minHeight: 3,
      maxHeight: 100,
    });

    if (!checkName) return;

    await dispatch(
      asyncCreateDepartament({
        parent_id: options.department.value.value,
        name: IV.nameDepartment,
        director: options.director?.value?.value || null,
        profiles: options.profiles.value.map((e) => e.value),
      })
    );
    handleChange && handleChange("employees:structures");
    changeModalVisible("addDepartment", false);
  };

  useEffect(() => {
    getForSelect.getUsers({ withoutCompany: true }, (users) => {
      changeOptions("employees", "options", users);
      changeOptions("director", "options", users);
    });
    changeOptions(
      "department",
      "options",
      structures.departments?.map((e) => ({ label: e.name, value: e._id }))
    );
    changeOptions("department", "value", {
      label: structures.name,
      value: structures._id,
    });
  }, []);

  return (
    <ModalWrapperMobile
      title={"Добавить отдел"}
      onClose={() => changeModalVisible("addDepartment", false)}
      btns={
        <div className="modalAddDepartmentMobile-btn-wrapper">
          <Button type="submit" title="Добавить" color="blue" form={formId} />
        </div>
      }
    >
      <div className="modalAddDepartmentMobile">
        <form
          onSubmit={onSubmit}
          action="modalAddDepartmentMobile-form"
          className="modalAddDepartmentMobile-form"
          id={formId}
        >
          <Select
            options={options.department.options}
            value={options.department.value}
            handleChange={(data) => changeOptions("department", "value", data)}
            placeholder="Выберите отдел"
            title="Родительский отдел:*"
            forInput
            validate={checkValidateOptions("department")}
          />
          <div className="modalAddDepartmentMobile-content">
            <Input
              onChange={changeIV}
              value={IV.nameDepartment}
              name="nameDepartment"
              title="Название отдела:*"
              placeholder="Введите название отдела не более 50 символов"
              validate={checkValidateIV("nameDepartment")}
            />
            <div className="modalAddDepartmentMobile-content-select">
              <InputSearch
                forInput
                value={options.director.value}
                title="Руководитель отдела:"
                handleChange={(data) =>
                  changeOptions("director", "value", data)
                }
                placeholderSelect="Выберите руководителя"
                optionsForSearch={{ company: structures?.company?._id }}
              />
            </div>
          </div>
          <div className="modalAddDepartmentMobile-content">
            <InputSearch
              multi={true}
              forInput={true}
              listModal={true}
              value={options.profiles.value}
              title={"Сотрудники:"}
              handleChange={(data) => changeOptions("profiles", "value", data)}
              placeholderSelect="Выберите сотрудника"
              optionsForSearch={{
                company: structures?.company?._id,
                profile: true,
              }}
            />
          </div>
        </form>
      </div>
    </ModalWrapperMobile>
  );
};

export default ModalAddDepartmentMobile;
