import React from "react";
import "./EmployeeBaseInfoProfile.scss";
import Button from "../Button/Button";
import { useModal } from "../../hooks/useModal";
import { useHasPermissions } from "../../hooks/useHasPermissions";
import Screen from "../Screen/Screen";
import { useNavigate } from "react-router-dom";

const EmployeeBaseInfoProfile = ({ info, addTask = false, onClick }) => {
  const { company, departments, position, groups } = info;

  const { add: addTaskPemission } = useHasPermissions("profileRowModal", info);

  const { changeModalVisible } = useModal();

  const navigate = useNavigate();

  const nvigateToCompany = () => {
    navigate(`/company/${company._id}/baseInfo`);
  };

  const clearDoble = (arr) => {
    const resObj = {};
    const res = arr.filter(({ name }) => !resObj[name] && (resObj[name] = 1));

    return res;
  };

  const createArray = (array, key) => {
    const arr = [];
    array.map((elem) => {
      if (elem[key]) {
        arr.push(elem[key]);
      }
    });
    return arr;
  };

  // console.log("info", info);

  return (
    <div className="employeeBaseInfoProfile">
      <ul className="employeeBaseInfoProfile-list">
        <li className="employeeBaseInfoProfile-item">
          <span className="employeeBaseInfoProfile-item-title">Компания:</span>
          <span
            className="employeeBaseInfoProfile-item-text employeeBaseInfoProfile-item-text-hover"
            onClick={nvigateToCompany}
          >
            {company.name || ""}
          </span>
        </li>
        <li className="employeeBaseInfoProfile-item">
          <span className="employeeBaseInfoProfile-item-title">Отделы:</span>
          <span className="employeeBaseInfoProfile-item-text">
            {clearDoble(createArray(departments, "name")).join(", ") || ""}
          </span>
        </li>
        <li className="employeeBaseInfoProfile-item">
          <span className="employeeBaseInfoProfile-item-title">Должность:</span>
          <span className="employeeBaseInfoProfile-item-text">{position.name || ""}</span>
        </li>
        <li className="employeeBaseInfoProfile-item">
          <span className="employeeBaseInfoProfile-item-title">Группы:</span>
          <span className="employeeBaseInfoProfile-item-text">
            {clearDoble(createArray(groups, "name")).join(", ") || ""}
          </span>
        </li>
        <Screen size={"sm"}>
          <li className="employeeBaseInfoProfile-item">
            {addTask && addTaskPemission && (
              <Button color="blue" icon={{ position: "left" }} title="Задача" onClick={onClick} />
            )}
          </li>
        </Screen>
      </ul>
      <Screen size={"sm"} down>
        <li className="employeeBaseInfoProfile-item">
          {addTask && addTaskPemission && (
            <Button color="blue" icon={{ position: "left" }} title="Задача" onClick={onClick} />
          )}
        </li>
      </Screen>
    </div>
  );
};

export default EmployeeBaseInfoProfile;
