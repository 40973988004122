import React from "react";
import Select from "../../../Select/Select";
import Input from "../../../Input/Input";
import Textarea from "../../../Textarea/Textarea";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { getFormatDateTime } from "../../../../utils/getFormatDateTime";
import RegularityForm from "../../../RegularityForm/RegularityForm";
import "./EditInfoTask.scss";
import { useSelector } from "react-redux";
import InputDate from "../../../InputDate/InputDate";
import { useModal } from "../../../../hooks/useModal";
import Checkbox from "../../../Checkbox/Checkbox";
import InputSearch from "../../../InputSearch/InputSearch";
import { getModalName } from "../../../../utils/getModalName";
import noAvatar from "../../../../assets/images/pages/noavatar.svg";
import NotificationFrequency from "../../../../components/NotificationFrequency/NotificationFrequency";

function EditInfoTask(props) {
  const {
    mode,
    checkValidateIVTask,
    authorCompany,
    checkValidateOptionsTask,
    IVTask,
    changeIV,
    optionsTask,
    changeOptions,
    start,
  } = props;

  const { getInfo } = useModal();

  const { userData } = useSelector((state) => state.user);

  const infoTask = getInfo(getModalName(mode), "data");

  const author = mode === "edit" ? infoTask?.author : userData;

  return (
    <div className="editInfoTask">
      <Input
        placeholder="Введите название задачи"
        onChange={changeIV}
        value={IVTask.name}
        name="name"
        title="Название задачи:*"
        validate={checkValidateIVTask("name")}
      />
      <Textarea
        onChange={changeIV}
        value={IVTask.description}
        height="170px"
        name="description"
        title="Описание задачи:"
        placeholder="Введите описание задачи"
      />
      {/* <div className="editInfoTask-row"> */}
      <div className="editInfoTask-row-item-grid">
        <div className="editInfoTask-person">
          <span className="title">Автор: </span>
          {mode === "edit" ||
          mode === "add" ||
          mode === "copy" ||
          mode === "delegation" ||
          mode === "subTask" ||
          mode === "editSubTask" ? (
            <Select
              options={optionsTask.author.options}
              value={optionsTask.author.value}
              handleChange={(data) => changeOptions("author", "value", data)}
              placeholder="Выберите автора"
              forInput
              menuPlacement="top"
              validate={checkValidateOptionsTask("author")}
            />
          ) : (
            <>
              <div className="d-flex gap-10">
                {author.avatar ? (
                  <img
                    className="editInfoTask-person-logo"
                    src={author.avatar}
                    alt={getFormatFIO(author)}
                  />
                ) : (
                  <img
                    className="editInfoTask-person-logo"
                    src={noAvatar}
                    alt={getFormatFIO(author)}
                  />
                )}
                <div className="editInfoTask-person-info">
                  <span className="editInfoTask-person-info-name">{getFormatFIO(author)}</span>
                  <span className="editInfoTask-person-info-position">
                    {author?.position?.name} {author?.company?.name}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        {mode !== "add" && <div></div>}
        {mode === "add" || (
          <div className="editInfoTask-row-item-startDate ">
            <span className="title">Поставлена:</span>
            <span className="bold">{getFormatDateTime(IVTask.start || new Date())}</span>
          </div>
        )}
        <InputDate
          dev={["title-blue"]}
          value={IVTask.end}
          onChange={(value) => changeIV("set", { name: "end", value })}
          title="Крайний срок:*"
          placeholderText="Выберите дедлайн"
          time={true}
          validate={checkValidateIVTask("end")}
          defultTime={{ hour: 18 }}
        />
      </div>
      <div className="editInfoTask-form-item">
        <InputSearch
          multi={true}
          type={`${optionsTask.projects?.value?.value ? "users+companies" : "users"}`}
          options={optionsTask.executors.options}
          value={optionsTask.executors.value}
          handleChange={(data) => changeOptions("executors", "value", data)}
          placeholder="Выберите исполнителя"
          forInput
          menuPlacement="top"
          title="Исполнитель:*"
          validate={checkValidateOptionsTask("executors")}
          listModal={true}
          optionsForSearch={{
            profile: true,
            project: optionsTask.projects?.value?.value,
            company: optionsTask.projects?.value?.value ? null : authorCompany,
          }}
        />
      </div>
      <div className="editInfoTask-form-item">
        <InputSearch
          type={`${optionsTask.projects?.value?.value ? "users+companies" : "users"}`}
          multi={true}
          options={optionsTask.observers.options}
          value={optionsTask.observers.value}
          handleChange={(data) => changeOptions("observers", "value", data)}
          placeholder="Выберите наблюдателя"
          forInput
          menuPlacement="top"
          title="Наблюдатели:"
          validate={checkValidateOptionsTask("observers")}
          listModal={true}
          optionsForSearch={{
            profile: true,
            project: optionsTask.projects?.value?.value,
            company: optionsTask.projects?.value?.value ? null : authorCompany,
          }}
        />
      </div>
      <div className="editInfoTask-form">
        <div className="editInfoTask-item">
          <NotificationFrequency
            changeIV={changeIV}
            IV={IVTask}
            notificationFrequencyOptions={optionsTask.notificationFrequency.options}
            notificationFrequencyValue={optionsTask.notificationFrequency.value}
            changeOptions={(data) => changeOptions("notificationFrequency", "value", data)}
            isDisabled={!IVTask.notificationEveryDay}
          />
        </div>
      </div>
      <div className="editInfoTask-regularity">
        <Checkbox
          check={!!IVTask.regularity}
          onCheck={() => {
            changeIV("set", {
              name: "regularity",
              value: IVTask.regularity ? null : {},
            });
          }}
        />
        <span className="editInfoTask-regularity-title">Сделать задачу регулярной </span>
      </div>
      {!!IVTask.regularity && (
        <RegularityForm changeIVTask={changeIV} IVRegularity={IVTask.regularity} />
      )}
    </div>
  );
}

export default EditInfoTask;
