import React from "react";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO.js";
import { getFormatDate } from "../../../../../../../utils/getFormatDate";
import "./CardRowWorkScheduler.scss";
import Icons from "../../../../../../../components/Icons/Icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useSearch } from "../../../../../../../hooks/useSearch";

const CardRowWorkScheduler = (props) => {
  const { info, check, number, changeModalVisible, addItemSelect, edit } = props;

  const { _id, name, customer, executor, start_contract, end_contract, start_chart, end_chart } =
    info;

  const { setQueryParam } = useSearch();

  const onEvent = (name) => {
    if (name === "info") {
      // setQueryParam({ work: _id });
      return changeModalVisible("infoWorkSchedule", true, info, false, false);
    } else if (name === "edit") {
      // setQueryParam({ work: _id, edit: 1 });
      return changeModalVisible("infoWorkSchedule", true, info, true, false);
    } else if (name === "remove") changeModalVisible("deleteWorkSchedule", true, info);
  };

  const getFormatDeviation = (number) => {
    if (number < 0) {
      return 0;
    }
    return number;
  };

  return (
    <li className="workSchedulerRowCard">
      <ul className="workSchedulerRowCard__list">
        {/* <li className="workSchedulerRowCard__item workSchedulerRowCard__item-number">
          <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
          <span>{number}</span>
        </li> */}
        <li
          className="workSchedulerRowCard__item workSchedulerRowCard__item-name"
          onClick={() => onEvent("info")}
        >
          <span title={name}>{name}</span>
        </li>
        {/* <li className="workSchedulerRowCard__item workSchedulerRowCard__item-customer">
          <span title={customer.name}>{customer.name}</span>
        </li>
        <li className="workSchedulerRowCard__item workSchedulerRowCard__item-responsible">
          <span title={executor.name}>{executor.name}</span>
        </li> */}
        <li className="workSchedulerRowCard__item workSchedulerRowCard__item-contract">
          <span title={getFormatDate(start_contract) + " - " + getFormatDate(end_contract)}>
            {getFormatDate(start_contract) + " - " + getFormatDate(end_contract)}
          </span>
        </li>

        <li className="workSchedulerRowCard__item workSchedulerRowCard__item-schedule">
          <span title={getFormatDate(start_chart) + " - " + getFormatDate(end_chart)}>
            {getFormatDate(start_chart) + " - " + getFormatDate(end_chart)}
          </span>
        </li>
        {/* <li className="workSchedulerRowCard__item workSchedulerRowCard__item-director">
          <span title={generalContractor.name}>{generalContractor.name}</span>
        </li> */}

        <li className="workSchedulerRowCard__item workSchedulerRowCard__item-overdue">
          <span title={getFormatDeviation(dayjs(new Date()).diff(end_contract, "day"))}>
            {getFormatDeviation(dayjs(new Date()).diff(end_contract, "day"))}
          </span>
        </li>
        <li className="workSchedulerRowCard__item workSchedulerRowCard__btns">
          <button className="workSchedulerRowCard__btn" onClick={() => onEvent("info")}>
            <Icons type={"infoItem"} size={"md"} cursor title={{ visible: true, text: "" }} />
          </button>
          {edit && (
            <button className="workSchedulerRowCard__btn" onClick={() => onEvent("edit")}>
              <Icons type={"edit"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
          {edit && (
            <button onClick={() => onEvent("remove")} className="workSchedulerRowCard__btn">
              <Icons type={"remove"} size={"md"} cursor title={{ visible: true, text: "" }} />
            </button>
          )}
        </li>
      </ul>
    </li>
  );
};
export default CardRowWorkScheduler;
