import React, { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import TasksColumn from "./components/TasksColumn/TasksColumn";
import "./TasksDragDrop.scss";
import { asyncEditStatusTask } from "../../store/reducers/tasksReducer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";

const TasksDragDrop = ({ mobile = false, onDrag }) => {
  const dispatch = useDispatch();

  const { tasksKanban } = useSelector((state) => state.tasks);

  const [columns, setColumns] = useState({
    assigned: { name: "Назначено", type: "assigned", items: [], count: 0, load: false },
    rejected: { name: "Отклонено", type: "rejected", items: [], count: 0, load: false },
    atWork: { name: "В работе", type: "atWork", items: [], count: 0, load: false },
    overdue: { name: "Просрочено", type: "overdue", items: [], count: 0, load: false },
    onVerification: {
      name: "На проверке",
      type: "onVerification",
      items: [],
      count: 0,
      load: false,
    },
    completed: { name: "Выполнено", type: "completed", items: [], count: 0, load: false },
  });

  const setColumnsInfo = (dataTasks) => {
    setColumns((prev) => ({
      ...prev,
      [dataTasks.status]: {
        ...prev[dataTasks.status],
        items: dataTasks.data.tasks,
        count: dataTasks.data.count,
        load: dataTasks.load,
      },
    }));
  };

  const setTasks = (status, tasks) => {
    if (typeof tasks === "function") {
      setColumns((prev) => ({
        ...prev,
        [status]: {
          ...prev[status],
          items: tasks(prev[status].items),
        },
      }));
    } else {
      setColumns((prev) => ({
        ...prev,
        [status]: {
          ...prev[status],
          items: tasks,
        },
      }));
    }
  };

  const setCount = (status, count) => {
    setColumns((prev) => ({
      ...prev,
      [status]: {
        ...prev[status],
        count,
      },
    }));
  };

  const setLoad = (status, load) => {
    setColumns((prev) => ({
      ...prev,
      [status]: {
        ...prev[status],
        load,
      },
    }));
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]; // клонка из которой забирается
      const destColumn = columns[destination.droppableId]; // колонка в которую приходит
      const sourceItems = [...sourceColumn.items]; // все таски
      const destItems = [...destColumn.items]; // выбранная таска
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed); //клонирует

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
          count: columns[source.droppableId].count - 1,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
          count: columns[destination.droppableId].count + 1,
        },
      });

      await dispatch(
        asyncEditStatusTask({
          status: destination.droppableId,
          tasks: [draggableId],
        }),
      );

      onDrag && onDrag();
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    for (const key in tasksKanban) {
      setColumnsInfo({
        status: key,
        data: tasksKanban[key],
        load: !(
          tasksKanban[key].tasks.length + columns[key].items.length >=
          tasksKanban[key].count
        ),
      });
    }
  }, [tasksKanban]);

  return (
    <div className="tasksDragDrop">
      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        <ul className={`tasksDragDrop-column ${mobile ? "customScroll-horizontally" : ""}`}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <TasksColumn
                title={column.name}
                type={column.type}
                key={columnId}
                columnId={columnId}
                tasks={columns[columnId].items}
                setTasks={setTasks}
                count={columns[columnId].count}
                setCount={setCount}
                load={columns[columnId].load}
              />
            );
          })}
        </ul>
      </DragDropContext>
    </div>
  );
};

export default TasksDragDrop;
