import React from "react";
import Icons from "../../../Icons/Icons";
import Screen from "../../../Screen/Screen";
import { getFormatDate } from "../../../../utils/getFormatDate";
import dayjs from "dayjs";
import ProjectWorkScheduleColListHeader from "./components/ProjectWorkScheduleColListHeader/ProjectWorkScheduleColListHeader";
import ProjectWorkScheduleColListContent from "./components/ProjectWorkScheduleColListContent/ProjectWorkScheduleColListContent";

const ProjectWorkScheduleColList = ({ tasks, edit }) => {
  return (
    <>
      {tasks?.map((stage) => (
        <div key={stage._id} className={`projectWorkScheduleCol-list-stages-item `}>
          <ProjectWorkScheduleColListHeader stage={stage} edit={edit} />
          <div className="projectWorkScheduleCol-list-stages-item-works">
            <div className={"projectWorkScheduleCol-list-stages-item-works-line"}></div>

            <ProjectWorkScheduleColListContent
              tasks={stage.works}
              stageId={stage._id}
              edit={edit}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectWorkScheduleColList;
