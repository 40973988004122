import React, { useEffect, useState } from "react";
import "./ListModal.scss";
import Select from "../Select/Select";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { asyncGetGroup } from "../../store/reducers/groupsReducer";
import { asyncGetProject } from "../../store/reducers/projectsReducer";
import { useDispatch } from "react-redux";

// eslint-disable-next-line
const ListModalItem = React.memo(function ListModalItem({ handelClick, edit, onClickInfo, info, type }) {
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelctedItemId] = useState(null);

  const dispatch = useDispatch();

  const clickRemove = (e) => {
    e.preventDefault();
    handelClick();
  };

  const clickInfo = (e) => {
    setSelctedItemId(info._id);
    e.preventDefault();
    setLoading(true);
    if (type === "employees" || type === "employeesPosition" || type === "employeesCompany") {
      dispatch(asyncGetUser(info._id)).then((data) => {
        if (data?.payload?._id) onClickInfo(data.payload);
        // console.log(data.payload);
        setLoading(false);
        setSelctedItemId(null);
      });
    } else if (type === "projects") {
      dispatch(asyncGetProject(info._id)).then((data) => {
        if (data?.payload?._id) onClickInfo(data.payload);
        setLoading(false);
        setSelctedItemId(null);
      });
    }
  };
  const disbledBtn = selectedItemId !== info._id && !!selectedItemId && !loading;

  const getInfo = (type) => {
    switch (type) {
      case "employeesPosition":
        return `(${info?.position.name})`;
      case "employeesCompany":
        return `(${info?.company.name})`;
      default:
        return "";
    }
  };

  return (
    <li
      key={info._id}
      className={`listModalItem ${selectedItemId !== info._id && loading && !!selectedItemId ? "" : "disabled"}`}
      title={info?.patronymic ? getFormatFIO(info) + " " + getInfo(type) : info.name}
      onClick={clickInfo}
    >
      {info?.patronymic ? (
        <span>{getFormatFIO(info) + " " + getInfo(type)}</span>
      ) : (
        <span>{info.name || info.label}</span>
      )}

      <div className="listModalItem__btns">
        {loading ? (
          <div className="submit-spinner-lm"></div>
        ) : (
          <div className="listModalItem__btn">
            {/* <Icons
                type={"infoItem"}
                size={"md"}
                cursor
                title={{
                  visible: true,
                  text: "",
                }}
              /> */}
          </div>
        )}
      </div>
    </li>
  );
});

const ListModal = ({
  edit,
  title,
  placeholder,
  titleSelect,
  widthSelect,
  type,
  onClickInfo,
  optionsList,
  onChange,
  infoList,
  columnCount = null,
}) => {
  const [listModal, setListModal] = useState(infoList || []);
  const [loading, setLoading] = useState(false);
  const { options, changeOptions } = useChangeOptions({
    select: {
      options: [],
      value: [],
    },
  });

  const dispatch = useDispatch();

  // запрос на получение сотрудника для отображения в списке
  const getUser = (_id) => {
    dispatch(asyncGetUser(_id)).then((data) => {
      if (data?.payload) {
        setListModal((prev) => [...prev, data?.payload]);
        // console.log(data?.payload);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  };
  // запрос на получение группы
  const getGroup = (_id) => {
    dispatch(asyncGetGroup(_id)).then((data) => {
      if (data?.payload) {
        setListModal((prev) => [...prev, data?.payload]);
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  };

  const gridColumn = columnCount ? `repeat(${columnCount}, 1fr)` : `repeat(auto-fill, minmax(150px, 1fr))`;

  const handelAddList = (data) => {
    const { value } = data;
    const newArr = [...(options.select?.value || []), data];
    const newSelect = {
      value: newArr,
      options: options.select.options.filter((opt) => opt.value !== value),
    };

    if (type === "group") getGroup(value);
    else if (type.includes("employees")) getUser(value);

    changeOptions("select", "set", newSelect);
    onChange && onChange(newSelect);
  };

  const getLabel = (el) => {
    if (el.label) return el.label;
    if (el.surname) return getFormatFIO(el);
    return el.name || "Элемент";
  };

  // удаление
  const deleteItem = (data) => {
    // console.log(options.select.value);
    // console.log(data);
    const { _id } = data;
    const newArr = options.select.value.filter((opt) => opt.value !== _id);
    // console.log("newArr", newArr);
    setListModal(listModal.filter((opt) => opt._id !== _id)); // обновляем список при удалении
    const newSelect = {
      value: newArr,
      options: [...options.select.options, { label: getLabel(data), value: data.value ? data.value : data._id }],
    };
    // console.log("newSelect удаленные", newSelect);
    changeOptions("select", "set", newSelect);
    onChange && onChange(newSelect);
  };

  //генерация списка добавленных
  useEffect(() => {
    // console.log("генерация списка добавленных", infoList);
    let info_for_select = [];
    info_for_select = infoList?.map((el, index) => {
      if (typeof el !== "object") return { label: "Элемент", value: index };
      // console.log(el);
      return { label: el.label, value: el.value };
    });
    // console.log("infoList info_for_select", info_for_select);
    changeOptions("select", "value", info_for_select);
  }, [infoList]);

  //генерация списка опций
  useEffect(() => {
    if (!optionsList || !optionsList?.length) return;
    let info_for_select = [];

    info_for_select = optionsList.map((el, index) => {
      // console.log("el", el);
      if (typeof el !== "object") return { label: "Элемент", value: index };
      return { label: el.label, value: el.value };
    });
    // console.log("optionsList info_for_select", info_for_select);
    changeOptions("select", "options", info_for_select);
  }, [optionsList]);

  // высота списка селекта
  const heightSelectOptions = (value) => {
    // console.log("options", options);
    // console.log("value", value);
    if (value.length <= 3) {
      return 80;
    } else if (value.length > 3 && value.length <= 6) {
      return 120;
    } else if (value.length > 6) {
      return 200;
    }
  };

  return (
    <div className="listModal">
      {!!title && <h4 className="listModal__title">{title}</h4>}
      {edit && (
        <div className="listModal__select" style={{ width: widthSelect }}>
          <Select
            options={options.select.options}
            value={null}
            handleChange={(value) => handelAddList(value)}
            placeholder={placeholder}
            title={titleSelect}
            forInput
            menuPlacement={"top"}
            // maxMenuHeight={heightSelectOptions(listModal)}
            maxMenuHeight={200}
          />
        </div>
      )}
      {listModal?.length > 0 && (
        <ul className={`listModal__list customScroll `} style={{ gridTemplateColumns: gridColumn }}>
          {listModal.map((elem) => (
            <ListModalItem
              onClickInfo={onClickInfo}
              handelClick={() => deleteItem(elem)}
              key={elem._id}
              info={elem}
              edit={edit}
              type={type}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListModal;
