import React, { useEffect } from "react";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import "./DocumentationProjectMobile.scss";
import { useState } from "react";
import Pagination from "../../../../../components/Pagination/Pagination";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import ModalAddDoc from "../../../../../components/ModalAddDoc/ModalAddDoc";
import ModalAddToFolder from "../../../../../components/ModalAddToFolder/ModalAddToFolder";
import ModalAddFolder from "../../../../../components/ModalAddFolder/ModalAddFolder";
import { useSelector } from "react-redux";
import ModalInfoEditFolder from "../../../../../components/ModalInfoEditFolder/ModalInfoEditFolder";
import { getPageCount } from "../../../../../utils/getPageCount";
import { useDispatch } from "react-redux";
import { asyncDeleteFolder, asyncGetFolders } from "../../../../../store/reducers/projectsReducer";
import axios from "axios";
import { toast } from "react-toastify";
import DocumentationList from "../../../../../components/DocumentationList/DocumentationList";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import { useModal } from "../../../../../hooks/useModal";
import { useSearch } from "../../../../../hooks/useSearch";
import ModalMobileMenu from "../../../../../components/ModalMobileMenu/ModalMobileMenu";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";
import { getTypePages } from "../../../../../utils/getTypePages";

const DocumentationProjectMobile = ({ viewCards }) => {
  const project = useSelector((state) => state.projects.project);
  const { folder } = project;

  const dispatch = useDispatch();

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { search, setQueryParam, queries } = useSearch("project:documentation");

  const { visible, types = [] } = useHasPermissions("project:documentation", project);

  const [headFolder, setHeadFolder] = useState(null);

  const [fatherFolder, setFatherFolder] = useState(null);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");

  const [visibleHeader, setVisibleHeader] = useState(false);

  // * получаем папку
  const getFolder = async () => {
    if (!queries.father) return;
    search();
  };

  const getFolderById = async () => {
    const folderId = queries.father;
    const res = await dispatch(asyncGetFolder({ folderId }));
    if (res.error) return;

    setFatherFolder(res.payload);
    setHeadFolder(res.payload);

    return res.payload._id;
  };

  const deleteFolder = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteFolder", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteFolder", false, null);
  };

  const deleteDocument = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteDocument", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteDocument", false, null);
  };

  const changeActiveNavSearch = (tab) => {
    setQueryParam({ tab });
  };

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    (async () => {
      let father = folder._id;

      if (queries.father) {
        const id = await getFolderById();
        if (id) father = id;
      } else {
        setFatherFolder(folder);
        setHeadFolder(folder);
      }

      setQueryParam({ father });
    })();
  }, []);

  useEffect(() => {
    if (fatherFolder?.main) {
      setHeadFolder(fatherFolder);
    }
  }, [fatherFolder]);

  return (
    <>
      <div className="documentationProjectMobile">
        <div className="companiesMobile-header">
          <NavigationListMobile
            activeNav={activeSearchNav}
            listBtn={{
              visible: visible,
              type: "typePages",
            }}
            typePages={getTypePages(types)}
          />
        </div>

        <DocumentationList
          viewCards={"column"}
          setFatherFolder={setFatherFolder}
          fatherFolder={fatherFolder}
          visibleHeader={visibleHeader}
          setVisibleHeader={setVisibleHeader}
        />

        {getVisible("menu") && <ModalMobileMenu chapter={"documentation"} nameModal={"menu"} />}
        {getVisible("menuAddDocumenattion") && (
          <ModalMobileMenu
            chapter={"menuAddDocumenattion"}
            nameModal={"menuAddDocumenattion"}
            onClose={() => changeModalVisible("menuAddDocumenattion", false)}
          />
        )}
        {/* Добавление документа */}
        {getVisible("addDocument") && (
          <ModalAddDoc
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Добавление папки */}
        {getVisible("addFolder") && (
          <ModalAddFolder
            handleChange={getFolder}
            fatherFolder={fatherFolder}
            headFolder={headFolder}
          />
        )}

        {/* Просмотр/реадктирование папки */}
        {getVisible("infoFolder") && (
          <ModalInfoEditFolder
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Редактирование файла */}
        {getVisible("infoFile") && (
          <ModalInfoEditFile
            fatherFolder={fatherFolder}
            handleChange={getFolder}
            headFolder={headFolder}
          />
        )}

        {/* Добавление в папку */}
        {getVisible("addToFolder") && (
          <ModalAddToFolder
            headFolder={headFolder}
            fatherFolder={fatherFolder}
            handleChange={getFolder}
          />
        )}

        {/* Удаление документа */}
        {getVisible("deleteDocument") && (
          <ModalDeleteItem
            headerTitle="Удалить документ"
            title="Вы уверены, что хотите удалить этот документ?"
            nameModal="deleteDocument"
            onDelete={deleteDocument}
          />
        )}

        {/* Просмотр документов*/}
        {getVisible("viewDoc") && <ModalViewDoc />}

        {/* Удаление папки */}
        {getVisible("deleteFolder") && (
          <ModalDeleteItem
            headerTitle="Удалить папку"
            title="Вы уверены, что хотите удалить эту папку?"
            nameModal="deleteFolder"
            onDelete={deleteFolder}
          />
        )}
      </div>
    </>
  );
};

export default DocumentationProjectMobile;
