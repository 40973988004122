import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { getLimitElements } from "../../utils/getLimitElements";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { toast } from "react-toastify";

export const asyncGetReferences = createAsyncThunk(
  "references/asyncGetReferences",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitElements();
      const res = await axios.post(`/references/get/${data.page}/?limit=${limit}`, data.params);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateReferences = createAsyncThunk(
  "references/asyncCreateReferences",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/references/create`, data);
      console.log(res.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditReferences = createAsyncThunk(
  "references/asyncEditReferences",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/references/edit/${data.referencesId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncDeleteReferences = createAsyncThunk(
  "references/asyncDeleteReferences",
  async (data, helpers) => {
    const reference_ids = data;
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/references/delete`, {
        data: { reference_ids },
      });
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const referencesReducer = createSlice({
  name: "references",
  initialState: {
    references: [],
    count: 0,
    edit: false,
    loading: false,
    error: false,
  },
  reducers: {
    set_references: (state, action) => {
      const { references } = action.payload;
      state.references = references;
    },
    set_edit_reference_mode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetReferences.fulfilled]: (state, action) => {
      const { references } = action.payload;
      state.references = references;
      const { count } = action.payload;
      state.count = count;
    },
    [asyncGetReferences.rejected]: "Не удалось получить справки",
    [asyncCreateReferences.fulfilled]: "Справка создана",
    [asyncCreateReferences.rejected]: "Не удалось создать справку.",
    [asyncEditReferences.fulfilled]: () => toast.success("Справка отредактирована"),
    [asyncEditReferences.rejected]: "Не удалось отредактировать справку.",
    [asyncDeleteReferences.fulfilled]: () => toast.success("Справка удалена"),
    [asyncDeleteReferences.rejected]: "Не удалось удалить справку.",
  }),
});

export const { set_reference, set_edit_reference_mode } = referencesReducer.actions;

export default referencesReducer.reducer;
