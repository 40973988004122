export const transformRole = (role) => {
  if (!role) return "";
  switch (role) {
    case "admin":
      return "Администратор";
    case "director":
      return "Директор";
    case "customer":
      return "Заказчик";
    case "executor":
      return "Исполнитель";
    case "user":
      return "Пользователь";
    default:
      break;
  }
};
