import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  asyncGetUnReadMessages,
  setUnreadMessageChat,
} from "../../../../store/reducers/chatReduser";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { getFullPath } from "../../../../utils/getFullPath";
import "./ChatListItem.scss";

const ChatListItem = (props) => {
  const { info, myId, onClick, currentChatId } = props;

  const { _id, name, isPersonal, activeUserList, userList, lastMessage, unreadMessageCount } = info;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getTitle = () => {
    if (isPersonal) {
      const interlocutor = userList.find((user) => user._id !== myId);
      return getFormatFIO(interlocutor);
    }
    return name;
  };

  const getLogo = () => {
    if (isPersonal) {
      const interlocutor = userList.find((user) => user._id !== myId);
      return getFullPath(interlocutor?.avatar, "avatar");
    }
    return {
      list: activeUserList.map((u) => getFullPath(u.avatar, "avatar")),
      count: activeUserList.length - 2,
    };
  };

  const isLeaveChat = () => {
    return !!!activeUserList.find((u) => u._id === myId);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await dispatch(asyncGetUnReadMessages(_id));
      setLoading(false);
      if (res.error) return;
      dispatch(setUnreadMessageChat({ chatId: _id, count: res.payload.unreadCount }));
    })();
  }, [lastMessage]);

  return (
    <div onClick={onClick} className={`chatListItem ${_id === currentChatId ? "active" : ""}`}>
      <div className="chatListItem-info">
        <div className="chatListItem-info-logo">
          {getLogo()?.list ? (
            <div className="chatListItem-info-logo-list">
              <div
                style={{ backgroundImage: `url(${getLogo().list[0]})` }}
                className="chatListItem-info-logo-img"
              ></div>
              {getLogo().list.length > 1 && (
                <div
                  style={{ backgroundImage: `url(${getLogo().list[1]})` }}
                  className="chatListItem-info-logo-img"
                ></div>
              )}
              {getLogo().list.length > 2 && (
                <div className="chatListItem-info-logo-list-countMembers">
                  <span>+ {getLogo().count}</span>
                </div>
              )}
            </div>
          ) : (
            <div className="chatListItem-info-logo-member">
              <div
                style={{ backgroundImage: `url(${getLogo()})` }}
                className="chatListItem-info-logo-img"
              ></div>
            </div>
          )}
        </div>
        <div className={`chatListItem-info-title ${isPersonal ? "personal" : "group"}`}>
          <div className="chatListItem-info-title-wrapper">
            <span className="chatListItem-info-title-name">
              {getTitle()}{" "}
              {isLeaveChat() && (
                <span className="chatListItem-info-title-name-sub"> (Покинутый)</span>
              )}
            </span>
          </div>
          {lastMessage && (
            <span className="chatListItem-info-title-lastMassage">{lastMessage?.text || ""}</span>
          )}
        </div>
      </div>
      {loading ? (
        <div className="submit-spinner-lm"></div>
      ) : (
        <>
          {!!unreadMessageCount && (
            <div className="chatListItem-newMassageCount">
              <span>{unreadMessageCount}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatListItem;
