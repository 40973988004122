import React from "react";
import "./NavbarMobile.scss";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Icons from "../../../../../Icons/Icons";
import { useModal } from "../../../../../../hooks/useModal";
import { logout } from "../../../../../../store/reducers/userReducer";
import { useDispatch } from "react-redux";
import { useSearch } from "../../../../../../hooks/useSearch";
import { useSelector } from "react-redux";

const availability = {
  admin: [],
  customer: [
    "projects",
    "tasks",
    "calendar",
    "employees",
    "reference",
    "profile",
    "documentation",
    "settings",
  ],
  executor: [],
};

function NavbarMobileItem(props) {
  const { availability, info, currentPage, setActiveMenu } = props;
  const { name, path } = info;

  const { goToPage } = useSearch();

  const dispatch = useDispatch();

  const projectInfo = useSelector((state) => state.projects.project);

  const { changeModalVisible } = useModal();

  if (
    !availability.includes(path) &&
    path !== "exit" &&
    availability.length !== 0
  )
    return;

  const clearProject = () => {
    if (projectInfo && name !== "Проекты") {
      dispatch(setProject(null));
    }
  };

  return (
    // <NavLink
    //   onClick={(e) => {
    //     e.preventDefault();

    //     goToPage(path);
    //     changeModalVisible("clear");
    //     clearProject();
    //   }}
    //   className={({ isActive }) =>
    //     isActive
    //       ? "navbarMobile-link navbarMobile-link-active"
    //       : "navbarMobile-link"
    //   }
    //   exact
    //   to={path}
    // >
    //   <div className="navbarMobile-link-icon">
    //     {/* <Icons nameIcon={path} /> */}
    //     <Icons
    //       type={info.path.match(/[a-z\s]+/giu).join("")}
    //       size={"m-xl"}
    //       color={"black"}
    //       className={"header-icon"}
    //     />
    //   </div>
    //   <span
    //     className={`navbarMobile-link-title ${
    //       info.path.includes(currentPage) ? "text-black" : ""
    //     }`}
    //   >
    //     {info.name}
    //   </span>
    // </NavLink>
    <>
      <div
        onClick={() => {
          goToPage(path);
          setActiveMenu(false);
          clearProject();
          changeModalVisible("clear");
        }}
        className={`navbarMobile-link ${
          !currentPage?.indexOf(path) ? "navbarMobile-link-active" : ""
        }`}
      >
        <div className="navbarMobile-link-icon">
          {/* <Icons nameIcon={path} /> */}
          <Icons
            type={path.match(/[a-z\s]+/giu).join("")}
            size={"m-xl"}
            color={!currentPage?.indexOf(path) ? "white" : "black"}
            // className={"header-icon"}
          />
        </div>
        <span
          className={`navbarMobile-link-title ${
            !currentPage?.indexOf(path) ? "text-black" : ""
          }`}
        >
          {name}
        </span>
      </div>
    </>
  );
}

const NavbarMobile = ({ currentPage, linkPage, activeMenu, setActiveMenu }) => {
  const role = "admin";
  return (
    <div className={`navbarMobile ${activeMenu ? "navbarMobile-active" : ""}`}>
      <div className="navbarMobile-container">
        <div className="navbarMobile-container-list">
          {linkPage.map((link, i) => (
            <NavbarMobileItem
              availability={availability[role]}
              info={link}
              key={link.name}
              currentPage={currentPage}
              setActiveMenu={setActiveMenu}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;

// const NavbarMobile = ({
//   currentPage,
//   linkPage,
//   activeMenu,
//   availability,
//   setActiveMenu,
// }) => {
//   const navigate = useNavigate();
//   const { changeModalVisible } = useModal();
//   const dispatch = useDispatch();
//   const goToPage = (path) => {
//     // console.log(path.match(/[a-z\s]+/giu).join(""));
//     navigate(`/${path}`);
//     changeModalVisible("clear");
//   };

//   // if (
//   //   !availability.includes(path) &&
//   //   path !== "exit" &&
//   //   availability.length !== 0
//   // )
//   //   return;
//   return (
//     <div className={`navbarMobile ${activeMenu ? "navbarMobile-active" : ""}`}>
//       <div className="navbarMobile-container">
//         <div className="navbarMobile-container-list">
//           {linkPage.map((link, i) => (
//             <div
//               key={i}
//               onClick={
//                 link.toClick ||
//                 (() => {
//                   goToPage(link.path);
//                   setActiveMenu(false);
//                 })
//               }
//               className={`navbarMobile-link ${
//                 link.path.includes(currentPage)
//                   ? "navbarMobile-link-active"
//                   : ""
//               }`}
//             >
//               <div className="navbarMobile-link-icon">
//                 {/* <Icons nameIcon={path} /> */}
//                 <Icons
//                   type={link.path.match(/[a-z\s]+/giu).join("")}
//                   size={"m-xl"}
//                   color={link.path.includes(currentPage) ? "white" : "black"}
//                   // className={"header-icon"}
//                 />
//               </div>
//               <span
//                 className={`navbarMobile-link-title ${
//                   link.path.includes(currentPage) ? "text-black" : ""
//                 }`}
//               >
//                 {link.name}
//               </span>
//             </div>
//           ))}
//         </div>

//         <div onClick={() => dispatch(logout())} className={`navbarMobile-link`}>
//           <div className="navbarMobile-link-icon">
//             {/* <Icons nameIcon={path} /> */}
//             <Icons
//               type={"exit"}
//               size={"m-xl"}
//               color={"black"}
//               // className={"header-icon"}
//             />
//           </div>
//           <span className={`navbarMobile-link-title `}>Выйти</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NavbarMobile;
