import React from "react";
import { useSelector } from "react-redux";
import { useSearch } from "../../hooks/useSearch";
import ProjectsList from "../ProjectsList/ProjectsList";
import EmptySection from "../EmptySection/EmptySection";
import "./CompanyProject.scss";
import SearchPanel from "../SearchPanel/SearchPanel";

const CompanyProject = () => {
  const { projects, count, loading } = useSelector((state) => state.projects);
  const { queries } = useSearch("company:projects");

  return (
    <div className="companyProject">
      <div className="companyProject-search">
        <SearchPanel
          inputSearch={{
            visible: true,
            placeholder: "Найти проект по названию или номеру",
          }}
        />
      </div>

      {/* <div className="companyProject-list "> */}
      {projects.length > 0 ? (
        <ProjectsList projects={projects} count={count} />
      ) : (
        <EmptySection
          text={"Проектов не создано"}
          // textBtn={"Добавить"}
          // onClick={() => {
          //   changeModalVisible("addProject", true);
          // }}
        />
      )}
      {/* </div> */}
    </div>
  );
};

export default CompanyProject;
