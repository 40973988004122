import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import Pagination from "../../../../../components/Pagination/Pagination";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { useModal } from "../../../../../hooks/useModal";
import { useSelects } from "../../../../../hooks/useSelects";
import "./Companies.scss";
import CardRowCompanies from "./components/CardRowCompanies/CardRowCompanies";
import ModalAddCompany from "./components/ModalAddCompany/ModalAddCompany";
import ModalInfoEditCompany from "../../../../../components/ModalInfoEditCompany/ModalInfoEditCompany";
import ModalParticipant from "../../../../../components/ModalParticipants/ModalParticipant";
import { useFilter } from "../../../../../hooks/useFilter";
import {
  asyncDeleteCompaniesFromProject,
  asyncGetProjectCompanies,
} from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import { getPageCount } from "../../../../../utils/getPageCount";
import CompaniesGridCard from "../../../../../components/CompaniesGridCard/CompaniesGridCard";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";

function Companies(props) {
  const { viewChapterCompanies } = props;

  const dispatch = useDispatch();

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const projects = useSelector((state) => state.projects);
  const { project_companies, project } = projects;

  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState(0);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(companies);

  const { setQueryParam, queries, params, search } = useSearch("project:companies");

  const { visible, types = [] } = useHasPermissions("project:companies", project);

  const numberPage = params.page || 1;

  const deleteCompaniesFromProject = async () => {
    let companiesForDelete = [];
    if (!!selectItems.length) {
      companiesForDelete = selectItems;
    } else {
      companiesForDelete.push(getInfo("deleteCompany", "data")._id);
    }

    await dispatch(asyncDeleteCompaniesFromProject(companiesForDelete));

    changeModalVisible("deleteCompany");
    clearItems();
    search();
  };

  useEffect(() => {
    setCompanies(project_companies?.companies || []);
    setCount(project_companies?.count || 0);
  }, [project_companies]);

  return (
    <>
      {getVisible() && <div className="bgc-shadow"></div>}
      <div className="companies">
        <div className="companies-searchPanel">
          <SearchPanel
            navigationLeft={{
              visible: true,
            }}
            buttons={{
              list: [
                {
                  title: "Добавить",
                  onClick: () => changeModalVisible("addCompanyToProject"),
                  // icon: true,
                },
              ],
            }}
            inputSearch={{
              visible: true,
              placeholder: "Найти компанию по названию",
            }}
          />
        </div>
        <WrapperLoader
          loading={projects.loading}
          chapter={"companies-project"}
          viewCards={viewChapterCompanies}
        >
          {companies.length ? (
            <>
              {viewChapterCompanies === "rows" && (
                <div className="companies-cards">
                  <div className="companies-cards-header">
                    {/* <div className="companies-cards-header-item number">
                      <Checkbox
                        onCheck={addAllItemSelect}
                        check={selectItems.length === companies.length}
                      />
                      <span>№:</span>
                    </div> */}
                    <div className="companies-cards-header-item name">
                      <span title="Название компании:">Название компании:</span>
                    </div>
                    <div className="companies-cards-header-item numberDocument">
                      <span title="ФИО руководителя:">ФИО руководителя:</span>
                    </div>
                    <div className="companies-cards-header-item dataStart">
                      <span title="Город:">Город:</span>
                    </div>
                    <div className="companies-cards-header-item dataEnd">
                      <span title="Email:">Email:</span>
                    </div>
                    <div className="companies-cards-header-item country">
                      <span title="Телефон:">Телефон:</span>
                    </div>
                    <div className="companies-cards-header-item city">
                      <span title="Кол-во сотрудников:">Кол-во сотрудников:</span>
                    </div>
                    <div className="companies-cards-header-item address">
                      <span title="Тип:">Тип:</span>
                    </div>
                    <div className="companies-cards-header-item btns"></div>
                  </div>
                  <div className="companies-cards-content">
                    {companies.map((company, i) => (
                      <CardRowCompanies
                        changeModalVisible={changeModalVisible}
                        key={company._id}
                        number={i + 1 + companies.length * numberPage - companies.length}
                        info={company}
                        check={selectItems.includes(company._id)}
                        addItemSelect={addItemSelect}
                      />
                    ))}
                  </div>
                </div>
              )}
              {viewChapterCompanies === "columns" && (
                <div className="companies-cardsColumns">
                  {companies.map((company, i) => (
                    <CompaniesGridCard
                      changeModalVisible={changeModalVisible}
                      key={company._id}
                      info={company}
                      check={selectItems.includes(company._id)}
                      addItemSelect={addItemSelect}
                      project={true}
                    />
                  ))}
                </div>
              )}

              {count > 10 && (
                <div className="companies-pagination">
                  <Pagination count={count} />
                </div>
              )}
            </>
          ) : (
            <EmptySection
              text={"В данном проекте ещё нет компаний"}
              textBtn={"Добавить"}
              onClick={() => {
                changeModalVisible("addCompany", true);
              }}
            />
          )}
        </WrapperLoader>
        {/* Информация/реактировать компанию */}
        {getVisible("infoCompany") && <ModalInfoEditCompany />}
        {/* Информация/редактирование участника */}
        {getVisible("infoParticipant") && (
          <ModalParticipant
            info={getInfo("infoParticipant", "data")}
            edit={false}
            changeModalVisible={changeModalVisible}
          />
        )}
        {/* Добавить компанию */}
        {getVisible("addCompanyToProject") && (
          <ModalAddCompany changeModalVisible={changeModalVisible} />
        )}
        {/* Удалить компанию */}
        {getVisible("deleteCompany") && (
          <ModalDeleteItem
            onDelete={deleteCompaniesFromProject}
            changeModalVisible={() => changeModalVisible("deleteCompany")}
            modalName="deleteCompany"
            haderTitle="Удалить компанию"
            title="Вы уверены, что хотите удалить эту компанию?"
          />
        )}
      </div>
    </>
  );
}

export default Companies;
