import { createSlice } from "@reduxjs/toolkit";

const filterReducer = createSlice({
  name: "filter",
  initialState: {
    options: {
      employee: null,
      region: null,
      project: null,
      company: null,
      period: [null, null],
      status: null,
      roomType: null,
      author: null,
      executor: null,
      type: null,
      dateStart: null,
      deadLine: null,
      tasks: null,
      priority: null,
      chapter: null,
      regulations: null,
      participant: null,
      post: null,
      position: null,
      role: null,
      protocol: null,
      director: null,
      start: null,
      end: null,

      companyName: null,
      groupName: null,
      userFio: null,
      taskName: null,

      fromGenCompany: false,
      fromCustomer: false,
      fromExecutor: false,
    },
  },
  reducers: {
    set_options: (state, action) => {
      const { name, value } = action.payload;
      state.options[name] = value;
    },
    clear_options: (state) => {
      const clearOptions = {};
      for (const key in state.options) {
        clearOptions[key] = null;
      }
      state.options = clearOptions;
    },
  },
});

export const { set_options, clear_options } = filterReducer.actions;

export default filterReducer.reducer;
