import React, { useId } from "react";
import "./ModalAddCompany.scss";
import ImageUpload from "../ImageUpload/ImageUpload";
import Input from "../Input/Input";
import { useChangeIV } from "../../hooks/useChangeIV";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Button from "../Button/Button";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { asyncGetCompanyByInn, asyncCreateCompany } from "../../store/reducers/companiesReducer";
import { useDispatch } from "react-redux";
import InputSearch from "../InputSearch/InputSearch";
import SelectMulti from "../SelectMulti/SelectMulti";
import { useState } from "react";
import { toast } from "react-toastify";
import UserRowModal from "../UserRowModal/UserRowModal";
import axios from "../../axios/axios";
import { transformForSelect } from "../../utils/transformForSelect";
import Icons from "../Icons/Icons";
import Screen from "../Screen/Screen";
import { useModal } from "../../hooks/useModal";

const ModalAddCompany = ({ handleChange }) => {
  const formId = useId();

  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const [loading, setLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    nameСompany: "",
    innСompany: "",
    emailСompany: "",
    phoneСompany: "",
    logo: null,
  });

  const [profiles, setProfiles] = useState([
    {
      index: 1,
      user: null,
      position: null,
    },
  ]);

  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    typeCompanies: "default",
    region: "default",
    position: "default",
  });

  const addProfile = () => {
    setProfiles((prev) => [
      ...prev,
      {
        index: Math.random() * 1000,
        user: null,
        position: null,
      },
    ]);
  };

  const removeProfile = (index) => {
    setProfiles((prev) => {
      return prev.filter((p) => p.index !== index);
    });
  };

  const changeProfile = (index, field, data) => {
    setProfiles((prev) => {
      return prev.map((profile) => {
        if (profile.index === index) {
          return {
            ...profile,
            [field]: data,
          };
        }
        return profile;
      });
    });
  };

  const getRegionByName = async (name) => {
    const res = await axios.post(`/regions/get_by_name/${name}`);

    if (!res.data) return;

    if (!res.data.length) return toast.warn("Город, который указан в данных по ИНН - не найден");

    changeOptions("region", "value", transformForSelect(res.data[0]));
  };

  const findByInn = async () => {
    setLoading(true);
    const res = await dispatch(asyncGetCompanyByInn(IV.innСompany));
    setLoading(false);

    if (res.error) return;

    const suggestions = res.payload;

    if (!suggestions.length) {
      return toast.error("По введенному ИНН компания - не найдена");
    }

    const dataCompany = suggestions[0];

    if (dataCompany?.data?.name?.full_with_opf) {
      changeIV("set", {
        name: "nameСompany",
        value: dataCompany.data.name.full_with_opf,
      });
    }
    if (dataCompany?.data?.phones?.length) {
      changeIV("set", {
        name: "phoneСompany",
        value: dataCompany.data.phones[0],
      });
    }
    if (dataCompany?.data?.emails?.length) {
      changeIV("set", {
        name: "emailСompany",
        value: dataCompany.data.emails[0],
      });
    }

    if (dataCompany?.data?.address.data.region) {
      await getRegionByName(dataCompany.data.address.data.region);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const checkName = checkValidateIV("nameСompany", {
      minHeight: 3,
      maxHeight: 250,
    });
    const checkInn = checkValidateIV("innСompany", { minHeight: 10, maxHeight: 12 });
    const checkEmail = checkValidateIV("emailСompany", { email: true });
    const checkPhone = checkValidateIV("phoneСompany", { tel: true });

    const checkRegion = checkValidateOptions("region", { required: true });
    const checkType = checkValidateOptions("typeCompanies", { required: true });

    if (!checkName || !checkEmail || !checkInn || !checkPhone || !checkRegion || !checkType) return;

    // * проверка profiles
    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];
      if (!profile.user || !profile.position) {
        return toast.error("Заполните все поля сотрудника");
      }
    }

    const formData = new FormData();
    formData.append("name", IV.nameСompany);
    formData.append("inn", IV.innСompany);
    formData.append("phone", IV.phoneСompany);
    formData.append("email", IV.emailСompany);
    formData.append("region", options.region.value.value);
    formData.append("type", JSON.stringify(options.typeCompanies.value.map((t) => t.value)));

    formData.append(
      "profiles",
      JSON.stringify(
        profiles.map((p) => ({
          user: p.user.value,
          position: p.position.value,
        })),
      ),
    );

    if (IV.logo && IV.logo !== "remove") formData.append("logo", IV.logo);
    setLoading(true);
    const res = await dispatch(asyncCreateCompany(formData));
    setLoading(false);
    if (res.error) return;
    handleChange && handleChange("employees:companies");
    changeModalVisible("addCompany", false);
  };

  return (
    <ModalWrapper
      title={"Добавить компанию"}
      onClose={() => changeModalVisible("addCompany", false)}
      btns={
        <div className="modalAddCompany-btn-wrapper">
          <Button type="submit" title="Создать" color="blue" form={formId} />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modalAddCompany" id={formId}>
        <div className="modalAddCompany-content">
          <h4 className="modalAddCompany-content-title">Основная информация</h4>
          <div className="modalAddCompany-content-baseInfo">
            <div className="modalAddCompany-content-wrapper">
              <div className="modalAddCompany-content-info-company">
                <Input
                  onChange={changeIV}
                  value={IV.nameСompany}
                  name="nameСompany"
                  title="Название компаниии:*"
                  placeholder="Введите название компании"
                  validate={checkValidateIV("nameСompany")}
                />
              </div>
              <ul className="modalAddCompany-content-info modalAddCompany-content-info">
                <div className="d-flex gap-10 align-end">
                  <li className="modalAddCompany-content-info-item">
                    <div className="modalAddCompany-content-info-item-inn">
                      <Input
                        onChange={changeIV}
                        value={IV.innСompany}
                        name="innСompany"
                        title="ИНН компаниии:*"
                        placeholder="Введите ИНН"
                        validate={checkValidateIV("innСompany")}
                        icon={true}
                      />
                      {IV.innСompany.length >= 10 && (
                        <div className="modalAddCompany-content-info-item-inn-btn">
                          <Icons
                            type={"search"}
                            size={"md"}
                            cursor
                            color={"black"}
                            title={{
                              visible: true,
                              text: "Найти компанию по ИНН",
                            }}
                            onClick={findByInn}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                </div>
                <li className="modalAddCompany-content-info-item">
                  <Input
                    onChange={changeIV}
                    value={IV.emailСompany}
                    name="emailСompany"
                    title="Email:*"
                    placeholder="Введите email"
                    validate={checkValidateIV("emailСompany")}
                  />
                </li>

                <li className="modalAddCompany-content-info-item">
                  <Input
                    onChange={changeIV}
                    value={IV.phoneСompany}
                    name="phoneСompany"
                    title="Телефон:*"
                    type="tel"
                    placeholder="+ 7 000 000 00 00"
                    validate={checkValidateIV("phoneСompany")}
                  />
                </li>

                <li className="modalAddCompany-content-info-item">
                  <InputSearch
                    forInput={true}
                    type="region"
                    name="region"
                    placeholder="Введите город"
                    title="Город:*"
                    handleChange={(data) => {
                      changeOptions("region", "value", data);
                    }}
                    value={options.region.value}
                    placeholderSelect="Выберите город"
                    validate={checkValidateOptions("region")}
                  />
                </li>
                <li className="modalAddCompany-content-info-item">
                  <SelectMulti
                    options={options.typeCompanies.options}
                    value={options.typeCompanies.value}
                    handleChange={(data) => changeOptions("typeCompanies", "value", data)}
                    placeholder="Выберите тип"
                    title="Тип:*"
                    forInput
                    validate={checkValidateOptions("typeCompanies")}
                  />
                </li>
              </ul>
            </div>
            <div className="modalAddCompany-content-avatar">
              <span className="modalAddCompany-content-info-title">Логотип:</span>
              <div className="modalAddCompany-content-info-img">
                <ImageUpload onChange={(value) => changeIV("set", { name: "logo", value })} />
              </div>
            </div>
          </div>
        </div>
        <div className="hr"></div>
        <div className="modalAddCompany-content">
          <h4 className="modalAddCompany-content-title">Сотрудники</h4>
          <div className="modalAddCompany-content">
            <div className="modalAddCompany-employee-list">
              <div className="modalAddCompany-employee-wrapper">
                {profiles.map((profile, i) => (
                  <UserRowModal
                    info={profile}
                    changeProfile={changeProfile}
                    optionsPosition={options.position.options}
                    removeProfile={() => removeProfile(profile.index)}
                    disabledUsers={false}
                    disabledPositions={false}
                    visibleRemove={false}
                    key={profile?.index || i}
                  />
                ))}
              </div>
            </div>{" "}
            <Button
              onClick={addProfile}
              title="Сотрудник"
              color="dark"
              icon={{ position: "left" }}
            />
          </div>
        </div>
        <Screen size={"lg"}>
          <div className="modalAddCompany-btn-wrapper">
            <Button type="submit" title="Создать" color="blue" form={formId} loading={loading} />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddCompany;
