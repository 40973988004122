import React, { useState } from "react";
import "./ListWorks.scss";
import Icons from "../Icons/Icons";
import { useDispatch } from "react-redux";
import { asyncGetWork } from "../../store/reducers/projectsReducer";

const ListWorks = ({
  works = [],
  changeModalVisible,
  editMode = false,
  title,
  changeRelationshipTasks,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getColorStatus = (number, text = false) => {
    if (text) {
      if (number < 0) return "Просрочено";
      if (number >= 0 && number < 3) return "Срок до окончания — 3 дня";
      if (number > 0) return "Выполнено";
      return "";
    }
    if (number < 0) return "red";
    if (number >= 0 && number < 3) return "yellow";
    if (number > 0) return "green";
    return "";
  };

  const onInfo = (workId) => {
    setLoading(true);
    dispatch(asyncGetWork(workId))
      .then((res) => {
        if (res.payload?._id) {
          changeModalVisible("infoWork", true, res.payload);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="listWorks">
        {title && <span className="listWorks-title">{title}</span>}

        <div className="listWorks-wrapper">
          <ul className="listWorks-list customScroll">
            {works?.map((work) => (
              <li className="listWorks-item" key={work.value}>
                <span className="listWorks-item-name bold" title={work.name || work.label}>
                  {work.name || work.label}
                </span>
                <div className="listWorks-item-btns">
                  {editMode ? (
                    <>
                      {/* <Icons
                        type={"edit"}
                        size={"md"}
                        cursor
                        onClick={() =>
                          changeModalVisible("work", true, work, true)
                        }
                      /> */}
                      <Icons
                        type={"remove"}
                        size={"md"}
                        cursor
                        onClick={() => changeRelationshipTasks(work, "remove")}
                        title={{
                          visible: true,
                          text: "",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {loading ? (
                        "..."
                      ) : (
                        <Icons
                          type={"infoItem"}
                          size={"md"}
                          cursor
                          onClick={() => onInfo(work._id)}
                          title={{
                            visible: true,
                            text: "",
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListWorks;
