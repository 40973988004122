import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./ProjectWorkScheduleMobile.scss";
// import ModalAddWorkScheldule from "./components/ModalAddWorkScheldule/ModalAddWorkScheldule";
import ModalChooseAddStepWorkMobile from "./components/ModalChooseAddStepWorkMobile/ModalChooseAddStepWorkMobile";
import ModalStage from "../../../../../components/ModalStage/ModalStage";
import ModalWork from "../../../../../components/ModalWork/ModalWork";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import { useSelects } from "../../../../../hooks/useSelects";
// import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
// import Checkbox from "../../../../../components/Checkbox/Checkbox";
// import CardRowWorkScheduler from "./components/CardRowWorkScheduler/CardRowWorkScheduler";
import ProjectWorkScheduleMobileItem from "./components/ProjectWorkScheduleMobileItem/ProjectWorkScheduleMobileItem";
import Pagination from "../../../../../components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import {
  asyncDeleteScheduleProductionWorks,
  asyncDeleteStage,
  asyncDeleteWork,
  asyncGetWorksAndSteps,
} from "../../../../../store/reducers/projectsReducer";
import WrapperLoader from "../../../../../components/WrapperLoader/WrapperLoader";
import EmptySection from "../../../../../components/EmptySection/EmptySection";
import { useModal } from "../../../../../hooks/useModal";
import NavigationListMobile from "../../../../../components/NavigationListMobile/NavigationListMobile";
import ProjectWorkScheduleMobileCard from "./components/ProjectWorkScheduleMobileCard/ProjectWorkScheduleMobileCard";
import ModalAddWorkScheldule from "../../../../../components/ModalAddWorkScheldule/ModalAddWorkScheldule";

const ProjectWorkScheduleMobile = ({
  setChapterChild,
  getWorkSchedules,
  setProjectWorkScheduleLength,
  viewCards,
}) => {
  const dispatch = useDispatch();

  const { changeModalVisible, getInfo, getVisible } = useModal();

  // const { project_scheduleProductionWorks, loading } = useSelector((state) => state.projects);

  // const [scheduleProductionWorks, setScheduleProductionWorks] = useState([]);
  // const [count, setCount] = useState(0);
  // const [numberPage, setNumberPage] = useState(1);

  // const [tasks, setTasks] = useState([]);

  // const [activeNav, setActiveNav] = useState("customer");
  // const { selectItems, addItemSelect, addAllItemSelect } = useSelects(scheduleProductionWorks);

  // const deleteWorkSchedules = async () => {
  //   let workSchedules_for_delete = [];
  //   if (!!selectItems.length) {
  //     workSchedules_for_delete = selectItems;
  //   } else {
  //     workSchedules_for_delete.push(getInfo("deleteWorkSchedule", "data")._id);
  //   }
  //   await dispatch(asyncDeleteScheduleProductionWorks(workSchedules_for_delete));
  //   changeModalVisible("deleteWorkSchedule", false);
  //   getWorkSchedules(activeNav, numberPage);
  // };

  // const changeActiveNav = (page) => {
  //   setActiveNav(page);
  // };

  // const changeSearchIV = (v) => {
  //   if (v === "") {
  //     setNumberPage(1);
  //     getWorkSchedules(activeNav, numberPage, "");
  //   }
  // };

  // const getWorksAndSteps = () => {
  //   const scheduleWorksId = getInfo("infoWorkSchedule", "data")?._id;
  //   if (!scheduleWorksId) return;
  //   dispatch(asyncGetWorksAndSteps(scheduleWorksId)).then(({ payload }) => {
  //     setTasks(payload || []);
  //   });
  // };

  // const deleteStage = async () => {
  //   const stage_id = getInfo("deleteStage", "data")?._id;
  //   const res = await dispatch(asyncDeleteStage(stage_id));
  //   if (res.error) return;
  //   await getWorksAndSteps();
  //   changeModalVisible("deleteStage", false, null);
  // };

  // const deleteWork = async () => {
  //   const work_id = getInfo("deleteWork", "data")?._id;
  //   const res = await dispatch(asyncDeleteWork(work_id));
  //   if (res.error) return;
  //   await getWorksAndSteps();
  //   changeModalVisible("deleteWork", false, null);
  // };

  // // useEffect(() => {
  // //   if (getVisible("infoWorkSchedule")) {
  // //     return setChapterChild("infoWorkSchedule");
  // //   }
  // // }, [getVisible("infoWorkSchedule")]);

  // useEffect(() => {
  //   setScheduleProductionWorks(project_scheduleProductionWorks?.scheduleWorks || []);
  //   setCount(project_scheduleProductionWorks?.count || 0);
  //   setProjectWorkScheduleLength(project_scheduleProductionWorks?.scheduleWorks.length || 0);
  // }, [project_scheduleProductionWorks]);

  // useEffect(() => {
  //   if (getVisible("infoWorkSchedule")) return;
  //   getWorkSchedules(activeNav, numberPage);
  // }, [numberPage, activeNav, getVisible("infoWorkSchedule")]);

  // useEffect(() => {
  //   getWorksAndSteps();
  // }, [getInfo("infoWorkSchedule", "data")]);

  return (
    <div className="projectWorkScheduleMobile">
      <div className="projectWorkScheduleMobile-content">
        {getVisible("infoWorkSchedule") ? (
          <ProjectWorkScheduleMobileItem
            info={getInfo("infoWorkSchedule", "data")}
            tasks={tasks}
            edit={getInfo("infoWorkSchedule", "editMode")}
            viewCards={viewCards}
          />
        ) : (
          <>
            {/* <NavigationListMobile
              activeNav={activeNav}
              listBtn={{
                list: [
                  {
                    title: "Ген.подрядчика",
                    onClick: () => changeActiveNav("genСontractor"),
                    active: activeNav === "genСontractor" ? true : false,
                    type: "genСontractor",
                  },
                  {
                    title: "Заказчика",
                    onClick: () => changeActiveNav("customer"),
                    active: activeNav === "customer" ? true : false,
                    type: "customer",
                  },
                  {
                    title: "Подрядчика",
                    onClick: () => changeActiveNav("performer"),
                    active: activeNav === "performer" ? true : false,
                    type: "performer",
                  },
                ],
              }}
            />
            <WrapperLoader loading={loading} chapter={"workSchedule"}>
              {scheduleProductionWorks?.length ? (
                <>
                  <div className="projectWorkScheduleMobile-grid">
                    {scheduleProductionWorks.map((workSchedule) => {
                      return <ProjectWorkScheduleMobileCard info={workSchedule} key={workSchedule._id} />;
                    })}
                  </div>
                  <Pagination currentPage={numberPage} count={count} onPageChange={(page) => setNumberPage(page)} />
                </>
              ) : (
                <EmptySection
                  text={"В данном проекте ещё нет графиков"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addWorkScheldule", true);
                  }}
                />
              )}
            </WrapperLoader> */}
            <EmptySection text={"Графики доступны только на десктоп"} />
          </>
        )}
      </div>
      {/* Модальное окно просмотра/редактирование/создания Графика */}
      {getVisible("addWorkScheldule") && (
        <ModalAddWorkScheldule
          getWorkSchedules={getWorkSchedules}
          access={activeNav}
          changeModalVisible={changeModalVisible}
        />
      )}
      {/* Модальное окно выбора создания Этапа или Раборты */}
      {getVisible("chooseStageOrWork") && <ModalChooseAddStepWorkMobile />}
      {/* Модальное окно просмотра/редактирование/создания Этапа */}
      {getVisible("infoStage") && (
        <ModalStage
          info={getInfo("infoStage", "data")}
          changeModalVisible={changeModalVisible}
          scheduleWorksId={getInfo("infoWorkSchedule", "data")?._id}
          getWorksAndSteps={getWorksAndSteps}
          editMode={getInfo("infoStage", "editMode")}
          createMode={getInfo("infoStage", "createMode")}
        />
      )}
      {/* Модальное окно просмотра/редактирование/создания Работы */}
      {getVisible("infoWork") && (
        <ModalWork
          info={getInfo("infoWork", "data")}
          changeModalVisible={changeModalVisible}
          scheduleWorksId={getInfo("infoWorkSchedule", "data")?._id}
          getWorksAndSteps={getWorksAndSteps}
          editMode={getInfo("infoWork", "editMode")}
          createMode={getInfo("infoWork", "createMode")}
        />
      )}
    </div>
  );
};

export default ProjectWorkScheduleMobile;
