import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useChangeOptions = (initialOptions) => {
  const { typeCompanies } = useSelector((state) => state.companies);
  const { positions } = useSelector((state) => state.positions);

  const roles = [
    { label: "Администратор", value: "admin" },
    { label: "Пользователь", value: "user" },
  ];

  const roomType = [
    { label: "Торговое", value: "Торговое" },
    { label: "Жилое", value: "Жилое" },
    { label: "Муниципальное", value: "Муниципальное" },
    { label: "Учебное заведение", value: "Учебное заведение" },
    {
      label: "Дошкольное учебное заведение",
      value: "Дошкольное учебное заведение",
    },
    { label: "Производственное", value: "Производственное" },
    { label: "Офисное", value: "Офисное" },
    { label: "Спортивное", value: "Спортивное" },
    { label: "Объекта общепита", value: "Объекта общепита" },
  ];

  const initialOptionsFilter = {};
  for (const key in initialOptions) {
    if (initialOptions[key] === "default" || initialOptions[key]?.options === "default") {
      if (key === "typeCompanies") {
        initialOptionsFilter["typeCompanies"] = {
          options: typeCompanies
            .map((type) => ({ label: type.name, value: type._id }))
            .filter((c) => c.value !== "deleted"),
          value: initialOptions[key]?.value || null,
        };
      }
      if (key === "region") {
        initialOptionsFilter["region"] = {
          options: [],
          value: initialOptions[key]?.value || null,
        };
      }
      if (key === "role") {
        initialOptionsFilter["role"] = {
          options: roles,
          value: initialOptions[key]?.value || null,
        };
      }
      if (key === "position") {
        initialOptionsFilter["position"] = {
          options: positions.map((position) => ({
            label: position.name,
            value: position._id,
          })),
          value: initialOptions[key]?.value || null,
        };
      }
      if (key === "roomType") {
        initialOptionsFilter["roomType"] = {
          options: roomType,
          value: initialOptions[key]?.value || null,
        };
      }
    } else {
      initialOptionsFilter[key] = initialOptions[key];
    }
  }

  const [options, setOptions] = useState(initialOptionsFilter);

  const [validateOptions, setValidateOptions] = useState(() => {
    const obj = {};
    for (const key in initialOptionsFilter) {
      obj[key] = null;
    }
    return obj;
  });

  const changeOptions = (name, toСhange, value) => {
    if (name === "setAll") return setOptions(toСhange);

    if (name === "all") {
      return setOptions(initialOptionsFilter);
    }

    if (toСhange === "set") {
      return setOptions((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "clear") {
      const clearOptions = {};
      for (const key in options) {
        if (Array.isArray(options[key].value)) {
          if (options[key].value.length) {
            const length = options[key].value.length;
            clearOptions[key] = {
              ...options[key],
              value: new Array(length).fill(null),
            };
          } else {
            clearOptions[key] = {
              ...options[key],
              value: [],
            };
          }
        } else {
          clearOptions[key] = {
            ...options[key],
            value: null,
          };
        }
      }
      setOptions(clearOptions);
    }

    if (name === "role") {
      if (typeof value === "object") {
        return setOptions((prev) => ({
          ...prev,
          role: {
            ...prev.role,
            value: value,
          },
        }));
      }
      return setOptions((prev) => ({
        ...prev,
        role: {
          ...prev.role,
          value: { label: roles.find((r) => value === r.value).label, value },
        },
      }));
    }

    setOptions((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [toСhange]: value,
      },
    }));

    setValidateOptions((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  const checkValidateOptions = (field, validateData) => {
    if (field === "clear") {
      setValidateOptions((prev) => ({
        ...prev,
        [validateData]: null,
      }));
    }

    let value = true;
    if (!validateData) return validateOptions[field];

    // проверка наличия
    if (validateData.required) {
      if (!options[field].value) {
        setValidateOptions((prev) => ({
          ...prev,
          [field]: "Поле является обязательным",
        }));
        value = false;
      }
    }
    // проверка длины массива
    if (validateData.requiredArray) {
      if (Array.isArray(options[field].value)) {
        const lengthField = options[field].value.length;
        if (!lengthField) {
          setValidateOptions((prev) => ({
            ...prev,
            [field]: "Поле является обязательным",
          }));
          value = false;
        }
      } else {
        value = false;
      }
    }

    return value;
  };

  useEffect(() => {
    setOptions(initialOptionsFilter);
  }, [typeCompanies, positions]);

  useEffect(() => {
    let count = 0;
    for (let key in validateOptions) {
      let val = validateOptions[key];
      if (val !== null && count === 0) {
        count = count + 1;
        toast.error("Заполните все обязательные поля");
      }
    }
  }, [validateOptions]);

  return { options, changeOptions, checkValidateOptions, validateOptions };
};
