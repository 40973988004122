import React from "react";
import "./AccessEditRow.scss";
import Icons from "../../../Icons/Icons";
import СheckboxSwitch from "../../../СheckboxSwitch/СheckboxSwitch";

const AccessEditRow = ({
  info,
  permissions,
  checked,
  onCheck,
  changePermissions,
  typeFile,
}) => {
  const { name, _id } = info;
  const findId = (arr, id) => {
    if (arr?.find((e) => e._id === id)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`accessEditRow  ${typeFile === "file" ? "file" : ""}`}>
      <div className="accessEditRow-item name">
        <span className="accessEditRow-name" title={name}>
          {name}
        </span>
      </div>
      <div
        className="accessEditRow-item view"
        title={
          findId(permissions.view, _id)
            ? "Просмотр разрешен"
            : "Просмотр запрещен"
        }
      >
        <СheckboxSwitch
          check={findId(permissions.view, _id)}
          onCheck={() =>
            changePermissions(
              findId(permissions.view, _id) ? "clearAll" : "view",
              info
            )
          }
        />
      </div>
      {typeFile === "folder" && (
        <div
          className="accessEditRow-item add"
          title={
            findId(permissions.add, _id)
              ? "Добавление разрешено"
              : "Добавление запрещено"
          }
        >
          <СheckboxSwitch
            check={findId(permissions.add, _id)}
            onCheck={() => changePermissions("add", info)}
          />
        </div>
      )}
      <div
        className="accessEditRow-item edit"
        title={
          findId(permissions.edit, _id)
            ? "Редактирование разрешено"
            : "Редактирование запрещено"
        }
      >
        {" "}
        <СheckboxSwitch
          check={findId(permissions.edit, _id)}
          onCheck={() => changePermissions("edit", info)}
        />
      </div>
      <div
        className="accessEditRow-item copy"
        title={
          findId(permissions.copy, _id)
            ? "Копирование разрешено"
            : "Копирование запрещено"
        }
      >
        <СheckboxSwitch
          check={findId(permissions.copy, _id)}
          onCheck={() => changePermissions("copy", info)}
        />
      </div>
      <div
        className="accessEditRow-item move"
        title={
          findId(permissions.move, _id)
            ? "Перемещение разрешено"
            : "Перемещение запрещено"
        }
      >
        <СheckboxSwitch
          check={findId(permissions.move, _id)}
          onCheck={() => changePermissions("move", info)}
        />
      </div>
      <div
        className="accessEditRow-item remove"
        title={
          findId(permissions.remove, _id)
            ? "Удаление разрешено"
            : "Удаление запрещено"
        }
      >
        <СheckboxSwitch
          check={findId(permissions.remove, _id)}
          onCheck={() => changePermissions("remove", info)}
        />
      </div>
      <div className="accessEditRow-btn">
        <Icons
          type={"remove"}
          size={"md"}
          cursor
          onClick={() => changePermissions("clearAll", info)}
        />
      </div>
    </div>
  );
};

export default AccessEditRow;
