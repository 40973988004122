import React, { useEffect, useState, useRef, useMemo } from "react";
import "./Filter.scss";
import { useFilter } from "../../hooks/useFilter";
import { getVisibleFilterPage } from "../../utils/getVisibleFilterPage";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import Icons from "../Icons/Icons";
import FilterModal from "./components/FilterModal/FilterModal";
import Button from "../Button/Button";
import { getForSelect } from "../../utils/getForSelect";
import { useDispatch } from "react-redux";
import { setBlockScroll } from "../../store/reducers/blockScrollReducer";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import { useSearch } from "../../hooks/useSearch";
import { useChangeIV } from "../../hooks/useChangeIV";

function Filter(props) {
  let { onAccept, onClear, disabled, currentPage: currentPageProps, mobile } = props;

  const [currentPage, setCurrentPage] = useState(null);

  const [filterVisible, setFilterVisible] = useState(false);

  const dispatch = useDispatch();

  const { filters, chageFitlers } = useFilter();
  const ref = useRef();

  const { setFilter, searchByFilter, clearParams, getCurrentPage, queries } = useSearch();

  useOnClickOutside(ref, () => setFilterVisible(false));

  const { IV, changeIV } = useChangeIV({
    isImportant: false,
  });

  const { options, changeOptions } = useChangeOptions({
    period: {
      value: [],
    },
    section: {
      options: [
        { label: "Физическая приёмка", value: "physical" },
        { label: "Документальная приемка", value: "documentary" },
      ],
      value: null,
    },
    project: {
      options: [],
      value: null,
    },
    company: {
      options: [],
      value: null,
    },
    employee: {
      options: [],
      value: null,
    },
    director: {
      options: [],
      value: null,
    },
    participant: {
      options: [],
      value: null,
    },
    protocol: {
      options: [],
      value: null,
    },
    author: {
      options: [],
      value: null,
    },
    executor: {
      options: [],
      value: null,
    },
    projects: {
      options: [],
      value: null,
    },
    isImportant: {
      // options: [
      //   { label: "Важная", value: "isImportant" },
      //   { label: "Стандартная", value: "noImportant" },
      // ],
      value: null,
    },
    status: {
      options: [
        { label: "Назначенные", value: "assigned" },
        { label: "В работе", value: "atWork" },
        { label: "На проверке", value: "onVerification" },
        { label: "Отклонено", value: "rejected" },
        // { label: "Просрочено", value: "overdue" },
        { label: "Выполнено", value: "completed" },
      ],
      value: null,
    },
    overdue: {
      options: [
        { label: "В срок", value: "nooverdue" },
        { label: "Просроченные", value: "overdue" },
      ],
      value: null,
    },
    instructions: {
      options: [],
      value: null,
    },
    quantity: {
      options: [
        { label: "По уменьшению", value: "max" },
        { label: "По увеличению", value: "min" },
      ],
      value: null,
    },

    role: "default",
    region: {
      value: null,
    },
    start: {
      value: null,
    },
    end: {
      value: null,
    },
    typeCompanies: "default",
    roomType: "default",
    position: "default",
  });

  const onChange = (name, data) => {
    if (!data) return;

    setFilter(name, data);

    changeOptions(name, "value", data);
  };

  const clear = () => {
    clearParams();
    chageFitlers("clear");
    changeOptions("clear");
    changeIV("clear");
    onClear && onClear();
  };

  const changeFilterVisible = () => {
    if (disabled) return;
    setFilterVisible((prev) => !prev);
  };

  useEffect(() => {
    if (filterVisible) {
      dispatch(
        setBlockScroll({
          disabled: true,
        }),
      );
    } else {
      dispatch(
        setBlockScroll({
          disabled: false,
        }),
      );
    }
  }, [filterVisible]);

  const accept = () => {
    searchByFilter();

    onAccept && onAccept();
    mobile && changeFilterVisible();
  };

  const badgeVisible = useMemo(() => {
    for (const option in options) {
      if (queries[option]?.value) {
        return true;
      }
    }
    return false;
  }, [queries]);

  useEffect(() => {
    if (getVisibleFilterPage("company", currentPage) && !options.company.options.length) {
      getForSelect.getCompanies({}, (companies) => {
        changeOptions("company", "options", companies);
      });
    }
  }, [options.company.options]);

  // * проверка currentPage
  useEffect(() => {
    changeOptions("clear");

    if (currentPageProps) return setCurrentPage(currentPageProps);

    const page = getCurrentPage();

    if (page) {
      setCurrentPage(page);
    } else {
      setCurrentPage("analytics");
    }
  }, [currentPageProps]);

  return (
    <div className={`filter`}>
      <div className={`filter-btn`}>
        {disabled ||
          (mobile ? (
            <div onClick={changeFilterVisible} className="filter-btn-mobile">
              <Icons type={"filterIcon"} size={"xl"} title={{ visible: true, text: "" }} />
            </div>
          ) : (
            <div
              onClick={changeFilterVisible}
              className={`filter-btn-content ${
                filterVisible ? "hide" : `show  ${badgeVisible ? "filterBadge" : ""}`
              }`}
            >
              <Icons
                type={"filterIcon"}
                size={"xl"}
                title={{ visible: true, text: "" }}
                // color={badgeVisible && "blue"}
              />
            </div>
          ))}
      </div>

      {mobile
        ? filterVisible && (
            <ModalWrapperMobile
              onClose={() => setFilterVisible(false)}
              title={"Фильтр"}
              btns={
                <div className={"filter-modal-btns"}>
                  <Button color="blue" title="Применить" onClick={accept} />
                  <Button color="dark" title="Сбросить" onClick={clear} />
                </div>
              }
            >
              <FilterModal
                getVisibleFilterPage={getVisibleFilterPage}
                filters={filters}
                onChange={onChange}
                currentPage={currentPage}
                options={options}
                accept={accept}
                clear={clear}
                mobile
                filterVisible={filterVisible}
                changeFilterVisible={changeFilterVisible}
                IV={IV}
                changeIV={changeIV}
              />
            </ModalWrapperMobile>
          )
        : // </div>
          filterVisible && (
            <div className="filter-ref" ref={ref}>
              <FilterModal
                getVisibleFilterPage={getVisibleFilterPage}
                filters={filters}
                onChange={onChange}
                currentPage={currentPage}
                options={options}
                accept={accept}
                clear={clear}
                changeFilterVisible={changeFilterVisible}
                IV={IV}
                changeIV={changeIV}
              />
            </div>
          )}
    </div>
  );
}

export default Filter;
