/* eslint-disable camelcase */
import { toast } from "react-toastify";

export const extraReducersWrapper = (reducers) => {
  const newReducers = reducers;

  for (const key in newReducers) {
    const full_name_pending = [...key.split("/").splice(0, 2), "pending"].join("/");
    const full_name_rejected = [...key.split("/").splice(0, 2), "rejected"].join("/");
    const full_name_fulfilled = [...key.split("/").splice(0, 2), "fulfilled"].join("/");

    if (typeof newReducers[full_name_pending] !== "function") {
      newReducers[full_name_pending] = (state) => {
        state.loading = true;
      };
    }

    if (typeof newReducers[full_name_rejected] !== "function") {
      const test_error = newReducers[full_name_rejected];
      newReducers[full_name_rejected] = (state, { payload }) => {
        if (typeof state?.loading !== "undefined") state.loading = false;
        if (typeof state?.error !== "undefined") state.error = payload?.response?.data?.message;
        toast.error(`${test_error} ${payload?.response?.data?.message || ""}`);
      };
    }

    if (newReducers[full_name_fulfilled]) {
      if (typeof newReducers[full_name_fulfilled] === "string") {
        const test_success = newReducers[full_name_fulfilled];
        newReducers[full_name_fulfilled] = (state) => {
          if (typeof state?.loading !== "undefined") state.loading = false;
          toast.success(test_success);
        };
      } else if (typeof newReducers[full_name_fulfilled] === "function") {
        const fulfilled_wrapper = (func) => {
          return (state, action) => {
            state.loading = false;
            func(state, action);
          };
        };
        newReducers[full_name_fulfilled] = fulfilled_wrapper(newReducers[full_name_fulfilled]);
      }
    } else {
      const fulfilled_wrapper = () => (state) => {
        state.loading = false;
      };
      newReducers[full_name_fulfilled] = fulfilled_wrapper(newReducers[full_name_fulfilled]);
    }
  }

  return newReducers;
};
