import React, { useRef, useState } from "react";
import "./ModalWrapperDesktop.scss";
import Icons from "../Icons/Icons";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { setSizeModal, setViewCardsModal } from "../../store/reducers/settingsReducer";
import { useSelector, useDispatch } from "react-redux";
import Screen from "../Screen/Screen";
import Button from "../Button/Button";
import { useModal } from "../../hooks/useModal";
import { closeAllNotificationDraft } from "../../store/reducers/draftReduces";
import { useDraft } from "../../hooks/useDraft";
import { ErrorBoundary } from "../../ui";

const ModalWrapperDesktop = ({
  onClose: onCloseProps,
  title,
  children,
  className,
  width,
  noscroll = true,
  minHeight = true,
  viewCardVisible = false,
  editBtn = {
    visible: false,
    onClick: null,
  },
  visibleSize = true,
  draftModal = null,
}) => {
  const dispatch = useDispatch();
  const { sizeModal, viewModal } = useSelector((state) => state.settings);

  const { getVisibleDraft, getDataDraft } = useDraft("modalWrapper", {}, {});

  const changeSizeModal = async (sizeModal) => {
    if (sizeModal) {
      await dispatch(
        setSizeModal({
          sizeModal: false,
        }),
      );
    } else {
      await dispatch(
        setSizeModal({
          sizeModal: true,
        }),
      );
    }
  };

  const changeViewCard = async (viewModal) => {
    if (viewModal) {
      await dispatch(
        setViewCardsModal({
          viewModal: false,
        }),
      );
    } else {
      await dispatch(
        setViewCardsModal({
          viewModal: true,
        }),
      );
    }
  };

  const onClose = () => {
    onCloseProps();
    dispatch(closeAllNotificationDraft());
  };

  const onPush = () => {
    const data = getDataDraft(draftModal?.name);

    draftModal.onPush && draftModal.onPush(data);

    dispatch(closeAllNotificationDraft());
  };

  const onDelete = () => {
    draftModal.onDelete && draftModal.onDelete();

    dispatch(closeAllNotificationDraft());
  };

  const refModal = useRef();
  useOnClickOutside(refModal, onClose);

  return (
    <div className="animate__animated animate__fadeIn modal owerflow-modal">
      <div
        style={{
          width: width ? width : "100%",
        }}
        className={`modalWrapperDesctop ${sizeModal ? "modalWrapperDesctop-fullScreen" : ""}`}
        ref={refModal}
      >
        <div className="modalWrapperDesctop-header">
          <h4 className="modalWrapperDesctop-title">{title}</h4>
          <div className="modalWrapperDesctop-btns">
            <Screen size={"xxl"}>
              {viewCardVisible &&
                (viewModal ? (
                  <button
                    className="modalWrapperDesctop-btn"
                    onClick={() => changeViewCard(viewModal)}
                  >
                    <Icons
                      type={"viewCardWhite"}
                      size={"md"}
                      cursor
                      title={{ visible: true, text: "" }}
                      className="rotate90"
                    />
                  </button>
                ) : (
                  <button
                    className="modalWrapperDesctop-btn"
                    onClick={() => changeViewCard(viewModal)}
                  >
                    <Icons
                      type={"viewCardWhite"}
                      size={"md"}
                      cursor
                      title={{ visible: true, text: "" }}
                    />
                  </button>
                ))}
            </Screen>
            {editBtn.visible && (
              <button className="modalWrapperDesctop-btn" onClick={editBtn.onClick}>
                <Icons
                  type={"edit"}
                  size={"md"}
                  cursor
                  color={"white"}
                  title={{ visible: true, text: "" }}
                />
              </button>
            )}
            {visibleSize && (
              <button
                className="modalWrapperDesctop-btn"
                onClick={() => changeSizeModal(sizeModal)}
              >
                {sizeModal ? (
                  <Icons
                    type={"windowDouble"}
                    size={"md"}
                    cursor
                    color={"white"}
                    title={{ visible: true, text: "" }}
                  />
                ) : (
                  <Icons
                    type={"window"}
                    size={"md"}
                    cursor
                    color={"white"}
                    title={{ visible: true, text: "" }}
                  />
                )}
              </button>
            )}
            <button className="modalWrapperDesctop-btn" onClick={onClose}>
              <Icons
                type={"closeWhite"}
                size={"md"}
                cursor
                color={"white"}
                title={{ visible: true, text: "" }}
              />
            </button>
          </div>
        </div>
        <div
          style={{
            minHeight: minHeight ? "200px" : "fit-content",
          }}
          className={`modalWrapperDesctop-container ${noscroll ? "customScroll" : ""}  ${
            className ? className : ""
          }`}
        >
          {children}
          {getVisibleDraft(draftModal?.name) && draftModal && (
            <div className="modalWrapperDesctop-container-draftNotification animate__animated animate__fadeIn">
              <span className="modalWrapperDesctop-container-draftNotification-title">
                У Вас есть сохраненные данные в черновике
              </span>
              <div className="modalWrapperDesctop-container-draftNotification-btns">
                <Button onClick={onPush} title="Вставить" color="green" />
                <Button onClick={onDelete} title="Удалить" color="red" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapperDesktop;
