import React, { useState } from "react";
import {
  getCalendarMatrix,
  monthName,
  getRangeYears,
} from "../../utils/utilsCalendar";
import Icons from "../Icons/Icons";
import "./CalendarPanel.scss";

const CalendarPanel = ({ changeDate, currentDate }) => {
  const [menuChooseDate, setmenuChooseDate] = useState(false);
  const { year, month } = currentDate;

  return (
    <div className="calendarPanel">
      <div className="calendarPanel-content">
        <div
          onClick={() => changeDate("prev")}
          className="calendarPanel-content-prev"
        >
          <Icons
            type={"arrow"}
            size="md"
            cursor
            title={{ visible: true, text: "Предыдущий" }}
          />
        </div>
        <div className="calendarPanel-content-value">
          <div
            onClick={() => setmenuChooseDate((prev) => !prev)}
            className="calendarPanel-content-value-title"
          >
            <span>{monthName[month]}</span>
            <span>{year}г.</span>
          </div>
          {menuChooseDate && (
            <div className="calendarPanel-content-value-menu">
              <div className="calendarPanel-content-value-menu-month">
                {monthName.map((m, i) => (
                  <span
                    onClick={() =>
                      changeDate("set", { field: "month", value: i })
                    }
                    className={m === monthName[month] ? "active" : ""}
                  >
                    {m}
                  </span>
                ))}
              </div>
              <div className="calendarPanel-content-value-menu-year customScroll-light">
                {getRangeYears().map((y) => (
                  <span
                    onClick={() =>
                      changeDate("set", { field: "year", value: y })
                    }
                    className={y === year ? "active" : ""}
                  >
                    {y}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => changeDate("next")}
          className="calendarPanel-content-next"
        >
          <Icons
            type={"arrow"}
            size="md"
            cursor
            title={{ visible: true, text: "Следующий" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarPanel;
