export const getAllLines = (allLines) => {
  if (!allLines.length) return '{"lines": [], "width": 0, "height": 0}';
  let data;
  allLines.forEach((line, i) => {
    if (i === 0) {
      if (!line.lines) return "";
      const dataParce = JSON.parse(line.lines);
      const lines = dataParce.lines.map((el) => ({ ...el, idTask: line.id }));
      const range = getRangePin(lines);
      data = {
        ...dataParce,
        lines: range,
      };
    } else {
      if (!line.lines) return "";
      let { lines } = JSON.parse(line.lines);
      lines = lines.map((el) => ({ ...el, idTask: line.id }));
      const range = getRangePin(lines);
      data = {
        ...data,
        lines: [...data.lines, ...range],
      };
    }
  });
  return data ? JSON.stringify(data) : '{"lines": [], "width": 0, "height": 0}';
};
