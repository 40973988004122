import React, { useState } from "react";
import Icons from "../Icons/Icons";
import { useEffect } from "react";

const DocIcons = ({ type, size, onClick, cursor = true, titleText = "" }) => {
  const [typeIcon, setTypeIcon] = useState("noneFormat");
  const typeFile = type.split(".").at(-1);

  useEffect(() => {
    if (typeFile.includes("png")) {
      return setTypeIcon("png");
    } else if (typeFile.includes("docx")) {
      return setTypeIcon("docx");
    } else if (typeFile.includes("doc")) {
      return setTypeIcon("doc");
    } else if (typeFile.includes("txt")) {
      return setTypeIcon("txt");
    } else if (typeFile.includes("ppt")) {
      return setTypeIcon("ppt");
    } else if (typeFile.includes("pdf")) {
      return setTypeIcon("pdf");
    } else if (typeFile.includes("xlsx")) {
      return setTypeIcon("xlsx");
    } else if (typeFile.includes("xls")) {
      return setTypeIcon("xls");
    } else if (typeFile.includes("jpeg")) {
      return setTypeIcon("jpeg");
    } else if (typeFile.includes("jpg")) {
      return setTypeIcon("jpg");
    } else if (typeFile.includes("mpp")) {
      return setTypeIcon("mpp");
    } else if (typeFile.includes("zip")) {
      return setTypeIcon("zip");
    } else {
      setTypeIcon("noneFormat");
    }
  }, [type]);

  return (
    <Icons
      type={typeIcon}
      size={size || "xxl"}
      onClick={onClick}
      cursor={cursor ? true : false}
      title={{ visible: true, text: titleText }}
    />
  );
};

export default DocIcons;
