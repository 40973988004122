import React from "react";
import { useSelector } from "react-redux";
import { getFormatFIO } from "../../../../utils/getFormatFIO";
import { transformTypeCompany } from "../../../../utils/transformTypeCompany";
import ImageUpload from "../../../ImageUpload/ImageUpload";
import "./CompanyBaseInfoInfo.scss";
import Icons from "../../../Icons/Icons";
import { useHasPermissions } from "../../../../hooks/useHasPermissions";

const CompanyBaseInfoInfo = ({ setEdit }) => {
  const { company } = useSelector((state) => state.companies);
  const { edit: editPermission } = useHasPermissions("companiesCard", company);

  const { _id, logo, name, inn, email, phone, director, region, type, groups } = company;

  return (
    <div className="companyBaseInfoInfo">
      {editPermission && (
        <div className="companyBaseInfoInfo-row">
          {" "}
          <Icons
            type={"edit"}
            size={"md"}
            cursor
            title={{ visible: true, text: "Редактировать компанию" }}
            onClick={() => setEdit(true)}
          />
        </div>
      )}
      <div className="companyBaseInfoInfo-grid">
        <div className="companyBaseInfoInfo-wrapper">
          <div className="modalInfoCompany-content-info-item">
            <span className="modalInfoCompany-content-info-title">Название:</span>
            <span className="modalInfoCompany-content-info-text">{name}</span>
          </div>
          <ul className="modalInfoCompany-content-info">
            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">ИНН:</span>
              <span className="modalInfoCompany-content-info-text">{inn}</span>
            </li>

            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">Город:</span>
              <span className="modalInfoCompany-content-info-text">{region.name}</span>
            </li>
            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">Email:</span>
              <span className="modalInfoCompany-content-info-text">{email}</span>
            </li>

            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">Телефон:</span>
              <span className="modalInfoCompany-content-info-text">{phone}</span>
            </li>
            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">Тип:</span>
              <span className="modalInfoCompany-content-info-text">
                {transformTypeCompany(type)}
              </span>
            </li>
            <li className="modalInfoCompany-content-info-item">
              <span className="modalInfoCompany-content-info-title">Руководитель:</span>
              <span className="modalInfoCompany-content-info-text">{getFormatFIO(director)}</span>
            </li>
          </ul>
        </div>
        <div className="companyBaseInfoInfo-avatar">
          <span className="companyBaseInfoInfo-title">Логотип:</span>
          <div className="companyBaseInfoInfo-logo modalInfoCompany-content-logo">
            <ImageUpload
              // onChange={(value) => changeIV("set", { name: "logo", value })}
              logo={logo}
              edit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBaseInfoInfo;
