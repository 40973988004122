import React from "react";
import "./ProfileMobileEdit.scss";
import { useSelector } from "react-redux";
import { transformRole } from "../../../../../utils/transformRole";
import ProfileEditInfo from "../../../../../components/ProfileEditInfo/ProfileEditInfo";
import ProfilesList from "../../../../../components/ProfilesList/ProfilesList";

const ProfileMobileEdit = ({ changeWiewChapter }) => {
  const { userData } = useSelector((state) => state.user);
  // if (!userData) return;
  const { name, surname, role } = userData;

  return (
    <div className="profileMobileEdit">
      <div className="profileMobileEdit-header">
        <h2 className="profileMobileEdit-header-title">
          {surname} {name} {userData?.patronymic}
        </h2>
        <span className="profileMobileEdit-header-subtitle">
          (Роль в системе: {transformRole(role)})
        </span>
      </div>
      <ProfileEditInfo />
      <ProfilesList />
    </div>
  );
};

export default ProfileMobileEdit;
