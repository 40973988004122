import React from "react";
import "./CardRowCompaniesMin.scss";
import Checkbox from "../../../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import { transformTypeCompany } from "../../../../../../../utils/transformTypeCompany";
import { countCompanyEmployess } from "../../../../../../../utils/countCompanyEmployess.js";
function cardRowCompaniesMin(props) {
  const { info, addItemSelect, check } = props;
  const { _id, name, director, region, count, type, profiles } = info;

  return (
    <div className="cardRowCompaniesMin">
      <div className="cardRowCompaniesMin-item">
        <Checkbox check={check} onCheck={() => addItemSelect(_id)} />
      </div>
      <div className="cardRowCompaniesMin-item name">
        <span title={name}>{name}</span>
      </div>
      <div className="cardRowCompaniesMin-item type">
        <span title={transformTypeCompany(type)}>{transformTypeCompany(type)}</span>
      </div>
      <div className="cardRowCompaniesMin-item region">
        <span title={region.name}>{region.name}</span>
      </div>
      <div className="cardRowCompaniesMin-item directorFIO">
        <span title={getFormatFIO(director)}>{getFormatFIO(director)}</span>
      </div>

      <div className="cardRowCompaniesMin-item quentityParticipants">
        <span title={countCompanyEmployess(profiles) + 1 || 1}>
          {countCompanyEmployess(profiles) || 1}{" "}
        </span>
      </div>
    </div>
  );
}

export default cardRowCompaniesMin;
