import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/Button";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Pagination from "../../../../../components/Pagination/Pagination";
import SearchInputMobile from "../../../../../components/SearchInputMobile/SearchInputMobile";
import "./ModalAddCompanyMobile.scss";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../../axios/axios";
import {
  asyncAddCompaniesToProject,
  asyncGetProjectCompaniesForAdd,
} from "../../../../../store/reducers/projectsReducer";
import ModalWrapperMobile from "../../../../../components/ModalWrapperMobile/ModalWrapperMobile";
import CompaniesCardMobile from "../../../../../components/CompaniesCardMobile/CompaniesCardMobile";

function ModalAddCompanyMobile(props) {
  const { changeModalVisible, get_companies } = props;
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState([]);
  const [numberPage, setNumberPage] = useState(1);
  const { _id: projectId } = useSelector((state) => state.projects.project);

  const { selectItems, addItemSelect, addAllItemSelect, clearItems } = useSelects(companies);

  const getCompanies = async (companyName = "") => {
    const res = await dispatch(
      asyncGetProjectCompaniesForAdd({
        projectId,
        numberPage,
        companyName,
      }),
    );
    if (res.error) return;

    setCompanies(res.payload.companies);
    setCount(res.payload.count);
  };

  const add_companies_from_project = async () => {
    await dispatch(asyncAddCompaniesToProject(selectItems));

    changeModalVisible("addCompany");
    await get_companies();
    clearItems();
  };

  const changeSearchIV = (v) => {
    if (v === "") getCompanies();
  };

  useEffect(() => {
    getCompanies();
  }, [numberPage]);

  return (
    <ModalWrapperMobile
      title={"Добавить компании в проект"}
      onClose={() => changeModalVisible("addCompany", false)}
      btns={
        <div className="modalAddCompanyMobile-btns">
          <Button
            onClick={add_companies_from_project}
            title="Добавить"
            color="blue"
            icon
            disabled={!selectItems.length}
          />
        </div>
      }
    >
      <div className="modalAddCompanyMobile">
        <div className="modalAddCompanyMobile-header">
          <span>Всего компаний: {count}</span>
          <div className="modalAddCompanyMobile-header-check">
            <Checkbox onCheck={addAllItemSelect} check={selectItems.length === companies.length} />
            <span>Выбрать всех</span>
          </div>
        </div>
        <div className="modalAddCompanyMobile-searchPanel">
          <SearchInputMobile
            onSubmit={(v) => getCompanies(v)}
            onChange={(v) => changeSearchIV(v)}
            inputSearch={{
              visible: true,
              placeholder: "Найти команию по названию",
            }}
          />
        </div>
        <div className="modalAddCompanyMobile-grid">
          {companies.map((complanies) => (
            <CompaniesCardMobile
              key={complanies._id}
              info={complanies}
              check={selectItems.includes(complanies._id)}
              addItemSelect={addItemSelect}
              modalCard={true}
            />
          ))}
        </div>
        <div className="modalAddCompanyMobile-footer">
          <div className="modalAddCompanyMobile-footer-pagination">
            {count > 10 && (
              <Pagination
                pageCount={Math.ceil(count / 10)}
                onPageChange={(n) => setNumberPage(n)}
                modal={true}
              />
            )}
          </div>
        </div>
      </div>
    </ModalWrapperMobile>
  );
}

export default ModalAddCompanyMobile;
