import React, { useState } from "react";
import Input from "../../../Input/Input";
import "./FormCreate.scss";
import { useChangeIV } from "../../../../hooks/useChangeIV";
import Select from "../../../Select/Select";
import Period from "../../../Period/Period";
import Button from "../../../Button/Button";
import AreasChart from "../../../AreasChart/AreasChart";
import BandsChart from "../../../BandsChart/BandsChart";
import Checkbox from "../../../Checkbox/Checkbox";
import StepAreasChart from "../../../StepAreasChart/StepAreasChart";
import LinesChart from "../../../LinesChart/LinesChart";
import PieCircleChart from "../../../PieCircleChart/PieCircleChart";
import DonutChart from "../../../DonutChart/DonutChart";
import SelectMulti from "../../../SelectMulti/SelectMulti";
import { useChangeOptions } from "../../../../hooks/useChangeOptions";
import ListChart from "../../../ListChart/ListChart";
import ColumnsChart from "../../../ColumnsChart/ColumnsChart";
import Screen from "../../../Screen/Screen";
function FormCreate(props) {
  const { currentChart, heightChart } = props;
  const { IV, changeIV } = useChangeIV({
    nameChart: "",
    period: null,
    buildColumns: false,
  });

  const [date] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const { options, changeOptions } = useChangeOptions({
    chapter: {
      options: [
        { label: "Раздел 1", value: "Раздел 1" },
        { label: "Раздел 2", value: "Раздел 2" },
        { label: "Раздел 3", value: "Раздел 3" },
      ],
      value: null,
    },
    sizeImg: {
      options: [
        { label: "Размер изображения 1", value: "Размер изображения 1" },
        { label: "Размер изображения 2", value: "Размер изображения 2" },
        { label: "Размер изображения 3", value: "Размер изображения 3" },
      ],
      value: null,
    },
    tasks: {
      options: [
        { label: "Задача 1", value: "Задача 1" },
        { label: "Задача 2", value: "Задача 2" },
        { label: "Задача 3", value: "Задача 3" },
      ],
      value: null,
    },
    project: {
      options: [
        { label: "Проект 1", value: "Проект 1" },
        { label: "Проект 2", value: "Проект 2" },
        { label: "Проект 3", value: "Проект 3" },
      ],
      value: null,
    },
    layer: {
      options: [
        { label: "Слой 1", value: "Слой 1" },
        { label: "Слой 2", value: "Слой 2" },
        { label: "Слой 3", value: "Слой 3" },
      ],
      value: null,
    },
    type: {
      options: [
        { label: "По сотрудникам", value: "employees" },
        { label: "По компаниям", value: "companys" },
        { label: "По группам", value: "groups" },
      ],
      value: [],
    },
    employee: {
      options: [
        { label: "Сотрудник 1", value: "Сотрудник 1" },
        { label: "Сотрудник 2", value: "Сотрудник 2" },
      ],
      value: [],
    },
    company: {
      options: [
        { label: "Рога и копыта", value: "Рога и копыта" },
        { label: "Металлинвест", value: "Металлинвест" },
        { label: "Поистройдеталь", value: "Поистройдеталь" },
      ],
      value: [],
    },
    status: {
      options: [
        { label: "Статус 1", value: "Статус 1" },
        { label: "Статус 2", value: "Статус 2" },
        { label: "Статус 3", value: "Статус 3" },
      ],
      value: null,
    },
    stages: {
      options: [
        { label: "Этап 1", value: "Этап 1" },
        { label: "Этап 2", value: "Этап 2" },
        { label: "Этап 3", value: "Этап 3" },
      ],
      value: null,
    },
    works: {
      options: [
        { label: "Работа 1", value: "Работа 1" },
        { label: "Работа 2", value: "Работа 2" },
        { label: "Работа 3", value: "Работа 3" },
      ],
      value: null,
    },
    region: {
      options: [
        { label: "Город 1", value: "Город 1" },
        { label: "Город 2", value: "Город 2" },
        { label: "Город 3", value: "Город 3" },
      ],
      value: null,
    },
    companies: {
      options: [
        { label: "Компания 1", value: "Компания 1" },
        { label: "Компания 2", value: "Компания 2" },
        { label: "Компания 3", value: "Компания 3" },
      ],
      value: null,
    },
    groups: {
      options: [
        { label: "Плиточники", value: "Плиточники" },
        { label: "Водители", value: "Водители" },
        { label: "Электрики", value: "Электрики" },
      ],
      value: null,
    },
    filter: {
      options: [
        { label: "Фильтр 1", value: "Фильтр 1" },
        { label: "Фильтр 2", value: "Фильтр 2" },
        { label: "Фильтр 3", value: "Фильтр 3" },
      ],
      value: null,
    },
    form: {
      options: [
        { label: "Форма 1", value: "Форма 1" },
        { label: "Форма 2", value: "Форма 2" },
        { label: "Форма 3", value: "Форма 3" },
      ],
      value: null,
    },
    group: {
      options: [
        { label: "Форма 1", value: "Форма 1" },
        { label: "Форма 2", value: "Форма 2" },
        { label: "Форма 3", value: "Форма 3" },
      ],
      value: null,
    },
    value: {
      options: [
        { label: "Форма 1", value: "Форма 1" },
        { label: "Форма 2", value: "Форма 2" },
        { label: "Форма 3", value: "Форма 3" },
      ],
      value: null,
    },
    buildColumnsValue: {
      options: [
        { label: "Форма 1", value: "Форма 1" },
        { label: "Форма 2", value: "Форма 2" },
        { label: "Форма 3", value: "Форма 3" },
      ],
      value: null,
    },
  });

  const visibleFields = {
    nameChart: [],
    chapter: [],
    sizeImg: [],
    tasks: ["Колонны", "Шаговые области", "Список"],
    project: ["Колонны", "Полосы", "Области", "Шаговые области", "Список"],
    layer: ["Колонны", "Список"],
    type: ["Колонны", "Шаговые области", "Линии", "Пирог", "Пончик", "Список"],
    status: ["Колонны", "Полосы", "Области", "Список"],
    period: [],
    stages: ["Полосы"],
    works: ["Полосы"],
    region: ["Области", "Шаговые области"],
    companies: ["Шаговые области"],
    filter: ["Линии", "Пирог", "Пончик"],
    form: ["Линии", "Пирог", "Пончик"],
    data: ["Линии", "Пирог", "Пончик"],
  };

  return (
    <div className="formCreate">
      <Screen size={"sm"} down>
        <div className="formCreate-viewing-btns">
          <Button color="blue" title="Создать" />
          <Button color="dark" title="Отмена" />
        </div>
      </Screen>
      <div className="formCreate-form">
        {/* Название диаграммы */}
        {(visibleFields.nameChart.includes(currentChart) || !visibleFields.nameChart.length) && (
          <Input
            title="Название диаграммы:"
            placeholder="Введите название диаграммы"
            onChange={changeIV}
            id={"nameChart"}
            value={IV.nameChart}
            name="nameChart"
          />
        )}

        {/* Выбор раздела */}
        {(visibleFields.chapter.includes(currentChart) || !visibleFields.chapter.length) && (
          <Select
            options={options.chapter.options}
            value={options.chapter.value}
            title="Раздел:"
            placeholder="Выберите раздел"
            handleChange={(data) => changeOptions("chapter", "value", data)}
          />
        )}

        {/* Выбор размера изображения */}
        {(visibleFields.sizeImg.includes(currentChart) || !visibleFields.sizeImg.length) && (
          <Select
            options={options.sizeImg.options}
            value={options.sizeImg.value}
            title="Размер изображения:"
            placeholder="Выберите размер"
            handleChange={(data) => changeOptions("sizeImg", "value", data)}
          />
        )}

        {/* Выбор формы */}
        {(visibleFields.form.includes(currentChart) || !visibleFields.form.length) && (
          <Select
            options={options.form.options}
            value={options.form.value}
            title="Форма:"
            placeholder="Выберите форму"
            handleChange={(data) => changeOptions("form", "value", data)}
          />
        )}

        {/* Выбор фильтра */}
        {(visibleFields.filter.includes(currentChart) || !visibleFields.filter.length) && (
          <Select
            options={options.filter.options}
            value={options.filter.value}
            title="Фильтр:"
            placeholder="Выберите фильтр"
            handleChange={(data) => changeOptions("filter", "value", data)}
          />
        )}

        {/* Выбор города */}
        {(visibleFields.region.includes(currentChart) || !visibleFields.region.length) && (
          <Select
            options={options.region.options}
            value={options.region.value}
            title="Город:"
            placeholder="Выберите города"
            handleChange={(data) => changeOptions("region", "value", data)}
          />
        )}

        {/* Выбор компании */}
        {(visibleFields.companies.includes(currentChart) || !visibleFields.companies.length) && (
          <Select
            options={options.companies.options}
            value={options.companies.value}
            title="Компании:"
            placeholder="Выберите компанию"
            handleChange={(data) => changeOptions("companies", "value", data)}
          />
        )}

        {/* Выбор задачи */}
        {(visibleFields.tasks.includes(currentChart) || !visibleFields.tasks.length) && (
          <Select
            options={options.tasks.options}
            value={options.tasks.value}
            title="Задачи:"
            placeholder="Выберите задачу"
            handleChange={(data) => changeOptions("tasks", "value", data)}
          />
        )}

        {/* Выбор проекта */}
        {(visibleFields.project.includes(currentChart) || !visibleFields.project.length) && (
          <Select
            options={options.project.options}
            value={options.project.value}
            title="Проекты:"
            placeholder="Выберите проект"
            handleChange={(data) => changeOptions("project", "value", data)}
          />
        )}

        {/* Выбор этапа */}
        {(visibleFields.stages.includes(currentChart) || !visibleFields.stages.length) && (
          <Select
            options={options.stages.options}
            value={options.stages.value}
            title="Этапы:"
            placeholder="Выберите этапы"
            handleChange={(data) => changeOptions("stages", "value", data)}
          />
        )}

        {/* Выбор работы */}
        {(visibleFields.works.includes(currentChart) || !visibleFields.works.length) && (
          <Select
            options={options.works.options}
            value={options.works.value}
            title="Работы:"
            placeholder="Выберите работу"
            handleChange={(data) => changeOptions("works", "value", data)}
          />
        )}

        {/* Выбор слоёв */}
        {(visibleFields.layer.includes(currentChart) || !visibleFields.layer.length) && (
          <Select
            options={options.layer.options}
            value={options.layer.value}
            title="Слои:"
            placeholder="Выберите слой"
            handleChange={(data) => changeOptions("layer", "value", data)}
          />
        )}

        {/* Выбор сотрудника */}
        {(visibleFields.type.includes(currentChart) || !visibleFields.type.length) && (
          <>
            <Select
              options={options.type.options}
              value={options.type.value}
              title="Тип данных"
              placeholder="Выберите используемые данные"
              handleChange={(data) => changeOptions("type", "value", data)}
            />
            {options.type.value.value === "employees" && (
              <SelectMulti
                options={options.employee.options}
                value={options.employee.value}
                title="Сотрудники"
                placeholder="Выберите сотрудников"
                handleChange={(data) => changeOptions("employee", "value", data)}
              />
            )}
            {options.type.value.value === "companys" && (
              <SelectMulti
                options={options.company.options}
                value={options.company.value}
                title="Компании"
                placeholder="Выберите компании"
                handleChange={(data) => changeOptions("company", "value", data)}
              />
            )}
            {options.type.value.value === "groups" && (
              <SelectMulti
                options={options.groups.options}
                value={options.groups.value}
                title="Группы"
                placeholder="Выберите группы"
                handleChange={(data) => changeOptions("groups", "value", data)}
              />
            )}
          </>
        )}

        {/* Выбор статуса */}
        {(visibleFields.status.includes(currentChart) || !visibleFields.status.length) && (
          <Select
            options={options.status.options}
            value={options.status.value}
            title="Статусы:"
            placeholder="Выберите статус"
            handleChange={(data) => changeOptions("status", "value", data)}
          />
        )}

        {/* Выбор Периода */}
        {(visibleFields.period.includes(currentChart) || !visibleFields.period.length) && (
          <Period
            state={IV.period || date}
            handleSelect={(range1) => {
              // console.log(range1);
              changeIV("set", { name: "period", value: range1 });
            }}
          />
        )}

        {/* Выбор данных */}
        {(visibleFields.data.includes(currentChart) || !visibleFields.data.length) && (
          <div className="formCreate-form-data">
            <span className="titleSection">Данные:</span>
            {/* Выбор группировки */}
            <Select
              options={options.group.options}
              value={options.group.value}
              title="Группировать по:"
              placeholder="Выберите статус"
              handleChange={(data) => changeOptions("group", "value", data)}
            />

            {/* Выбор значения */}
            <Select
              options={options.value.options}
              value={options.value.value}
              title="Значение:"
              placeholder="Выберите значение"
              menuPlacement="top"
              handleChange={(data) => changeOptions("value", "value", data)}
            />

            <Checkbox
              title="Постройте столбцы для каждого значения"
              onCheck={() =>
                changeIV("set", {
                  name: "buildColumns",
                  value: !IV.buildColumns,
                })
              }
              check={IV.buildColumns}
            />

            {/* Выбор значения для постройки каждого значения */}
            {IV.buildColumns && (
              <Select
                options={options.buildColumnsValue.options}
                value={options.buildColumnsValue.value}
                placeholder="Выберите значение"
                menuPlacement="top"
                handleChange={(data) => changeOptions("buildColumnsValue", "value", data)}
              />
            )}
          </div>
        )}
      </div>
      <div className="formCreate-viewing">
        <div className="formCreate-viewing-chart">
          {currentChart === "Колонны" && (
            <ColumnsChart heightChart={heightChart} title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Полосы" && (
            <BandsChart heightChart={heightChart} title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Области" && (
            <AreasChart heightChart={heightChart} title="Предварительный просмотр диаграммы" imgNumber={2} />
          )}
          {currentChart === "Шаговые области" && (
            <StepAreasChart heightChart={heightChart} title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Линии" && (
            <LinesChart heightChart={heightChart} title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Пирог" && (
            <PieCircleChart heightChart={heightChart} title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Пончик" && (
            <DonutChart heightChart={heightChart} forCreate title="Предварительный просмотр диаграммы" />
          )}
          {currentChart === "Список" && (
            <ListChart heightChart={heightChart} title="Предварительный просмотр диаграммы:" />
          )}
        </div>
        <Screen size={"sm"}>
          <div className="formCreate-viewing-btns">
            <Button color="blue" title="Создать" />
            <Button color="dark" title="Отмена" />
          </div>
        </Screen>
      </div>
    </div>
  );
}

export default FormCreate;
