import React, { useState, useEffect } from "react";
import "./ModalAddDellEmployees.scss";
import InputSearch from "../InputSearch/InputSearch";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { useModal } from "../../hooks/useModal";
import Button from "../Button/Button";
import { asynсAddEmployees, asynсDelEmployees } from "../../store/reducers/tasksReducer";
import { useDispatch, useSelector } from "react-redux";
import Select from "../Select/Select";
import { formatLabel } from "../../utils/formatLabel";
import SelectMulti from "../SelectMulti/SelectMulti";

const ModalAddDellEmployees = ({ add, getTasks }) => {
  const dispatch = useDispatch();

  const { changeModalVisible, getInfo } = useModal();

  const info = getInfo(add ? "addEmployeesTask" : "delEmployeesTask", "data");

  const [loading, setLoading] = useState(false);
  const { options, changeOptions, checkValidateOptions } = useChangeOptions({
    type: {
      options: [
        { label: "Наблюдатели", value: "observers" },
        { label: "Исполнители", value: "executors" },
      ],
      value: { label: "Исполнители", value: "executors" },
    },
    profiles: {
      options: [],
      value: null,
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (add) {
      await dispatch(
        asynсAddEmployees({
          type: options.type.value.value,
          employees: options.profiles.value.map((e) => e.value),
          tasks: info.map((task) => task._id),
        })
      );
    } else {
      await dispatch(
        asynсDelEmployees({
          type: options.type.value.value,
          employees: options.profiles.value.map((e) => e.value),
          tasks: info.map((task) => task._id),
        })
      );
    }
    getTasks && getTasks(1);
    setLoading(false);
    changeModalVisible(add ? "addEmployeesTask" : "delEmployeesTask", false);
  };

  const sortProfiles = (type) => {
    let sortArr = [];
    if (type === "observers") sortArr = [].concat(...info.map((task) => [...task.observers]));
    if (type === "executors") {
      sortArr = [].concat(...info.map((task) => [...task.executors]));
    }

    let profiles = sortArr.reduce((profile, i) => {
      if (!profile.find((v) => v._id == i._id)) {
        profile.push(i);
      }
      return profile;
    }, []);

    changeOptions(
      "profiles",
      "options",
      profiles.map((u) => ({
        label: formatLabel(u),
        value: u._id,
      })) || []
    );
  };

  useEffect(() => {
    sortProfiles("executors");
  }, []);

  useEffect(() => {
    sortProfiles(options.type.value.value);
  }, [options.type.value]);

  return (
    <ModalWrapper
      title={add ? "Добавить сотрудников в задачи" : "Удалить сотрудников из задач"}
      onClose={() => changeModalVisible(add ? "addEmployeesTask" : "delEmployeesTask", false)}
      minHeight={"150px"}
    >
      <form className="modalAddDellEmployees" onSubmit={onSubmit} action="#">
        <div className="modalAddDellEmployees-tasks">
          <span className="modalAddDellEmployees-tasks-title">Выбранные задачи:</span>
          <span className="modalAddDellEmployees-tasks-text">{info?.map((task) => task.name).join(", ")}</span>
        </div>
        <div className="modalAddDellEmployees-grid">
          <Select
            options={options.type.options}
            value={options.type.value}
            title="Тип:"
            placeholder="Выберите тип"
            handleChange={(data) => changeOptions("type", "value", data)}
            isClearable={false}
          />
        </div>

        {add ? (
          <>
            <InputSearch
              value={options.profiles.value}
              options={options.profiles.options}
              placeholder="Выберите сотрудников для добавления в задачи"
              title="Сотрудники:"
              forInput
              handleChange={(data) => changeOptions("profiles", "value", data)}
              multi={true}
              listModal={true}
              optionsForSearch={{ profile: true }}
              menuPlacement={"top"}
            />
          </>
        ) : (
          <SelectMulti
            value={options.profiles.value}
            options={options.profiles.options}
            placeholder="Выберите сотрудников для удаления из задач"
            title="Сотрудники:"
            forInput
            handleChange={(data) => changeOptions("profiles", "value", data)}
            multi={true}
            listModal={true}
            optionsForSearch={{ profile: true }}
            menuPlacement={"top"}
          />
        )}

        <div className="modalAddDellEmployees-btn">
          <Button
            type="submit"
            color="blue"
            title={add ? "Добавить" : "Удалить"}
            loading={loading}
            disabled={!options.profiles.value}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default ModalAddDellEmployees;
