import React from "react";
import { getFormatFIO } from "../../../../../../../utils/getFormatFIO";
import { getFormatDate } from "../../../../../../../utils/getFormatDate";
import { useModal } from "../../../../../../../hooks/useModal";
import Icons from "../../../../../../../components/Icons/Icons";
import "./MeetingMinutesMobileCard.scss";
import CardGridHeader from "../../../../../../../components/CardGridHeader/CardGridHeader";

const MeetingMinutesMobileCard = ({ info, check, addItemSelectProtocol, checkCard }) => {
  const {
    _id,
    name,
    number,
    tasks,
    secretary,
    employees_customer,
    employees_gencontractor,
    employees_executor,
    createdAt,
  } = info;

  const { changeModalVisible } = useModal();
  return (
    <div className="meetingMinutesMobileCard">
      {" "}
      <div className="meetingMinutesMobileCard-header">
        {checkCard ? (
          <div className="employeesCardMobile-header-mobile">
            <Checkbox check={check} onCheck={() => addItemSelectProtocol(_id)} />
            <span>{number}</span>
          </div>
        ) : (
          // <div className="meetingMinutesMobileCard-header-info">
          //   <div className="meetingMinutesMobileCard-header-info-text">
          //     <span className="meetingMinutesMobileCard-header-info-text-title"> Задач: </span>
          //     <span className="meetingMinutesMobileCard-header-info-text-count">
          //       {" "}
          //       {tasks?.length || 0}
          //     </span>
          //   </div>
          //   <Icons
          //     type={"menu"}
          //     color={"black"}
          //     size={"m-md"}
          //     onClick={() => changeModalVisible("menu", true, info)}
          //   />
          // </div>
          <CardGridHeader
            title={"Задач:"}
            text={tasks?.length || 0}
            onClick={() => changeModalVisible("menu", true, info)}
          />
        )}

        <h4 className="meetingMinutesMobileCard-header-title">{name}</h4>
      </div>
      <ul
        className="meetingMinutesMobileCard-content"
        onClick={() => changeModalVisible("infoProtocol", true, info)}
      >
        <li className="meetingMinutesMobileCard-content-item">
          <span className="meetingMinutesMobileCard-content-item-title">Дата создания:</span>
          <span className="meetingMinutesMobileCard-content-item-text">
            {getFormatDate(createdAt)}
          </span>
        </li>
        <li className="meetingMinutesMobileCard-content-item">
          <span className="meetingMinutesMobileCard-content-item-title">Автор протокола:</span>
          <span className="meetingMinutesMobileCard-content-item-text">
            {getFormatFIO(secretary.user)}
          </span>
        </li>

        <li className="meetingMinutesMobileCard-content-item">
          <span className="meetingMinutesMobileCard-content-item-title">
            Участников от Ген.подрядчика:
          </span>
          <span className="meetingMinutesMobileCard-content-item-text">
            {employees_gencontractor.length || 0}
          </span>
        </li>
        <li className="meetingMinutesMobileCard-content-item">
          <span className="meetingMinutesMobileCard-content-item-title">
            Участников от Заказчика:
          </span>
          <span className="meetingMinutesMobileCard-content-item-text">
            {employees_customer.length || 0}
          </span>
        </li>
        <li className="meetingMinutesMobileCard-content-item">
          <span className="meetingMinutesMobileCard-content-item-title">
            Участников от Исполнителя:
          </span>
          <span className="meetingMinutesMobileCard-content-item-text">
            {employees_executor.length || 0}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default MeetingMinutesMobileCard;
