import { createArray } from "./createArray.js";

const clearDoble = (arr) => {
  const resObj = {};
  const res = arr.filter(({ name }) => !resObj[name] && (resObj[name] = 1));
  return res;
};

export const profilesParsing = (key, profiles, typeRes) => {
  if (!key || !profiles) return;
  const arr = createArray(profiles, key);
  if (key === "departments" || key === "groups") {
    if (typeRes === "array") {
      return clearDoble([].concat(...arr));
    } else {
      return createArray(clearDoble([].concat(...arr)), "name").join(", ");
    }
  } else {
    if (typeRes === "array") {
      return clearDoble(arr);
    } else {
      return createArray(clearDoble(arr), "name").join(", ");
    }
  }
};
