import React, { useState, useEffect, useRef } from "react";
import "./ProfileMobileTabsListItem.scss";
import Icons from "../../../../../../../../../components/Icons/Icons";

// const

const ProfileMobileTabsListItem = ({ data, index, children, page }) => {
  const [height, setHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };
  const panelRef = useRef(null);
  const contentRef = useRef(null);

  const { name, permission, actionPermission } = data;

  useEffect(() => {
    const height = contentRef.current.lastChild.clientHeight;
    setHeight(height);
  }, [isActive]);

  const activateTab = (tab) => {
    setActiveTab(activeTab === tab ? -1 : tab);
  };
  return (
    <div
      className={`profileMobileTabsListItem ${
        page
          ? "profileMobileTabsListItem-page"
          : "profileMobileTabsListItem-category"
      }`}
    >
      <div
        className="profileMobileTabsListItem-panel"
        role="tabpanel"
        aria-expanded={isActive}
        ref={panelRef}
      >
        <div className="profileMobileTabsListItem-header">
          <div className="profileMobileTabsListItem-header-info">
            {permission === true && page && (
              <Icons type={"allowedIcon"} size={"md"} />
            )}
            {permission === false && <Icons type={"bannedIcon"} size={"md"} />}
            <span
              className={`${
                page
                  ? "profileMobileTabsListItem-title-page"
                  : "profileMobileTabsListItem-title"
              } ${
                permission === false
                  ? " profileMobileTabsListItem-title-disabled"
                  : ""
              }`}
            >
              {name}
            </span>
          </div>
          <button
            className={`profileMobileTabsListItem-btn ${
              isActive && "profileMobileTabsListItem-btn-active"
            }`}
            role="tab"
            onClick={() => activateTab(index)}
            //   disabled={disabled}
          >
            <Icons
              type={"arrowDown"}
              size={"m-md"}
              cursor
              className={`profileMobileTabsListItem-icon ${
                isActive ? "profileMobileTabsListItem-icon-active" : ""
              }`}
            />
          </button>
        </div>

        <div
          ref={contentRef}
          className="profileMobileTabsListItem-inner"
          aria-hidden={!isActive}
          style={innerStyle}
        >
          <div className="profileMobileTabsListItem-inner-content">
            <ul className="profileMobileTabsListItem-permissionList">
              {actionPermission.map((elem, index) => (
                <PermissionRow data={elem} key={index} />
              ))}
            </ul>
            {/* <p className="referenceItem-panel-content__text">{text}</p> */}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProfileMobileTabsListItem;

const PermissionRow = ({ data }) => {
  const getStatus = (type) => {
    switch (type) {
      case "read":
        return "Чтение";

      case "create":
        return "Добавление";

      case "update":
        return "Изменение";

      case "delete":
        return "Удаление";

      case "approve":
        return "Утверждение";

      case "export":
        return "Экспорт";

      default:
        return "";
    }
  };

  return (
    <li className="permissionRow">
      <div className="permissionRow-left">
        <span>{getStatus(data.type)}</span>
      </div>
      <div className="permissionRow-right">
        {data.access === "all" && <Icons type={"allowedIcon"} size={"md"} />}
        {/* {elem.access === "blocked" && <img src={bannedIcon} alt="" />} */}
        {(data.access === "Свои / Своего отдела" ||
          data.access === "Свои" ||
          data.access === "Своего отдела") && <span>{data.access}</span>}
      </div>
    </li>
  );
};
