import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchPanel from "../../../../../components/SearchPanel/SearchPanel";
import "./PhotoReports.scss";
import { useModal } from "../../../../../hooks/useModal";
import ModalDeleteItem from "../../../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalInfoEditFolder from "../../../../../components/ModalInfoEditFolder/ModalInfoEditFolder";
import ModalAddDoc from "../../../../../components/ModalAddDoc/ModalAddDoc";
import ModalSwiperPhoto from "../../../../../components/ModalSwiperPhoto/ModalSwiperPhoto";
import ModalViewDoc from "../../../../../components/ModalViewDoc/ModalViewDoc";
import ModalAddFolder from "../../../../../components/ModalAddFolder/ModalAddFolder";
import ModalAddToFolder from "../../../../../components/ModalAddToFolder/ModalAddToFolder";
import {
  asyncDeleteFile,
  asyncGetFolder,
} from "../../../../../store/reducers/documentationReduser";
import DocumentationList from "../../../../../components/DocumentationList/DocumentationList";
import ModalInfoEditFile from "../../../../../components/ModalInfoEditFile/ModalInfoEditFile";
import { useSelects } from "../../../../../hooks/useSelects";
import { useSearch } from "../../../../../hooks/useSearch";
import { useHasPermissions } from "../../../../../hooks/useHasPermissions";
import { getTypePages } from "../../../../../utils/getTypePages";

function PhotoReports({ viewCards }) {
  const project = useSelector((state) => state.projects.project);

  const { album: folder } = project;

  const dispatch = useDispatch();

  const [headFolder, setHeadFolder] = useState(null);

  const [fatherFolder, setFatherFolder] = useState(null);

  const [photoReports] = useState([]);
  const [activeSearchNav, setActiveSearchNav] = useState("genСontractor");
  const { visible, types = [] } = useHasPermissions("project:documentation", project);

  const [currentFolder] = useState("all");

  const { changeModalVisible, getInfo, getVisible } = useModal();

  const { search, queries, setQueryParam } = useSearch("project:photoReports");

  const [visibleHeader, setVisibleHeader] = useState(false);

  // * получаем папку
  const getFolder = async () => {
    if (queries.father) return search();
  };

  const getFolderById = async () => {
    const folderId = queries.father;
    const res = await dispatch(asyncGetFolder({ folderId }));
    if (res.error) return;

    setFatherFolder(res.payload);
    setHeadFolder(res.payload);

    return res.payload._id;
  };

  const deleteFolder = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteFolder", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteFolder", false, null);
  };

  const deleteDocument = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteDocument", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteDocument", false, null);
  };

  const changeActiveNavSearch = (tab) => {
    setQueryParam({ tab });
  };

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    (async () => {
      let father = folder._id;

      if (queries.father) {
        const id = await getFolderById();
        if (id) father = id;
      } else {
        setFatherFolder(folder);
        setHeadFolder(folder);
      }

      setQueryParam({ father });
    })();
  }, []);

  return (
    <div className="photoReports">
      {getVisible() && <div className="bgc-shadow"></div>}
      <SearchPanel
        navigationLeft={{
          visible: visible,
        }}
        buttons={{
          list: [
            {
              title: "Папка",
              onClick: () => changeModalVisible("addFolder"),
              icon: true,
            },

            {
              title: "Фото",
              onClick: () =>
                changeModalVisible("addDocument", true, {
                  folder: currentFolder,
                }),
              icon: true,
            },
          ],
        }}
        inputSearch={{
          visible: true,
          placeholder: "Найти фотографию или папку по названию",
        }}
        typePages={getTypePages(types)}
      />

      <DocumentationList
        viewCards={viewCards}
        setFatherFolder={setFatherFolder}
        fatherFolder={fatherFolder}
        visibleHeader={visibleHeader}
        setVisibleHeader={setVisibleHeader}
      />
      {/* Добавление документа */}
      {getVisible("addDocument") && (
        <ModalAddDoc
          fatherFolder={fatherFolder}
          handleChange={getFolder}
          typeFolder={"photos"}
          headFolder={headFolder}
        />
      )}

      {/* Добавление папки */}
      {getVisible("addFolder") && (
        <ModalAddFolder
          handleChange={getFolder}
          fatherFolder={fatherFolder}
          headFolder={headFolder}
        />
      )}

      {/* Просмотр/реадктирование папки */}
      {getVisible("infoFolder") && (
        <ModalInfoEditFolder
          fatherFolder={fatherFolder}
          handleChange={getFolder}
          headFolder={headFolder}
        />
      )}

      {/* Редактирование файла */}
      {getVisible("infoFile") && (
        <ModalInfoEditFile
          fatherFolder={fatherFolder}
          handleChange={getFolder}
          headFolder={headFolder}
        />
      )}

      {/* Добавление в папку */}
      {getVisible("addToFolder") && (
        <ModalAddToFolder
          infoDoc={getInfo("addToFolder")}
          headFolder={headFolder}
          fatherFolder={fatherFolder}
          handleChange={getFolder}
        />
      )}

      {/* Удаление документа */}
      {getVisible("deleteDocument") && (
        <ModalDeleteItem
          headerTitle="Удалить документ"
          title="Вы уверены, что хотите удалить этот документ?"
          nameModal="deleteDocument"
          onDelete={deleteDocument}
        />
      )}

      {/* Просмотр документов*/}
      {getVisible("viewDoc") && <ModalViewDoc />}

      {/* Удаление папки */}
      {getVisible("deleteFolder") && (
        <ModalDeleteItem
          headerTitle="Удалить папку"
          title="Вы уверены, что хотите удалить эту папку?"
          nameModal="deleteFolder"
          onDelete={deleteFolder}
        />
      )}

      {/* Просмотр фото */}
      {getVisible("swiperPhoto") && (
        <ModalSwiperPhoto
          changeModalVisible={changeModalVisible}
          info={getInfo("swiperPhoto", "data")}
          list={photoReports}
        />
      )}
    </div>
  );
}

export default PhotoReports;
