import React, { useState } from "react";
import "./ProfileMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import ProfileMobileEdit from "./components/ProfileMobileEdit/ProfileMobileEdit";
import ProfileMobileTabs from "./components/ProfileMobileTabs/ProfileMobileTabs";
import { ErrorBoundary } from "../../../ui";

const ProfileMobile = () => {
  const [chapter, setChapter] = useState("profileEdit");
  const changeWiewChapter = (value) => {
    setChapter(value);
  };
  return (
    <LayoutMobile
      currentPage={"profile"}
      footerBtnBack={
        chapter !== "profileEdit" && {
          visible: true,
          onClick: () => changeWiewChapter("profileEdit"),
        }
      }
      filterDisabled={true}
      footerBtnAddCircle={{
        visible: false,
      }}
      search={{
        inputSearch: { visible: false },
      }}
    >
      <ErrorBoundary>
        <div className="profileMobile">
          {chapter === "profileEdit" ? (
            <ProfileMobileEdit changeWiewChapter={changeWiewChapter} />
          ) : (
            <ProfileMobileTabs chapter={chapter} changeWiewChapter={changeWiewChapter} />
          )}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default ProfileMobile;
