import { transformForSelect } from "../../../utils/transformForSelect";
import { transformPriority } from "../../../utils/transformPriority";
import { transformStatus } from "../../../utils/transformStatus";
import { transformSection } from "../../../utils/transformSection";
import { transformTypeWork } from "../../../utils/transformTypeWork";
import { getFormatFIO } from "../../../utils/getFormatFIO";

export const getValueForOptions = (data, field) => {
  if (!data) return null;

  if (data[field]?.value) return data[field]?.value;

  if (field === "status") {
    if (data["status"])
      return transformForSelect(transformStatus(data["status"]));
    return { label: "Назначено", value: "assigned" };
  }

  if (field === "projects") {
    if (data["project"]) return transformForSelect(data["project"]);
    return null;
  }

  if (field === "layers") {
    if (data["layer"]) return transformForSelect(data["layer"]);
    return null;
  }

  if (field === "engineer") {
    if (data["engineer"] && data["engineer"]?._id) {
      return {
        label: `${getFormatFIO(data["engineer"]?.user)} (Компания:${
          data["engineer"]?.company?.name
        }; Должность: ${data["engineer"]?.position?.name})`,
        value: data["engineer"]?._id,
      };
    }

    return [];
  }

  if (field === "section") {
    if (data["section"] && ["section"]?.value) return data["section"];
    else if (data["section"] && typeof data["section"] === "string")
      return transformForSelect(transformSection(data["section"]));
    return null;
  }

  if (field === "type_work") {
    if (data["type_work"] && ["type_work"]?.value) return data["type_work"];
    else if (data["type_work"] && typeof data["type_work"] === "string")
      return transformForSelect(transformTypeWork(data["type_work"]));
    return null;
  }
  if (field === "author") {
    if (data["author"] && data["author"]?._id) {
      return {
        label: `${getFormatFIO(data["author"]?.user)} (Компания: ${
          data["author"]?.company?.name
        }; Должность: ${data["author"]?.position?.name})`,
        value: data["author"]?._id,
      };
    }
    return null;
  }
  return null;
};
