import { getFormatFIO } from "../../../utils/getFormatFIO";
import { transformForSelect } from "../../../utils/transformForSelect";
import { transformNotification } from "../../../utils/transformNotification";

export const getValueForOptions = (data, field) => {
  if (!data) {
    if (
      field === "employees_gencontractor" ||
      field === "employees_customer" ||
      field === "employees_executor"
    ) {
      return [];
    }

    return null;
  }
  if (data[field]?.value) return data[field]?.value;

  if (field === "employees_gencontractor") {
    if (data["employees_gencontractor"] && Array.isArray(data["employees_gencontractor"])) {
      return data["employees_gencontractor"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_gencontractor"])) {
      return data["protocol"]["employees_gencontractor"].map((e) => ({
        label: `${getFormatFIO(e.user)} (Компания: ${e.company.name}; Должность: ${
          e.position.name
        };)`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "employees_customer") {
    if (data["employees_customer"] && Array.isArray(data["employees_customer"])) {
      return data["employees_customer"].map((e) => ({
        label: `${getFormatFIO(e.user)} ${e.position.name} ${e.company.name}`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_customer"])) {
      return data["protocol"]["employees_customer"].map((e) => ({
        label: `${getFormatFIO(e.user)} (Компания: ${e.company.name}; Должность: ${
          e.position.name
        };)`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "employees_executor") {
    if (data["employees_executor"] && Array.isArray(data["employees_executor"])) {
      return data["employees_executor"].map((e) => ({
        label: `${getFormatFIO(e.user)} (Компания: ${e.company.name}; Должность: ${
          e.position.name
        };)`,
        value: e._id,
      }));
    } else if (data["protocol"] && Array.isArray(data["protocol"]["employees_executor"])) {
      return data["protocol"]["employees_executor"].map((e) => ({
        label: `${getFormatFIO(e.user)} (Компания: ${e.company.name}; Должность: ${
          e.position.name
        };)`,
        value: e._id,
      }));
    }
    return [];
  }

  if (field === "employees") {
    if (data["employees"] && Array.isArray(data["employees"]))
      return data["employees"].map((e) => ({
        label: `${getFormatFIO(e.user)} (Компания: ${e.company.name}; Должность: ${
          e.position.name
        };)`,
        value: e._id,
      }));
    return [];
  }

  if (field === "project") {
    if (data["protocol"] && data["protocol"]["project"]?.value)
      return transformForSelect(data["protocol"]["project"]);
    else if (data["protocol"] && data["protocol"]["project"]?.name)
      return transformForSelect(data["protocol"]["project"]);
    return null;
  }

  if (field === "secretary") {
    if (data["secretary"] && ["secretary"]?.value)
      return {
        label: `${getFormatFIO(data["secretary"].user)} (Компания: ${
          data["secretary"].company.name
        }; Должность: ${data["secretary"].position.name};)`,
        value: data["secretary"]._id,
      };
    else if (data["protocol"] && data["protocol"]["secretary"]?._id)
      return {
        label: `${getFormatFIO(data["protocol"]["secretary"].user)} (Компания: ${
          data["protocol"]["secretary"].company.name
        }; Должность: ${data["protocol"]["secretary"].position.name};)`,
        value: data["protocol"]["secretary"]._id,
      };
    return null;
  }

  if (field === "author") {
    if (["author"]?.value) {
      return {
        label: data["author"].label,
        value: data["author"].value,
      };
    } else if (data["author"]?._id) {
      return {
        label: `${getFormatFIO(data["author"].user)} (Компания: ${
          data["author"].company.name
        }; Должность: ${data["author"].position.name};)`,
        value: data["author"]._id,
      };
    }
    return null;
  }

  if (field === "notificationFrequency") {
    if (data["notifications"])
      return transformForSelect(transformNotification(data["notifications"]));
    return null;
  }

  return null;
};
