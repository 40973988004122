import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "../Select/Select";
import "./InputSearch.scss";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { useSelector } from "react-redux";
import SelectMulti from "../SelectMulti/SelectMulti";
import { asyncGetResponsibleCompany } from "../../store/reducers/companiesReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function InputSearch(props) {
  const {
    onChange,
    handleChange,
    title,
    placeholder,
    type,
    forInput,
    validate,
    multi,
    isClearable = true,
    value: optionsValue = [],
    optionsForSearch = {},
    isDisabled,
    listModal,
    menuPlacement,
    stylesSelect,
  } = props;

  const dispatch = useDispatch();

  const { options: optionsFitler } = useSelector((state) => state.filter);

  const [value, setValue] = useState(null);

  const { options, changeOptions } = useChangeOptions({
    select: {
      options: [],
      value: multi ? [] : optionsValue,
    },
  });

  const formatLabel = (info) => {
    const fio = getFormatFIO(info.user);
    const company = info.company ? `Компания: ${info.company.name};` : "";
    const position = info.position ? `Должность: ${info.position.name};` : "";
    return fio + " " + "( " + company + " " + position + ")";
  };

  const onChangeSelect = async (data) => {
    if (type === "users+companies") {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          const { label, value } = data[i];

          if (label.includes("Компания -")) {
            const res = await dispatch(
              asyncGetResponsibleCompany({
                company: value,
                project: optionsForSearch.project,
              }),
            );

            if (res.error) return;

            const responsible = res.payload;

            if (!responsible?.responsible && !responsible?.director) {
              return toast.error("Не удалось найти ответственное лицо от компании");
            }

            const responsibleLabel = formatLabel(responsible.responsible || responsible.director);

            const newItemLabel = responsibleLabel;
            const newItemValue = responsible?.responsible?._id || responsible.director._id;

            const companyName = label.replace("Компания - ", "");

            if (options.select?.value) {
              if (!Array.isArray(options.select.value)) {
                options.select.value = [options.select.value];
              }

              if (options.select.value.find((v) => v.value === newItemValue)) {
                toast.success(
                  `Ответственное лицо от компании ${companyName} ${newItemLabel} уже есть в списке`,
                );
                return;
              }

              const selectValue = [
                ...options.select.value,
                {
                  label: newItemLabel,
                  value: newItemValue,
                },
              ];

              toast.success(
                `Ответственным лицом от компании ${companyName} был добавлен ${responsibleLabel}`,
              );

              changeOptions("select", "value", selectValue);
              handleChange(selectValue);
              return;
            }
          }
        }
      } else {
        const { label, value } = data;

        if (label.includes("Компания -")) {
          const res = await dispatch(
            asyncGetResponsibleCompany({
              company: value,
              project: optionsForSearch.project,
            }),
          );

          if (res.error) return;

          const responsible = res.payload;

          if (!responsible?.responsible && !responsible?.director) {
            return toast.error("Не удалось найти ответственное лицо от компании");
          }

          const responsibleLabel = formatLabel(responsible.responsible || responsible.director);

          const newItemLabel = responsibleLabel;
          const newItemValue = responsible?.responsible?._id || responsible.director._id;

          const companyName = label.replace("Компания - ", "");

          if (options.select.value?.value === newItemValue) {
            toast.success(
              `Ответственное лицо от компании ${companyName} ${newItemLabel} уже есть в списке`,
            );
            return;
          }

          const selectValue = {
            label: newItemLabel,
            value: newItemValue,
          };

          toast.success(
            `Ответственным лицом от компании ${companyName} был добавлен ${responsibleLabel}`,
          );

          changeOptions("select", "value", selectValue);
          handleChange(selectValue);
          return;
        }
      }
    }

    changeOptions("select", "value", data);
    handleChange(data);
  };

  // * type === users
  const searchUsers = async () => {
    const res = await axios.post(`/users/get_by_surname/${value}`, optionsForSearch);

    const emptyOrder = Object.keys(optionsForSearch).length === 0;

    if (!emptyOrder && optionsForSearch.profile) {
      return (
        res.data.map((u) => ({
          label: formatLabel(u),
          value: u._id,
        })) || []
      );
    } else {
      return res.data.map((u) => ({ label: getFormatFIO(u), value: u._id })) || [];
    }
  };

  // * type === region
  const searchRegion = async () => {
    const res = await axios.post(`/regions/get_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  // * type === project
  const searchProject = async () => {
    const res = await axios.post(`/projects/get_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  // * type === company
  const searchCompanies = async () => {
    const res = await axios.post(`/companies/get_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  // * type === department
  const searchDepartment = async () => {
    const res = await axios.post(`/structures/get_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  // * type === group
  const searchGroups = async () => {
    const res = await axios.post(`/groups/get_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  // * type === scheduleWorks
  const searchScheduleWorks = async () => {
    const res = await axios.post(`/scheduleWorks/get_work_by_name/${value}`, optionsForSearch);
    return res.data.map((u) => ({ label: u.name, value: u._id })) || [];
  };

  const search = async () => {
    let data = [];
    if (type === "region") {
      data = await searchRegion();
    } else if (type === "project") {
      data = await searchProject();
    } else if (type === "company") {
      data = await searchCompanies();
    } else if (type === "department") {
      data = await searchDepartment();
    } else if (type === "group") {
      data = await searchGroups();
    } else if (type === "scheduleWorks") {
      data = await searchScheduleWorks();
    } else if (type === "users+companies") {
      const users = await searchUsers();
      const companies = await searchCompanies();

      data = [
        ...companies.map((c) => ({
          label: `Компания - ${c.label}`,
          value: c.value,
        })),
        ...users,
      ];
    } else {
      data = await searchUsers();
    }

    changeOptions("select", "options", data);
  };

  useEffect(() => {
    if (onChange) onChange(value);

    if (value?.trim()) {
      search();
    } else {
      changeOptions("select", "options", []);
    }
  }, [value]);

  useEffect(() => {
    if (optionsFitler.director === null) {
      changeOptions("select", "value", null);
    }
  }, [optionsFitler.director]);

  useEffect(() => {
    changeOptions("select", "value", optionsValue);
  }, [optionsValue]);

  const checkRequaerd = (title) => {
    const arr = title.split("");
    if (arr.at(-1) === "*") {
      arr.splice(arr.length - 1);
      return (
        <div className="inputSearch-title">
          <span>{arr}</span>
          <span className="inputSearch-title-red"> *</span>
        </div>
      );
    } else {
      return <span className="inputSearch-title">{title}</span>;
    }
  };

  const getStylesOptions = () => {
    return {
      option: (base, state) => {
        return {
          ...base,
          color: state.data.label.includes("Компания - ") ? "#04488e" : base.color,
        };
      },
    };
  };

  return (
    <div className={`inputSearch  ${forInput ? "forInput" : ""}`}>
      {!!title && checkRequaerd(title)}
      {multi ? (
        <SelectMulti
          forInput={forInput}
          placeholder={placeholder || "Найти"}
          handleChange={onChangeSelect}
          value={options.select.value}
          options={options.select.options}
          onInputChange={(v) => setValue(v)}
          validate={validate}
          isDisabled={isDisabled}
          listModal={listModal}
          menuPlacement={menuPlacement}
          styles={{ ...stylesSelect, ...getStylesOptions() }}
        />
      ) : (
        <Select
          forInput={forInput}
          placeholder={placeholder || "Найти"}
          handleChange={onChangeSelect}
          value={options.select.value}
          options={options.select.options}
          onInputChange={(v) => setValue(v)}
          validate={validate}
          isClearable={isClearable}
          isDisabled={isDisabled}
          menuPlacement={menuPlacement}
          styles={stylesSelect}
        />
      )}
    </div>
  );
}

export default InputSearch;
