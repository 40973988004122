import React from "react";
import "./ModalChooseAddStepWork.scss";
import Button from "../../../../../../../components/Button/Button";
import ModalWrapper from "../../../../../../../components/ModalWrapper/ModalWrapper";
import { useModal } from "../../../../../../../hooks/useModal";

function ModalChooseAddStepWork(props) {
  const { changeModalVisible } = useModal();
  return (
    <ModalWrapper
      width="614px"
      onClose={() => changeModalVisible("chooseStageOrWork", false)}
      title="Добавить"
      noscroll={false}
    >
      <div className="modalChooseAddStepWork">
        <div className="modalChooseAddStepWork-main">
          <span className="modalChooseAddStepWork-main-title title">
            Выберите:
          </span>
          <div className="d-flex justify-center gap-10">
            <div className="modalChooseAddStepWork-main-card">
              <span className="number">1</span>
              <div className="hr"></div>
              <span className="title">Добавить этап</span>
              <Button
                onClick={() =>
                  changeModalVisible("infoStage", true, null, false, true)
                }
                title="Выбрать"
                color="blue"
              />
            </div>
            <div className="modalChooseAddStepWork-main-card">
              <span className="number">2</span>
              <div className="hr"></div>
              <span className="title">Добавить работу</span>
              <Button
                onClick={() =>
                  changeModalVisible("infoWork", true, null, false, true)
                }
                title="Выбрать"
                color="blue"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalChooseAddStepWork;
