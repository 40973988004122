import React, { useState, useEffect } from "react";
import { useChangeIV } from "../../hooks/useChangeIV";
import Input from "../Input/Input";
import "./ModalInfoEditFile.scss";
import Button from "../Button/Button";
import ListModal from "../ListModal/ListModal";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Select from "../Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetPermissions } from "../../store/reducers/documentationReduser";
import SelectMulti from "../SelectMulti/SelectMulti";
import { useChangeOptions } from "../../hooks/useChangeOptions";
import { getFormatFIO } from "../../utils/getFormatFIO";
import { asyncEditFile } from "../../store/reducers/documentationReduser";
import { getForSelect } from "../../utils/getForSelect";
import { useModal } from "../../hooks/useModal";
import AccessEdit from "../AccessEdit/AccessEdit";
import AccessInfo from "../AccessInfo/AccessInfo";
import { createArray } from "../../utils/createArray";
import { formatLabel } from "../../utils/formatLabel";
import WrapperLoader from "../WrapperLoader/WrapperLoader";

const ModalInfoEditFile = ({ fatherFolder, handleChange, headFolder }) => {
  const { changeModalVisible, getInfo } = useModal();
  const info = getInfo("infoFile", "data");
  const edit = getInfo("infoFile", "editMode");

  const dispatch = useDispatch();

  const [permissionsListСompanies, setPermissionsListСompanies] = useState({}); // все доступные компании
  const [permissionsListProfiles, setPermissionsListProfiles] = useState({}); // все доступные компании

  const [permissionsСompanies, setPermissionsСompanies] = useState({});
  const [permissionsProfiles, setPermissionsProfiles] = useState({});

  const [loading, setLoading] = useState(false);

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: info?.name,
  });

  const getPermissions = async (type = "child", id) => {
    setLoading(true);
    const res = await dispatch(asyncGetPermissions({ fileId: id }));

    if (res.error) return setLoading(false);
    const companiesAccesses = res.payload.companies_accesses;
    const profilesAccesses = res.payload.profiles_accesses;

    if (type === "father") {
      setPermissionsListСompanies({
        view: companiesAccesses.view || [],
        add: companiesAccesses.add || [],
        edit: companiesAccesses.edit || [],
        copy: companiesAccesses.copy || [],
        move: companiesAccesses.move || [],
        remove: companiesAccesses.remove || [],
      });
      setPermissionsListProfiles({
        view:
          profilesAccesses.view.map((u) => ({
            name: formatLabel(u),
            _id: u._id,
          })) || [],
        add: profilesAccesses.add || [],
        edit: profilesAccesses.edit || [],
        copy: profilesAccesses.copy || [],
        move: profilesAccesses.move || [],
        remove: profilesAccesses.remove || [],
      });
    } else {
      setPermissionsСompanies({
        view: companiesAccesses.view || [],
        add: companiesAccesses.add || [],
        edit: companiesAccesses.edit || [],
        copy: companiesAccesses.copy || [],
        move: companiesAccesses.move || [],
        remove: companiesAccesses.remove || [],
      });
      setPermissionsProfiles({
        view:
          profilesAccesses.view.map((u) => ({
            name: formatLabel(u),
            _id: u._id,
          })) || [],
        add: profilesAccesses.add || [],
        edit: profilesAccesses.edit || [],
        copy: profilesAccesses.copy || [],
        move: profilesAccesses.move || [],
        remove: profilesAccesses.remove || [],
      });
    }
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3, maxHeight: 50 })) return;
    setLoading(true);
    await dispatch(
      asyncEditFile({
        fileId: info._id,
        data: {
          name: IV.name,
          companies_accesses: {
            view: createArray(permissionsСompanies.view, "_id"),
            add: createArray(permissionsСompanies.add, "_id"),
            edit: createArray(permissionsСompanies.edit, "_id"),
            copy: createArray(permissionsСompanies.copy, "_id"),
            move: createArray(permissionsСompanies.move, "_id"),
            remove: createArray(permissionsСompanies.remove, "_id"),
          },
          profiles_accesses: {
            view: createArray(permissionsProfiles.view, "_id"),
            add: createArray(permissionsProfiles.add, "_id"),
            edit: createArray(permissionsProfiles.edit, "_id"),
            copy: createArray(permissionsProfiles.copy, "_id"),
            move: createArray(permissionsProfiles.move, "_id"),
            remove: createArray(permissionsProfiles.remove, "_id"),
          },
        },
      })
    );
    handleChange && handleChange();
    setLoading(false);
    changeModalVisible("infoFile", false);
  };

  useEffect(() => {
    getPermissions("child", info._id);
    if (fatherFolder) getPermissions("father", fatherFolder._id);
  }, []);

  return (
    <div>
      <ModalWrapper
        onClose={() => changeModalVisible("infoFile", false)}
        title={`Редактировать файл ${info?.name}`}
        minHeight={false}
      >
        {edit ? (
          <form onSubmit={onSubmit} className="modalInfoEditFile">
            <Input
              title="Название файла:"
              placeholder="Введите название файла"
              name="name"
              value={IV.name}
              onChange={changeIV}
              validate={checkValidateIV("name")}
            />
            <div className="hr"></div>
            <span className="modalInfoEditFolder-main-title">Доступы:</span>
            <WrapperLoader loading={loading} chapter={"permissionsFileEdit"}>
              <AccessEdit
                permissionsList={permissionsListСompanies}
                permissions={permissionsСompanies}
                setPermissions={setPermissionsСompanies}
                typePermissions={"company"}
                fatherFolder={fatherFolder}
                typeFile={"file"}
                searchProfile={fatherFolder._id === headFolder._id}
              />
              <AccessEdit
                permissionsList={permissionsListProfiles}
                permissions={permissionsProfiles}
                setPermissions={setPermissionsProfiles}
                typePermissions={"profile"}
                fatherFolder={fatherFolder}
                typeFile={"file"}
                searchProfile={fatherFolder._id === headFolder._id}
              />
            </WrapperLoader>
            <div className="d-flex justify-center">
              <Button
                disabled={IV.name?.length <= 3}
                type="submit"
                title="Сохранить"
                color="blue"
                loading={loading}
              />
            </div>
          </form>
        ) : (
          <div className="modalInfoEditFile-info">
            <div className="modalInfoEditFolder-info-name">
              <span className="modalInfoEditFolder-info-name-title">
                Название файла:
              </span>
              <span className="modalInfoEditFolder-info-name-text">
                {info?.name}
              </span>
            </div>
            <div className="hr"></div>
            <span className="modalInfoEditFolder-info-title">Доступы:</span>
            <WrapperLoader loading={loading} chapter={"permissionsFile"}>
              <AccessInfo
                permissions={permissionsСompanies}
                typePermissions={"company"}
                typeFile={"file"}
              />
              <AccessInfo
                permissions={permissionsProfiles}
                typePermissions={"profile"}
                typeFile={"file"}
              />
            </WrapperLoader>
          </div>
        )}
      </ModalWrapper>
    </div>
  );
};

export default ModalInfoEditFile;
