import React, { useState, useRef } from "react";
import "./SearchBox.scss";
import Icons from "../../../../../Icons/Icons";
import { useDispatch } from "react-redux";
import { asyncGetReferences } from "../../../../../../store/reducers/referencesReducer";
import { useModal } from "../../../../../../hooks/useModal";
import { useEffect } from "react";
import { useSearch } from "../../../../../../hooks/useSearch";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const SearchBox = ({
  activeSearch,
  onClickActive,
  inputSearch = { visible: false, placeholder: null },
  onClick,
  onChange,
  onSubmit,
  currentPage,
}) => {
  const dispatch = useDispatch();

  const { setQueryParam, queries } = useSearch();

  const [IV, setIV] = useState("");
  const [inputRef, setInputFocus] = useFocus();

  const clearInput = () => {
    setIV("");
    onSubmit && onSubmit("");
    setQueryParam({ name: null });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) onSubmit(IV);
    else {
      if (IV.trim().length) setQueryParam({ name: IV });
    }
  };

  const onFormChange = (e) => {
    const value = e.target.value;
    setIV(value);

    if (onChange) {
      onChange(value);
    } else {
      if (value === "" && queries.name?.trim()?.length) {
        setQueryParam({ name: null });
      }
    }
  };

  useEffect(() => {
    if (queries.name) setIV(queries.name);
    else setIV("");
  }, [queries.name]);
  return (
    <>
      {inputSearch.visible ? (
        <form
          className={`searchBox ${activeSearch ? "active" : ""}`}
          onClick={onClickActive}
          onSubmit={onFormSubmit}
        >
          {activeSearch && (
            <input
              className="searchBox-input"
              value={IV}
              onChange={onFormChange}
              type="text"
              maxLength={30}
              placeholder={
                inputSearch.placeholder ? inputSearch.placeholder : "Поиск"
              }
              ref={inputRef}
              autoFocus
            />
          )}
          {activeSearch && (
            <div
              onClick={() => clearInput()}
              className={`searchBox-buttons-close ${
                IV.length > 0 ? "searchBox-buttons-close-active" : ""
              }`}
            >
              <Icons type={"crossBlackIcon"} size={"m-xl"} cursor />
            </div>
          )}

          <button
            className="searchBox-buttons-white"
            href="#"
            onClick={setInputFocus}
          >
            {/* <i className="material-icons">search</i> */}
            <Icons
              type={"search"}
              size={"m-xl"}
              color={"white"}
              className={"searchBox-buttons-iconWhite"}
            />
          </button>
          <button className="searchBox-buttons-dark" href="#">
            <Icons
              type={"search"}
              size={"m-xl"}
              color={"black"}
              className={"searchBox-buttons-iconDark"}
              onClick={(e) => onFormSubmit(e)}
            />
          </button>
        </form>
      ) : (
        <div className="searchBox"></div>
      )}
    </>
  );
};

export default SearchBox;
