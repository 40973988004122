import React, { useState, useEffect } from "react";
import LayoutMobileHeader from "./components/LayoutMobileHeader/LayoutMobileHeader";
import LayoutMobileFooter from "./components/LayoutMobileFooter/LayoutMobileFooter";
import "./LayoutMobile.scss";
import ModalWrapperMobile from "../ModalWrapperMobile/ModalWrapperMobile";
import { useModal } from "../../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";

import { useSearch } from "../../hooks/useSearch";
const LayoutMobile = ({
  currentPage: currentPageProps,
  children,
  inputSearch,
  footerBtnBack,
  footerBtnAddCircle,
  onChangeSearch,
  onSubmitSearch,
  filterOnAccept,
  filterDisabled,
  viewCards,
  onViewClick,
  activePage,
  footerBtnRotate,
  search,
  titleLayout,
  changeModalVisible,
  visibleModal,
  viewChapterPhoto,
  changeChapterPhoto,
  chapter,
  disabledScroll,
}) => {
  const { getCurrentPage } = useSearch();

  const [currentPage, setCurrentPage] = useState(currentPageProps); // если требуется отправить кастомный currentPage
  const page = getCurrentPage();

  useEffect(() => {
    if (currentPageProps) return;

    if (page) setCurrentPage(page);
  }, [currentPageProps, page]);

  return (
    <>
      <div className="layoutMobile">
        <LayoutMobileHeader
          currentPage={currentPage}
          inputSearch={inputSearch}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          search={search}
          activePage={activePage}
          titleLayout={titleLayout}
        />
        <main className={`main-mobile `}>
          <div className="mobile-container">{children}</div>
        </main>
        <div id="portal"></div>
        <LayoutMobileFooter
          footerBtnAddCircle={footerBtnAddCircle}
          footerBtnBack={footerBtnBack}
          filterDisabled={filterDisabled}
          filterOnAccept={filterOnAccept}
          currentPage={currentPage}
          viewCards={viewCards}
          onViewClick={onViewClick}
          activePage={activePage}
          footerBtnRotate={footerBtnRotate}
          viewChapterPhoto={viewChapterPhoto}
          changeChapterPhoto={changeChapterPhoto}
        />
      </div>
    </>
  );
};

export default LayoutMobile;
