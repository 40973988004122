import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getOptionsFilters } from "../../utils/getOptionsFilters";
import { getLimitElements } from "../../utils/getLimitElements";

export const asyncGetCompanies = createAsyncThunk(
  "companies/asyncGetCompanies",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const limit = data?.params?.limit || getLimitElements();
      const res = await axios.post(`/companies/get/${data.page}/?limit=${limit}`, data.params);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetCompany = createAsyncThunk("companies/get_company", async (id, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/companies/get_company/${id}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetCompanyByInn = createAsyncThunk(
  "companies/asyncGetCompanyByInn",
  async (inn, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/companies/get_by_inn/${inn}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetCompanyTypes = createAsyncThunk(
  "companies/asyncGetCompanyTypes",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post("/companies/types");
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetCompanyResponsible = createAsyncThunk(
  "companies/asyncGetCompanyResponsible",
  async (companyId, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/responsible/${companyId}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncCreateCompany = createAsyncThunk(
  "companies/asyncCreateCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post("/companies/create", data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditCompany = createAsyncThunk(
  "companies/asyncEditCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const { formData, company_id } = data;
      const res = await axios.patch(`/companies/edit/${company_id}`, formData);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditProfileCompany = createAsyncThunk(
  "companies/asyncEditProfileCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/companies/edit_profile/${data.companyId}`, data.data);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditLogoCompany = createAsyncThunk(
  "companies/asyncEditLogoCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const { formData, company_id } = data;
      const res = await axios.patch(`/companies/edit_logo/${company_id}`, formData);

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncRemoveCompany = createAsyncThunk(
  "companies/asyncRemoveCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/companies/remove/${data.company_id}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetResponsibleCompany = createAsyncThunk(
  "companies/asyncGetResponsibleCompany",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/companies/get_responsible`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncGetResponsibles = createAsyncThunk(
  "companies/asyncGetResponsibles",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/companies/get_responsibles/${data.companyId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncEditResponsibles = createAsyncThunk(
  "companies/asyncEditResponsibles",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/companies/edit_responsibles/${data.companyId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const companiesReducer = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    count: 0,
    typeCompanies: [],
    company: null,
    edit: false,
    loading: false,
  },
  reducers: {
    set_companies: (state, action) => {
      const { companies } = action.payload;
      state.companies = companies;
    },
    set_edit_сompany_mode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetCompanies.fulfilled]: (state, { payload }) => {
      state.companies = payload.companies;
      state.count = payload.count;
    },
    [asyncGetCompanies.rejected]: "Не удалось получить компании.",

    [asyncGetCompany.fulfilled]: (state, { payload }) => {
      state.company = payload;
    },

    [asyncCreateCompany.fulfilled]: "Компания успешно создана.",
    [asyncCreateCompany.rejected]: "Не удалось создать компанию.",

    [asyncGetResponsibles.rejected]: "Не удалось получить ответственных по проекту компании.",

    [asyncGetResponsibleCompany.rejected]:
      "Не удалось получить ответственного по проекту компании.",

    [asyncEditCompany.fulfilled]: "Информация успешно изменена.",
    [asyncEditCompany.rejected]: "Не удалось изменить компанию.",

    [asyncEditResponsibles.fulfilled]: "Ответственный успешно изменен.",
    [asyncEditResponsibles.rejected]: "Не удалось изменить отвественного.",

    [asyncEditProfileCompany.fulfilled]: "Информация сотрудников компании успешно изменена.",
    [asyncEditProfileCompany.rejected]: "Не удалось изменить информациию сотрудников компании.",

    [asyncEditLogoCompany.fulfilled]: "Логотип успешно изменен.",
    [asyncEditLogoCompany.rejected]: "Не удалось изменить логотип.",

    [asyncRemoveCompany.fulfilled]: "Компания успешно удалена.",
    [asyncRemoveCompany.rejected]: "Не удалось удалить компанию.",

    [asyncGetCompanyTypes.fulfilled]: (state, { payload }) => {
      state.typeCompanies = payload;
    },
    [asyncGetCompanyTypes.rejected]: "Не удалось получить типы компаний.",

    [asyncGetCompanyByInn.rejected]: "Не удалось получить компанию по ИНН.",

    [asyncGetCompanyResponsible.rejected]: "Не удалось получить ответственного компании.",
  }),
});

export const { set_edit_сompany_mode } = companiesReducer.actions;

export default companiesReducer.reducer;
