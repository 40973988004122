import React, { useEffect, useState } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import "./DeliveryWorkMobile.scss";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeliveryWorkMobileCard from "./components/DeliveryWorkMobileCard/DeliveryWorkMobileCard";
import {
  asyncDeleteDeliveryWork,
  asyncGetDeliveryWorks,
} from "../../../store/reducers/deliveryWorkReducer";
import { useModal } from "../../../hooks/useModal";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import ModalAddDeliveryWorkMobile from "./components/ModalAddDeliveryWorkMobile/ModalAddDeliveryWorkMobile";
import DeliveryWorkRequestsMobile from "./components/DeliveryWorkRequestsMobile/DeliveryWorkRequestsMobile";
import DeliveryWorkRequestsMobileCard from "./components/DeliveryWorkRequestsMobileCard/DeliveryWorkRequestsMobileCard";
import ModalEditDeliveryWork from "../../../components/ModalEditDeliveryWork/ModalEditDeliveryWork";
import { ErrorBoundary } from "../../../ui";

const DeliveryWorkMobile = () => {
  const { getVisible, changeModalVisible, getInfo } = useModal();
  const [requestsTitle, setRequestsTitle] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deliveryWorks, count, loading } = useSelector((state) => state.deliveryWorks);
  const [openProject, setOpenProject] = useState(false);
  const [requests, setRequests] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [importFile, setImportFile] = useState(false);

  const getWorks = async (page = 1, filter = {}) => {
    await dispatch(asyncGetDeliveryWorks({ page, myFilter: filter }));
  };

  const changePage = (page = 1) => {
    navigate("/deliveryWork/" + page);
  };

  const deleteWork = async () => {
    const { _id: workId } = getInfo("deleteDeliveryWork", "data");
    if (workId) {
      await dispatch(asyncDeleteDeliveryWork(workId));
      await getWorks();
    }
    changeModalVisible("deleteDeliveryWork", false);
  };

  const changeSearchIV = (v) => {
    if (v === "") {
      getWorks(1);
    }
  };

  const handleOpenProject = (activeId) => {
    setOpenProject(true);
    setRequests(deliveryWorks.find((item) => item.id === activeId).requests);

    setRequestsTitle(
      deliveryWorks.find((item) => item.id === activeId).basicInformation.nameProject,
    );
  };

  useEffect(() => {
    getWorks(params.page);
    setPageNumber(params.page);
  }, [params.page]);

  useEffect(() => {
    changePage(params.page || 1);
  }, []);

  return (
    <LayoutMobile
      currentPage={"deliveryWork"}
      search={{
        inputSearch: {
          visible: getVisible("сheckingDeliveryWork") || openProject ? false : true,
          placeholder: "Найти проект по названию",
        },
      }}
      filter={{ visible: true }}
      filterDisabled={getVisible("сheckingDeliveryWork") || openProject ? true : false}
      footerBtnAddCircle={
        !getVisible("сheckingDeliveryWork") && {
          visible: true,
          onClick: () => changeModalVisible("addDeliveryWork", true),
        }
      }
    >
      <ErrorBoundary>
        {openProject ? (
          // <DeliveryWorkRequestsMobile
          //   info={requests}
          //   handleOpenProject={handleOpenProject}
          //   setOpenProject={setOpenProject}
          //   requestsTitle={requestsTitle} // временно
          // />
          <></>
        ) : (
          <div className="deliveryWorkMobile">
            <HeaderMobilePage
              currentPage="deliveryWork"
              title={"Всего запросов: 15"}

              // active={chapter}
              // currentPage={"tasks"}
              // changeWiewChapter={changeWiewChapter}
            />

            <ul className="deliveryWorkMobile-grid">
              {deliveryWorks.map((deliveryWork, index) => {
                return (
                  <DeliveryWorkRequestsMobileCard
                    key={deliveryWork.id}
                    id={deliveryWork.id}
                    info={deliveryWork}
                    // number={index + 1 + requestsCount}
                    // onCheck={() => {
                    //   addItemSelect(deliveryWork.id);
                    // }}
                    // check={selectItems.includes(elem.id)}
                    changeModalVisible={changeModalVisible}
                  />
                );
              })}
            </ul>
          </div>
        )}
        {getVisible("menu") && (
          <ModalMobileMenu
            changeModalVisible={changeModalVisible}
            chapter={"deliveryWork"}
            nameModal={"menu"}
            // setCompanyLength={setCompanyLength}
            handleOpenProject={handleOpenProject}
            openProject={openProject}
          />
        )}
        {getVisible("addDeliveryWorkMobile") && <ModalAddDeliveryWorkMobile info={deliveryWorks} />}
        {/* Создать работу */}
        {getVisible("addDeliveryWork") && (
          <ModalEditDeliveryWork handleChange={() => getWorks(pageNumber)} mode="add" />
        )}
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default DeliveryWorkMobile;
