import React from "react";
import "./СheckboxSwitch.scss";

const СheckboxSwitch = ({ check, onCheck }) => {
  return (
    <label className="checkboxSwitch">
      <input
        className="checkboxSwitch__input"
        type="checkbox"
        defaultChecked={check}
        onChange={onCheck}
      />
      <span className="checkboxSwitch__slider"></span>
    </label>
  );
};

export default СheckboxSwitch;
