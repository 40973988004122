import React, { useState, useEffect } from "react";
import "./ProjectsMobile.scss";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import WrapperLoader from "../../../components/WrapperLoader/WrapperLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  asyncDeleteProject,
  asyncGetProjects,
  setEditProjectMode,
  setProject,
} from "../../../store/reducers/projectsReducer";
import CardColumnProject from "../../../components/CardColumnProject/CardColumnProject";
import EmptySection from "../../../components/EmptySection/EmptySection";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalAddProject from "../../../components/ModalAddProject/ModalAddProject";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import { useModal } from "../../../hooks/useModal";
import { useSearch } from "../../../hooks/useSearch";
import Pagination from "../../../components/Pagination/Pagination";
import { ErrorBoundary } from "../../../ui";

const ProjectsMobile = () => {
  const dispatch = useDispatch();

  const { projects, count, loading } = useSelector((state) => state.projects);

  const { queries } = useSearch("projects");
  const [chapter, setChapter] = useState("all"); // all | creator | member
  const [viewCards, setViewCards] = useState("columns"); // columns | rows
  const [numberPageProjects, setNumberPageProjects] = useState(1);

  const { getVisible, changeModalVisible, getInfo } = useModal();

  const deleteProject = async () => {
    await dispatch(asyncDeleteProject(getInfo("deleteProject", "data")._id));
    changeModalVisible("deleteProject", false);
    dispatch(asyncGetProjects({ page: numberPageProjects }));
  };

  const changeViewCard = (view) => {
    setViewCards(view);
  };

  // * проверка параметра tab
  useEffect(() => {
    if (queries.tab) setChapter(queries.tab);
  }, [queries.tab]);

  // * проверка параметра view
  useEffect(() => {
    if (queries.view) setViewCards(queries.view);
  }, [queries.view]);

  const goToPageProject = (edit, info) => {
    dispatch(setEditProjectMode({ edit }));
    dispatch(setProject(info));
    navigate(`/projects/info/${info._id}`);
  };

  return (
    <LayoutMobile
      search={{
        inputSearch: {
          visible: true,
          placeholder: "Найти проект по названию",
        },
      }}
      filter={{ visible: true }}
      footerBtnAddCircle={{
        visible: true,
        onClick: () => changeModalVisible("addProject", true),
      }}
      filterDisabled={getVisible("delete")?.visible ? true : false}
    >
      <ErrorBoundary>
        <HeaderMobilePage
          title={`Всего проектов: ${count}`}
          active={chapter}
          currentPage={"projects"}
          viewCards={viewCards}
          onViewClick={changeViewCard}
          visibleNav={true}
        />
        <div className="projectsMobile">
          <WrapperLoader loading={loading} chapter={"projects"}>
            {projects.length > 0 ? (
              <>
                <div className="projectsMobile-grid">
                  {projects.map((card, i) => (
                    <CardColumnProject
                      number={i + 1}
                      key={card._id}
                      info={card}
                      onChangeNumberPageProjects={(page) => setNumberPageProjects(page)}
                    />
                  ))}
                </div>
                <Pagination count={count} />
              </>
            ) : (
              !loading && (
                <EmptySection
                  text={"Проектов не создано"}
                  textBtn={"Добавить"}
                  onClick={() => {
                    changeModalVisible("addProject", true);
                  }}
                />
              )
            )}
          </WrapperLoader>
          {/* Модальное окно добавление проекта */}
          {getVisible("addProject") && <ModalAddProject />}

          {/* Модальное окно удаление проекта */}
          {getVisible("deleteProject") && (
            <ModalDeleteItem
              headerTitle="Удаление проекта"
              title="Вы уверены, что хотите удалить проект?"
              changeModalVisible={() => changeModalVisible("deleteProject", false)}
              onDelete={deleteProject}
            />
          )}
          {getVisible("menu") && <ModalMobileMenu chapter={"projects"} nameModal={"menu"} />}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default ProjectsMobile;
