import React, { useState } from "react";
import { useEffect } from "react";
import Checkbox from "../../components/Checkbox/Checkbox";
import Pagination from "../../components/Pagination/Pagination";
import { useModal } from "../../hooks/useModal";
import { useSelects } from "../../hooks/useSelects";
import "./TaskListForLayer.scss";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import EmptySection from "../../components/EmptySection/EmptySection";
import ActiveHeaderTasks from "../../components/ActiveHeaderTasks/ActiveHeaderTasks";
import CardRowTask from "../../components/CardRowTask/CardRowTask";
import Screen from "../../components/Screen/Screen";
import TasksRowHeader from "../../components/TasksRowHeader/TasksRowHeader";

const TaskListForLayer = () => {
  const { getInfo, changeModalVisible, getVisible } = useModal();
  const infoLayer = getInfo("tasksLayer", "data");
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const { selectItems, addItemSelect, addAllItemSelect } = useSelects(tasks);

  const getTaskForLayer = async () => {
    try {
      const tasks = await axios.post(`/tasks/get/${pageNumber}`, {
        layer: infoLayer._id,
      });
      setTasks(tasks.data.tasks || []);
      setCount(tasks.data.count || 0);
    } catch (error) {
      toast.error("Не удалость получить задачи");
    }
  };

  const deleteLayerFromTask = async (taskId) => {
    changeModalVisible("deleteLayerFromTasks", true, {
      taskIds: selectItems.length ? selectItems : [taskId],
      layerId: infoLayer._id,
    });
  };

  useEffect(() => {
    getTaskForLayer();
  }, [pageNumber]);

  const changePage = (page) => setPageNumber(page);

  useEffect(() => {
    if (selectItems.length > 0) {
      setVisibleHeader(true);
    } else {
      setVisibleHeader(false);
    }
  }, [selectItems]);

  useEffect(() => {
    getTaskForLayer();
  }, []);

  return (
    <div className="taskListForLayer">
      {tasks.length ? (
        <div className="taskListForLayer-content">
          <div className="taskListForLayer-wrapper">
            <TasksRowHeader
              check={selectItems.length === tasks.length}
              onCheck={addAllItemSelect}
              onDelete={() =>
                changeModalVisible("deleteLayerFromTasks", true, {
                  taskIds: selectItems,
                  layerId: infoLayer._id,
                })
              }
              visible={selectItems.length === 1}
              selectItems={selectItems}
              handleChange={getTaskForLayer}
              tasks={tasks}
              visibleHeader={visibleHeader}
            />

            <ul className="taskListForLayer-cards">
              {tasks &&
                tasks.map((task) => {
                  return (
                    <CardRowTask
                      info={task}
                      data={task}
                      key={task._id}
                      onCheck={(taskId) => {
                        addItemSelect(taskId);
                      }}
                      check={(taskId) => selectItems.includes(taskId)}
                      onDelete={() =>
                        changeModalVisible("deleteLayerFromTasks", true, {
                          taskIds: [task._id],
                          layerId: infoLayer._id,
                        })
                      }
                    />
                  );
                })}
            </ul>
          </div>
          <Pagination
            currentPage={pageNumber}
            count={count}
            onPageChange={(page) => changePage(page)}
          />
        </div>
      ) : (
        // <div className="d-flex justify-center">
        //   <h3>В данном слое отсутствуют задачи</h3>
        // </div>
        <EmptySection
          text={"Задач для данного слоя не создано"}
          // textBtn={"Добавить"}
          // onClick={() => {
          //   changeModalVisible("addDeliveryWork", true);
          // }}
        />
      )}
    </div>
  );
};

export default TaskListForLayer;
