import React from "react";
import "./Checkbox.scss";

function Checkbox(props) {
  const { check, title, onCheck } = props;
  return (
    <div className="checkbox">
      <div onClick={onCheck} className={`checkbox-input ${check ? "check" : ""}`}></div>
      {title && <span id="check">{title}</span>}
    </div>
  );
}

export default Checkbox;
