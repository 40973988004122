import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import store from "./store";
import "./styles/global.scss";
import "animate.css";
import DevTools from "./components/DevTools/DevTools";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const isDevMode = (process.env.REACT_APP_MODE || "development") === "development";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <Provider store={store}>
        <App />
        {isDevMode && <DevTools />}
      </Provider>
    </QueryParamProvider>
  </Router>
);
