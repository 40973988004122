import React from "react";
import "./NavbarItemLink.scss";
import { NavLink } from "react-router-dom";
import Icons from "../Icons/Icons";
import { useModal } from "../../hooks/useModal";
import { setProject } from "../../store/reducers/projectsReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearch } from "../../hooks/useSearch";

function NavbarItemLink(props) {
  const { availability, info, unreadMessages } = props;
  const { name, path } = info;

  const { goToPage } = useSearch();

  const dispatch = useDispatch();

  const projectInfo = useSelector((state) => state.projects.project);

  const { changeModalVisible } = useModal();

  if (!availability.includes(path) && path !== "exit" && availability.length !== 0) return;

  const clearProject = () => {
    if (projectInfo && name !== "Проекты") {
      dispatch(setProject(null));
    }
  };

  return (
    <NavLink
      onClick={(e) => {
        e.preventDefault();

        goToPage(path);
        changeModalVisible("clear");
        clearProject();
      }}
      className={({ isActive }) => (isActive ? "navbarItemLink active" : "navbarItemLink")}
      to={path}
    >
      <div className="navbarItemLink-icon">
        <Icons
          type={path.match(/[a-z\s]+/giu).join("")}
          size={"xl"}
          color={"white"}
          // className={"header-icon"}
        />
        {path === "chat" && unreadMessages > 0 && (
          <span className="navbarItemLink-icon-count">{unreadMessages}</span>
        )}
      </div>
      <span className={`navbarItemLink-title`}>{name}</span>
    </NavLink>
  );
}

export default NavbarItemLink;
