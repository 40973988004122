import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getOptionsFilters } from "../../utils/getOptionsFilters";
import { getLimitElements } from "../../utils/getLimitElements";

export const asyncGetDeliveryWorks = createAsyncThunk("deliveryWorks/asyncGetDeliveryWork", async (data, helpers) => {
  const { rejectWithValue, getState } = helpers;
  try {
    const limit = getLimitElements();
    const res = await axios.post(`/deliveryWorks/get/${data.page}/?limit=${limit}`, data.params);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncGetDeliveryWork = createAsyncThunk("deliveryWorks/asyncGetDeliveryWork", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/deliveryWorks/get_work/${data}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncCreateDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/deliveryWorks/create/`, data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncEditDeliveryWork = createAsyncThunk("deliveryWorks/asyncEditDeliveryWork", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.patch(`/deliveryWorks/edit/${data.workId}`, data.data);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditStatusDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncEditStatusDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/deliveryWorks/edit_status/${data.workId}/${data.status}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncDeleteDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/deliveryWorks/delete/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ответ инженера
export const asyncCreateAnswerDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncCreateAnswerDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/deliveryWorks/create_answer/${data.workId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncEditAnswerDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncEditAnswerDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/deliveryWorks/edit_answer/${data.answerId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteAnswerDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncDeleteAnswerDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/deliveryWorks/delete_answer/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// письмо
export const asyncCreateLetterDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncCreateLetterDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.post(`/deliveryWorks/create_letter/${data.answerId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncEditLetterDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncEditLetterDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.patch(`/deliveryWorks/edit_letter/${data.letterId}`, data.data);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteLetterDeliveryWork = createAsyncThunk(
  "deliveryWorks/asyncDeleteLetterDeliveryWork",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios.delete(`/deliveryWorks/delete_letter/${data}`);
      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deliveryWorkReducer = createSlice({
  name: "deliveryWorks",
  initialState: {
    deliveryWorks: [],
    count: 0,
    loading: false,
  },
  reducers: {},
  extraReducers: extraReducersWrapper({
    [asyncGetDeliveryWorks.fulfilled]: (state, { payload }) => {
      state.deliveryWorks = payload.works;
      state.count = payload.count;
    },
    [asyncGetDeliveryWorks.rejected]: "Не удалось получить работы",

    [asyncGetDeliveryWork.rejected]: "Не удалось получить работу",

    [asyncCreateDeliveryWork.fulfilled]: "Работа успешно создана",
    [asyncCreateDeliveryWork.rejected]: "Не удалось создать работу",

    [asyncEditDeliveryWork.fulfilled]: "Работа успешно изменена",
    [asyncEditDeliveryWork.rejected]: "Не удалось изменить работу",

    [asyncEditStatusDeliveryWork.fulfilled]: "Статус работы успешно изменен",
    [asyncEditStatusDeliveryWork.rejected]: "Не удалось изменить статус работы",

    [asyncDeleteDeliveryWork.fulfilled]: "Работа успешно удалена",
    [asyncDeleteDeliveryWork.rejected]: "Не удалось удалить работу",

    [asyncCreateAnswerDeliveryWork.fulfilled]: "Ответ успешно создан",
    [asyncCreateAnswerDeliveryWork.rejected]: "Не удалось создать ответ",

    [asyncEditAnswerDeliveryWork.fulfilled]: "Ответ успешно изменен",
    [asyncEditAnswerDeliveryWork.rejected]: "Не удалось изменить ответ",

    [asyncDeleteAnswerDeliveryWork.fulfilled]: "Ответ успешно удален",
    [asyncDeleteAnswerDeliveryWork.rejected]: "Не удалось удалить ответ",

    [asyncCreateLetterDeliveryWork.fulfilled]: "Письмо успешно создано",
    [asyncCreateLetterDeliveryWork.rejected]: "Не удалось создать письмо",

    [asyncEditLetterDeliveryWork.fulfilled]: "Письмо успешно изменено и отправлено",
    [asyncEditLetterDeliveryWork.rejected]: "Не удалось изменить и отправить письмо",

    [asyncDeleteLetterDeliveryWork.fulfilled]: "Письмо успешно удалено",
    [asyncDeleteLetterDeliveryWork.rejected]: "Не удалось удалить письмо",
  }),
});

export const {} = deliveryWorkReducer.actions;

export default deliveryWorkReducer.reducer;
