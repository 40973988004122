import React, { useState, useEffect } from "react";
import LayoutMobile from "../../../components/LayoutMobile/LayoutMobile";
import "./DocumentationMobile.scss";
import HeaderMobilePage from "../../../components/HeaderMobilePage/HeaderMobilePage";
import NavigationListMobile from "../../../components/NavigationListMobile/NavigationListMobile";
import ModalDeleteItem from "../../../components/ModalDeleteItem/ModalDeleteItem";
import ModalAddDoc from "../../../components/ModalAddDoc/ModalAddDoc";
import ModalAddToFolder from "../../../components/ModalAddToFolder/ModalAddToFolder";
import ModalAddFolder from "../../../components/ModalAddFolder/ModalAddFolder";
import ModalInfoEditFolder from "../../../components/ModalInfoEditFolder/ModalInfoEditFolder";
import LeftInfo from "../../../components/LeftInfo/LeftInfo";
import { useChangeOptions } from "../../../hooks/useChangeOptions";
import AdditionalFilterPanel from "../../../components/AdditionalFilterPanel/AdditionalFilterPanel";
import Filter from "../../../components/Filter/Filter";
import { useModal } from "../../../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  asyncGetFolders,
  asyncDeleteFile,
  asyncGetFolder,
  asyncGetFoldersEntity,
} from "../../../store/reducers/documentationReduser";
import { getForSelect } from "../../../utils/getForSelect";
import { toast } from "react-toastify";
import ModalViewDoc from "../../../components/ModalViewDoc/ModalViewDoc";
import DocumentationList from "../../../components/DocumentationList/DocumentationList";
import ModalInfoEditFile from "../../../components/ModalInfoEditFile/ModalInfoEditFile";
import { useSelects } from "../../../hooks/useSelects";
import ModalMobileMenu from "../../../components/ModalMobileMenu/ModalMobileMenu";
import { useSearch } from "../../../hooks/useSearch";
import { useHasPermissions } from "../../../hooks/useHasPermissions";
import { getTypePages } from "../../../utils/getTypePages";
import { ErrorBoundary } from "../../../ui";

const DocumentationMobile = () => {
  const dispatch = useDispatch();

  const { getInfo, getVisible, changeModalVisible } = useModal();

  const { folders } = useSelector((state) => state.documentation);
  const { father, documents } = folders;

  const [chapter, setChapter] = useState("companies");
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [headFolder, setHeadFolder] = useState(null);
  const [fatherFolder, setFatherFolder] = useState(null);

  const [projectInfo, setInfoProject] = useState(null);

  const { search, setQueryParam, queries } = useSearch(`documentation`);

  const [activeSearchNav, setActiveSearchNav] = useState("genContractor");
  const { visible, types = [] } = useHasPermissions("documentation", projectInfo);

  const changeWiewChapter = (value) => setChapter(value);

  const changeCurrentFolder = (folder) => setFatherFolder(folder);

  // * получаем папку
  const getFolder = async () => queries.father && search();

  const getFolderById = async () => {
    const folderId = queries.father;
    const res = await dispatch(asyncGetFolder({ folderId }));
    if (res.error) return;

    setFatherFolder(res.payload);
    setHeadFolder(res.payload);

    return res.payload._id;
  };

  const deleteFolder = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteFolder", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteFolder", false, null);
  };

  const deleteDocument = async () => {
    const idArray = [];
    idArray.push(getInfo("deleteDocument", "data")._id);
    await dispatch(
      asyncDeleteFile({
        document_ids: idArray,
      }),
    );
    getFolder();
    changeModalVisible("deleteDocument", false, null);
  };

  useEffect(() => {
    if (!queries.chapter) return setChapter("companies");
    // console.log("!!queries.chapter", queries.chapter);
    setChapter(queries.chapter);
  }, [queries.chapter]);

  useEffect(() => {
    if (!queries.tab) return setActiveSearchNav("genContractor");

    setActiveSearchNav(queries.tab);
  }, [queries.tab]);

  useEffect(() => {
    if (fatherFolder) return;
    // console.log("queries.father", queries.father);
    (async () => {
      if (queries.father) {
        const id = await getFolderById();
      } else {
        setFatherFolder(fatherFolder);
        setHeadFolder(fatherFolder);
      }

      setQueryParam({ father });
    })();
  }, [fatherFolder]);

  useEffect(() => {
    if (fatherFolder?.main) {
      setHeadFolder(fatherFolder);
    }
  }, [fatherFolder]);

  useEffect(() => {
    setInfoProject(folders?.father?.projectInfo);
  }, [folders]);

  return (
    <LayoutMobile
      currentPage={"documentation"}
      search={
        folders?.father && {
          inputSearch: {
            visible: true,
            placeholder: "Найти документ по названию",
          },
        }
      }
      filterDisabled={true}
      footerBtnAddCircle={
        folders?.father && {
          visible: true,
          onClick: () => changeModalVisible("menuAddDocumenattion", true, "test"),
        }
      }
    >
      <ErrorBoundary>
        <HeaderMobilePage
          title={`Всего документов: ${documents?.length}`}
          active={chapter}
          changeWiewChapter={changeWiewChapter}
          visibleNav={true}
        />
        <div className="documentationMobile">
          {chapter === "project" && (!!fatherFolder || !!queries.father) && (
            <NavigationListMobile
              listBtn={{
                visible: visible,
                type: "typePages",
              }}
              typePages={getTypePages(types)}
            />
          )}
          <div
            className={`documentationMobile-wrapper ${
              chapter === "companies" || folders?.father ? "all" : ""
            }`}
          >
            <DocumentationList
              viewCards={"column"}
              setFatherFolder={setFatherFolder}
              fatherFolder={fatherFolder}
              visibleHeader={visibleHeader}
              setVisibleHeader={setVisibleHeader}
              changeCurrentFolder={changeCurrentFolder}
              activeChapter={chapter}
            />
          </div>

          {getVisible("menu") && (
            <ModalMobileMenu chapter={"documentation"} info={getInfo("menu").data} />
          )}
          {getVisible("menuAddDocumenattion") && (
            <ModalMobileMenu
              chapter={"menuAddDocumenattion"}
              nameModal={"menuAddDocumenattion"}
              onClose={() => changeModalVisible("menuAddDocumenattion", false)}
            />
          )}
          {/* Добавление документа */}
          {getVisible("addDocument") && (
            <ModalAddDoc
              fatherFolder={fatherFolder}
              handleChange={getFolder}
              headFolder={headFolder}
            />
          )}

          {/* Добавление папки */}
          {getVisible("addFolder") && (
            <ModalAddFolder
              handleChange={getFolder}
              fatherFolder={fatherFolder}
              headFolder={headFolder}
            />
          )}

          {/* Просмотр/реадктирование папки */}
          {getVisible("infoFolder") && (
            <ModalInfoEditFolder
              fatherFolder={fatherFolder}
              handleChange={getFolder}
              headFolder={headFolder}
            />
          )}
          {/* Редактирование файла */}
          {getVisible("infoFile") && (
            <ModalInfoEditFile
              fatherFolder={fatherFolder}
              handleChange={getFolder}
              headFolder={headFolder}
            />
          )}
          {/* Добавление в папку */}
          {getVisible("addToFolder") && (
            <ModalAddToFolder
              headFolder={headFolder}
              fatherFolder={fatherFolder}
              handleChange={getFolder}
            />
          )}

          {/* Удаление документа */}
          {getVisible("deleteDocument") && (
            <ModalDeleteItem
              headerTitle="Удалить документ"
              title="Вы уверены, что хотите удалить этот документ?"
              nameModal="deleteDocument"
              onDelete={deleteDocument}
            />
          )}

          {/* Просмотр документов*/}
          {getVisible("viewDoc") && <ModalViewDoc />}

          {/* Удаление папки */}
          {getVisible("deleteFolder") && (
            <ModalDeleteItem
              headerTitle="Удалить папку"
              title="Вы уверены, что хотите удалить эту папку?"
              nameModal="deleteFolder"
              onDelete={deleteFolder}
            />
          )}
        </div>
      </ErrorBoundary>
    </LayoutMobile>
  );
};

export default DocumentationMobile;
