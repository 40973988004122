import React from "react";
import "./StructuresList.scss";
import StructuresListHeader from "./components/StructuresListHeader/StructuresListHeader";
import StructuresListRow from "./components/StructuresListRow/StructuresListRow";
import Select from "../../../../components/Select/Select";

const StructuresList = ({ structures }) => {
  const { director, company, departments, profiles } = structures;

  return (
    <div className="structuresList customScroll">
      <div className="structuresList__content ">
        <StructuresListHeader
          employeesLength={profiles?.length + Number(!!director)}
          director={director}
          company={company}
          info={structures}
        />
        <ul className="structuresList__rows structuresList-rows ">
          {departments?.map((elem) => {
            return (
              <StructuresListRow
                key={elem._id}
                name={elem.name}
                director={elem.director}
                employeesLength={elem.profiles.length + Number(!!elem?.director)}
                info={elem}
                id={elem.id}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default StructuresList;
