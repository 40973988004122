import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const get_roles = createAsyncThunk("roles/get_roles", async () => {
  try {
    const res = await axios.get("roles/getAll");
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const rolesReducer = createSlice({
  name: "roles",
  initialState: {
    roles: [],
  },
  extraReducers: {
    [get_roles.fulfilled]: (state, action) => {
      const { roles } = action.payload;
      state.roles = roles;
    },
  },
});

export default rolesReducer.reducer;
