import React, { useState, useEffect } from "react";
import "./WorkProjectInfo.scss";
import { useSelector } from "react-redux";
import CanvasPlan from "../../pages/desktop/Project/components/Layers/components/CanvasPlan/CanvasPlan";
import { getBaseUrl } from "../../utils/getBaseUrl";
import Select from "../Select/Select";
import ImageList from "../ImageList/ImageList";
import Button from "../Button/Button";

const WorkProjectInfo = ({
  // violationsImages,
  options,
  changeOptions,
  editMode,
  // layersOptions,
  // getLayersInfo,
  // onEvent,
  // layersValue,
  // projectOptions,
  // typeWork,
  // typeWorkOptions,
  // typeWorkValue,
  // nameProject,
  // projectValue,
}) => {
  const [images, setImages] = useState(violationsImages || []);
  const [lines, setLines] = useState(null);

  const removeImg = (img) => {
    setImages((prev) => prev.filter((i) => i !== img));
  };

  useEffect(() => {
    setImages(violationsImages);
  }, [violationsImages]);

  return (
    <ul className="workProjectInfo">
      <li className="workProjectInfo__item workProjectInfo-item">
        {editMode ? (
          <Select
            title="Вид работ:"
            forInput
            options={typeWorkOptions}
            value={typeWorkValue}
            placeholder="Выберите вид работ"
            handleChange={(value) => changeOptions("typeWork", "value", value)}
          />
        ) : (
          <>
            <span className="workProjectInfo-item-title">Вид работ:</span>
            <span className="workProjectInfo-item-text">{typeWork}</span>
          </>
        )}
      </li>
      <li className="workProjectInfo__item workProjectInfo-item">
        {editMode ? (
          <Select
            handleChange={(value) =>
              changeOptions("nameProject", "value", value)
            }
            title="Проект:"
            placeholder="Выберите проект"
            forInput
            options={projectOptions}
            value={projectValue}
          />
        ) : (
          <>
            <span className="workProjectInfo-item-title">
              Название проекта:
            </span>
            <span className="workProjectInfo-item-text">{nameProject}</span>
          </>
        )}
      </li>
      <li className="workProjectInfo__item workProjectInfo-item">
        <span className="workProjectInfo-item-title">Слой: </span>
        {!!layersValue?.value && layersValue?.label && !editMode && (
          <span className="workProjectInfo-item-text">
            {!!layersValue?.value && layersValue?.label}
          </span>
        )}
        {editMode && (
          <Select
            options={layersOptions}
            forInput
            value={layersValue}
            placeholder="Выберите слой"
            handleChange={(value) => changeOptions("layers", "value", value)}
          />
        )}
        {!!layersValue?.value && (
          <li className="workProjectInfo__item-canvas workProjectInfo-item-canvas">
            <CanvasPlan
              lines={lines}
              imgSrc={
                editMode
                  ? getBaseUrl() + getLayersInfo(layersValue?.value)?.doc?.path
                  : getBaseUrl() + layersValue?.path
              }
              visiblePoints={true}
              filterPin={"all"}
              changeCurrentTask={() => {}}
              onChangelines={() => {}}
              width={420}
              height={297}
            />
          </li>
        )}
        {!!layersValue?.value && editMode && (
          <li className="workProjectInfo__item workProjectInfo-item">
            <Button
              disabled={!!!layersValue?.value}
              color="blue"
              title="Добавить"
              // icon={{ position: "left" }}
              onClick={() => onEvent("addPoint")}
            />
          </li>
        )}
      </li>
      <li className="workProjectInfo__item workProjectInfo-item">
        <ImageList
          onChange={(list) => setImages(list)}
          images={images}
          title="Фото:"
          editMode={editMode ? true : false}
        />
      </li>
    </ul>
  );
};
export default WorkProjectInfo;
