import React, { useState, useRef } from "react";
import "./DeliveryWorkRequestsRow.scss";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import { getFormatFIO } from "../../../../../utils/getFormatFIO.js";
import { getFormatDate } from "../../../../../utils/getFormatDate";
import Icons from "../../../../../components/Icons/Icons";
import { useModal } from "../../../../../hooks/useModal";
import dayjs from "dayjs";
import { transformStatus } from "../../../../../utils/transformStatus";
import { transformSection, transformTypeWork } from "../../utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside";
const DeliveryWorkRequestsRow = (props) => {
  const { info } = props;
  const {
    name,
    engineer,
    section,
    call_date,
    status,
    project,
    engineer_answer,
    author,
  } = info;

  let { _id: userId, role: userRole } = useSelector(
    (state) => state.user.userData
  );

  // userId = "64466797ec8efb38b9ed1124";
  // userRole = "user";

  const [editMenu, setEditMenu] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setEditMenu(false));
  const { changeModalVisible } = useModal();

  const onEvent = (name, modalName) => {
    if (name === "info") changeModalVisible("infoDeliveryWork", true, info);
    else if (name === "email") {
      if (userId !== engineer.user._id && userRole !== "admin") {
        return toast.error("Редактировать письмо может только инженер работы");
      }

      if (!!engineer_answer?.letter) {
        changeModalVisible("editLetterDeliveryWork", true, info);
      } else {
        changeModalVisible("addLetterDeliveryWork", true, info);
      }
    } else if (name === "edit") {
      if (userRole !== "admin") {
        if (userId === engineer.user._id) {
          if (engineer_answer) {
            changeModalVisible("editAnswerDeliveryWork", true, info);
          } else {
            changeModalVisible("addAnswerDeliveryWork", true, info);
          }
        } else if (userId === author.user._id) {
          changeModalVisible("editDeliveryWork", true, info);
        }
      } else {
        if (!!engineer_answer) {
          changeModalVisible(modalName, true, info);
        } else {
          changeModalVisible(modalName, true, info);
        }
      }
    } else if (name === "create") {
      changeModalVisible("addAnswerDeliveryWork", true, info);
    } else if (name === "remove")
      changeModalVisible("deleteDeliveryWork", true, info);
  };

  return (
    <li className="deliveryWorkRequestsRow">
      <ul className="deliveryWorkRequestsRow__list">
        {/* <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-number">
          <Checkbox check={check} onCheck={onCheck} />
          <span>{number}</span>
        </li> */}
        <li
          className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-typeWork"
          onClick={() => onEvent("info")}
        >
          <span title={name}>{name}</span>
        </li>
        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-author">
          <span title={getFormatFIO(author.user)}>
            {getFormatFIO(author.user)}
          </span>
        </li>
        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-inspector">
          <span title={getFormatFIO(engineer.user)}>
            {getFormatFIO(engineer.user)}
          </span>
        </li>
        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-inspector">
          <span title={project?.name || "-"}>{project?.name || "-"}</span>
        </li>

        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-dateStart">
          <span title={dayjs(call_date).format("DD.MM.YYYY")}>
            {dayjs(call_date).format("DD.MM.YYYY")}
          </span>
        </li>
        <li
          className={`deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-${status}`}
        >
          <span title={transformStatus(status).name}>
            {transformStatus(status).name}
          </span>
        </li>
        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-typeReceiving">
          <span title={transformSection(section).name}>
            {transformSection(section).name}
          </span>
        </li>
        <li className="deliveryWorkRequestsRow__item deliveryWorkRequestsRow__item-btns">
          <Icons
            onClick={() => onEvent("info")}
            type={"infoItem"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
          />

          {info.engineer_answer &&
            (userId === engineer.user._id || userRole === "admin") && (
              <Icons
                onClick={() => onEvent("email")}
                type={"emailOpen"}
                size={"md"}
                cursor
                title={{ visible: true, text: "" }}
              />
            )}

          {editMenu && (
            <div
              ref={ref}
              className="deliveryWorkRequestsRow__btn deliveryWorkRequestsRow__btn-editMenu"
            >
              <span onClick={() => onEvent("edit", "editDeliveryWork")}>
                Редактировать работу
              </span>
              {info.engineer_answer ? (
                <span onClick={() => onEvent("edit", "editAnswerDeliveryWork")}>
                  Редактировать ответ инженера
                </span>
              ) : (
                <span onClick={() => onEvent("create")}>
                  Создать ответ инженера
                </span>
              )}
            </div>
          )}

          {userRole === "admin" ? (
            <Icons
              onClick={() => setEditMenu((prev) => !prev)}
              type={"menu"}
              size={"md"}
              cursor
              title={{ visible: true, text: "" }}
            />
          ) : (
            <Icons
              onClick={() => onEvent("edit", "editAnswerDeliveryWork")}
              type={"edit"}
              size={"md"}
              cursor
              title={{ visible: true, text: "" }}
            />
          )}

          <Icons
            onClick={() => onEvent("remove")}
            type={"remove"}
            size={"md"}
            cursor
            title={{ visible: true, text: "" }}
          />
        </li>
      </ul>
    </li>
  );
};
export default DeliveryWorkRequestsRow;
