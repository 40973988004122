import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/axios";
import { extraReducersWrapper } from "../../utils/extraReducersWrapper";
import { getLimitElements } from "../../utils/getLimitElements";

export const asyncGetGroups = createAsyncThunk("groups/asyncGetGroups", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  const limit = data?.params?.limit || getLimitElements();
  const res = await axios.post(`/groups/get/${data.page}/?limit=${limit}`, data.params);
  if (res.data) return res.data;
  rejectWithValue(res);
});

export const asyncGetGroup = createAsyncThunk("groups/asyncGetGroup", async (id, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios.post(`/groups/get_group/${id}`);
    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncEditGroup = createAsyncThunk("groups/asyncEditGroup", async (data, helpers) => {
  const { rejectWithValue } = helpers;
  try {
    const res = await axios({
      url: `/groups/edit/${data.groupId}`,
      method: "PATCH",
      data: data.data,
    });

    if (res.data) return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const asyncCreateGroup = createAsyncThunk(
  "groups/asyncCreateGroup",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios({
        url: "/groups/create",
        method: "POST",
        data,
      });

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const asyncRemoveGroup = createAsyncThunk(
  "groups/asyncRemoveGroup",
  async (data, helpers) => {
    const { rejectWithValue } = helpers;
    try {
      const res = await axios({
        url: `/groups/remove/${data}`,
        method: "DELETE",
      });

      if (res.data) return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const groupsReducer = createSlice({
  name: "groups",
  initialState: {
    groups: [],
    count: 0,
    group: null,
    edit: false,
    loading: false,
  },
  reducers: {
    set_groups: (state, action) => {
      const { groups } = action.payload;
      state.groups = groups;
    },
    set_group: (state, action) => {
      const { group } = action.payload;
      state.group = group;
    },
    set_edit_group_mode: (state, action) => {
      const { edit } = action.payload;
      state.edit = edit;
    },
  },
  extraReducers: extraReducersWrapper({
    [asyncGetGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload.groups;
      state.count = payload.count;
    },
    [asyncGetGroups.rejected]: "Не удалось получить группы",

    [asyncEditGroup.fulfilled]: "Группа успешно изменена",
    [asyncEditGroup.rejected]: "Не удалось изменить группу",

    [asyncCreateGroup.fulfilled]: "Группа успешно создана",
    [asyncCreateGroup.rejected]: "Не удалось создать группу",

    [asyncRemoveGroup.fulfilled]: "Группа успешно удалена",
    [asyncRemoveGroup.rejected]: "Не удалось удалить группу",
  }),
});

export const { set_group, set_edit_group_mode } = groupsReducer.actions;

export default groupsReducer.reducer;
