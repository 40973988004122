import React from "react";
import ActiveHeaderTasks from "../ActiveHeaderTasks/ActiveHeaderTasks";
import Checkbox from "../Checkbox/Checkbox";
import Screen from "../Screen/Screen";
import "./TasksRowHeader.scss";

const TasksRowHeader = ({
  visibleHeader,
  check,
  onCheck,
  onDelete,
  selectItems,
  visible,
  tasks,
  edit = true,
  handleChange,
}) => {
  return (
    <ul className={`tasksRowHeader ${edit ? "edit" : ""}`}>
      {edit && (
        <>
          {visibleHeader && (
            <ActiveHeaderTasks
              check={check}
              onCheck={onCheck}
              onDelete={onDelete}
              visible={visible}
              selectItems={selectItems}
              tasks={tasks}
              handleChange={handleChange}
            />
          )}
          <li className="tasksRowHeader-item numder-item">
            <Checkbox check={check} onCheck={onCheck} />
          </li>
        </>
      )}
      <Screen size={"xxxl"}>
        <li className="tasksRowHeader-item company-item">
          <span title="Номер задачи">№:</span>
        </li>
      </Screen>
      <li className="tasksRowHeader-item company-item">
        <span title="Задача">Задача:</span>
      </li>
      <li className="tasksRowHeader-item author-item">
        <span title="Автор">Автор:</span>
      </li>
      <li className="tasksRowHeader-item performer-item">
        <span title="Исполнитель">Исполнитель:</span>
      </li>
      <Screen size={"xxxl"}>
        <li className="tasksRowHeader-item performer-item">
          <span title="Компания исполнитель">Компания исполнитель:</span>
        </li>
      </Screen>
      <li className="tasksRowHeader-item project-item">
        <span title="Проект">Проект:</span>
      </li>
      <li className="tasksRowHeader-item create-item">
        <span title="Период">Период:</span>
      </li>

      <li className="tasksRowHeader-item deadline-item">
        <span title="Срок выполнения:">Срок выполнения:</span>
      </li>

      <li className="tasksRowHeader-item status-item">
        <span title="Статус">Статус:</span>
      </li>
    </ul>
  );
};

export default TasksRowHeader;
