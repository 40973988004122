import React, { useId } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModal } from "../../hooks/useModal";
import Input from "../Input/Input";
import Button from "../Button/Button";
import "./ModallAddPosition.scss";
import { useChangeIV } from "../../hooks/useChangeIV";
import { asyncСreatePosition, getPosition } from "../../store/reducers/positionsReducer";
import { useDispatch } from "react-redux";
import Screen from "../Screen/Screen";

const ModallAddPosition = (props) => {
  const formId = useId();

  const { handleChange } = props;

  const dispatch = useDispatch();

  const { changeModalVisible } = useModal();

  const { IV, changeIV, checkValidateIV } = useChangeIV({
    name: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkValidateIV("name", { minHeight: 3, maxHeight: 50 })) return;
    await dispatch(asyncСreatePosition({ name: IV.name }));
    changeModalVisible("addPosition", false);
    handleChange && handleChange();
  };

  return (
    <ModalWrapper
      onClose={() => changeModalVisible("addPosition", false)}
      title={"Добавить должность"}
      minHeight={"150px"}
      btns={
        <div className="modallAddPosition-btn-wrapper">
          <Button
            disabled={IV.name.length <= 3}
            type="submit"
            title="Создать"
            color="blue"
            form={formId}
          />
        </div>
      }
    >
      <form onSubmit={onSubmit} className="modallAddPosition" id={formId}>
        <Input
          title="Название должности:*"
          placeholder="Введите название должности"
          name="name"
          value={IV.name}
          onChange={changeIV}
          validate={checkValidateIV("name")}
        />
        <Screen size={"lg"}>
          <div className="modallAddPosition-btn-wrapper">
            <Button
              disabled={IV.name.length <= 3}
              type="submit"
              title="Создать"
              color="blue"
              form={formId}
            />
          </div>
        </Screen>
      </form>
    </ModalWrapper>
  );
};

export default ModallAddPosition;
