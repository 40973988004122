import { getFormatFIO } from "../../../utils/getFormatFIO";
import { transformForSelect } from "../../../utils/transformForSelect";
import { transformNotification } from "../../../utils/transformNotification";
import { transformPriority } from "../../../utils/transformPriority";
import { transformStatus } from "../../../utils/transformStatus";

export const getValueForOptions = (data, field) => {
  if (!data) {
    if (field === "executors" || field === "observers") return [];
    return null;
  }
  if (data[field]?.value) return data[field]?.value;

  if (field === "status") {
    if (data["status"]) {
      return transformForSelect(transformStatus(data["status"]));
    }

    return { label: "Назначено", value: "assigned" };
  }

  if (field === "executors") {
    if (data["executors"] && Array.isArray(data["executors"]))
      return data["executors"].map((e) => ({
        label: `${getFormatFIO(e.user)} ( Компания:${e?.company?.name}; Должность:${
          e?.position?.name
        } )`,
        value: e?._id,
      }));
    return [];
  }

  if (field === "observers") {
    if (data["observers"] && Array.isArray(data["observers"]))
      return data["observers"].map((e) => ({
        label: `${getFormatFIO(e.user)} ( Компания: ${e?.company?.name}; Должность: ${
          e?.position?.name
        } )`,
        value: e?._id,
      }));
    return [];
  }

  if (field === "projects") {
    if (data["project"]) return transformForSelect(data["project"]);
    return null;
  }

  if (field === "layers") {
    if (data["layer"]) return transformForSelect(data["layer"]);
    return null;
  }

  if (field === "author") {
    if (data["author"] && data["author"]?._id) {
      return {
        label: `${getFormatFIO(data["author"]?.user)} (Компания:${
          data["author"]?.company?.name
        }; Должность: ${data["author"]?.position?.name})`,
        value: data["author"]?._id,
      };
    }
    return null;
  }

  if (field === "notificationFrequency") {
    if (data["notifications"]) {
      return transformForSelect(transformNotification(data["notifications"]));
    }
    return { label: "6 часов", value: "6hour" };
  }

  return null;
};
