import React from "react";
import "./ProjectWorkScheduleMobileItem.scss";
import HeaderMobilePage from "../../../../../../../components/HeaderMobilePage/HeaderMobilePage";
import { useModal } from "../../../../../../../hooks/useModal";
import ProjectWorkScheduleInfo from "../../../../../../../components/ProjectWorkScheduleInfo/ProjectWorkScheduleInfo";
import ProjectWorkScheduleChart from "../../../../../../../components/ProjectWorkScheduleChart/ProjectWorkScheduleChart";
import Screen from "../../../../../../../components/Screen/Screen";
import ProjectWorkScheduleCol from "../../../../../../../components/ProjectWorkScheduleCol/ProjectWorkScheduleCol";
import EmptySection from "../../../../../../../components/EmptySection/EmptySection";

const ProjectWorkScheduleMobileItem = ({ info, edit = false, tasks, viewCards }) => {
  const { name } = info;
  const { changeModalVisible } = useModal();
  return (
    <div className="projectWorkScheduleMobileItem">
      {/* <HeaderMobilePage
        title={edit ? "Редактировать график" : `График:  ${name}`}
        //active={chapter}
        currentPage={""}
        // changeWiewChapter={changeWiewChapter}
        onClose={() => changeModalVisible("infoWorkSchedule", false)}
      />
      <div className="projectWorkScheduleMobileItem-content">
        <ProjectWorkScheduleInfo edit={edit} data={info} />
        {viewCards === "rows" ? (
          <ProjectWorkScheduleCol tasks={tasks} edit={edit} />
        ) : (
          <Screen size={"sm"}>
            <ProjectWorkScheduleChart tasks={tasks} edit={edit} />
          </Screen>
        )}
      </div> */}
      <EmptySection text={"Графики доступны только на десктоп"} />
    </div>
  );
};

export default ProjectWorkScheduleMobileItem;
